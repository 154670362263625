import { getIfScopeInternational } from './scope';
import { FLIGHT_SEGMENT_TYPE } from '../../constants/list/enums/domesticPriceSortTypeGroup';
/**
 * 在筛选框和低价日历是否需要将该航班的最低运价给包括进去进行比较，
 * 需要考虑的逻辑是，查询两舱推荐低舱（不限当前程，即当前显示返程，去程有高舱推低舱也需要跳过该航班价格），查询头等舱推荐公务舱，就跳过该航班价格
 * 不能是虚拟航班
 */
export const getIfFlightAsLowPrice = (flight) => {
	const hasGetFunc = (item) => typeof item.get === 'function',
		flightIsImmutable = hasGetFunc(flight),
		cached = flightIsImmutable ? flight.get('_ifFlightAsLowPrice') : flight._ifFlightAsLowPrice;

	if (typeof cached === 'boolean') {
		return cached;
	}

	const scopeInternational = getIfScopeInternational();
	if (scopeInternational) {
		const isFCRecommendYS = flightIsImmutable ? flight.get('isFCRecommendYS') : flight.isFCRecommendYS,
			containsRecommendCabin = flightIsImmutable
				? flight.get('containsRecommendCabin')
				: flight.containsRecommendCabin,
			priceList = flightIsImmutable ? flight.get('priceList').valueSeq() : flight.priceList,
			containsVirtualFlight = flightIsImmutable
				? flight.get('flightSegments').filter((seg) => seg.get('containsVirtualFlight')).size
				: flight.flightSegments.some((seg) => seg.containsVirtualFlight);

		return (
			!containsVirtualFlight &&
			!isFCRecommendYS &&
			!containsRecommendCabin &&
			(hasGetFunc(priceList) ? priceList.size : priceList.length)
		);
	} else {
		const containsVirtualFlight = flightIsImmutable
			? flight.get('flightSegments').some((seg) => seg.get('segmentType') == FLIGHT_SEGMENT_TYPE.FLIGHT_TRAIN.key)
			: flight.flightSegments.some((seg) => seg.segmentType == FLIGHT_SEGMENT_TYPE.FLIGHT_TRAIN.key);

		return !containsVirtualFlight;
	}
};

export default getIfFlightAsLowPrice;
