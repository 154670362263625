import React from 'react'

const defaultProps = {
    loading: null,
    then: value => <span>{value}</span>
}

let OBSERVER_OPTIONS = {
    root: null,
    rootMargin: '0px',
    threshold: [0, 1]
}

export default class DeferredContent extends React.Component {
    constructor(props) {
        super(props)

        const { getPromise, loading = defaultProps.loading, promise, then, delayGetPromise = false } = props

        this.isMount = false
        this.getPromise = getPromise    //componentDidMount才会执行 promise
        this.loading = loading
        this.promise = promise      //即时执行 promise
        this.then = then
        this.delayGetPromise = delayGetPromise
        this.delayContentRef = React.createRef()

        this.state = {
            value: null,
            extValue: null,
            content: loading
        }

        this.invokeThen()
    }

    invokeThen() {
        const { loading, promise, then } = this

        if (promise && then) {
            promise.then(value => {
                if (this.isMount) {
                    const content = then(value?.el || value, value?.ext || loading)
                    this.setState({ content })
                }
            })
        }
    }

    componentWillReceiveProps(nextProp) {
        if (this.state.promise !== nextProp.promise && this.state.then !== nextProp.then) {
            this.setState({
                loading: nextProp.loading || defaultProps.loading,
                value: null,
                promise: nextProp.promise,
                then: nextProp.then
            })

            this.invokeThen()
        }
    }

    ifNeedGetPromise() {
        return !this.promise && typeof this.getPromise === 'function'
    }

    componentDidMount() {
        this.isMount = true
        if (this.ifNeedGetPromise()) {
            const doGetPromise = () => {
                this.promise = this.getPromise()
                this.invokeThen()
            }

            if (this.delayGetPromise && this.delayContentRef.current) {
                let observer = new IntersectionObserver((entries) => {
                    // 进入屏幕才调用 getPromise
                    const entry = entries[0]
                    if (entry && entry.intersectionRatio > 0) {
                        setTimeout(() => {
                            if (this.isMount) {
                                doGetPromise()
                                observer.disconnect()
                            }
                        }, 500)
                    }
                }, OBSERVER_OPTIONS)

                observer.observe(this.delayContentRef.current)
            } else {
                doGetPromise()
            }

        }
    }

    componentWillUnmount() {
        this.isMount = false
    }

    render() {
        const { content } = this.state
        if (this.ifNeedGetPromise() && this.delayGetPromise && !content) {
            return <div ref={this.delayContentRef} />
        } else {
            return content
        }

    }
}
