import * as types from '../../constants/channel/ubtActionTypes'

const dftState = {
    poiNoResult: []
}

export default function ubt(state = dftState, action) {
    switch (action.type) {
        //poi
        case types.POI_NO_RESULT:
            return {
                ...state,
                poiNoResult: [...state.poiNoResult, action.data]
            }
        case types.CLEAR_POI_NO_RESULT:
            return {
                ...state,
                poiNoResult: []
            }
        default:
            return state
    }
}
