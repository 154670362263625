import { getIfScopeInternational } from "./scope";

/**
 * 使用范围：函数
 * 目的：当前搜索国际航班，就返回参数中 i 字段对应函数， 搜索国内航班，就返回参数中 d 字段对应函数，
 *      二者都不能为空，且类型要求是function
 */
export const scopeFunction = ({ i: internationalFunc, d: domesticFunc }) => {
    const typeOfIntlFunc = typeof internationalFunc
    const typeofDomesticFunc = typeof domesticFunc

    if (typeOfIntlFunc === 'function') {
        if (typeofDomesticFunc === 'function') {
            return (...args) => getIfScopeInternational() ? internationalFunc.call(null, ...args) : domesticFunc.call(null, ...args)
        } else {
            throw `传入scopeFunction的参数 d 要求是function，但得到的是 ${typeofDomesticFunc}`
        }
    } else {
        throw `传入scopeFunction的参数 i 要求是function，但得到的是 ${typeOfIntlFunc}`
    }
}

/**
 * 使用范围：函数
 * 目的：当前搜索国际航班，就返回参数中 i 字段对应函数， 搜索国内航班，就返回参数中 d 字段对应函数，
 *      二者都不能为空，且类型要求是function，通过args传参，直接执行该函数
 */
export const scopeFunctionAndExec = ({ i: internationalFunc, d: domesticFunc }, ...args) => {
    const func = scopeFunction({ i: internationalFunc, d: domesticFunc })
    return func(...args)
}