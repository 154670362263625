import React from 'react'
import { List } from 'immutable'
import Cursor from 'immutable-cursor'
import Converter from '../../../../sources/common/converter'
import { PRICE_TAG_TYPE } from '../../../../constants/list/enums/priceTagType';
import { TRANSIT_VISA_LUGGAGE_TIP_TYPE } from '../../../../constants/common/enum/common';

const getIsDifferrentTerminal = (currentFlight, nextFlight) => {
    //当前航班到达的机场
    const currentArrivalAirportCode = currentFlight.get('arrivalAirportCode'),
        currentArrivalAirportTerminal = currentFlight.get('arrivalTerminal'),
        // 下一段航班到达的机场
        nextDepartureAirportCode = nextFlight.get('departureAirportCode'),
        nextDepartureTerminal = nextFlight.get('departureTerminal')
    return currentArrivalAirportCode == nextDepartureAirportCode && currentArrivalAirportTerminal != nextDepartureTerminal
}

/**
 * 将航程信息进行包装
 */
export const wrapTheSegment = (segment, currentSegmentSeq, sortContainsTax, totalSegmentCount, theMinPriceItem, priceList) => {
    return segment
        .withMutations(seg => {
            let flightListCursor = Cursor.from(seg, 'flightList', newSeg => seg = newSeg),
                getFlightListOf = (prop) => flightListCursor.deref().map(f => f.get(prop)).reduce((prev, next) => prev.concat(next), List([])).toSet().toList(),
                airlineList = getFlightListOf('marketAirlineCode'),
                flightNoList = getFlightListOf('flightNo'),
                stopTextList = [],
                addToStopTextList = (tag, {cityName, airportName,buildingShortName, duration}, isDifferentTerminal = false) => {
                    stopTextList.push({
                        tag,
                        cityName,
                        airportName,
                      buildingShortName,
                        duration,
                        isDifferentTerminal
                    })
                },
                minAvgPriceWithTax = theMinPriceItem.get('avgPriceWithTax'),
                minAvgPriceWithoutTax = theMinPriceItem.get('avgPriceWithoutTax'),
                minAvgTax = theMinPriceItem.get('avgTax'),
                minMiseryIndex = priceList.map(t => t.get('eligibility') !== 'NOR' ? Infinity : t.get('miseryIndex')).min(), //全程最小痛苦指数，排除掉有资质运价
                curSegmentMinMiseryIndex = priceList.map(t => t.get('eligibility') !== 'NOR' ? Infinity : t.get('curSegmentMiseryIndex', Infinity)).min(), //当前程最小痛苦指数，排除掉有资质运价
                priceCount = priceList.size, //当前航班的运价数量
                theMinPriceCount = priceList.filter(t => t.get('avgPriceWithTax') == minAvgPriceWithTax).size, // 最低运价的数量
                // 换乘总时长
                totalTransferDuration = Converter.formatMinAsDayHourMin(segment.get("flightList").reduce((total, flight) => { return total + flight.get('transferDuration') }, 0))
            theMinPriceItem.get('priceTagEnums').forEach((item) => {
                if (item.tag === PRICE_TAG_TYPE.CaSpecialDiscount) {
                    minAvgPriceWithTax += item.data
                    minAvgPriceWithoutTax += item.data
                }
            })

          flightListCursor.deref().map((transFlight, index) => {
                let innerStopList = transFlight.get('stopList')
                if (innerStopList.size) {
                    innerStopList.forEach((innerStop, _innerIndex) => {

                        addToStopTextList('停',{
                          cityName:innerStop.get('cityName'),
                          airportName:innerStop.get('airportName'),
                          buildingShortName:innerStop.get('buildingShortName'),
                          duration:Converter.formatMinAsDayHourMinForShort(innerStop.get('duration'))
                        })
                    })
                }

                if (index < flightListCursor.deref().size - 1) {
                    const isDifferrentTerminal = getIsDifferrentTerminal(transFlight, flightListCursor.deref().get(index + 1))
                  // 香港隔离
                  const HKIsolation = transFlight.get('isolationDescription')
                    ? (<span style={{color:'#FF7700'}}>{transFlight.get('isolationDescription')}</span>)
                    : null;
                  addToStopTextList('转', {
                      cityName:transFlight.get('arrivalCityName'),
                      airportName:'',
                      buildingShortName:transFlight.get('buildingShortName'),
                      duration:HKIsolation || Converter.formatMinAsDayHourMinForShort(transFlight.get('transferDuration')),
                    }, isDifferrentTerminal);
                }
            })
          return seg
                .set('isLastSegment', currentSegmentSeq === totalSegmentCount - 1)
                .set('airlineCount', airlineList.size)
                .set('flightNoCount', flightNoList.size)
                .set('durationDayHourMin', Converter.formatMinAsDayHourMin(seg.get('duration')))
                .set('durationHourMin', Converter.formatMinAsHourMin(seg.get('duration')))
                .set('stopTextList', stopTextList)
                .set('sortContainsTax', sortContainsTax)
                .set('minAvgTax', minAvgTax)
                .set('minMiseryIndex', minMiseryIndex)
                .set('curSegmentMinMiseryIndex', curSegmentMinMiseryIndex)
                .set('minAvgPriceWithTax', minAvgPriceWithTax)
                .set('minAvgPriceWithoutTax', minAvgPriceWithoutTax)
                .set('theMinPriceItem', theMinPriceItem)
                .set('priceList', priceList)
                .set('priceCount', priceCount)
                .set('theMinPriceCount', theMinPriceCount)
                .set('totalTransferDuration', totalTransferDuration)
                .set('transferAndStopCount', seg.get('transferCount') + seg.get('stopCount'))
                .update('transVisaLuggageTipTitle', (_title) => {
                    //存在多票的运价
                    let getTransVisaLuggageTipTitle = () => seg.get('transVisaLuggageTipEnums').size && seg.get('transVisaLuggageTipEnums').some(tipEnum => tipEnum === TRANSIT_VISA_LUGGAGE_TIP_TYPE.VISA) ? '过境需签证' : ''

                    return getTransVisaLuggageTipTitle()
                })
        })
}
