import Enum from 'enum'

/**
 * 乘客资质
 */
export const FILTER_ELIGIBILITY_TYPE = new Enum({
    EMI: {
        title: "移民"
    },
    LAB: {
        title: "劳务人员"
    },
    NEW: {
        title: "新移民"
    },
    NOR: {
        title: "普通乘客"
    },
    SEA: {
        title: "海员"
    },
    STU: {
        title: "留学生"
    },
    CHD: {
        title: "儿童"
    },
    TAI: {
        title: "台商"
    },
    YOU: {
        title: "青年"
    },
    SNR: {
        title: "老年人"
    },
    INF: {
        title: "婴儿"
    },
    VFR: {
        title: "探亲访友"
    },
    WOM: {
        title: "女士"
    }
});