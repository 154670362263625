import { lightUpload } from '../../sources/common/lightUbt';

export const goLogin = (callBack) => {
	window.CLogin.authBiz.isLogin({}, function (result) {
		// 0表示成功，510未登录，其他异常
		const returnCode = result ? result.returnCode : null;
		// strictStopQuickLoginSwitch 如果打开，则要求不能是快速登录
		// CLogin登录api的相关文档：http://conf.ctripcorp.com/pages/viewpage.action?pageId=156615771
		if (returnCode === 0) {
			callBack && callBack();
		} else {
			window.CLogin.Mask.showView(
				'',
				function () {
					window.location.reload();
				},
				false,
				false,
				true,
				false
			);
			lightUpload('O_Online_fanpadengluye', {});
		}
	});
};
