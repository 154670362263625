
import Immutable from "immutable"
import { ListUbt } from './listUbt'
import { getSearchCityText } from '../components/list/top/search'

 class SearchHistoryUbt extends ListUbt {
    constructor(props) {
        super(props)
        // 埋点key
        this.SEARCH_HISTORY_UBT_KEY = "fltintl.searchhistory"
    }

    reportSearchHistory() {
        let infos = [[],[],[]];
        window.GlobalSearchCriteria.flightSegments.forEach(seg => {
            let depInfo = getSearchCityText(Immutable.fromJS(seg), "departure"),
                arrInfo = getSearchCityText(Immutable.fromJS(seg), "arrival")
            infos[0].push(`${depInfo.name}|${depInfo.text}|2|${depInfo.code}|${seg.timeZone}`);
            infos[1].push(`${arrInfo.name}|${arrInfo.text}|2|${arrInfo.code}|${seg.timeZone}`);
            infos[2].push(`${seg.departureDate}`);
        })
        // TODO get UID window.Ubt.UID ||
        this.reportToApi(`UID=${ ''}&FlightWay=${window.GlobalSearchCriteria.flightWay}&from=${infos[0].join(',')}&to=${infos[1].join(',')}&DepartDate=${infos[2].join(',')}`, this.SEARCH_HISTORY_UBT_KEY)
    }
}

export default new SearchHistoryUbt()
