import React from 'react'
import { fetchPost } from '../common'
import CONFIG_URL from '../../constants/list/api'
import LIST_CONSTS from '../../constants/list/constConfig'
import { regWindowEvent } from '../../sources/common/regWindowEvent'
import { UbtHandler } from '../../ubt.v2/handler'

let ngsCache = {},
    ngsInProgress = {}

const getNgsLabel = score => score > 0 ? (
    <React.Fragment>
        <span className='ngs-label'>
            <span className='current-score'>{score}</span>
            <span className='total-score'>/6分</span>
        </span>
    </React.Fragment>) : null
const getNgsContent = score => score > 0 ? (
    <div className='ngs-content'>
        <div>
            <span className='score-header'>
                {score}
                <span className='header-total-score'>/6分</span>
                {score <= 2 ? '一般' : (score <= 4 ? '棒' : '超棒！')}
                <span className='ngs-logo' />
            </span>
        </div>
        <div className='score-remark'>
            {LIST_CONSTS.PRICE.NGS_TIP_REMARK}
        </div>
    </div>) : null

regWindowEvent('beforeunload', () => {
    const scoresMap = Object.keys(ngsCache).reduce((prev, next) => {
        const cache = ngsCache[next]
        if (cache && cache.score) {
            if (!prev[cache.score]) {
                prev[cache.score] = 0
            }

            prev[cache.score] = prev[cache.score] + 1
        }

        return prev
    }, {})

    Object.keys(scoresMap).forEach(score => {
        UbtHandler.onUploadData(`c_price_ngs_scores_${score}`, scoresMap[score])
    })
})

export const getNgsPromise = (price, searchCriteriaToken, airlineCodes) => () => {
    const routeSearchToken = price.get('routeSearchToken'),
        searchParam = {
            routeSearchToken,
            searchCriteriaToken
        }

    return new Promise((resolve, _reject) => {
        const showNgsAirlines = LIST_CONSTS.PRICE.SHOW_NGS_SCORE_AIRLINES || []   //需要显示NGS打分的航司
        const currentAirlineShowNgs = airlineCodes && airlineCodes.length && showNgsAirlines.length
            && ((showNgsAirlines.length === 1 && showNgsAirlines[0] === '*') || airlineCodes.some(airlineCode => airlineCode && showNgsAirlines.includes(airlineCode)))

        if (routeSearchToken && searchCriteriaToken && currentAirlineShowNgs) {
            let cacheKey = routeSearchToken

            if (ngsCache[cacheKey]) {
                resolve(ngsCache[cacheKey])
            } else {
                let isTheFirstOne = false
                if (!ngsInProgress[cacheKey]) {
                    ngsInProgress[cacheKey] = []
                    isTheFirstOne = true
                }

                ngsInProgress[cacheKey].push(resolve)

                if (isTheFirstOne) {
                    fetchPost(CONFIG_URL.getNgs, searchParam)
                        .then(res => res ? res.data : null)
                        .then(json => {
                            if (json && json.data) {
                                let score = json.data,
                                    el = [getNgsLabel(score), getNgsContent(score)],
                                    ext = { score }

                                ngsCache[cacheKey] = { score, el, ext }

                                ngsInProgress[cacheKey].forEach(theResolve => theResolve({ el, ext }))
                                delete ngsInProgress[cacheKey]
                            }
                        })
                }
            }
        } else {
            resolve({ el: [null, null], ext: { score: 0 } })
        }
    })
}

export const getNgsDataFromCache = routeSearchToken => ngsCache[routeSearchToken]
