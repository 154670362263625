import mergeConfig from '../mergeConfig';
import Q_CONFIG_KEYS from '../../book/common/qConfigKeys';

// 报销凭证图片
const INVOICE_TYPE = {
	T: {
		title: '行程单或电子发票',
		link: '//pic.c-ctrip.com/flight/book/e_invoice/travel-itinerary.png',
		desc: '行程单或电子发票',
		content: '可提供报销凭证类型为“行程单或电子发票”，可根据报销需求选择任一类型，凭证金额以实际开具金额为准，支付成功后可在订单中申请凭证，行程单需在起飞后28天内申请。',
	},
	F: {
		title: '电子发票',
		link: 'https://pic.c-ctrip.com/flight_intl/book/invoice.png',
		desc: '电子发票',
		className: '',
		content: '可提供报销凭证类型为“电子发票”，凭证金额以实际开具金额为准（优惠券已优惠金额，不计入报销金额内），支付成功后可在订单中申请凭证。',
	},
	E: {
		title: '境外电子凭证',
		link: 'https://pic.c-ctrip.com/flight_intl/book/e_invoice.png',
		desc: '',
	},
	ZZFP: {
		title: '纸质发票',
		link: 'https://pic.c-ctrip.com/flight_intl/book/invoice.png',
		desc: '全额发票',
	},
	NONE: {
		title: '',
		link: '',
	},
	// 国内专用，行程单 + 发票，图片地址用|分割
	T_F: {
		title: '非全额行程单+差额发票',
		link: '//pic.c-ctrip.com/flight/book/e_invoice/travel-itinerary.png|https://pic.c-ctrip.com/flight_intl/book/invoice.png',
		desc: '<span class="yellowColor">"非全额行程单+差额发票"</span>',
		className: 'yellowColor',
	},
};

export default mergeConfig(Q_CONFIG_KEYS.INVOICE_TYPE_CONFIG, INVOICE_TYPE);
