import React, { FC, useEffect } from 'react';
import { List } from 'immutable';
import { sendBlockTrace } from '@/src/utils/sendBlockTrace';
import { BlockTypeValue, InterruptTypeValue, MainTypeValue, SubTypeValue } from '@/src/utils/types';

import PopDialog from '../../common/PopDialog.js';
import { UbtHandler } from '../../../../ubt.v2/list';
import { PopContents } from './PopContents';

type NoticePopProps = {
	notices: List<any>;
	isShowDialog: boolean;
	onConfirm: () => void;
};

const NoticePop: FC<NoticePopProps> = ({ notices, onConfirm, isShowDialog }) => {
	useEffect(() => {
		if (notices && notices.size > 0 && isShowDialog) {
			const contentList = notices
				.reduce((prev, next) => {
					// @ts-expect-error
					prev.push(next.get('content'));
					return prev;
				}, [])
				.join(';');
			UbtHandler.onUploadData('show_crital_warn_dialog', {
				remark: '显示紧急通告弹窗',
				content: contentList
			});
			sendBlockTrace({
				blockType: BlockTypeValue.Pop,
				interruptType: InterruptTypeValue.Block,
				mainType:  MainTypeValue.Search,
				subType: SubTypeValue.RedNotice,
			});
		}
	}, [notices, isShowDialog]);

	if (notices && notices.size > 0 && isShowDialog) {
		return (
			<PopDialog
				contents={
					<PopContents
						{...{
							notices,
							onConfirm
						}}
					/>
				}
			/>
		);
	} else {
		return null;
	}
};

export default NoticePop;
