import React, { useState, useMemo } from 'react';
import moment from 'moment';
import { sendVetTrace } from '@/src/sources/common/lightUbt';
import City from './City';
import ModifyDateInfo from '../modifyDateInfo';
import { DATE_PICKER_TITLE, DATE_PICKER_FOOTER_TIPS } from '../../../constants/common/enum/common';
import { popNextFormItem } from '../../../utils/commonUtils';
import PassengerCountContainer from './PassengerCountContainer';
import { SEARCH_FORM_INPUTS, GEN_SEARCH_FORM_ANIMATION } from '../../../constants/common/enum/searchForm';
import DateUtil from '../../../sources/common/dateUtil';
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import { RoundTripDays } from './RtDays';

let OwAndRt = ({ ...props }) => {
	const OW_DCITY_NAME = 'owDCity',
		OW_ACITY_NAME = 'owACity';
	const {
		flightWay,
		hotCountryList,
		getHotDistrictsOfTheCityNow,
		hasHotDistrictOfTheDepartCityNow,
		showCityPicker,
		exchangeCity,
		owDDate,
		dDateError,
		showOwAndRtValidateErrorTip,
		classGrade,
		onFetchLowPrice,
		fetchLowRPrice,
		selectedCity,
		lowPrice,
		lowRPrice,
		lowestPrice,
		onRtStartDateChange,
		onRtEndDateChange,
		workingDays,
		rtADate,
		aDateError,
		dCityError,
		aCityError,
		// onCityFocus,
		lowestRPrice,
		onSubmitPassengerTypeCount,
		isDistrictSearch,
		arrivalDistrict,
		fetchCountryLowPrice,
		countryLowestPrice,
		countryLowPrice,
		countryLowestRPrice,
		countryLowRPrice,
		dCityFocus,
		aCityFocus,
		dDateFocus,
		aDateFocus,
		onSetActiveExchangeCity,
		activeExchangeCity,
		onClearFormInputList,
		focusInputList,
		onFousFormInput,
		owADateFocus,
		onSetOwADateFocus,
		dCityHasVal,
		aCityHasVal,
		onSetDCityActive,
		onSetACityActive,
		dCityActive,
		aCityActive,
		onNextMomthClick,
		onPrevMomthClick,
		priceMapSwitch,
		changePoi,
		clickHotDistrict,
		changeToDistrictSwitch,
		changeArrivalDistrict,
		onDistrictError,
		clearPoiItem,
		showWarn,
		cancelFocusMtSecondDCity,
		autoFocus,
		domestic,
		passenger,
	} = props;

	let getCityText = (name) => {
		let city = selectedCity.get(name);
		if (city) {
			return city.keySeq().size > 0
				? city.get('text')
					? city.get('text')
					: `${city.get('Name')}(${city.get('Code')})`
				: '';
		} else {
			return '';
		}
	};

	let getRtCityText = (name) => {
		if (isDistrictSearch) {
			return arrivalDistrict.areaName;
		} else {
			return getCityText(name);
		}
	};
	let dCityText = getCityText(OW_DCITY_NAME),
		aCityText = getRtCityText(OW_ACITY_NAME),
		enableExchangeCity = !isDistrictSearch && dCityText && aCityText,
		handleExchangeCityClick = (enableExchangeCity) => {
			if (enableExchangeCity) {
				onSetActiveExchangeCity(true);
				exchangeCity();
				setTimeout(() => {
					onSetActiveExchangeCity(false);
				}, 200);
			}
		};
	// 校验下rtAdate
	let aDate = useMemo(() => (rtADate && rtADate.isValid() ? rtADate : null), [rtADate]);

	let dCityShowCover = !dCityText && !dCityFocus && !dCityHasVal,
		aCityShowCover = !aCityText && !aCityFocus && !aCityHasVal,
		dDateShowCover = !owDDate && !dDateFocus,
		aDateShowCover =
			(!rtADate || !rtADate.isValid()) && !aDateFocus && (moment(owDDate).isAfter(aDate) || aDate == null);

	let datePickerShowLowPrice = () => {
		let { adtCount, chiCount, infCount } = passenger;

		return (
			(flightWay == 'OW' || flightWay == 'RT') &&
			selectedCity &&
			selectedCity.get(OW_DCITY_NAME) &&
			selectedCity.get(OW_ACITY_NAME) &&
			((adtCount === 1 && chiCount === 0 && infCount === 0) || domestic)
		);
	};
	let getTimeZone = (city) => {
		return (selectedCity.get(city) && selectedCity.getIn([city, 'TimeZone'])) || 480;
	};

	const [dCityHasSelect, setdCityHasSelect] = useState(false),
		[aCityHasSelect, setaCityHasSelect] = useState(false),
		[dDateHasSelect, setdDateHasSelect] = useState(false);

	let handleEnter = (el, isNextDistrictSearch) => {
		if (autoFocus === false) {
			return;
		}

		let needFocusNext = false;
		if (SEARCH_FORM_INPUTS.join('').indexOf(focusInputList.join('')) == 0 && focusInputList.length > 0) {
			needFocusNext = true;
		} else {
			needFocusNext = false;
			onClearFormInputList();
		}
		setaCityHasSelect(false);
		setdCityHasSelect(false);
		setdDateHasSelect(false);

		if (needFocusNext) {
			// 检查后续的input是否暴露 是否可点
			let timeout = 0;
			// 精选地区有一个动画，确保在动画之后锚定
			if (isNextDistrictSearch) {
				timeout = 300;
			}
			let focusInput = focusInputList[focusInputList.length - 1];
			if (focusInput == 'dCity') {
				setdCityHasSelect(true);
				timeout = 400;
			}

			if (focusInput == 'aCity') {
				setaCityHasSelect(true);
				onSetDCityActive(null);
				timeout = 150;
			}
			if (focusInput == 'dDate') {
				setdDateHasSelect(true);
				onSetACityActive(null);
			}

			let elForm = window.document.getElementById('searchForm');
			setTimeout(() => {
				popNextFormItem(el, elForm, 'form-item-v3', true);
			}, timeout);
		}
	};
	let transfromDateInfoSelecteCityData = () => {
		if (!isDistrictSearch || !arrivalDistrict) {
			return null;
		}
		let newSelectedCityData = {};
		if (selectedCity) {
			if (!newSelectedCityData.aCity) {
				newSelectedCityData.aCity = {};
			}
			if (!newSelectedCityData.dCity) {
				newSelectedCityData.dCity = {};
			}
			newSelectedCityData.aCity[`countryCode`] = arrivalDistrict.areaCode;
			let dCity = flightWay == 'RT' ? selectedCity.getIn(['rtDCity']) : selectedCity.getIn(['owDCity']);
			if (dCity && dCity.get)
				newSelectedCityData.dCity[`code`] =
					dCity &&
					(dCity.get('type') == 'airport' || dCity.get('Code').indexOf('0') > 0) &&
					dCity.get('ParentCode')
						? dCity.get('ParentCode')
						: dCity.get('Code');
		}

		return newSelectedCityData;
	};

	let onAddRtDateClick = () => {
		onSetOwADateFocus(true);
	};
	let onACityFocus = () => {
		// const useNewPoiSwitcher = window.GlobalSwitches.useNewPoiSwitcher;
		// !useNewPoiSwitcher && onCityFocus();
		onFousFormInput('aCity', true);
	};
	let getDateTips = (date) => {
		if (date) {
			let tips = DateUtil.getDateTipText(date);
			if (tips.length <= 2) {
				return (
					<span className="week" key="1">
						{tips}
					</span>
				);
			} else if (tips.length >= 3) {
				return (
					<span className="festival" key="1">
						{tips}
					</span>
				);
			}
		} else {
			return null;
		}
	};

	let dCityErrorAndACityError = dCityError && aCityError,
		dDateErrorAndADateError = dDateError && aDateError;

	// 是否显示往返天数
	const [showRtDays, setShowRtDays] = useState(false);

	let isDAirport = false,
		departCity = null,
		departCityCode;
	if (flightWay === 'OW') {
		departCity = selectedCity.get('owDCity') || null;
	} else if (flightWay === 'RT') {
		departCity = selectedCity.get('rtDCity') || null;
	}
	isDAirport = departCity && departCity.get('type') ? departCity.get('type') === 'airport' : false;
	departCityCode = departCity && departCity.get('Code') ? departCity.get('Code') : '';
	if (isDAirport) {
		departCityCode = departCity.get('ParentCode') ? departCity.get('ParentCode') : '';
	}

	return (
		<div className="form-line">
			<div className="form-item-v3-group-wrap">
				<div
					className={`form-item-v3-group  ${dCityActive || aCityActive ? 'active' : ''}  ${
						dCityErrorAndACityError && showOwAndRtValidateErrorTip ? 'error' : ''
					}`}
				>
					<span className="error-hint">{showOwAndRtValidateErrorTip && dCityErrorAndACityError}</span>
					<div
						className={`form-item-v3 flt-depart ${
							dCityActive ? 'active' : ''
						} ${GEN_SEARCH_FORM_ANIMATION.CITY(dCityActive, {
							move: dCityHasSelect,
							hide: aCityHasSelect,
						})}  ${dCityError && showOwAndRtValidateErrorTip ? 'error' : ''} ${
							isDistrictSearch ? 'flt-date-X-animation' : ''
						} ${dCityShowCover ? 'none-value' : ''}`}
					>
						<label className="form-label-v3">出发地</label>
						{showOwAndRtValidateErrorTip && dCityError && !dCityErrorAndACityError ? (
							<span className="error-hint">{dCityError}</span>
						) : null}
						<UbtBoundary
							tracker={SearchBoxUbtGenerator(
								SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{
									type: 'cityInput',
									cityType: 'depart',
									text: '出发地',
									value: { dCityText }.dCityText,
								}
							)}
						>
							<City
								key={1}
								departCityCode={departCityCode}
								mode="D"
								label={dCityShowCover ? '' : '可输入城市或机场'}
								name={OW_DCITY_NAME}
								changePoi={changePoi}
								inputClass={'form-input-v3'}
								errorMsg={''}
								cityText={dCityText}
								flightWay={flightWay}
								id="search"
								handleEnter={handleEnter}
								hotCountryList={hotCountryList}
								getHotDistrictsOfTheCityNow={getHotDistrictsOfTheCityNow}
								hasHotDistrictOfTheDepartCityNow={hasHotDistrictOfTheDepartCityNow}
								isDistrictSearch={isDistrictSearch}
								arrivalDistrict={arrivalDistrict}
								priceMapSwitch={priceMapSwitch}
								newChannel={true}
								outsideClassName={'owrt_outside'}
								focusSecond={showCityPicker && showCityPicker.get(OW_DCITY_NAME)}
								onInputFocus={() => {
									onFousFormInput('dCity', true);
								}}
								onInputBlur={() => {
									onFousFormInput('dCity', false);
								}}
								onSetCityActive={onSetDCityActive}
								clickHotDistrict={clickHotDistrict}
								changeToDistrictSwitch={changeToDistrictSwitch}
								changeArrivalDistrict={changeArrivalDistrict}
								onDistrictError={onDistrictError}
								clearPoiItem={clearPoiItem}
								showWarn={showWarn}
								cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
							/>
						</UbtBoundary>
					</div>
					<div
						className={`switch-btn ${enableExchangeCity ? '' : 'disabled'} `}
						data-ubt-key="ChangeCity"
						onClick={() => {
							sendVetTrace({
								key: 'online_flight_switch_city_click_test',
							});
							handleExchangeCityClick(enableExchangeCity);
						}}
					>
						<div className={`switch-icon-wrap ${activeExchangeCity ? 'hover' : ''}`} name="exchangeBtn">
							<i className="iconf-exchange">&#xe66a;</i>
						</div>
					</div>

					<div
						className={`form-item-v3 flt-arrival ${isDistrictSearch ? 'flt-date-X-animation' : ''} ${
							aCityActive ? 'active' : ''
						} ${GEN_SEARCH_FORM_ANIMATION.ACITY(aCityActive, {
							move: aCityHasSelect,
							diaplayAnimate: dCityHasSelect && aCityActive,
							hide: dDateHasSelect,
						})} ${showOwAndRtValidateErrorTip && aCityError ? 'error' : ''} ${
							aCityShowCover ? 'none-value' : ''
						}`}
					>
						<label className="form-label-v3">目的地</label>

						{aCityError && showOwAndRtValidateErrorTip && !dCityErrorAndACityError ? (
							<span className="error-hint">{aCityError}</span>
						) : null}
						<UbtBoundary
							tracker={SearchBoxUbtGenerator(
								SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{
									type: 'cityInput',
									cityType: 'arrive',
									text: '目的地',
									value: { aCityText }.aCityText,
								}
							)}
						>
							<City
								key={2}
								mode="A"
								departCityCode={departCityCode}
								label={aCityShowCover ? '' : '可输入城市或机场'}
								name={OW_ACITY_NAME}
								inputClass={'form-input-v3'}
								errorMsg={''}
								cityText={aCityText}
								flightWay={flightWay}
								focusSecond={showCityPicker && showCityPicker.get(OW_ACITY_NAME)}
								id="search"
								changePoi={changePoi}
								handleEnter={handleEnter}
								hotCountryList={hotCountryList}
								getHotDistrictsOfTheCityNow={getHotDistrictsOfTheCityNow}
								hasHotDistrictOfTheDepartCityNow={hasHotDistrictOfTheDepartCityNow}
								outsideClassName={'owrt_outside'}
								onInputFocus={() => {
									onACityFocus();
								}}
								onInputBlur={() => {
									onFousFormInput('aCity', false);
								}}
								isDistrictSearch={isDistrictSearch}
								arrivalDistrict={arrivalDistrict}
								priceMapSwitch={priceMapSwitch}
								newChannel={true}
								onSetCityActive={onSetACityActive}
								clickHotDistrict={clickHotDistrict}
								changeToDistrictSwitch={changeToDistrictSwitch}
								changeArrivalDistrict={changeArrivalDistrict}
								onDistrictError={onDistrictError}
								clearPoiItem={clearPoiItem}
								showWarn={showWarn}
								cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
								domestic={domestic}
								selectorStyle={{
									marginLeft: '-18px',
								}}
							/>
						</UbtBoundary>
					</div>
				</div>
			</div>
			<div className="form-item-v3-group-wrap">
				<div
					id="datePicker"
					className={`form-item-v3-group ${
						flightWay == 'OW' && !owADateFocus ? 'form-item-v3-group-ow' : ''
					} ${dDateFocus || aDateFocus ? 'active' : ''} ${GEN_SEARCH_FORM_ANIMATION.BORDER_TOP(
						dDateFocus || aDateFocus
					)} ${dDateErrorAndADateError && showOwAndRtValidateErrorTip ? 'error' : ''}`}
				>
					<span className="error-hint" style={{ minWidth: '192px' }}>
						{showOwAndRtValidateErrorTip && dDateErrorAndADateError}
					</span>
					<div
						className={`form-item-v3 flt-date flt-date-depart ${
							dDateFocus ? 'active' : ''
						} ${GEN_SEARCH_FORM_ANIMATION.BORDER_TOP(dDateFocus)} ${
							showOwAndRtValidateErrorTip && dDateError ? 'error' : ''
						} ${dDateShowCover ? 'none-value' : ''}`}
					>
						<label className="form-label-v3">出发日期</label>
						{!dDateErrorAndADateError && showOwAndRtValidateErrorTip && dDateError ? (
							<span style={{ minWidth: '192px' }} className="error-hint">
								{dDateError}
							</span>
						) : null}
						<UbtBoundary
							tracker={SearchBoxUbtGenerator(
								SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{
									type: 'dateInput',
									text: '出发日期',
									value: moment(owDDate).format('YYYY-MM-DD'),
								}
							)}
						>
							<span>
								<ModifyDateInfo
									label={!dDateFocus && !owDDate ? '' : 'yyyy-mm-dd'}
									date={owDDate && owDDate.isValid() ? owDDate : null}
									start={owDDate && owDDate.isValid() ? owDDate : null}
									end={flightWay == 'RT' ? aDate : ''}
									max={moment().add(1, 'year')}
									id={`${OW_DCITY_NAME}Date${
										dDateError && showOwAndRtValidateErrorTip ? '_error' : ''
									}`}
									// errorMsg={dDateError}
									source="channel"
									showWeek={false}
									timeZone={getTimeZone(`${flightWay.toLowerCase()}DCity`)}
									flightWay={flightWay}
									mode="departTime"
									cabin={classGrade.toUpperCase()}
									fetchLowPrice={onFetchLowPrice}
									fetchLowRPrice={fetchLowRPrice}
									showLowPrice={datePickerShowLowPrice}
									selectedCity={selectedCity}
									lowPrice={lowPrice}
									lowRPrice={lowRPrice}
									lowestPrice={lowestPrice}
									onDateChange={onRtStartDateChange}
									title={DATE_PICKER_TITLE[flightWay].value.depart}
									footerTips={DATE_PICKER_FOOTER_TIPS.PRICE.value[flightWay].depart.concat(
										DATE_PICKER_FOOTER_TIPS.TIME.value[flightWay].depart
									)}
									onNextMomthClick={onNextMomthClick}
									onPrevMomthClick={onPrevMomthClick}
									workingDays={workingDays}
									selectedCityAndCountry={transfromDateInfoSelecteCityData()}
									fetchCountryLowPrice={fetchCountryLowPrice}
									countryLowestPrice={countryLowestPrice}
									countryLowPrice={countryLowPrice}
									showCountryLowPrice={isDistrictSearch}
									onInputFocus={() => {
										onFousFormInput('dDate', true);
									}}
									onInputBlur={() => {
										onFousFormInput('dDate', false);
									}}
									handleEnter={handleEnter}
									skin={'date-custom-v2'}
									offset={{ top: 6 }}
									readOnly={true}
									dateTips={[getDateTips(owDDate)]}
									positionEle={'#datePicker'}
									domestic={domestic}
									hideAnimateClass={
										flightWay == 'OW' ? GEN_SEARCH_FORM_ANIMATION.CALENDAR(false) : ''
									}
									showAnimateClass={GEN_SEARCH_FORM_ANIMATION.CALENDAR(true, {
										showAnimate: !aCityHasSelect,
									})}
								/>
							</span>
						</UbtBoundary>
					</div>
					<div className={`form-item-v3 flt-date ${flightWay != 'OW' ? 'hide-without-ainimate' : ''}`}>
						<div className="oneway">
							<a onClick={onAddRtDateClick}>+ 添加返程</a>
						</div>
					</div>
					<div
						id="owADatewrap"
						className={`form-item-v3 flt-date flt-date-arrival ${showRtDays ? 'show-rt-days' : ''} ${
							aDateFocus ? 'active' : ''
						} ${GEN_SEARCH_FORM_ANIMATION.CITY(aDateFocus)} ${
							aDateError && showOwAndRtValidateErrorTip ? 'error' : ''
						}   ${aDateShowCover ? 'none-value' : ''} ${flightWay == 'OW' ? 'hide-without-ainimate' : ''}`}
					>
						<RoundTripDays dDate={owDDate} aDate={aDate} onShow={setShowRtDays} flightWay={flightWay} />
						<label className="form-label-v3">返回日期</label>
						{!dDateErrorAndADateError && showOwAndRtValidateErrorTip && aDateError ? (
							<span className="error-hint">{aDateError}</span>
						) : null}
						<UbtBoundary
							tracker={SearchBoxUbtGenerator(
								SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK,
								SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK,
								{
									type: 'dateInput',
									text: '返回日期',
									value: moment(aDate).format('YYYY-MM-DD'),
								}
							)}
						>
							<span>
								<ModifyDateInfo
									label={aDateFocus && !aDate ? 'yyyy-mm-dd' : ''}
									date={flightWay == 'RT' ? aDate : ''}
									start={owDDate && owDDate.isValid() ? owDDate : null}
									end={aDate}
									max={moment().add(1, 'year')}
									min={owDDate && owDDate.isValid() ? owDDate : null}
									id={`${OW_ACITY_NAME}Date${aDateError ? '_error' : ''}`}
									// errorMsg={aDateError}
									source="channel"
									mode="arrivalTime"
									cabin={classGrade.toUpperCase()}
									showWeek={false}
									timeZone={getTimeZone(`${flightWay.toLowerCase()}ACity`)}
									flightWay={flightWay}
									fetchLowPrice={onFetchLowPrice}
									fetchLowRPrice={fetchLowRPrice}
									showLowPrice={datePickerShowLowPrice}
									lowRPrice={lowRPrice}
									lowestRPrice={lowestRPrice}
									selectedCity={selectedCity}
									onDateChange={(...args) => setTimeout(onRtEndDateChange.bind(null, ...args), 0)}
									title={DATE_PICKER_TITLE['RT'].value.arrival}
									footerTips={DATE_PICKER_FOOTER_TIPS.PRICE.value['RT'].arrival.concat(
										DATE_PICKER_FOOTER_TIPS.TIME.value['RT'].arrival
									)}
									onNextMomthClick={onNextMomthClick}
									onPrevMomthClick={onPrevMomthClick}
									workingDays={workingDays}
									// dateTips={[genOwRecommendRtTip()]}
									selectedCityAndCountry={transfromDateInfoSelecteCityData()}
									fetchCountryLowPrice={fetchCountryLowPrice}
									countryLowestRPrice={countryLowestRPrice}
									showCountryLowPrice={isDistrictSearch}
									countryLowRPrice={countryLowRPrice}
									needFocus={aDateFocus}
									onInputFocus={() => {
										onFousFormInput('aDate', true);
									}}
									onInputBlur={() => {
										onFousFormInput('aDate', false);
									}}
									handleEnter={() => {
										onSetOwADateFocus(false);
									}}
									showRangeOnMouseEnter={true}
									skin={'date-custom-v2'}
									offset={{ top: 6 }}
									readOnly={true}
									dateTips={[getDateTips(aDate)]}
									positionEle={'#datePicker'}
									domestic={domestic}
									hideAnimateClass={GEN_SEARCH_FORM_ANIMATION.CALENDAR(false)}
									showCalendar={owADateFocus}
								/>
							</span>
						</UbtBoundary>
					</div>
				</div>
			</div>
			{!isDistrictSearch ? (
				<div className="form-item-v3-group-wrap">
					<PassengerCountContainer
						domestic={domestic}
						passenger={passenger}
						flightWay={flightWay}
						dCityText={dCityText}
						aCityText={aCityText}
						cabinType={classGrade.toUpperCase()}
						onSubmitPassengerTypeCount={onSubmitPassengerTypeCount}
					/>
				</div>
			) : null}
		</div>
	);
};

export default OwAndRt;
