import { onResearchFlightsWithFullUrl } from '@/src/actions/list/getGlobalSearchCriteria';
import { setAdjacentRecommendType } from './SessionHandler';

const toNewListPageV2 = (url, ubtProps) => {
	if (ubtProps && Object.keys(ubtProps).length > 0) {
		setAdjacentRecommendType(ubtProps);
	}
	onResearchFlightsWithFullUrl(url);
};

export default toNewListPageV2;
