import { API_ROOT } from '../common';
import { countryLowPriceUrl, fuzzySearch, getCalendarLowestPrice } from './gatewayApi';

const configUrl = {
	// 重要公告
	getHomepageNotifyUrl: `${API_ROOT}/notify/getHomepageNotify`,
	// 最近订单
	getLatestOrdersUrl: `${API_ROOT}/homepage/getLatestBookingAirlines`,
	// 往返特价航线
	getReturnAirlinesUrl: `${API_ROOT}/lowprice/special/getRtSpecialRoutes`,
	// 单程航线城市列表
	getSingleAirlineLocationsUrl: `${API_ROOT}/lowprice/special/getOwSpecialArrivalCityList`,
	// 单程特价航线
	getSingleAirlinesUrl: `${API_ROOT}/lowprice/special/getOwSpecialRoutes`,
	// 特殊航班特价
	getSpecialAirlinesUrl: `${API_ROOT}/homepage/getSpecialAirlineList`,
	// 热门主题
	getHotTopicList: `${API_ROOT}/homepage/getHotTopicList`,
	// poi
	getPoi: `${API_ROOT}/poi/search`,

	getCalendarL: getCalendarLowestPrice,
	// 老版日历低价单程
	// getLowPriceUrl: `${API_ROOT}/lowprice/calendar/getOwCalendarPrices`,
	// 老版日历低价返程
	// getLowRPriceUrl: `${API_ROOT}/lowprice/calendar/get45DaysCalendarDetailList`,
	// getDomesticOwLowPriceUrl: `${API_ROOT}/lowprice/calendar/getDomesticSingleTripPriceCalendar`,
	// getDomesticRtLowPriceUrl: `${API_ROOT}/lowprice/calendar/getDomesticRoundTripPriceCalendar`,

	// 通过ip获取三字码
	getUserCityCodeUrl: `${API_ROOT}/ip/getUserCityCode`,
	// 获取高级搜索航司列表
	getAirlinesListUrl: `${API_ROOT}/airline/getAirlines`,
	// 获取热门国家信息
	getHotCountryListUrl: `${API_ROOT}/poi/getHotCountryList`,

	// 获取精选地区
	// districtListUrl,
	// 获取时区
	getTimeZoneBycityCode: `${API_ROOT}/poi/getCityTimeZone`,
	// 开关
	getSwitchOpened: `${API_ROOT}/switch/getSwitchOpened`,

	// 精选地区低价日历
	countryLowPriceUrl,

	getSearchHistoryList: `${API_ROOT}/homepage/getSearchHistoryList`,
	// 低价速报, 低价地图， 模糊搜索
	fuzzySearch,
	// 获取用户所在城市信息
	getRequestCityInfo: `${API_ROOT}/ip/getRequestCityInfo`,
	getUserResidenceCity: `${API_ROOT}/user/getUserResidenceCity`,
	getPreAggSearchId: `${API_ROOT}/search/getSearchId`,

	// 获取航司对应下关于出发地的特惠航线
	getAirlinePreferenceLines: `${API_ROOT}/lowprice/getRouteLowestPriceByAirline`,
};

export default configUrl;
