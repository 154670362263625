import { createSelector } from 'reselect'
import { currentSegmentSeqSelector, isBuildUpSelector, getSplitRoundFlightsSwitchSelector, getRoundTabModeSwitchSelector , searchFlightsIsFinishedSelector } from '../flight/baseSelector'
import { adjacentAirlineFormatSelector } from './adjacentAirlineSelector'
import { adjacentDateSelector } from './adjacentDateSelector'
import { showOpenJawRecommendDataWrapperSelector } from './openJawRecommendSelector'
import { flightTrainCombinationSelector } from './flightTrainCombinationSelector'
import { getDirectRecommendTab } from './directRecommendSelector'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector';

export const recommendVersionSelector = (state) => state.getIn(['list', 'recommend', 'recommendVersion'])

//是否显示推荐 B版 和 E版显示
export const isShowRecommendSelector = createSelector([recommendVersionSelector, searchFlightsIsFinishedSelector], (version, isfinish) => isfinish && (version == 'B' || version == 'E'))
//是否显示头部直飞推荐
export const isShowHeaderRecommendSelector = createSelector([recommendVersionSelector, searchFlightsIsFinishedSelector], (version, isfinish) => isfinish && version == 'B')

//是否有空铁
export const isExistFlightTrainSelector = createSelector(flightTrainCombinationSelector, flightTrain => flightTrain && flightTrain.size)

export const isFinishGetRecommendVersionSelector = createSelector(recommendVersionSelector, version => version)

export const existRecommendSelector = createSelector([adjacentAirlineFormatSelector, adjacentDateSelector, showOpenJawRecommendDataWrapperSelector, getDirectRecommendTab],
    (adjacentAirlineFormat, adjacentDate, openJawRecommendData, getDirectRecommendTab) => {
        return !!(adjacentAirlineFormat && adjacentAirlineFormat.size) ||
            !!(adjacentDate && adjacentDate.size) ||
            !!(openJawRecommendData && openJawRecommendData.size) ||
            !!(getDirectRecommendTab && getDirectRecommendTab.size)
    })

/**
 * 是否需要显示 无结果推荐 或者 需求单，需要满足下面几个条件：
 * 1）航班搜索结果为空
 * 2）已经查询完成
 */
export const getIfShowNoResultRecommendOrDemandFormSelector = createSelector([searchNoResultRecommendSelector],
    (emptyFlightsWhenFinished) => {
        return emptyFlightsWhenFinished
    })

// 只有在第一程 或者 往返组合 时候，才可能显示无结果推荐
export const getIfFirstSegmentSeqOrBuildUpSelector = createSelector([currentSegmentSeqSelector, isBuildUpSelector],
    (currentSegmentSeq, isBuildUp) => {
        return currentSegmentSeq === 0 || isBuildUp
    })

// 当前拿到可用来显示的无结果推荐航班
export const getIfAnyRecommendFlightsSelector = createSelector([adjacentAirlineFormatSelector, adjacentDateSelector, getDirectRecommendTab, isShowRecommendSelector],
    (adjacentAirline, adjacentDate, directRecommendList, isShowRecommend) => {
        // 如果有直飞推荐和临近低价推荐，则不出现需求单
        // 临近日期少于4个，又无结果的时候,展示需求单
        return isShowRecommend && ((adjacentAirline.size > 0 || adjacentDate.size >= 4) || directRecommendList.size > 0)
    })

// 无结果，是显示推荐 还是显示 需求单，返回true表示显示 推荐
export const getIfShowNoResultRecommendSelector = createSelector([getIfShowNoResultRecommendOrDemandFormSelector, getIfAnyRecommendFlightsSelector, getIfFirstSegmentSeqOrBuildUpSelector, getSplitRoundFlightsSwitchSelector],
    (emptyFlightsWhenFinished, anyRecommendFlights, firstSegmentSeqOrBuildUp, splitRoundFlightsSwitch) => {
        return emptyFlightsWhenFinished && anyRecommendFlights && firstSegmentSeqOrBuildUp && !splitRoundFlightsSwitch
    })

// 无结果，是显示推荐 还是显示 需求单，返回true表示显示 需求单
export const getIfShowDemandFormSelector = createSelector([getIfShowNoResultRecommendOrDemandFormSelector, getIfAnyRecommendFlightsSelector, getIfFirstSegmentSeqOrBuildUpSelector, getSplitRoundFlightsSwitchSelector],
    (emptyFlightsWhenFinished, anyRecommendFlights, firstSegmentSeqOrBuildUp, splitRoundFlightsSwitch) => {
        return emptyFlightsWhenFinished && !(anyRecommendFlights && firstSegmentSeqOrBuildUp && !splitRoundFlightsSwitch)
    })

// 是否往返tab模式
export const getIfShowRoundTabModeSelector = createSelector([getRoundTabModeSwitchSelector, getIfShowDemandFormSelector], (roundTabModeSwitch, showDemandForm) => {
    return roundTabModeSwitch && !showDemandForm
})