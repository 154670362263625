import Immutable, { List } from 'immutable';
import { createSelector } from 'reselect';
import { FilterOptionRetrieverFactory } from './filterOptionRetriever';

import {
	activeKeysSelector,
	bestChoiceFlightsForceTopSelector,
	containsTaxSelector,
	currentSegmentSeqSelector,
	filterActiveKeysSelector,
	filterTypesSelector,
	flightListSelector,
	flightTrainFlightsSelector,
	getSortTypesSelector,
	isBuildUpSelector,
	prevCondSelector,
	getSplitRoundFlightsSwitchSelector,
	selectedFlightsSelector,
	getIfSegmentCountAsPriceSortSwitchSelector,
	getTheMinPriceOfAllFlightsSelector,
} from '../../result/flight/baseSelector';
import { FILTER_GROUP_TRANS_COUNT, TRANS_COUNT_TYPE } from '../../../../constants/list/enums/filter';
import { getAllFilterTypes } from '../../../../sources/list/filterInit';
import { setMinPriceItemOfFlight } from '../../../../utils/commonUtils';
import { SORT_INDICATOR_TYPE } from '../../../../constants/list/enums/sortIndicatorType';
import { getIfPrevCondIsHighCabin } from '../../../../sources/list/highCabinRec';
import { getFlightAircraftSizeList } from '../../../../sources/list/getMainSegmentAirCraftSize';
import { getIfFlightAsLowPrice } from '../../../../sources/list/getIfFlightAsLowPrice';
import { convertFilterItemKeyAsString } from '../../../../sources/list/convertFilterItemKeyAsString';
import Converter from '../../../../sources/list/converter';
import { PRICE_SORT_TYPE_GROUP } from '../../../../constants/list/enums/domesticPriceSortTypeGroup';
import { getIfScopeDomestic } from '../../../../sources/list/scope';

/**
 * [S1]获取筛选选项航班组合信息，用来A和B的共享参数，避免重复计算
 */
const filterFlightsWrapperSelector = createSelector(
	[currentSegmentSeqSelector, flightListSelector, filterTypesSelector, prevCondSelector, isBuildUpSelector],
	(currentSegmentSeq, flightList, filterTypes, prevCond, isBuildUp) => {
		let filterTypesGroupsMap = Immutable.Map();

		return filterTypesGroupsMap.withMutations((map) => {
			filterTypes.valueSeq().forEach((filter) => {
				let filterType = filter.get('type'),
					theRetriever = FilterOptionRetrieverFactory.getInstance(filterType);

				map.set(filterType, theRetriever.getFlightsWrapper(flightList, currentSegmentSeq, prevCond, isBuildUp));
			});
		});
	}
);

/**
 * 生成筛选项结构
 */
const getFlatFilterGroupsSelector = createSelector(
	[
		currentSegmentSeqSelector,
		flightListSelector,
		filterFlightsWrapperSelector,
		filterTypesSelector,
		prevCondSelector,
		isBuildUpSelector,
	],
	(currentSegmentSeq, flightsOfGenFilterItem, flightsWrapper, filterTypes, prevCond, isBuildUp) => {
		let filterTypesGroupsMap = Immutable.Map();

		return filterTypesGroupsMap.withMutations((map) => {
			filterTypes.valueSeq().forEach((filter) => {
				let filterType = filter.get('type'),
					theRetriever = FilterOptionRetrieverFactory.getInstance(filterType);

				map.set(
					filterType,
					theRetriever.getFlatGroups(
						flightsOfGenFilterItem,
						flightsWrapper.get(filterType),
						currentSegmentSeq,
						prevCond,
						isBuildUp
					)
				);
			});
		});
	}
);

const getFilterGroupToAllKeysMapSelector = createSelector([getFlatFilterGroupsSelector], (flatFilterGroups) => {
	let filterGroupToItemKeysMap = Immutable.Map();
	flatFilterGroups.keySeq().forEach((filterType) => {
		flatFilterGroups
			.get(filterType)
			.keySeq()
			.forEach((groupType) => {
				filterGroupToItemKeysMap = filterGroupToItemKeysMap.set(
					filterType.value.groups.find((group) => group.type.key === groupType).type,
					flatFilterGroups.getIn([filterType, groupType, 'items']).map((item) => item.get('key'))
				);
			});
	});

	return filterGroupToItemKeysMap;
});

const setFilterResult = (flight, currentSegmentSeq, isBuildUpMode, flatFilterGroups) => {
	let flightFilterResult = {},
		priceList = flight.get('priceList'),
		priceListFilterResult = {};

	flatFilterGroups.keySeq().forEach((filterType) => {
		flatFilterGroups
			.get(filterType)
			.keySeq()
			.forEach((groupTypeKey) => {
				let groupType = filterType.value.groups.find((group) => group.type.key === groupTypeKey).type,
					groupTypeId = groupType.value.id,
					filterItems = flatFilterGroups.getIn([filterType, groupTypeKey, 'items']);

				filterItems.forEach((filterItem) => {
					let filterItemKeyString = convertFilterItemKeyAsString(filterItem),
						rawFilterItemKey = filterItem.get('key'),
						groupAndFilterItemUnionKey = `${groupTypeId}_${filterItemKeyString}`;

					flightFilterResult[groupAndFilterItemUnionKey] =
						flight.get('skipFilter') ||
						groupType.value.filterFlight(rawFilterItemKey, flight, currentSegmentSeq, isBuildUpMode);
					priceList.forEach((price, _priceIndex) => {
						//此处只要是推荐运价就不参与筛选，肯定是放过的，不论查询低舱还是高舱，因为推荐运价的舱等不展现到舱等筛选框中，且对运价的筛选目前只有舱等筛选框
						const routeSearchToken = price.get('routeSearchToken');
						if (!priceListFilterResult[routeSearchToken]) {
							priceListFilterResult[routeSearchToken] = {};
						}

						priceListFilterResult[routeSearchToken][groupAndFilterItemUnionKey] =
							price.get('isRecommendCabin') || groupType.value.filterPrice(rawFilterItemKey, price);
					});
				});
			});
	});

	flight = flight.update('priceList', (priceList) => {
		let priceListAfterUpdate = priceList;
		priceList.forEach((price, priceIndex) => {
			const routeSearchToken = price.get('routeSearchToken');

			priceListAfterUpdate = priceListAfterUpdate.setIn(
				[priceIndex, 'filterResult'],
				priceListFilterResult[routeSearchToken]
			);
		});

		return priceListAfterUpdate;
	});

	return flight.set('filterResult', flightFilterResult);
};
/**
 * 带过滤结果的航班集合
 */
export const flightsWithFilterResultSelector = createSelector(
	[currentSegmentSeqSelector, flightListSelector, isBuildUpSelector, getFlatFilterGroupsSelector],
	(currentSegmentSeq, flightList, isBuildUpMode, flatFilterGroups) => {
		return flightList.map((flight) => setFilterResult(flight, currentSegmentSeq, isBuildUpMode, flatFilterGroups));
	}
);

/**
 * 返回某个Group对应激活KEY的MAP
 */
export const getActiveKeysOfGroupMapSelector = createSelector(
	[activeKeysSelector, getFilterGroupToAllKeysMapSelector],
	(activeKeys, filterGroupToAllKeysMap) => {
		const allFilterTypes = getAllFilterTypes();
		let groupToItemsKeyMap = Immutable.Map();

		allFilterTypes.forEach((filterType) => {
			let activeKeysOfFilterType = activeKeys.get(filterType.key);

			filterType.value.groups.forEach((filterGroupType) => {
				const filterGroupKeys = filterGroupToAllKeysMap.get(filterGroupType.type);
				if (activeKeysOfFilterType && filterGroupKeys) {
					groupToItemsKeyMap = groupToItemsKeyMap.set(
						filterGroupType.type,
						activeKeysOfFilterType.toSet().intersect(filterGroupKeys)
					);
				} else {
					groupToItemsKeyMap = groupToItemsKeyMap.set(filterGroupType.type, Immutable.Set());
				}
			});
		});

		return groupToItemsKeyMap;
	}
);

/**
 * 返回舱等激活的筛选项，返回值是个数组：['Y', 'S']
 */
export const getClassGradeFilterEnumSelector = createSelector(
	[activeKeysSelector, getFilterGroupToAllKeysMapSelector],
	(activeKeys, filterGroupToAllKeysMap) => {
		const classGradeActiveKeys = activeKeys.get('CLASS_GRADE', Immutable.List());
		if (classGradeActiveKeys && classGradeActiveKeys.size) {
			const theClassGradeEnum = classGradeActiveKeys.get(0);

			const classGradeKey = filterGroupToAllKeysMap
				.keySeq()
				.find((k) => k.value.id === 'FILTER_GROUP_CLASS_GRADE.DEFAULT');
			if (classGradeKey) {
				const classGradeEnumItems = filterGroupToAllKeysMap.get(classGradeKey);
				if (
					classGradeEnumItems &&
					classGradeEnumItems.size &&
					classGradeEnumItems.find((item) => item.key === theClassGradeEnum.key)
				) {
					// 筛选项中存在才能传出去
					return theClassGradeEnum;
				} else {
					return null;
				}
			} else {
				return null;
			}
		} else {
			return null;
		}
	}
);

/**
 * 生成 friendFlights
 */
export const getFriendFlightsMapSelector = createSelector(
	[flightsWithFilterResultSelector, filterTypesSelector, getActiveKeysOfGroupMapSelector],
	(flightsWithFilterResult, filters, activeKeysOfGroupMap) => {
		const friendFlightsOfFilterGroups = {},
			allFilterTypes = getAllFilterTypes();
		allFilterTypes.forEach((currentFilterType) => {
			friendFlightsOfFilterGroups[currentFilterType.key] = {};
			currentFilterType.value.groups.forEach((filterGroup) => {
				let filterGroupType = filterGroup.type;

				friendFlightsOfFilterGroups[currentFilterType.key][filterGroupType] = flightsWithFilterResult.filter(
					(flight) => {
						//所有筛选组都要通过筛选逻辑，才能将该航班视为该航班对当前筛选分组有效，具体逻辑如下：
						//  1）依次遍历每个筛选组，如果是当前筛选组或在friendGroups里面就跳过，否则继续
						//  2）如果当前遍历组部分选中筛选项可以通过当前筛选逻辑，就当校验通过，否则继续
						//  3）如果当前遍历组存在friendGroups，则遍历该 friendGroups，如果部分 friendGroups 部分选中筛选项不为空且可以校验通过，就当校验通过（比如：直飞校验通过，就可以不校验 中转次数）,否则继续
						//  4）校验不通过
						return filters.every((filter) => {
							let filterType = filter.get('type');

							return filterType.value.groups.every((otherGroup) => {
								let otherGroupType = otherGroup.type;
								//根据过滤项的GroupType设定 friendType ，用于禁用项的逻辑判断
								if (otherGroupType === filterGroupType) {
									return true;
								} else {
									//指定Group是否允许某航班显示
									let isGroupTypeAllowFlightVisible = (groupType) => {
										let activeKeysInGroup = activeKeysOfGroupMap.get(groupType);

										//如果一个group一条筛选项都没有选中，就不应该影响其他group获取friendFlights，进而影响可用性
										//当前group不应该影响过滤结果，直接置空
										return (
											!!activeKeysInGroup &&
											doFilterFlight(
												groupType,
												activeKeysInGroup,
												activeKeysOfGroupMap.set(filterGroupType, Immutable.List()),
												flight
											)
										);
									};

									return isGroupTypeAllowFlightVisible(otherGroupType);
								}
							});
						});
					}
				);
			});
		});

		return friendFlightsOfFilterGroups;
	}
);

/**
 * 判断可用性的航班
 */
export const filterFriendFlightsSelector = createSelector(
	[getFriendFlightsMapSelector, filterTypesSelector],
	(friendFlightsMap, filters) => {
		let theFriendFlightsMap = Immutable.Map();

		filters.forEach((filter) => {
			let theFilterType = filter.get('type'),
				friendsFlightsInFilterType = friendFlightsMap[theFilterType];

			theFilterType.value.groups.forEach((filterGroup) => {
				const friendFlights =
					friendsFlightsInFilterType && friendsFlightsInFilterType[filterGroup.type]
						? friendsFlightsInFilterType[filterGroup.type]
						: Immutable.List();

				theFriendFlightsMap = theFriendFlightsMap.set(filterGroup.type, friendFlights);
			});
		});

		return theFriendFlightsMap;
	}
);

/**
 * 如果某个FilterGroup部分选项选中后是否筛选航班为显示状态
 */
const doFilterFlight = (currentGroupType, activeKeysInGroup, activeKeysOfGroupMap, flight) => {
	// 1) 如果这个 FilterGroup 没有选中，则该航班可以显示（既然用来判断就是可见航班），否则继续
	return (
		activeKeysInGroup.size === 0 ||
		activeKeysInGroup.some((theActiveKey) => {
			let theActiveKeyString = convertFilterItemKeyAsString(theActiveKey),
				currentGroupTypeId = currentGroupType.value.id;

			// 2） 该Group的部分选中筛选项可以筛选成功，并且筛选成功后的可见运价里，存在至少一条运价可以通过其他Group的选中项的筛选
			//     比如当前是判断舱等的Group，这个航班要能够显示，需要该航班的至少一个运价舱等过滤OK，还要已选资质、已选运价渠道过滤OK
			return (
				flight.get('filterResult')[`${currentGroupTypeId}_${theActiveKeyString}`] &&
				flight.get('priceList').some(
					(price) =>
						price.get('filterResult')[`${currentGroupTypeId}_${theActiveKeyString}`] &&
						activeKeysOfGroupMap.keySeq().every((groupType) => {
							let innerActiveKeysOfGroupMap = activeKeysOfGroupMap.get(groupType);

							return (
								groupType === currentGroupType ||
								innerActiveKeysOfGroupMap.size === 0 ||
								innerActiveKeysOfGroupMap.some((theInnerActiveKey) => {
									let theInnerActiveKeyString = convertFilterItemKeyAsString(theInnerActiveKey);
									return price.get('filterResult')[
										`${groupType.value.id}_${theInnerActiveKeyString}`
									];
								})
							);
						})
				)
			);
		})
	);
};

/**
 * 计算筛选项的可用性
 */
const filtersEnableSelector = createSelector(
	[getFlatFilterGroupsSelector, filterFriendFlightsSelector, getActiveKeysOfGroupMapSelector],
	(flatFilterGroups, friendFlights, activeKeysOfGroupMap) => {
		let theEnableResult = {},
			getFilterTypeFromKey = (filterType, filterGroupKey) =>
				filterType.value.groups.find((group) => group.type.key === filterGroupKey).type;

		flatFilterGroups.keySeq().forEach((filterType) => {
			theEnableResult[filterType] = {};
			let theGroupsVal = flatFilterGroups.get(filterType);

			theGroupsVal.keySeq().forEach((filterGroupKey) => {
				theEnableResult[filterType][filterGroupKey] = {};
				let theItems = theGroupsVal.getIn([filterGroupKey, 'items']),
					groupType = getFilterTypeFromKey(filterType, filterGroupKey),
					friendFlightsOfGroupType = friendFlights.get(groupType),
					//拿到某个 Group 的 friendFlights 之后，需要检测该 Group 中是否任何 filterItem 与 部分 friendFlights 筛选不为空
					theActiveKeyAllowAnyVisibleFlight = (activeKey) =>
						friendFlightsOfGroupType.some((flight) => {
							return doFilterFlight(groupType, Immutable.List([activeKey]), activeKeysOfGroupMap, flight);
						});

				theItems.forEach((filterItem) => {
					let theKey = convertFilterItemKeyAsString(filterItem);

					theEnableResult[filterType][filterGroupKey][theKey] = theActiveKeyAllowAnyVisibleFlight(filterItem);
				});
			});

			//将某FilterType里面的所有Group的ITEM可用性计算完后，需要对那些辅助性筛选组可用性重新计算，
			//比如选择 航司联盟、中转城市国家等筛选项，本身不过滤任何航班，而是依靠对应筛选项进行过滤
			//  航司联盟A，选中后就会选中航司A1，A2，A3，那A1，A2，A3就会过滤航班，A不会过滤，A1，A2，A3可能禁掉，但A永远不会禁掉，因为上面的 filterFlight 方法永远返回true
			//  对于这类筛选GROUP，有个专门附加的方法判断，其实就是判断A1，A2，A3是否都已经禁掉，来决定是否把A禁掉
			theGroupsVal.keySeq().forEach((filterGroupKey) => {
				let theItems = theGroupsVal.getIn([filterGroupKey, 'items']);
				theItems.forEach((filterItem) => {
					let theKey = convertFilterItemKeyAsString(filterItem),
						groupType = getFilterTypeFromKey(filterType, filterGroupKey),
						enableResultOfFilterType = theEnableResult[filterType],
						otherGroupKeys = Object.keys(enableResultOfFilterType).filter((key) => key !== filterGroupKey);

					if (groupType.value.getItemEnable) {
						let enableItemKeysInOtherGroup = otherGroupKeys
							.map((groupKey) =>
								Object.keys(enableResultOfFilterType[groupKey]).filter(
									(itemKey) => enableResultOfFilterType[groupKey][itemKey]
								)
							)
							.reduce((prev, next) => prev.concat(next), []);

						theEnableResult[filterType][filterGroupKey][theKey] = groupType.value.getItemEnable(
							filterItem,
							enableItemKeysInOtherGroup
						);
					}
				});
			});
		});

		return theEnableResult;
	}
);

/**
 * 过滤可见航班
 */
export const filterVisibleFlightsSelector = createSelector(
	[
		prevCondSelector,
		flightsWithFilterResultSelector,
		getActiveKeysOfGroupMapSelector,
		containsTaxSelector,
		filtersEnableSelector,
	],
	(prevCond, flights, activeKeysOfGroupMap, containsTax, filtersEnableMap) => {
		const allFilterTypes = getAllFilterTypes();

		return flights
			.filter((flight) => {
				let theFilterResult = Immutable.Map();
				allFilterTypes.forEach((filterType) => {
					const filtersEnableMapOfGroup = filtersEnableMap[filterType.key],
						filterGroupKeys = Object.keys(filtersEnableMapOfGroup),
						flatFilterGroupKeysOfEnable = filterGroupKeys.reduce(
							(prev, next) =>
								prev.concat(
									Object.keys(filtersEnableMapOfGroup[next]).filter(
										(key) => filtersEnableMapOfGroup[next][key]
									)
								),
							[]
						);

					filterType.value.groups.forEach((filterGroupType) => {
						let groupType = filterGroupType.type,
							activeKeysInGroup = activeKeysOfGroupMap
								.get(groupType)
								.map((filterKey) => convertFilterItemKeyAsString(filterKey)),
							activeKeysOfEnableInGroup = activeKeysInGroup.intersect(
								Immutable.Set(flatFilterGroupKeysOfEnable)
							);

						if (activeKeysOfEnableInGroup.size === 0) {
							theFilterResult = theFilterResult.set(groupType, true);
						} else {
							theFilterResult = theFilterResult.set(
								groupType,
								doFilterFlight(groupType, activeKeysOfEnableInGroup, activeKeysOfGroupMap, flight)
							);
						}
					});
				});

				return allFilterTypes.every((filterType) => {
					return filterType.value.groups.every((filterGroupType) => {
						let groupType = filterGroupType.type;

						return theFilterResult.get(groupType);
					});
				});
			})
			.map((flight) => {
				let visiblePriceList = filterVisiblePriceList(flight, activeKeysOfGroupMap);
				return visiblePriceList.size
					? setMinPriceItemOfFlight(prevCond, visiblePriceList, flight, containsTax)
					: null;
			})
			.filter(Boolean);
	}
);

/**
 * 为可见航班设置最低价信息
 */
// export const getVisibleFlightsWithMinPriceSelector = createSelector([prevCondSelector, filterVisibleFlightsSelector, containsTaxSelector, getActiveKeysOfGroupMapSelector], (prevCond, visibleFlights, containsTax, activeKeysOfGroupMap) => {
//     return visibleFlights.map(flight => {
//         let visiblePriceList = filterVisiblePriceList(flight, activeKeysOfGroupMap)
//         return setMinPriceItemOfFlight(prevCond, visiblePriceList, flight, containsTax)
//     })
// })

/**
 * 为所有航班设置最低价信息
 */
export const getAllFlightsWithMinPriceSelector = createSelector(
	[prevCondSelector, flightListSelector, flightTrainFlightsSelector, containsTaxSelector],
	(prevCond, nonVirtualFlights, virtualFlights, containsTax) => {
		return nonVirtualFlights.concat(virtualFlights).map((flight) => {
			return setMinPriceItemOfFlight(prevCond, flight.get('priceList'), flight, containsTax);
		});
	}
);

// 计算当前页面所有可见航班外露的最低价
export const getTheMinPriceOfAllVisibleFlightsSelector = createSelector(
	[filterVisibleFlightsSelector],
	(visibleFlights) => {
		// totalPrice(含税价格) price(不含税价格)
		let flightsWithoutTrain = visibleFlights.filter((flight) => {
			const theMinPriceItemSortTypeGroupEnum = flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']);
			return theMinPriceItemSortTypeGroupEnum && theMinPriceItemSortTypeGroupEnum.key != 'OTHER_TRANSFER_TYPE';
		});
		let totalPrice = Math.min(
			...flightsWithoutTrain.map((flight) => flight.getIn(['theMinPriceItem', 'totalPriceWithTax']))
		);
		let price = Math.min(...flightsWithoutTrain.map((flight) => flight.getIn(['theMinPriceItem', 'totalPrice'])));

		return Immutable.fromJS({ totalPrice, price });
	}
);

/**
 * 执行运价筛选逻辑
 * @param {*航班} flight
 * @param {*选中的筛选KEY} activeKeys
 */
export const filterVisiblePriceList = (flight, activeKeysOfGroupMap) => {
	return flight.get('priceList').filter((price) => {
		return activeKeysOfGroupMap.keySeq().every((groupType) => {
			let innerActiveKeysOfGroupMap = activeKeysOfGroupMap.get(groupType);

			return (
				innerActiveKeysOfGroupMap.size === 0 ||
				innerActiveKeysOfGroupMap.some((theInnerActiveKey) => {
					let theInnerActiveKeyString = convertFilterItemKeyAsString(theInnerActiveKey);
					return price.get('filterResult')[`${groupType.value.id}_${theInnerActiveKeyString}`];
				})
			);
		});
	});
};

const filtersWrapperSelector = createSelector(
	[
		prevCondSelector,
		flightListSelector,
		filterTypesSelector,
		filterActiveKeysSelector,
		getFlatFilterGroupsSelector,
		filtersEnableSelector,
		isBuildUpSelector,
	],
	(prevCond, flightList, filterTypes, filterActiveKeys, flatFilterGroups, enableGroups, isBuildUp) => {
		return filterTypes.valueSeq().map((filter) => {
			let theRetriever = FilterOptionRetrieverFactory.getInstance(filter.get('type'));
			return filter.withMutations((theFilter) => {
				let theFilterType = filter.get('type'),
					theEnable = enableGroups[theFilterType],
					flatGroups =
						flatFilterGroups.size && flatFilterGroups.get(theFilterType)
							? flatFilterGroups.get(theFilterType)
							: List(),
					renderFunc = theFilterType.value.render;

				flatGroups.forEach((flatGroup, theIndex) => {
					let theGroupType = flatGroup.get('type'),
						theGroupVal = theEnable[theGroupType];

					flatGroups = flatGroups.update(theIndex, (theFlatGroup) => {
						let updatePriceAndEnable = (key) => {
							let theItems = theFlatGroup.get(key);
							if (theItems) {
								theItems.forEach((item, theItemIndex) => {
									//筛选框的KEY可能是枚举或者字符串
									let theKey =
										typeof item.get('key') === 'string' ? item.get('key') : item.get('key').key;
									theFlatGroup = theFlatGroup.setIn(
										[key, theItemIndex, 'enable'],
										theGroupVal[theKey]
									);
								});
							}
						};

						updatePriceAndEnable('items');

						return theFlatGroup;
					});
				});

				return theFilter
					.set('groups', flatGroups)
					.set('visible', theRetriever.getVisible(isBuildUp, prevCond.get('flightWay'), flightList))
					.set('activeKeys', filterActiveKeys.get(theFilterType.key))
					.set('clickHandler', theRetriever.getClickHandler())
					.set('render', renderFunc);
			});
		});
	}
);

const getSortIndicatorsOfStatusParamsSelector = createSelector(
	[containsTaxSelector, isBuildUpSelector, currentSegmentSeqSelector, getIfSegmentCountAsPriceSortSwitchSelector],
	(containsTax, isBuildUp, currentSegmentSeq, segmentCountAsPriceSortSwitch) => {
		return { containsTax, isBuildUp, currentSegmentSeq, segmentCountAsPriceSortSwitch };
	}
);

/**
 * 计算每个航班对应的排序因子
 */
export const getVisibleFlightsWithSortIndicatorsSelector = createSelector(
	[
		prevCondSelector,
		filterVisibleFlightsSelector,
		currentSegmentSeqSelector,
		getSortTypesSelector,
		isBuildUpSelector,
		containsTaxSelector,
		getSortIndicatorsOfStatusParamsSelector,
		getTheMinPriceOfAllFlightsSelector,
	],
	(
		prevCond,
		flights,
		currentSegmentSeq,
		sortTypes,
		isBuildUp,
		containsTax,
		sortIndicatorsOfStatusParams,
		minPrice
	) => {
		let allSortIndicatorTypes = [];
		sortTypes.forEach((sortType) => {
			const addToAllSortIndicatorTypes = (containsTax) => {
				let sortIndcatorsWithTax = sortType.getSortIndicatorsOfStatus({
					...sortIndicatorsOfStatusParams,
					containsTax,
				});
				sortIndcatorsWithTax.forEach((sortIndicatorList) => {
					sortIndicatorList.forEach((sortIndicator) => {
						const { type: sortIndicatorType } = sortIndicator;
						if (allSortIndicatorTypes.indexOf(sortIndicatorType) < 0) {
							allSortIndicatorTypes.push(sortIndicatorType);
						}
					});
				});
			};

			//将含税/不含税的排序因子信息都添加
			addToAllSortIndicatorTypes(true);
			addToAllSortIndicatorTypes(false);
		});

		let newFlights = [];
		//计算所有航班对每个排序因子的结果
		flights.valueSeq().forEach((flight) => {
			let sortIndicators = {};
			allSortIndicatorTypes.forEach((indicatorType) => {
				sortIndicators[indicatorType] = SORT_INDICATOR_TYPE[indicatorType](
					prevCond,
					flight,
					currentSegmentSeq,
					isBuildUp,
					containsTax
				);
			});

			newFlights.push(flight.set('sortIndicators', sortIndicators));
		});

		return Immutable.fromJS(newFlights);
	}
);

/**
 * 已经激活的排序项信息，目前只有一个激活状态的排序项，默认是 直飞优先 排序
 */
export const getActiveSortTypesSelector = createSelector(
	[getSortTypesSelector, getSortIndicatorsOfStatusParamsSelector],
	(sortTypes, sortIndicatorsOfStatusParams) => {
		return sortTypes.filter((sortTypeEntry) => {
			const statusList = sortTypeEntry.getSortIndicatorsOfStatus(sortIndicatorsOfStatusParams),
				activeIndex = sortTypeEntry.activeIndex;

			return statusList && statusList[activeIndex] && statusList[activeIndex].length; //排序因子长度为0，表示不需要根据该类型计算排序，也就是非激活状态的排序
		});
	}
);

export const getActiveSortKeySelector = createSelector([getActiveSortTypesSelector], (activeSortTypes) => {
	if (activeSortTypes && activeSortTypes.length) {
		return activeSortTypes[0].key;
	} else {
		return '';
	}
});

/**
 * 当前激活的是否直飞优先排序
 */
export const getIfDirectFlightSortSelector = createSelector([getActiveSortKeySelector], (activeKey) => {
	return activeKey === 'direct_flight';
});

/**
 * 当前激活的是否精选优先排序
 */
export const getIfBestChoiceSortSelector = createSelector([getActiveSortKeySelector], (activeKey) => {
	return activeKey === 'best_choice';
});

/**
 * 将可见航班进行排序
 */
export const getSortedVisibleFlightsSelector = createSelector(
	[
		getVisibleFlightsWithSortIndicatorsSelector,
		getActiveSortTypesSelector,
		currentSegmentSeqSelector,
		isBuildUpSelector,
		prevCondSelector,
		bestChoiceFlightsForceTopSelector,
		getSortIndicatorsOfStatusParamsSelector,
	],
	(
		flights,
		activeSortTypes,
		currentSegmentSeq,
		isBuildUp,
		prevCond,
		bestChoiceFlightsForceTop,
		sortIndicatorsOfStatusParams
	) => {
		if (!activeSortTypes.length) {
			return flights;
		} else {
			const theActiveSortType = activeSortTypes[0], //目前只会有一个激活状态的排序项
				sortIndicators =
					theActiveSortType.getSortIndicatorsOfStatus(sortIndicatorsOfStatusParams)[
						theActiveSortType.activeIndex
					],
				sortIndicatorValueList = {}, //将当前激活项的每个排序因子进行计算，每个航组对应一个数组：    { "CA123": [3, 5.1, 4] }
				getFlightId = (flight) => {
					const isTongcheng = !!flight.get('tongChengFlightEnum');
					return `${flight.get('itineraryId')}${isTongcheng ? '-tongcheng' : ''}`;
				};

			flights.forEach((flight) => {
				sortIndicatorValueList[getFlightId(flight)] = sortIndicators.map((sortIndicator) => {
					return sortIndicator.weight * flight.getIn(['sortIndicators', sortIndicator.type]);
				});
			});

			//排序分两个阶段：
			// 第一阶段 是常规排序，就是根据 sortIndicators 的大小正常排序
			// 第二阶段 是将部分精选航班置顶 (只有查询条件不是两舱才会出现精选航班)
			let sortedFlights = flights.sort((prevFlight, nextFlight) => {
				let prevFlightIndicatorValueList = sortIndicatorValueList[getFlightId(prevFlight)], //该航组对应一个排序因子计算结果对应数组，[1.5, 4.2, 1.1]
					nextFlightIndicatorValueList = sortIndicatorValueList[getFlightId(nextFlight)], //同上
					sort = (index) => {
						if (prevFlightIndicatorValueList.length > index) {
							let delta = prevFlightIndicatorValueList[index] - nextFlightIndicatorValueList[index];
							// 每个排序项会有多个待排序计算因子，排在前的因子优先级更高，
							// 所以前面计算因子不一致(delta不等于0)就直接得到比较结果，
							// 否则继续比较下一个排序因子(如果已经是最后一个排序因子，就直接返回0，即两个航班等同顺序)
							if (delta === 0 && prevFlightIndicatorValueList.length > index + 1) {
								return sort(index + 1);
							} else {
								return delta;
							}
						} else {
							console.error(`排序因子计算有误`);
							return 0;
						}
					};

				return sort(0);
			});

			// 此处是对精选航班置顶
			// 只有查询条件不是两舱才会出现精选航班
			const prevCondIsHighCabin = getIfPrevCondIsHighCabin(prevCond);
			if (!prevCondIsHighCabin && bestChoiceFlightsForceTop) {
				const forceTopBestChoiceFlightsSorts =
					theActiveSortType.getForceTopBestChoiceFlightsSorts()[theActiveSortType.activeIndex];
				if (forceTopBestChoiceFlightsSorts && forceTopBestChoiceFlightsSorts.length) {
					// 依照优先级遍历查找各类精选航班，累计查找到的航班(注意直飞优先排序时，要求置顶航班是直飞)数目大于或等于3就可以停止遍历
					// 再将前三个航班置顶
					// 此处需要注意的是，对于 当日低价、直飞低价、便宜省时 精选航班标签，可能因为对应的运价被筛选掉而隐藏该标签，也就不再考虑该航班的置顶
					let forceTopFlightIndexList = [],
						searchIndex = 0;

					while (forceTopFlightIndexList.length < 3 && searchIndex < forceTopBestChoiceFlightsSorts.length) {
						const currentSearchBestChoiceType = forceTopBestChoiceFlightsSorts[searchIndex];
						sortedFlights.valueSeq().forEach((flight, index) => {
							let itineraryTagEnums = flight.get('itineraryTagEnums'); //站点返回的行程标签，需要过滤下看当前显示的运价是否符合该航班精选标签，比如直飞低价等
							itineraryTagEnums = itineraryTagEnums.filter((itineraryTagEnum) => {
								const fullfill = itineraryTagEnum.tag.value.fullfill;
								if (typeof fullfill === 'function') {
									const priceList = flight.get('priceList');
									return fullfill(itineraryTagEnum.data, priceList);
								} else {
									return true;
								}
							});

							sortedFlights = sortedFlights.set(
								index,
								flight
									.set('itineraryTagEnums', itineraryTagEnums)
									.update('itineraryTags', (itineraryTags) => {
										return itineraryTags.filter((tag) => {
											return itineraryTagEnums.some((tagEnum) => {
												return tagEnum.tag.key === tag.get('type');
											});
										});
									})
							);

							if (
								forceTopFlightIndexList.length < 3 &&
								itineraryTagEnums.some((tagEnum) => {
									return (
										tagEnum.tag.key === currentSearchBestChoiceType &&
										theActiveSortType.doFilterIfForceTopBestChoiceFlight(
											flight,
											currentSegmentSeq,
											isBuildUp
										)
									);
								})
							) {
								forceTopFlightIndexList.push(index);
							}
						});

						searchIndex++;
					}

					if (forceTopFlightIndexList.length) {
						let toForceTopFlightList = [];

						for (let index = 0; index < forceTopFlightIndexList.length; index++) {
							const toForceTopFlightIndex = forceTopFlightIndexList[index], //保持原来顺序进行置顶，排在后面的置顶后依旧在后面
								indexLessThanCurrentCount = forceTopFlightIndexList.filter(
									(prevFlightIndex, dataIndex) =>
										prevFlightIndex < toForceTopFlightIndex && dataIndex < index
								).length;

							toForceTopFlightList.push(
								sortedFlights.get(toForceTopFlightIndex - indexLessThanCurrentCount)
							); //将需要置顶的航班抽出来

							sortedFlights = sortedFlights.splice(toForceTopFlightIndex - indexLessThanCurrentCount, 1);
						}

						sortedFlights = Immutable.List(toForceTopFlightList).concat(sortedFlights);
					}
				}
			}

			return sortedFlights;
		}
	}
);

/**
 * 可见航班数目
 */
const visibleFlightsCountSelector = createSelector([filterVisibleFlightsSelector], (flights) => {
	return flights.count();
});

/**
 * 可见航班中的机型集合，目前只有  L, M, S 三种机型
 */
const visibleFlightsAirCraftSizeListSelector = createSelector(
	[filterVisibleFlightsSelector, currentSegmentSeqSelector],
	(flights, currentSegmentSeq) => {
		let allAirCraftSizes = [];

		flights.forEach((flight) => {
			const airCraftSizeList = getFlightAircraftSizeList(flight, currentSegmentSeq);
			airCraftSizeList.forEach((airCraftSize) => {
				if (airCraftSize && allAirCraftSizes.indexOf(airCraftSize) < 0) {
					if (getIfFlightAsLowPrice(flight)) {
						allAirCraftSizes.push(airCraftSize);
					}
				}

				if (allAirCraftSizes.length >= 3) {
					return false;
				}
			});
		});

		return allAirCraftSizes;
	}
);

const visibleDirectFlightsCountSelector = createSelector(
	[filterVisibleFlightsSelector, currentSegmentSeqSelector, isBuildUpSelector],
	(flights, currentSegmentSeq, isBuildUp) => {
		return flights
			.filter((flight) =>
				FILTER_GROUP_TRANS_COUNT.TRANS_COUNT.value.filterFlight(
					TRANS_COUNT_TYPE.ZERO,
					flight,
					currentSegmentSeq,
					isBuildUp
				)
			)
			.count();
	}
);

/**
 * 功能：获取直飞航班
 * 作者：sjp
 */
const visibleDirectFlightsSelector = createSelector(
	[filterVisibleFlightsSelector, currentSegmentSeqSelector, isBuildUpSelector],
	(flights, currentSegmentSeq, isBuildUp) => {
		return flights.filter((flight) =>
			FILTER_GROUP_TRANS_COUNT.TRANS_COUNT.value.filterFlight(
				TRANS_COUNT_TYPE.ZERO,
				flight,
				currentSegmentSeq,
				isBuildUp
			)
		);
	}
);
/**
 * 功能：获取直飞最低价
 * 作者：sjp
 */
export const getVisibleDirectFlightsWithMinPriceSelector = createSelector(
	[prevCondSelector, visibleDirectFlightsSelector],
	(prevCond, visibleFlights) => {
		let target = visibleFlights
			.map((flight) => {
				return setMinPriceItemOfFlight(prevCond, flight.get('priceList'), flight, true);
			})
			.sort((a, b) => a.getIn(['totalOfMinPriceWithTax', 'avg']) - b.getIn(['totalOfMinPriceWithTax', 'avg']));
		if (target) {
			let lowTarget = target.getIn(['0', 'totalOfMinPriceWithTax']);
			if (lowTarget) return lowTarget.get('ADULT') + lowTarget.get('CHILD') + lowTarget.get('INFANT');
		}
		return 0;
	}
);

const getSelectedFiltersSelector = createSelector([filtersWrapperSelector], (filters) => {
	let ret = [];
	filters.valueSeq().forEach((filter) => {
		Converter.getFilterItemsForPreviewSelector(filter)
			.valueSeq()
			.forEach((item) => {
				ret.push(item.get('titleForPreview'));
			});
	});
	return ret;
});
const selectedFlightWithFilterResultSelector = createSelector(
	[
		currentSegmentSeqSelector,
		selectedFlightsSelector,
		isBuildUpSelector,
		getFlatFilterGroupsSelector,
		getSplitRoundFlightsSwitchSelector,
	],
	(currentSegmentSeq, selected, isBuildUpMode, flatFilterGroups, splitRoundFlightsSwitch) => {
		let flight = selected.get('flights');
		if (!splitRoundFlightsSwitch || !flight) return selected;
		return selected.update('flights', (_) =>
			setFilterResult(flight, currentSegmentSeq, isBuildUpMode, flatFilterGroups)
		);
	}
);
/**
 * 功能：分屏显示获取已选航班信息 增加最低价过滤
 * 作者：sjp
 */
export const getSelectedFlightSelector = createSelector(
	[
		prevCondSelector,
		getSplitRoundFlightsSwitchSelector,
		selectedFlightWithFilterResultSelector,
		containsTaxSelector,
		getActiveKeysOfGroupMapSelector,
	],
	(prevCond, splitRoundFlightsSwitch, selected, containsTax, activeKeysOfGroupMap) => {
		let flight = selected.get('flights');
		if (!splitRoundFlightsSwitch || !flight) return selected;
		let visiblePriceList = filterVisiblePriceList(flight, activeKeysOfGroupMap);
		return selected.set('flights', setMinPriceItemOfFlight(prevCond, visiblePriceList, flight, containsTax));
	}
);
// 过滤可以在侧边栏显示的航班类型（更多出行方案，中转组合）
export const getVisibleFlightsSegmentTypeGroupSelector = createSelector([filterVisibleFlightsSelector], (flights) => {
	let segmentTypeGroupTypes = {};
	getIfScopeDomestic() &&
		PRICE_SORT_TYPE_GROUP.enums.map((enumItem) => {
			const key = enumItem.key,
				showInSideBar = enumItem.value.showInSideBar;
			if (showInSideBar) {
				segmentTypeGroupTypes[key] = {
					...enumItem.value,
				};
				segmentTypeGroupTypes[key]['count'] = flights.reduce((totalCount, flight) => {
					if (flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).value.key == key) {
						totalCount += 1;
					}
					return totalCount;
				}, 0);
			}
		});
	return segmentTypeGroupTypes;
});
export const getVisiblePriceSortTypeGroupSiezeSelector = createSelector(
	[getVisibleFlightsSegmentTypeGroupSelector],
	(visiblePriceSortTypeGroup) => {
		return Object.keys(visiblePriceSortTypeGroup).reduce((size, key) => {
			if (visiblePriceSortTypeGroup[key].count > 0) {
				size = size + 1;
			}
			return size;
		}, 0);
	}
);

export {
	getFlatFilterGroupsSelector,
	filtersWrapperSelector,
	visibleFlightsCountSelector,
	visibleDirectFlightsCountSelector,
	visibleFlightsAirCraftSizeListSelector,
	getSelectedFiltersSelector,
};
