import React, { useMemo } from 'react';
import Image, { ImageWrapProps } from '@/src/components/ImageWrap';

type VirtualLogoSize = '32' | '48' | '72';

type VirtualLogoProps = Override<
	ImageWrapProps,
	{
		trafficType?: string;
		size: VirtualLogoSize;
		src?: string;
		alt?: string;
	}
>;

type VirtualLogoSrcProps = {
	trafficType?: string;
};

const VIRTUAL_AIR_LOGO_PREFIX = 'https://pic.c-ctrip.com/AssetCatalog/biz/flightship/';

const VIRTUAL_FLIGHT_ICON = {
	SEAPLANE: `icon_ship3.png`,
	TRAIN: `icon_train.png`,
	BUS: `icon_bus3.png`,
};

/**
 * 获取去你航班logo地址
 */
const useVirtualLogoSrc = ({ trafficType }: VirtualLogoSrcProps) =>
	useMemo(() => {
		if (trafficType) {
			const icon = VIRTUAL_FLIGHT_ICON[trafficType.toUpperCase()];
			if (icon) {
				return VIRTUAL_AIR_LOGO_PREFIX + icon;
			}
		}
		return undefined;
	}, [trafficType]);

const VirtualLogo = ({ trafficType, size, ...props }: VirtualLogoProps) => {
	const src = useVirtualLogoSrc({ trafficType });
	if (!src) {
		return null;
	}
	const onAirlineLogoNotFound = (event) => {
		event.target.onerror = null;
		event.target.src = `https://static.tripcdn.com/packages/flight/airline-logo/latest/airline/${size}/default.png`;
	};

	return <Image {...props} src={src} alt={`${trafficType}图标`} onError={onAirlineLogoNotFound} />;
};

export default VirtualLogo;
