import moment from 'moment';
import * as TYPES from '../../constants/list/actionTypes';
import { fetchPostWithHeaders, getIfRoundTabModeSwitchFromGlobal } from '../common';
import URL from '../../constants/list/api';
import {
  onFetchFlights,
  fetchSearchFlights,
  genSetLastSearchTime,
  genSetFilterClassGrade,
  resetRouteSearchKey,
  resetSearchPullKey,
} from './search';
import CONSTS from '../../constants/list/constConfig';
import { UbtHandler } from '../../ubt.v2/list';
import { genAntiCrawlerHeader } from '../../sources/list/genAntiCrawlerHeader';

export const genBeforeRouteSearch = (clearFlights) => ({ type: TYPES.FLIGHT_BEFORE_ROUTE_SEARCH, clearFlights });
const genSelectedFlights = (selectedflight, targetSegmentSeq, routeSearchCriteria) => ({
  type: TYPES.SELECTED_FLIGHTS,
  selectedflight,
  targetSegmentSeq,
  routeSearchCriteria,
});
export const genUpdateSelectedFlight = (flights, seq) => ({ type: TYPES.UPDATE_SELECTED_FLIGHT, flights, seq });

let isSearch = false,
  lastSearchTime = moment('2018-01-01'),
  ROUTE_SEARCH_TIME_OUT = 1000,
  filterCabins = [];   //各航程舱等筛选状态

let currentRouteSearchKey = +new Date();

export const onRouteSearch = (selectedflight, routeSearchCriteria, prevCond, targetSegmentSeq, isBuildUpMode, classGradeFilterEnum) => dispatch => {
  if (!isSearch || moment().diff(lastSearchTime) > ROUTE_SEARCH_TIME_OUT) {

    const splitRoundFlightsSwitch = getIfRoundTabModeSwitchFromGlobal();
    if (!splitRoundFlightsSwitch) { //非分屏则在反查时候禁掉普通搜索
      resetSearchPullKey();
    }

    const searchKey = resetRouteSearchKey();
    const flightWay = prevCond.get('flightWay');

    let toReplaceClassGradeFilterEnum = null;

    // 往返分屏，目标是返程
    if (flightWay === 'D' && targetSegmentSeq === 1) {
      filterCabins = [];
    }

    if (targetSegmentSeq > filterCabins.length) {
      // 点击 航班 的预订按钮进行反查
      // 如果当前是搜索高舱，则需要将预订航班最低价舱等替换掉 筛选框已经选中舱等 进行上传到站点，但不应该替换前端筛选框
      const isHighCabin = prevCond.get('cabinEnum').value.isHighCabin;
      if (isHighCabin && selectedflight) {
        toReplaceClassGradeFilterEnum = selectedflight.getIn(['theMinPriceItem', 'mainCabinEnum']);
      }

      filterCabins.push(classGradeFilterEnum);
    } else {
      // 点击 修改航班 重新反查已选航班，需要将之前选中的舱等重新显示到筛选框中
      dispatch(genSetFilterClassGrade(filterCabins[targetSegmentSeq] || ''));
      filterCabins.splice(targetSegmentSeq);
    }

    const filterCabinsParams = (toReplaceClassGradeFilterEnum ? filterCabins.slice(0, filterCabins.length - 1).concat(toReplaceClassGradeFilterEnum) : filterCabins).reduce((prev, next, nextIndex) => {
      const cabins = next && next.value.includeKeys.length === 1 ? next.value.includeKeys[0] : '';
      return ({ ...prev, [nextIndex + 1]: cabins });
    }, {});

    isSearch = true;
    lastSearchTime = moment();
    routeSearchCriteria = routeSearchCriteria?.set('segmentNo', targetSegmentSeq + 1);

    dispatch(genSetLastSearchTime());

    if (targetSegmentSeq == 0) {
      // 回到第一程不走反查逻辑
      dispatch(genBeforeRouteSearch(true));

      dispatch(fetchSearchFlights(prevCond, 0, false, true));
      dispatch(genSelectedFlights(selectedflight, 0, routeSearchCriteria));
    } else {

      let header = genAntiCrawlerHeader(prevCond);
      dispatch(genBeforeRouteSearch(false));

      routeSearchCriteria = routeSearchCriteria?.set('filterCabins', filterCabinsParams);
      dispatch(genSelectedFlights(selectedflight, targetSegmentSeq, routeSearchCriteria));
      currentRouteSearchKey = +new Date();
      routeSearchCriteria = routeSearchCriteria?.set('roundTrip', true);
      ((theSearchKey) => {
        fetchPostWithHeaders(URL.routeSearch, routeSearchCriteria, header)
          .then(res => {
            const json = res.data;
            if (theSearchKey === currentRouteSearchKey) {
              if (json && json.data && json.data.flightItineraryList) {
                isSearch = false;
                prevCond = prevCond.set('segmentNo', targetSegmentSeq + 1);
                onFetchFlights({dispatch, prevCond, currentSegmentSeq: targetSegmentSeq, json, pullDelay: CONSTS.SEARCH.FIRST_PULL_DELAY, searchKey, isBuildUpMode, isRouteSearch:true});
              } else {
                dispatch(genSelectedFlights(null, 0, routeSearchCriteria));
                UbtHandler.onUploadData('c_normal_search_error', { data: json });
                if(!res?.isLogin) {
                  alert('页面数据加载失败，请重新搜索。');
                }
              }
            }
          }).catch(err => {
          if (theSearchKey === currentRouteSearchKey) {
            isSearch = false;
          }

          UbtHandler.onUploadData('route_search_error', err);
        });
      })(currentRouteSearchKey);
    }
  }
};

// 国际列表中间运价查询
export const onSearchIntlPriceList = (selectedflight, routeSearchCriteria, prevCond, targetSegmentSeq, isBuildUpMode, classGradeFilterEnum) => dispatch => {
  if (!isSearch || moment().diff(lastSearchTime) > ROUTE_SEARCH_TIME_OUT) {

    const splitRoundFlightsSwitch = getIfRoundTabModeSwitchFromGlobal();
    if (!splitRoundFlightsSwitch) { //非分屏则在反查时候禁掉普通搜索
      resetSearchPullKey();
    }

    const searchKey = resetRouteSearchKey();
    const flightWay = prevCond.get('flightWay');

    let toReplaceClassGradeFilterEnum = null;

    // 往返分屏，目标是返程
    if (flightWay === 'D' && targetSegmentSeq === 1) {
      filterCabins = [];
    }

    if (targetSegmentSeq > filterCabins.length) {
      // 点击 航班 的预订按钮进行反查
      // 如果当前是搜索高舱，则需要将预订航班最低价舱等替换掉 筛选框已经选中舱等 进行上传到站点，但不应该替换前端筛选框
      const isHighCabin = prevCond.get('cabinEnum').value.isHighCabin;
      if (isHighCabin && selectedflight) {
        toReplaceClassGradeFilterEnum = selectedflight.getIn(['theMinPriceItem', 'mainCabinEnum']);
      }

      filterCabins.push(classGradeFilterEnum);
    } else {
      // 点击 修改航班 重新反查已选航班，需要将之前选中的舱等重新显示到筛选框中
      // dispatch(genSetFilterClassGrade(filterCabins[targetSegmentSeq] || ''));
      filterCabins.splice(targetSegmentSeq);
    }

    const filterCabinsParams = (toReplaceClassGradeFilterEnum ? filterCabins.slice(0, filterCabins.length - 1).concat(toReplaceClassGradeFilterEnum) : filterCabins).reduce((prev, next, nextIndex) => {
      const cabins = next && next.value.includeKeys.length === 1 ? next.value.includeKeys[0] : '';
      return ({ ...prev, [nextIndex + 1]: cabins });
    }, {});

    isSearch = true;
    lastSearchTime = moment();
    routeSearchCriteria = routeSearchCriteria?.set('segmentNo', targetSegmentSeq + 1);

    // dispatch(genSetLastSearchTime());

    if (targetSegmentSeq == 0) {
      // 回到第一程不走反查逻辑
      // dispatch(genBeforeRouteSearch(true));

      // dispatch(fetchSearchFlights(prevCond, 0, false, true));
      // dispatch(genSelectedFlights(selectedflight, 0, routeSearchCriteria));
    } else {

      let header = genAntiCrawlerHeader(prevCond);
      // dispatch(genBeforeRouteSearch(false));

      routeSearchCriteria = routeSearchCriteria?.set('filterCabins', filterCabinsParams);
      // dispatch(genSelectedFlights(selectedflight, targetSegmentSeq, routeSearchCriteria));
      currentRouteSearchKey = +new Date();
      // routeSearchCriteria = routeSearchCriteria?.set('searchConfigId', searchConfigId);

      ((theSearchKey) => {
        fetchPostWithHeaders(URL.routeSearch, routeSearchCriteria, header)
          .then(res => {
            const json = res.data;
            if (theSearchKey === currentRouteSearchKey) {
              if (json && json.data && json.data.flightItineraryList) {
                isSearch = false;
                // prevCond = prevCond.set('segmentNo', targetSegmentSeq + 1);
                onFetchFlights({dispatch, prevCond, currentSegmentSeq: targetSegmentSeq - 1, json, pullDelay: CONSTS.SEARCH.FIRST_PULL_DELAY, searchKey, isBuildUpMode, isRouteSearch: true, isIntlSearch :true});
                // const intlFlight = json.data.flightItineraryList[0];
                // dispatch({ type: TYPES.SEARCH_INTL_PRICE_LIST, payload: intlFlight });
              } else {
                // dispatch(genSelectedFlights(null, 0, routeSearchCriteria));
                UbtHandler.onUploadData('c_normal_search_error', { data: json });
                if(!res?.isLogin) {
                  alert('页面数据加载失败，请重新搜索。');
                }
              }
            }
          }).catch(err => {
          if (theSearchKey === currentRouteSearchKey) {
            isSearch = false;
          }

          UbtHandler.onUploadData('route_search_error', err);
        });
      })(currentRouteSearchKey);
    }
  }
};
