const mergeConfig = (configKey, raw) => {
    try {
        if (typeof window.GlobalConfigs !== 'undefined' && raw) {
            let configValue = window.GlobalConfigs[configKey]
            if (configValue) {
                let configValueClone = JSON.parse(JSON.stringify(configValue)),
                    exportsKeys = Object.keys(raw)

                for (let i = 0; i < exportsKeys.length; i++) {
                    let exportsKey = exportsKeys[i],
                        rawValue = raw[exportsKey],
                        innerKeys = typeof rawValue === 'object' ? Object.keys(rawValue) : []

                    if (configValueClone[exportsKey] || raw[exportsKey]) {
                        if (raw[exportsKey] && typeof configValueClone[exportsKey] === 'undefined') {
                            configValueClone[exportsKey] = raw[exportsKey]
                        }

                        for (let j = 0; j < innerKeys.length; j++) {
                            let innerKey = innerKeys[j]

                            if (typeof configValueClone[exportsKey][innerKey] === 'undefined') {
                                configValueClone[exportsKey][innerKey] = raw[exportsKey][innerKey]
                            }
                        }
                    }
                }

                return configValueClone
            } else {
                return raw
            }
        }
        return raw
    } catch (e) {
        console.error(`合并配置:key=${configKey},出现异常:${e}`)
        return raw
    }
}

export default mergeConfig
