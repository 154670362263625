import getIfFlightAsLowPrice from '../getIfFlightAsLowPrice';

/**
 * 获取航班所有出票航司/航司联盟信息
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 */
const getAllAirlineOfFlights = (flights, currentSegmentSeq) => {
	let airlineMap = {},
		allianceMap = {},
		allianceToAirlineMap = {};

	if (!(currentSegmentSeq instanceof Array)) {
		currentSegmentSeq = [currentSegmentSeq];
	}

	let extractAirlineAndAlliance = (flight, seq) => {
		let currentSegment = flight.getIn(['flightSegments', seq]);
		// subAirlineCode subAirlineName 目前用于123航空，这个没有航班code的航班[○･｀Д´･ ○]
		let airlineCode = currentSegment.get('subAirlineCode') || currentSegment.get('airlineCode'),
			airlineName = currentSegment.get('subAirlineName') || currentSegment.get('airlineName'),
			theMainInnerFlight = currentSegment.get('flightList').find((flight) => flight.get('mainSegment')), //主航段
			alliance = theMainInnerFlight ? theMainInnerFlight.get('marketAlliance') : null,
			minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
			minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']),
			flightAsLowPrice = getIfFlightAsLowPrice(flight);

		if (alliance) {
			let theAllianceToAirlineMap = allianceToAirlineMap[alliance];
			if (!theAllianceToAirlineMap) {
				theAllianceToAirlineMap = allianceToAirlineMap[alliance] = [];
			}

			if (!theAllianceToAirlineMap.includes(airlineCode)) {
				theAllianceToAirlineMap.push(airlineCode);
			}
		}

		let theAirlineCodeMap = airlineMap[airlineCode];
		if (!theAirlineCodeMap) {
			theAirlineCodeMap = airlineMap[airlineCode] = {
				airlineCode,
				airlineName,
				minAvgPriceWithTax: Infinity,
				minAvgPriceWithoutTax: Infinity,
				alliance,
			};
		}

		if (flightAsLowPrice) {
			if (minAvgPriceWithTax < theAirlineCodeMap.minAvgPriceWithTax) {
				theAirlineCodeMap.minAvgPriceWithTax = minAvgPriceWithTax;
			}

			if (minAvgPriceWithoutTax < theAirlineCodeMap.minAvgPriceWithoutTax) {
				theAirlineCodeMap.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
			}
		}

		if (alliance) {
			let theAllianceMap = allianceMap[alliance];
			if (!theAllianceMap) {
				theAllianceMap = allianceMap[alliance] = {
					airlineCode,
					airlineName,
					minAvgPriceWithTax: Infinity,
					minAvgPriceWithoutTax: Infinity,
					alliance,
				};
			}

			if (flightAsLowPrice) {
				if (minAvgPriceWithTax < theAllianceMap.minAvgPriceWithTax) {
					theAllianceMap.minAvgPriceWithTax = minAvgPriceWithTax;
				}

				if (minAvgPriceWithoutTax < theAllianceMap.minAvgPriceWithoutTax) {
					theAllianceMap.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
				}
			}
		}
	};

	flights.valueSeq().forEach((flight) => {
		currentSegmentSeq.forEach((seq) => {
			extractAirlineAndAlliance(flight, seq);
		});
	});

	return {
		airline: airlineMap,
		alliance: allianceMap,
		map: allianceToAirlineMap,
	};
};

export default getAllAirlineOfFlights;
