import React from 'react'

const styles = {
    tooltipMargin: {
        verticalAlign: 'top',
        textAlign: 'left'
    }
}

export default class CashBack extends React.Component {
    render () {
        let { cashBack, adultMoney, childMoney } = this.props
        return (
            <div className="tooltip tooltip-cashback" style={styles.tooltipMargin}>
                <div className="tooltip-content">
                    {
                        adultMoney && childMoney ? (
                            <div className="tooltip-section">
                                <div className="cashback-sum">成人<span className="price">返现<dfn>&yen;</dfn>{adultMoney}</span></div>
                                <div className="cashback-sum">儿童<span className="price">返现<dfn>&yen;</dfn>{childMoney}</span></div>
                            </div>
                        ) : undefined
                    }
                    <div className="tooltip-section">
                        <div className="cashback-way">
                            <h5>返现说明</h5>
                            <p>{cashBack}</p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}