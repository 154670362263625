import { fromPairs } from 'ramda';
import { lightUpload } from '../sources/common/lightUbt';
import { regWindowEvent } from '../sources/common/regWindowEvent';

let performanceTrace = {
	trace(phase) {
		return null;
	},
};

try {
	// 只统计现代浏览器
	if (navigator.userAgent.toLowerCase().includes('webkit')) {
		// 搜索周期埋点追踪
		class PerformanceTrace {
			cache;
			// curIt;
			curIdx;
			timer;
			timestamp;
			hasSent;

			constructor(lifeCycles = [[]]) {
				this.lifeCycles = lifeCycles;
			}

			send() {
				clearTimeout(this.timer);
				if (this.cache?.size && !this.hasSent) {
					// Object.fromEntries有兼容性问题，改用R.fromPairs函数
					lightUpload('flt_online_performance_trace', fromPairs(Array.from(this.cache)));
					this.hasSent = true;
				}
			}

			reset() {
				this.send();
				// this.curIt = this.lifeCycles[Symbol.iterator]();
				this.curIdx = 0;
				this.cache = new Map();
				this.hasSent = false;
				this.timestamp = Date.now();
			}

			trace(phase) {
				try {
					clearTimeout(this.timer);
					this.timer = setTimeout(this.send.bind(this), 20 * 1000); // 20s 没触发流程埋点基本是接口问题，不纳入性能统计
					if (this.lifeCycles[0].includes(phase)) {
						this.reset();
					}
					// const { value, done } = this.curIt.next();
					// 严格限制流程，每阶段只能触发一次。
					const done = this.curIdx === this.lifeCycles.length;
					const value = this.lifeCycles[this.curIdx];
					if (!done && value.includes(phase)) {
						this.cache.set(phase, Date.now() - this.timestamp);
					}
					this.curIdx++;
				} catch (e) {}
			}
		}

		performanceTrace = new PerformanceTrace(
			[
				['beforeHistoryChange', 'afterPageMount'],
				['beforeFetchFlights'],
				['afterFetchFlights'],
				['afterParsingFlights'],
				['renderFlights'],
			],
			false
		);

		regWindowEvent('beforeunload', () => {
			performanceTrace.send();
		});
	}
} catch (e) {}

export { performanceTrace };
