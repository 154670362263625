import { createSelector } from 'reselect'
import { selectedFlightsSelector } from './flight/baseSelector'

/**
 * 去程已选航班，针对往返分屏，要考虑去程已选航班是否可见
 */
export const getSelectFlightItineryIdSelector = createSelector([selectedFlightsSelector],
    (selected) => {
        const selectedFlight = selected.get('flights')
        if (selectedFlight) {
            const itineraryId = selectedFlight.get('itineraryId')
            return itineraryId
        } else {
            return ''
        }
    })