import moment from 'moment';
import axios from 'axios';
import * as types from '../../constants/channel/actionTypes';
import configUrl from '../../constants/channel/api';
import poiUbt from '../../ubt/poi';
import {
  fetchGet,
  fetchPost,
  fetchPostWithHeaders,
} from '../common';
import CONFIG from '../../constants/channel/config';
import LOW_PRICE_REPORT_CONST from '../../constants/channel/LowPriceReport';
import { CHANNEL_UBT_KEY_TYPES } from '../../ubt.v2/channel';
import { fetchJsonpGet } from '../../sources/common/fetchJsonpGet';
import h5GatewayHead from '../../constants/common/config/h5GatewayCommonHead';
import { genScopeHeader, getScopeValue } from '../../sources/list/scope';

const GRADE_MAPING = {
  Y_S: 1 | 3,
  Y: 1,
  S: 2,
  C_F: 4 | 8,
  C: 4,
  F: 8,
  Y_S_C_F: 15
}
// 全局事件
export const genOnMouseDown = (target, tag = {}, id) => ({
  type: types.MOUSE_DOWN,
  target,
  tag,
  id,
});
export const genOnMouseUp = (target, tag = {}) => ({
  type: types.MOUSE_UP,
  target,
  tag,
});
export const genOnResize = () => ({ type: types.RESIZE });

export const genFetchHotTopic = hotTopics => ({
  type: types.FETCH_HOT_TOPICS,
  hotTopics,
});

export const genExtractLatestOrders = (index, count, allLatestOrders) => ({
  type: types.EXTRACT_CHANNEL_LATEST_ORDERS,
  index,
  count,
  allLatestOrders,
});

//底部最新订单
export const genFetchLatestOrders = () => ({
  type: types.FETCH_CHANNEL_LATEST_ORDERS,
});
export const genFetchLatestOrdersSuccess = (index, count, allLatestOrders) => ({
  type: types.FETCH_CHANNEL_LATEST_ORDERS_SUCCESS,
  index,
  count,
  allLatestOrders,
});
export const genFetchLatestOrdersFailure = () => ({
  type: types.FETCH_CHANNEL_LATEST_ORDERS_FAILURE,
});

//底部热门航线
export const genFetchSpecialAirlines = () => ({
  type: types.FETCH_CHANNEL_SPECIAL_AIRLINES,
});
export const genFetchSpecialAirlinesSuccess = specialAirlines => ({
  type: types.FETCH_CHANNEL_SPECIAL_AIRLINES_SUCCESS,
  specialAirlines,
});
export const genFetchSpecialAirlinesFailure = () => ({
  type: types.FETCH_CHANNEL_SPECIAL_AIRLINES_FAILURE,
});
export const genScrollLeftSpecialAirlines = () => ({
  type: types.SCROLL_LEFT_SPECIAL_AIRLINES,
});
export const genScrollRightSpecialAirlines = () => ({
  type: types.SCROLL_RIGHT_SPECIAL_AIRLINES,
});

//用户当前城市三字码
export const genFetchCityCode = () => ({ type: types.FETCH_CITY_CODE });
export const genFetchCityCodeSuccess = returnFrom => ({
  type: types.FETCH_CITY_CODE_SUCCESS,
  returnFrom,
});
export const genFetchCityCodeFailure = () => ({
  type: types.FETCH_CITY_CODE_FAILRUE,
});

//往返低价航线
export const genFetchReturnAirlines = returnTo => ({
  type: types.FETCH_CHANNEL_RETURN_AIRLINES,
  returnTo,
});
export const genFetchReturnAirlinesSuccess = data => ({
  type: types.FETCH_CHANNEL_RETURN_AIRLINES_SUCCESS,
  data,
});
export const genFetchReturnAirlinesFailure = () => ({
  type: types.FETCH_CHANNEL_RETURN_AIRLINES_FAILURE,
});

//往返出发/到达城市
export const genChangeReturnFrom = cities => ({
  type: types.CHANGE_CHANNEL_RETURN_FROM,
  cities,
});
export const genChangeReturnTo = cities => ({
  type: types.CHANGE_CHANNEL_RETURN_TO,
  cities,
});

//单程低价航线
export const genFetchSingleAirlines = cityname => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINES,
  cityname,
});
export const genFetchSingleAirlinesSuccess = data => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINES_SUCCESS,
  data,
});
export const genFetchSingleAirlinesFailure = () => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINES_FAILURE,
});

//单程低价航线目的航线列表
export const genFetchSingleAirlineLocations = () => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS,
});
export const genFetchSingleAirlineLocationsSuccess = (locations, names) => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_SUCCESS,
  locations,
  names,
});
export const genFetchSingleAirlineLocationsFailure = () => ({
  type: types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_FAILURE,
});

// 左侧机票工具箱
export const genChangeSearchCondition = currentSearchConditon => ({
  type: types.CHANGE_CHANNEL_SEARCH_CONDITION,
  currentSearchConditon,
});
export const genSearchFlight = () => ({ type: types.SEARCH_CHANNEL_FLIGHT });
export const genFlightNo = flightNo => ({
  type: types.CHANGE_CHANNEL_FLIGHTNO,
  flightNo,
});
//上边距样式控制
export const genSideBoxStyle = sideBoxMarginTop => ({
  type: types.SIDEBOX_STYLE_RESET,
  sideBoxMarginTop,
});

// 搜索框
export const genSelectFlightWay = flightWay => ({
  type: types.SELECT_CHANNEL_FLIGHTWAY,
  flightWay,
});
export const genSelectCity = (city, isDCity) => ({
  type: types.SELECT_CITY,
  city,
  isDCity,
});
export const genSearchBoxWidth = searchBoxWidth => ({
  type: types.SEARCH_BOX_WIDTH,
  searchBoxWidth,
});

//城市选择器
export const genShowHotCityPicker = (name, id) => ({
  type: types.SHOW_HOT_CITY_PICKER,
  name,
  id,
});
export const genHideHotCityPicker = (name, id) => ({
  type: types.HIDE_HOT_CITY_PICKER,
  name,
  id,
});
export const genExchangeCity = () => ({ type: types.EXCHANGE_CITY });
export const genChangeMtDepartCity = (city, index) => ({
  type: types.CHANGE_MT_DEPARTCITY,
  city,
  index,
});
export const genChangeMtArrivalCity = (city, index) => ({
  type: types.CHANGE_MT_ARRIVALCITY,
  city,
  index,
});

//POI
export const genChangePoiKey = (name, key, id) => ({
  type: types.CHANGE_POI_KEY,
  name,
  key,
  id,
});
export const genHidePoiCityPicker = (name, key, id) => ({
  type: types.HIDE_POI_CITY_PICKER,
  name,
  key,
  id,
});
export const genChangePOIList = (name, key, list, id) => ({
  type: types.CHANGE_POI_LIST,
  name,
  key,
  list,
  id,
});
export const genSelectPOIItem = (name, city, id, focusNextInput) => ({
  type: types.SELECT_POI_ITEM,
  name,
  city,
  id,
  focusNextInput,
});
export const genLastPoi = (list, id) => ({
  type: types.SAVE_LAST_POI,
  list,
  id,
});

//搜索框日期选择
export const genChangeOwStartDate = (date, dateStr) => ({
  type: types.CHANGE_OW_START_DATE,
  date,
  dateStr,
});
export const genChangeOwEndDate = (date, dateStr) => ({
  type: types.CHANGE_OW_END_DATE,
  date,
  dateStr,
});
export const genChangeRtStartDate = (date, dateStr) => ({
  type: types.CHANGE_RT_START_DATE,
  date,
  dateStr,
});
export const genChangeRtEndDate = (date, dateStr) => ({
  type: types.CHANGE_RT_END_DATE,
  date,
  dateStr,
});
export const genChangeMtStartDate = (index, date, dateStr) => ({
  type: types.CHANGE_MT_START_DATE,
  index,
  date,
  dateStr,
});
export const genGetStartDateTips = text => ({
  type: types.GET_START_DATE_TIP,
  text,
});
export const genGetEndDateTips = text => ({
  type: types.GET_END_DATE_TIP,
  text,
});
export const genCancelFocus = () => ({ type: types.CANCEL_FOCUS });

//搜索框多程
export const genAddMtSeg = id => ({ type: types.ADD_MT_SEG, id });
export const genDelMtSeg = (index, id) => ({
  type: types.DEL_MT_SEG,
  index,
  id,
});
export const genExchangeMtSeg = (index, id) => ({
  type: types.EXCHANGE_MT_SEG,
  index,
  id,
});

//搜索框乘客人数

export const genChangePaseengerCount = (passenger, domestic) => ({
  type: types.CHANGE_PASSENGER_COUNT,
  passenger,
  domestic,
});
//高级查询
export const genToggleAdvancedSearch = () => ({
  type: types.TOGGLE_ADVANCED_SEARCH,
});
export const genChangeClassGrade = (classGrade, domestic) => ({
  type: types.CHANGE_CLASS_GRADE,
  classGrade,
  domestic,
});

//高级查询航司列表及下拉搜索框
export const genFetchAirlinesList = () => ({ type: types.FETCH_AIRLINES_LIST });
export const genFetchAirlinesListSuccess = (
  suggestionAirlines,
  allAirlines,
) => ({
  type: types.FETCH_AIRLINES_LIST_SUCCESS,
  suggestionAirlines,
  allAirlines,
});
export const genFetchAirlinesListFailure = () => ({
  type: types.FETCH_AIRLINES_LIST_FAILRUE,
});
export const genSelectAirline = airline => ({
  type: types.SELECT_AIRLINE,
  airline,
});

//重要公告
export const genImportNotice = importantNoticeList => ({
  type: types.GEN_IMPORTANT_NOTICE,
  importantNoticeList,
});
export const genCloseImportNotice = () => ({
  type: types.GEN_CLOSE_IMPORTANT_NOTICE,
});

//日历低价
export const genFetchLowPrice = () => ({ type: types.FETCH_LOW_PRICE });
export const genFetchLowPriceSuccess = (
  dCityCode,
  aCityCode,
  list,
  cabinCode,
) => ({
  type: types.FETCH_LOW_PRICE_SUCCESS,
  dCityCode,
  aCityCode,
  list,
  cabinCode,
});
export const genFetchLowPriceFailure = msg => ({
  type: types.FETCH_LOW_PRICE_FAILRUE,
  msg,
});
export const genFetchLowRPriceSuccess = (
  departureCityCode,
  arrivalCityCode,
  pricelist,
  domestic,
  cabinCode,
) => ({
  type: types.FETCH_LOW_RPRICE_SUCCESS,
  departureCityCode,
  arrivalCityCode,
  pricelist,
  domestic,
  cabinCode,
});
// validate
export const genShowInfantTips = () => ({ type: types.SHOW_INFANT_TIPS });
export const genChangeDDateError = error => ({
  type: types.DDATE_ERROR,
  error,
});
export const genChangeADateError = error => ({
  type: types.ADATE_ERROR,
  error,
});
export const genChangeDCityError = error => ({
  type: types.DCITY_ERROR,
  error,
});
export const genChangeACityError = error => ({
  type: types.ACITY_ERROR,
  error,
});
export const genCleanOwRtError = () => ({ type: types.OW_RT_CLEAN_ERROR });
export const genChangeMtDCityError = (index, error, id) => ({
  type: types.MT_DCITY_ERROR,
  index,
  error,
  id,
});
export const genChangeMtACityError = (index, error, id) => ({
  type: types.MT_ACITY_ERROR,
  index,
  error,
  id,
});
export const genChangeMtDDateError = (index, error, id) => ({
  type: types.MT_DDATE_ERROR,
  index,
  error,
  id,
});
export const genCleanMtError = (index, error, id) => ({
  type: types.MT_CLEAN_ERROR,
  id,
});
export const genShowChildTips = () => ({ type: types.SHOW_CHILD_TIPS });

//持久数据
export const genCacheRecovery = persist => ({
  type: types.CACHE_RECOVERY,
  persist,
});

//机加酒
export const genSearchFltPlusHotel = () => ({
  type: types.SEARCH_FLT_PLUS_HOTEL,
});

//关闭第一次进入时弹出的优惠提醒气泡
export const genCloseFirstEnterTip = () => ({
  type: types.CLOSE_FIRST_ENTER_TIP,
});
//打开第一次进入时弹出的优惠提醒气泡
export const genOpenFirstEnterTip = () => ({
  type: types.OPEN_FIRST_ENTER_TOP,
});

//focus第二程
export const genFocusMtSecondDCity = id => ({
  type: types.FOCUS_MT_SECOND_DCITY,
  id,
});

//有告警搜索按钮不跳转
export const genSetCanNotSearch = () => ({ type: types.SET_CAN_NOT_SEARCH });
export const genResetCanNotSearch = () => ({
  type: types.RESET_CAN_NOT_SEARCH,
});

export const genSetDirectFlight = checked => ({
  type: types.SET_DIRECT_FLIGHT,
  checked,
});

export const genShowWarn = () => ({ type: types.SHOW_WARN });

export const genCancelFocusMtSecondDCity = () => ({
  type: types.CANCEL_FOCUS_MT_SECOND_DCITY,
}); //多程第二程出发城市为空时点击搜索弹出城市选择器

// 首页地图搜索

export const genSetSearchMapDCity = city => ({
  type: types.SET_SEARCH_MAP_DCITY,
  city,
});
export const genSetSearchMapDDate = date => ({
  type: types.SET_SEARCH_MAP_DDATE,
  date,
});
export const genSetSearchMapADate = date => ({
  type: types.SET_SEARCH_MAP_ADATE,
  date,
});
export const genSetSearchMapACountry = country => ({
  type: types.SET_SEARCH_MAP_ACOUNTRY,
  country,
});
export const genSetSearchMapFlightWay = flightWay => ({
  type: types.SET_SEARCH_MAP_FLIGHTWAY,
  flightWay,
});
export const genSetSearchMapDirectFlight = directFlight => ({
  type: types.SET_SEARCH_MAP_DIRECT_FLIGHT,
  directFlight,
});
export const genSetSearchMapFetchStatus = status => ({
  type: types.SET_SEARCH_MAP_FETCH_STATUS,
  status,
});
export const genSetSearchMapSearchResult = (flightKey, countries) => ({
  type: types.set_SEARCH_MAP_SEARCH_RESULT,
  flightKey,
  countries,
});
export const genFetchMapSearchResult = ({
  dCityCode,
  aCountryCodes,
  flightWay,
  dDate,
  aDate,
  callback,
}) => dispatch => {
  if (
    !dCityCode ||
    !aCountryCodes ||
    !flightWay ||
    !dDate ||
    !aCountryCodes.length
  ) {
    return;
  }
  let fetchedCount = 0,
    allCountris = {},
    defaultSelectedCountry = null;
  dispatch(genSetSearchMapFetchStatus('START'));
  let fetchSingleCountry = (aCountryCode, aCountryName, countryIndex) => {
    let param = null;
    if (dDate) {
      param = {
        head: h5GatewayHead,
        tt: flightWay == 'RT' && aDate ? '2' : '1',
        source: 'online_flight_intl',
        st: 1,
        segments: [
          {
            dcl: [dCityCode],
            acl: [aCountryCode],
            ddate: moment(dDate).format('YYYY-MM-DD'),
          },
        ],
      };
    }
    if (flightWay == 'RT' && aDate && dDate) {
      param.segments[0].rdate = moment(aDate).format('YYYY-MM-DD');
    }

    if (!param) {
      return;
    }

    if (!defaultSelectedCountry) {
      defaultSelectedCountry = {
        areaCode: aCountryCode,
      };
    }
    fetchPost(configUrl.fuzzySearch, param).then(res => {
      fetchedCount++;
      // 批量更新数据

      if (res.data && res.data.routes) {
        // 根据热度排序,只取热度前十的城市
        let searchResult = res.data.routes.sort(function(t1, t2) {
          t2.hot - t1.hot;
        });
        searchResult = searchResult.slice(0, 10);
        let countryLowestPrice = null,
          result = [];
        searchResult.map(route => {
          let { pl, arriveCity } = route || {};
          let { price } = pl && pl[0];

          if (price) {
            if (!countryLowestPrice || price < countryLowestPrice) {
              countryLowestPrice = price;
            }
          }
          let { code, name, lat, lon, imageUrl, id } = arriveCity;
          result.push({
            cityCode: code,
            cityName: name,
            cityId: id,
            latitude: lat,
            longtitude: lon,
            imageUrl: imageUrl,
            totalPrice: price,
            description: route.description,
          });
        });
        allCountris[aCountryCode] = {
          cities: result,
          countryName: aCountryName,
          lowestPrice: countryLowestPrice,
          index: countryIndex,
        };
      } else {
        allCountris[aCountryCode] = {
          cities: [],
          countryName: aCountryName,
          lowestPrice: null,
          index: countryIndex,
        };
      }
      if (fetchedCount == aCountryCodes.length) {
        let flightKey = `${dCityCode}_${flightWay}_${
          aDate && flightWay == 'RT' ? `${dDate}_${aDate}` : dDate
        }`;
        dispatch(genSetSearchMapFetchStatus('DONE'));
        dispatch(genSetSearchMapSearchResult(flightKey, allCountris));
        dispatch(genSetSearchMapACountry(defaultSelectedCountry));
        if (callback) {
          callback();
        }
      }
    });
  };
  aCountryCodes.forEach((aCountry, index) => {
    fetchSingleCountry(aCountry.areaCode, aCountry.areaName, index);
  });
};

// 同步地图搜索条件到搜索框
export const genSynSearchMapDataToSearchBox = ({
  dDate,
  aDate,
  dCity,
  flightWay,
}) => dispatch => {
  if (aDate) {
    dispatch(genChangeRtEndDate(aDate, aDate.format('YYYY-MM-DD')));
  }
  if (dDate) {
    dispatch(genChangeRtStartDate(dDate, dDate.format('YYYY-MM-DD')));
  }
  if (flightWay) {
    dispatch(genSelectFlightWay(flightWay));
  }
  if (dCity) {
    dispatch(genSelectPOIItem('owDCity', dCity, 'search'));
  }
};

export const genGetUserLocationSucess = dCity => ({
  type: types.SET_SEARCH_MAP_ADATE,
  dCity,
});

export const genSynSearchBoxDataToSearchMap = ({
  dDate,
  aDate,
  dCity,
  flightWay,
}) => dispatch => {
  if (aDate) {
    dispatch(genSetSearchMapADate(aDate));
  }
  if (dDate) {
    dispatch(genSetSearchMapDDate(dDate));
  }
  if (flightWay) {
    dispatch(genSetSearchMapFlightWay(flightWay));
  }
  if (dCity) {
    dispatch(genSetSearchMapDCity(dCity));
  }
};

let poiCache = {};
export const changePOIInput = (name, val = '', id) => dispatch => {
  val = val.toUpperCase().trim();
  dispatch(genHideHotCityPicker(''));
  dispatch(genChangePoiKey(name, val, id));

  let onGetSucc = (json = {}) => {
    if (!json.Data || json.Data.length <= 0) {
      json.Data = [];
      //poi无结果埋点
      poiUbt.markPoiNoResult(val);
    } else {
      //poi有结果埋点
      poiUbt.poiRequestSuccess(val);
    }
    dispatch(genChangePOIList(name, val, json, id));
  };

  //优先取缓存
  if (poiCache[val]) {
    onGetSucc(poiCache[val]);
  } else {
    return fetchGet(configUrl.getPoi, { key: val })
      .then(res => res && res.data || {})
      .then(json => {
        poiCache[val] = json;
        onGetSucc(json);
      })
      .catch(() => {});
  }
};

//重要公告
export const fetchImportNotice = () => dispatch => {
  return fetchGet(configUrl.getHomepageNotifyUrl)
    .then(res => res && res.data || {})
    .then(json => dispatch(genImportNotice(json)));
};

//热门主题
export const fetchHotTopics = () => dispatch => {
  return fetchGet(configUrl.getHotTopicList)
    .then(res => res && res.data || {})
    .then(json => dispatch(genFetchHotTopic(json)));
};

//底部最新成交订单
export const fetchLatestOrders = (index, count) => dispatch => {
  dispatch(genFetchLatestOrders());
  return fetchGet(configUrl.getLatestOrdersUrl)
    .then(res => res && res.data || {})
    .then(allLatestOrders => {
      if (allLatestOrders.data != null && allLatestOrders.data.length) {
        dispatch(
          genFetchLatestOrdersSuccess(index, count, allLatestOrders.data),
        );
      } else {
        dispatch(genFetchLatestOrdersFailure());
      }
    })
    .catch(() => dispatch(genFetchLatestOrdersFailure()));
};

//底部最新成交订单轮播
export const extractLatestOrders = (
  index,
  count,
  allLatestOrders,
) => dispatch => {
  dispatch(genExtractLatestOrders(index, count, allLatestOrders));
};

//通过ip获取用于当前位置城市三字码
export const fetchUserCityCode = returnTo => dispatch => {
  // dispatch(genFetchCityCode())
  return fetchGet(configUrl.getUserCityCodeUrl)
    .then(res => res && res.data || {})
    .then(json => {
      // 假如所在城市不在下拉列表中，那就给一个默认城市上海
      let defaultDepartCityCode = 'SHA';
      if (json.data != null) {
        let hasSelectedVal = false,
          cityCode = json.data;
        for (var [key] of CONFIG.airlineSelectMap) {
          if (key == cityCode) {
            hasSelectedVal = true;
          }
        }
        if (!hasSelectedVal) {
          cityCode = defaultDepartCityCode;
        }
        dispatch(genFetchCityCodeSuccess(cityCode));
        dispatch(fetchReturnAirlines(returnTo, cityCode));
      } else {
        dispatch(fetchReturnAirlines(returnTo, defaultDepartCityCode));
      }
    })
    .catch(() => dispatch(genFetchCityCodeFailure()));
};

//往返低价航线
export const fetchReturnAirlines = (returnTo, returnFrom) => dispatch => {
  dispatch(genFetchReturnAirlines(returnTo));
  return fetchGet(configUrl.getReturnAirlinesUrl, {
    departCityCode: returnFrom,
    continent: returnTo,
  })
    .then(res => res && res.data || {})
    .then(data => {
      if (!data.data) {
        data.data = [];
      }
      dispatch(genFetchReturnAirlinesSuccess(data.data));
    })
    .catch(() => {
      dispatch(genFetchReturnAirlinesFailure());
    });
};

// 单程低价航线
export const fetchSingleAirlines = cityName => dispatch => {
  dispatch(genFetchSingleAirlines(cityName));
  return fetchGet(configUrl.getSingleAirlinesUrl, { arrivalCityCode: cityName })
    .then(res => res && res.data || {})
    .then(data => {
      if (data.data === null) {
        data.data = [];
      }
      dispatch(genFetchSingleAirlinesSuccess(data.data));
    })
    .catch(() => {
      dispatch(genFetchSingleAirlinesFailure());
    });
};

// 单程低价航线目的城市列表
export const fetchSingleAirlineLocations = () => dispatch => {
  dispatch(genFetchSingleAirlineLocations());
  return fetchGet(configUrl.getSingleAirlineLocationsUrl)
    .then(res => res && res.data || {})
    .then(data => {
      if (data.data === null) {
        data.data = [];
      }
      let locations = [];
      let names = [];
      data.data.map(item => {
        locations.push(item.name);
        names.push(item.code);
      });
      if (names.length) {
        dispatch(genFetchSingleAirlineLocationsSuccess(locations, names));
      }
      dispatch(fetchSingleAirlines(names[0]));
    })
    .catch(() => {
      dispatch(genFetchSingleAirlineLocationsFailure());
    });
};

//底部热门航线推荐
export const fetchSpecialsAirlines = () => dispatch => {
  dispatch(genFetchSpecialAirlines());

  return fetchGet(configUrl.getSpecialAirlinesUrl)
    .then(res => res && res.data || {})
    .then(json => {
      if (json.data && json.data.length) {
        dispatch(genFetchSpecialAirlinesSuccess(json.data));
      } else {
        dispatch(genFetchSpecialAirlinesFailure());
      }
    })
    .catch(() => {
      dispatch(genFetchSpecialAirlinesFailure());
    });
};

let isFetchingLowPrice = false,
  lastFetchLowrPriceDate = moment('2017-01-01'),
  fetchLowPriceTimeoutInSec = 3;

export const fetchLowPrice = (
  dCityCode,
  aCityCode,
  options = { domestic: false, cabin: '' },
) => dispatch => {
  const { domestic, cabin } = options;

  if (
    isFetchingLowPrice &&
    moment().diff(
      lastFetchLowrPriceDate.add(fetchLowPriceTimeoutInSec, 'seconds'),
    ) < 0
  ) {
    return;
  }

  if (!dCityCode || !aCityCode) return;

  isFetchingLowPrice = true;
  lastFetchLowrPriceDate = moment();

  dispatch(genFetchLowPrice());

  let cond = {
    departNewCityCode: dCityCode,
    arriveNewCityCode: aCityCode,
    searchType: domestic ? 1 : 2,
    flag: domestic ? 0 : 1 | 0 ,
    channelName: domestic ? "FlightOnline" : "FlightIntlOnline",
    calendarSelections: [{
      selectionType: 8,
      selectionContent: [`${GRADE_MAPING[cabin]}`]
    }],
    startDate: moment().format('YYYY-MM-DD'),
    grade: GRADE_MAPING[cabin],
    passengerList:[{passengercount:1, passengertype:'Adult'}]
  };
  const scopeHeader = genScopeHeader(getScopeValue(!domestic));

  return fetchPostWithHeaders(configUrl.getCalendarL, cond, scopeHeader)
    .then(res => res && res.data || {})
    .then(json => {
      isFetchingLowPrice = false;
      let data = json.priceList;
      dispatch(genFetchLowPriceSuccess(dCityCode, aCityCode, data, cabin));
    })
    .catch(msg => {
      isFetchingLowPrice = false;
      dispatch(genFetchLowPriceFailure(msg));
    });
};

export const fetchLowRPrice = (
  flightSegmentList,
  cabin,
  flightWay = 'D',
  domestic,
) => dispatch => {
  const startDate = flightSegmentList[0]?.departureDate || moment().format('YYYY-MM-DD');
  let params = {
    departNewCityCode: flightSegmentList[0]?.departureCityCode,
    arriveNewCityCode: flightSegmentList[0]?.arrivalCityCode,
    searchType: domestic ? 1 : 2,
    flag: domestic ? 4 : 1 | 4 ,
    channelName: domestic ? "FlightOnline" : "FlightIntlOnline",
    calendarSelections: [{
      selectionType: 8,
      selectionContent: [`${GRADE_MAPING[cabin]}`]
    }],
    startDate: startDate,
    returnDate: moment(startDate).add(3, 'days').format('YYYY-MM-DD'),
    grade: GRADE_MAPING[cabin],
    passengerList:[{passengercount:1, passengertype:'Adult'}]
  };

  const scopeHeader = genScopeHeader(getScopeValue(!domestic));
  return fetchPostWithHeaders(configUrl.getCalendarL, params, scopeHeader)
    .then(res => res && res.data || {})
    .then(json => {
      let data = json.priceList;
      dispatch(
        genFetchLowRPriceSuccess(
          flightSegmentList[0].departureCityCode,
          flightSegmentList[0].arrivalCityCode,
          data,
          domestic,
          cabin,
        ),
      );
    });
};
//获取高级搜索航司列表
export const fetchAirlinesList = () => dispatch => {
  dispatch(genFetchAirlinesList());
  return fetchGet(configUrl.getAirlinesListUrl)
    .then(res => res && res.data || {})
    .then(json => {
      if (json.data != null) {
        dispatch(
          genFetchAirlinesListSuccess(
            json.data.suggestionAirlines,
            json.data.allAirlines,
          ),
        );
      }
    })
    .catch(() => dispatch(genFetchCityCodeFailure()));
};

//获取热门国家列表
export const fetchHotCountrys = () => {
  return fetchGet(configUrl.getHotCountryListUrl).then(res => {
    return res.data;
  });
};

// 获取航司对应下关于出发地的特惠航线
export const fetchAirlinePreferenceLines = (airline, cityPairList) => {
  return fetchPost(configUrl.getAirlinePreferenceLines, {
    airline,
    cityPairList,
  }).then(res => {
    return res?.data || null;
  });
};

export const fetchTimeZoneByCityCode = cityCode => {
  return fetchJsonpGet(configUrl.getTimeZoneBycityCode, { cityCode: cityCode })
    .then(res => res && res.data || {})
    .then(res => {
      return res;
    });
};
//获取查询记录值
export const fetchSearchHistoryData = (data, callback) => {
  return fetchPost(configUrl.getSearchHistoryList, data)
    .then(res => res && res.data || {})
    .then(res => {
      callback(res);
    });
};
export const genSetLowPriceReportSearchResult = (
  searchKey,
  index,
  regionCode,
  result,
  name,
  dCityName,
  showDcity,
) => ({
  type: types.SET_LOW_PRICE_REPORT_SEARCH_RESULT,
  searchKey,
  index,
  regionCode,
  result,
  name,
  dCityName,
  showDcity,
});
export const genSetLowPriceReportLoadingStatus = status => ({
  type: types.SET_LOW_PRICE_REPORT_SEARCH_RESULT_LOADING_STATUS,
  status,
});
export const genClearLowPriceReportSearchResult = () => ({
  type: types.CLEAR_LOW_PRICE_REPORT_SEARCH_RESULT,
});

// 获取低价速报数据
export const fetchLowPriceReport = ({
  searchKey,
  departCity,
  minTravelDay,
  maxTravelDay,
  departTimes,
  weekDays,
  dCityName,
}) => dispatch => {
  // 契约地址http://contract.mobile.flight.ctripcorp.com/#/service/detail/3272
  // 1=城市对低价查询，2=航班低价列表查询，3=多地比价，4=拼假攻略，5=团购查询，6=低价速报，7=搜索全球低价（世界地图）,8=online低价查询（主题），9=个人推荐低价（主题）,10=特价机票放假去哪儿,11=特价机票降价榜单列表,12=特价爆款模块 13=热门城市低价航线，提供给攻略用
  // tt 行程类型；1=单程，2=往返，4=多程（多程不支持）
  // st 1=城市对低价查询，2=航班低价列表查询，3=多地比价，4=拼假攻略，5=团购查询，6=低价速报，7=搜索全球低价（世界地图）,8=online低价查询（主题），9=个人推荐低价（主题）,10=特价机票放假去哪儿,11=特价机票降价榜单列表,12=特价爆款模块 13=热门城市低价航线，提供给攻略用

  // let hostName = window.GlobalConfigs.h5HostName || 'http://m.ctrip.com'
  let postActions = [],
    formatDepartTimes = [];

  LOW_PRICE_REPORT_CONST.sortDistrict = LOW_PRICE_REPORT_CONST.sortDistrict.filter(
    item => {
      return item.show !== false;
    },
  );
  let commonParam = {
    source: 'online_flight_intl',
    st: '6',
    head: h5GatewayHead,
  };
  departTimes.map(date => {
    let year = date.split('-')[0],
      month = date.split('-')[1],
      isHalfYearLater = month == '00';
    if (isHalfYearLater) {
      let start = moment(),
        end = start.clone().add(6, 'months');
      formatDepartTimes.push({
        begin: start.format('YYYY-MM-DD'),
        end: end.format('YYYY-MM-DD'),
      });
    } else {
      let time = moment().set({ year: year, month: month - 1 });
      formatDepartTimes.push({
        begin: time.startOf('month').format('YYYY-MM-DD'),
        end: time.endOf('month').format('YYYY-MM-DD'),
      });
    }
  });

  LOW_PRICE_REPORT_CONST.sortDistrict.map(district => {
    postActions.push(
      fetchPost(
        configUrl.fuzzySearch,
        Object.assign({}, commonParam, {
          tt: district.tripType,
          segments: [
            {
              dcl: [departCity],
              acl: [district.code],
              dow: weekDays,
              drl: formatDepartTimes,
              sr: {
                min: minTravelDay,
                max: maxTravelDay,
              },
            },
          ],
        }),
      ),
    );
  });

  return axios.all(postActions).then(
    axios.spread(function(...args) {
      let hasReuslt = false;
      args &&
        args.forEach((res, i) => {
          if (res && res.data) {
            let routes = res.data.routes;

            let result = [];
            routes.map(route => {
              let arriveCity = route.arriveCity,
                departCity = route.departCity,
                pricelist = route.pl;

              pricelist.map(price => {
                // 精选推荐,去掉大于7折数据
                if (
                  LOW_PRICE_REPORT_CONST.sortDistrict[i].code == '1' &&
                  price.rate > 0.7
                ) {
                  return null;
                }
                result.push({
                  arriveCityCode: arriveCity.code,
                  arriveCityName: arriveCity.name,
                  departCityCode: departCity.code,
                  departCityName: departCity.name,
                  departDate: price.departDate,
                  imageUrl: arriveCity.imageUrl,
                  international: route.isIntl,
                  jumpUrl: '',
                  price: price.price,
                  rate: price.rate,
                  returnDate: price.returnDate,
                  tagType: 0,
                  tags: [],
                });
              });
            });
            result = result.slice(0, LOW_PRICE_REPORT_CONST.maxShow);
            if (result.length) {
              hasReuslt = true;
              let {
                index,
                regionCode,
                name,
                showDcity,
              } = LOW_PRICE_REPORT_CONST.sortDistrict[i];
              dispatch(
                genSetLowPriceReportSearchResult(
                  searchKey,
                  index,
                  regionCode,
                  result,
                  name,
                  dCityName,
                  showDcity,
                ),
              );
            }
          }
        });
      CHANNEL_UBT_KEY_TYPES.SEND_NOW_SHOW.value.process({
        lowPriceHasResult: hasReuslt,
      });

      dispatch(genSetLowPriceReportLoadingStatus(false));
    }),
  );
};
//更改热门国家到达国家
export const genChangeArrivalCountry = arrivalCountry => ({
  type: types.CHANGE_ARRIVAL_COUNTRY,
  arrivalCountry,
});
//切换至热门国家发现低价状态
export const genSwitchHotSearch = isHotSearch => ({
  type: types.SWITCH_HOT_SEARCH,
  isHotSearch,
});
//切换时间选择器类型
export const genSwitchFreePicker = () => ({ type: types.SWITCH_FREE_PICKER });
//更改日历当前月份
export const genChangeCurrentMonth = currentMonthIndex => ({
  type: types.CHANGE_CURRENT_MONTH,
  currentMonthIndex,
});
//更改日历选中日期
export const genChangeSelectedDay = selectedDay => ({
  type: types.CHANGE_SELECTED_DAY,
  selectedDay,
});
//修改浮动天数
export const genChangeFloatDays = floatDays => ({
  type: types.CHANGE_FLOAT_DAYS,
  floatDays,
});
//修改出游天数
export const genChangeStayDays = stayDays => ({
  type: types.CHANGE_STAY_DAYS,
  stayDays,
});
//修改选中时间范围信息
export const genChangeSelectedTimeItem = selectedTimeItems => ({
  type: types.CHANGE_SELECTED_TIME_ITEM,
  selectedTimeItems,
});
//修改国家来源
export const genChangeCountrySource = countrySource => ({
  type: types.CHANGE_COUNRTY_SOURCE,
  countrySource,
});
//修改input内容
export const genChangeInputString = inputString => ({
  type: types.CHANGE_INPUT_STRING,
  inputString,
});

/**
 * 初始化从服务端传回的开关信息，比如ABT
 * @param {*全局开关信息} globalSwitch
 */
export const genInitGlobalSwitch = globalSwitch => {
  return { type: types.SEARCH_INIT_GLOBAL_SWITCH, globalSwitch };
};

//获取链接城市code
export const genSearchLinkData = code => dispatch => {
  if (code) {
    return fetchGet(configUrl.getPoi, { key: code })
      .then(res => res && res.data || {})
      .then(json => {
        let result = json.Data && json.Data[0];
        if (result && result.Code) {
          let dCity = {
              text: `${result.Name}(${result.Code})`,
              Code: result.Code,
              TimeZone: result.TimeZone,
              Name: result.Name,
              CityId: result.CityId,
              domestic: result.TimeZone === '480',
            },
            aCity = null;
          dispatch(genSelectPOIItem('owDCity', dCity, 0, false));
          dispatch(genSelectPOIItem('owACity', aCity, 0, false));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }
};

// 获取用户常驻地
export const genUserResident = () => dispatch => {
  return fetchGet(configUrl.getUserResidenceCity)
    .then(res => res && res.data || {})
    .then(residenceRes => {
      let res = residenceRes.data;
      if (res && res.cityCode && res.hasAirport) {
        let dCity = {
          text: `${res.cnName}(${res.cityCode})`,
          Code: res.cityCode,
          TimeZone: res.timeZone,
          Name: res.cnName,
          CityId: res.cityId,
          domestic: res.domestic,
        };
        dispatch(genSelectPOIItem('owDCity', dCity, 0, false));
      } else {
        dispatch(genUserLocation());
      }
    })
    .catch(() => {
      dispatch(genUserLocation());
    });
};
//获取用户地址，更新搜索框默认值
export const genUserLocation = () => dispatch => {
  fetchGet(configUrl.getRequestCityInfo)
    .then(res => res && res.data || {})
    .then(res => {
      let dCity;
      res = res.data;
      if (res && res.cityCode && res.hasAirport) {
        dCity = {
          text: `${res.cnName}(${res.cityCode})`,
          Code: res.cityCode,
          TimeZone: res.timeZone,
          Name: res.cnName,
          CityId: res.cityId,
          domestic: res.domestic,
        };
      } else {
        dCity = window.GlobalConfigs.defaultSearchData.dCity;
      }

      dispatch(genSelectPOIItem('owDCity', dCity, 0, false));
    });
};

// 获取国内航班数据
export const fetchDomesticFlightsApi = req => {
  let host =
    window.GlobalConfigs.domesticFlightsApiHost || 'https://flights.ctrip.com';
  let url = `${host}/itinerary/api/12808/products`;
  return fetchPost(url, req).then(res => {
    // 航班数据已存服务端，本地只是打印一下
    console.log(res?.data);
  });
};
