

import React, { useState } from 'react'
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import OutsideClickHandler from '../OutsideClickHandler'
import {MtFlightWayTips, DomesticMtFlightWayTips} from './MtFlightWayTips'

const flightWayOptions = [{ label: '单程 ', name: 'OW' }, { label: '往返 ', name: 'RT' }, { label: '多程', des: '(含缺口程) ', name: 'MT' }]


const FlightWaySwitch = ({
    selected,
    handleClick,
    type,
    domestic
}) => {
    const [dropdownDisplay, onDropdownDisplay] = useState(false);
    const onSelectActive = () => {
        onDropdownDisplay(true)
    }
    const onOutsideClick = () => {
        onDropdownDisplay(false)
    }
    let lastIndex = flightWayOptions.length - 1
    let selectItem = flightWayOptions.filter((item) => {
        return item.name == selected
    })
    let dropdownList = () => (
        <div className="class-grade-select" style={{ display: dropdownDisplay ? 'block' : 'none', position: 'absolute', top: '59px', left: '0' }}>
            <ul>
                {flightWayOptions.map((item, index) => {
                    return (
                        <UbtBoundary key={index} tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT, { type: 'flightWay', text: item.value, value: item.key })}>
                            <li className={`${item.name === selected ? 'active' : ''}`} onClick={() => { handleClick(item.name); onDropdownDisplay(false) }}>
                                {item.name === selected ? <div><span className="iconf-check">&#xe666;</span></div> : <div></div>}
                                <div>{item.label}</div>
                            </li>
                        </UbtBoundary>

                    )
                })}
            </ul>
        </div>
    )
    if (type == "SELECT") {
        return (
            <OutsideClickHandler onOutsideClick={onOutsideClick} >

                <div className={`form-item-v3-group form-item-v3-stand-out flightWay-selector ${dropdownDisplay ? 'active' : ''}`}>
                    <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'flightWayClick', text: '点击展开航程类型' })}>
                        <div className="form-item-v3 flt-field-v3 " onClick={onSelectActive}>
                            <div className="form-select-input ">
                                <div className="form-select-v3">
                                    <span className="form-select-txt">
                                        {selectItem && selectItem.length && selectItem[0].label}
                                    </span>
                                    <i className="iconf-arrow-down">&#xe60c;</i>
                                    <i className="iconf-arrow-up">&#xe604;</i>
                                </div>
                            </div>
                        </div>
                    </UbtBoundary>
                    {dropdownList()}
                </div>
            </OutsideClickHandler>

        )
    } else {
        return (
            <ul className="form-select-radio-group">
                {flightWayOptions.map((item, index) =>
                    (<UbtBoundary key={index} tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'switchFlightWay', flightWay: item.name })}>
                        <li onClick={() => { handleClick(item.name) }} className={`${selected === item.name ? "active " : ""}  ${lastIndex == index ? "last" : ""}`}>
                            <span className="radio-label">
                                <i className="iconf-radio-unselect">&#xe60d;</i>
                                <i className="iconf-radio-selected">&#xe60a;</i>
                                {item.label}{item.des}
                            </span>
                        </li>
                    </UbtBoundary>
                    )
                )}
                <div className="flightWay-des">
                    <i className="iconf-question">&#xe606;</i>
                    {domestic ? <DomesticMtFlightWayTips/> : <MtFlightWayTips />}
                </div>
            </ul>
        )
    }

}

export default FlightWaySwitch

