import store from 'store'
import moment from 'moment'
import LIST_CONST_CONFIG from '../../constants/list/constConfig'

const CACHE_KEY = 'sort_cache',
    CACHE_LIMIT_DAYS = LIST_CONST_CONFIG.SORT.CACHE_STATUS_IN_DAYS   //记忆用户排序规则的时效性，默认30天

export class SortCache {
    //缓存排序信息
    static saveCache(sortKey, index) {
        if (sortKey && index) {
            store.set(CACHE_KEY, {
                date: +new Date(),
                key: sortKey,
                index
            })
        } else {
            store.remove(CACHE_KEY)
        }
    }

    static getCache() {
        const cache = store.get(CACHE_KEY)
        if (cache && cache.date && cache.key && cache.index) {
            const theMoment = moment(cache.date)
            const index = +cache.index

            if (theMoment.isValid()) {
                const days = moment().diff(theMoment, 'days', true)
                if (days <= CACHE_LIMIT_DAYS) {
                    return {
                        key: cache.key,
                        index
                    }
                }
            }
        }

        return ''
    }
}