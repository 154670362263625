import mergeConfig from '../common/mergeConfig'
import Q_CONFIG_KEYS from './qConfigKeys'

const CONSTS = {
    //筛选框
    FILTER: {
        DEFAULT_DISPLAY_COUNT: 5,    //筛选框展现数目，超出部分要展开才显示
        DEFAULT_DISPLAY_COUNT_V2: 6,    //筛选框展现数目，超出部分要展开才显示
        HIDDEN_GROUPS_IDS: ['FILTER_GROUP_TRANS_CITY.COUNTRY']
    },
    SEARCH: {
        FIRST_PULL_DELAY: 2000,     //第一次PULL查询轮询间隔时间，单位毫秒
        LATER_PULL_DELAY: 1000,      //后续PULL查询轮询间隔时间，单位毫秒
        MAX_STAY_TIME_OUT_IN_MIN: 15,  //重查时间间隔，15分钟
        d_MAX_STAY_TIME_OUT_IN_MIN: 10,  //国内重查时间间隔，10分钟
        BOOK_TIME_OUT_IN_MS: 1000,  //预订超时，单位毫秒
        REDIRECT_TO_BOOK_TIME_OUT_IN_MS: 5000,  //跳转到填写页超时，单位毫秒
        RESEARCH_WITHOUT_REFRESH_MAX_DURATION_IN_MIN: 15,    //列表页要启用重查无刷新的最大页面停留时长，单位分钟（即停留在列表页超过多少分钟就一定会强制刷新，以获取最新配置等）
        STRICT_SEARCH_WAIT_FOR_PENALTY_DETAIL_TIMEOUT_IN_MS: 2000, // 进行严格反查是否要等到退改签接口拿到之后才进行，主要是传最小退票费给严格反查接口，增值页依赖最小退票费。此处超时是等待退改签的最长时间，单位毫秒
        STRICT_SEARCH_WAIT_FOR_PENALTY_DETAIL_INTERVAL_IN_MS: 50, // 上述等待严格反查的判断时间间隔，单位毫秒
        QUICK_SEARCH_FLIGHTS_VAILD_DURATION_IN_MS: 5000,    // 列表页初始化就开始搜索航班的有效时长，单位毫秒
    },
    RECOMMEND: {
        MIDDLE_REC_INDEX: 8,         //腰部推荐的显示位置索引号
        MIDDLE_REC_INDEX_TWO: 16,
        MIDDLE_REC_INDEX_THREE: 24,
        CF_REC_YS_REC_FOLD_INDEX: 4,     //两舱折叠索引号
        CF_REC_YS_REC_MIN_INDEX: 12,     //两舱索引号
        CF_REC_YS_REC_AFTER_INDEX: 10,     //两舱索引号
        OPEN_JAW_RECOMMEND_MISERYINDEX_WEIGHT: 400,
        CALENDAR_LP_SINGLE_DAYSLIMIT: 365, //低价日历单程日期上限
        CALENDAR_LP_ROUNG_DAYSLIMIT: 365,   //低价日历往返日期上限
        HEAD_RECOMMEND_POSITION: 4,
        QRCODE_INDEX: 7,
        // 右侧浮动框要隐藏的推荐类型，定义文件：\src\components\list\floatSideBar\recommends.js
        HIDDEN_FLOAT_RECOMMEND_TYPES: []
    },
    FLIGHTS: {
        PAGE_SIZE: 5,
        BATCH_SEARCH_COMFORT_MAX_SIZE: 50   //批量请求舒适度，最多
    },
    BAGGAGE: {
        DEFAULT_DETAIL_HOVER: '行李尺寸限制请咨询航司',
        DEFAULT_DETAIL_FOOTER_HOVER: '行李尺寸限制请咨询航司',
        DEFAULT_HAS_BAGGAGE_SERVICE_HOVER: '后续页面提供购买行李额服务',
        DEFAULT_NO_BAGGEAGE_HOVER: '行李额信息请在点击“预订”按钮后，在下一个页面查看'
    },
    PRICE: {
        PLUS_ONE_TWO_SEG_TITLE: '多飞一程',//智能推荐，多飞一程标题
        PLUS_ONE_TWO_SEG_CONTENT: '此机票共有两程，请妥善安排您的出行计划',//智能推荐，多飞一程内容
        PLUS_ONE_ENTER_TITLE: '入境须知',//智能推荐，入境须知标题
        PLUS_ONE_ENTER_CONTENT: '请备齐全部航程所需签证或其他旅行文件以便出境登机',//智能推荐，入境须知内容
        NON_FORCE_TOP_AirlineAuthorizedStore: [],     //航司授权店强制置顶黑名单，即配置内的出票航司不置顶
        FORCE_TOP_FlagShipStore: ['MU', 'CA', '3U', 'ZH'],     //航司旗舰店强制置顶
        SHOW_NGS_SCORE_AIRLINES: [],	//显示NGS打分的出票航司，要是数组：1）可配置多个航司，2）只配置一个元素*，表示所有航司均显示
        NGS_TIP_REMARK: '该评分是NGS认证的价格评分，反映了该价格的退改政策、行李额、选座政策和座位舒适度等因素的综合素质。'
    },
    UBT: {
        COMMON_LOG_MAX_ACTION_SIZE: 500,
        COMMON_LOG_RECORD_ACTION_TIME: true
    },
    OTHERS: {
        CTM_HASH: ["ctm_ref=Iflt_hp_hot_fav_p"],
        CTM_REF: 'Iflt_hp_hot_fav_p'
    },
    SORT: {
        CACHE_STATUS_IN_DAYS: 30,     // 记忆用户排序规则的时效性
        CHANGE_FIX_STATUS_MINI_INTERVAL: 500    //分屏时候，切换是否吸顶，最小间隔时长，单位毫秒
    },
    TRANSFER_DES: {
        MORE_THAN_ONE_DAY_AND_MORE_THAN_2_HOURS: {
            title: '跨天中转',
            preview: '跨天中转',
            good: false,
            sortIndex: 6,
            info: '航班中转跨天，您可选择在机场候机室小憩，或提前休息室酒店候机。如在机场候机，请提前确认转机机场开放时间。'
        },
        LESS_THAN_2_HOURS: {
            title: '中转时间短',
            info: '航班中转换乘时间仅[MINUTES]，请注意时间安排。',
            preview: '中转时间短',
            good: false,
            sortIndex: 7,
        },
        MULTI_FLIGHT_LESS_THAN_2_HOURS: {
            info: '航班中转换乘不足2小时，请注意时间安排。'
        }
    },
    SEARH_NO_RESULT: {
        WARN_CONTEN: {
            text: '您好，为坚决遏制境外新冠肺炎疫情输入风险高发态势，根据国务院疫情联防联控工作要求，民航局调减了国际客运航班运行数量。<br/>您查询的 [segment] 的[flightWay]机票可能因航线取消或无位导致暂时无法查询到对应价格。<br/>建议您更换旅行日期或旅行城市重新查询。'
        },
        DEMAND_FORM_LINK: {
            text: '申请需求单'
        }
    }
}

/**
 * 单程 往返 多程tab切换数据
 */
export const FLIGHTWAY_TAB_DATA = [
    { id: 'OW', label: '单程' },
    { id: 'RT', label: '往返' },
    { id: 'MT', label: '多程(含缺口程)', hasIcon: true }
]


/**
 * 城市选择字段
 */
export const OW_DCITY_NAME = 'owDCity'
export const OW_ACITY_NAME = 'owACity'
export const RT_DCITY_NAME = 'rtDCity'
export const RT_ACITY_NAME = 'rtACity'

export default mergeConfig(Q_CONFIG_KEYS.CONSTS, CONSTS)
