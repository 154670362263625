import { InternalAxiosRequestConfig } from 'axios';
import signature from '@/src/common/utils/signature';
import { searchFlightsUrl } from '@/src/constants/list/api';
import { ubtDevTrace } from '@/src/sources/common/lightUbt';

const signatureInterceptor = async (config: InternalAxiosRequestConfig<any>) => {
	if (config.url?.includes(searchFlightsUrl)) {
		// 使用重试策略
		const { listConstConfig } = window.GlobalConfigs || {};
		const { CAPTCHA_LOOP_INTERVAL_TIME = 20, CAPTCHA_LOOP_INTERVAL_TIMES = 3 } = listConstConfig || {};
		config.headers.token = await signature(config.data, CAPTCHA_LOOP_INTERVAL_TIME, CAPTCHA_LOOP_INTERVAL_TIMES);
	} else {
		// 有就调用没有就不调用
		if (typeof window.signature === 'function') {
			let signatureParams;
			if (config.method === 'get') {
				signatureParams = config.url?.split('?')[1];
			} else {
				signatureParams = config.data;
			}
			if (signatureParams) {
				const token = window.signature(signatureParams);
				config.headers.token = token;
				if (!token) {
					ubtDevTrace('hotel_captcha_token_failed', { url: config.url, params: signatureParams });
				}
			}
		}
	}

	if (!config.headers.token) {
		// 这个来判断是否超时？
		config.headers.FltOlFpCs = '1';
	}

	return config;
};

export default signatureInterceptor;
