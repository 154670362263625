import getConfig from 'next/config';

export const { GLOBAL_PREFIX } = getConfig().publicRuntimeConfig;
export const RUNTIME_SERVER_HOST =
	window.runtimeEnv === 'pro' ? 'https://flights.ctrip.com' : `https://${window.runtimeServerHost}`;
export const RUNTIME_SERVER = `${RUNTIME_SERVER_HOST}/international/search`;
/**
 * 网站HOST名字，取一级域名 ，比如 https://flights.ctrip.com/
 */
export const HOST_NAME = `${window.location.protocol}//${window.location.host}`;
/**
 * 站点根目录，末尾不带 / ，比如 http://flights.fat34.qa.nt.ctripcorp.com:8080/international
 */
export const SITE_ROOT = `${HOST_NAME}/online`;
/**
 * 站点虚拟目录的根目录，末尾不带 / ，比如 http://flights.fat34.qa.nt.ctripcorp.com:8080/international/search
 */
// 现在是列表页
export const PAGE_LIST_URL = `${SITE_ROOT}/list`;
/**
 * API接口的根目录，末尾不带 / ，比如 http://flights.fat34.qa.nt.ctripcorp.com:8080/international/search/api
 */
export const API_ROOT = `${RUNTIME_SERVER}/api`;

export const SCHEDULE_URL = `${RUNTIME_SERVER}/schedule`;

export default API_ROOT;
