/**
 * 从假定有效的日期时间字符串中提取出小时，比如传入 2017-10-31 09:59:48  返回 9
 * @param {*日期字符串，格式 2017-10-31 09:59:13} dateStr
 */
const extractHourFromDateStr = (dateStr: string) => {
	let groups = dateStr.match(/\d{4}\-\d{2}\-\d{2} (\d{2}):\d{2}:\d{2}/);
	return groups.length > 1 ? +groups[1] : NaN;
};

export default extractHourFromDateStr;
