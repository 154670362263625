import React from "react"
import moment from 'moment'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

class DiffDaysSelector extends React.Component {
    constructor(props) {
        super(props);
        this.onChangeDiffDays = this.onChangeDiffDays.bind(this)

        this.toggleIntervalDays = this.toggleIntervalDays.bind(this);
        this.closeIntervalDays = this.closeIntervalDays.bind(this)
        this.state = {
            showIntervalDay: false
        }
    }
    onChangeDiffDays(day) {
        let { diffDays, changeDiffDays, searchCriteria } = this.props
        if ((day < 0 && diffDays + day >= 0) || (day > 0 && moment(searchCriteria.getIn(["flightSegments", 0, "departureDate"])).add(diffDays + day - 1, 'days').diff(moment().add(1, 'years'), 'days') < 0)) {
            diffDays += day;
            changeDiffDays(diffDays)
        }

    }
    toggleIntervalDays() {
        this.setState({ showIntervalDay: !this.state.showIntervalDay })
    }
    closeIntervalDays() {
        this.setState({ showIntervalDay: false })
    }
    render() {
        const { diffDays, searchCriteria } = this.props;
        if (!searchCriteria) return null
        return (

            <div className="travel-days">
                <div className="travel-days-cpt">出行天数</div>
                <div className="travel-days-form">
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_minus1')}>
                        <i className={`ico-minus-o ${diffDays <= 0 ? 'disabled' : ''}`} onMouseDown={() => { this.onChangeDiffDays(-1); }}></i>
                    </UbtBoundary>
                    <span className="days">{diffDays + 1}</span>
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_plus1')}>
                        <i className={`ico-plus-o ${(moment(searchCriteria.getIn(["flightSegments", 0, "departureDate"])).add(diffDays, 'days').diff(moment().add(1, 'years'), 'days') >= 0) ? 'disabled' : ''}`} onMouseDown={() => { this.onChangeDiffDays(1); }}></i>
                    </UbtBoundary>
                </div>
            </div>

        )

    }
}

export default DiffDaysSelector
