export const defaultFlightProps = {
    itineraryTags: [],
    priceList: [],
    isFCRecommendYS: false,
    isCFRecommendYS: false,
    comfortTags: {
        hasEntertainEquipment: false,
        hasPower: false,
        hasWifi: false
    }
}