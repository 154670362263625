import { createSelector } from 'reselect'
import CONSTS from '../../../../constants/list/constConfig'
import { getMinMiseryIndexSelector } from './baseRecommendSelector'
import {getMinPriceSelector} from '../flight/baseSelector'

export const openJawRecommendDataSelector = (state) => state.getIn(["list", "recommend", "openJawRecommendData"])
export const openJawGroundTrafficDataSelector = (state) => state.getIn(["list", "recommend", "openJawGroundTrafficData"])

export const showOpenJawRecommendDataWrapperSelector = createSelector([openJawRecommendDataSelector, getMinMiseryIndexSelector, getMinPriceSelector],
    (openJawRecommendData, minMiseryIndex, minPrice) => {
        // 缺口程推荐展示条件(是否往返已在调接口时处理)
        if (openJawRecommendData.size > 0) {
            if (openJawRecommendData.get("miseryIndex") + CONSTS.RECOMMEND.OPEN_JAW_RECOMMEND_MISERYINDEX_WEIGHT < minMiseryIndex && openJawRecommendData.get("price") + openJawRecommendData.get("tax") < minPrice.get("totalPrice")) {
                return openJawRecommendData
            }
        }
        return null
    }
)
