import React from 'react';
import Tooltip from '../../../components/list/common/tooltip';

const scattered_group_icons = window.GlobalConfigs.listPageConfig.scattered_group_icons || [];
const icon_filename = scattered_group_icons[Math.floor(Math.random() * 20)] || '0a29492a284c3ac20eca6782b50e9f8a';

const simplePriceType = {
  None: '',
  Fly_Recommend: '指定政策',
  Service_Packages: '服务包',
  Airline: '航司直销',
  Favorable: '专属',
  Package: '特惠',
  FlyHotel: '飞宿',
  YPlus: 'Y+',
};

const customPriceType = {
  Priority: {
    rawLabel: '优选',
    getLabel: (wrapper, id) =>
      wrapper.name ? (
        <span className="d_product_type" id={id}>
          {wrapper.name}
          {wrapper.data || ''}
        </span>
      ) : wrapper.icon ? (
        <img id={id} src={wrapper.icon} alt="优选" />
      ) : null,
  },
  ScatteredGroup: {
    rawLabel: '拼团',
    getLabel: (wrapper, id) =>
    wrapper.name ? (
      <span className="d_product_type" id={id}>
        {wrapper.name}
      </span>
    ) : wrapper.icon ? (
      <img id={id} src={wrapper.icon} alt={simplePriceType[type]} />
    ) : wrapper.policyFlag ? (
      <div className="scattered_group">
        <p>{scatteredGroupType[wrapper.policyFlag] || '特惠拼团'}</p>
        <div className="scattered_content">
          <img className="attr" src={`//pages.c-ctrip.com/flight_h5/online/ScatteredGroupIcon/${icon_filename}.jpeg`} alt=""/>
          <img className="sub_attr" src={'//pages.c-ctrip.com/flight_h5/online/ScatteredGroupIcon/scattered_attr@1x.slice.svg'}></img>
        </div>
      </div>
    ) : null,
  }
};

const scatteredGroupType = {
  1: '特惠拼团',
  2: '套餐拼团',
};

export const DomesticProductType = [...Object.keys(simplePriceType), ...Object.keys(customPriceType)].reduce((acc, type) => {
  acc[type] = {
    rawLabel: simplePriceType[type],
    getLabel: (wrapper, id) =>
      wrapper.name ? (
        <span className="d_product_type" id={id}>
          {wrapper.name}
        </span>
      ) : wrapper.icon ? (
        <img id={id} src={wrapper.icon} alt={simplePriceType[type]} />
      ) : null,
    getLabelTooltip: (wrapper, _price, _currentSegmentSeq) => (wrapper.description ? <Tooltip content={wrapper.description} /> : null),
    getFlightRowLabel: (_wrapper, _id) => null,
    getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
    getBookNotice: (_wrapper, _id) => null,
    getBookNoticeTooltip: _wrapper => null,
    ...(customPriceType[type] || {}),
  };

  return acc;
}, {});
