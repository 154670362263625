import React from 'react';
import classnames from 'classnames';
import Enum from 'enum';
import { PROVINCE, COUNTRY, TRANS_COUNT } from './common';
import {
	filterOptionsGenerator,
	filterFlatOptionsV2Generator,
} from '@/src/components/list/sidebar/filterbar/filterGenerator';
import { getIfFlightAsLowPrice } from '@/src/sources/list/getIfFlightAsLowPrice';
import { AIRCRAFT_SIZE } from '../../common/enum/airCraftSize';
import { getFlightAircraftSizeList } from '@/src/sources/list/getMainSegmentAirCraftSize';
import { convertFilterItemKeyAsString } from '@/src/sources/list/convertFilterItemKeyAsString';
import { getIfDepartOrArrivalInChina } from '@/src/sources/list/getIfDepartOrArrivalInChina';
import { scopeField } from '@/src/sources/list/scopeField';
import { getIfScopeInternational } from '@/src/sources/list/scope';
import { getIfFlightTrain } from '@/src/sources/list/getIfFlightTrain';
import { CABIN } from '../../common/enum/common';
import INVOICE_TYPE from '../../common/config/invoiceType';
import { scopeMapping } from '@/src/sources/list/scopeMapping';
import { scopeObject } from '@/src/sources/list/scopeObject';
import { scopeFunction } from '@/src/sources/list/scopeFunction';
import AirlineLogo from '@/src/components/Logo/AirlineLogo';
import extractHourFromDateStr from '@/src/sources/list/converter/extractHourFromDateStr';
import getDurtionEnumOfFlight from '@/src/sources/list/converter/getDurtionEnumOfFlight';
import getAllTransAndStopCitiesOfFlights from '@/src/sources/list/converter/getAllTransAndStopCitiesOfFlights';
import getAllTransDurationOfFlights from '@/src/sources/list/converter/getAllTransDurationOfFlights';
import getAllAirlineOfFlights from '@/src/sources/list/converter/getAllAirlineOfFlights';
import getAllDepartAndArrivalTimeOfFlights from '@/src/sources/list/converter/getAllDepartAndArrivalTimeOfFlights';
import getAllAirportOfFlights from '@/src/sources/list/converter/getAllAirportOfFlights';
import getAllCabinPriceForFilter from '@/src/sources/list/converter/getAllCabinPriceForFilter';
import getAllAircraftSizeOfFlights from '@/src/sources/list/converter/getAllAircraftSizeOfFlights';
import getAllInvoiceTypesOfFlights from '@/src/sources/list/converter/getAllInvoiceTypesOfFlights';
import FILTER_CONTROL_TYPE from './filter/FILTER_CONTROL_TYPE';

const TIME_TYPE = {
	AM_0_6: {
		seq: 0,
		title: '凌晨 0~6点',
		min: 0,
		max: 6,
	},
	AM_6_12: {
		seq: 1,
		title: '上午 6~12点',
		min: 6,
		max: 12,
	},
	AM_12_18: {
		seq: 2,
		title: '下午 12~18点',
		min: 12,
		max: 18,
	},
	AM_18_24: {
		seq: 3,
		title: '晚上 18~24点',
		min: 18,
		max: 24,
	},
};

const genTimeTypeEnum = (tag) => {
	const finalTimeType = Object.keys(TIME_TYPE).reduce(
		(prev, next) => ({
			...prev,
			[next]: {
				...TIME_TYPE[next],
				tag,
			},
		}),
		{}
	);

	return finalTimeType;
};

export { FILTER_CONTROL_TYPE };

/**
 * 出发时间
 */
export const FILTER_DEPART_TIME_TYPE = new Enum(genTimeTypeEnum(''));

export const FILTER_GO_DEPART_TIME_TYPE = new Enum(genTimeTypeEnum('GO_DEPART'));

export const FILTER_BACK_DEPART_TIME_TYPE = new Enum(genTimeTypeEnum('BACK_DEPART'));

/**
 * 到达时间
 */
export const FILTER_ARRIVE_TIME_TYPE = new Enum(genTimeTypeEnum(''));

export const FILTER_GO_ARRIVE_TIME_TYPE = new Enum(genTimeTypeEnum('GO_ARRIVAE'));

export const FILTER_BACK_ARRIVE_TIME_TYPE = new Enum(genTimeTypeEnum('BACK_ARRIVE'));

/**
 * 中转城市
 */
export const FILTER_TRANS_CITY_TYPE = new Enum({
	MAIN_LAND_GW: {
		title: '中国大陆城市及港澳',
	},
	TW: {
		title: '中国台湾城市',
	},
});

/**
 * 中转时长
 */
export const FILTER_TRANS_TIMESPAN_TYPE = new Enum({
	HOURS_0_2: {
		seq: 0,
		title: '0~2小时',
		min: 1,
		max: 120,
	},
	HOURS_2_4: {
		seq: 1,
		title: '2~4小时',
		min: 120,
		max: 240,
	},
	HOURS_4_8: {
		seq: 2,
		title: '4~8小时',
		min: 240,
		max: 480,
	},
	HOURS_8_12: {
		seq: 3,
		title: '8~12小时',
		min: 480,
		max: 720,
	},
	HOURS_12_24: {
		seq: 4,
		title: '12~24小时',
		min: 720,
		max: 1440,
	},
	HOURS_24: {
		seq: 5,
		title: '24小时以上',
		min: 1440,
		max: Infinity,
	},
});

/**
 * 中转次数
 */
export const TRANS_COUNT_TYPE = new Enum({
	ZERO: {
		key: 0,
		title: '直飞',
		titleV2: '直飞',
		tag: {
			min: 0,
			max: 0,
		},
		filterPrice: (_flight, _price) => {
			return true;
		},
	},
	ONE: {
		key: 1,
		title: '1程中转',
		tag: {
			min: 1,
			max: 1,
		},
		filterPrice: (_flight, _price) => {
			return true;
		},
	},
	MORE: {
		key: 2,
		title: '2程中转及以上',
		tag: {
			min: 2,
			max: Infinity,
		},
		filterPrice: (_flight, _price) => {
			return true;
		},
	},
});

// 中转加经停次数
export const filterFlightOfTransferAndStopCount = (filterItem, flight, currentSegmentSeq, isBuildUp) => {
	const requiredMinTransCount = filterItem.value.tag.min,
		requiredMaxTransCount = filterItem.value.tag.max,
		flightSegments = flight.getIn(['flightSegments']),
		theSegmentSeqMatch = (segmentSeq) => {
			const transferAndStopCount = flightSegments.getIn([segmentSeq, 'transferAndStopCount']);

			return transferAndStopCount >= requiredMinTransCount && transferAndStopCount <= requiredMaxTransCount;
		};

	return isBuildUp
		? flightSegments.every((segment, seq) => theSegmentSeqMatch(seq))
		: theSegmentSeqMatch(currentSegmentSeq);
};
// 中转次数
export const filterFlightOfTransferCount = (filterItem, flight, currentSegmentSeq, isBuildUp) => {
	const requiredMinTransCount = filterItem.value.tag.min,
		requiredMaxTransCount = filterItem.value.tag.max,
		flightSegments = flight.getIn(['flightSegments']),
		theSegmentSeqMatch = (segmentSeq) => {
			const transferAndStopCount = flightSegments.getIn([segmentSeq, 'transferCount']);

			return transferAndStopCount >= requiredMinTransCount && transferAndStopCount <= requiredMaxTransCount;
		};

	return isBuildUp
		? flightSegments.every((segment, seq) => theSegmentSeqMatch(seq))
		: theSegmentSeqMatch(currentSegmentSeq);
};

export const DOMESTIC_TRANS_COUNT_TYPE = new Enum({
	// 保持跟国际筛选项中直飞KEY的ZERO一致
	ZERO: {
		key: 0,
		title: '直飞/经停',
		filterFlight: (flight, currentSegmentSeq) => getIfFlightIsDirectAndStop(flight, currentSegmentSeq),
	},
	ALLOW_TRANSFER: {
		key: 1,
		title: '中转',
		filterFlight: (flight, currentSegmentSeq) => getIfFlightHasTransfer(flight, currentSegmentSeq),
	},
	ALLOW_FLIGHT_TRAIN: {
		key: 3,
		title: '空铁',
		filterFlight: (flight, currentSegmentSeq) => getIfFlightTrain(flight, currentSegmentSeq),
	},
});

// 是否直飞或者经停航班（排除掉空铁）
export const getIfFlightIsDirectAndStop = (flight, currentSegmentSeq) => {
	return (
		!getIfFlightTrain(flight, currentSegmentSeq) &&
		flight.getIn(['flightSegments', currentSegmentSeq, 'transferCount']) === 0
	);
};

// 是否中转航班（排除掉空铁）
const getIfFlightHasTransfer = (flight, currentSegmentSeq) => {
	return (
		!getIfFlightTrain(flight, currentSegmentSeq) &&
		!!flight.getIn(['flightSegments', currentSegmentSeq, 'transferCount'])
	);
};

const destructTimeWrapperToFlatItems = (
	flightsWrapper,
	field,
	timeEnumType,
	groupType,
	titleForNarrowPreviewPrefix,
	titleForPreviewPrefix
) => {
	let items = [],
		fieldValue = flightsWrapper[field];

	Object.keys(fieldValue).forEach((theKey) => {
		let theTimeEnum = timeEnumType.get(theKey),
			item = fieldValue[theKey];

		items.push({
			key: theTimeEnum,
			titleForNarrowPreview: `${titleForNarrowPreviewPrefix}${theTimeEnum.value.title}`,
			titleForPreview: `${titleForPreviewPrefix}${theTimeEnum.value.title}`,
			titleForChoose: theTimeEnum.value.title,
			minAvgPriceWithTax: item.minAvgPriceWithTax,
			minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
			enable: true,
			groupType,
		});
	});

	return items.sort((prev, next) => prev.key.value.seq - next.key.value.seq);
};

const destructAirportWrapperToFlatItems = (flightsWrapper, field, titlePrefix, groupType) => {
	return Object.keys(flightsWrapper[field])
		.map((airportCode) => {
			const item = flightsWrapper[field][airportCode],
				airportCodeValue = item[`${field}AirportCode`];

			return {
				key: `[${groupType.value.id}]${airportCodeValue}`,
				titleForPreview: titlePrefix + item[`${field}AirportName`],
				titleForChoose: item[`${field}AirportName`],
				minAvgPriceWithTax: item.minAvgPriceWithTax,
				minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
				nearbyAirportDistance: item[`${field}NearbyAirportDistance`],
				nearbyAirportName: item[`${field}NearbyAirportName`],
				enable: true,
				groupType,
			};
		})
		.sort((prev, next) => prev.minAvgPriceWithTax - next.minAvgPriceWithTax);
};

// 直飞/中转次数
export const FILTER_GROUP_TRANS_COUNT = new Enum({
	TRANS_COUNT: {
		id: 'FILTER_GROUP_TRANS_COUNT.TRANS_COUNT',
		// 两舱推荐时，是否允许筛选框的结构和价格共享wrapper
		getTitle: (_prevCond, _currentSegmentSeq) => '中转次数',
		filterFlight: (filterItem, flight, currentSegmentSeq, isBuildUp) =>
			filterFlightOfTransferAndStopCount(filterItem, flight, currentSegmentSeq, isBuildUp),
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 中转/经停/空铁
export const DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN = new Enum({
	TRANS_COUNT: {
		id: 'DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT',
		getTitle: (_prevCond, _currentSegmentSeq) => '中转/经停/空铁',
		filterFlight: (filterItem, flight, currentSegmentSeq, _isBuildUp) =>
			filterItem.value.filterFlight(flight, currentSegmentSeq),
		filterPrice: (_filterItem, _price) => true,
	},
});

// 航司/航司联盟
export const FILTER_GROUP_AIRLINE = new Enum({
	UNION: {
		id: 'FILTER_GROUP_AIRLINE.UNION',
		// 因为联盟自身不筛选，即 filterFlight 永远返回true，所以不能依靠原始方式判断，增加此方法
		getItemEnable: (filterItem, enabledKeysInGroup) =>
			enabledKeysInGroup.some((key) => filterItem.get('airlineList').some((airlineCode) => airlineCode === key)),
		getTitle: (_prevCond, _currentSegmentSeq) => '航空公司',
		filterFlight: (_filterItem, _flight, _currentSegmentSeq, _isBuildUp) => {
			// 联盟不做真正意义的筛选，用航司筛选
			return true;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	AIRLINE: {
		id: 'FILTER_GROUP_AIRLINE.AIRLINE',
		neverFallbackToFilterTypeTitle: true,
		getTitle: (_prevCond, _currentSegmentSeq) => '',
		filterFlight: (filterItem, flight, currentSegmentSeq, isBuildUp) => {
			let theSegmentSeqIsAirlineCode = scopeFunction({
				i: (segmentSeq) => {
					return (
						flight.getIn(['flightSegments', segmentSeq, 'airlineCode']) === filterItem ||
						flight
							.getIn(['flightSegments', segmentSeq, 'flightList'])
							.some((flight) => flight.get('operateAirlineCode') === filterItem)
					);
				},
				d: (segmentSeq) => {
					let segmentSeqFlight = flight.getIn(['flightSegments', segmentSeq]),
						airlineCode = segmentSeqFlight.get('airlineCode'),
						subAirlineCode = segmentSeqFlight.get('subAirlineCode');
					return (subAirlineCode || airlineCode) === filterItem;
				},
			});

			if (isBuildUp) {
				return theSegmentSeqIsAirlineCode(0) || theSegmentSeqIsAirlineCode(1);
			} else {
				return theSegmentSeqIsAirlineCode(currentSegmentSeq);
			}
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 只有搜索国际航班才有此提示，搜索国内航班不需要，因为都是北京时间
const getLocalTimePrefix = (prevCond, segmentSeq, isDepart) =>
	getIfScopeInternational()
		? getIfDepartOrArrivalInChina(prevCond, segmentSeq, isDepart)
			? '（北京时间）'
			: '（当地时间）'
		: '';

// 非往返组合，包括出发/到达时间
export const FILTER_GROUP_TIME = new Enum({
	DEPART: {
		id: 'FILTER_GROUP_TIME.DEPART',
		getTitle: (_prevCond, _currentSegmentSeq) =>
			`起飞时段${getLocalTimePrefix(_prevCond, _currentSegmentSeq, true)}`,
		filterFlight: (filterItem, flight, currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				departureDateTime = flight.getIn(['flightSegments', currentSegmentSeq, 'departureDateTime']),
				departureHour = extractHourFromDateStr(departureDateTime);

			return min <= departureHour && departureHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	ARRIVE: {
		id: 'FILTER_GROUP_TIME.ARRIVE',
		getTitle: (_prevCond, _currentSegmentSeq) =>
			`抵达时段${getLocalTimePrefix(_prevCond, _currentSegmentSeq, false)}`,
		filterFlight: (filterItem, flight, currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				arrivalDateTime = flight.getIn(['flightSegments', currentSegmentSeq, 'arrivalDateTime']),
				arrivalHour = extractHourFromDateStr(arrivalDateTime);

			return min <= arrivalHour && arrivalHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 往返组合，去程出发/到达时间
export const FILTER_GROUP_GO_TIME = new Enum({
	GO_DEPART: {
		id: 'FILTER_GROUP_GO_TIME.GO_DEPART',
		getTitle: (_prevCond, _currentSegmentSeq) => `去程起飞时段${getLocalTimePrefix(_prevCond, 0, true)}`,
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				departureDateTime = flight.getIn(['flightSegments', 0, 'departureDateTime']),
				departureHour = extractHourFromDateStr(departureDateTime);

			return min <= departureHour && departureHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	GO_ARRIVE: {
		id: 'FILTER_GROUP_GO_TIME.GO_ARRIVE',
		getTitle: (_prevCond, _currentSegmentSeq) => `去程抵达时段${getLocalTimePrefix(_prevCond, 0, false)}`,
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				arrivalDateTime = flight.getIn(['flightSegments', 0, 'arrivalDateTime']),
				arrivalHour = extractHourFromDateStr(arrivalDateTime);

			return min <= arrivalHour && arrivalHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 往返组合，返程出发/到达时间
export const FILTER_GROUP_BACK_TIME = new Enum({
	BACK_DEPART: {
		id: 'FILTER_GROUP_BACK_TIME.BACK_DEPART',
		getTitle: (_prevCond, _currentSegmentSeq) => `返程起飞时段${getLocalTimePrefix(_prevCond, 1, true)}`,
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				departureDateTime = flight.getIn(['flightSegments', 1, 'departureDateTime']),
				departureHour = extractHourFromDateStr(departureDateTime);

			return min <= departureHour && departureHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	BACK_ARRIVE: {
		id: 'FILTER_GROUP_BACK_TIME.BACK_ARRIVE',
		getTitle: (_prevCond, _currentSegmentSeq) => `返程抵达时段${getLocalTimePrefix(_prevCond, 1, false)}`,
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			let { min, max } = filterItem.value,
				arrivalDateTime = flight.getIn(['flightSegments', 1, 'arrivalDateTime']),
				arrivalHour = extractHourFromDateStr(arrivalDateTime);

			return min <= arrivalHour && arrivalHour < max;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 中转城市
export const FILTER_GROUP_TRANS_CITY = new Enum({
	COUNTRY: {
		id: 'FILTER_GROUP_TRANS_CITY.COUNTRY',
		// 因为中转国家自身不筛选，即 filterFlight 永远返回true，所以不能依靠原始方式判断，增加此方法
		getItemEnable: (filterItem, enabledKeysInGroup) =>
			enabledKeysInGroup.some((key) => filterItem.get('cityCodeList').some((cityCode) => cityCode === key)),
		getTitle: (_prevCond, _currentSegmentSeq) => '中转城市',
		filterFlight: (_filterItem, _flight, _currentSegmentSeq, _isBuildUp) => {
			// 国家以及地区的筛选项不做真正筛选
			return true;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	CITY: {
		id: 'FILTER_GROUP_TRANS_CITY.CITY',
		getTitle: (_prevCond, _currentSegmentSeq) => '中转城市',
		filterFlight: (filterItem, flight, currentSegmentSeq, isBuildUp) => {
			let validateFlightCity = (flight, validateFunc) =>
					validateFunc(
						flight.get('departureCountryName'),
						flight.get('departureProvinceId'),
						flight.get('departureCityCode')
					),
				validateAnyStopCity = (flight, validateFunc) =>
					flight
						.get('stopList')
						.some((stopCity) =>
							validateFunc(
								stopCity.get('countryName'),
								stopCity.get('provinceId'),
								stopCity.get('cityCode')
							)
						),
				theSegmentSeqHasTransCity = (segmentSeq) => {
					return flight.getIn(['flightSegments', segmentSeq, 'flightList']).some((flight, index) => {
						return (
							validateAnyStopCity(
								flight,
								(countryName, provinceId, cityCode) => cityCode === filterItem
							) ||
							(index > 0 &&
								validateFlightCity(
									flight,
									(countryName, provinceId, cityCode) => cityCode === filterItem
								))
						);
					});
				};

			if (isBuildUp) {
				return theSegmentSeqHasTransCity(0) || theSegmentSeqHasTransCity(1);
			} else {
				return theSegmentSeqHasTransCity(currentSegmentSeq);
			}
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

// 中转停留时长
export const FILTER_GROUP_TRANS_TIMESPAN = new Enum({
	TRANS_TIMESPAN: {
		id: 'FILTER_GROUP_TRANS_TIMESPAN.TRANS_TIMESPAN',
		getTitle: (_prevCond, _currentSegmentSeq) => '中转时长',
		filterFlight: (filterItem, flight, currentSegmentSeq, isBuildUp) => {
			let transTimespanEnum = FILTER_TRANS_TIMESPAN_TYPE.get(filterItem),
				{ min, max } = transTimespanEnum.value,
				getTotalTransDurationOfSegmentSeq = (segmentSeq) => getDurtionEnumOfFlight(flight, segmentSeq).duration;

			if (isBuildUp) {
				let totalTransDurationOfSeq1 = getTotalTransDurationOfSegmentSeq(0),
					totalTransDurationOfSeq2 = getTotalTransDurationOfSegmentSeq(1);

				return (
					(min <= totalTransDurationOfSeq1 && totalTransDurationOfSeq1 < max) ||
					(min <= totalTransDurationOfSeq2 && totalTransDurationOfSeq2 < max)
				);
			} else {
				let totalTransDuration = getTotalTransDurationOfSegmentSeq(currentSegmentSeq);
				return min <= totalTransDuration && totalTransDuration < max;
			}
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

const trimFilterAirport = (airportCode) => airportCode.replace(/\[.*?\]/, '');

/**
 * 非往返组合
 */
export const FILTER_GROUP_AIRPORT = new Enum({
	DEPART: {
		id: 'FILTER_GROUP_AIRPORT.DEPART',
		getTitle: (prevCond, currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', currentSegmentSeq, 'departureCityName'])}出发机场`;
		},
		filterFlight: (filterItem, flight, currentSegmentSeq, _isBuildUp) => {
			return (
				flight.getIn(['flightSegments', currentSegmentSeq, 'departureAirportCode']) ===
				trimFilterAirport(filterItem)
			);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	ARRIVE: {
		id: 'FILTER_GROUP_AIRPORT.ARRIVE',
		getTitle: (prevCond, currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', currentSegmentSeq, 'arrivalCityName'])}到达机场`;
		},
		filterFlight: (filterItem, flight, currentSegmentSeq, _isBuildUp) => {
			return (
				flight.getIn(['flightSegments', currentSegmentSeq, 'arrivalAirportCode']) ===
				trimFilterAirport(filterItem)
			);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

/**
 * 往返组合，描述去程机场
 */
export const FILTER_GROUP_GO_AIRPORT = new Enum({
	GO_DEPART: {
		id: 'FILTER_GROUP_GO_AIRPORT.GO_DEPART',
		getTitle: (prevCond, _currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', 0, 'departureCityName'])}出发机场`;
		},
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			return flight.getIn(['flightSegments', 0, 'departureAirportCode']) === trimFilterAirport(filterItem);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	GO_ARRIVE: {
		id: 'FILTER_GROUP_GO_AIRPORT.GO_ARRIVE',
		getTitle: (prevCond, _currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', 0, 'arrivalCityName'])}到达机场`;
		},
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			return flight.getIn(['flightSegments', 0, 'arrivalAirportCode']) === trimFilterAirport(filterItem);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

/**
 * 往返组合，描述返程机场
 */
export const FILTER_GROUP_BACK_AIRPORT = new Enum({
	BACK_DEPART: {
		id: 'FILTER_GROUP_BACK_AIRPORT.BACK_DEPART',
		getTitle: (prevCond, _currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', 1, 'departureCityName'])}出发机场`;
		},
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			return flight.getIn(['flightSegments', 1, 'departureAirportCode']) === trimFilterAirport(filterItem);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	BACK_ARRIVE: {
		id: 'FILTER_GROUP_BACK_AIRPORT.BACK_ARRIVE',
		getTitle: (prevCond, _currentSegmentSeq) => {
			return `${prevCond.getIn(['flightSegments', 1, 'arrivalCityName'])}到达机场`;
		},
		filterFlight: (filterItem, flight, _currentSegmentSeq, _isBuildUp) => {
			return flight.getIn(['flightSegments', 1, 'arrivalAirportCode']) === trimFilterAirport(filterItem);
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

/**
 * 舱等
 */
export const FILTER_GROUP_CLASS_GRADE = new Enum({
	DEFAULT: {
		id: 'FILTER_GROUP_CLASS_GRADE.DEFAULT',
		getTitle: (_prevCond, _currentSegmentSeq) => '舱位',
		filterFlight: (_filterItem, _flight, _currentSegmentSeq, _isBuildUp) => {
			// 完全受运价筛选
			return true;
		},
		filterPrice: (filterItem, price) => {
			const currentSegmentCabinList = new Set();
			// 目前只有搜索国内航班才有舱等筛选，而国内航班没有主航段、主舱等概念，所以筛选就用当前程所有航段做筛选： currentSegmentCabinEnumList
			price
				.get('currentSegmentCabinEnumList')
				.valueSeq()
				.forEach((cabinEnum) => {
					const cabinIncludeKeys = cabinEnum.value.includeKeys;
					cabinIncludeKeys && cabinIncludeKeys.length && currentSegmentCabinList.add(cabinIncludeKeys[0]);
				});

			return filterItem.value.includeKeys.some((key) => currentSegmentCabinList.has(key));
		},
	},
});

// 其他
export const FILTER_GROUP_OTHER = new Enum({
	AIRCRAFT_SIZE: {
		id: 'FILTER_GROUP_OTHER.AIRCRAFT_SIZE',
		getTitle: (_prevCond, _currentSegmentSeq) => '机型',
		filterFlight: (filterItem, flight, currentSegmentSeq, isBuildUp) => {
			const getAircraftSizeList = (segmentSeq) => getFlightAircraftSizeList(flight, segmentSeq);

			if (isBuildUp) {
				return getAircraftSizeList(0).includes(filterItem) || getAircraftSizeList(1).includes(filterItem);
			} else {
				return getAircraftSizeList(currentSegmentSeq).includes(filterItem);
			}
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	INVOICE_TYPE: {
		id: 'FILTER_GROUP_OTHER.INVOICE_TYPE',
		getTitle: (_prevCond, _currentSegmentSeq) => '报销凭证',
		filterFlight: (_filterItem, _flight, _currentSegmentSeq, _isBuildUp) => {
			return true;
		},
		filterPrice: (filterItem, price) => {
			return price.get('invoiceType', '').split('_').includes(filterItem);
		},
	},
	// 隐藏共享航班
	HIDE_SHARED_FLIGHTS: {
		id: 'FILTER_GROUP_OTHER.HIDE_SHARED_FLIGHTS',
		getTitle: (_prevCond, _currentSegmentSeq) => '其他',
		filterFlight: (_filterItem, flight, currentSegmentSeq, _isBuildUp) => {
			return !flight
				.getIn(['flightSegments', currentSegmentSeq, 'flightList'])
				.some((flight) => flight.get('operateAirlineCode'));
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
	CONTAINS_TAX: {
		id: 'FILTER_GROUP_OTHER.CONTAINS_TAX',
		getTitle: (_prevCond, _currentSegmentSeq) => '其他',
		filterFlight: (_filterItem, _flight, _currentSegmentSeq, _isBuildUp) => {
			return true;
		},
		filterPrice: (_filterItem, _price) => {
			return true;
		},
	},
});

const genTransItem = (transEnum, minAvgPriceWithoutTax, minAvgPriceWithTax) => {
	return {
		key: transEnum,
		titleForPreview: transEnum.value.title,
		titleForChoose: transEnum.value.title,
		minAvgPriceWithoutTax,
		minAvgPriceWithTax,
		enable: true,
		groupType: FILTER_GROUP_TRANS_COUNT.TRANS_COUNT,
	};
};

const getBasicFilterData = (theEnum, groupType) => {
	return {
		key: theEnum,
		titleForPreview: theEnum.value.title,
		titleForChoose: theEnum.value.title,
		price: 0,
		enable: true,
		groupType,
		minAvgPriceWithoutTax: Infinity,
		minAvgPriceWithTax: Infinity,
		isValid: false,
	};
};

const getPartialTransCountFilter = (domestic) => {
	return {
		seq: 2,
		key: 'transCount',
		type: FILTER_CONTROL_TYPE.CHECK_BOX,
		rememberInSegments: false,
		getRememberInSegments: (filterItemKey) => {
			// V2中该大组的选中状态只有中转次数才需要跨航程保留，中转城市和时长都不需要
			const itemKey = convertFilterItemKeyAsString(filterItemKey);
			return !!TRANS_COUNT.get(itemKey);
		},
		urlSearchKey: 'directflight',
		getActiveKeysFromUrl: ({ searchKey, queryParam }) => {
			let queryVal = queryParam[searchKey] || '',
				result = [],
				transCountEnumType = domestic ? DOMESTIC_TRANS_COUNT_TYPE.ZERO : TRANS_COUNT_TYPE.ZERO;

			if (queryVal === '1') {
				result = [transCountEnumType];
			}

			return result;
		},
		getDefaultActiveKeys: (_flights, _prevCond) => [],
		getVisible: (_isBuildUp, _flightWay, _flights) => true,
	};
};

/**
 * 筛选框 V2
 */
const FILTER_TYPE_V2 = new Enum(
	scopeMapping(
		[{ i: 'TRANS_COUNT', d: 'DOMESTIC_TRANS_COUNT' }],
		scopeField(
			{
				i: ['TRANS_COUNT', 'AIRLINE', 'TIME', 'BUILD_UP_TIME', 'AIRPORT', 'BUILD_UP_AIRPORT', 'OTHER'],
				d: ['DOMESTIC_TRANS_COUNT', 'AIRLINE', 'TIME', 'AIRPORT', 'CLASS_GRADE', 'OTHER'],
			},
			{
				TRANS_COUNT: {
					...getPartialTransCountFilter(false),
					title: '中转',
					groups: [
						{
							col: 0,
							seq: 0,
							type: FILTER_GROUP_TRANS_COUNT.TRANS_COUNT,
							getFlightsWrapper: (flights, currentSegmentSeq, prevCond, isBuildUp) => {
								let segmentSeqList = isBuildUp ? [0, 1] : [currentSegmentSeq],
									getPriceFlightOfTransAndStopAt = (count) => {
										let key = ['ZERO', 'ONE', 'MORE'][count],
											theEnum = TRANS_COUNT_TYPE[key];

										return getBasicFilterData(theEnum, FILTER_GROUP_TRANS_COUNT.TRANS_COUNT);
									};

								let minPriceFlightOfTransAndStopAt0 = getPriceFlightOfTransAndStopAt(0),
									minPriceFlightOfTransAndStopAt1 = getPriceFlightOfTransAndStopAt(1),
									minPriceFlightOfTransAndStopAt2 = getPriceFlightOfTransAndStopAt(2);

								flights.forEach((flight) => {
									let totalCountList = [];

									segmentSeqList.forEach((segmentSeq) => {
										let totalCount = flight.getIn([
											'flightSegments',
											segmentSeq,
											'transferAndStopCount',
										]);
										totalCountList.push(totalCount);
									});

									let minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
										minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']),
										flightAsLowPrice = getIfFlightAsLowPrice(flight);

									if (totalCountList.every((count) => count === 0)) {
										minPriceFlightOfTransAndStopAt0.isValid = true;
										if (flightAsLowPrice) {
											if (
												minAvgPriceWithoutTax <
												minPriceFlightOfTransAndStopAt0.minAvgPriceWithoutTax
											) {
												minPriceFlightOfTransAndStopAt0.minAvgPriceWithoutTax =
													minAvgPriceWithoutTax;
											}

											if (
												minAvgPriceWithTax < minPriceFlightOfTransAndStopAt0.minAvgPriceWithTax
											) {
												minPriceFlightOfTransAndStopAt0.minAvgPriceWithTax = minAvgPriceWithTax;
											}
										}
									} else if (totalCountList.every((count) => count === 1)) {
										minPriceFlightOfTransAndStopAt1.isValid = true;
										if (flightAsLowPrice) {
											if (
												minAvgPriceWithoutTax <
												minPriceFlightOfTransAndStopAt1.minAvgPriceWithoutTax
											) {
												minPriceFlightOfTransAndStopAt1.minAvgPriceWithoutTax =
													minAvgPriceWithoutTax;
											}

											if (
												minAvgPriceWithTax < minPriceFlightOfTransAndStopAt1.minAvgPriceWithTax
											) {
												minPriceFlightOfTransAndStopAt1.minAvgPriceWithTax = minAvgPriceWithTax;
											}
										}
									} else if (totalCountList.every((count) => count >= 2)) {
										minPriceFlightOfTransAndStopAt2.isValid = true;
										if (flightAsLowPrice) {
											if (
												minAvgPriceWithoutTax <
												minPriceFlightOfTransAndStopAt2.minAvgPriceWithoutTax
											) {
												minPriceFlightOfTransAndStopAt2.minAvgPriceWithoutTax =
													minAvgPriceWithoutTax;
											}

											if (
												minAvgPriceWithTax < minPriceFlightOfTransAndStopAt2.minAvgPriceWithTax
											) {
												minPriceFlightOfTransAndStopAt2.minAvgPriceWithTax = minAvgPriceWithTax;
											}
										}
									}
								});

								return {
									transAndStopsAt0: minPriceFlightOfTransAndStopAt0,
									transAndStopsAt1: minPriceFlightOfTransAndStopAt1,
									transAndStopsAt2: minPriceFlightOfTransAndStopAt2,
								};
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								let items = [],
									transAndStopsAt0 = flightsWrapper.transAndStopsAt0,
									transAndStopsAt1 = flightsWrapper.transAndStopsAt1,
									transAndStopsAt2 = flightsWrapper.transAndStopsAt2;

								if (transAndStopsAt0 && transAndStopsAt0.isValid) {
									items.push(
										genTransItem(
											TRANS_COUNT_TYPE.ZERO,
											transAndStopsAt0.minAvgPriceWithoutTax,
											transAndStopsAt0.minAvgPriceWithTax
										)
									);
								}

								if (transAndStopsAt1 && transAndStopsAt1.isValid) {
									items.push(
										genTransItem(
											TRANS_COUNT_TYPE.ONE,
											transAndStopsAt1.minAvgPriceWithoutTax,
											transAndStopsAt1.minAvgPriceWithTax
										)
									);
								}

								if (transAndStopsAt2 && transAndStopsAt2.isValid) {
									items.push(
										genTransItem(
											TRANS_COUNT_TYPE.MORE,
											transAndStopsAt2.minAvgPriceWithoutTax,
											transAndStopsAt2.minAvgPriceWithTax
										)
									);
								}

								return items;
							},
						},
						{
							col: 1,
							seq: 0,
							alwaysKeepTitle: true, // 即便可选筛选项集合为空，也要显示title
							type: FILTER_GROUP_TRANS_CITY.COUNTRY,
							getFlightsWrapper: (flights, currentSegmentSeq, prevCond, _isBuildUp) => {
								let wrapper = getAllTransAndStopCitiesOfFlights(flights, currentSegmentSeq),
									departCountryName = prevCond.get('departCountryName'),
									departProvinceId = prevCond.get('departProvinceId'),
									arrivalCountryName = prevCond.get('arrivalCountryName'),
									arrivalProvinceId = prevCond.get('arrivalProvinceId'),
									twProvinceId = PROVINCE.TW.value.id,
									cnName = COUNTRY.CN.value.name,
									departCountryIsCn = departCountryName === cnName,
									arrivalCountryIsCn = arrivalCountryName === cnName,
									departProvinceIsTW = departProvinceId === twProvinceId,
									arrivalProvinceIsTW = arrivalProvinceId === twProvinceId,
									isValidForDepart = (currentProvinceIsTW) => {
										if (departCountryIsCn) {
											if (!departProvinceIsTW) {
												return true;
											}

											return currentProvinceIsTW;
										} else {
											return true;
										}
									},
									isValidForArrival = (currentProvinceIsTW) => {
										if (arrivalCountryIsCn) {
											if (!arrivalProvinceIsTW) {
												return true;
											}

											return currentProvinceIsTW;
										} else {
											return true;
										}
									},
									// 只有在出发/到达所在国家的筛选项才需要显示到中转城市的国家分组里面，但台湾作为一个独立选项（因为过境签条例不同）
									// 是否需要显示到筛选框的国家分组中
									isValidCountry = (inDepartCountry, inArrivalCountry, currentProvinceIsTW) => {
										if (!inDepartCountry && !inArrivalCountry) {
											return false;
										} else if (inDepartCountry && inArrivalCountry) {
											return (
												isValidForDepart(currentProvinceIsTW) ||
												isValidForArrival(currentProvinceIsTW)
											);
										} else if (inDepartCountry) {
											return isValidForDepart(currentProvinceIsTW);
										} else {
											return isValidForArrival(currentProvinceIsTW);
										}
									};

								let wrapperList = [];

								Object.keys(wrapper).forEach((cityCode) => {
									let currentItem = wrapper[cityCode],
										currentCountryName = currentItem.countryName,
										currentProvinceId = currentItem.provinceId,
										currentProvinceIsTW = currentProvinceId === twProvinceId,
										inDepartCountry = currentCountryName === departCountryName,
										inArrivalCountry = currentCountryName === arrivalCountryName;

									const isValid = isValidCountry(
										inDepartCountry,
										inArrivalCountry,
										currentProvinceIsTW
									);

									if (isValid) {
										wrapperList.push(currentItem);
									}
								});

								return wrapperList;
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								let coutnryItems = {};

								flightsWrapper.forEach((item) => {
									let {
										countryKey,
										countryName,
										cityCode,
										minAvgPriceWithoutTax,
										minAvgPriceWithTax,
									} = item;
									if (countryKey === FILTER_TRANS_CITY_TYPE.MAIN_LAND_GW.key) {
										countryName = FILTER_TRANS_CITY_TYPE.MAIN_LAND_GW.value.title;
									} else if (countryKey === FILTER_TRANS_CITY_TYPE.TW.key) {
										countryName = FILTER_TRANS_CITY_TYPE.TW.value.title;
									}

									let existedCountryItem = coutnryItems[countryKey];
									if (!existedCountryItem) {
										existedCountryItem = coutnryItems[countryKey] = {
											countryKey,
											countryName,
											cityCodeList: [],
											minAvgPriceWithoutTax: Infinity,
											minAvgPriceWithTax: Infinity,
										};
									}

									if (!existedCountryItem.cityCodeList.includes(cityCode)) {
										existedCountryItem.cityCodeList.push(cityCode);
									}

									if (minAvgPriceWithoutTax < existedCountryItem.minAvgPriceWithoutTax) {
										existedCountryItem.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
									}

									if (minAvgPriceWithTax < existedCountryItem.minAvgPriceWithTax) {
										existedCountryItem.minAvgPriceWithTax = minAvgPriceWithTax;
									}
								});

								let wrappedCountryItems = Object.keys(coutnryItems).map((countryKey) => {
									const item = coutnryItems[countryKey];
									return {
										key: countryKey,
										cityCodeList: item.cityCodeList,
										titleForPreview: null,
										titleForChoose: item.countryName,
										minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
										minAvgPriceWithTax: item.minAvgPriceWithTax,
										enable: true,
										groupType: FILTER_GROUP_TRANS_CITY.COUNTRY,
									};
								});

								return wrappedCountryItems.sort(
									(prev, next) => prev.minAvgPriceWithTax - next.minAvgPriceWithTax
								);
							},
						},
						{
							col: 1,
							seq: 1,
							type: FILTER_GROUP_TRANS_CITY.CITY,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllTransAndStopCitiesOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return Object.keys(flightsWrapper)
									.map((cityCode) => {
										const item = flightsWrapper[cityCode];

										return {
											key: cityCode,
											countryKey: item.countryKey,
											titleForPreview: item.cityName,
											titleForChoose: item.cityName,
											minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
											minAvgPriceWithTax: item.minAvgPriceWithTax,
											enable: true,
											groupType: FILTER_GROUP_TRANS_CITY.CITY,
										};
									})
									.sort((prev, next) => prev.minAvgPriceWithTax - next.minAvgPriceWithTax);
							},
						},
						{
							col: 2,
							seq: 0,
							type: FILTER_GROUP_TRANS_TIMESPAN.TRANS_TIMESPAN,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllTransDurationOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return flightsWrapper
									.map((item) => {
										const { key } = item,
											keyEnum = FILTER_TRANS_TIMESPAN_TYPE.get(key);

										return {
											key: keyEnum,
											titleForPreview: keyEnum.value.title,
											titleForChoose: keyEnum.value.title,
											minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
											minAvgPriceWithTax: item.minAvgPriceWithTax,
											enable: true,
											groupType: FILTER_GROUP_TRANS_TIMESPAN.TRANS_TIMESPAN,
										};
									})
									.sort((prev, next) => prev.key.value.seq - next.key.value.seq);
							},
						},
					],
					render: (
						{ filter, allItems, title, containsTax, popup, onClickItem, selectAnyOption },
						options
					) => {
						let theDirectFlightItem = allItems.find((item) => item.get('key').key === 'ZERO'),
							directFlightEnable = theDirectFlightItem ? theDirectFlightItem.get('enable') : false,
							activeKeys = filter.get('activeKeys'),
							clickHandler = filter.get('clickHandler'),
							directFlightItems = allItems
								.filter((item) => item.get('key') === TRANS_COUNT_TYPE.ZERO)
								.toList(),
							allTransCountItems = allItems
								.filter((item) => {
									const key = item.get('key').key;
									return key === 'ONE' || key === 'MORE';
								})
								.toList(),
							filterOfKeepTransCountItem = filter.update('groups', (groups) =>
								groups.set(
									FILTER_GROUP_TRANS_COUNT.TRANS_COUNT.key,
									groups
										.get(FILTER_GROUP_TRANS_COUNT.TRANS_COUNT.key)
										.set('items', allTransCountItems)
								)
							),
							transCountItemsDisable = allTransCountItems.every((t) => {
								return !t.get('enable');
							});

						return (
							<React.Fragment>
								{filterFlatOptionsV2Generator(
									directFlightItems,
									activeKeys,
									clickHandler,
									directFlightEnable,
									TRANS_COUNT_TYPE.ZERO.value.title,
									options
								)}
								<li
									id={`filter_item_trans_count`}
									className={classnames({
										disabled: transCountItemsDisable,
										'filter-item-v2-root-flag': true,
										popup,
									})}
									onClick={transCountItemsDisable ? null : onClickItem}
								>
									<div
										className={classnames({ 'filter-key': true, 'has-selected': selectAnyOption })}
									>
										{title}
										{popup ? (
											<i className="iconf-arrow-up">&#xe604;</i>
										) : (
											<i className="iconf-arrow-down">&#xe60c;</i>
										)}
									</div>
									{popup && filterOptionsGenerator(filterOfKeepTransCountItem, containsTax, options)}
								</li>
							</React.Fragment>
						);
					},
				},
				// 国内专用：中转经停空铁筛选
				DOMESTIC_TRANS_COUNT: {
					...getPartialTransCountFilter(true),
					title: '中转/经停/空铁',
					groups: [
						{
							col: 0,
							seq: 0,
							type: DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								// 直飞/经停
								let filterDataOfDirectAndStopFlight = getBasicFilterData(
									DOMESTIC_TRANS_COUNT_TYPE.ZERO,
									DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT
								);
								// 中转
								let filterDataOfHasTrans = getBasicFilterData(
									DOMESTIC_TRANS_COUNT_TYPE.ALLOW_TRANSFER,
									DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT
								);
								// 空铁
								let filterDataOfFlightTrain = getBasicFilterData(
									DOMESTIC_TRANS_COUNT_TYPE.ALLOW_FLIGHT_TRAIN,
									DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT
								);

								const updateMinPrice = (toUpdateMinPrice, flight) => {
									toUpdateMinPrice.isValid = true;

									let minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
										minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']);

									if (minAvgPriceWithTax < toUpdateMinPrice.minAvgPriceWithTax) {
										toUpdateMinPrice.minAvgPriceWithTax = minAvgPriceWithTax;
									}

									if (minAvgPriceWithoutTax < toUpdateMinPrice.minAvgPriceWithoutTax) {
										toUpdateMinPrice.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
									}
								};

								flights.forEach((flight) => {
									// 国内不含往返组合模式
									const isDirectAndStopFlight = filterFlightOfTransferCount(
										TRANS_COUNT_TYPE.ZERO,
										flight,
										currentSegmentSeq,
										false
									);
									const hasTransfer = getIfFlightHasTransfer(flight, currentSegmentSeq);
									const isFlightTrain = getIfFlightTrain(flight, currentSegmentSeq);

									isDirectAndStopFlight && updateMinPrice(filterDataOfDirectAndStopFlight, flight);
									hasTransfer && updateMinPrice(filterDataOfHasTrans, flight);
									isFlightTrain && updateMinPrice(filterDataOfFlightTrain, flight);
								});

								// 4个筛选项一定都会有，没对应航班时使用禁用样式
								return [filterDataOfDirectAndStopFlight, filterDataOfHasTrans, filterDataOfFlightTrain];
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								// 4个筛选项一定都会有，没对应航班时使用禁用样式
								return flightsWrapper;
							},
						},
					],
					render: ({ filter, allItems, containsTax, popup, onClickItem, selectAnyOption }, options) => {
						let theDirectFlightItem = allItems.find((item) => item.get('key').key === 'ZERO'),
							directFlightEnable = theDirectFlightItem ? theDirectFlightItem.get('enable') : false,
							activeKeys = filter.get('activeKeys'),
							clickHandler = filter.get('clickHandler'),
							directFlightItems = allItems.filter((item) => item.get('key').key === 'ZERO').toList(),
							nonDirectFlightItems = allItems.filter((item) => item.get('key').key !== 'ZERO').toList(),
							groupKey = DOMESTIC_FILTER_GROUP_TRANS_AND_TRAIN.TRANS_COUNT.key,
							filterOfKeepTransCountItem = filter.update('groups', (groups) =>
								groups.set(groupKey, groups.get(groupKey).set('items', nonDirectFlightItems))
							),
							renderNoneDirectFlightItems = nonDirectFlightItems.filter((item) => item.get('isValid')),
							coloredTitle = renderNoneDirectFlightItems.map((item, index) => {
								const title = item.get('key').value.title;
								const valid = item.get('isValid');
								const lastOne = index === renderNoneDirectFlightItems.size - 1;

								// 没有符合的航班就不展示
								return (
									<React.Fragment key={title}>
										<span className={classnames('auto_cursor', { disabled: !valid })}>{title}</span>
										{lastOne ? '' : ' / '}
									</React.Fragment>
								);
							});

						options.groupTitle = coloredTitle;
						options.noPriceText = '暂无';

						let filterGroupsKeyPath = ['groups', groupKey, 'items'];

						// 不可选的筛选项过滤掉
						filterOfKeepTransCountItem = filterOfKeepTransCountItem.updateIn(
							filterGroupsKeyPath,
							(items) => {
								return items.filter((item) => item.get('isValid'));
							}
						);

						// 没有选项就不展示了
						let anyNonDirectFlightItemsTitleIsValid = nonDirectFlightItems.some(
								(item) => !!item.get('isValid')
							),
							anyDirectFlightItems = directFlightItems.some((item) => !!item.get('isValid'));

						// 此处要动态设置 中转/经停/空铁 外露标签的禁用状态
						return (
							<React.Fragment>
								{anyDirectFlightItems &&
									filterFlatOptionsV2Generator(
										directFlightItems,
										activeKeys,
										clickHandler,
										directFlightEnable,
										DOMESTIC_TRANS_COUNT_TYPE.ZERO.value.title,
										options
									)}
								{anyNonDirectFlightItemsTitleIsValid && (
									<li
										id={`filter_item_trans_count`}
										className={classnames('filter-item-v2-root-flag', { popup })}
										onClick={onClickItem}
									>
										<div
											className={classnames({
												'filter-key': true,
												'has-selected': selectAnyOption,
											})}
										>
											{coloredTitle}
											{popup ? (
												<i className="iconf-arrow-up">&#xe604;</i>
											) : (
												<i className="iconf-arrow-down">&#xe60c;</i>
											)}
										</div>
										{popup &&
											filterOptionsGenerator(filterOfKeepTransCountItem, containsTax, options)}
									</li>
								)}
							</React.Fragment>
						);
					},
				},
				AIRLINE: {
					seq: 3,
					key: 'airline',
					title: '航空公司',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: 'airline',
					getActiveKeysFromUrl: ({ searchKey, queryParam }) => {
						let queryVal = queryParam[searchKey] || '',
							searchKeyArray = queryVal.split(','),
							result = [];

						searchKeyArray.forEach((key) => {
							if (key) {
								result.push(key.toUpperCase());
							}
						});

						return result;
					},
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (_isBuildUp, _flightWay, _flights) => true,
					groups: [
						{
							col: 0,
							seq: 0,
							type: FILTER_GROUP_AIRLINE.UNION,
							alwaysKeepTitle: true, // 即便可选筛选项集合为空，也要显示title
							getFlightsWrapper: (flights, currentSegmentSeq, prevCond, isBuildUp) => {
								if (isBuildUp) {
									return getAllAirlineOfFlights(flights, [0, 1]);
								} else {
									return getAllAirlineOfFlights(flights, currentSegmentSeq);
								}
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								let items = [],
									{ map: allianceToAirlineMap, alliance: allAlliance } = flightsWrapper;

								allAlliance &&
									Object.keys(allAlliance).forEach((allianceName) => {
										const airlineList = allianceToAirlineMap[allianceName],
											{ minAvgPriceWithoutTax, minAvgPriceWithTax } = allAlliance[allianceName];

										items.push({
											key: allianceName,
											airlineList,
											titleForPreview: null,
											titleForChoose: allianceName,
											minAvgPriceWithoutTax,
											minAvgPriceWithTax,
											enable: true,
											groupType: FILTER_GROUP_AIRLINE.UNION,
										});
									});

								return items.sort((prev, next) => prev.minAvgPriceWithTax - next.minAvgPriceWithTax);
							},
						},
						{
							col: 0,
							seq: 1,
							type: FILTER_GROUP_AIRLINE.AIRLINE,
							getFlightsWrapper: (flights, currentSegmentSeq, prevCond, isBuildUp) => {
								if (isBuildUp) {
									return getAllAirlineOfFlights(flights, [0, 1]);
								} else {
									return getAllAirlineOfFlights(flights, currentSegmentSeq);
								}
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								const items = [];
								const allAirlines = flightsWrapper.airline;
								allAirlines &&
									Object.keys(allAirlines).forEach((airlineCode) => {
										const airline = allAirlines[airlineCode];
										const { alliance, airlineName, minAvgPriceWithoutTax, minAvgPriceWithTax } =
											airline;
										const getLogoForChoose = () => (
											<AirlineLogo
												className="airline-logo"
												airlineCode={airlineCode}
												airlineName={airlineName}
												size="32"
											/>
										);

										items.push({
											key: airlineCode,
											alliance,
											getLogoForChoose,
											titleForPreview: airlineName,
											titleForChoose: airlineName,
											minAvgPriceWithoutTax,
											minAvgPriceWithTax,
											enable: true,
											groupType: FILTER_GROUP_AIRLINE.AIRLINE,
										});
									});

								return items.sort((prev, next) => prev.minAvgPriceWithTax - next.minAvgPriceWithTax);
							},
						},
					],
				},
				TIME: {
					seq: 4,
					key: 'time',
					title: '起抵时间',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: null,
					getActiveKeysFromUrl: () => null,
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (isBuildUp, _flightWay, _flights) => !isBuildUp,
					groups: [
						{
							col: 0,
							seq: 0,
							type: FILTER_GROUP_TIME.DEPART,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'departure',
									FILTER_DEPART_TIME_TYPE,
									FILTER_GROUP_TIME.DEPART,
									'出发:',
									'出发:'
								);
							},
						},
						{
							col: 1,
							seq: 0,
							type: FILTER_GROUP_TIME.ARRIVE,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									FILTER_ARRIVE_TIME_TYPE,
									FILTER_GROUP_TIME.ARRIVE,
									'到达:',
									'到达:'
								);
							},
						},
					],
				},
				BUILD_UP_TIME: {
					seq: 5,
					key: 'buildUpTime',
					title: '起抵时段',
					titleForNarrow: '',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: null,
					getActiveKeysFromUrl: () => null,
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (isBuildUp, _flightWay, _flights) => isBuildUp,
					groups: [
						{
							// 去程出发
							col: 0,
							seq: 0,
							type: FILTER_GROUP_GO_TIME.GO_DEPART,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, 0);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'departure',
									FILTER_GO_DEPART_TIME_TYPE,
									FILTER_GROUP_GO_TIME.GO_DEPART,
									'去程出发:',
									'出发:'
								);
							},
						},
						{
							// 去程到达
							col: 1,
							seq: 0,
							type: FILTER_GROUP_GO_TIME.GO_ARRIVE,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, 0);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									FILTER_GO_ARRIVE_TIME_TYPE,
									FILTER_GROUP_GO_TIME.GO_ARRIVE,
									'去程到达:',
									'到达:'
								);
							},
						},
						{
							// 返程出发
							col: 0,
							seq: 1,
							type: FILTER_GROUP_BACK_TIME.BACK_DEPART,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, 1);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'departure',
									FILTER_BACK_DEPART_TIME_TYPE,
									FILTER_GROUP_BACK_TIME.BACK_DEPART,
									'返程出发:',
									'出发:'
								);
							},
						},
						{
							// 返程到达
							col: 1,
							seq: 1,
							type: FILTER_GROUP_BACK_TIME.BACK_ARRIVE,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllDepartAndArrivalTimeOfFlights(flights, 1);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructTimeWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									FILTER_BACK_ARRIVE_TIME_TYPE,
									FILTER_GROUP_BACK_TIME.BACK_ARRIVE,
									'返程到达:',
									'到达:'
								);
							},
						},
					],
				},
				AIRPORT: {
					seq: 9,
					key: 'airport',
					title: '机场',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: null,
					getActiveKeysFromUrl: () => null,
					getDefaultActiveKeys: (_flights, prevCond, { currentSegmentSeq, isBuildUp, whileCleanFilter }) => {
						// 重置筛选时候不需要默认勾选机场筛选条件
						if (
							whileCleanFilter ||
							typeof currentSegmentSeq === 'undefined' ||
							typeof isBuildUp === 'undefined'
						) {
							return [];
						} else {
							if (isBuildUp) {
								return [];
							} else {
								const departureAirportCode = prevCond.getIn([
										'flightSegments',
										currentSegmentSeq,
										'departureAirportCode',
									]),
									arrivalAirportCode = prevCond.getIn([
										'flightSegments',
										currentSegmentSeq,
										'arrivalAirportCode',
									]);

								return [
									departureAirportCode ? `[FILTER_GROUP_AIRPORT.DEPART]${departureAirportCode}` : '',
									arrivalAirportCode ? `[FILTER_GROUP_AIRPORT.ARRIVE]${arrivalAirportCode}` : '',
								].filter(Boolean);
							}
						}
					},
					getVisible: (isBuildUp, _flightWay, _flights) => !isBuildUp,
					groups: [
						{
							col: 0,
							seq: 0,
							type: FILTER_GROUP_AIRPORT.DEPART,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'departure',
									'出发:',
									FILTER_GROUP_AIRPORT.DEPART
								);
							},
						},
						{
							col: 1,
							seq: 0,
							type: FILTER_GROUP_AIRPORT.ARRIVE,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									'到达:',
									FILTER_GROUP_AIRPORT.ARRIVE
								);
							},
						},
					],
				},
				BUILD_UP_AIRPORT: {
					seq: 10,
					key: 'buildUpAirport',
					title: '机场',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: null,
					getActiveKeysFromUrl: () => null,
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (isBuildUp, _flightWay, _flights) => isBuildUp,
					groups: [
						{
							type: FILTER_GROUP_GO_AIRPORT.GO_DEPART,
							col: 0,
							seq: 0,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, 0);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'departure',
									'出发:',
									FILTER_GROUP_GO_AIRPORT.GO_DEPART
								);
							},
						},
						{
							type: FILTER_GROUP_GO_AIRPORT.GO_ARRIVE,
							col: 1,
							seq: 0,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, 0);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									'到达:',
									FILTER_GROUP_GO_AIRPORT.GO_ARRIVE
								);
							},
						},
						{
							type: FILTER_GROUP_BACK_AIRPORT.BACK_DEPART,
							col: 0,
							seq: 1,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, 1);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'departure',
									'出发:',
									FILTER_GROUP_BACK_AIRPORT.BACK_DEPART
								);
							},
						},
						{
							type: FILTER_GROUP_BACK_AIRPORT.BACK_ARRIVE,
							col: 1,
							seq: 1,
							getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAirportOfFlights(flights, 1);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return destructAirportWrapperToFlatItems(
									flightsWrapper,
									'arrival',
									'到达:',
									FILTER_GROUP_BACK_AIRPORT.BACK_ARRIVE
								);
							},
						},
					],
				},
				CLASS_GRADE: {
					seq: 11,
					key: 'classGrade',
					title: '舱位',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: true,
					urlSearchKey: 'classgrade',
					getActiveKeysFromUrl: () => null,
					getActiveKeysFromPrevCond: (prevCond) => {
						const cabinEnumInPrevCond = prevCond.get('cabinEnum');
						return cabinEnumInPrevCond && cabinEnumInPrevCond.value.asFilterKeys
							? [cabinEnumInPrevCond]
							: [];
					},
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (_isBuildUp, _flightWay, _flights) => true,
					groups: [
						{
							type: FILTER_GROUP_CLASS_GRADE.DEFAULT,
							getFlightsWrapper: (flights, currentSegmentSeq, prevCond, _isBuildUp) => {
								return getAllCabinPriceForFilter(flights, currentSegmentSeq, prevCond);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								return Object.keys(flightsWrapper)
									.map((cabinKey) => {
										let cabinEnum = CABIN.get(cabinKey),
											item = flightsWrapper[cabinKey];

										return {
											key: cabinEnum,
											titleForPreview: item.sameAsPrevCond ? '' : cabinEnum.value.title,
											titleForChoose: cabinEnum.value.title,
											minAvgPriceWithTax: item.minAvgPriceWithTax,
											minAvgPriceWithoutTax: item.minAvgPriceWithoutTax,
											enable: item.minAvgPriceWithTax !== Infinity,
											groupType: FILTER_GROUP_CLASS_GRADE.DEFAULT,
										};
									})
									.sort((prev, next) => prev.key.value.filterItemSeq - next.key.value.filterItemSeq);
							},
						},
					],
				},
				OTHER: {
					seq: 12,
					key: 'other',
					title: '更多',
					type: FILTER_CONTROL_TYPE.CHECK_BOX,
					rememberInSegments: false,
					urlSearchKey: null,
					getActiveKeysFromUrl: () => null,
					getDefaultActiveKeys: (_flights, _prevCond) => [],
					getVisible: (_isBuildUp, _flightWay, _flights) => true,
					groups: [
						{
							col: 0,
							seq: 0,
							type: FILTER_GROUP_OTHER.AIRCRAFT_SIZE,
							getFlightsWrapper: (flights, currentSegmentSeq, _prevCond, _isBuildUp) => {
								return getAllAircraftSizeOfFlights(flights, currentSegmentSeq);
							},
							getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
								const allAircraftSizes = Object.keys(flightsWrapper);
								return allAircraftSizes
									.map((size) => {
										const theSizeEnum = AIRCRAFT_SIZE.get(size);

										if (theSizeEnum) {
											return {
												key: size,
												titleForChoose: theSizeEnum.value.sizeTitle,
												minAvgPriceWithTax: flightsWrapper[size].minAvgPriceWithTax,
												minAvgPriceWithoutTax: flightsWrapper[size].minAvgPriceWithoutTax,
												enable: true,
												groupType: FILTER_GROUP_OTHER.AIRCRAFT_SIZE,
											};
										} else {
											return null;
										}
									})
									.filter(Boolean);
							},
						},
						...scopeObject({
							i: [
								{
									col: 0,
									seq: 3,
									type: FILTER_GROUP_OTHER.CONTAINS_TAX,
									getFlightsWrapper: (_flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
										return {};
									},
									getFlatItems: (_flightsWrapper, _currentSegmentSeq) => {
										return [
											{
												key: 'containsTax',
												titleForChoose: '看不含税价',
												minAvgPriceWithTax: Infinity,
												minAvgPriceWithoutTax: Infinity,
												enable: true,
												clickHandler: 'onToggleContainsTax',
												hidePrice: true,
												groupType: FILTER_GROUP_OTHER.CONTAINS_TAX,
											},
										];
									},
								},
							],
							d: [
								{
									col: 0,
									seq: 1,
									type: FILTER_GROUP_OTHER.INVOICE_TYPE,
									getFlightsWrapper: (flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
										return getAllInvoiceTypesOfFlights(flights);
									},
									getFlatItems: (flightsWrapper, _currentSegmentSeq) => {
										const invoiceTypes = Object.keys(flightsWrapper);
										return invoiceTypes
											.map((invoiceType) => {
												const theInvoiceType = INVOICE_TYPE[invoiceType];

												if (theInvoiceType) {
													return {
														key: invoiceType,
														titleForChoose: theInvoiceType.title,
														minAvgPriceWithTax:
															flightsWrapper[invoiceType].minAvgPriceWithTax,
														minAvgPriceWithoutTax:
															flightsWrapper[invoiceType].minAvgPriceWithoutTax,
														enable: true,
														groupType: FILTER_GROUP_OTHER.INVOICE_TYPE,
													};
												} else {
													console.error(`unkonwn invoce type: ${invoiceType}`);
													return null;
												}
											})
											.filter(Boolean);
									},
								},
								{
									col: 0,
									seq: 2,
									type: FILTER_GROUP_OTHER.HIDE_SHARED_FLIGHTS,
									getFlightsWrapper: (_flights, _currentSegmentSeq, _prevCond, _isBuildUp) => {
										return {};
									},
									getFlatItems: (_flightsWrapper, _currentSegmentSeq) => {
										return [
											{
												key: 'hideSharedFlights',
												titleForChoose: '隐藏共享航班',
												minAvgPriceWithTax: Infinity,
												minAvgPriceWithoutTax: Infinity,
												enable: true,
												hidePrice: true,
												groupType: FILTER_GROUP_OTHER.HIDE_SHARED_FLIGHTS,
											},
										];
									},
								},
							],
						}),
					],
				},
			}
		)
	)
);

// 往返分屏也会使用V2
export const getFilterTypes = () => FILTER_TYPE_V2;
