import { ubtDevTrace } from '@/src/sources/common/lightUbt';

let report = false;
let reportList = false;
const start = new Date().getTime();
export default function reportCaptcha(url) {
	const hasLoad = typeof window.signature === 'function';

	if (url.indexOf('batchSearch') !== -1) {
		ubtDevTrace('hotel_captcha', { url, hasLoad });
		report = true;
		if (!reportList) {
			reportList = true;
			const spend = new Date().getTime() -  start;
			ubtDevTrace('list_page_batch_search_time', { time: spend, performanceTime: performance ? performance.now() : 0});
			performance && ubtDevTrace('list_page_batch_search_performance_time', { time: performance.now()});
		}
		return;
	}

	if (report && hasLoad) {
		return;
	}
	ubtDevTrace('hotel_captcha', { url, hasLoad });
	report = true;
}
