import Enum from 'enum';
import HOVER_MESSAGE from './hoverMessage';
import INVOICE_TYPE_CONFIG from '../config/invoiceType';

const _LUGGAGE_DIRECT_TYPE = {
	1: {
		preview: HOVER_MESSAGE.DIRECT_LUGGAGE.value.preview,
		title: HOVER_MESSAGE.DIRECT_LUGGAGE.value.title,
		text: HOVER_MESSAGE.DIRECT_LUGGAGE.value.text,
		typeName: HOVER_MESSAGE.DIRECT_LUGGAGE.value.typeName,
		tipTitle: HOVER_MESSAGE.DIRECT_LUGGAGE.value.tipTitle,
		IsNeedHighLight: false,
		good: true,
		sortIndex: 1,
	},
	2: {
		preview: HOVER_MESSAGE.TRANSFER_LUGGAGE.value.preview,
		title: HOVER_MESSAGE.TRANSFER_LUGGAGE.value.title,
		text: HOVER_MESSAGE.TRANSFER_LUGGAGE.value.text,
		typeName: HOVER_MESSAGE.TRANSFER_LUGGAGE.value.typeName,
		tipTitle: HOVER_MESSAGE.TRANSFER_LUGGAGE.value.tipTitle,
		IsNeedHighLight: true,
		good: false,
		sortIndex: 3,
	},
	UNKNOWN: {
		preview: HOVER_MESSAGE.CONSULT_LUGGAGE.value.preview, //航班级别展现，未知情况不需要展现
		title: HOVER_MESSAGE.CONSULT_LUGGAGE.value.title,
		text: HOVER_MESSAGE.CONSULT_LUGGAGE.value.text,
		typeName: HOVER_MESSAGE.CONSULT_LUGGAGE.value.typeName,
		tipTitle: HOVER_MESSAGE.CONSULT_LUGGAGE.value.tipTitle,
		IsNeedHighLight: false,
	},
};
export const LUGGAGE_DIRECT_TYPE = new Enum(_LUGGAGE_DIRECT_TYPE);

export const LUGGAGE_DIRECT_TYPE_V2 = new Enum({
	..._LUGGAGE_DIRECT_TYPE,
	1: {
		preview: HOVER_MESSAGE.DIRECT_LUGGAGE_V2.value.preview,
		title: HOVER_MESSAGE.DIRECT_LUGGAGE_V2.value.title,
		text: HOVER_MESSAGE.DIRECT_LUGGAGE_V2.value.text,
		typeName: HOVER_MESSAGE.DIRECT_LUGGAGE_V2.value.typeName,
		tipTitle: HOVER_MESSAGE.DIRECT_LUGGAGE_V2.value.tipTitle,
		IsNeedHighLight: false,
		good: true,
		sortIndex: 1,
	},
	2: {
		preview: HOVER_MESSAGE.TRANSFER_LUGGAGE_V2.value.preview,
		title: HOVER_MESSAGE.TRANSFER_LUGGAGE_V2.value.title,
		text: HOVER_MESSAGE.TRANSFER_LUGGAGE_V2.value.text,
		typeName: HOVER_MESSAGE.TRANSFER_LUGGAGE_V2.value.typeName,
		tipTitle: HOVER_MESSAGE.TRANSFER_LUGGAGE_V2.value.tipTitle,
		IsNeedHighLight: true,
		good: false,
		sortIndex: 3,
	},
});

export const TRANSIT_VISA_LUGGAGE_TIP_TYPE = new Enum({
	TRANSIT: {
		title_of_single: '转机',
		title_of_mutiple: '转机',
	},
	VISA: {
		title_of_single: '过境签',
		title_of_mutiple: '签证',
	},
	LUGGAGE: {
		title_of_single: '行李',
		title_of_mutiple: '行李',
	},
});
export const TRANSIT_TAG_TYPE = new Enum({
	DIFFERENT_TERMINAL: {
		preview: '不同航站楼中转',
		good: false,
		sortIndex: 5,
	},
	DIFFERENT_AIRPORT: {
		preview: '不同机场转机',
		good: false,
		sortIndex: 4,
	},
	NEED_VISA: {
		preview: '过境需签证',
		good: false,
		sortIndex: 2,
	},
	NOT_NEED_VISA: {
		preview: '无需过境签',
		good: true,
		sortIndex: 2,
	},
});
export const PASSENGER_TYPE = new Enum({
	ADULT: {
		key: 'adult',
		title: '成人',
		priceText: '成人票',
	},
	CHILD: {
		key: 'child',
		title: '儿童',
		priceText: '儿童票',
	},
	INFANT: {
		key: 'infant',
		title: '婴儿',
		priceText: '婴儿票',
	},
});

export const CABIN = new Enum({
	Y_S_C_F: {
		priceDisplaySeq: 6,
		title: '不限舱等',
		shortTitle: '不限',
		includeKeys: ['Y', 'S', 'C', 'F'],
		filterVisibleKey: 'Y_S_C_F',
		isHighCabin: false,
		rank: 6,
		avaliableInFilter: false,
		searchCabinShow: ['d'],
		asFilterKeys: false, // 当搜索框中选中这个舱等进行查询，查完航班后，筛选框中是否需要默认勾选该舱等
	},
	Y_S: {
		priceDisplaySeq: NaN, // 运价处展现的先后顺序
		title: '经济/超经舱',
		shortTitle: '',
		includeKeys: ['Y', 'S'],
		filterVisibleKey: 'Y_S',
		isHighCabin: false, // 是否高舱等
		rank: 1,
		avaliableInFilter: false, // 目前国际航班的筛选也不会出现舱等，只有国内航班有舱等筛选，avaliableInFilter 表示该舱等是否显示在筛选框中
		searchCabinShow: ['i'], // 搜索框下拉仓等显示 'i'国际 'd'国内
	},
	S: {
		priceDisplaySeq: 1,
		title: '超级经济舱',
		shortTitle: '超经',
		includeKeys: ['S'],
		filterVisibleKey: 'Y_S',
		isHighCabin: false,
		rank: 2,
		avaliableInFilter: false,
	},
	Y: {
		priceDisplaySeq: 0,
		title: '经济舱',
		shortTitle: '经济',
		includeKeys: ['Y'],
		filterVisibleKey: 'Y_S',
		isHighCabin: false,
		rank: 1,
		avaliableInFilter: true,
		searchCabinShow: ['d'],
		asFilterKeys: true,
	},
	C_F: {
		priceDisplaySeq: NaN,
		title: '公务/头等舱',
		shortTitle: '',
		includeKeys: ['C', 'F'],
		filterVisibleKey: 'C_F',
		isHighCabin: true,
		rank: 3,
		avaliableInFilter: true,
		searchCabinShow: ['i', 'd'],
		asFilterKeys: true,
	},
	C: {
		priceDisplaySeq: 2,
		title: '公务舱',
		shortTitle: '公务',
		includeKeys: ['C'],
		filterVisibleKey: 'C_F',
		isHighCabin: true,
		rank: 3,
		avaliableInFilter: false,
		searchCabinShow: ['i'],
	},
	F: {
		priceDisplaySeq: 3,
		title: '头等舱',
		shortTitle: '头等',
		includeKeys: ['F'],
		filterVisibleKey: 'C_F',
		isHighCabin: true,
		rank: 4,
		avaliableInFilter: false,
		searchCabinShow: ['i'],
	},
});
const filterCanbin = (showInType) => {
	let filterCabin = {};
	CABIN.enums.map((item) => {
		if (item.value.searchCabinShow && item.value.searchCabinShow.indexOf(showInType) >= 0) {
			filterCabin[item.key] = item.value.title;
		}
	});
	return new Enum(filterCabin);
};
export const CABIN_INTERNATIONAL = filterCanbin('i');
export const CABIN_DOMESTIC = filterCanbin('d');

export const INVOICE_TYPE = new Enum(INVOICE_TYPE_CONFIG);

export const DATE_PICKER_TITLE = new Enum({
	OW: {
		depart: ['选择出发日期'],
	},
	RT: {
		depart: ['选择去程日期'],
		arrival: ['选择返程日期'],
	},
	MT: {
		depart: ['选择出发日期'],
	},
});

export const DATE_PICKER_FOOTER_TIPS = new Enum({
	PRICE: {
		OW: {
			depart: ['价格变动频繁，以实际为准'],
		},
		RT: {
			depart: [],
			arrival: ['当前显示的价格为往返总价，价格变动频繁，以实际为准'],
		},
		MT: {
			depart: [],
			arrival: [],
		},
	},
	TIME: {
		OW: {
			depart: ['所选日期均为当地时间'],
		},
		RT: {
			depart: ['所选日期均为当地时间'],
			arrival: ['所选日期均为当地时间'],
		},
		MT: {
			depart: ['所选日期均为当地时间'],
			arrival: ['所选日期均为当地时间'],
		},
	},
});
