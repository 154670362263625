import React from 'react';

const scrollWidth = window.innerWidth - window.document.body.clientWidth + 'px';

class PopDialog extends React.Component {
    constructor(props) {
        super(props)
        this.computePosition = this.computePosition.bind(this)
        this.resetPosition = this.resetPosition.bind(this)
        this.prePosition = null
        this.containerElRef = React.createRef()
    }

    componentDidMount() {
        this.resetPosition()
        // 需要适配windows系统不能滚动时，滚动条消失导致的页面宽度变化问题
        document.body.style.paddingRight = scrollWidth;
        document.body.style.overflow = 'hidden'
    }
    
    componentWillUnmount() {
        document.body.style.paddingRight = '0'
        document.body.style.overflow = 'auto'
    }

    resetPosition() {
        const { parentPosition } = this.props
        let target = this.containerElRef.current
        if (!parentPosition) {
            target.style.top = '50%'
            target.style.left = '50%'
            target.style.transform = 'translate(-50%, -50%)'
        } else {
            let cPosition = this.prePosition = this.computePosition()

            target.style['left'] = cPosition.left + "px"
            target.style['top'] = cPosition.top + "px"
            target.style['max-height'] = cPosition.maxHeight + 'px'
        }
    }

    computePosition() {
        const content = this.containerElRef.current
        const contentHeight = content.clientHeight, contentWidth = content.clientWidth;
        const bodyHeight = document.documentElement.clientHeight;
        const { parentPosition } = this.props;
        const RESERVEHEIGHT = 5
        const { left, top, right, bottom, leftSide } = parentPosition;
        let cPosition = { maxHeight: '70%' };
        if (!this.prePosition) {
            if (bodyHeight - bottom >= contentHeight + RESERVEHEIGHT) cPosition = { top: bottom, vertical: 'bottom' }
            else if (top > contentHeight + RESERVEHEIGHT) cPosition = { top: top - contentHeight, vertical: 'top' }
            else if (bodyHeight - bottom >= top) {
                cPosition = { top: bottom, maxHeight: Math.min(contentHeight, bodyHeight - bottom) - RESERVEHEIGHT, vertical: 'bottom' }
            }
            else cPosition = { top: RESERVEHEIGHT, maxHeight: Math.min(contentHeight, top) - RESERVEHEIGHT, vertical: 'top' }

            if (leftSide) cPosition = { ...cPosition, left: right - contentWidth }
            else cPosition = { ...cPosition, left: left }
        } else {
            let { vertical } = this.prePosition;
            if (vertical == 'top') {
                if (top > contentHeight + RESERVEHEIGHT) cPosition = { top: top - contentHeight, vertical: 'top' }
                else cPosition = { top: RESERVEHEIGHT, maxHeight: Math.min(contentHeight, top) - RESERVEHEIGHT, vertical: 'top' }
            } else {
                if (bodyHeight - bottom >= contentHeight + RESERVEHEIGHT) cPosition = { top: bottom, vertical: 'bottom' }
                else {
                    cPosition = { top: bottom, maxHeight: Math.min(contentHeight, bodyHeight - bottom) - RESERVEHEIGHT, vertical: 'bottom' }
                }
            }
        }
        return cPosition
    }

    render() {
        const { onClickBackground, parentPosition, lotteryPopup } = this.props
        const contents = React.cloneElement(this.props.contents)
        return (
            <div>
                <div id="outerContainer-background-translucent" className={`background-translucent ${!parentPosition ? '' : 'transparent'}`} onClick={() => { onClickBackground && onClickBackground() }} style={{ zIndex: lotteryPopup === 'lotteryPopup' ? 2001 : '' }} />
                <div style={Object.assign({ position: "fixed", zIndex: 2000 }, parentPosition ? {} : { left: "50%", top: "50%" }, this.props.style || {})}
                    ref={this.containerElRef} id="outerContainer" className={`custom-srcoll-bar ${!parentPosition ? '' : 'flight-info-outer-container'}`}>
                    {contents}
                </div>
            </div>
        )
    }
}

export default PopDialog;
