const CONFIG_KEYS = {
  CONSTS: 'listConstConfig',
  PRICE_MSG: 'listPriceMsgConfig',
  PRODUCT_MSG: 'listProductMsgConfig',
  DEMAND_FORM_MSG: 'listDemandFormMsgConfig',
  FLIGHT_TRAIN_MSG: 'listFlightTrainMsgConfig',
  FLIGHT_ROW: 'listFlightRowMsgConfig',
  POPUP: 'listPopUpMsgConfig',
  COUPON: 'listCouponConfig',
  BEST_CHOICE_FLIGHTS_SORT: 'listBestChoiceFlightsSortConfig',
  OW_RECOMMEND_RT: 'listOwRecommendRtConfig',
  LIST_TIP_CONFIG: 'listTipConfig',
  AD_CONFIG: 'adConfig',
  DOMESTIC_TRAIN_CABIN: 'd_trainCabinConfig',
  LIST_FLIGHT_HOVER_MESSAGE: 'listFlightHoverMessage',
};

export default CONFIG_KEYS;
