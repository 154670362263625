import mergeConfig from '../../common/mergeConfig';
import Q_CONFIG_KEYS from '../qConfigKeys';
import { scopeMapping } from '../../../sources/list/scopeMapping';
import styles from './productMessage.module.scss';

const PRODUCT_MESSAGE = {
    ITINERARY_TAG: {
        // NightFlight: '夜间航班',
        // SuperFlyer: '超级飞人',
        // SuperFlyerFullTag: '<span class="tag tag-warn">超飞</span> 临近起飞',
        // Flyer: '飞人',
        // FlyerFullTag: '<span class="tag tag-warn">飞人</span> 临近起飞',
        FlightGift: '航班礼盒',
    },
    //精选航班
    BEST_CHOICE: {
        DirectCheapestChoice: '精选·直飞低价',
        CheapestChoice: '精选·当日低价',
        CheaperTimeSavingChoice: '精选·便宜省时',
        HigherCostPerformance: '精选·高性价比',
        ShortestTimeChoice: '精选·超省时', //以前叫 精选·耗时短
        DepartAtEveningLowPrice: '精选·晚上出发低价',
        DomesticeTransferLowPrice: '精选·国内中转低价',
        FiveStarAirlineLowPrice: '精选·五星航司低价',
        HkTransferVisaFreeChoice: '精选·中转免签玩香港',
        MacaoTransferVisaFreeChoice: '精选·中转免签玩澳门',
        ArriveAtAfternoonLowPrice: '精选·下午到达低价',
        DepartAtForenoonLowPrice: '精选·上午出发低价',
        MinorShortestTimeChoice: '精选·耗时短',
        MinorFiveStarAirlineLowPrice: '精选·五星航司',
        MinorNonNightFlight: '精选·避开红眼',
        HotSaleFlight: '精选·热销航班',
        ForChildPostfix: '·适合儿童',
        ForInfantPostfix: '·适合婴儿',
        ForChildAndInfantPostfix: '·适合婴童',
        FiveStarPostfix: '·五星航司',
    },
    //精选航班的HOVER片段
    BEST_CHOICE_HOVER: {
        CommonTitle: '精选航班',
        DirectCheapestChoice: '当日出发价格低的直飞航班', //以前是'当日出发价格最低的直飞航班'
        CheapestChoice: '当日出发低价', //以前是'当日出发最低价'
        CheaperTimeSavingChoice: '便宜又省时的中转航班',
        HigherCostPerformance: '便宜又省时的中转航班',
        ShortestTimeChoice: '耗时最短的中转航班',
        DepartAtEveningLowPrice: '晚上出发的低价航班，少请一天假',
        DomesticeTransferLowPrice: '国内中转，更省心',
        FiveStarAirlineLowPrice: '享五星航司优质服务，乘机更舒适',
        HkTransferVisaFreeChoice: '一张机票，玩两个城市',
        MacaoTransferVisaFreeChoice: '一张机票，玩两个城市',
        ArriveAtAfternoonLowPrice: '下午到达的低价航班，入住后立即玩',
        DepartAtForenoonLowPrice: '上午出发的低价航班',
        MinorShortestTimeChoice: '耗时最短的中转航班',
        MinorFiveStarAirlineLowPrice: '享五星航司优质服务',
        MinorNonNightFlight: '避开红眼',
        HotSaleFlight: '销量超高的航班',
        ForChildPostfix: '起降时间好，适合儿童',
        ForInfantPostfix: '起降时间好，适合婴儿',
        ForChildAndInfantPostfix: '起降时间好，适合儿童婴儿',
        FiveStarPostfix: '享五星航司优质服务',
    },
    // // ITINERARY_TAG_HOVER: {
    //     // NightFlight: '指深夜或凌晨起飞的航班',
    //     // SuperFlyer: {
    //     //     title: '超飞',
    //     //     hint: '携程为有紧急出行需求的用户推出的一项便捷服务',
    //     //     text: '该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间。'
    //     // },
    //     // Flyer: {
    //     //     title: '飞人',
    //     //     hint: '携程为有紧急出行需求的用户推出的一项便捷服务',
    //     //     text: '该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间，该航班已临近起飞时间。'
    //     // }
    // // },
    FLYER_RELATED: {
		Flyer: {
			tag: '<span class="tag tag-warn">临近起飞</span>',
			tooltipTitle: `<span class="${styles.titleStyle}">临近起飞</span>`,
			tooltipContent: `<div class="${styles.contentStyle} ${styles.marginBottom}">享受“飞人通道”服务只能使用国内信用卡来支付机票款和其他相关费用。</div><div class="${styles.contentStyle} ${styles.marginBottom}">电子机票行程单（报销凭证）将由我们邮寄到您指定的地址。</div><div class="${styles.contentStyle}">如果您在机场办理登机手续时发生问题，请立刻电话联系我们，电话：95010。</div>`,
		},
		SuperFlyer: {
			tag: `<span class="tag tag-warn">${window.GlobalConfigs.superFlyConfig || '2小时内起飞'}</span>`,
			tooltipTitle: `<span class="${styles.titleStyle}">${window.GlobalConfigs.superFlyConfig || '2小时内起飞'}</span>`,
			tooltipContent: `<div class="${styles.contentStyle} ${styles.marginBottom}">购票前请先到值机柜台确认出票后仍有时间值机(支付成功后需5分钟完成出票)。</div><div class="${styles.contentStyle}">若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。</div>`,
		},
	},
    d_FLYER_RELATED: {
        Flyer: {
            tag: '<span class="tag tag-warn">临近起飞</span>',
            tooltipTitle: '临近起飞',
            tooltipContent:
                '1.本航班已临近起飞时间，购票前请先到值机柜台确认出票后仍有时间值机（支付成功后需5分钟完成出票）。<br>2.若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。',
            tooltipContentDefault: '1.本航班已临近起飞时间，购票前请先到值机柜台确认出票后仍有时间值机。<br>2.若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。',
        },
        SuperFlyer: {
            tag: '<span class="tag tag-warn">临近起飞</span>',
            tooltipTitle: '临近起飞',
            tooltipContent:
                '1.本航班已临近起飞时间，购票前请先到值机柜台确认出票后仍有时间值机（支付成功后需5分钟完成出票）。<br>2.若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。',
            tooltipContentDefault: '1.本航班已临近起飞时间，购票前请先到值机柜台确认出票后仍有时间值机。<br>2.若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。',
        },
    },
    PRODUCT_TAG: {
        TransitPU: '多票组合',
        MultiPU: '组合特价',
        FlagShipStore: '航司旗舰店',
        AirlineAuthorizedStore: '航司官方授权店',
        LowestPrice: '航司直销',
        SpecialRefund24Hours: '24小时特惠退',
        SpecialRefund2Hours: '2小时特惠退',
        Prioritizing: '出行优选',
        Exclusive: '旅行套餐',
        BaggagePackage: '行李套餐',
        GuangfaExclusive: '广发专享',
        BusinessRecommend: '商务推荐',
        SpecialRecommend: '特别推荐',
        Cloud: '特惠推荐',
    },
    PRODUCT_TAG_HOVER: {
        TransitPUHasRisk:
            '<h4>什么是多票组合？</h4>(1) 多票组合与航司联运中转航班不同，是多张机票自行拼接的行程，需在换乘地“TRANS_CITY_NAME”提取和托运行李，重新出入关，办理登机手续，行李额（重量/数量）可能有差异。<br />(2) 请注意查询中转地/目的地所属国家的签证政策，确保备齐所需签证后再预订。<br /><br /><h4>有哪些风险需要乘客注意？</h4>包括但不限于乘客证件不齐、乘客不合理安排换乘时间等原因导致无法搭乘任一航班，需乘客自行对每张票分开退票改期，损失将由乘机人自行承担。',
        TransitPUNoRisk:
            '<h4>什么是多票组合？</h4>多票组合与航司联运中转航班不同，是多张机票自行拼接的行程，需在换乘地“TRANS_CITY_NAME”提取和托运行李，行李额（重量/数量）可能有差异。<br /><br /><h4>有哪些风险需要乘客注意？</h4>包括但不限于乘客证件不齐、乘客不合理安排换乘时间等原因导致无法搭乘任一航班，需乘客自行对每张票分开退票改期，损失将由乘机人自行承担。',
        MultiPU: '此为组合特价产品，机票将分开出票。出票后，如遇航变及退改签等问题，需分开处理；若产生费用将分开收取。',
        SpecialRefund24Hours: '支付完成24小时内退票，供应商仅收取小额退票服务费；支付完成24小时后，以具体的退改规则为准',
        SpecialRefund24HoursWithoutFee: '支付完成24小时内退票，退票免费；支付完成24小时后，以具体的退改规则为准',
        SpecialRefund24HoursWithFee: '支付完成24小时内退票，供应商仅收取退票服务费¥[VAL]/人；支付完成24小时后，以具体的退改规则为准',
        SpecialRefund2Hours: '支付完成2小时内退票，供应商仅收取小额退票服务费；支付完成2小时后，以具体的退改规则为准',
        SpecialRefund2HoursWithoutFee: '支付完成2小时内退票，退票免费；支付完成2小时后，以具体的退改规则为准',
        SpecialRefund2HoursWithFee: '支付完成2小时内退票，供应商仅收取退票服务费¥[VAL]/人；支付完成2小时后，以具体的退改规则为准',
        Prioritizing: '出行优选机票服务有保障，我们提供：<br>1.快速出票；<br>2.提供行程单或者机票发票，报销无忧；<br>3.服务优先：尊享7×24小时的全天候服务。',
        PrioritizingExceptEInvoice: '出行优选机票服务有保障，我们提供：<br>1.快速出票；<br>2.服务优先：尊享7×24小时的全天候服务。',
        Exclusive: '',
        TransitPU_Summary_Header: `什么是多票组合？`,
        TransitPU_Summary_Content: `携程为用户推出的灵活出行产品。通过智能计算，自行拼接多个独立航班，获得更优选择及价格`,
        TransitPU_Advantage_Split: `产品优势`,
        TransitPU_Ensure_Header: `多票产品携程保障出行`,
        TransitPU_Ensure_Content: `如因航空公司原因引起的一程航班调整或取消，导致无法正常搭乘后续航班，携程将<span class='highlight'>保障您到达目的地或对未使用机票全额退款。</span><a href='//pages.c-ctrip.com/flight/promise/web.html' target='_blank' class='link'>了解更多 ></a>`,
        TransitPU_Caution_Split: `注意事项`,
        TransitPU_Boarding_Header: `换乘需重新办理登机手续`,
        TransitPU_Boarding_Content: `多票组合产品为多个独立航班，换乘时需重新办理登机手续。<span class='highlight'>请合理安排换乘时间、确保不同航段证件携带齐全。</span>因证件不齐、乘客不合理安排换乘时间等原因导致无法登机或出行，需乘机人自行承担责任。`,
        TransitPU_Visa_Header: `换乘地过境类签证`,
        TransitPU_Visa_Content: `在换乘地“[CITY_LIST]”需重新出入关，请注意查询相关过境地签证政策，确保备齐所需签证后再预订。`,
        TransitPU_Luggage_Header: `换乘需重新托运行李`,
        TransitPU_Luggage_Content: `<span class='highlight'>换乘时需提取行李并重新办理托运；</span>不同航班行李额(重量/数量)可能有差异，预订前请仔细查看行李规定。`,
    },
    //预订须知
    BOOK_NOTICE: {
        TransitPUPart1: `<h4>多票组合 出行保障</h4>携程保障服务保障您出行无忧。如因航空公司原因引起一程航班调整或取消，导致无法正常搭乘后续航班，携程将保障您到达目的地或对未使用机票全额退款。<a href='//pages.c-ctrip.com/flight/promise/web.html' target='_blank'>了解更多></a><br/>`,
        // TransitPUHasRiskPart2: '<h4>预订前请确认您已了解并同意以下信息：</h4>(1) 多票组合与航司联运中转航班不同，是多张机票自行拼接的行程，需在换乘地“TRANS_CITY_NAME”提取和托运行李，重新出入关，办理登机手续，行李额（重量/数量）可能有差异，请留出足够换乘时间。<br/>(2) 请注意查询中转地/目的地所属国家的签证政策，确保备齐所需签证后再预订。[EXT_VISA_TIP]<br/>(3) 包括但不限于乘客证件不齐、乘客不合理安排换乘时间等原因导致无法搭乘任一航班，需乘客自行对每张票分开退票改期，损失将由乘机人自行承担。',
        TransitPUHasRiskPart2WithExtTip:
            '<h4>预订前请确认您已了解并同意以下信息：</h4>(1) 多票组合与航司联运中转航班不同，是多张机票自行拼接的行程，需在换乘地“TRANS_CITY_NAME”提取和托运行李，重新出入关，办理登机手续，行李额（重量/数量）可能有差异，请留出足够换乘时间。<br/>(2) 请注意查询中转地/目的地所属国家的签证政策，确保备齐所需签证后再预订。[EXT_VISA_TIP]<br/>(3) 包括但不限于乘客证件不齐、乘客不合理安排换乘时间等原因导致无法搭乘任一航班，需乘客自行对每张票分开退票改期，损失将由乘机人自行承担。',
        TransitPUNoRiskPart2:
            '<h4>预订前请确认您已了解并同意以下信息：</h4>(1) 多票组合与航司联运中转航班不同，是多张机票自行拼接的行程，需在换乘地“TRANS_CITY_NAME”提取和托运行李，行李额（重量/数量）可能有差异，请留出足够换乘时间。<br />(2) 包括但不限于乘客证件不齐、乘客不合理安排换乘时间等原因导致无法搭乘任一航班，需乘客自行对每张票分开退票改期，损失将由乘机人自行承担。',
    },
    BOOK_PRODUCT_TAG: {
        Exclusive: '旅游优惠券(200000积分)',
    },
};

export default scopeMapping(
	[{ i: 'FLYER_RELATED', d: 'd_FLYER_RELATED' }],
	mergeConfig(Q_CONFIG_KEYS.PRODUCT_MSG, PRODUCT_MESSAGE)
);
