import React, { useState, useCallback } from "react"
import PopDialog from '../common/PopDialog'
/**
 * 伤残军警弹窗
 *
 */

const ArmyPopup = ({ ...props }) => {
    const { show, cancel, confirm } = props
    const [agree, setAgree] = useState(false)
    const onContinue = useCallback(() => {
        if (agree) {
            confirm()
        }
    }, [agree])
    return (
        !show ? null : (
            <PopDialog contents={
                <div className="basic-alert popup-commmon"  >
                    <div className="alert-title">残疾军人（警察）预订须知</div>
                    <div className="alert-body">
                        <div className="alert-info">1.残疾军人（警察）可通过此通道购买经济舱（头等舱）5折优惠机票。也可以选择购买其他种类票价，并执行相应的限制条件。</div>
                        <div className="alert-info">2.购票时，请务必上传您的<span className="warn">2020年起更新的新版</span>残疾军人（警察）证件信息，并确认出行时在证件有效期内。</div>
                        <div className="alert-info">3.乘机时，请务必提供您的残疾军人（警察）证件和订票时使用的有效身份证件。</div>
                        <div className="alert-info warn">4.如乘机人伪造、冒充军人（警察）身份属于严重违法行为，需承担法律责任。</div>
                        <div className="alert-info" onClick={() => { setAgree(!agree) }}>
                            <i className={`ico-checkbox ${agree ? 'active' : ''}`}></i>我已阅读并同意以上条款
                    </div>

                    </div>
                    <div className="alert-footer">
                        <div className="btn-group">
                            <a href="javascript:;" className="btn  btn-ghost" onClick={cancel} >取消</a>
                            <a href="javascript:;" className={`btn ${agree ? '' : 'disabled'}`} onClick={onContinue}>继续预订</a>
                        </div>
                    </div>
                </div>

            }
            />
        )
    )
}

export default ArmyPopup
