import React, { useState, useEffect } from 'react';

import LiveChat from '../../Base/liveChat';
import { getScrollY } from '../../../sources/common/getScrollY';
import { windowScrollToTop } from '../../../sources/common/triggerEvent';
import { getIfScopeDomestic } from '../../../sources/list/scope';

let isScroll = false;
export const Shortcut = () => {
	const [scrollTop, setScrollTop] = useState(0);

	useEffect(() => {
		const handleScroll = () => {
			if (!isScroll) {
				isScroll = true;
				setTimeout(() => {
					const currentY = getScrollY();
					if (scrollTop !== currentY) {
						setScrollTop(currentY);
					}

					isScroll = false;
				}, 500);
			}
		};

		setTimeout(() => {
			window.addEventListener('scroll', handleScroll);
		}, 5000);

		return () => window.removeEventListener('scroll', handleScroll);
	});

	return (
		<div className="shortcut">
			<a
				className="scroll-top"
				href="#!"
				style={{ display: scrollTop >= 300 ? 'block' : 'none' }}
				onClick={() => windowScrollToTop(0)}
				aria-label="点击置顶"
			>
				&nbsp;
			</a>
			<LiveChat className="shortcut-link" domestic={getIfScopeDomestic()} />
		</div>
	);
};
