import { getIfScopeInternational } from "./scope"

const getValidFields = (target, fields) => {
    if (!fields) {
        fields = '*'
    }

    const allFields = Object.keys(target)
    const typeOfFields = typeof fields

    if (typeOfFields === 'string') {
        fields = [fields.trim()]
    }

    if (Array.isArray(fields) && fields.length === 1 && fields[0] === '*') {
        // 返回对象的所有字段
        return allFields
    }

    if (!Array.isArray(fields) || fields.some(field => !field || typeof field !== 'string')) {
        console.error('target:', target, 'fields:', fields)
        throw `传入的 fields 字段如果不为空，就只能是字符串或包含字符串的数组，但现在得到的类型是 ${typeOfFields}`
    }

    // 无效字段
    const invalidFields = fields.filter(filed => !allFields.includes(filed))
    if (invalidFields.length) {
        console.error(target, fields, `fields中包含无效字段: ${invalidFields.join(', ')}`)
    }

    return fields.filter(filed => !invalidFields.includes(filed))
}

/**
 * 使用范围：对象的字段筛选
 * 目的：target是待处理的对象，i 和 d 是搜索国际航班和国内航班时，需要 target 对象中返回的有效字段，
 * i 和 d 可为空 或 字符串 * 或字段名组成的数组，为空或*表示所有字段都是有效字段，
 * 但需要注意的是，返回的对象就是确定的值，后续如果再出现搜索国际航班变成搜索国内航班，或搜索国内航班变成搜索国际航班，
 * 下面的判断逻辑不会主动再次进入重新获取
 */
export const scopeField = ({ i: internationalFields, d: domesticFields }, target) => {
    const typeOfTarget = typeof target
    if (target && typeOfTarget === 'object') {
        const isInternational = getIfScopeInternational()
        const validFields = getValidFields(target, isInternational ? internationalFields : domesticFields)

        return validFields.reduce((currentTarget, next) => {
            currentTarget[next] = target[next]
            return currentTarget
        }, {})
    } else {
        console.error(target)
        throw `只能针对有效对象进行的字段进行筛选，但传参是${typeOfTarget}`
    }
}