export default {
    cityPriceFetchStateEnum: {
        /**
         * ajax加载完成
         */
        done: "done",
        /**
         * 表单条件检测失败
         */
        formerror: "formerror",
        /**
         * ajax请求中
         */
        pending: "pending"
    },
    pageFromEnum: {
        /**
         * 大首页
         */
        ctripHome: "ctrip_home",
        /**
         * 频道首页
         */
        channelHome: "channel_home",
        /**
         * 地图页
         */
        priceMap: "price_map",
        /**
         * 列表页
         */
        list: "list"
    },
    /**
     * 价格所在位置
     */
    priceLocationEnum: {
        cityList: "city_list",
        map: "map"
    },
    /**
     * 搜索条件的元素类别
     */
    searchElementType: {
        flightWay: "flightWay",
        dCity: "dCity",
        aCity: "aCity",
        dDate: "dDate",
        aDate: "aDate"
    }
}