import React from 'react'

export default class extends React.Component {
    static defaultProps = {
        preposeResult: null
    }
    constructor(props) {
        super(props)
    }
    render() {
        const preposeResult = this.props.preposeResult
        if (preposeResult == null) {
            return null
        }
        return (
            <div className="tooltip tooltip-membership">
                <div className="membership-title">
                    <span className={preposeResult.diamondUser ? "title-high-light" : "title-high-light2"}>{preposeResult.titleType}</span>
                    预订享以下乘机礼遇
                    </div>
                <div className="membership-item-container">
                    {
                        preposeResult.serviceList.map(service => {
                            let iconClassName = ""
                            if (service.name.includes("值机")) {
                                iconClassName = "icon-checkin"
                            } else if (service.name.includes("行李")) {
                                iconClassName = "icon-luggage"
                            } else if (service.name.includes("登机")) {
                                iconClassName = "icon-get-on"
                            }
                            return (
                                <div className="membership-item" key={service.name}>
                                    <span className={`membership-icon ${iconClassName}`}></span>
                                    <div className="item-title">{service.name}</div>
                                    <div className="item-desc">{service.desc}</div>
                                </div>
                            )
                        })
                    }
                </div>
                {
                    !preposeResult.diamondUser && !preposeResult.superVipUser && (
                        <div className="vip-qrcode">
                            <img className="qr-img" src={preposeResult.config.superQRCodeUrl} alt=""/>
                            <div className="tip-txt">您还不是超级会员，手机扫描二维码加入超级会员可享此权益</div>
                        </div>
                    )
                }
                <div className="service-desc">
                    {preposeResult.config.tip}
                </div>
                <div className="activity-title">活动说明</div>
                <div className="activity-desc" dangerouslySetInnerHTML={{ __html: preposeResult.config.activityDesc }}></div>
            </div>
        )
    }
}
