import React from 'react'
import Lib from "../../utils/lib"

const pageIdEl = document.getElementById("page_id")
const getPageId = () => pageIdEl ? pageIdEl.value : ''
const host = Lib.isFatEnvironment() ? "servicechat.fat2469.qa.nt.ctripcorp.com" : "servicechat.ctrip.com"
const defaultConfig = {
    domestic: {
        channel: 'flights',
        isPreSale: 1,
        bizType: '1325'
    },
    international: {
        channel: 'FlightIntlOnline',
        isPreSale: 1,
        bizType: '1335'
    }
}

const getUrl = (domestic) => {
    let url = `//${host}/?pageCode=${getPageId()}`
    let config = defaultConfig[domestic ? 'domestic' : 'international']

    Object.keys(config).map(key => {
        url += `&${key}=${config[key]}`
    })

    return url
}
const LiveChat = (props) => {
    let { className, domestic } = props

    return (
        <a target="_blank" rel="noopener noreferrer" className={className} href={getUrl(domestic)} title="在线客服">
            在线客服
        </a>
    )
}
export default LiveChat

