import { fetchGet, fetchPost } from "../../actions/common";

let remoteTooltipCache = {},
    remoteTooltipQueue = {}

const getRemoteTooltip = (wrapper) => {
    return new Promise((resolve, reject) => {
        let tag = wrapper.tag.value,
            url = tag.getUrl(wrapper.data),
            httpMethod = tag.httpMethod || 'GET',
            fianlFetch = httpMethod === 'GET' ? fetchGet : fetchPost,
            urlParams = tag.getUrlParams(wrapper.data),
            cacheKey = `${url}_${JSON.stringify(urlParams)}`,
            extractData = tag.extractData || (data => data)

        if (remoteTooltipCache[cacheKey]) {
            resolve(extractData(remoteTooltipCache[cacheKey]))
        } else {
            if (remoteTooltipQueue[cacheKey]) {
                remoteTooltipQueue[cacheKey].push({ resolve, reject })
            } else {
                remoteTooltipQueue[cacheKey] = []
                remoteTooltipQueue[cacheKey].push({ resolve, reject })

                fianlFetch(url, urlParams)
                    .then(res => res && res.data || {})
                    .then(body => {
                        let data = body.data
                        remoteTooltipCache[cacheKey] = data

                        if (extractData) {
                            data = extractData(data)
                        }

                        remoteTooltipQueue[cacheKey].forEach(queue => queue.resolve(data))
                        remoteTooltipQueue[cacheKey] = []
                    })
                    .catch(err => remoteTooltipQueue[cacheKey].forEach(queue => queue.reject(err)))
            }
        }
    })
}

export { getRemoteTooltip }
