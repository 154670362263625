import React from 'react';
import classnames from 'classnames'
import CalendarLowPriceHeader from './header.js';
import SingleCalendarLowPrice from './single.js';
import RoundCalendarLPMonth from './roundMonth.js'
import TrendCalendarContainer from '../../../../containers/list/top/calendarLowprice/TrendCalendarContainer.js'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

export default class CalendarLowPriceBody extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        const single = this.props.flightWay === "S"

        return (
            <div className={classnames('calendar-lowprice', { 'flight-way-single': single })}>
                <div className="calendarlp-main" >
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
                        LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
                        this.props.isTrend ? 'c_close_trend' : ((single || this.props.showMonth) ? 'c_close_month' : 'c_close_week'))}>
                        <i className="ico-close-b" onClick={this.props.switchShow.bind(this, false)}></i>
                    </UbtBoundary>
                    {
                        <CalendarLowPriceHeader {...this.props} />
                    }
                    {
                        this.props.isTrend ? (
                            <TrendCalendarContainer {...this.props} />
                        ) : (
                                single ? (
                                    <SingleCalendarLowPrice {...this.props} />
                                ) : (
                                        <RoundCalendarLPMonth {...this.props} />
                                    )
                            )
                    }
                </div>
            </div>
        )
    }
}
