
import React from 'react'
import Immutable from 'immutable'
import moment from 'moment'
import City from './City'
import ModifyDateInfo from '../modifyDateInfo'
import { DATE_PICKER_TITLE, DATE_PICKER_FOOTER_TIPS } from "../../../constants/common/enum/common"
import DateUtil from '../../../sources/common/dateUtil'
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import {GEN_SEARCH_FORM_ANIMATION} from '../../../constants/common/enum/searchForm'

let Multi = ({ onFetchLowPrice, ...props }) => {
    const {
        getMtSegList,
        selectedCity,
        isNeedFocusSecond,
        showCityPicker,
        flightWay,
        mtError,
        onChangeMtStartDate,
        workingDays,
        seqItemStatus,
        onSetInputFocus,
        onHandleAddMtSeg,
        onHandleDelMtSeg,
        onSetActiveExchangeCity,
        showValidateErrorTip,
        exchangeMtCity,
        onSetACityActive,
        onSetDCityActive,
        changePoi,
        cancelFocusMtSecondDCity
    } = props

    let mtSegList = getMtSegList()

    let handleRtFieldEnter = () => {
        // let elForm = document.getElementById('searchForm')
        // popNextFormItem(el, elForm, 'mt_outside')
    }
    let getCityText = (name) => {
        let city = selectedCity.get(name)

        if (city) {
            return city.keySeq().size > 0 ? city.get("text") ? city.get("text") : `${city.get("Name")}(${city.get("Code")})` : ''
        } else {
            return ''
        }
    }

    let getTimeZone = (city) => {
        return (selectedCity.get(city) && selectedCity.getIn([city, "TimeZone"])) || 480
    }

    let multiSelectedDates = []
    let getMtSegListLength = 0
    getMtSegList().forEach((key, i) => {
        let date = mtSegList.getIn([`${i}`, "StartDate"])
        getMtSegListLength++
        multiSelectedDates.push(date || null)
        // return date

    })
    let getDateTips = (date) => {
        if (date) {
            let tips = DateUtil.getDateTipText(date)
            if (tips.length <= 2) {
                return <span className="week" key="1">{tips}</span>
            } else if (tips.length >= 3) {
                return <span className="festival" key="1">{tips}</span>
            }
        } else {
            return null
        }
    }

    let switchBtn = (i) => {
        let enableExchangeCity = getCityText(`mtDCity${i + 1}`) && getCityText(`mtACity${i + 1}`)
        return (
            <div className={`switch-btn ${enableExchangeCity ? '' : 'disabled'} `} onClick={() => { handleExchangeCityClick(enableExchangeCity, i) }}>
                <div className={`switch-icon-wrap ${seqItemStatus[i].activeExchangeCity ? 'hover' : ''}`} name="exchangeBtn">
                    <i className="iconf-exchange">&#xe66a;</i>
                </div>
            </div>)
    }
    let handleExchangeCityClick = (enableExchangeCity, index) => {
        if (enableExchangeCity) {
            exchangeMtCity(index)
            onSetActiveExchangeCity(index, true)
            setTimeout(() => {
                onSetActiveExchangeCity(index, false)
            }, 200)
        }
    }
    let getErrorValue = (i, name) => {
        return mtError.get(i) && showValidateErrorTip ? mtError.getIn([i, `${name}`]) : '' || mtError.get(`${i}`) && showValidateErrorTip ? mtError.getIn([`${i}`, `${name}`]) : ''
    }
    let getMTDCityAndACityErrorValue = (i) => {
        return getErrorValue(i, 'dCity') && getErrorValue(i, 'aCity')

    }
    let getError = (i, name) => {
        return getErrorValue(i, name) ? <span className="error-hint">{getErrorValue(i, name)}</span> : null
    }
    let getMTDCityAndACityError = (i) => {
        return getMTDCityAndACityErrorValue(i) ? <span style={{ marginLeft: '13px' }} className="error-hint">{getMTDCityAndACityErrorValue(i)}</span> : null
    }

    let getSegItem = (i) => {
        let dCityActive = seqItemStatus[i].dCityActive,
            aCityActive = seqItemStatus[i].aCityActive,
            dCityOrACityActive = dCityActive || aCityActive,
            dDateFocus = seqItemStatus[i].dDateFocus

        return <div key={`${i}`} className="form-line">
                    <div className="form-item-v3-group-wrap">
                        <div className={`form-item-v3-group multiTrip ${ dCityOrACityActive ? 'active' : ''}  ${getMTDCityAndACityErrorValue(i) ? 'error' : ''}`}>
                            {getMTDCityAndACityError(i)}
                            <div className="multi-number">{i + 1}</div>
                            <div className={`form-item-v3 flt-depart ${dCityActive ? 'active' : ''} ${GEN_SEARCH_FORM_ANIMATION.CITY(dCityActive)} ${!getCityText(`mtDCity${i + 1}`) && !seqItemStatus[i].dCityFocus && !seqItemStatus[i].dCityHasVal ? 'none-value' : ''} ${getError(i, 'dCity') ? 'error' : ''}`}>
                                <label className="form-label-v3">出发地</label>
                                {!getMTDCityAndACityErrorValue(i) && getError(i, 'dCity')}
                                <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'cityInput', cityType: 'depart', text: '出发地' , value: getCityText(`mtDCity${i + 1}`) })}>
                                    <City
                                        mode="D"
                                        departCityCode={selectedCity.get(`mtDCity${i + 1}`)?.get('Code')}
                                        label={!seqItemStatus[i].dCityFocus && !getCityText(`mtDCity${i + 1}`) ? "" : '可输入城市或机场'}
                                        name={`mtDCity${i + 1}`}
                                        changePoi={changePoi}
                                        width="100%"
                                        isShowDepartCityDefault={i >= 1}
                                        prevACity={
                                            Immutable.fromJS(i >= 1 && selectedCity.get(`mtACity${i}`)
                                                ? selectedCity.get(`mtACity${i}`)
                                                : null)
                                        }
                                        curCity={Immutable.fromJS(selectedCity.get(`mtDCity${i + 1}`))}
                                        cityText={getCityText(
                                            `mtDCity${i + 1}`
                                        )}
                                        focusSecond={i === 1 && isNeedFocusSecond || showCityPicker.get(`mtDCity${i + 1}`)}
                                        errorMsg={
                                            ''
                                        }
                                        id={'search'}
                                        handleEnter={handleRtFieldEnter}
                                        outsideClassName={'mt_outside'}
                                        focus={seqItemStatus[i].dCityFocus}
                                        onInputFocus={() => { onSetInputFocus('dCity', true, i) }}
                                        onInputBlur={() => { onSetInputFocus('dCity', false, i) }}
                                        onSetCityActive={(active) => { onSetDCityActive(i, active) }}
                                        cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
                                        style={{
                                            marginLeft: '-56px'
                                        }}
                                        selectorStyle={{
                                            marginLeft: '-56px'
                                        }}
                                    />
                                </UbtBoundary>
                            </div>
                            {switchBtn(i)}

                            <div className={`form-item-v3 flt-arrival ${aCityActive ? 'active' : ''} ${GEN_SEARCH_FORM_ANIMATION.CITY(aCityActive)} ${!getCityText(`mtACity${i + 1}`) && !seqItemStatus[i].aCityFocus && !seqItemStatus[i].aCityHasVal ? 'none-value' : ''}  ${getError(i, 'aCity') ? 'error' : ''}`}>
                                <label className="form-label-v3">目的地</label>
                                {!getMTDCityAndACityErrorValue(i) && getError(i, 'aCity')}
                                <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'cityInput', cityType: 'arrive', text: '目的地', value: getCityText(`mtACity${i + 1}`)})}>

                                    <City
                                        mode="A"
                                        departCityCode={selectedCity.get(`mtDCity${i + 1}`)?.get('Code')}
                                        label={!getCityText(`mtACity${i + 1}`) && !seqItemStatus[i].aCityFocus ? "" : '可输入城市或机场'}
                                        name={`mtACity${i + 1}`}
                                        changePoi={changePoi}
                                        width="100%"
                                        cityText={getCityText(
                                            `mtACity${i + 1}`
                                        )}
                                        errorMsg={
                                            ''
                                        }
                                        flightWay={flightWay}
                                        id={'search'}
                                        handleEnter={handleRtFieldEnter}
                                        outsideClassName={'mt_outside'}
                                        focusSecond={showCityPicker.get(`mtACity${i + 1}`)}
                                        focus={seqItemStatus[i].aCityFocus}
                                        onInputFocus={() => { onSetInputFocus('aCity', true, i) }}
                                        onInputBlur={() => { onSetInputFocus('aCity', false, i) }}
                                        onSetCityActive={(active) => { onSetACityActive(i, active) }}
                                        cancelFocusMtSecondDCity={cancelFocusMtSecondDCity}
                                        style={{
                                            marginLeft: '-16px'
                                        }}
                                        selectorStyle={{
                                            marginLeft: '-18px',
                                        }}
                                    />
                                </UbtBoundary>
                            </div>
                        </div>
                    </div>

                    <div className="form-item-v3-group-wrap">
                        <div id={`multiDatePicker${i}`} className={`form-item-v3-group multiTrip ${dDateFocus ? 'active' : ''} ${GEN_SEARCH_FORM_ANIMATION.BORDER_TOP(dDateFocus)}`}>
                            <div className={`form-item-v3 flt-date ${dDateFocus ? 'active' : ''} ${!mtSegList.getIn([`${i}`, "StartDate"]) && !dDateFocus ? 'none-value' : ''} ${getError(i, 'dDate') ? 'error' : ''}`}>
                                <label className="form-label-v3">出发日期</label>

                                {getError(i, 'dDate')}
                                <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'dateInput', text: '出发日期',  value: moment(mtSegList.getIn([`${i}`, "StartDate"])).format("YYYY-MM-DD")})}>
                                    <span>
                                        <ModifyDateInfo
                                            label={!mtSegList.getIn([`${i}`, "StartDate"]) && !dDateFocus ? "" : 'yyyy-mm-dd'}
                                            date={mtSegList.getIn([`${i}`, "StartDate"])}
                                            // start={mtSegList.get(`${i - 1}`) && mtSegList.getIn([`${i - 1}`, "StartDate"])}
                                            // end={mtSegList.getIn([`${i}`, "StartDate"])}
                                            max={moment().add(1, "year")}
                                            min={i == 0 ? moment() :
                                                (mtSegList.get(`${0}`) &&
                                                    mtSegList.getIn([`${0}`, "StartDate"])) || moment()
                                            }
                                            multiSelectedDates={multiSelectedDates}
                                            multiSelectedDateIndex={i}
                                            id={`mtDate${i + 1}${mtError.get(`${i}`) && mtError.getIn([`${i}`, "dDate"])}`}
                                            source='channel'
                                            flightWay={flightWay}
                                            timeZone={getTimeZone(
                                                `${flightWay.toLowerCase()}DCity${i + 1}`
                                            )}
                                            title={DATE_PICKER_TITLE['MT'].value.depart}
                                            footerTips={DATE_PICKER_FOOTER_TIPS.TIME.value['MT'].depart}
                                            mutilTripNumber={i}
                                            outsideClassName={'mt_outside'}
                                            onDateChange={(...args) => onChangeMtStartDate(i, ...args)}
                                            workingDays={workingDays}
                                            mode="departTime"
                                            showWeek={false}
                                            needFocus={dDateFocus}
                                            onInputFocus={() => { onSetInputFocus('dDate', true, i) }}
                                            onInputBlur={() => { onSetInputFocus('dDate', false, i) }}
                                            skin={'date-custom-v2'}
                                            offset={{ top: 6 }}
                                            readOnly={true}
                                            dateTips={[getDateTips(mtSegList.getIn([`${i}`, "StartDate"]))]}
                                            positionEle={`#multiDatePicker${i}`}
                                            hideAnimateClass={GEN_SEARCH_FORM_ANIMATION.CALENDAR(false)}
                                            showAnimateClass={GEN_SEARCH_FORM_ANIMATION.CALENDAR(true)}
                                        />
                                    </span>
                                </UbtBoundary>

                            </div>
                        </div>
                    </div>
                    {i >= 2 ? (
                        <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'removeFlight', text: '删除一程' })}>
                            <a data-ubt-key="DeleteSequence" key={`del-btn-${i}`} href="javascript:void(0);" onClick={() => { onHandleDelMtSeg(i) }} className="remove-trip-btn"><i className="iconf-remove">&#xe661;</i></a>
                        </UbtBoundary>
                    ) : null}
                </div>


    }
    return (
        <div>
            {mtSegList.keySeq().map((key, i) => getSegItem(i))}
            {getMtSegListLength < 6  ? (
                <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'addFlight', text: '再加一程' })}>
                    <a data-ubt-key="AddSequence" href="javascript:void(0);" onClick={onHandleAddMtSeg} className="add-trip-btn">+ 再加一程</a>
                </UbtBoundary>

            ) : null }
        </div>
    )
}


export default Multi
