import React from "react"
import PopDialog from '../common/PopDialog'
import { UED } from "./styleConst"

export const StayTimeoutPopup = ({ onConfirm }) => {
    return (<PopDialog contents={(
        <div className="basic-alert alert-giftinfo" style={UED.popupMargin}>
            <div className="alert-title">温馨提示</div>
            <div className="alert-body">
                您的停留时间过长，航班及价格信息可能发生变动，正在为您查询最新价格
            </div>
            <div className="alert-footer">
                <div className="btn-group">
                    <a href="javascript:;" className="btn" onClick={() => onConfirm()}>我知道了</a>
                </div>
            </div>
        </div>)} />)
}
