export const isDomesticFlight = (selectedCity, mtSegList, flightWay) => {
    let dCity = selectedCity.get('owDCity'),
        aCity = selectedCity.get('owACity'),
        isDomesticFlight = false
    if (flightWay == 'OW' || flightWay == 'RT') {
        if (dCity && aCity && dCity.get('domestic') && aCity.get('domestic')) {
            isDomesticFlight = true
        }
    } else {
        let allSegIsDomestic = true
            mtSegList.map((key, i) => {
            i = parseInt(i)
            let dCity = selectedCity.get(`mtDCity${i + 1}`),
                aCity = selectedCity.get(`mtACity${i + 1}`)
            // 有一程是境外的那全部是境外航线
            if (dCity && aCity && (!aCity.get('domestic') || !dCity.get('domestic'))) {
                allSegIsDomestic = false
            }
        })
        isDomesticFlight = allSegIsDomestic
    }
    return isDomesticFlight
}
