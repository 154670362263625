import React from 'react';
// import { ifIE } from '@/src/sources/common/getIEVersion';
// import toWebpUrl from './toWebpUrl';
import { ImageWrapProps } from './types';

// const WebPPicture = ({ webPUrl, children }: PropsWithChildren<{ webPUrl?: string | false }>) => {
// 	if (webPUrl && !ifIE()) {
// 		// IE 不支持 webp 格式图片，所以这里不渲染 webp 图片
// 		return (
// 			<picture>
// 				<source srcSet={webPUrl} />
// 				{children}
// 			</picture>
// 		);
// 	}
// 	return <>{children}</>;
// };

export default function ImageWrap(props: ImageWrapProps) {
	const { src, webp = true, alt, ...imgProps } = props;
	// const webpSrc = toWebpUrl(src);
	return (
		// <WebPPicture {...{ webPUrl: webp && webpSrc }}>
		<img {...imgProps} src={src} alt={alt} />
		// </WebPPicture>
	);
}
