// 顶部公告actionTypes 为了防止命名冲突，统一使用NOTICE开头
/**
 * 关闭第三级公告
 */
export const NOTICE_ON_CLOSE_THIRD_CLASS = 'NOTICE_ON_CLOSE_THIRD_CLASS'
/**
 * 请求公告接口后更新公告数据
 */
export const NOTICE_GET_NEW_NOTICES = 'NOTICE_GET_NEW_NOTICES'
/**
 * 关闭一级公告弹框
 */
export const NOTICE_ON_CLOSE_FIRST_CLASS_POP = 'NOTICE_ON_CLOSE_FIRST_CLASS_POP'
/**
 * 请求公告以及广告
 */
export const AD_GET_NEW_ADS = 'AD_GET_NEW_ADS'
/**
 * 打开广告弹框
 */
export const AD_ON_OPEN = 'AD_ON_OPEN'
/**
 * 关闭广告弹框
 */
export const AD_ON_CLOSE = 'AD_ON_CLOSE'
