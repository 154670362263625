import axios from 'axios';
import { ubtError } from '@/src/sources/common/lightUbt';
import { sendBlockTraceInList } from '@/src/utils/getSpecialBlockBasicInfo';
import { BlockTypeValue, SubTypeValue } from '@/src/utils/types';
import { showCaptchaModal } from './showCaptchaModal';
import { goLogin } from './goLogin';
import { lightUploadSpeed } from '../../sources/common/lightUbt';

export const handleSpider = (response) => {
	try {
		const { data, status, config } = response || {};
		const { context, needUserLogin } = data?.data || {};
		// 老爬虫
		const isOldSpider = context?.showAuthCode;
		// 老登录
		const isOldLogin = needUserLogin;
		// 是否识别432为爬虫
		const needIdentify432 = window.GlobalSwitches.needIdentify432;
		// 新爬虫
		const isNewSpider = needIdentify432 && status === 432;
		// 新登录
		const isNewLogin = isNewSpider && data?.actionType === 'login';
		const isLoginFn = typeof CLogin !== 'undefined' && window.CLogin.authBiz && window.CLogin.authBiz.isLogin;
		const frontUrl = config.url.split('?')[0] || '';
		const requestName = frontUrl.split('/')[frontUrl.split('/').length - 1];
		if (isOldLogin || isNewLogin) {
			lightUploadSpeed({
				speed: isNewLogin ? 'response_need_login_new' : 'response_need_login_old',
				requestName,
			});
			if (isLoginFn) {
				goLogin();
			} else {
				window.alert('页面数据加载失败，请重新查询。');
				window.location.reload();
			}
			return {
				isLogin: true,
			};
		} else {
			if (isNewSpider || isOldSpider) {
				lightUploadSpeed({
					speed: isNewSpider ? 'response_is_spider_new' : 'response_is_spider_old',
					requestName,
				});
				sendBlockTraceInList({blockType: BlockTypeValue.Pop, subType:SubTypeValue.Captcha});
				return new Promise((resolve) => {
					showCaptchaModal({
						onReSearch: () => {
							lightUploadSpeed({
								speed: isNewSpider ? 'spider_new_research' : 'spider_old_research',
								requestName,
							});
							resolve(axios(config));
						},
						requestName,
					});
				});
			}
			return false;
		}
	} catch (e) {
		console.error(e);
		ubtError(e);
		return false;
	}
};
