import { createSelector } from 'reselect';
import moment from 'moment';
import Immutable from 'immutable';

import { getFilterTypes } from '../../../../constants/list/enums/filter';
import { setMinPriceItemOfFlight } from '../../../../utils/commonUtils';
import { getIfSplitRoundFlights } from './getIfSplitRoundFlights';
import CONSTS from '../../../../constants/list/constConfig';
import { ifScopeValueInternational } from '../../../../sources/list/scope';

export const globalSwitchSelector = (state) => state.getIn(['list', 'globalSwitch']) || state.getIn(['globalSwitch']);

export const getChannelIdSelector = (state) => state.getIn(['list', 'flights', 'channelId']);

export const getSubChannelIdSelector = (state) => state.getIn(['list', 'flights', 'subChannelId']);

export const containsTaxSelector = (state) => state.getIn(['list', 'containsTax']);

export const getMultiCheapestSelector = (state) => state.getIn(['list', 'recommend', 'multiCheapest']);

export const getSearchSelector = (state) => {
	return state.getIn(['list', 'search']) || state.getIn(['search']);
};

/**
 * 两舱推荐，当第二区块的推荐航班数量超过4，就会允许用户手动折叠/收缩超出部分推荐航班，返回是否展开状态
 */
export const getHighCabinExpandExtraRecFlightsSelector = (state) =>
	state.getIn(['list', 'recommend', 'expandExtraRecFlights']);

// export const prevCondSelector = (state) => state.getIn(['list', 'search', 'prevCond']) || state.getIn(['search', 'prevCond'])

export const prevCondSelector = createSelector([getSearchSelector], (search) => search.get('prevCond'));

/**
 * 当前查询的是否国际航班
 */
export const getIfScopeInternationalSelector = createSelector(
	[prevCondSelector],
	(prevCond) => prevCond && ifScopeValueInternational(prevCond.get('scope'))
);

/**
 * 当前查询的是否国内航班
 */
export const getIfScopeDomesticSelector = createSelector(
	[getIfScopeInternationalSelector],
	(international) => !international
);

/**
 * 是否有scope输出
 */
export const getIfScopeOutputSelector = createSelector([prevCondSelector], (prevCond) => !!prevCond.get('scope'));

/**
 * 是否选择了伤残军警
 */
export const getIfArmySelector = (state) => state.getIn(['list', 'army']);

export const getSearchCriteriaTokenSelector = createSelector([getSearchSelector], (search) =>
	search.get('searchCriteriaToken')
);

export const currentSegmentSeqSelector = (state) => state.getIn(['list', 'flights', 'currentSegmentSeq']);

/**
 * 是否需要弹出验证框
 */
export const getShowAuthCodeSelector = (state) => state.getIn(['list', 'flights', 'showAuthCode']);

// 总航程数目
export const getTotalSegmentCountSelector = createSelector(
	[prevCondSelector],
	(prevCond) => prevCond.get('flightSegments').size
);

// 当前航程是否是最后一程
export const getCurrentSegmentIsLastSelector = createSelector(
	[currentSegmentSeqSelector, getTotalSegmentCountSelector],
	(currentSegmentSeq, totalSegmentCount) => {
		return currentSegmentSeq + 1 === totalSegmentCount;
	}
);

// 搜索条件中成人/儿童/婴儿的数量，返回长度为3的数组，依次是 成人/儿童/婴儿 的数量
export const getPassengerCountArrayFromPrevCondSelector = createSelector([prevCondSelector], (prevCond) => {
	return [prevCond.get('adultCount'), prevCond.get('childCount'), prevCond.get('infantCount')];
});

export const prevCondFlightWaySelector = createSelector(prevCondSelector, (prevCond) => prevCond.get('flightWay'));

/**
 * 当前航程类型：
 * 单程 S
 * 往返 D
 * 多程 M
 */
export const getFlightWaySelector = createSelector([prevCondSelector], (prevCond) => prevCond.get('flightWay'));

const getRawBuildUpSelector = (state) => state.getIn(['list', 'isBuildUp']);

export const getActiveRoundTabIndexSelector = (state) =>
	state.getIn(['list', 'splitRound', 'flights', 'acitveSegmentSeq']);

export const getRoundTabModeSwitchSelector = createSelector(
	[getFlightWaySelector, getRawBuildUpSelector, globalSwitchSelector],
	(flightWay, buildUp, globalSwitch) => {
		return flightWay === 'D' && !buildUp && globalSwitch.get('roundTabModeSwitch');
	}
);

export const getIfRoundTabActiveArrivalTabSelector = createSelector(
	[getActiveRoundTabIndexSelector, getRoundTabModeSwitchSelector],
	(activeRoundTabIndex, roundTabModeSwitch) => {
		return roundTabModeSwitch && activeRoundTabIndex === 1;
	}
);

export const getShowCityTextSelector = (state) => state.getIn(['list', 'search', 'showCityText']);

export const getOpenJawGroundTrafficDataSelector = (state) =>
	state.getIn(['list', 'recommend', 'openJawGroundTrafficData']);

// 读取往返分屏开关
export const getRawSplitRoundFlightsSwitchSelector = createSelector([prevCondSelector], (prevCond) =>
	getIfSplitRoundFlights(prevCond, false)
);

// 列表页往返航班是否分屏显示
export const getSplitRoundFlightsSwitchSelector = createSelector(
	[prevCondSelector, getRawBuildUpSelector],
	(prevCond, rawBuildUp) => getIfSplitRoundFlights(prevCond, rawBuildUp)
);

// 国际航班才会有往返组合
export const isBuildUpSelector = createSelector(
	[
		getIfScopeInternationalSelector,
		getRawBuildUpSelector,
		prevCondFlightWaySelector,
		getSplitRoundFlightsSwitchSelector,
	],
	(scopeInternational, buildUp, prevCondFlightWay, splitRoundFlightsSwitch) =>
		scopeInternational && buildUp && prevCondFlightWay === 'D' && !splitRoundFlightsSwitch
);

// 往返分屏是否需要给去程增加遮罩的开关
export const splitRoundMaskFlightsSwitchSelector = createSelector([globalSwitchSelector], (globalSwitch) =>
	globalSwitch.getIn(['splitRoundMaskFlightsSwitch'])
);

export const getSortTypesSelector = (state) => state.getIn(['list', 'sortTypes']);

export const filterTypesSelector = (state) => state.getIn(['list', 'filters', 'types']);

export const filterActiveKeysSelector = (state) => state.getIn(['list', 'filters', 'activeKeys']);

export const filterShowAirlineMatrixSelector = (state) => state.getIn(['list', 'filters', 'showAirlineMatrix']);

export const activeKeysSelector = (state) => state.getIn(['list', 'filters', 'activeKeys']);

export const flightsSelector = (state) => state.getIn(['list', 'flights']);

export const splitRoundFlightsSelector = (state) => state.getIn(['list', 'splitRound', 'flights']);

// 是否存在任何航班
export const hasAnyFlightsSelector = createSelector([flightsSelector], (flights) => !!flights.size);

export const lastSearchTimeSelector = (state) => state.getIn(['list', 'search', 'lastSearchTime']);

export const giftIdsSelector = (state) => state.getIn(['list', 'flights', 'giftIds']);

export const fakeMarkSelector = (state) => state.getIn(['list', 'flights', 'lgn']);

export const agreementIdsSelector = (state) => state.getIn(['list', 'flights', 'agreementIds']);

export const comfortTagsSelector = (state) => state.getIn(['list', 'comfortTags']);

export const showFirstClassPopSelector = (state) => state.getIn(['list', 'notice', 'firstClassPopShow']);

export const anyFirstClassNoticeSelector = (state) =>
	state.getIn(['list', 'notice', 'noticeList']).some((t) => t.get('noticeLevel').key === 'FIRSTCLASS');

// 允许自动弹超时窗的前提条件：没有紧急公告内容，或者关闭了紧急公告弹窗
export const allowShowStayTimeoutDialogSelector = createSelector(
	[anyFirstClassNoticeSelector, showFirstClassPopSelector],
	(anyFirstClassNotice, firstClassPop) => (anyFirstClassNotice ? !firstClassPop : true)
);

const rawStayTimeoutFlagSelector = (state) => state.getIn(['list', 'popups', 'stayTimeoutFlag']);

export const stayTimeoutFlagSelector = createSelector(
	[allowShowStayTimeoutDialogSelector, rawStayTimeoutFlagSelector],
	(allowShowStayTimeoutDialog, rawStayTimeoutFlag) => allowShowStayTimeoutDialog && rawStayTimeoutFlag
);

export const flightSelector = (state, props) => props.flight;

export const bestChoiceFlightsForceTopSelector = (state) =>
	state.getIn(['list', 'globalSwitch', 'bestChoiceFlightsForceTop']);

/**
 * 生成筛选项的航班列表
 */
export const flightListSelector = (state) => state.getIn(['list', 'flights', 'list']);

// 筛选舱等
export const filterClassGrade = createSelector([filterActiveKeysSelector], (activeKeys) => {
	return activeKeys.get('CLASS_GRADE').map((activeKey) => activeKey.key);
});

// 是否需要展现两舱舱等
export const cfRecommendYSShowCabinSelector = (state) => state.getIn(['list', 'recommend', 'cfRecommendYSShowCabin']);

// 虚拟航班
export const virtualFlightsSelector = (state) => state.getIn(['list', 'recommend', 'virtualFlights']);

export const flightTrainFlightsSelector = createSelector(
	[prevCondSelector, currentSegmentSeqSelector, virtualFlightsSelector, containsTaxSelector],
	(prevCond, seq, flights, containsTax) => {
		return (flights.get(seq) || Immutable.List([])).map((flight) => {
			const visiblePriceList = flight.get('priceList');
			return setMinPriceItemOfFlight(prevCond, visiblePriceList, flight, containsTax);
		});
	}
);

// 筛选框V2使用到，标志是否选中任何筛选项，或者是选中不含税价
export const anyActiveKeysOrNotContainsTaxSelector = createSelector(
	[activeKeysSelector, containsTaxSelector],
	(activeKeys, containsTax) => {
		return (
			activeKeys
				.valueSeq()
				.toList()
				.find((item) => item.size) || !containsTax
		);
	}
);

export const selectedFlightsSelector = (state) => state.getIn(['list', 'flights', 'selected']);

export const selectAnyFlightsSelector = createSelector(
	[selectedFlightsSelector],
	(selected) => !!selected.get('flights')
);

export const anyFlightIsDirectSelector = createSelector(
	[flightsSelector, currentSegmentSeqSelector, isBuildUpSelector],
	(flights, currentSegmentSeq, isBuildUp) => {
		if (isBuildUp) {
			return flights
				.get('list')
				.some(
					(flight) =>
						flight.getIn(['flightSegments', 0, 'transferAndStopCount']) === 0 &&
						flight.getIn(['flightSegments', 1, 'transferAndStopCount']) === 0
				);
		} else {
			return flights
				.get('list')
				.some((flight) => flight.getIn(['flightSegments', currentSegmentSeq, 'transferAndStopCount']) === 0);
		}
	}
);

export const searchFlightsIsFinishedSelector = (state) => state.getIn(['list', 'flights', 'isFinished']);

export const isResearchingSelector = (state) => state.getIn(['list', 'isResearching']);

export const getSplitRoundFlightsSelector = (state) => state.getIn(['list', 'splitRound', 'flights', 'list']);

// 往返返程是否有直飞
export const getSecondSegmentHasDirectFlightSelector = (state) =>
	state.getIn(['list', 'flights', 'existDirectReturnTrip']);

export const searchProgressSelector = (state) => state.getIn(['list', 'search', 'searchProgress']);

export const isSearchSubAirportSelector = createSelector(
	prevCondSelector,
	(prevCond) =>
		prevCond.getIn(['flightSegments', 0, 'departureAirportCode']) ||
		prevCond.getIn(['flightSegments', 0, 'arrivalAirportCode'])
);

export const autoFixOnSplitRoundRouteSwitchSelector = createSelector(globalSwitchSelector, (globalSwitch) =>
	globalSwitch.getIn(['autoFixOnSplitRoundRouteSwitch'])
);

// 低价优先排序逻辑增加航段数
export const getIfSegmentCountAsPriceSortSwitchSelector = createSelector(globalSwitchSelector, (globalSwitch) =>
	globalSwitch.getIn(['segmentCountAsPriceSortSwitch'])
);

// 列表页是否打开广告的开关
export const getAdBarSwitchSelector = createSelector(globalSwitchSelector, (globalSwitch) =>
	globalSwitch.getIn(['adBarSwitch'])
);

// 第一程信息
export const getFirstPrevCondSegmentSelector = createSelector(prevCondSelector, (prevCond) =>
	prevCond.getIn(['flightSegments', 0])
);

// 根据当前选定的筛选项，生成需要传到下一程的筛选项对象
export const getToRememberActiveKeysSelector = createSelector(
	[filterActiveKeysSelector, containsTaxSelector],
	(activeKeys, containsTax) => {
		const toRememberActiveKeys = {};
		const filterTypes = getFilterTypes();

		activeKeys
			.keySeq()
			.toList()
			.forEach((activeKey) => {
				const theActiveKeyEnum = filterTypes.get(activeKey);
				if (theActiveKeyEnum && theActiveKeyEnum.value.rememberInSegments) {
					const theActiveKeySearchKey = theActiveKeyEnum.value.urlSearchKey,
						theActiveKeyVal = activeKeys.get(activeKey);
					if (theActiveKeySearchKey) {
						toRememberActiveKeys[theActiveKeyEnum.value.urlSearchKey] = theActiveKeyVal
							.map((theActiveKey) => {
								return typeof theActiveKey === 'string' ? theActiveKey : theActiveKey.key;
							})
							.filter((theActiveKey) => {
								const getRememberInSegments = theActiveKeyEnum.value.getRememberInSegments;
								if (typeof getRememberInSegments === 'function') {
									return getRememberInSegments(theActiveKey);
								} else {
									return true;
								}
							})
							.join(',');
					}
				}
			});

		toRememberActiveKeys['containstax'] = containsTax ? '1' : '0';

		return toRememberActiveKeys;
	}
);

export const getRouterSelector = (state) => state.getIn(['list', 'router']);

export const getRouterKeySelector = createSelector([getRouterSelector], (router) => router.get('key'));

// 排序筛选是否吸顶
export const getFixSortBarSelector = (state) => state.getIn(['list', 'fixTheTop', 'fixSortBar']);

// 低价日历是否吸顶
export const getFixLowPriceCalendarSelector = (state) => state.getIn(['list', 'fixTheTop', 'fixLowPriceCalendar']);

// 统一推荐接口
export const getAllRecommendFlightsSelector = (state) => state.getIn(['list', 'allRecommendFlights']);

export const getAllRecommendFlightsListSelector = createSelector([getAllRecommendFlightsSelector], (recommendList) => {
	// 需要排除掉不显示的推荐类型，主要是考虑到推荐类型不准确导致用户投诉
	const hiddenTypes = CONSTS.RECOMMEND.HIDDEN_FLOAT_RECOMMEND_TYPES || [];
	const newRecommendList = recommendList.map((wrapper) => {
		const { flights } = wrapper;
		return {
			...wrapper,
			flights: flights.filter((item) => hiddenTypes.indexOf(item.get('recommendType')) < 0),
		};
	});
	return newRecommendList;
});

export const getSearchInfoToUbtSelector = createSelector([prevCondSelector], (prevCond) => {
	const ret = {};
	prevCond.get('flightSegments').forEach((searchedFlightSegment, index) => {
		if (index == 0) {
			ret['DcityCode'] = searchedFlightSegment.get('departureCityCode');
			ret['AcityCode'] = searchedFlightSegment.get('arrivalCityCode');
			ret['StartDate'] = searchedFlightSegment.get('departureDate');
		} else {
			ret['ReturnDate'] = searchedFlightSegment.get('departureDate');
		}
	});
	ret['flightWay'] = prevCond.get('flightWay');
	return ret;
});

export const giftIdListSelector = (state) => state.getIn(['list', 'giftIdList']);

export const getMinPrice = (state) => state.getIn(['list', 'calendar_lowprice', 'lowPriceDatas']);

// 当前列表页的最低价(可能最低价不是航班上展示的最低价 指路genTodayLowPrice)
export const getMinPriceSelector = createSelector(getMinPrice, prevCondSelector, (getMinPrice, prevCond) => {
	if (prevCond.get('flightWay') === 'S' || prevCond.get('flightWay') === 'M') {
		const searchDate = prevCond.getIn(['flightSegments', 0, 'departureDate']);
		return getMinPrice.get(searchDate) || Immutable.fromJS({ totalPrice: 0, price: 0 });
	}
	if (prevCond.get('flightWay') === 'D') {
		const startDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
			endDate = prevCond.getIn(['flightSegments', 1, 'departureDate']),
			diffDate = moment(endDate).diff(moment(startDate), 'days');
		return getMinPrice.get(startDate) && getMinPrice.get(startDate).get(diffDate - 1)
			? getMinPrice.get(startDate).get(diffDate - 1)
			: Immutable.fromJS({ totalPrice: 0, price: 0 });
	}
});
// 为所有航班设置最低价
export const getAllFlightsWithMinPriceSelector = createSelector(
	[prevCondSelector, flightListSelector, containsTaxSelector],
	(prevCond, flightsList, containsTax) => {
		return flightsList.map((flight) => {
			return setMinPriceItemOfFlight(prevCond, flight.get('priceList'), flight, containsTax);
		});
	}
);

// 获取所有航班的最低价,不包含空铁
export const getTheMinPriceOfAllFlightsSelector = createSelector(getAllFlightsWithMinPriceSelector, (flights) => {
	const flightsWithoutTrain = flights.filter((flight) => {
		const theMinPriceItemSortTypeGroupEnum = flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']);
		return theMinPriceItemSortTypeGroupEnum && theMinPriceItemSortTypeGroupEnum.key != 'OTHER_TRANSFER_TYPE';
	});
	const totalPriceWithTax = Math.min(
		...flightsWithoutTrain.map((flight) => flight.getIn(['theMinPriceItem', 'totalPriceWithTax']))
	);
	const totalPrice = Math.min(
		...flightsWithoutTrain.map((flight) => flight.getIn(['theMinPriceItem', 'totalPrice']))
	);
	return Immutable.fromJS({ totalPriceWithTax, totalPrice });
});

// 获取直飞航班的最低价
export const getTheMinPriceOfAllDirectFlightsSelector = createSelector(getAllFlightsWithMinPriceSelector, (flights) => {
	const directFlights = flights.filter((flight) => {
		const segments = flight.getIn(['flightSegments']);
		return segments.every((segment) => segment.get('transferCount') == 0);
	});
	const totalPriceWithTax = Math.min(
		...directFlights.map((flight) => flight.getIn(['theMinPriceItem', 'totalPriceWithTax']))
	);
	const totalPrice = Math.min(...directFlights.map((flight) => flight.getIn(['theMinPriceItem', 'totalPrice'])));
	return Immutable.fromJS({ totalPriceWithTax, totalPrice });
});
