/* eslint-disable no-case-declarations */
import moment from 'moment';
import Immutable from 'immutable';
import getMtSegList from '@/src/reducers/channel/getMtSegList';

import * as types from '../../constants/channel/actionTypes';
import * as COMMON_TYPES from '../../constants/common/actionTypes';
import * as hotDistrictTypes from '../../constants/common/actionTypes/hotDistrict';
import * as searchBoxTypes from '../../constants/common/actionTypes/searchBox';
import mapSearch from '../../constants/pricemap/actionTypes';
import poiHandler from '../common/poi';
import keyValues from '../../constants/pricemap/keyValues';
import {
	COUNTRY_SOURCE,
	HOT_COUNTRY_STAYDAYS,
	MOUSE_ACTION_SOURCE,
	SEARCH_BOX_WIDTH,
} from '../../constants/channel/enum';
import { getSearchMapCity } from '../../containers/channelv2/searchMapHelper';
import channelConfig from '../../constants/channel/config';
import searchDataHelper from '../../constants/common/searchDataHelper';
import { DOMESTIC_PASSENGER_TYPE } from '../../constants/common/enum/searchForm';
import { getLowPriceKey } from '../../sources/common/lowPriceHelper';
import { UbtHandler } from '../../ubt.v2/handler';

// const googleMapDomainList = window.GoogleMapDomainList;
const googleMapDomainList = ['//ditu.google.cn', '//ditu.google.com', '//maps.googleapis.com'];
const defaultCityPriceInfo = {
	cityPriceList: [],
	cityPriceListKey: '',
	fetchState: keyValues.cityPriceFetchStateEnum.done,
	fetchErrorMsg: '',
	priceSummary: -1,
};
const isDomesticSearch = window.location.href.indexOf('international/search/domestic') > 0;

const defaultState = Immutable.fromJS({
	// 是不是国内页面
	domesticSearchPage: isDomesticSearch,
	//不同组件通用数据
	common: {
		workingDays: {}, //工作日数据（来自去哪儿节假日接口）
	},
	//重要公告
	importantNotice: {
		importantNoticeList: [],
		showImportantNotice: true,
	},
	//往返特惠
	roundAirlines: {
		roundAirlines: [],
		returnFrom: `SHA`,
		returnTo: 0,
		showReturnCircle: false,
	},
	singleAirlines: {
		singleAirlines: [],
		singleAirlineLocations: [],
		singleAirlineLocationNames: [],
		showSingleCircle: false,
	},
	specialAirlines: {
		specialAirlines: [],
		specialAirlinesScrollIndex: 0,
	},
	others: {
		smallScreen: false, //是否窄屏
		isFirstEntryTip: false, //第一次进入优惠气泡是否展示
	},
	hotTopics: {
		hotTopics: [],
	},
	latestOrders: {
		allLatestOrders: [],
		visibleLatestOrders: [],
	},
	calendar: {
		//日历低价
		lowPrice: {},
		lowRPrice: {},
		lowestPrice: {},
		lowestRPrice: {},
		// 精选地区低价日历
		countryLowestPrice: {},
		countryLowPrice: {},
		countryLowestRPrice: {},
		countryLowRPrice: {},
	},
	search: {
		//查询框是否是境内搜索
		domesticCityData: null,
		domestic: false,
		currentFlightWay: 'OW',
		specialAirlinesIsLoading: true,
		showCityPicker: {},
		owDDate: null,
		owADate: null,
		rtDDate: null,
		rtEndDate: null,
		owDDateString: '',
		owADateString: '',
		rtDDateString: '',
		rtEndDateString: '',
		showValidateWarnTip: false,
		// 国内乘机人数量
		dPassenger: {
			adtCount: 1,
			chiCount: 0,
			infCount: 0,
		},
		// 国际乘机人数量
		iPassenger: {
			adtCount: 1,
			chiCount: 0,
			infCount: 0,
		},
		// 国内仓等
		dClassGrade: 'Y_S_C_F',
		// 国际仓等
		iClassGrade: 'Y_S',

		canAddMoreMtSeg: true,
		searchBoxShowAdvancedSearch: false,
		searchBoxWidth: SEARCH_BOX_WIDTH.NOT_MT,
		//左侧工具栏上边距
		sideBoxMarginTop: 140,
		//单程 多程 往返 出发城市和到达城市
		dCityText: '',
		aCityText: '',
		dDateError: '',
		aDateError: '',
		dCityError: '',
		aCityError: '',
		mtError: {},
		focusReturnDate: false,

		directflight: false,
		//机加酒
		isFltPlusHotel: false,

		//validate
		isShowInfantTips: false,
		isShowChildTips: false,
		isShowFltPlusHotelTip: false,

		mouseIsDownOnCityPicker: false,

		showValidateErrorTip: false,

		//不能跳转搜索
		canNotSearch: false,

		//focus第二程
		isNeedFocusSecond: false,
	},
	hotCountry: {
		//热门国家模糊入口
		arrivalCountry: '', //到达国家
		countrySource: COUNTRY_SOURCE.SEARCH,
		isHotSearch: false, //是否热门国家搜索
		isFreeDept: false, //是否自由出发
		floatDays: 0, //浮动天数
		stayDays: HOT_COUNTRY_STAYDAYS.FREE, //游玩天数
		currentMonthIndex: 0, //当前日历展示月份索引
		selectedDayCalendar: '', //日历选中的出发日期（自由选择）
		inputString: '', //输入框中内容
		selectedTimeItems: [
			{
				text: '任何时间',
				dateRange: [],
				key: 0,
			},
		], //选中的出发时间（非自由）,text是选中项的文字信息（字符串），用于更新页面显示;dateRange保存的是start-end形式保存的时间数组，用于传值,key决定在input中的显示顺序
	},
	hotDistrict: {
		isDistrictSearch: '',
		arrivalDistrict: {
			areaCode: '',
			areaName: '',
		},
	},
	airline: {
		//高级搜索航司列表
		suggestionAirlines: [],
		allAirlines: [],
		airlineSelected: '',
	},
	poi: {
		poiKey: {},
		poiResult: {},
		lastPoi: [],
		poiCache: {},
		selectedCity: {
			mtDCity1: null,
			mtACity1: null,
			mtStartDate1: null,
			mtDCity2: null,
			mtACity2: null,
			mtStartDate2: null,
			mtDCity3: null,
			mtACity3: null,
			mtStartDate3: null,
		},
	},
	toolBox: {
		//航班动态查询
		searchFlightInfoDepartCity: '',
		searchFlightInfoArrivalCity: '',
		searchCondition: 'searchByFlightNo',
		flightNo: '',
		isShowInvalidFltNo: false,
	},
	mapSearch: {
		googleJsUrlList: [],
		isLoadedGoogleMap: false,
		mapUrl: null,
		cityPriceInfo: defaultCityPriceInfo,
		countryListInfo: [],
		dCity: channelConfig.defaultSearchMapDepartCity,
		dDate: moment().add(1, 'day'),
		aDate: null,
		flightWay: 'OW',
		directFlight: false,
		aCountry: null,
		searchResult: null,
		fetchCountryDataStatus: '',
	},
	//从服务端传回的配置信息，比如ABT
	globalSwitch: {
		lowPriceReportSwitch: false,
		appendListPageInChannelSwitch: false, // 频道首页是否内嵌列表页的iframe
		domesticSearchSwitch: true,
		appendSignToListPageSwitch: false,
		homePreAggSearchSwitch: false,
		smoothAnimationSwitch: false,
		searchBoxFixTopSwitch: false,
		defaultSearchValueSwitch: false,
	},
	// 低价速报
	lowPriceReport: {
		searchResult: {},
		show: false,
	},
});
export default function channel(state = defaultState, action) {
	let hideAllFlightIsDomesticSwitch = state.getIn(['globalSwitch', 'hideAllFlightIsDomesticSwitch']);

	switch (action.type) {
		//全局事件
		case types.MOUSE_DOWN:
			let fromCityPicker = action.tag.source == MOUSE_ACTION_SOURCE.CITY_PICKER,
				showCityPicker = state.showCityPicker;

			if (!fromCityPicker) {
				showCityPicker = {};
			}

			// if (inputName) {
			//     showCityPicker[inputName] = true
			// }

			return state
				.setIn(['search', 'showValidateErrorTip'], false)
				.setIn(['search', 'mouseIsDownOnCityPicker'], fromCityPicker)
				.setIn(['search', 'showCityPicker'], Immutable.fromJS({ ...showCityPicker }));

		// case types.MOUSE_UP:
		//     return {
		//         ...state,
		//         mouseIsDownOnCityPicker: false
		//     }
		case types.RESIZE:
			return state.setIn(['others', 'smallScreen'], document.body.offsetWidth < 1250);
		//通过ip获取城市三字码
		case types.FETCH_CITY_CODE:
			return state;
		case types.FETCH_CITY_CODE_SUCCESS:
			return state.setIn(['roundAirlines', 'returnFrom'], action.returnFrom);
		case types.FETCH_CITY_CODE_FAILRUE:
			return state;

		/* 往返特惠 */
		//获取往返特惠航线
		case types.FETCH_CHANNEL_RETURN_AIRLINES:
			return state.setIn(['roundAirlines', 'showReturnCircle'], true);
		case types.FETCH_CHANNEL_RETURN_AIRLINES_SUCCESS:
			return state
				.setIn(['roundAirlines', 'showReturnCircle'], false)
				.setIn(['roundAirlines', 'roundAirlines'], Immutable.fromJS(action.data));
		case types.FETCH_CHANNEL_RETURN_AIRLINES_FAILURE:
			return state.setIn(['roundAirlines', 'showReturnCircle'], false);
		//更改往返特惠出发地
		case types.CHANGE_CHANNEL_RETURN_FROM:
			return state.setIn(['roundAirlines', 'returnFrom'], action.cities);
		//更改往返目的地
		case types.CHANGE_CHANNEL_RETURN_TO:
			return state.setIn(['roundAirlines', 'returnTo'], action.cities);

		/* 单程特惠 */
		//获取单程特惠城市
		case types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS:
			return state.setIn(['singleAirlines', 'showSingleCircle'], true);
		case types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_SUCCESS:
			return state
				.setIn(['singleAirlines', 'singleAirlineLocations'], Immutable.fromJS(action.locations))
				.setIn(['singleAirlines', 'singleAirlineLocationNames'], Immutable.fromJS(action.names))
				.setIn(['singleAirlines', 'showSingleCircle'], false);
		case types.FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_FAILURE:
			return state.setIn(['singleAirlines', 'showSingleCircle'], false);
		//获取单程特惠航线
		case types.FETCH_CHANNEL_SINGLE_AIRLINES:
			return state.setIn(['singleAirlines', 'showSingleCircle'], true);
		case types.FETCH_CHANNEL_SINGLE_AIRLINES_SUCCESS:
			return state
				.setIn(['singleAirlines', 'singleAirlines'], Immutable.fromJS(action.data.slice(0, 12)))
				.setIn(['singleAirlines', 'showSingleCircle'], false);
		case types.FETCH_CHANNEL_SINGLE_AIRLINES_FAILURE:
			return state.setIn(['singleAirlines', 'showSingleCircle'], false);
		/* 热门主题 */
		case types.FETCH_HOT_TOPICS:
			return state.setIn(['hotTopics', 'hotTopics'], Immutable.fromJS(action.hotTopics));

		/* 重要公告 */
		case types.GEN_IMPORTANT_NOTICE:
			return state.setIn(
				['importantNotice', 'importantNoticeList'],
				Immutable.fromJS(action.importantNoticeList)
			);
		case types.GEN_CLOSE_IMPORTANT_NOTICE:
			return state.setIn(['importantNotice', 'showImportantNotice'], false);

		case types.FETCH_SERVICE_NOTICE_FAILURE:
			return state;

		/*首次进入气泡*/
		case types.CLOSE_FIRST_ENTER_TIP:
			return state.setIn(['others', 'isFirstEntryTip'], false);

		case types.OPEN_FIRST_ENTER_TOP:
			return state.setIn(['others', 'isFirstEntryTip'], true);

		/* 底部最新订单 */
		case types.FETCH_CHANNEL_LATEST_ORDERS:
			return state
				.setIn(['latestOrders', 'allLatestOrders'], Immutable.fromJS([]))
				.setIn(['latestOrders', 'visibleLatestOrders'], Immutable.fromJS([]));
		case types.FETCH_CHANNEL_LATEST_ORDERS_SUCCESS:
			return state
				.setIn(['latestOrders', 'allLatestOrders'], Immutable.fromJS(action.allLatestOrders))
				.setIn(
					['latestOrders', 'visibleLatestOrders'],
					Immutable.fromJS(action.allLatestOrders.slice(action.index, action.index + action.count))
				);
		case types.FETCH_CHANNEL_LATEST_ORDERS_FAILURE:
			return state
				.setIn(['latestOrders', 'allLatestOrders'], Immutable.fromJS([]))
				.setIn(['latestOrders', 'visibleLatestOrders'], Immutable.fromJS([]));
		case types.EXTRACT_CHANNEL_LATEST_ORDERS:
			let extractCount2 = action.index + action.count - action.allLatestOrders.size;
			extractCount2 = Math.max(0, extractCount2);
			return state.setIn(
				['latestOrders', 'visibleLatestOrders'],
				Immutable.fromJS(
					action.allLatestOrders
						.slice(action.index, action.index + action.count)
						.concat(action.allLatestOrders.slice(0, extractCount2))
				)
			);

		/* 底部特价航班 */
		case types.FETCH_CHANNEL_SPECIAL_AIRLINES:
			return state
				.setIn(['specialAirlines', 'specialAirlines'], Immutable.fromJS([]))
				.setIn(['specialAirlines', 'specialAirlinesIsLoading'], true);
		case types.FETCH_CHANNEL_SPECIAL_AIRLINES_SUCCESS:
			return state
				.setIn(['specialAirlines', 'specialAirlines'], Immutable.fromJS(action.specialAirlines))
				.setIn(['specialAirlines', 'specialAirlinesIsLoading'], false);
		case types.FETCH_CHANNEL_SPECIAL_AIRLINES_FAILURE:
			return state
				.setIn(['specialAirlines', 'specialAirlines'], Immutable.fromJS([]))
				.setIn(['specialAirlines', 'specialAirlinesIsLoading'], false);
		case types.SCROLL_LEFT_SPECIAL_AIRLINES:
			let delta1 = state.getIn(['others', 'smallScreen']) ? 3 : 4;
			return state.setIn(
				['specialAirlines', 'specialAirlinesScrollIndex'],
				Math.max(0, state.getIn(['specialAirlines', 'specialAirlinesIsLoading']) - delta1)
			);
		case types.SCROLL_RIGHT_SPECIAL_AIRLINES:
			let delta2 = state.getIn(['others', 'smallScreen']) ? 3 : 4;
			return state.setIn(
				['specialAirlines', 'specialAirlinesScrollIndex'],
				Math.min(
					state.getIn(['specialAirlines', 'specialAirlines']).size - delta2,
					state.getIn(['specialAirlines', 'specialAirlinesIsLoading']) + delta2
				)
			);

		//城市选择器，只会显示一个
		case types.SHOW_HOT_CITY_PICKER:
			return state
				.setIn(['search', 'showCityPicker'], Immutable.fromJS({ [action.name]: true }))
				.setIn(['search', 'poiResult'], Immutable.fromJS({}));

		case types.HIDE_HOT_CITY_PICKER:
			return state
				.setIn(['search', 'showCityPicker'], Immutable.fromJS({}))
				.setIn(['search', 'isNeedFocusSecond'], false);

		case types.EXCHANGE_CITY:
			let owDCityName = 'owDCity',
				owACityName = 'owACity',
				rtDCityName = 'rtDCity',
				rtACityName = 'rtACity';

			// if (state.currentFlightWay == "RT") {

			// }

			let owOldDCity = state.getIn(['poi', 'selectedCity', owDCityName]),
				owOldACity = state.getIn(['poi', 'selectedCity', owACityName]),
				rtOldDCity = state.getIn(['poi', 'selectedCity', rtDCityName]),
				rtOldACity = state.getIn(['poi', 'selectedCity', rtACityName]),
				exchangedCity = null;
			exchangedCity = state.getIn(['poi', 'selectedCity']).merge(
				Immutable.fromJS(
					Object.assign(
						{},
						{
							[owDCityName]: owOldACity,
							[owACityName]: owOldDCity,
							[rtDCityName]: rtOldACity,
							[rtACityName]: rtOldDCity,
							mtACity1: owOldDCity,
							mtDCity1: owOldACity,
						}
					)
				)
			);

			// return {
			//     ...state,
			//     selectedCity: {
			//         ...state.selectedCity,
			//         ...exchangedCity
			//     }
			// }
			return state.setIn(
				['poi', 'selectedCity'],
				state.getIn(['poi', 'selectedCity']).merge(Immutable.fromJS(exchangedCity))
			);

		//POI，只会显示一个
		case types.CHANGE_POI_KEY:
			return state.setIn(['poi', 'poiKey'], Immutable.fromJS({ [action.name]: action.key }));
		case types.HIDE_POI_CITY_PICKER:
			return state.setIn(['poi', 'poiResult'], Immutable.fromJS({}));
		case types.CHANGE_POI_LIST:
			if (action.key == state.getIn(['poi', 'poiKey', action.name])) {
				return state
					.setIn(['poi', 'poiKey'], Immutable.fromJS({ [action.name]: action.key }))
					.setIn(['poi', 'poiResult'], Immutable.fromJS(poiHandler.init(action.key, action.list, 0)));
			} else {
				return state;
			}
		case types.SELECT_POI_ITEM:
			let toAssignCityInfo = {
					[action.name]: action.city,
				},
				nextSelectedCity = state.getIn(['poi', 'selectedCity']);
			if (action.name.indexOf('mt') < 0) {
				switch (action.name.substr(2, 1)) {
					case 'D':
						if (action.city) {
							nextSelectedCity = nextSelectedCity.merge(Immutable.fromJS({ mtDCity1: action.city }));
						}
						break;
					case 'A':
						if (action.city && action.city.typeName !== '国家') {
							nextSelectedCity = nextSelectedCity.merge(Immutable.fromJS({ mtACity1: action.city }));
						}
						break;
				}
			}
			let anotherName = '',
				anotherName2 = '';
			if (action.name.indexOf('ow') == 0) {
				anotherName = action.name.replace(/ow/g, 'rt');
			} else if (action.name.indexOf('rt') == 0) {
				anotherName = action.name.replace(/rt/g, 'ow');
			} else if (action.name == 'mtDCity1') {
				anotherName = 'owDCity';
				anotherName2 = 'rtDCity';
			} else if (action.name == 'mtACity1') {
				anotherName = 'owACity';
				anotherName2 = 'rtACity';
			}

			if (anotherName) {
				toAssignCityInfo[anotherName] = action.city;
			}
			if (anotherName2) {
				toAssignCityInfo[anotherName2] = action.city;
			}

			return state
				.setIn(['poi', 'poiResult'], Immutable.fromJS({}))
				.setIn(['poi', 'selectedCity'], nextSelectedCity.merge(Immutable.fromJS(toAssignCityInfo)));
		case types.SAVE_LAST_POI:
			// return Object.assign({}, state, {
			//     lastPoi: action.list
			// })
			return state.setIn(['poi', 'lastPoi'], Immutable.fromJS(action.list));
		//热门国家热门国家模糊入口
		case types.CHANGE_ARRIVAL_COUNTRY:
			return state.setIn(['hotCountry', 'arrivalCountry'], action.arrivalCountry);
		case types.SWITCH_HOT_SEARCH:
			return state.setIn(['hotCountry', 'isHotSearch'], action.isHotSearch);
		case types.SWITCH_FREE_PICKER:
			return state.setIn(['hotCountry', 'isFreeDept'], !state.getIn(['hotCountry', 'isFreeDept']));
		case types.CHANGE_CURRENT_MONTH:
			return state.setIn(['hotCountry', 'currentMonthIndex'], action.currentMonthIndex);
		case types.CHANGE_SELECTED_DAY:
			return state.setIn(['hotCountry', 'selectedDayCalendar'], action.selectedDay);
		case types.CHANGE_FLOAT_DAYS:
			return state.setIn(['hotCountry', 'floatDays'], action.floatDays);
		case types.CHANGE_STAY_DAYS:
			return state.setIn(['hotCountry', 'stayDays'], action.stayDays);
		case types.CHANGE_SELECTED_TIME_ITEM:
			return state.setIn(['hotCountry', 'selectedTimeItems'], Immutable.fromJS(action.selectedTimeItems));
		case types.CHANGE_COUNRTY_SOURCE:
			return state.setIn(['hotCountry', 'countrySource'], action.countrySource);
		case types.CHANGE_INPUT_STRING:
			return state.setIn(['hotCountry', 'inputString'], action.inputString);

		//高级搜索航司下拉及搜索框
		case types.FETCH_AIRLINES_LIST:
			return state;
		case types.FETCH_AIRLINES_LIST_SUCCESS:
			return state
				.setIn(['airline', 'suggestionAirlines'], Immutable.fromJS(action.suggestionAirlines))
				.setIn(['airline', 'allAirlines'], Immutable.fromJS(action.allAirlines));
		case types.FETCH_AIRLINES_LIST_FAILRUE:
			return state;
		case types.SELECT_AIRLINE:
			return state.setIn(['airline', 'airlineSelected'], action.airline);
		//搜索框日期选择
		case types.CHANGE_OW_START_DATE:
		case types.CHANGE_RT_START_DATE:
			let newSelectedCity = state.getIn(['poi', 'selectedCity']);
			if (!state.getIn(['poi', 'selectedCity', 'mtStartDate1'])) {
				newSelectedCity = newSelectedCity.merge(Immutable.fromJS({ mtStartDate1: action.date }));
			}

			newSelectedCity = newSelectedCity.merge(Immutable.fromJS({ mtStartDate1: action.date }));

			return state.withMutations((f) => {
				return f
					.setIn(['search', 'owDDate'], action.date)
					.setIn(['search', 'owDDateString'], action.dateStr)
					.setIn(['search', 'rtDDate'], action.date)
					.setIn(['search', 'rtDDateString'], action.dateStr)
					.setIn(['search', 'focusReturnDate'], !!(action.date && !state.rtEndDate))
					.setIn(['poi', 'selectedCity'], Immutable.fromJS(newSelectedCity))
					.setIn(['search', 'changeTime'], new Date().getTime());
			});

		case types.CHANGE_OW_END_DATE:
			// 往返，返程日期异常排查
			let d;
			if (moment.isMoment(action.date)) {
				let f = action.date.format('YYYY-MM-DD').toLowerCase();
				if (f !== 'invalid date') {
					d = action.date;
				}
			} else if (action.date instanceof Date) {
				d = moment(action.date);
			} else if (typeof action.date === 'string' && action.date.length === 13) {
				d = moment(+action.date);
			}
			if (!d) {
				UbtHandler.onUploadData('c_err_channel_rt_date', {
					date: action.date.toString(),
					remark: '频道首页，返程日期错误',
				});
				d = action.date;
			}
			return state.setIn(['search', 'rtEndDateString'], action.dateStr).setIn(['search', 'rtEndDate'], d);
		case types.CHANGE_RT_END_DATE:
			return state
				.setIn(['search', 'focusReturnDate'], null)
				.setIn(['search', 'rtEndDateString'], action.dateStr)
				.setIn(['search', 'rtEndDate'], action.date);
		case types.CHANGE_MT_START_DATE:
			let selectedCity = state
				.getIn(['poi', 'selectedCity'])
				.merge(Immutable.fromJS({ [`mtStartDate${action.index + 1}`]: action.date }));
			if (action.index == 0) {
				return state
					.setIn(['poi', 'selectedCity'], selectedCity)
					.setIn(['search', 'owDDate'], action.date)
					.setIn(['search', 'rtDDate'], action.date)
					.setIn(['search', 'owDDateString'], action.dateStr)
					.setIn(['search', 'rtDDateString'], action.dateStr);
			} else {
				return state.setIn(['poi', 'selectedCity'], selectedCity);
			}

		case types.CANCEL_FOCUS:
			return state.setIn(['search', 'focusReturnDate'], false);
		case types.SHOW_WARN:
			return state.setIn(['search', 'showValidateWarnTip'], true);

		// 搜索框多程
		case types.ADD_MT_SEG:
			if (state.getIn(['search', 'canAddMoreMtSeg'])) {
				const selectedCityState = state.getIn(['poi', 'selectedCity']);
				const addMtCount = getMtSegList(selectedCityState).keySeq().size;
				const selectedCity = selectedCityState.merge(
					Immutable.fromJS({
						[`mtDCity${addMtCount + 1}`]: null,
						[`mtACity${addMtCount + 1}`]: null,
						[`mtStartDate${addMtCount + 1}`]: null,
					})
				);
				return state
					.setIn(['poi', 'selectedCity'], selectedCity)
					.setIn(['search', 'canAddMoreMtSeg'], addMtCount < 5);
			} else {
				return state;
			}
		case types.DEL_MT_SEG:
			const selectedCityState = state.getIn(['poi', 'selectedCity']);
			const delMtCount = getMtSegList(selectedCityState).keySeq().size,
				toAssign = {};

			for (let i = action.index + 1; i < delMtCount; i++) {
				toAssign[`mtACity${i}`] = state.getIn(['poi', 'selectedCity', `mtACity${i + 1}`]);
				toAssign[`mtDCity${i}`] = state.getIn(['poi', 'selectedCity', `mtDCity${i + 1}`]);
				toAssign[`mtStartDate${i}`] = state.getIn(['poi', 'selectedCity', `mtStartDate${i + 1}`]);
			}

			let SelectedCity = state.getIn(['poi', 'selectedCity']).merge(Immutable.fromJS(toAssign));
			SelectedCity = SelectedCity.delete(`mtACity${delMtCount}`)
				.delete(`mtDCity${delMtCount}`)
				.delete(`mtStartDate${delMtCount}`);

			return state
				.setIn(['poi', 'selectedCity'], SelectedCity)
				.setIn(['search', 'canAddMoreMtSeg'], delMtCount <= 6);
		case types.EXCHANGE_MT_SEG:
			let newMtCity = {},
				newExchangeDCity = state.getIn(['poi', 'selectedCity', `mtACity${action.index + 1}`]),
				newExchangeACity = state.getIn(['poi', 'selectedCity', `mtDCity${action.index + 1}`]);

			newMtCity[`mtDCity${action.index + 1}`] = newExchangeDCity;
			newMtCity[`mtACity${action.index + 1}`] = newExchangeACity;
			if (action.index == 0) {
				newMtCity[`owDCity`] = newExchangeDCity;
				newMtCity[`owACity`] = newExchangeACity;
				newMtCity[`rtDCity`] = newExchangeDCity;
				newMtCity[`rtACity`] = newExchangeACity;
			}

			let newSelectedCity1 = state.getIn(['poi', 'selectedCity']).merge(Immutable.fromJS(newMtCity));
			return state.setIn(['poi', 'selectedCity'], newSelectedCity1);
		case types.FOCUS_MT_SECOND_DCITY:
			return state.setIn(['search', 'isNeedFocusSecond'], true);

		// 搜索框乘客人数
		case types.CHANGE_PASSENGER_COUNT: {
			const domestic = action.domestic,
				path = domestic ? ['search', 'dPassenger'] : ['search', 'iPassenger'],
				passenger = state.getIn(path);
			return state
				.setIn(path, passenger.merge(Immutable.fromJS(action.passenger)))
				.setIn(['search', 'isShowFltPlusHotelTip'], false);
		}

		//搜索框宽度
		case types.SEARCH_BOX_WIDTH:
			return state.setIn(['search', 'searchBoxWidth'], action.searchBoxWidth);

		//高级查询
		case types.TOGGLE_ADVANCED_SEARCH:
			return state.setIn(
				['search', 'searchBoxShowAdvancedSearch'],
				!state.getIn(['search', 'searchBoxShowAdvancedSearch'])
			);
		case types.CHANGE_CLASS_GRADE: {
			if (action.domestic) {
				return state.setIn(['search', 'dClassGrade'], action.classGrade);
			} else {
				return state.setIn(['search', 'iClassGrade'], action.classGrade);
			}
		}

		case types.CHANGE_CHANNEL_SEARCH_CONDITION:
			return state.setIn(['toolBox', 'searchCondition'], action.currentSearchConditon);
		case types.SEARCH_CHANNEL_FLIGHT:
			if (state.getIn(['toolBox', 'searchCondition']) == 'searchByCity') {
				let depart = state.getIn(['poi', 'selectedCity', 'owSearchDCityPicker']), //出发
					arrive = state.getIn(['poi', 'selectedCity', 'owSearchACityPicker']), //到达
					url;

				depart = depart ? depart.get('Code') : '';
				arrive = arrive ? arrive.get('Code') : '';

				if (depart == '' && arrive == '') {
					url = '';
				} else if (arrive == '') {
					url = 'depart-' + depart + '/';
				} else if (depart == '') {
					url = 'arrive-' + arrive + '/';
				} else {
					url = [depart, arrive].join('-') + '/';
				}

				window.open('//flights.ctrip.com/actualtime/' + url.toLowerCase(), '_blank');
				return state;
			}
			if (state.getIn(['toolBox', 'searchCondition']) == 'searchByFlightNo') {
				if (!/^([a-zA-Z]|\d){2}\d{1,4}$/.test(state.getIn(['toolBox', 'flightNo']))) {
					return state.setIn(['toolBox', 'isShowInvalidFltNo'], true);
				}
				window.open(
					'//flights.ctrip.com/actualtime/fno-' + state.getIn(['toolBox', 'flightNo']).toLowerCase() + '/',
					'_blank'
				);
				return state.setIn(['toolBox', 'isShowInvalidFltNo'], false);
			}
			break;
		case types.CHANGE_CHANNEL_FLIGHTNO:
			return state.setIn(['toolBox', 'flightNo'], action.flightNo);
		case types.SELECT_CHANNEL_FLIGHTWAY:
			let nonErr = {
				dCityError: '',
				aCityError: '',
				dDateError: '',
				aDateError: '',
				mtError: {},
			};

			let owDCity = state.getIn(['poi', 'selectedCity', 'owDCity']),
				owACity = state.getIn(['poi', 'selectedCity', 'owACity']),
				rtDCity = state.getIn(['poi', 'selectedCity', 'rtDCity']),
				rtACity = state.getIn(['poi', 'selectedCity', 'rtACity']);

			let toCopyCity = {};
			switch (action.flightWay) {
				case 'OW':
				case 'RT':
					if (action.flightWay == 'OW') {
						if (rtDCity && !owDCity) {
							toCopyCity.owDCity = rtDCity;
						}
						if (rtACity && !owACity) {
							toCopyCity.owACity = rtACity;
						}
					}

					if (action.flightWay == 'RT') {
						if (owDCity && !rtDCity) {
							toCopyCity.rtDCity = owDCity;
						}
						if (owACity && !rtACity) {
							toCopyCity.rtACity = owACity;
						}
					}

					return state.withMutations((f) => {
						return f
							.set(['search'], state.get('search').merge(Immutable.fromJS(nonErr)))
							.setIn(['search', 'currentFlightWay'], action.flightWay)
							.setIn(
								['search', 'owADate'],
								action.flightWay == 'OW' ? null : state.getIn(['search', 'owADate'])
							)
							.setIn(
								['poi', 'selectedCity'],
								state.getIn(['poi', 'selectedCity']).merge(Immutable.fromJS(toCopyCity))
							);
					});

				case 'MT':
					const selectedCityState = state.getIn(['poi', 'selectedCity']);
					const newList = getMtSegList(selectedCityState);
					const selectSomeCity = newList
						.keySeq()
						.some((t) => newList.getIn([t, 'dCityText']) || newList.getIn([t, 'aCityText']));
					let nextSelectedCity = state.getIn(['poi', 'selectedCity']);
					if (!selectSomeCity) {
						nextSelectedCity.set('mtDCity1', state.getIn(['poi', 'selectedCity', 'owDCity']));
						nextSelectedCity.set('mtACity1', state.getIn(['poi', 'selectedCity', 'owACity']));
					}
					nextSelectedCity.keySeq().forEach(function (key) {
						let s = nextSelectedCity.get(key);
						if (key.indexOf('mtS') == 0) {
							nextSelectedCity = nextSelectedCity.set(key, s && moment(s));
						}
					});
					// 页面刚加载时默认为三个，不得少于三个
					for (let i = 1; i <= 3; i++) {
						if (!nextSelectedCity.get(`mtDCity${i}`)) {
							nextSelectedCity.set(`mtDCity${i}`, null);
							nextSelectedCity.set(`mtACity${i}`, null);
							nextSelectedCity.set(`mtStartDate${i}`, null);
						}
					}
					return state
						.set(['search'], state.get('search').merge(Immutable.fromJS(nonErr)))
						.setIn(['search', 'currentFlightWay'], action.flightWay)
						.setIn(
							['poi', 'selectedCity'],
							state.getIn(['poi', 'selectedCity']).merge(Immutable.fromJS(toCopyCity))
						);
				default:
			}

			break;
		// 机票工具栏上边距重置
		case types.SIDEBOX_STYLE_RESET:
			return state.setIn(['search', 'sideBoxMarginTop'], action.sideBoxMarginTop);

		// 日历低价
		case types.FETCH_LOW_PRICE_SUCCESS:
			const { dCityCode, aCityCode, list = [], cabinCode } = action;
			let lowestPrice = {},
				resultList = {};
			list.forEach((item) => {
				const itemDepartDate = item.departDate?.includes('Date')
					? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD HH:mm:ss')
					: item.departDate;
				let theMonth = moment(itemDepartDate).format('MM'),
					formatDay = moment(itemDepartDate).format('YYYY-MM-DD');
				resultList[formatDay] = item.price;
				if (!lowestPrice[theMonth] || lowestPrice[theMonth] > item.price) {
					lowestPrice[theMonth] = item.price;
				}
			});
			let lowPriceKey = getLowPriceKey({
				departureCityCode: dCityCode,
				arrivalCityCode: aCityCode,
				cabinCode,
			});
			return state
				.setIn(
					['calendar', 'lowPrice'],
					Immutable.fromJS(Object.assign({}, state.lowPrice, { [`${lowPriceKey}`]: resultList }))
				)
				.setIn(
					['calendar', 'lowestPrice'],
					Immutable.fromJS(Object.assign({}, state.lowestPrice, { [`${lowPriceKey}`]: lowestPrice }))
				);
		case types.FETCH_LOW_RPRICE_SUCCESS: {
			// 20191128: {20191130: 1068, 20191130: 1068} domestic data
			let { departureCityCode, arrivalCityCode, pricelist = [] } = action,
				stateList = {};
			let lowestRPrice = {};
			pricelist.forEach((item) => {
				const itemReturnDate = item.returnDate?.includes('Date')
					? moment(new Date(parseInt(item.returnDate.slice(6, 19)))).format('YYYY-MM-DD HH:mm:ss')
					: item.returnDate;
				const itemDepartDate = item.departDate?.includes('Date')
					? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD HH:mm:ss')
					: item.departDate;
				let arrivalDate = moment(itemReturnDate).format('YYYY-MM-DD'),
					theMonth = moment(itemReturnDate).format('MM');
				const departDate = moment(itemDepartDate).format('YYYY-MM-DD');
				stateList[`${departDate}-${arrivalDate}`] = item['price'];
				if (
					!lowestRPrice[`${departDate}-${theMonth}`] ||
					lowestRPrice[`${departDate}-${theMonth}`] > item['price']
				) {
					lowestRPrice[`${departDate}-${theMonth}`] = item['price'];
				}
			});
			let lowPriceKey = getLowPriceKey({
				departureCityCode,
				arrivalCityCode,
				cabinCode: action.cabinCode,
			});

			return state
				.setIn(
					['calendar', 'lowRPrice'],
					Immutable.fromJS(Object.assign({}, state.lowRPrice, { [`${lowPriceKey}`]: stateList }))
				)
				.setIn(
					['calendar', 'lowestRPrice'],
					Immutable.fromJS(Object.assign({}, state.lowestRPrice, { [`${lowPriceKey}`]: lowestRPrice }))
				);
		}

		// 精选地区
		case hotDistrictTypes.CHANGE_ARRIVAL_DISTRICT:
			return state.setIn(['hotDistrict', 'arrivalDistrict'], action.arrivalDistrict);
		case hotDistrictTypes.SWITCH_TO_DISTRICT_SEARCH:
			return state.setIn(['hotDistrict', 'isDistrictSearch'], action.isDistrictSearch);
		case hotDistrictTypes.SET_HOT_DISTRICT_LIST:
			return state.setIn(['hotDistrict', 'hotDistrictList'], action.districtList);

		// 精选地区低价日历

		case hotDistrictTypes.FETCH_COUNTRY_LOW_PRICE_SUCCESS_ACTION:
			return (() => {
				let { aCityCode, list = {}, dCityCode } = action;
				let countryLowestPrice = {};

				Object.keys(list).forEach((p) => {
					let theMonth = moment(p).format('MM');
					if (!countryLowestPrice[theMonth] || countryLowestPrice[theMonth] > list[p]) {
						countryLowestPrice[theMonth] = list[p];
					}
				});
				let oldCountryLowPrice = state.getIn(['calendar', 'countryLowPrice']).toJS();
				let oldCountryLowestPrice = state.getIn(['calendar', 'countryLowestPrice']).toJS();
				return state
					.setIn(
						['calendar', 'countryLowPrice'],
						Immutable.fromJS(Object.assign({}, oldCountryLowPrice, { [`${dCityCode}-${aCityCode}`]: list }))
					)
					.setIn(
						['calendar', 'countryLowestPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowestPrice, {
								[`${dCityCode}-${aCityCode}`]: countryLowestPrice,
							})
						)
					);
			})();

		// newState.countryLowestPrice[`${dCityCode}-${aCityCode}`] = countryLowestPrice
		// newState.countryLowPrice[`${dCityCode}-${aCityCode}`] = list

		case hotDistrictTypes.FETCH_COUNTRY_LOW_R_PRICE_SUCCESS_ACTION:
			return (() => {
				let { dCityCode, aCityCode, list = {} } = action;
				let lowestPrice = {};
				// list {2018-10-11(开始时间):{2019-10-22(返回日期): 1908(往返总价)}}
				// lowestPrice{2018-10-11(开始时间):{'04(返程月份)': 900(最低价)}}
				Object.keys(list).forEach((p) => {
					let priceMap = list[p];
					Object.keys(priceMap).forEach((aDate) => {
						let theAMonth = moment(aDate).format('MM');
						if (!lowestPrice[p]) {
							lowestPrice[p] = {};
						}
						if (
							!lowestPrice[p][theAMonth] ||
							(list[p][aDate] && lowestPrice[p][theAMonth] > list[p][aDate])
						) {
							lowestPrice[p][theAMonth] = list[p][aDate];
						}
					});
				});
				let oldCountryLowRPrice = state.getIn(['calendar', 'countryLowRPrice']).toJS();
				let oldCountryLowestRPrice = state.getIn(['calendar', 'countryLowestRPrice']).toJS();
				return state
					.setIn(
						['calendar', 'countryLowRPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowRPrice, { [`${dCityCode}-${aCityCode}`]: list })
						)
					)
					.setIn(
						['calendar', 'countryLowestRPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowestRPrice, { [`${dCityCode}-${aCityCode}`]: lowestPrice })
						)
					);
			})();
		// newState.countryLowestRPrice[`${dCityCode}-${aCityCode}`] = lowestPrice
		// newState.countryLowRPrice[`${dCityCode}-${aCityCode}`] = list

		case types.SHOW_INFANT_TIPS:
			return state.setIn(['search', 'isShowInfantTips'], true).setIn(['search', 'isShowFltPlusHotelTip'], true);

		case types.SHOW_CHILD_TIPS:
			return state
				.setIn(['search', 'isShowInfantTips'], false)
				.setIn(['search', 'isShowChildTips'], true)
				.setIn(['search', 'isShowFltPlusHotelTip'], true);

		case types.DDATE_ERROR:
			return state
				.setIn(['search', 'dDateError'], action.error)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.ADATE_ERROR:
			return state
				.setIn(['search', 'aDateError'], action.error)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.DCITY_ERROR:
			return state
				.setIn(['search', 'dCityError'], action.error)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.ACITY_ERROR:
			return state
				.setIn(['search', 'aCityError'], action.error)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.OW_RT_CLEAN_ERROR:
			return state.withMutations((f) => {
				return f
					.setIn(['search', 'dDateError'], '')
					.setIn(['search', 'aDateError'], '')
					.setIn(['search', 'dCityError'], '')
					.setIn(['search', 'aCityError'], '')
					.setIn(['search', 'showValidateErrorTip'], false);
			});
		case types.MT_DCITY_ERROR:
			return state
				.setIn(
					['search', 'mtError', `${action.index}`],
					state.getIn(['search', 'mtError', `${action.index}`])
						? state
								.getIn(['search', 'mtError', `${action.index}`])
								.merge(Immutable.fromJS({ dCity: action.error }))
						: Immutable.fromJS({ dCity: action.error })
				)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.MT_ACITY_ERROR:
			return state
				.setIn(
					['search', 'mtError', `${action.index}`],
					state.getIn(['search', 'mtError', `${action.index}`])
						? state
								.getIn(['search', 'mtError', `${action.index}`])
								.merge(Immutable.fromJS({ aCity: action.error }))
						: Immutable.fromJS({ aCity: action.error })
				)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.MT_DDATE_ERROR:
			return state
				.setIn(
					['search', 'mtError', `${action.index}`],
					state.getIn(['search', 'mtError', `${action.index}`])
						? state
								.getIn(['search', 'mtError', `${action.index}`])
								.merge(Immutable.fromJS({ dDate: action.error }))
						: Immutable.fromJS({ dDate: action.error })
				)
				.setIn(['search', 'showValidateErrorTip'], !!action.error);
		case types.MT_CLEAN_ERROR:
			return state
				.setIn(['search', 'mtError'], Immutable.fromJS({}))
				.setIn(['search', 'showValidateErrorTip'], !!action.error);

		case types.CANCEL_FOCUS_MT_SECOND_DCITY:
			return state.setIn(['search', 'isNeedFocusSecond'], false);
		//机加酒
		case types.SEARCH_FLT_PLUS_HOTEL:
			return state.setIn(['search', 'isFltPlusHotel'], true);

		//有告警搜索按钮不跳转
		case types.SET_CAN_NOT_SEARCH:
			return state.setIn(['search', 'canNotSearch'], true);
		case types.RESET_CAN_NOT_SEARCH:
			return state.setIn(['search', 'canNotSearch'], false);
		case types.SET_DIRECT_FLIGHT:
			return state.setIn(['search', 'directflight'], action.checked);
		case types.SEARCH_INIT_GLOBAL_SWITCH: {
			return state
				.setIn(['globalSwitch', 'priceMapSwitch'], !!action.globalSwitch.priceMapSwitch)
				.setIn(['globalSwitch', 'hotDistrictMemorySwitch'], !!action.globalSwitch.hotDistrictMemorySwitch)
				.setIn(['globalSwitch', 'owRecommendRtSwitch'], !!action.globalSwitch.owRecommendRtSwitch)
				.setIn(['globalSwitch', 'channelAdSwitch'], !!action.globalSwitch.channelAdSwitch)
				.setIn(['globalSwitch', 'channelSearchMapSwitch'], !!action.globalSwitch.channelSearchMapSwitch)
				.setIn(
					['globalSwitch', 'hideAllFlightIsDomesticSwitch'],
					!!action.globalSwitch.hideAllFlightIsDomesticSwitch
				)
				.setIn(['globalSwitch', 'lowPriceReportSwitch'], !!action.globalSwitch.lowPriceReportSwitch)
				.setIn(
					['globalSwitch', 'lowPriceReportShowAfterLowPriceMapSwitch'],
					!!action.globalSwitch.lowPriceReportShowAfterLowPriceMapSwitch
				)
				.setIn(
					['globalSwitch', 'appendListPageInChannelSwitch'],
					!!action.globalSwitch.appendListPageInChannelSwitch
				)
				.setIn(['globalSwitch', 'searchBoxFixTopSwitch'], !!action.globalSwitch.searchBoxFixTopSwitch)
				.setIn(['globalSwitch', 'defaultSearchValueSwitch'], !!action.globalSwitch.defaultSearchValueSwitch)
				.setIn(
					['globalSwitch', 'domesticSearchSwitch'],
					!!action.globalSwitch.domesticSearchSwitch || isDomesticSearch
				)
				.setIn(['globalSwitch', 'appendSignToListPageSwitch'], !!action.globalSwitch.appendSignToListPageSwitch)
				.setIn(['globalSwitch', 'homePreAggSearchSwitch'], !!action.globalSwitch.homePreAggSearchSwitch)
				.setIn(['globalSwitch', 'smoothAnimationSwitch'], !!action.globalSwitch.smoothAnimationSwitch)
				.setIn(['globalSwitch', 'changeChannelPageIdSwitch'], !!action.globalSwitch.changeChannelPageIdSwitch);
		}

		case types.SET_PRICE_MAP_SWITCH:
			return state.setIn(['globalSwitch', 'priceMapSwitch'], action.priceMapSwitch);
		// 持久数据恢复
		case types.CACHE_RECOVERY:
			let toMoment = (date) => {
					return date && moment(date);
				},
				setSearchDataNull = state.getIn(['globalSwitch', 'defaultSearchValueSwitch']),
				todayStr = moment().format('YYYY-MM-DD'),
				isBeforeToday = (day) => {
					if (!day) {
						return false;
					}
					return day.isBefore(todayStr);
				},
				mtDDateIsExpired = false,
				dDateIsExpired = false,
				aDateIsExpired = false,
				toMomentArr = (arr) => {
					Object.keys(arr).forEach((key) => {
						let item = arr[key];
						if (key.indexOf('mtS') == 0) {
							let mtIndex = key.substr(key.length - 1);
							let mtDDate = moment().add(3, 'days');
							if (mtIndex == 1) {
								mtDDateIsExpired = isBeforeToday(toMoment(item));
								mtDDate = mtDDateIsExpired && setSearchDataNull ? null : moment().add(1, 'days');
							} else {
								mtDDate =
									mtDDateIsExpired && setSearchDataNull
										? null
										: moment().add(3 * (mtIndex - 1) + 1, 'days');
							}
							arr[key] = mtDDateIsExpired && item ? mtDDate : toMoment(item);
						}
					});
					return arr;
				},
				//三程以上，如果全是空则不记录，如果有不是空则全部记录
				deleteSpaceArr = (arr) => {
					let hasSpaceNoUse = false;
					let delMtCount = 3;
					let mtCount = 0;
					Object.keys(arr).forEach((key) => {
						if (key.indexOf('mt') == 0) {
							let index = key.substr(-1);
							if (index > 3) {
								delMtCount = index;
							}
						}
						if (key.indexOf('mtDCity') == 0) {
							mtCount++;
						}
					});
					for (let i = 4; i <= delMtCount; i++) {
						if (arr[`mtDCity${i}`] == null && arr[`mtACity${i}`] == null) {
							hasSpaceNoUse = true;
						} else if (arr[`mtDCity${i}`] != null || arr[`mtACity${i}`] != null) {
							hasSpaceNoUse = false;
						}
					}
					if (hasSpaceNoUse == true) {
						for (let i = 4; i <= delMtCount; i++) {
							delete arr[`mtACity${i}`];
							delete arr[`mtDCity${i}`];
							delete arr[`mtStartDate${i}`];
						}
					}
					// 不够三程，补上空的
					if (mtCount < 3) {
						for (let i = mtCount; i <= 3; i++) {
							if (!arr[`mtACity${i}`]) {
								arr[`mtACity${i}`] = null;
							}
							if (!arr[`mtDCity${i}`]) {
								arr[`mtDCity${i}`] = null;
							}
							if (!arr[`mtStartDate${i}`]) {
								arr[`mtStartDate${i}`] = null;
							}
						}
					}
					return arr;
				};

			let persist = action.persist;
			let hasFlightWay = !!(persist && (persist.flightWay || persist.currentFlightWay)),
				currentFlightWay = '';

			if (hasFlightWay) {
				currentFlightWay = persist.flightWay ? persist.flightWay : persist.currentFlightWay;
			}

			if (persist && currentFlightWay && persist.selectedCity) {
				let owStart = moment(persist.owDDate),
					owEnd = persist.rtADate ? moment(persist.rtADate) : '',
					rtStart = moment(persist.owDDate),
					rtEnd = persist.rtADate ? moment(persist.rtADate) : '';

				// 新版搜索框需要重新计算下日期
				if (currentFlightWay == 'RT' || currentFlightWay == 'OW') {
					let newDate = searchDataHelper.getSearchDate({
						flightWay: currentFlightWay,
						dDate: persist.owDDate,
						aDate: persist.rtEndDate || persist.rtADate,
					});
					owStart = moment(newDate.dDate);
					rtStart = moment(newDate.dDate);
					rtEnd = moment(newDate.aDate);
					owEnd = moment(newDate.aDate);
					aDateIsExpired = newDate.aDateIsExpired;
					dDateIsExpired = newDate.dDateIsExpired;
				}
				if (setSearchDataNull) {
					// 过期时间不显示
					if (aDateIsExpired) {
						rtEnd = null;
						owEnd = null;
					}
					if (dDateIsExpired) {
						owStart = null;
						rtStart = null;
					}
				}

				let owStartString = owStart && owStart.isValid() ? owStart.format('YYYY-MM-DD') : '',
					owEndString = owEnd && owEnd.isValid() ? owEnd.format('YYYY-MM-DD') : '',
					rtStartString = rtStart && rtStart.isValid() ? rtStart.format('YYYY-MM-DD') : '',
					rtEndString = rtEnd && rtEnd.isValid() ? rtEnd.format('YYYY-MM-DD') : '',
					isDistrictSearch =
						!!(persist.arrivalDistrict && persist.arrivalDistrict.areaCode && persist.arrivalDistrict.areaName),
					arrivalDistrict = isDistrictSearch ? persist.arrivalDistrict : null,
					hotDistrictList = {};
				if (isDistrictSearch && arrivalDistrict) {
					let isAirPort = persist.selectedCity.owDCity.isPort && persist.selectedCity.owDCity.ParentCode;
					let cityCode = isAirPort
						? persist.selectedCity.owDCity.ParentCode.toUpperCase()
						: persist.selectedCity.owDCity.Code.toUpperCase();
					hotDistrictList[`${cityCode}`] = [
						{
							countryList: [{ areaCode: arrivalDistrict.areaCode, areaName: arrivalDistrict.areaName }],
						},
					];
				}
				let initCurrentFlightWay = currentFlightWay,
					adtCount = persist.adultCount || persist.adultCount == 0 ? persist.adultCount : 1,
					chiCount = persist.childCount || 0,
					infCount = persist.infantCount || 0,
					classGrade = persist.classGrade ? persist.classGrade : 'Y_S',
					domesticPassenger =
						Immutable.fromJS(persist.domesticPassenger) || state.getIn(['search', 'domesticPassenger']),
					domesticClassGrade = persist.domesticClassGrade || state.getIn(['search', 'domesticClassGrade']);

				// 同步下地图搜索数据
				let mapSearch = state.getIn(['mapSearch']).merge(
					Immutable.fromJS({
						flightWay: initCurrentFlightWay,
						dDate: owStart || moment().add('days', 1),
						aDate: rtEnd && rtEnd.isValid() ? rtEnd : null,
						dCity: getSearchMapCity(persist.selectedCity.owDCity),
					})
				);
				return state.withMutations((f) => {
					return f
						.setIn(['search', 'currentFlightWay'], initCurrentFlightWay)
						.setIn(['search', 'searchBoxWidth'], SEARCH_BOX_WIDTH.NOT_MT)
						.setIn(
							['poi', 'selectedCity'],
							Immutable.fromJS(deleteSpaceArr(toMomentArr(persist.selectedCity)))
						)
						.setIn(['search', 'dCityText'], persist.dCityText)
						.setIn(['search', 'aCityText'], persist.aCityText)
						.setIn(['search', 'owADate'], owEnd)
						.setIn(['search', 'owDDate'], owStart)
						.setIn(['search', 'rtDDate'], rtStart)
						.setIn(['search', 'rtEndDate'], rtEnd || null)
						.setIn(['search', 'owDDateString'], owStartString)
						.setIn(['search', 'owADateString'], owEndString)
						.setIn(['search', 'rtDDateString'], rtStartString)
						.setIn(['search', 'rtEndDateString'], rtEndString)
						.setIn(['search', 'adtCount'], adtCount)
						.setIn(['search', 'chiCount'], chiCount)
						.setIn(['search', 'infCount'], infCount)
						.setIn(['search', 'classGrade'], classGrade)
						.setIn(['search', 'domesticClassGrade'], domesticClassGrade)
						.setIn(['search', 'domesticPassenger'], domesticPassenger)
						.setIn(['hotDistrict', 'isDistrictSearch'], isDistrictSearch)
						.setIn(['hotDistrict', 'arrivalDistrict'], arrivalDistrict)
						.setIn(['hotDistrict', 'hotDistrictList'], hotDistrictList)
						.setIn(['mapSearch'], mapSearch);
				});
			} else if (!persist) {
				let { dDate, aDate } = searchDataHelper.getSearchDate({
						dDate: null,
						aDate: null,
					}),
					defaultFlightWay = 'RT';

				aDate = moment(aDate);
				dDate = moment(dDate);
				if (setSearchDataNull) {
					aDate = null;
					dDate = null;
				}
				// 国内页面默认单程
				if (isDomesticSearch) {
					(defaultFlightWay = 'OW'), (aDate = null);
				}
				return state.withMutations((f) => {
					return f
						.setIn(['search', 'currentFlightWay'], defaultFlightWay)
						.setIn(['search', 'owADate'], aDate || null)
						.setIn(['search', 'owDDate'], dDate)
						.setIn(['search', 'rtDDate'], dDate)
						.setIn(['search', 'rtEndDate'], aDate || null)
						.setIn(['search', 'owDDateString'], dDate && dDate.isValid && dDate.format('YYYY-MM-DD'))
						.setIn(['search', 'owADateString'], aDate && aDate.isValid && aDate.format('YYYY-MM-DD'))
						.setIn(['search', 'rtDDateString'], dDate && dDate.isValid && dDate.format('YYYY-MM-DD'))
						.setIn(['search', 'rtEndDateString'], aDate && aDate.isValid && aDate.format('YYYY-MM-DD'));
				});
			} else {
				return state;
			}
		//获取工作日数据
		case COMMON_TYPES.COMMON_GET_WORKINGDAYS:
			return state.setIn(['common', 'workingDays'], Immutable.fromJS(action.workingDays));

		// 地图相关
		case mapSearch.mapSearch.INIT_MAP_URL:
			const mapUrlList = googleMapDomainList.map((k) => {
				return `${k}${action.data}`;
			});
			return state.setIn(['mapSearch', 'googleJsUrlList'], mapUrlList);
		case mapSearch.mapSearch.UPDATE_ISLOADED_GOOGLEMAP:
			return state
				.setIn(['mapSearch', 'isLoadedGoogleMap'], true)
				.setIn(['mapSearch', 'isLoadedGoogleMap'], action.mapUrl);
		case mapSearch.mapSearch.INIT_COUNTRY_LIST:
			const countryListInfo = {
				dataList: action.data || {},
			};
			return state.setIn(['mapSearch', 'countryListInfo'], countryListInfo);
		case types.SET_SEARCH_MAP_DCITY:
			return state.setIn(['mapSearch', 'dCity'], Immutable.fromJS(action.city));
		case types.SET_SEARCH_MAP_DDATE:
			if (action.date && action.date.isValid && !action.date.isValid()) {
				action.date = null;
			} else {
				action.date = moment(action.date);
			}
			return state.setIn(['mapSearch', 'dDate'], action.date);

		case types.SET_SEARCH_MAP_ADATE:
			if (action.date && action.date.isValid && !action.date.isValid()) {
				action.date = null;
			} else {
				action.date = moment(action.date);
			}
			return state.setIn(['mapSearch', 'aDate'], action.date);
		case types.SET_SEARCH_MAP_ACOUNTRY:
			return state.setIn(['mapSearch', 'aCountry'], Immutable.fromJS(action.country));
		case types.SET_SEARCH_MAP_FLIGHTWAY:
			return state.setIn(['mapSearch', 'flightWay'], action.flightWay);
		case types.SET_SEARCH_MAP_DIRECT_FLIGHT:
			return state.setIn(['mapSearch', 'directFlight'], action.directFlight);
		case types.SET_SEARCH_MAP_FETCH_STATUS:
			return state.setIn(['mapSearch', 'fetchCountryDataStatus'], action.status);

		case types.set_SEARCH_MAP_SEARCH_RESULT:
			// {dCode_aCode_flightWay_dDate_aDate: list}
			if (action.flightKey) {
				let newsSearchMapResult = state.getIn(['mapSearch', 'searchResult']);
				if (!newsSearchMapResult) {
					newsSearchMapResult = {};
				}
				newsSearchMapResult[action.flightKey] = action.countries;

				return state.setIn(['mapSearch', 'searchResult'], newsSearchMapResult);
			} else {
				return state;
			}
		case types.SET_LOW_PRICE_REPORT_SEARCH_RESULT_LOADING_STATUS: {
			return state.setIn(['lowPriceReport', 'loading'], action.status);
		}
		case types.CLEAR_LOW_PRICE_REPORT_SEARCH_RESULT:
			return state.setIn(['lowPriceReport', 'searchResult'], Immutable.fromJS({}));

		case types.SET_LOW_PRICE_REPORT_SEARCH_RESULT: {
			let { searchKey, index, regionCode, result, name, dCityName, showDcity } = action,
				searchResult = state.getIn(['lowPriceReport', 'searchResult']),
				newState = state,
				show = true,
				domesticSearchPage = state.getIn(['domesticSearchPage']);

			// 精选推荐所有数据都是国内的就不展示了
			if (name == '精选推荐' && hideAllFlightIsDomesticSwitch && !domesticSearchPage) {
				show = false;
				let international = result.filter((t) => t.international === true);
				if (international && international.length) {
					show = true;
				}
				// 新版的国内首页需要展示精选地区
				const isDomesticSearch = window.location.href.includes('international/search/domestic');
				if (isDomesticSearch) {
					show = true;
				}
			}
			if (show) {
				if (!searchResult.get(searchKey)) {
					newState = state.setIn(
						['lowPriceReport', 'searchResult', searchKey],
						Immutable.fromJS([
							{
								index,
								regionCode,
								result,
								name,
								show,
								dCityName,
								showDcity,
							},
						])
					);
				} else {
					let newResult = searchResult.get(searchKey).toJS();
					newResult.push({
						index,
						regionCode,
						result,
						name,
						show,
						dCityName,
						showDcity,
					});
					newState = state.setIn(['lowPriceReport', 'searchResult', searchKey], Immutable.fromJS(newResult));
				}
				newState = newState.setIn(['lowPriceReport', 'show'], true);
			}

			return newState;
		}
		case searchBoxTypes.SET_DOMESTIC_CITY_DATA:
			return state.setIn(['search', 'domesticCityData'], action.data);
		case searchBoxTypes.SET_FLIGHT_SAMECITY_DATA:
			return state.setIn(['search', 'flightSameCityData'], action.data);
		case searchBoxTypes.SET_DOMESTIC_PASSENGER_TYPE: {
			let keys = ['search', 'domesticPassenger', DOMESTIC_PASSENGER_TYPE.get(action.passengerType).value.name],
				selected = state.getIn(keys);
			return state.setIn(keys, !selected);
		}
		default:
			return state;
	}
}
