import Enum from 'enum'

export const POI_TYPE = new Enum({
    0: { text: '景点', canSelect: false },
    1: { text: '国家', canSelect: false },
    2: { text: '省份', canSelect: false },
    3: { text: '机场', canSelect: true },
    4: { text: '城市', canSelect: false },//无机场城市，不可选
    5: { text: '城市', canSelect: true }, //有机场城市，可选
    6: { text: '', canSelect: false },
    7: { text: '临近', canSelect: true },
    8: { text: '', canSelect: false },
    9: { text: '火车站', canSelect: true },
    10: { text: '汽车站', canSelect: true },
    11: { text: '码头', canSelect: true }
})

export const MOUSE_ACTION_SOURCE = new Enum([
    'CITY_PICKER'
])

export const SEARCH_BOX_WIDTH = new Enum({
    NOT_MT: 380,
    MT: 768
})

export const CALENDAR_WORK_STATUS = new Enum({
    NONE: '',
    WORK: '班',
    NOT_WORK: '休'
})

//@热门国家
//往返时出游天数枚举
export const HOT_COUNTRY_STAYDAYS = new Enum({
    FREE: {
        text: '不限',
        maxDays: null,
        minDays: null
    },
    THREE2FIVE: {
        text: '3-5天',
        maxDays: 5,
        minDays: 3
    },
    SIX2EIGHT: {
        text: '6-8天',
        maxDays: 8,
        minDays: 6
    },
    NINE2ELEVEN: {
        text: '9-11天',
        maxDays: 11,
        minDays: 9
    },
    TWELVE2FIFTEEN: {
        text: '12-15天',
        maxDays: 15,
        minDays: 12
    }
})
//跳转时ctm内容枚举：搜索的poi点击为search，热门城市国家选择器点击为suggestion
export const COUNTRY_SOURCE = new Enum({
    SEARCH: {
        ctm: '#ctm_ref=fli_hp_sb_findlprice_t'
    },
    SUGGESTION: {
        ctm: '#ctm_ref=fli_hp_sb_findlprice_b'
    }
})