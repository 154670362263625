import { CSSProperties } from 'react';

export type Renderable = JSX.Element | string | null;

export type ValueFunction<TValue, TArg> = (arg: TArg) => TValue;
export type ValueOrFunction<TValue, TArg> = TValue | ValueFunction<TValue, TArg>;

const isFunction = <TValue, TArg>(
    valOrFunction: ValueOrFunction<TValue, TArg>,
): valOrFunction is ValueFunction<TValue, TArg> => typeof valOrFunction === 'function';

export const resolveValue = <TValue, TArg>(valOrFunction: ValueOrFunction<TValue, TArg>, arg: TArg): TValue =>
    isFunction(valOrFunction) ? valOrFunction(arg) : valOrFunction;

export type ModalPosition = 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';

export interface IModal {
    id: string;

    style?: CSSProperties;
    className?: string;
    createdAt: number;
    autoClose: boolean; // 点击按钮后，是否自动关闭弹窗。默认为true

    // 遮罩层
    mask?: {
        enableClose?: boolean; // 点击遮照是否可以关闭
        style?: CSSProperties; // 遮罩层样式
        callback?: () => void;
    };
    title?: string; // 弹窗标题
    content?: ValueOrFunction<Renderable, IModal>; // 弹窗的内容
    icon?: Renderable; // 弹窗上的icon
    // 弹窗上的关闭按钮
    close?: {
        position?: string;
        callback?: (v?: unknown) => void;
        style?: CSSProperties;
    };
    // 弹窗上的主要按钮
    buttons?: {
        text?: string;
        class?: string;
        style?: CSSProperties;
        callback: (v?: unknown) => void;
    }[];
    animationClassName?: string;
    closeMethod?: 'close' | 'dismiss';
    position?: 'bottom' | 'middle' | 'top';
}

export type ModalOptions = Partial<
    Pick<
        IModal,
        | 'id'
        | 'autoClose'
        | 'mask'
        | 'title'
        | 'content'
        | 'icon'
        | 'content'
        | 'close'
        | 'buttons'
        | 'className'
        | 'style'
        | 'animationClassName'
        | 'closeMethod'
        | 'position'
    >
>;

export type ModalOptionsInner = ModalOptions & { name: string; id: string };

export interface Handlers {
    close: (id: string) => void;
    dismiss: (id: string) => void;
}
