import React from 'react';

export default class SliderTab extends React.Component {
  constructor(props) {
    super(props);
    this.slideLeft = this.slide.bind(this, 1);
    this.slideRight = this.slide.bind(this, -1);
    this.state = {
      show: this.props.tabTitleLength > this.props.maxItem ? this.props.maxItem : this.props.tabTitleLength,
      left: this.props.leftMargin,
      currentStart: this.props.currentStart,
    };
  }
  componentDidMount() {
    this.width = this.props.tabClassName
      ? document.getElementsByClassName(this.props.tabClassName)[0]?.offsetWidth / this.props.maxItem
      : document.getElementsByClassName(this.props.parentClassName)[0]?.offsetWidth / this.props.maxItem;
    this.props.handleCurrentStart && this.props.handleCurrentStart(this.state.currentStart);
  }

  componentWillReceiveProps(nextProps) {
    const { currentStart } = nextProps;
    if (currentStart === 0 && this.state.left !== 0) {
      this.setState({ currentStart: 0, left: 0 });
    }
  }

  // flag: 1 向左滑动
  // flag: -1 向右滑动
  slide(flag) {
    let count = this.props.tabTitleLength ? this.props.tabTitleLength : 0,
      show = this.state.show,
      start = this.state.currentStart,
      end = start + show,
      n = 0;
    if (flag > 0) {
      if (start <= show) {
        n = end - show;
      } else {
        n = show;
      }
    } else {
      const target = count - end;
      if (target < show) {
        if (target >= 0) {
          n = target;
        }
      } else {
        n = show;
      }
    }
    this.pagetTurn(n, flag);
  }

  // p代表滑动几项
  pagetTurn(p, flag) {
    let width = this.props.tabClassName
        ? document.getElementsByClassName(this.props.tabClassName)[0].offsetWidth / this.props.maxItem
        : document.getElementsByClassName(this.props.parentClassName)[0].offsetWidth / this.props.maxItem,
      distance = this.state.left + width * p * flag - 2 * flag, // 边距2px
      start = flag > 0 ? this.state.currentStart - p : this.state.currentStart + p;
    this.setState({ left: distance, currentStart: start });
    this.props.handleCurrentStart && this.props.handleCurrentStart(start);
  }

  render() {
    // 参数说明:
    // parentClassName: 最外层div的class，要在页面中保证唯一
    // tabClassName: 滑动部分的class
    // preActiveClass: 左箭头的可点击class
    // nextActiveClass: 右箭头的可点击class
    // preDisabledClass: 左箭头的不可点击class(到头)
    // nextDisabledClass: 右箭头的不可点击class(到头)
    // tabTitleLength: 渲染数据的数组长度
    // getTabTitle: 渲染每一条数据
    // maxItem: 每次最多展示几条数据
    // currentStart: 轮播从第几条数据开始
    // preContent: 左箭头内容
    // nextContent: 右箭头内容
    // leftMargin: 初始左侧边距（用于预留按钮宽度）
    // handleCurrentStart: 对外暴露currentStart（可发action）
    const {
      parentClassName,
      preActiveClass,
      nextActiveClass,
      preDisabledClass,
      containerStyle,
      nextDisabledClass,
      tabTitleLength,
      getTabTitle,
      getTabBox,
      preContent,
      nextContent,
      tabClassName,
    } = this.props;

    let ulStyle = {
      position: 'absolute',
      left: this.state.left,
      transitionDuration: '500ms',
      width: '100%',
    };

    ulStyle = Object.assign(ulStyle, containerStyle);

    let preBoxClass = this.state.currentStart == 0 ? preDisabledClass : preActiveClass,
      nextBoxClass = this.state.currentStart + this.state.show >= tabTitleLength ? nextDisabledClass : nextActiveClass;

    return (
      <div className={parentClassName}>
        {getTabBox}
        <div className={tabClassName}>
          <div style={ulStyle}>{getTabTitle}</div>
        </div>
        <div className={preBoxClass} onClick={ preBoxClass === preActiveClass ? this.slideLeft : null} data-testid="pre-page">
          {preContent}
        </div>
        <div className={nextBoxClass} onClick={nextBoxClass === nextActiveClass ? this.slideRight : null} data-testid="next-page">
          {nextContent}
        </div>
      </div>
    );
  }
}
