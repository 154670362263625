import { createSelector } from 'reselect'
import Immutable from 'immutable'
import moment from 'moment'
import { getGlobalSearchCriteria } from '../../selector/search.js'
import { getFirstSegmentHasDirectFlightSelector } from './baseRecommendSelector'

const directRecommendTab = (state) => state.getIn(["list", "recommend", "directRecommendTab"])
const calendarDataSelector = (state) => state.getIn(['list', 'recommend', 'directCalendarData'])
const calendarBaseDateSelector = (state) => state.getIn(['list', 'recommend', 'directCalendarBaseDate'])
const calendarDepDateSelector = (state) => state.getIn(['list', 'recommend', 'directCalendarDeptDate'])
const calendarRetDateSelector = (state) => state.getIn(['list', 'recommend', 'directCalendarRetDate'])

const getDirectRecommendTab = createSelector(directRecommendTab, getGlobalSearchCriteria, getFirstSegmentHasDirectFlightSelector,
    (directRecommendTab, searchCriteria, hasDirect) => {
        let directRecommendList = []
        if (searchCriteria.get("flightWay") === "S") {
            if (hasDirect === false) {
                directRecommendTab.get("departList").map((item) => {
                    let departItem = {
                        departureCityCode: item.get("departureCityCode"),
                        arrivalCityCode: item.get("arrivalCityCode"),
                        departureDate: item.get("departureDate"),
                        isDirect: item.get("direct")
                    }
                    directRecommendList.push(departItem)
                })
            }
        }
        if (searchCriteria.get("flightWay") === "D") {
            if (hasDirect === false) {
                directRecommendTab.get("departList").map((item) => {
                    let departItem = {
                        departureCityCode: item.get("departureCityCode"),
                        arrivalCityCode: item.get("arrivalCityCode"),
                        departureDate: item.get("departureDate"),
                        returnDate: item.get("returnDate"),
                        isDirect: item.get("direct"),
                        returnIsDirect: item.get("returnIsDirect")
                    }
                    directRecommendList.push(departItem)
                })
            } else {
                let returnIsDirect = directRecommendTab.get("returnList").some((item) => {
                    return searchCriteria.getIn(["flightSegments", 1, "departureDate"]) === moment(item.get("departureDate")).format("YYYY-MM-DD") && item.get("direct")
                })
                if (returnIsDirect) {
                    return Immutable.fromJS([])
                }
                let searchStartDate = new Date(searchCriteria.getIn(["flightSegments", 0, "departureDate"]))
                directRecommendTab.get("returnList").map((item) => {
                    let recommendReturnDate = new Date(item.get("departureDate"))
                    if (item.get("direct") === true && recommendReturnDate > searchStartDate) {
                        let departItem = {
                            departureCityCode: item.get("departureCityCode"),
                            arrivalCityCode: item.get("arrivalCityCode"),
                            departureDate: searchCriteria.getIn(["flightSegments", 0, "departureDate"]),
                            returnDate: item.get("departureDate"),
                            isDirect: true,
                            returnIsDirect: true
                        }
                        directRecommendList.push(departItem)
                    }
                })
            }
        }
        return Immutable.fromJS(directRecommendList)
    })

//获取直飞日历恒纵轴
const getCalendarItemsSelector = createSelector([calendarDataSelector, calendarBaseDateSelector, getGlobalSearchCriteria], (calendarData, calendarBaseDate, searchCriteria) => {
    let tripTr = [],
        returnTr = [],
        result = {},
        diffDays = moment(searchCriteria.getIn(["flightSegments", 1, "departureDate"])).diff(moment(searchCriteria.getIn(["flightSegments", 0, "departureDate"])), "days")
    //从基准日期左三右三共七个
    for (let i = -3; i <= 3; i++) {
        let thisDate = moment(calendarBaseDate).add(i, "days"),
            thisDateReturn = moment(calendarBaseDate).add(i + diffDays, "days"),
            tripItem = {},
            returnItem = {},
            thisDepData = calendarData.get("departList") ? calendarData.get("departList").filter((item) => {
                return item.get("departureDate").split(' ')[0] === thisDate.format("YYYY-MM-DD")
            }).first() : null,//直飞接口返回数据中吻合当前日期的
            thisRetData = calendarData.get("returnList") ? calendarData.get("returnList").filter((item) => {
                return item.get("departureDate").split(' ')[0] === thisDateReturn.format("YYYY-MM-DD")
            }).first() : null
        tripItem.date = thisDate.format("YYYY-MM-DD")
        returnItem.date = thisDateReturn.format("YYYY-MM-DD")
        if (thisDepData && thisDepData.size) {
            if (thisDepData.get("direct")) {
                tripItem.isDirect = true
            }
        } else {
            tripItem.noData = true  //无数据为“查看”
        }
        if (thisRetData && thisRetData.size) {
            if (thisRetData.get("direct")) {
                returnItem.isDirect = true
            }
        } else {
            returnItem.noData = true
        }
        tripTr.push(tripItem)
        returnTr.push(returnItem)
    }
    result.tripTr = tripTr
    result.returnTr = returnTr
    return result
})

//日历头部显示的直飞标签是否存在
const getCalendarHeaderDirectSelector = createSelector([calendarDataSelector, calendarBaseDateSelector, calendarDepDateSelector, calendarRetDateSelector],
    (calendarData, calendarBaseDate, calendarDepDate, calendarRetDate) => {
        let DepData = calendarData.get("departList") ? calendarData.get("departList").filter((item) => {
            return item.get("departureDate").split(' ')[0] === calendarDepDate
        }).first() : null,
            RetData = calendarData.get("returnList") ? calendarData.get("returnList").filter((item) => {
                return item.get("departureDate").split(' ')[0] === calendarRetDate
            }).first() : null,
            result = {},
            depDirect = false,
            retDirect = false
        if (DepData && DepData.size && DepData.get("direct")) {
            depDirect = true
        }
        if (RetData && RetData.size && RetData.get("direct")) {
            retDirect = true
        }
        result.depDirect = depDirect
        result.retDirect = retDirect
        return result
    })

export {
    getDirectRecommendTab,
    getCalendarItemsSelector,
    getCalendarHeaderDirectSelector
}