import Immutable from 'immutable';
// import { isBuildUpSelector } from '../../containers/list/result/flight/baseSelector'
import store from '../../stores/list';
// 空铁组合
// import { genSetVirtualFlights } from '../../actions/list/recommend'
import { genUpdateSelectedFlight } from '../../actions/list/flight';
import { initTheFlights } from '../common/initTheFlights';

import { setPriceEnums } from '../common/setPriceEnums';

const setPriceListEnums = (flights) => {
	flights.forEach((flight) => {
		flight.priceList.forEach((price) => {
			setPriceEnums(price);

			if (price.relatedPrices && price.relatedPrices.length) {
				price.relatedPrices.forEach((relatedPrice) => {
					setPriceEnums(relatedPrice);
				});
			}
		});

		flight.containsPlusOne = flight.priceList.some((price) =>
			price.priceTagEnums.some((priceTagEnum) => priceTagEnum.tag.key === 'CheaperPlusAnotherTrip')
		);
	});

	return flights;
};

/**
 * 对航班查询结果进行处理
 * @param {*查询航班结果} flights
 */
export const formatSearchFlights = (flights, prevCond, currentSegmentSeq, isMultiCheapest, isBuildUp) => {
	return new Promise((resolve) => {
		// let virtualFlights = [],
		//     stateInStore = store.getState(),
		// 两舱推荐时候是否需要显示提示语以及区块显示等逻辑，目前只有在 单程、往返自由搭配、多程 的第一程，以及往返组合 时候有效
		// 要排除掉多程超低价的转换
		let ifHighCabinNecessary = (isBuildUp || currentSegmentSeq === 0) && !isMultiCheapest;

		// const dispatchFlights = (flightsAfterInitPrice) => {
		//     //过滤掉空铁
		//     flightsAfterInitPrice = flightsAfterInitPrice.filter(flight => {
		//         if (isBuildUpSelector(stateInStore)) {
		//             if (flight.flightSegments.some((seg, _index) => seg.containsVirtualFlight)) {
		//                 return true
		//             }
		//         }

		//         if (flight.flightSegments.some((seg, index) => index == currentSegmentSeq && seg.containsVirtualFlight)) {
		//             virtualFlights.push(flight)
		//             return true
		//         }
		//         else
		//             return true
		//     })

		//     let formatVirtualFlights = arr => {
		//         arr.forEach(item => {
		//             let segments = item.flightSegments
		//             segments.forEach(seg => {
		//                 let ftTrainCount = 0, ftFlightCount = 0
		//                 seg.flightList.forEach(item => {
		//                     if (item.virtualFlight)
		//                         ftTrainCount++
		//                     else
		//                         ftFlightCount++
		//                 })
		//                 seg.ftTrainCount = ftTrainCount
		//                 seg.ftFlightCount = ftFlightCount
		//                 seg.isAllTrains = ftTrainCount > 0 && ftFlightCount === 0
		//             })
		//         })
		//         return arr
		//     },
		//         formattedVirtualFlights = formatVirtualFlights(virtualFlights)

		//     const prevCondCabinEnum = prevCond.get('cabinEnum')
		//     if (prevCondCabinEnum.value.isHighCabin) {
		//         //如果是搜索高舱，出来非查询条件舱位的虚拟航班，则过滤掉该航班
		//         //比如搜索F，出来主舱等C的虚拟航班，或者搜索C，出来主舱等F的虚拟航班，都要过滤掉
		//         formattedVirtualFlights = formattedVirtualFlights.filter(flight => {
		//             return !flight.containsRecommendCabin   //搜索高舱，不包含推荐舱位的虚拟航班才展示
		//         })
		//     }

		//     !isMultiCheapest && store.dispatch(genSetVirtualFlights(Immutable.fromJS(formattedVirtualFlights), currentSegmentSeq))

		//     resolve(flightsAfterInitPrice)
		// }

		if (flights) {
			let theFlights = initTheFlights(flights, currentSegmentSeq, ifHighCabinNecessary, isBuildUp);
			theFlights = setPriceListEnums(theFlights);
			store.dispatch(genUpdateSelectedFlight(Immutable.fromJS(theFlights), currentSegmentSeq));
			resolve(theFlights);
			// dispatchFlights(theFlights)
		}
	});
};
