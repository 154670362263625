import { FILTER_DEPART_TIME_TYPE } from '@/src/constants/list/enums/filter';
import extractHourFromDateStr from './extractHourFromDateStr';
import { getIfFlightAsLowPrice } from '../getIfFlightAsLowPrice';
/**
 * 获取航班所有出发/到达时间信息
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 */
const getAllDepartAndArrivalTimeOfFlights = (flights, currentSegmentSeq) => {
	let departureTimeEnums = {},
		arrivalTimeEnums = {};

	flights.forEach((flight) => {
		let departureTimeHour = extractHourFromDateStr(
				flight.getIn(['flightSegments', currentSegmentSeq, 'flightList', 0, 'departureDateTime'])
			),
			arrivalTimeHour = extractHourFromDateStr(
				flight.getIn(['flightSegments', currentSegmentSeq, 'flightList']).last().get('arrivalDateTime')
			),
			minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
			minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']),
			flightAsLowPrice = getIfFlightAsLowPrice(flight);

		let departureTimeEnum = FILTER_DEPART_TIME_TYPE.enums.find(
			(theEnum) => departureTimeHour >= theEnum.value.min && departureTimeHour < theEnum.value.max
		);
		if (departureTimeEnum) {
			if (!departureTimeEnums[departureTimeEnum.key]) {
				departureTimeEnums[departureTimeEnum.key] = {
					minAvgPriceWithTax: Infinity,
					minAvgPriceWithoutTax: Infinity,
				};
			}

			let theDepartureTime = departureTimeEnums[departureTimeEnum.key];

			if (flightAsLowPrice) {
				if (minAvgPriceWithTax < theDepartureTime.minAvgPriceWithTax) {
					theDepartureTime.minAvgPriceWithTax = minAvgPriceWithTax;
				}

				if (minAvgPriceWithoutTax < theDepartureTime.minAvgPriceWithoutTax) {
					theDepartureTime.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
				}
			}
		}

		let arrivalTimeEnum = FILTER_DEPART_TIME_TYPE.enums.find(
			(theEnum) => arrivalTimeHour >= theEnum.value.min && arrivalTimeHour < theEnum.value.max
		);
		if (arrivalTimeEnum) {
			if (!arrivalTimeEnums[arrivalTimeEnum.key]) {
				arrivalTimeEnums[arrivalTimeEnum.key] = {
					minAvgPriceWithTax: Infinity,
					minAvgPriceWithoutTax: Infinity,
				};
			}

			let theArrivalTime = arrivalTimeEnums[arrivalTimeEnum.key];

			if (flightAsLowPrice) {
				if (minAvgPriceWithTax < theArrivalTime.minAvgPriceWithTax) {
					theArrivalTime.minAvgPriceWithTax = minAvgPriceWithTax;
				}

				if (minAvgPriceWithoutTax < theArrivalTime.minAvgPriceWithoutTax) {
					theArrivalTime.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
				}
			}
		}
	});

	return {
		departure: departureTimeEnums,
		arrival: arrivalTimeEnums,
	};
};

export default getAllDepartAndArrivalTimeOfFlights;
