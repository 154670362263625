export const CHANGE_ARRIVAL_COUNTRY = 'CHANGE_ARRIVAL_COUNTRY'
export const SWITCH_HOT_SEARCH = 'SWITCH_HOT_SEARCH'

export const SWITCH_FREE_PICKER = 'SWITCH_FREE_PICKER'
export const CHANGE_CURRENT_MONTH = 'CHANGE_CURRENT_MONTH'
export const CHANGE_SELECTED_DAY = 'CHANGE_SELECTED_DAY'
export const CHANGE_FLOAT_DAYS = 'CHANGE_FLOAT_DAYS'
export const CHANGE_STAY_DAYS = 'CHANGE_STAY_DAYS'
export const CHANGE_SELECTED_TIME_ITEM = 'CHANGE_SELECTED_TIME_ITEM'
export const CHANGE_COUNRTY_SOURCE = 'CHANGE_COUNRTY_SOURCE'
export const CHANGE_INPUT_STRING = 'CHANGE_INPUT_STRING'
