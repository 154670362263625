import React from 'react';
import { LIST_UBT_KEY_TYPES } from '../../ubt.v2/list';
import { getIfScopeDomestic } from './scope';

export const getShareText = isTongChengFlight => {
  return isTongChengFlight ? '联运' : '共享';
};

export const getFlightTransAndStopCount = (isTongChengFlight, transferCount, transferAndStopCount, cleanItineraryId) => {
  LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({ transferAndStopCount, cleanItineraryId });
  let isDomestic = getIfScopeDomestic(),
    isTransfer = transferCount > 0,
    contentHighlight = isDomestic && isTransfer;
  if (isDomestic) {
    if (isTransfer && !isTongChengFlight) {
      return (
        <i className={`arrow-transfer ${contentHighlight ? 'hight-light' : ''}`}>
          <span>转{transferCount}次</span>
        </i>
      );
    } else {
      return <i className="arrow-oneway"></i>;
    }
  } else {
    return transferAndStopCount ? (
      <i className={`arrow-transfer`}>
        <span>{transferCount ? (isTongChengFlight ? '华夏联程' : `转${transferAndStopCount}次`) : '经停'}</span>
      </i>
    ) : (
      <i className="arrow-oneway"></i>
    );
  }
};

export const getDomesticFlightStopAndTongchengText = ({ transferCount, transferAndStopCount, tongChengFlightEnum }) => {
  if (getIfScopeDomestic()) {
    if (tongChengFlightEnum) {
      return (
        <React.Fragment>
          <span className="stop-text high-light">{tongChengFlightEnum?.label || '华夏联程'}</span>
        </React.Fragment>
      );
    } else if (transferCount == 0 && transferAndStopCount > 0) {
      return <span className="stop-text">经停</span>;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const isRealLogin = GlobalConfigs.userId && !GlobalConfigs.quickBooking;
