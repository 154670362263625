import {createSelector} from 'reselect';
import {searchFlightsIsFinishedSelector} from '../flight/baseSelector';
import {filterVisibleFlightsSelector} from '../../sidebar/filterbar/filterbarSelector';

export const searchNoResultRecommendSelector = createSelector([filterVisibleFlightsSelector, searchFlightsIsFinishedSelector],
    (flights, isFinished) => {
        // return flights.get('list').size === 0 && isFinished
        return isFinished && !flights.size;
    },
);
