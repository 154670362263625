import React from 'react';
import dynamic from 'next/dynamic';
import { ubtError } from '@/src/sources/common/lightUbt';

export default dynamic(
	() =>
		import(
			/* webpackChunkName: "react.datepicker.plus" */ '@ctrip/flight-online-datepicker/lib/ReactDatepickerPlus'
		),
	{
		ssr: false,
		loading: ({ error }) => {
			if (error) {
				ubtError(error);
			}
			return <div />;
		},
	}
);
