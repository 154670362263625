import moment from 'moment';
import cQueryCookie from '../../ubt/cQueryCookie';


// 存储页面查询条件
export const setPersistSearchLocalStorage = ({
  selectedCity,
  arrivalDistrict = {},
  flightWay,
  owADate,
  owDDate,
  rtDDate,
  rtADate,
  infantCount,
  childCount,
  adultCount,
  classGrade,
}) => {
  try {
    let persistListSearchData = JSON.stringify({
      selectedCity: selectedCity,
      arrivalDistrict: arrivalDistrict,
      flightWay: flightWay,
      currentFlightWay: flightWay, //兼容
      rtEndDate: rtADate, //兼容
      owADate: owADate,
      owDDate: owDDate,
      rtDDate: rtDDate,
      rtADate: rtADate,
      infantCount: infantCount,
      childCount: childCount,
      adultCount: adultCount,
      classGrade: classGrade,
      date: new Date().getTime(),
    });
    localStorage.setItem('persist', persistListSearchData);
  } catch (err) {
    //
  }
};
export const getPersistSearchLocalStorage = () => {
  let persist = {};
  try {
    persist = JSON.parse(localStorage.getItem('persist'));
  } catch (err) {
    console.log('获取存储查询条件失败');
  }
  return persist;
};
const getDomesticFlight = flight => {
  let { acity, acityname, aport, aportname, date, dcity, dcityname, dport, dportname, dcityid, acityid } = flight,
    dCity = {},
    aCity = {};
  if (!acityname || !dcityname || !acity || !dcity) {
    return null;
  }
  dcity = dcity.toUpperCase();
  acity = acity.toUpperCase();
  if (dport) {
    dCity = {
      CityId: dcityid,
      Code: dport,
      Name: dcityname,
      ParentCode: dcity,
      type: 'airport',
      Type: '3',
      TimeZone: 480,
      text: `${dcityname}(${dportname})(${dport})`,
      domestic: true,
    };
  } else {
    dCity = {
      Code: dcity,
      CityId: dcityid,
      Name: dcityname,
      TimeZone: 480,
      text: `${dcityname}(${dcity})`,
      domestic: true,
    };
  }
  if (aport) {
    aCity = {
      CityId: acityid,
      Code: aport,
      Name: acityname,
      ParentCode: acity,
      type: 'airport',
      Type: '3',
      TimeZone: 480,
      text: `${acityname}(${aportname})(${aport})`,
      domestic: true,
    };
  } else {
    aCity = {
      Code: acity,
      CityId: acityid,
      Name: acityname,
      TimeZone: 480,
      text: `${acityname}(${acity})`,
      domestic: true,
    };
  }
  return {
    aCity,
    dCity,
    date,
  };
};
// 获取国内的查询记录
export const getDomesticgetPersistSearchLocalStorage = () => {
  let resultData = null,
    selectedCity = {};

  try {
    let persist = JSON.parse(localStorage.getItem('domesticPersist'));
    if (persist) {
      let { flightWay, hasBaby, hasChild, classType, airportParams, date } = persist;
      let flight = getDomesticFlight(airportParams[0]),
        firstSegDDate = flight && flight.date;
      if (!flight) {
        return null;
      }
      selectedCity = {
        owDCity: flight.dCity,
        rtDCity: flight.dCity,
        rtACity: flight.aCity,
        owACity: flight.aCity,
        mtDCity1: flight.dCity,
        mtACity1: flight.aCity,
        mtStartDate1: firstSegDDate,
      };
      resultData = {
        dPassenger: {
          chiCount: hasChild == 'true',
          infCount: hasBaby == 'true',
        },
        dClassGrade: classType,
        date: date,
      };

      switch (flightWay) {
        case 'oneway':
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            flightWay: 'OW',
          });
          break;
        case 'roundtrip':
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            rtADate: airportParams[1] && airportParams[1].date,
            flightWay: 'RT',
          });
          break;
        case 'multiple':
          let seg2 = getDomesticFlight(airportParams[1]);
          Object.assign(selectedCity, {
            mtDCity2: seg2.dCity,
            mtACity2: seg2.aCity,
            mtStartDate1: firstSegDDate,
            mtStartDate2: seg2.date,
          });
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            flightWay: 'MT',
          });
          break;
        default:
          break;
      }
      resultData.selectedCity = selectedCity;
    }
  } catch (err) {
    console.log('获取存储查询条件失败');
  }
  return resultData;
};
// 存储页面历史查询条件，最多保存10条,只保存单程和往返
export const setSearchHistoryData = ({
  selectedCity,
  flightWay,
  owADate,
  owDDate,
  rtDDate,
  rtADate,
  classGrade,
  infantCount,
  childCount,
  adultCount,
}) => {
  try {
    if (flightWay == 'MT') {
      return;
    }
    let historyData = getSearchHistoryData();
    if (historyData && historyData.length >= 10) {
      // 大于等于十个删除最后一个
      historyData.pop();
    }

    let searchData = {
      selectedCity: selectedCity,
      flightWay: flightWay,
      owADate: owADate,
      owDDate: owDDate,
      rtDDate: rtDDate,
      rtADate: rtADate,
      infantCount: infantCount,
      childCount: childCount,
      adultCount: adultCount,
      classGrade: classGrade,
      isDomestic: false,
      searchAt: new Date().getTime(),
    };
    let currentDCity = selectedCity.owDCity,
      currentACity = selectedCity.owACity,
      currentDCityCode = currentDCity && currentDCity.Code,
      currentACityCode = currentACity && currentACity.Code,
      newHistoryData = [];

    if (currentDCity.type == 'airport' && currentDCity.ParentCode) {
      currentDCityCode = currentDCity.ParentCode;
    }
    if (currentACity.type == 'airport' && currentACity.ParentCode) {
      currentACityCode = currentACity.ParentCode;
    }
    // 过滤掉一致的航程
    if (historyData && historyData.length && currentDCityCode && currentACityCode) {
      historyData.forEach(data => {
        if (!data.isDomestic) {
          let dCity = data.selectedCity && data.selectedCity.owDCity;
          let aCity = data.selectedCity && data.selectedCity.owACity;
          let dCityCode = dCity.Code;
          let aCityCode = aCity.Code;
          if (dCity.type == 'airport' && dCity.ParentCode) {
            dCityCode = dCity.ParentCode;
          }
          if (aCity.type == 'airport' && aCity.ParentCode) {
            aCityCode = aCity.ParentCode;
          }
          if (
            !(
              dCityCode == currentDCityCode &&
              currentACityCode == aCityCode &&
              flightWay == data.flightWay &&
              moment(owDDate).format('YYYY-MM-DD') == moment(data.owDDate).format('YYYY-MM-DD') &&
              (flightWay == 'RT'
                ? moment(rtADate).format('YYYY-MM-DD') == moment(data.rtADate).format('YYYY-MM-DD')
                : true)
            )
          ) {
            newHistoryData.push(data);
          }
        }
      });
    }

    newHistoryData.unshift(searchData);
    localStorage.setItem('searchHistory', JSON.stringify(newHistoryData.filter(item => !item.isDomestic)));
  } catch (err) {
    //
  }
};
// 保存最后一条的查询数据的价格
export const setLatestSearchHistoryData = price => {
  let historyData = getSearchHistoryData();
  if (historyData && historyData.length && historyData[0]) {
    historyData[0].price = price;
    localStorage.setItem('searchHistory', JSON.stringify(historyData.filter(item => !item.isDomestic)));
  }
};

// 对每条搜索航线历史记录进行整改
const formatAirportParamsItem = (firstAirportParams = {}) => {
  let {
      acity,
      acityname,
      aport,
      aportname,
      date,
      dcity,
      dcityname,
      dport,
      dportname,
      dcityid,
      acityid,
    } = firstAirportParams,
    dCity = {},
    aCity = {};
  if (dport) {
    dCity = {
      CityId: parseInt(dcityid, 10),
      Code: dport.toUpperCase(),
      Name: dcityname,
      ParentCode: dcity.toUpperCase(),
      type: 'airport',
      Type: '3',
      TimeZone: 480,
      text: `${dcityname}(${dportname})(${dport.toUpperCase()})`,
    };
  } else {
    dCity = {
      CityId: parseInt(dcityid, 10),
      Code: dcity.toUpperCase(),
      Name: dcityname,
      TimeZone: 480,
      text: `${dcityname}(${dcity.toUpperCase()})`,
    };
  }
  if (aport) {
    aCity = {
      CityId: parseInt(acityid, 10),
      Code: aport.toUpperCase(),
      Name: acityname,
      ParentCode: acity.toUpperCase(),
      type: 'airport',
      Type: '3',
      TimeZone: 480,
      text: `${acityname}(${aportname})(${aport.toUpperCase()})`,
    };
  } else {
    aCity = {
      CityId: parseInt(acityid, 10),
      Code: acity.toUpperCase(),
      Name: acityname,
      TimeZone: 480,
      text: `${acityname}(${acity.toUpperCase()})`,
    };
  }
  return {
    aCity,
    dCity,
    date,
  };
};

// 对每条搜索历史记录进行整改
const formatDomesticSearchItem = flightItem => {
  let resultData = null,
    selectedCity = {};

  try {
    if (flightItem) {
      let { flightWay, hasBaby, hasChild, classType, airportParams, searchAt } = flightItem;
      let flight = formatAirportParamsItem(airportParams[0]),
        firstSegDDate = flight.date;

      selectedCity = {
        owDCity: flight.dCity,
        rtDCity: flight.dCity,
        rtACity: flight.aCity,
        owACity: flight.aCity,
        mtDCity1: flight.dCity,
        mtACity1: flight.aCity,
        mtStartDate1: firstSegDDate,
      };
      resultData = {
        infantCount: hasBaby ? 1 : 0,
        childCount: hasChild ? 1 : 0,
        adultCount: 1,
        classGrade: classType === 'CF' ? 'C_F' : 'Y_S',
        isDomestic: true,
        searchAt: searchAt || 0,
      };

      switch (flightWay.toLowerCase()) {
        case 'oneway':
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            flightWay: 'OW',
          });
          break;
        case 'roundtrip':
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            rtADate: airportParams[1] && airportParams[1].date,
            flightWay: 'RT',
          });
          break;
        case 'multiple':
          let seg2 = formatAirportParamsItem(airportParams[1]);
          Object.assign(selectedCity, {
            mtDCity2: seg2.dCity,
            mtACity2: seg2.aCity,
            mtStartDate1: firstSegDDate,
            mtStartDate2: seg2.date,
          });
          Object.assign(resultData, {
            owDDate: firstSegDDate,
            rtDDate: firstSegDDate,
            flightWay: 'MT',
          });
          break;
        default:
          break;
      }
      resultData.selectedCity = selectedCity;
    }
  } catch (err) {
    console.log('获取存储查询条件失败');
  }
  return resultData;
};

// 重新将国内的搜索历史记录数据整理成符合国际形式的数据
const formatDomesticSearchData = (domesticSearchData = []) => {
  let newDomesticSearchData = [];
  domesticSearchData.forEach(searchData => {
    if (searchData.airportParams && searchData.airportParams.length >= 1) {
      if (searchData.airportParams[0].dcityname !== '' && searchData.airportParams[0].acityname !== '') {
        const newSearchData = formatDomesticSearchItem(searchData);

        newDomesticSearchData.push(newSearchData);
      }
    }
  });

  return newDomesticSearchData;
};

// 根据某一个属性来进行排序，此处限定为时间由大到小排序
const compareForSort = property => {
  return (obj1, obj2) => {
    const value1 = obj1[property] ? new Date(obj1[property]).getTime() : 0;
    const value2 = obj2[property] ? new Date(obj2[property]).getTime() : 0;
    return value2 - value1;
  };
};

export const getSearchHistoryData = () => {
  let persist = [];
  try {
    persist = JSON.parse(localStorage.getItem('searchHistory')) || [];
    const domesticSearchHistory = formatDomesticSearchData(
      JSON.parse(localStorage.getItem('domesticSearchHistory')) || [],
    );
    if (domesticSearchHistory.length > 0) {
      persist.push.apply(persist, domesticSearchHistory);
      let persistItem = {};
      persist = persist.reduce((item, next) => {
        persistItem[next.owDDate] &&
        persistItem[next.selectedCity.owDCity.Name] &&
        persistItem[next.selectedCity.owACity.Name]
          ? ''
          : (persistItem[next.owDDate] = true) &&
            (persistItem[next.selectedCity.owDCity.Name] = true) &&
            (persistItem[next.selectedCity.owACity.Name] = true) &&
            item.push(next);
        return item;
      }, []);
    }
  } catch (err) {
    console.log('获取存储查询条件失败');
  }

  return (persist.length && persist.sort(compareForSort('searchAt'))) || [];
};

export const clearSearchHistoryData = () => {
  localStorage.removeItem('searchHistory');
  localStorage.removeItem('domesticSearchHistory');
};

// 获取查询cookie
const getCityCode = text => {
  if (text) {
    var start = text.lastIndexOf('(');
    var end = text.lastIndexOf(')');
    if (start != -1 && end != -1) {
      return text.substring(start + 1, end);
    }
  }
  return null;
};
const getCityId = text => {
  if (text) {
    let dataArr = text.split('|');
    if (dataArr.length && dataArr.length > 3 && /\d+/.test(dataArr[2])) {
      return dataArr[2];
    }
  }
  return null;
};
export const getSearchDataFromSearchCookie = () => {
  try {
    let searchData = cQueryCookie.get('FlightIntl', 'Search');
    if (searchData) {
      searchData = eval(searchData);
      if (searchData.length) {
        let from = searchData[0],
          to = searchData[1];
        return {
          dCity: {
            Code: getCityCode(from),
            CityId: getCityId(from),
          },
          aCity: {
            Code: getCityCode(to),
            CityId: getCityId(to),
          },
        };
      }
    }
  } catch (err) {
    //
  }

  return null;
};
// 保存下打开的列表页的url的查询日期是否过期
export const setSearchDateIsPassed = () => {
  localStorage.setItem('searchDateIsPassed', true);
};

export const getSearchDateIsPassed = () => {
  let isPassed = false;
  isPassed = localStorage.getItem('searchDateIsPassed');
  localStorage.removeItem('searchDateIsPassed');
  return isPassed;
};

// 保存列表页搜索相同航班时弹框24小时内不重复拦截
export const setWithExpiry = (key, val, ttl) => {
  const now = new Date(),
    item = {
      val: val,
      expiry: now.getTime() + ttl,
    };

  let searchSegments = [];
  try {
    searchSegments = JSON.parse(localStorage.getItem('searchSegments')) || [];
  } catch (err) {
    console.log(err);
  }

  const index = searchSegments.findIndex(segment => segment.key === key);

  if (index > -1) {
    searchSegments.splice(index, 1, { key, item });
  } else {
    searchSegments.push({ key, item });
  }

  localStorage.setItem('searchSegments', JSON.stringify(searchSegments));
};

export const getWithExpiry = key => {
  const searchSegmentsStr = localStorage.getItem('searchSegments');

  if (!searchSegmentsStr) {
    return {};
  } else {
    let searchSegments = [];
    try {
      searchSegments = JSON.parse(searchSegmentsStr) || [];
    } catch (err) {
      console.log(err);
    }
    const now = new Date(),
      targetItem = searchSegments.find(segment => segment.key === key),
      index = searchSegments.findIndex(segment => segment.key === key);

    if (index > -1) {
      if (now.getTime() > targetItem.item.expiry) {
        searchSegments.splice(index, 1);
        localStorage.setItem('searchSegments', JSON.stringify(searchSegments));
        return {};
      } else {
        return targetItem.item.val;
      }
    } else {
      return {};
    }
  }
};
