import Immutable, { List } from 'immutable';
import Cursor from 'immutable-cursor';
import shortid from 'shortid';
import moment from 'moment';
import qs from 'qs';
import {
	fetchGet,
	fetchPost,
	fetchPostWithHeaders,
	getPrevCondFromGlobal,
	getTransactionIdFromGlobal,
} from '../common';
import CONFIG_URL from '../../constants/list/api';
import Converter from '../../sources/list/converter';
import * as ACTION_TYPES from '../../constants/list/actionTypes';
import * as action from './index';
import { genSetStayTimeoutFlag } from './index';

import { FLIGHT_WAY, groupDomesticRecommend } from '../../constants/list/enums/common';
import { CABIN } from '../../constants/common/enum/common';

import CONSTS from '../../constants/list/constConfig';

import { LIST_UBT_GROUP_TYPES } from '../../ubt.v2/list/groupTypes';
import { vid } from '../../utils';
import { getIfFlightAsLowPrice } from '../../sources/list/getIfFlightAsLowPrice';
import { UbtHandler } from '../../ubt.v2/handler';
import { PRE_SEARCH_COST_TIME, MARK_CRAWLER } from '../../sources/common/localStorageKey';
import { tryExec } from '../../sources/common/tryExec';
import { genAntiCrawlerHeader } from '../../sources/list/genAntiCrawlerHeader';
import { lightSearchFlights } from './lightSearchFlights';
import { formatSearchFlights } from '../../sources/list/formatSearchFlights';
import { QueryStringKeys } from '../../sources/list/queryStringKeys';

import { logOuterLowPrice } from '../../sources/list/logOuterLowPrice';
import { getIfScopeInternational } from '../../sources/list/scope';
import { genSetGiftIdList } from './aside';
import { genSetAllRecommendFlights } from './recommend';

import { getLowPriceReferFromSessionBySegment } from '../../utils/seoLink';
import { sendUBT, lightUploadSpeed } from '../../sources/common/lightUbt';
import { UBT_KEY } from '../../constants/ubtKeys';
// import { genBeforeRouteSearch } from './index';

export const genSetChannelId = (channelId, subChannelId, isRouteSearch) => ({
	type: ACTION_TYPES.SET_CHANNEL_ID,
	channelId,
	subChannelId,
	isRouteSearch,
});
export const genActivateTabGroup = (id, tabType, stateId) => ({
	type: ACTION_TYPES.SEARCH_ACTIVATE_TABGROUP,
	id,
	tabType,
	stateId,
});
export const genSetBestChoiceFlightsForceTop = (forceTop) => ({
	type: ACTION_TYPES.SET_BEST_CHOICE_FLIGHTS_FORCE_TOP,
	forceTop,
});

export const genSetSearchCriteriaToken = (searchCriteriaToken) => ({
	type: ACTION_TYPES.SET_SEARCH_CRITERIA_TOKEN,
	searchCriteriaToken,
});
export const genIncrementPassenger = (passengerType, max, id) => ({
	type: ACTION_TYPES.INCREMENT_PASSENGER,
	passengerType,
	max,
	id,
});
export const genDecrementPassenger = (passengerType, min, id) => ({
	type: ACTION_TYPES.DECREMENT_PASSENGER,
	passengerType,
	min,
	id,
});

export const genChangeSearchHistoryListVisible = (isShow) => ({
	type: ACTION_TYPES.CHANGE_SEARCHHISTORY_LIST_VISIBLE,
	isShow,
});
export const genOnSearchDateChange = (field, date, focusNextInput, id) => ({
	type: ACTION_TYPES.ON_SEARCH_DATE_CHANGE,
	field,
	date,
	focusNextInput,
	id,
});
export const genExchangeMultiCity = (index, id) => ({ type: ACTION_TYPES.EXCHANGE_MULTI_CITY, index, id });

export const genSearchHistory = (history) => ({ type: ACTION_TYPES.SEARCH_HISTORY, history });
export const genSetGlobalSearchCriteria = (globalSearchCriteria) => ({
	type: ACTION_TYPES.SET_GLOBAL_SEARCH_CRITERIA,
	globalSearchCriteria,
});
export const genClearFocusNextInput = () => ({ type: ACTION_TYPES.CLEAR_FOCUS_NEXT_INPUT });
export const genModifySearchCity = (cityInfo) => ({ type: ACTION_TYPES.MODIFY_SEARCH_CITY, cityInfo });
export const genSearchFlightsSuccess = (
	flights,
	isFinished,
	currentSegmentSeq,
	giftIds,
	isRouteSearch,
	existDirectReturnTrip,
	showAuthCode
) => ({
	type: ACTION_TYPES.SEARCH_FLIGHTS,
	flights,
	isFinished,
	currentSegmentSeq,
	giftIds,
	isRouteSearch,
	existDirectReturnTrip,
	showAuthCode,
});
export const genSearchIntlFlightsPrice = (payload) => ({
	type: ACTION_TYPES.SEARCH_INTL_PRICE_LIST,
	payload,
});
export const genSetLastSearchTime = () => ({
	type: ACTION_TYPES.SEARCH_SET_LAST_SEARCH_TIME,
	time: moment().format('YYYY-MM-DD HH:mm:ss'),
});
export const genSetComfortTags = (comfortTags) => ({ type: ACTION_TYPES.SET_COMFORT_TAGS, comfortTags });
export const genSetIsResearching = (isResearching) => ({ type: ACTION_TYPES.SET_IS_RESEARCHING, isResearching });
export const genShowWarn = () => ({ type: ACTION_TYPES.SHOW_WARN });
export const genSetGuideLine = (priceChangeHistory, pricePrediction, travelTips) => ({
	type: ACTION_TYPES.SET_GUIDE_LINE,
	priceChangeHistory,
	pricePrediction,
	travelTips,
});
export const genSetFilterClassGrade = (classGradeEnum) => ({
	type: ACTION_TYPES.SET_FILTER_CLASS_GRADE,
	classGradeEnum,
});

export const genCancelFocusMtSecondDCity = () => ({ type: ACTION_TYPES.CANCEL_FOCUS_MT_SECOND_DCITY }); //多程第二程出发城市为空时点击搜索弹出城市选择器
export const genChangeClassGrade = (classGrade, domestic) => ({
	type: ACTION_TYPES.CHANGE_CLASS_GRADE,
	classGrade,
	domestic,
});

export const genChangePassengerQuantity = (passenger, domestic) => ({
	type: ACTION_TYPES.SET_PASSENGER_COUNT,
	passenger,
	domestic,
});

/**
 * 根据查询条件等初始化store中部分字段
 * @param {*查询条件} prevCond
 * @param {*查询参数信息，包括(isBuildUp, airline, directFlight)} queryParam
 */
export const genInitPrevSearchCondSuccess = (prevCond, queryParam, opts) => {
	prevCond.flightWayEnum = FLIGHT_WAY.get(
		Object.keys(FLIGHT_WAY.toJSON()).find((key) => FLIGHT_WAY.get(key).value.key === prevCond.flightWay)
	);
	prevCond.cabinEnum = CABIN.get(prevCond.cabin);
	prevCond.isMultiplePassengerType = prevCond.adultCount && (prevCond.childCount || prevCond.infantCount);
	prevCond.departCountryName = prevCond.flightSegments[0].departureCountryName;
	prevCond.departProvinceId = prevCond.flightSegments[0].departureProvinceId;
	prevCond.departureCityId = prevCond.flightSegments[0].departureCityId;
	prevCond.arrivalCountryName = prevCond.flightSegments[prevCond.flightSegments.length - 1].arrivalCountryName;
	prevCond.arrivalProvinceId = prevCond.flightSegments[prevCond.flightSegments.length - 1].arrivalProvinceId;
	prevCond.arrivalCityId = prevCond.flightSegments[prevCond.flightSegments.length - 1].arrivalCityId;
	return { type: ACTION_TYPES.SEARCH_INIT_PREV_COND, prevCond, queryParam, ...opts };
};

/**
 * 初始化从服务端传回的开关信息，比如ABT
 * @param {*全局开关信息} globalSwitch
 */
export const genInitGlobalSwitch = (globalSwitch, prevCond) => {
	return { type: ACTION_TYPES.SEARCH_INIT_GLOBAL_SWITCH, globalSwitch, prevCond };
};

/**
 * 初始化从服务端传回的配置信息
 * @param {*全局配置信息} globalConfig
 */
export const genInitGlobalConfig = (globalConfig, prevCond) => {
	return { type: ACTION_TYPES.SEARCH_INIT_GLOBAL_CONFIG, globalConfig, prevCond };
};

/**
 *  batchSearch 和 pull 查询标记
 */
let CURRENT_SEARCH_PULL_KEY = '';

/**
 *  反查查询标记
 */
let CURRENT_ROUTE_SEARCH_KEY = '';

//上一次拿到的航班最低价
let LAST_SEARCH_MIN_PRICE_WITH_TAX = 0;

/**
 * 停止轮询航班
 */
export const resetSearchPullKey = () => (CURRENT_SEARCH_PULL_KEY = shortid.generate());

/**
 * 停止反查查询标记
 */
export const resetRouteSearchKey = () => (CURRENT_ROUTE_SEARCH_KEY = shortid.generate());

/**
 * 点击搜索按钮后的航班查询接口调用
 * @param {*查询条件} prevCond
 * @param {*当前航程顺序号，从0开始} currentSegmentSeq
 * @param {*是否往返组合} isBuildUpMode
 * @param {*是否反查} isRouteSearch
 */
export const fetchSearchFlights = (prevCond, currentSegmentSeq, isBuildUpMode, isRouteSearch) => (dispatch) => {
	const currentSearchKey = resetSearchPullKey();
	prevCond = prevCond.set('segmentNo', isBuildUpMode ? 0 : currentSegmentSeq + 1);
	const searchFlightsPromise = lightSearchFlights(prevCond);

	setupStayTimeoutCountDown(dispatch);

	dispatch(genSetLastSearchTime());
	searchFlightsPromise
		.then((res) => {
			const json = res?.data ? res.data : {};
			if (!json || !json.data) json.data = {};
			const nextFn = () => {
				window.sessionStorage.removeItem(MARK_CRAWLER); // 搜索之前，先把爬虫标记去掉
				LAST_SEARCH_MIN_PRICE_WITH_TAX = 0;
				let { channelId, subChannelId } = json.data.context || {};
				onSetChannelId(dispatch, channelId, subChannelId, isRouteSearch);
				onFetchFlights({
					dispatch,
					prevCond,
					currentSegmentSeq,
					json,
					pullDelay: CONSTS.SEARCH.FIRST_PULL_DELAY,
					searchKey: currentSearchKey,
					isBuildUpMode,
					isRouteSearch,
					isIntlSearch: false,
				});
			};
			nextFn();
		});
	sendUBT(UBT_KEY.LIST.SEARCH_SUCCESS, { isInternational: getIfScopeInternational() }, true);
};

const onSetChannelId = (dispatch, channelId, subChannelId, isRouteSearch) => {
	if (channelId && subChannelId) {
		dispatch(action.genSetChannelId(channelId, subChannelId, isRouteSearch));
	}
};

/**
 * 当航班结果拿到时候触发，分普通查询的触发和轮询结果拿到后触发
 * @param {*redux的dispatch触发} dispatch
 * @param {*查询条件} prevCond
 * @param {*当前航程顺序号，从0开始} currentSegmentSeq
 * @param {*航班查询结果} json
 * @param {*距离下一次的pull间隔时间} pullDelay
 */
export const onFetchFlights = ({
	dispatch,
	prevCond,
	currentSegmentSeq,
	json,
	pullDelay,
	searchKey,
	isBuildUpMode,
	isRouteSearch,
	isIntlSearch = false,
	showSecurityPopup = false,
}) => {
	if (!json.data) {
		json.data = {
			couponAdditionList: [],
			flightItineraryList: [],
		};
	}

	if (!json.data.context) {
		json.data.context = {
			finished: true,
			searchId: '',
		};
	}
	dispatch({ type: ACTION_TYPES.FAKE_PRICE_MARK, payload: json.data.lgn });
	const setStorageOfCrawler = () => window.sessionStorage.setItem(MARK_CRAWLER, '1');
	const uploadUbtOfCrawler = () => UbtHandler.onUploadData('c_mark_is_bomb', json.data.context.flag);

	if ((json.data.context.flag & 1) == 1) {
		// 假数据
		setStorageOfCrawler();
		uploadUbtOfCrawler();

		LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.Is_Crawler = 1;
		LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.CrawlerType = 1;
	} else if ((json.data.context.flag & 2) == 2) {
		// 无结果
		setStorageOfCrawler();
		uploadUbtOfCrawler();

		LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.Is_Crawler = 1;
		LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.CrawlerType = 0;
	}
	const context = json.data.context;
	const giftIds = json.data.giftIds || '';
	const itineraryList = json.data.flightItineraryList || [];
	const hasAnyFlights = itineraryList.length;
	const { finished: isFinished, existDirectReturnTrip, searchId, searchCriteriaToken, showAuthCode } = context;
	const resultIsValid = searchKey === CURRENT_SEARCH_PULL_KEY || searchKey === CURRENT_ROUTE_SEARCH_KEY; // 判断搜索航班或者反查，任意KEY相同都视为有效
	const directFlightNoList = [];
	const batchComfortTagListParams = [];

	let preSearchCostTimeStr = window.sessionStorage.getItem(PRE_SEARCH_COST_TIME);
	const uploadUbtOfSearchResult = () =>
		UbtHandler.onUploadData('c_search_flights_reuslt', { size: itineraryList.length });

	if (!isFinished && searchId && resultIsValid) {
		setTimeout(
			() => fetchPullFlights(dispatch, prevCond, currentSegmentSeq, searchId, searchKey, isBuildUpMode),
			pullDelay
		);

		// 拿到第一个批次航班，记录预搜索埋点
		if (preSearchCostTimeStr) {
			try {
				let preSearchCostTimeJson = JSON.parse(preSearchCostTimeStr);
				if (
					preSearchCostTimeJson &&
					preSearchCostTimeJson.s0 &&
					preSearchCostTimeJson.s1 &&
					!preSearchCostTimeJson.s2
				) {
					preSearchCostTimeJson.s2 = +new Date();
					window.sessionStorage.setItem(PRE_SEARCH_COST_TIME, JSON.stringify(preSearchCostTimeJson));
				}
			} catch (error) {
				console.error(error);
			}
		}
	}

	if (resultIsValid && (hasAnyFlights || isFinished)) {
		// 拿到第二个批次航班，记录预搜索埋点
		uploadUbtOfSearchResult();
		if (isFinished && preSearchCostTimeStr) {
			try {
				let preSearchCostTimeJson = JSON.parse(preSearchCostTimeStr);
				if (
					preSearchCostTimeJson &&
					preSearchCostTimeJson.s0 &&
					preSearchCostTimeJson.s1 &&
					!preSearchCostTimeJson.s3
				) {
					if (!preSearchCostTimeJson.s2) {
						// 这种情况是直接拿到第二批
						preSearchCostTimeJson.s2 = preSearchCostTimeJson.s1;
					}

					preSearchCostTimeJson.s3 = hasAnyFlights ? +new Date() : preSearchCostTimeJson.s2;
					sessionStorage.setItem(PRE_SEARCH_COST_TIME, JSON.stringify(preSearchCostTimeJson));
				}
			} catch (error) {
				console.error(error);
			}
		}

		//航班数据处理
		const flightSegments = prevCond.get('flightSegments'),
			firstDepartDate = flightSegments.getIn([0, 'departureDate']),
			prevCondFlightWay = prevCond.get('flightWay');

		// 精选航班置顶逻辑先屏蔽掉，因为AB都没开过，且精选优先的排序也下线了
		// dispatch(genSetBestChoiceFlightsForceTop(bestChoiceFlightsForceTop))

		if (searchCriteriaToken) {
			dispatch(genSetSearchCriteriaToken(searchCriteriaToken));
		}

		formatSearchFlights(itineraryList, prevCond, currentSegmentSeq, false, isBuildUpMode).then((rawFlights) => {
			let allFlightsList = Immutable.List();
			let fetchFlightsIndex = 0;
			let BATCH_SIZE = 5;

			let getNextFlightsBatch = () => {
				const rawIndex = fetchFlightsIndex;

				if (rawIndex < rawFlights.length) {
					fetchFlightsIndex += BATCH_SIZE;
					return rawFlights.slice(rawIndex, rawIndex + BATCH_SIZE);
				} else {
					return null;
				}
			};

			let nextFlights = getNextFlightsBatch();
			let hasCalledCommitBatch = false;
			let processNextBatchFlights = (theNextFlights) => {
				window.requestAnimationFrame(() => {
					if (theNextFlights) {
						// 分批次进行js转换
						const flights = Immutable.fromJS(theNextFlights);
						allFlightsList = allFlightsList.concat(flights);
					} else {
						window.requestAnimationFrame(() => {
							//低价日历求当前列表页最低价
							let flightsOfNotRecommendLowerCabin = rawFlights.filter((flight) =>
								getIfFlightAsLowPrice(flight)
							);

							const allowedPriceDelta = 2; // 国际因为汇率问题允许价格相差2块钱
							const clearLowPriceReffer = isFinished;
							const {
								[QueryStringKeys.OuterLowPriceContainsTax]: outerLowPriceContainsTax = '1',
								[QueryStringKeys.OuterLowPriceValue]: outerLowPriceValue = '',
								[QueryStringKeys.OuterLowPriceSource]: outerLowPriceSource = '',
							} = getLowPriceReferFromSessionBySegment(
								{
									departureDate: firstDepartDate,
									departCityCode: flightSegments.getIn([0, 'departureCityCode']),
									arriveCityCode: flightSegments.getIn([0, 'arrivalCityCode']),
									segmentFlightWay: prevCondFlightWay,
									returnDate: flightSegments.getIn([1, 'departureDate']),
								},
								clearLowPriceReffer
							) || qs.parse(window.location.search.substr(1));

							const wrapLogOuterPrice = (key, currentPrice) =>
								logOuterLowPrice({
									key,
									outerPrice: outerLowPriceValue,
									outerContainsTax: outerLowPriceContainsTax,
									currentPrice,
									source: outerLowPriceSource,
								});

							if (flightsOfNotRecommendLowerCabin.length) {
								let {
									minPriceWithTax,
									minPriceWithoutTax,
									channelOfMinPriceWithTax,
									channelOfMinPriceWithoutTax,
								} = Converter.getMinAvgPriceOfFlights(flightsOfNotRecommendLowerCabin);

								if (prevCondFlightWay === 'S') {
									dispatch(
										action.genTodayLowPrice(
											minPriceWithoutTax,
											minPriceWithTax,
											firstDepartDate,
											'S',
											0,
											currentSegmentSeq,
											isRouteSearch
										)
									);
								} else if (prevCondFlightWay === 'M') {
									dispatch(
										action.genTodayLowPrice(
											minPriceWithoutTax,
											minPriceWithTax,
											firstDepartDate,
											'M',
											0,
											currentSegmentSeq,
											isRouteSearch
										)
									);
								} else if (prevCondFlightWay === 'D') {
									let lastDepartDate = flightSegments.getIn([
											flightSegments.size - 1,
											'departureDate',
										]),
										diffDays = Math.ceil(
											moment(lastDepartDate).diff(moment(firstDepartDate), 'days')
										);
									dispatch(
										action.genTodayLowPrice(
											minPriceWithoutTax,
											minPriceWithTax,
											firstDepartDate,
											'D',
											diffDays,
											currentSegmentSeq,
											isRouteSearch
										)
									);
									if (channelOfMinPriceWithoutTax || channelOfMinPriceWithTax) {
										dispatch(
											action.genTodayLowPriceChannel(
												channelOfMinPriceWithoutTax,
												channelOfMinPriceWithTax
											)
										);
									}
								}

								// 外部低价链接跳转到列表页，传参表示外部显示的含税价和外部渠道
								if (isFinished && outerLowPriceValue && outerLowPriceSource) {
									const toComparePrice =
										outerLowPriceContainsTax === '1' ? minPriceWithTax : minPriceWithoutTax;
									if (toComparePrice + allowedPriceDelta < outerLowPriceValue) {
										// 实际搜索到的最低价比传参的低价还高2块钱以上
										wrapLogOuterPrice('cur_low_price_higher_than_lowprice', toComparePrice);
									} else if (toComparePrice - allowedPriceDelta > outerLowPriceValue) {
										// 实际搜索到的最低价比传参的低价还低2块钱以上
										wrapLogOuterPrice('cur_low_price_lower_than_lowprice', toComparePrice);
									} else {
										// 实际搜索到的最低价在正常范围内
										wrapLogOuterPrice('cur_low_price_in_normal_range', toComparePrice);
									}
								}

								LAST_SEARCH_MIN_PRICE_WITH_TAX = minPriceWithTax;
							} else {
								if (outerLowPriceValue && outerLowPriceSource) {
									// 外部低价链接跳转到列表页，搜索无结果的埋点
									wrapLogOuterPrice('no_result_when_jump_from_lowprice', 0);
								}
							}

							//航班接口查询完成后，将最低价传给购票攻略接口，返回：价格预测、变价曲线、旅行贴士 数据
							if (
								LAST_SEARCH_MIN_PRICE_WITH_TAX &&
								isFinished &&
								prevCondFlightWay !== 'M' &&
								(currentSegmentSeq === 0 || isBuildUpMode)
							) {
								//第一程就返回
								getGuidelineList(dispatch, LAST_SEARCH_MIN_PRICE_WITH_TAX);
							}

							let currentSegmentACityCode = prevCond.getIn([
									'flightSegments',
									currentSegmentSeq,
									'arrivalCityCode',
								]),
								currentSegmentDCityCode = prevCond.getIn([
									'flightSegments',
									currentSegmentSeq,
									'departureCityCode',
								]),
								currentSegmentDDate = Converter.extractDate(
									prevCond.getIn(['flightSegments', currentSegmentSeq, 'departureDate'])
								);

							// 等舒适度外露批量请求接口做好再接
							if (!isIntlSearch && itineraryList.length > 0) {
								allFlightsList.map((flight) => {
									let getTheSegment = (theSegmentSeq) =>
											flight.getIn(['flightSegments', theSegmentSeq])?.withMutations((seg) => {
												let flightListCursor = Cursor.from(
														seg,
														'flightList',
														(newSeg) => (seg = newSeg)
													),
													getFlightListOf = (prop) =>
														flightListCursor
															.deref()
															.map((f) => f.get(prop))
															.reduce((prev, next) => prev.concat(next), List([]))
															.toSet()
															.toList(),
													airlineList = getFlightListOf('marketAirlineCode');

												return seg.set('airlineCount', airlineList.size);
											}),
										getParams = (index) => {
											let theSegment = getTheSegment(index),
												airlineCount = theSegment.get('airlineCount');
											if (theSegment.get('stopCount') + theSegment.get('transferCount') == 0) {
												let flightNo = theSegment.getIn(['flightList', 0, 'operateFlightNo'])
													? theSegment.getIn(['flightList', 0, 'operateFlightNo'])
													: theSegment.getIn(['flightList', 0, 'flightNo']);
												if (directFlightNoList.indexOf(flightNo) < 0) {
													directFlightNoList.push(flightNo);
												}
											} else if (airlineCount == 1) {
												theSegment.get('flightList').map((list) => {
													let flightNo = list.get('operateFlightNo')
															? list.get('operateFlightNo')
															: list.get('flightNo'),
														// arrivalCityCode = list.get('stopList') && list.get('stopList').size > 0 ? list.getIn(['stopList', 0, 'cityCode']) : list.get('arrivalCityCode'),
														arrivalCityCode = list.get('arrivalCityCode'),
														departureCityCode = list.get('departureCityCode'),
														departureDateTime = Converter.extractDate(
															list.get('departureDateTime')
														);
													batchComfortTagListParams.push({
														arrivalCityCode: arrivalCityCode,
														departureCityCode: departureCityCode,
														departureDate: departureDateTime,
														flightNoList: [`${flightNo}`],
													});
												});
											}
										};

									if (isBuildUpMode) {
										Array(currentSegmentSeq + 1)
											.fill(0)
											.forEach((val, index) => {
												getParams(index);
											});
									} else {
										getParams(currentSegmentSeq);
									}
								});
								let directFlightParams = {
									arrivalCityCode: currentSegmentACityCode,
									departureCityCode: currentSegmentDCityCode,
									departureDate: currentSegmentDDate,
									flightNoList: directFlightNoList,
								};
								batchComfortTagListParams.push(directFlightParams);
								getComfortTagList(dispatch, batchComfortTagListParams);

								if (flightsOfNotRecommendLowerCabin.length) {
									let {
										minPriceWithTax,
										minPriceWithoutTax,
										channelOfMinPriceWithTax,
										channelOfMinPriceWithoutTax,
									} = Converter.getMinAvgPriceOfFlights(flightsOfNotRecommendLowerCabin);

									if (prevCondFlightWay === 'S') {
										dispatch(
											action.genTodayLowPrice(
												minPriceWithoutTax,
												minPriceWithTax,
												firstDepartDate,
												'S',
												0,
												currentSegmentSeq,
												isRouteSearch
											)
										);
									} else if (prevCondFlightWay === 'M') {
										dispatch(
											action.genTodayLowPrice(
												minPriceWithoutTax,
												minPriceWithTax,
												firstDepartDate,
												'M',
												0,
												currentSegmentSeq,
												isRouteSearch
											)
										);
									} else if (prevCondFlightWay === 'D') {
										let lastDepartDate = flightSegments.getIn([
												flightSegments.size - 1,
												'departureDate',
											]),
											diffDays = Math.ceil(
												moment(lastDepartDate).diff(moment(firstDepartDate), 'days')
											);

										dispatch(
											action.genTodayLowPrice(
												minPriceWithoutTax,
												minPriceWithTax,
												firstDepartDate,
												'D',
												diffDays,
												currentSegmentSeq,
												isRouteSearch
											)
										);
										if (channelOfMinPriceWithoutTax || channelOfMinPriceWithTax) {
											dispatch(
												action.genTodayLowPriceChannel(
													channelOfMinPriceWithoutTax,
													channelOfMinPriceWithTax
												)
											);
										}
									}
								}
							}

							// 获取多程超低价数据
							if (prevCondFlightWay === 'M' && json.data.cheapestFlightItinerary) {
								let cheapestFlightItinerary = [json.data.cheapestFlightItinerary];

								formatSearchFlights(
									cheapestFlightItinerary,
									prevCond,
									currentSegmentSeq,
									true,
									isBuildUpMode
								).then((multiCheapest) => {
									const multiCheapestOfImmutable = Immutable.fromJS(multiCheapest);
									if (multiCheapestOfImmutable.size !== 0) {
										dispatch(action.genMultiCheapest(multiCheapestOfImmutable));
									}
								});
							}

							try {
								if (!isIntlSearch) {
									if (allFlightsList?.size) {
										const productTypeList = allFlightsList.map((flight) => {
											if (flight?.size && flight.get('priceList')) {
												const priceList = flight.get('priceList');
												const minAvgPriceWithTax = priceList
													.map((t) => t.get('avgPriceWithTax'))
													.min();
												const theMinPrice = priceList.find(
													(t) => t.get('avgPriceWithTax') === minAvgPriceWithTax
												); // 最低运价
												const priceTags = theMinPrice.get('priceTags');
												return {
													key: flight.get('itineraryId'),
													value: getIfScopeInternational()
														? theMinPrice.get('productType')
														: priceTags?.size && priceTags.map((t) => {
															const label = t.get('label');
															const type = t.get('type');
															const weight = t.get('weight');
															return {
																label,
																type,
																weight,
															}
														}),
												};
											}
											return {
												key: '',
												value: '',
											};
										});
										UbtHandler.onUploadData('o_flight_list_product_type', productTypeList);
									}
								}
							} catch (error) {}

							if (!isIntlSearch && (showAuthCode || showSecurityPopup)) {
								lightUploadSpeed({
									speed: showAuthCode ? 'response_is_spider_old' : 'response_is_spider_new',
									desc: isRouteSearch ? 'routeSearch' : 'batchSearch',
								});
							}

							// 最终处理
							isIntlSearch
								? dispatch(genSearchIntlFlightsPrice(allFlightsList.get(0)))
								: dispatch(
										genSearchFlightsSuccess(
											allFlightsList,
											isFinished,
											currentSegmentSeq,
											giftIds,
											isRouteSearch,
											existDirectReturnTrip,
											showAuthCode || showSecurityPopup
										)
								  );
						});
					}
				});

				nextFlights = getNextFlightsBatch();
				if (nextFlights || hasCalledCommitBatch === false) {
					if (nextFlights === null) {
						hasCalledCommitBatch = true;
					}

					processNextBatchFlights(nextFlights);
				}
			};

			processNextBatchFlights(nextFlights);
		});

		// 设置礼盒id
		dispatch(genSetGiftIdList(json.data.giftIds));
		if (
			json.data.recommendProduct &&
			json.data.recommendProduct.recommendInfoList &&
			json.data.recommendProduct.recommendInfoList.length
		) {
			dispatch(genSetAllRecommendFlights(groupDomesticRecommend(json.data.recommendProduct.recommendInfoList)));
		}
	}
};

//购票攻略
const getGuidelineList = (dispatch, lowestPrice) => {
	const prevCond = getPrevCondFromGlobal(),
		searchParams = Object.assign({}, prevCond, { lowestPrice });

	fetchPost(CONFIG_URL.getGuideline, searchParams)
		.then((res) => (res && res.data) || {})
		.then((json) => {
			if (json.data) {
				const { priceChangeHistory, pricePrediction, travelTips } = json.data;
				if (priceChangeHistory || pricePrediction || travelTips) {
					dispatch(genSetGuideLine(priceChangeHistory, pricePrediction, travelTips));
				}
			}
		});
};

// 批量获取舒适度，最多每次拿50条记录
const BATCH_GET_COMFORT_MAX_LENGTH = CONSTS.FLIGHTS.BATCH_SEARCH_COMFORT_MAX_SIZE;
const getComfortTagList = (dispatch, batchGetComfortTagListParams) => {
	if (
		batchGetComfortTagListParams &&
		Array.isArray(batchGetComfortTagListParams) &&
		batchGetComfortTagListParams.length
	) {
		fetchPost(
			CONFIG_URL.batchGetComfortTagList,
			batchGetComfortTagListParams.slice(0, BATCH_GET_COMFORT_MAX_LENGTH)
		)
			.then((res) => (res && res.data) || {})
			.then((json) => {
				if (!json.data) {
					json.data = [];
				}

				dispatch(genSetComfortTags(json.data));
			});

		if (batchGetComfortTagListParams.length > BATCH_GET_COMFORT_MAX_LENGTH) {
			getComfortTagList(dispatch, batchGetComfortTagListParams.slice(BATCH_GET_COMFORT_MAX_LENGTH));
		}
	}
};

const getMaxStayoutInMin = () => {
	return getIfScopeInternational()
		? CONSTS.SEARCH.MAX_STAY_TIME_OUT_IN_MIN
		: CONSTS.SEARCH.d_MAX_STAY_TIME_OUT_IN_MIN;
};

/**
 * 设置停留时长倒计时
 */
let StayoutTimeout = null;
export const setupStayTimeoutCountDown = (dispatch) => {
	dispatch(genSetStayTimeoutFlag(0)); //清理定时器标记，隐藏弹窗
	const maxStayoutInMin = getMaxStayoutInMin();
	if (StayoutTimeout) {
		clearTimeout(StayoutTimeout);
	}
	StayoutTimeout = setTimeout(() => {
		UbtHandler.onUploadData('c_bomb', {
			text: `列表页停留时间达到配置时长${maxStayoutInMin}分钟，自动弹窗要求重新查询`,
		});
		dispatch(genSetStayTimeoutFlag(1));
	}, maxStayoutInMin * 60 * 1000);
};

/**
 * 当轮询拿到响应结果后触发
 * @param {*redux的dispatch触发} dispatch
 * @param {*查询条件} prevCond
 * @param {*当前航程顺序号，从0开始} currentSegmentSeq
 * @param {*服务端轮询的唯一标记} searchId
 */
const fetchPullFlights = (dispatch, prevCond, currentSegmentSeq, searchId, searchKey, isBuildUpMode) => {
	const currentTransactionId = prevCond.get('transactionID'),
		latestTransactionId = getTransactionIdFromGlobal();

	if (currentTransactionId === latestTransactionId) {
		let header = genAntiCrawlerHeader(prevCond);

		fetchPostWithHeaders(CONFIG_URL.pullFlights + searchId, prevCond, header)
			.then((res) => (res && res.data) || {})
			.then((json) =>
				onFetchFlights({
					dispatch,
					prevCond,
					currentSegmentSeq,
					json,
					pullDelay: CONSTS.SEARCH.LATER_PULL_DELAY,
					searchKey,
					isBuildUpMode,
					isRouteSearch: false,
				})
			);
	}
};

// 查询历史搜索记录数据
export const fetchSearchHistory = () => (dispatch) => {
	return fetchGet(CONFIG_URL.getSearchHistory, { vid: vid() })
		.then((res) => (res ? res.data : {}))
		.then((json) => {
			if (!json.data) {
				json.data = '[]';
			}

			dispatch(genSearchHistory(tryExec(() => JSON.parse(json.data), [])));
		});
};
