import fetchJsonp from 'fetch-jsonp'
import { genUrlFromParams } from "./genUrlFromParams"

export const fetchJsonpGet = (url, params, option = {}) => {
    url = genUrlFromParams(url, params)

    return new Promise((resolve, reject) => {
        fetchJsonp(url, {...option, credentials: 'same-origin' })
            .then(res => {
                resolve && resolve({ data: res.json() })
            }, reason => {
                reject && reject(reason)
            })
    })
}