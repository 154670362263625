
import mergeConfig from "../common/mergeConfig"
import Q_CONFIG_KEYS from "./qConfigKeys"

const CONSTS = {
    // 区域code： 1:精选推荐（国内） 3:日韩 4:东南亚 5:欧美澳新 2:港澳台，暂时关闭
    // index为排序顺序
    // tripType 0=OW=单程 1=RT=往返 4=MT=多程, 其他默认往返
    // 是否显示出发城市如 上海飞日韩
    sortDistrict: [{
        code: "1",
        name: "精选推荐",
        index: 1,
        tripType: 0,
        showDcity: false
    },
    {
        code: "3",
        name: "日韩",
        index: 2,
        tripType: 1,
        showDcity: true
    },
    {
        code: "4",
        name: "东南亚",
        index: 3,
        tripType: 1,
        showDcity: true
    },
    {
        code: "5",
        name: "欧美澳新",
        index: 4,
        tripType: 1,
        showDcity: true
    },
    {
        code: "2",
        name: "港澳台",
        index: 5,
        show: false,
        tripType: 1,
        showDcity: true
    }],
    maxShow: 5,

}

export default mergeConfig(Q_CONFIG_KEYS.LOW_PRICE_REPORT, CONSTS)
