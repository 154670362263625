import React from 'react';
import moment from 'moment'
import CalendarLPMonthBody from './monthBody.js'
import Converter from '../../../../sources/list/converter'
import CONSTS from '../../../../constants/list/constConfig'
import SelectMonth from './selectMonth'

class SingleCalendarLowPrice extends React.Component {
    constructor(props) {
        super(props);
        this.getUrl = this.getUrl.bind(this)
        this.selectMonth = this.selectMonth.bind(this)
        this.resetButtonDisable = this.resetButtonDisable.bind(this)
        this.state = {
            nextDisabled: false,
            prevDisabled: false
        }
    }
    getUrl(depdate, _rtdate) {
        let url = window.location.href.replace(/depdate=([0-9]|-)+/, `depdate=${depdate}`)

        return Converter.batchUpdateUrlQueryString(url, this.props.toRememberActiveKeys)
    }
    resetButtonDisable(currentMonthIndex, cb) {
        let thisMonth = moment().add(currentMonthIndex, "months").month() + 1,
            thisYear = moment().add(currentMonthIndex, "months").year(),
            nextDisabled = moment().add(CONSTS.RECOMMEND.CALENDAR_LP_SINGLE_DAYSLIMIT, "days").month() === thisMonth - 1
                && moment().add(CONSTS.RECOMMEND.CALENDAR_LP_SINGLE_DAYSLIMIT, "days").year() === thisYear,
            prevDisabled = moment().month() === thisMonth - 1 && moment().year() === thisYear;
        this.setState({
            nextDisabled,
            prevDisabled
        }, () => {
            cb && cb(currentMonthIndex)
        })
    }
    componentWillMount() {
        this.resetButtonDisable(this.props.currentMonthIndex)
    }
    selectMonth(currentMonthIndex) {
        this.resetButtonDisable(currentMonthIndex, this.props.changeCurrentMonth)
    }
    render() {
        let { currentMonthIndex } = this.props;

        return (
            <div className="cld-body">
                <div className={`cld-tbl cld-new`}>
                    <SelectMonth currentMonthIndex={currentMonthIndex} {...this.state} selectMonth={this.selectMonth} />
                    <CalendarLPMonthBody calendarData={this.props.singleCalendar} searchCriteria={this.props.searchCriteria} getUrl={this.getUrl} getToolTip={() => { }} />
                </div>
            </div>
        )
    }
}

export default SingleCalendarLowPrice;