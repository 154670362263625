/**
   * 判断两个航段是否同一个机场，并且都有航站楼且航站楼不同
   * @param {*航程} segment
   * @param {*指定前一个航段索引号，从0开始，判断该航段和下一个航段之间是否换航站楼} thePrevFlightIndex
   */
export const isDifferentTerminalBetweenFlights = (segment, thePrevFlightIndex) => {
    const prevFlight = segment.flightList[thePrevFlightIndex],
        nextFlight = segment.flightList[thePrevFlightIndex + 1]

    if (prevFlight && nextFlight) {
        return prevFlight.arrivalAirportCode === nextFlight.departureAirportCode
            && prevFlight.arrivalTerminal && nextFlight.departureTerminal
            && prevFlight.arrivalTerminal !== nextFlight.departureTerminal
    } else {
        return false
    }
}