import CONSTS from '../../constants/common/constConfig'
import { getSwitchesFromGlobal } from '../../actions/common'

/**
 * 多票组合，如果中转地是台湾且有签证风险，就需要在原本的签证提示信息基础上增加提示：
 * （自2019年8月1日起，中国大陆公民台湾自由行个人旅游签注暂停办理）
 */
export const getIfNeedExTipOfTransitPU = (transferCheckInCity) => {
    const { showTWTransitPUExtTipSwitch } = getSwitchesFromGlobal()

    if (showTWTransitPUExtTipSwitch && transferCheckInCity && typeof transferCheckInCity.get === 'function') {
        return transferCheckInCity.get('hasVisaRisk') && transferCheckInCity.get('provinceId') === CONSTS.PROVINCE_ID.TW
    } else {
        return false
    }
}