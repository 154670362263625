import moment from 'moment';
import Immutable, { List } from 'immutable';
import qs from 'qs';
import { Base64 } from 'js-base64';

import { FILTER_DEPART_TIME_TYPE } from '@/src/constants/list/enums/filter';

import { memoizeWrapper } from './memoize';
import { MEMOIZE_KEYS } from '../../constants/list/memoizeKeys';

import { getIfFlightAsLowPrice } from './getIfFlightAsLowPrice';

import {
	SessionHandler,
	clearAdjacentRecommendType,
	getAdjacentRecommendType,
	getSourceInfo,
} from './converter/SessionHandler';
import toNewListPage from './converter/toNewListPage';
import assignObjectDelectIfBlank from './converter/assignObjectDelectIfBlank';
import addParamsToListUrl from './converter/addParamsToListUrl';
import toNewListPageV2 from './converter/toNewListPageV2';
import extractHourFromDateStr from './converter/extractHourFromDateStr';
import getDurtionEnumOfFlight from './converter/getDurtionEnumOfFlight';
import getAllTransAndStopCitiesOfFlights from './converter/getAllTransAndStopCitiesOfFlights';
import getAllTransDurationOfFlights from './converter/getAllTransDurationOfFlights';
import getAllAirlineOfFlights from './converter/getAllAirlineOfFlights';
import getAllAirportOfFlights from './converter/getAllAirportOfFlights';
import getAllCabinPriceForFilter from './converter/getAllCabinPriceForFilter';
import getAllAircraftSizeOfFlights from './converter/getAllAircraftSizeOfFlights';
import getAllInvoiceTypesOfFlights from './converter/getAllInvoiceTypesOfFlights';
import getCabinListTitle from './converter/getCabinListTitle';
import getAllSegsCabinHoverContent from './converter/getAllSegsCabinHoverContent';
import getCabinHoverContent from './converter/getCabinHoverContent';
import genCabinHoverTooltipOfSeatsFragment from './converter/genCabinHoverTooltipOfSeatsFragment';
import genCabinHoverTooltipFragment from './converter/genCabinHoverTooltipFragment';
import getCabinTitle from './converter/getCabinTitle';

/**
 * 实用转换
 */
class Converter {
	/**
	 * 新增/修改URL的KEY
	 * @param {*需要修改的URL} url
	 * @param {*新增/修改的参数KEY} key
	 * @param {*该KEY对应的VALUE} value
	 */
	static updateUrlQueryString(url, key, value) {
		var index = url.indexOf('#');
		var hash = index === -1 ? '' : url.substr(index);
		url = index === -1 ? url : url.substr(0, index);

		var regExp = new RegExp('([?&])' + key + '=.*?(&|$)', 'i');
		var separator = url.indexOf('?') !== -1 ? '&' : '?';

		if (!value) {
			url = url.replace(new RegExp('([?&]?)' + key + '=[^&]*', 'i'), '');
			if (url.slice(-1) === '?') {
				url = url.slice(0, -1);
			}

			if (url.indexOf('?') === -1) url = url.replace(/&/, '?');
		} else if (url.match(regExp)) {
			url = url.replace(regExp, '$1' + key + '=' + value + '$2');
		} else {
			url = url + separator + key + '=' + value;
		}
		return url + hash;
	}

	/**
	 * 批量新增/修改URL的KEY
	 * @param {*需要修改的URL} url
	 * @param {*传递到新URL的参数信息，转换成对象，字段名为KEY，字段值为VALUE，注：如果VALUE不是字符串，则自动转字符串，比如 {a:1,b:2} 就会追加/修改  a=1&b=2 } map
	 */
	static batchUpdateUrlQueryString(url, map) {
		let newUrl = url;

		Object.keys(map).forEach((key) => {
			newUrl = Converter.updateUrlQueryString(newUrl, key, map[key]);
		});

		return newUrl;
	}

	/**
	 * 提取包含日期时间的字符串中的时间部分，比如 2017-10-24 10:23 中的 10:23
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static extractTime(dateStr, format = 'HH:mm') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的秒部分，比如 2017-10-24 10:23:00 变成 2017-10-24 10:23
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static removeSecond(dateStr, format = 'YYYY-MM-DD HH:mm') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的年份和秒部分，比如 2017-10-24 10:23:00 变成 10月24日 10:23
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static removeYearAndSecond(dateStr, format = 'MM月DD日 HH:mm') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 10月24日
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static remainMonthAndDay(dateStr, format = 'MM月DD日') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 提取包含日期时间的字符串中的小时、分钟部分，并转换成整数，比如 2017-11-8 15:39 转换成  1539
	 * @param {*日期格式的字符串，比如 2017-11-8 15:38} dateStr
	 */
	static extractTimeAsInt(dateStr) {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.hour() * 100 + m.minute();
		} else {
			return NaN;
		}
	}

	/**
	 * 从假定有效的日期时间字符串中提取出小时，比如传入 2017-10-31 09:59:48  返回 9
	 * @param {*日期字符串，格式 2017-10-31 09:59:13} dateStr
	 */
	static extractHourFromDateStr = extractHourFromDateStr;

	/**
	 * 提取包含日期时间的字符串中的日期部分，比如 2017-10-24 10:23:59 中的 2017-10-24
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23:30} str
	 */
	static extractDate(str, format = 'YYYY-MM-DD') {
		let m = moment(str);
		if (str && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 10-24
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static extractDateRemoveYear(str, format = 'MM-DD') {
		let m = moment(str);
		if (str && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 周二
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static remianWeek(dateStr) {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return `周${'一二三四五六日'.charAt(m.isoWeekday() - 1)}`;
		} else {
			return '';
		}
	}

	/**
	 * 将分钟为单位的时长转换成 XX天XX小时XX分
	 * @param {*时长，以分钟为单位} min
	 */
	static formatMinAsDayHourMin(min, format = 'DD天HH小时MM分') {
		let days = Math.floor(min / (24 * 60)),
			hours = Math.floor((min % (24 * 60)) / 60),
			mins = min % 60;
		format = days > 0 ? format.replace(/D+/g, days) : format.replace(/.*(?=HH)/g, '');
		format = hours > 0 ? format.replace(/H+/g, hours) : format.replace(/H.*(?=MM)/g, '');
		format = mins > 0 ? format.replace(/M+/g, mins) : format.replace(/MM.*/g, '');
		return format;
	}

	/**
	 * 将小时为单位的时长转换成 XX天XX小时
	 * @param {*时长，以小时为单位} hour
	 */
	static formatHourAsDayHour(hour) {
		return hour ? Converter.formatMinAsDayHourMin(hour * 60) : '';
	}

	/**
	 * 找到航班集合中最低含税/不含税均价
	 * @param {*航班集合(数组)或者单个航班} flights
	 */
	static getMinAvgPriceOfFlights(flights) {
		let minPriceWithTax = Infinity,
			minPriceWithoutTax = Infinity,
			channelOfMinPriceWithTax = '',
			channelOfMinPriceWithoutTax = '',
			flightOfMinPriceWithTax = null,
			flightOfMinPriceWithoutTax = null;

		if (flights && flights.length) {
			flights.forEach((flight) => {
				if (flight.minPriceWithTax && flight.minPriceWithoutTax) {
					let curMinPriceWithTax = flight.minPriceWithTax.avg,
						curMinPriceWithoutTax = flight.minPriceWithoutTax.avg;

					if (curMinPriceWithTax < minPriceWithTax) {
						minPriceWithTax = curMinPriceWithTax;
						flightOfMinPriceWithTax = flight;
					}

					if (curMinPriceWithoutTax < minPriceWithoutTax) {
						minPriceWithoutTax = curMinPriceWithoutTax;
						flightOfMinPriceWithoutTax = flight;
					}
				}
			});
		}

		if (flightOfMinPriceWithTax) {
			let priceItemOfMinPriceWithTax = flightOfMinPriceWithTax.priceList.find(
				(price) => price.priceWithTax.avg === minPriceWithTax
			);
			if (priceItemOfMinPriceWithTax) {
				channelOfMinPriceWithTax = priceItemOfMinPriceWithTax.channel || '';
				if (channelOfMinPriceWithTax.indexOf(',') > 0) {
					channelOfMinPriceWithTax = 'CombinedPU';
				}
			}
		}

		if (flightOfMinPriceWithoutTax) {
			let priceItemOfMinPriceWithoutTax = flightOfMinPriceWithoutTax.priceList.find(
				(price) => price.priceWithoutTax.avg === minPriceWithoutTax
			);
			if (priceItemOfMinPriceWithoutTax) {
				channelOfMinPriceWithoutTax = priceItemOfMinPriceWithoutTax.channel || '';
				if (channelOfMinPriceWithoutTax.indexOf(',') > 0) {
					channelOfMinPriceWithoutTax = 'CombinedPU';
				}
			}
		}

		return {
			minPriceWithTax,
			minPriceWithoutTax,
			channelOfMinPriceWithTax,
			channelOfMinPriceWithoutTax,
		};
	}

	/**
	 * 获取航班所有中转和经停城市信息
	 * @param {*航班集合} flights
	 * @param {*当前航程顺序号} currentSegmentSeq
	 */
	static getAllTransAndStopCitiesOfFlights = getAllTransAndStopCitiesOfFlights;

	static getDurtionEnumOfFlight = getDurtionEnumOfFlight;

	/**
	 * 获取航班所有中转停留时长信息
	 * @param {*航班集合} flights
	 * @param {*当前航程顺序号} currentSegmentSeq
	 */
	static getAllTransDurationOfFlights = getAllTransDurationOfFlights;

	/**
	 * 获取航班所有出发/到达时间信息
	 * @param {*航班集合} flights
	 * @param {*当前航程顺序号} currentSegmentSeq
	 */
	static getAllDepartAndArrivalTimeOfFlights(flights, currentSegmentSeq) {
		let departureTimeEnums = {},
			arrivalTimeEnums = {};

		flights.forEach((flight) => {
			let departureTimeHour = Converter.extractHourFromDateStr(
					flight.getIn(['flightSegments', currentSegmentSeq, 'flightList', 0, 'departureDateTime'])
				),
				arrivalTimeHour = Converter.extractHourFromDateStr(
					flight.getIn(['flightSegments', currentSegmentSeq, 'flightList']).last().get('arrivalDateTime')
				),
				minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
				minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']),
				flightAsLowPrice = getIfFlightAsLowPrice(flight);

			let departureTimeEnum = FILTER_DEPART_TIME_TYPE.enums.find(
				(theEnum) => departureTimeHour >= theEnum.value.min && departureTimeHour < theEnum.value.max
			);
			if (departureTimeEnum) {
				if (!departureTimeEnums[departureTimeEnum.key]) {
					departureTimeEnums[departureTimeEnum.key] = {
						minAvgPriceWithTax: Infinity,
						minAvgPriceWithoutTax: Infinity,
					};
				}

				let theDepartureTime = departureTimeEnums[departureTimeEnum.key];

				if (flightAsLowPrice) {
					if (minAvgPriceWithTax < theDepartureTime.minAvgPriceWithTax) {
						theDepartureTime.minAvgPriceWithTax = minAvgPriceWithTax;
					}

					if (minAvgPriceWithoutTax < theDepartureTime.minAvgPriceWithoutTax) {
						theDepartureTime.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
					}
				}
			}

			let arrivalTimeEnum = FILTER_DEPART_TIME_TYPE.enums.find(
				(theEnum) => arrivalTimeHour >= theEnum.value.min && arrivalTimeHour < theEnum.value.max
			);
			if (arrivalTimeEnum) {
				if (!arrivalTimeEnums[arrivalTimeEnum.key]) {
					arrivalTimeEnums[arrivalTimeEnum.key] = {
						minAvgPriceWithTax: Infinity,
						minAvgPriceWithoutTax: Infinity,
					};
				}

				let theArrivalTime = arrivalTimeEnums[arrivalTimeEnum.key];

				if (flightAsLowPrice) {
					if (minAvgPriceWithTax < theArrivalTime.minAvgPriceWithTax) {
						theArrivalTime.minAvgPriceWithTax = minAvgPriceWithTax;
					}

					if (minAvgPriceWithoutTax < theArrivalTime.minAvgPriceWithoutTax) {
						theArrivalTime.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
					}
				}
			}
		});

		return {
			departure: departureTimeEnums,
			arrival: arrivalTimeEnums,
		};
	}

	/**
	 *将航班所有出发/到达时间的信息根据是否含税进行排序
	 * @param {*通过getAllDepartAndArrivalTimeOfFlights得到的Map} wrapper
	 * @param {*排序是否含税} sortContainsTax
	 */
	static sortAllDepartAndArrivalTimeOfFlights(wrapper, sortContainsTax) {
		let { departure: groupedDepartTimeList, arrival: groupedArrivalTimeList } = wrapper,
			priceField = sortContainsTax ? 'minAvgPriceWithTax' : 'minAvgPriceWithoutTax';

		let minPriceDepartTimeList = List();
		groupedDepartTimeList.keySeq().forEach((departureTime) => {
			minPriceDepartTimeList = minPriceDepartTimeList.push(
				groupedDepartTimeList
					.get(departureTime)
					.sortBy((item) => item.get(priceField))
					.first()
			);
		});

		let minPriceArrivalTimeList = List();
		groupedArrivalTimeList.keySeq().forEach((arrivalTime) => {
			minPriceArrivalTimeList = minPriceArrivalTimeList.push(
				groupedArrivalTimeList
					.get(arrivalTime)
					.sortBy((item) => item.get(priceField))
					.first()
			);
		});

		return {
			departure: minPriceDepartTimeList
				.map((t) => t.set('minAvgPrice', t.get(priceField)))
				.sortBy((item) => item.get('departureTimeHourEnum').value.seq),
			arrival: minPriceArrivalTimeList
				.map((t) => t.set('minAvgPrice', t.get(priceField)))
				.sortBy((item) => item.get('arrivalTimeHourEnum').value.seq),
		};
	}

	static getAllAirportOfFlights = getAllAirportOfFlights;
	/**
	 * 对机场信息Map按照价格进行排序
	 * @param {*对航班机场信息的包装Map} wrapper
	 * @param {*排序是否含税} sortContainsTax
	 */
	static sortAllAirportOfFlights(wrapper, sortContainsTax) {
		let groupedDepartureList = wrapper.get('departure'),
			groupedArriveList = wrapper.get('arrival'),
			minPriceDepartureList = List(),
			minPriceArriveList = List(),
			priceField = sortContainsTax ? 'minAvgPriceWithTax' : 'minAvgPriceWithoutTax';

		groupedDepartureList.keySeq().every((duration) => {
			minPriceDepartureList = minPriceDepartureList.push(
				groupedDepartureList
					.get(duration)
					.sortBy((item) => item.get(priceField))
					.first()
			);
			return true;
		});

		groupedArriveList.keySeq().every((duration) => {
			minPriceArriveList = minPriceArriveList.push(
				groupedArriveList
					.get(duration)
					.sortBy((item) => item.get(priceField))
					.first()
			);
			return true;
		});

		return Immutable.Map({
			departure: minPriceDepartureList
				.map((t) => t.set('minAvgPrice', t.get(priceField)))
				.sortBy((item) => item.get('minAvgPrice')),
			arrival: minPriceArriveList
				.map((t) => t.set('minAvgPrice', t.get(priceField)))
				.sortBy((item) => item.get('minAvgPrice')),
		});
	}

	static getAllCabinPriceForFilter = getAllCabinPriceForFilter;
	/**
	 * 获取航班所有出票航司/航司联盟信息
	 * @param {*航班集合} flights
	 * @param {*当前航程顺序号} currentSegmentSeq
	 */
	static getAllAirlineOfFlights = getAllAirlineOfFlights;

	/**
	 * 抽取航司矩阵
	 * @param {*航班集合} flights
	 * @param {*当前航程顺序号} currentSegmentSeq
	 * @param {*排序中是否是否含税} sortContainsTax
	 */
	static getAirlineMatrix(flights, currentSegmentSeq, sortContainsTax) {
		let airlineMatrixMap = { data: {}, maxTransferAndStopCount: 0 },
			minPrice = {
				0: { value: 0, airlineCodes: [] },
				1: { value: 0, airlineCodes: [] },
				2: { value: 0, airlineCodes: [] },
			};

		flights.valueSeq().forEach((flight) => {
			let currentSegment = flight.getIn(['flightSegments', currentSegmentSeq]),
				airlineCode = currentSegment.get('airlineCode'),
				airlineName = currentSegment.get('airlineName'),
				minAvgPrice = flight.getIn([sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax', 'avg']),
				transferAndStopCount = currentSegment.get('transferAndStopCount');

			//大于等于2程共用一个KEY
			if (transferAndStopCount > 2) {
				transferAndStopCount = 2;
			}

			if (!airlineMatrixMap.data[airlineCode]) {
				airlineMatrixMap.data[airlineCode] = {
					code: airlineCode,
					name: airlineName,
				};
			}

			if (
				minAvgPrice < airlineMatrixMap.data[airlineCode][transferAndStopCount] ||
				!airlineMatrixMap.data[airlineCode][transferAndStopCount]
			) {
				airlineMatrixMap.data[airlineCode][transferAndStopCount] = minAvgPrice;
				if (minAvgPrice === minPrice[transferAndStopCount].value) {
					minPrice[transferAndStopCount].airlineCodes.push(airlineCode);
				}

				if (minPrice[transferAndStopCount].value === 0) {
					minPrice[transferAndStopCount].value = minAvgPrice;
					minPrice[transferAndStopCount].airlineCodes.push(airlineCode);
				}
			}

			if (minPrice[transferAndStopCount].value > minAvgPrice) {
				minPrice[transferAndStopCount].value = minAvgPrice;
				minPrice[transferAndStopCount].airlineCodes = [airlineCode];
			}

			if (transferAndStopCount > airlineMatrixMap.maxTransferAndStopCount) {
				airlineMatrixMap.maxTransferAndStopCount = transferAndStopCount;
			}
		});

		let immutableAirlineMatrixMap = Immutable.fromJS(airlineMatrixMap),
			sortedAirlineCodes = immutableAirlineMatrixMap
				.get('data')
				.keySeq()
				.sort((prevKey, nextKey) => {
					let minPrevDataKey = Math.min.apply(
							null,
							Object.keys(airlineMatrixMap.data[prevKey]).filter(
								(key) => key !== 'name' && key !== 'code'
							)
						),
						minNextDataKey = Math.min.apply(
							null,
							Object.keys(airlineMatrixMap.data[nextKey]).filter(
								(key) => key !== 'name' && key !== 'code'
							)
						);

					if (minPrevDataKey < minNextDataKey) {
						return -1;
					} else if (minPrevDataKey > minNextDataKey) {
						return 1;
					} else {
						return (
							airlineMatrixMap.data[prevKey][minPrevDataKey] -
							airlineMatrixMap.data[nextKey][minNextDataKey]
						);
					}
				});

		return immutableAirlineMatrixMap
			.set(
				'data',
				sortedAirlineCodes.reduce(
					(list, airlineCode) => list.push(immutableAirlineMatrixMap.getIn(['data', airlineCode])),
					Immutable.List()
				)
			)
			.set('minPrice', Immutable.fromJS(minPrice));
	}

	/**
	 * 获取舱等名字，针对 南航的超经舱，要显示成  明珠经济舱
	 * @param {舱等} cabin
	 * @param {共享航班执行航司或销售航司} airlinCode
	 */
	static getCabinTitle = getCabinTitle;

	static getCabinListTitle = getCabinListTitle;

	static genCabinHoverTooltipOfSeatsFragment = genCabinHoverTooltipOfSeatsFragment;

	static genCabinHoverTooltipFragment = genCabinHoverTooltipFragment;

	/**
	 * 生成指定航程的舱等HOVER内容
	 * @param {*指定航程的航班中转集合} flightList
	 * @param {*舱等集合} cabinEnumList
	 */
	static getCabinHoverContent = getCabinHoverContent;

	/**
	 * 生成当前程以及之前的仓等HOVER内容
	 * @param
	 * @param {*舱等集合} cabinEnumList
	 * @param {*运价下特殊仓等集合} flightSeatList
	 * @param {*运价下特殊仓等集合} relatedSegmentList
	 */
	static getDomesticCabinContent({ cabinEnumList, flightSeatList, relatedSegmentList, flightWayEnum }) {
		let flightIndex = -1,
			cabinArr = cabinEnumList.toJS(),
			allSegmentCabin = {};
		// 构建仓等对象 {segmentNo ： {cabins:[title, tooltipContent], prefix: '第一程/去程等'}}
		relatedSegmentList.forEach((relatedSegment) => {
			let flightList = relatedSegment.get('flightList');
			flightList.valueSeq().forEach((flight, index) => {
				flightIndex++;
				let segmentNo = relatedSegment.get('segmentNo');
				let sequenceNo = index + 1;
				let flightWay = flightWayEnum.key;

				let cabinDetail = flightSeatList.find((seat) => {
						return seat.get('segmentNo') == segmentNo && seat.get('sequenceNo') == sequenceNo;
					}),
					specailCabin = cabinDetail.get('specialClassName') ? cabinDetail : null,
					discountRate = cabinDetail.get('discountRate') || '',
					showDiscountRate = '';
				// 中转航班不显示折扣
				if (discountRate && discountRate < 1 && flightList.size < 2) {
					showDiscountRate =
						(discountRate * 10).toString().length > 5 ? (discountRate * 10).toFixed(1) : discountRate * 10;
					showDiscountRate = showDiscountRate + '折';
				}

				let cabin = specailCabin
					? {
							title: specailCabin.get('specialClassName') + showDiscountRate,
							tooltipContent: specailCabin.get('specialClassDescription'),
					  }
					: {
							title: cabinArr[flightIndex].value.title + showDiscountRate,
							tooltipContent: null,
					  };
				if (!allSegmentCabin[segmentNo]) {
					allSegmentCabin[segmentNo] = {};
					allSegmentCabin[segmentNo]['cabins'] = [];
					if (flightWay == 'D') {
						allSegmentCabin[segmentNo]['prefix'] = segmentNo == 1 ? '去' : '返';
					} else if (flightWay == 'M') {
						allSegmentCabin[segmentNo]['prefix'] = `第${segmentNo}程`;
					}
				}
				// 检查同一航程是否有重复仓等(往返多程情况下)
				let hasRepeat =
					(flightWay == 'D' || flightWay == 'M') &&
					allSegmentCabin[segmentNo]['cabins'].some((t) => t.title == cabin.title);
				if (!hasRepeat) {
					allSegmentCabin[segmentNo]['cabins'].push(cabin);
				}
			});
		});

		return allSegmentCabin;
	}

	/**
	 * 生成所有航程的舱等HOVER内容
	 * @param
	 * @param {*舱等集合} cabinEnumList
	 */
	static getAllSegsCabinHoverContent = getAllSegsCabinHoverContent;

	/**
	 * 抽取condition部分
	 * @param {*查询条件} prevCond
	 * @param {*当前预订所对应的运价} price
	 */
	static getBookCondition(prevCond, price, minPriceInAllFlightPrice) {
		let segmentList = [],
			openJawCityIndex = parseInt(Converter.getListUrlParamByName('openjawcitysequence'));

		prevCond
			.get('flightSegments')
			.valueSeq()
			.forEach((segment) => {
				let departureCityCode = segment.get('departureCityCode'),
					arrivalCityCode = segment.get('arrivalCityCode'),
					departureCityName = segment.get('departureCityName'),
					arrivalCityName = segment.get('arrivalCityName'),
					timeZone = segment.get('timeZone'),
					departureDate = segment.get('departureDate');

				segmentList.push({
					DCityCode: departureCityCode,
					ACityCode: arrivalCityCode,
					DCity: `${departureCityCode}|${departureCityName}|${departureCityCode}|${departureCityCode}|${timeZone}`,
					ACity: `${arrivalCityCode}|${arrivalCityName}|${arrivalCityCode}|${arrivalCityCode}|${timeZone}`,
					DepartDate: departureDate,
					DCityName: departureCityName,
					ACityName: arrivalCityName,
				});
			});

		let transNo = prevCond.get('transactionID'),
			condition = {
				FlightWay: prevCond.get('flightWayEnum').value.key,
				SegmentList: segmentList,
				Quantity: prevCond.get('adultCount'),
				ChildQuantity: prevCond.get('childCount'),
				InfantQuantity: prevCond.get('infantCount'),
				ClassGrade: prevCond.get('cabin'),
				TransNo: transNo,
				SearchKey: '',
				FromJavaVersion: true,
				isBomb: price.get('priceTagEnums').some((e) => e.tag.key === 'Bomb') ? 1 : 0, //爬虫标记
				IsLowPrice: minPriceInAllFlightPrice.get('totalPrice') == price.get('avgPriceWithTax'),
				AirportCityBusSwitch: window.GlobalSwitches.airportCityBusSwitch,
			};

		if (openJawCityIndex !== null && openJawCityIndex !== '' && !isNaN(openJawCityIndex)) {
			condition.OpenJawCitySequence = openJawCityIndex + 1;
		}
		return condition;
	}

	/**
	 * 根据查询条件和运价生成预定接口参数信息
	 * @param {*查询条件} prevCond
	 * @param {*当前预定所对应的运价} price
	 */
	static getBookParameter(prevCond, price, minPriceInAllFlightPrice) {
		let parameter = {
				ShoppingToken: encodeURIComponent(price.get('routeSearchToken')),
				Eligibility: price.get('eligibility'),
			},
			condition = Converter.getBookCondition(prevCond, price, minPriceInAllFlightPrice);

		const customerCityID = prevCond.getIn(['extensionAttributes', 'CustomerCityID']);
		if (customerCityID) {
			condition.UserResidenceCityId = customerCityID;
		}

		return {
			SearchMode: 'Book',
			condition: JSON.stringify(condition),
			parameter: encodeURIComponent(JSON.stringify(parameter)),
			ABTString: document.getElementById('ab_testing_tracker').value,
		};
	}

	/**
	 * 筛选筛选项中已选条目
	 * @param {*过滤项信息} filter
	 */
	static getFilterItemsForPreviewSelector(filter) {
		let groups = filter.get('groups'),
			activeKeys = filter.get('activeKeys'),
			allItems = [];

		groups.forEach((t) => {
			t.get('items').forEach((item) => {
				allItems.push(item);
			});
		});

		return Immutable.fromJS(allItems).filter(
			(item) => activeKeys.some((activeKey) => activeKey == item.get('key')) && item.get('titleForPreview')
		);
	}

	/**
	 * 根据航站楼字段转换中文名
	 * @param {*航站楼标记} terminal
	 */
	static getTerminalName(terminal) {
		return terminal == 'I' ? '国际航站楼' : terminal;
	}

	/**
	 * 根据特定参数名获取列表页url后所携带json参数信息
	 * @param {*参数名} paramName
	 */
	static getListUrlParamByName(paramName) {
		let urlParamsString = qs.parse(window.location.search.substr(1)).params;
		if (urlParamsString) {
			let urlParams = '';
			try {
				urlParams = JSON.parse(Base64.decode(urlParamsString));
			} catch (err) {
				return null;
			}
			return urlParams[paramName];
		}
		return null;
	}

	static addParamsToListUrl = addParamsToListUrl;
	/**
	 * 修改列表页中的url中的参数，如depdate，cabin等
	 * /international/search/multi-hnl-sel-sha-osa-osa-sel?depdate=2019-09-28_2019-10-10_2019-11-06&cabin=y_s&adult=1&child=0&infant=0
	 * @param {*要写入的参数} param {key: value}
	 * @param {*当前URL} url
	 */
	static setParamToListUrl({ key, value }, url) {
		let urlParams = qs.parse(url.split('?')[1]);
		if (urlParams[key]) {
			urlParams[key] = value;
		}
		return url.split('?')[0] + '?' + qs.stringify(urlParams);
	}

	static toNewListPage = toNewListPage;

	static toNewListPageV2 = toNewListPageV2;

	static getSourceInfo = getSourceInfo;
	static getAdjacentRecommendType = getAdjacentRecommendType;
	static clearAdjacentRecommendType = clearAdjacentRecommendType;
	static assignObjectDelectIfBlank = assignObjectDelectIfBlank;

	static saveRecommendSourceInfoForBookingPage(ubtProps, txid) {
		let storageUbtSourceData = {};
		if (sessionStorage.ubtSourceDataForBooking) {
			storageUbtSourceData = JSON.parse(sessionStorage.ubtSourceDataForBooking);
		}
		storageUbtSourceData[txid] = ubtProps;
		sessionStorage.ubtSourceDataForBooking = JSON.stringify(storageUbtSourceData);
	}

	static ubtSourceInfoCombiner(SourceInfoOfListPage, SourceInfoForBookingPage) {
		let sourceInfo = [];
		if (SourceInfoForBookingPage && Object.keys(SourceInfoForBookingPage).length > 0) {
			sourceInfo.push(SourceInfoForBookingPage);
		}
		if (SourceInfoOfListPage && Object.keys(SourceInfoOfListPage).length > 0) {
			sourceInfo.push(SourceInfoOfListPage);
		}
		return sourceInfo;
	}

	static getAllAircraftSizeOfFlights = getAllAircraftSizeOfFlights;

	static getAllInvoiceTypesOfFlights = getAllInvoiceTypesOfFlights;
}

export { SessionHandler };

export default memoizeWrapper(MEMOIZE_KEYS.Converter, Converter);
