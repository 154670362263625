import Enum from 'enum'
import React from 'react'
import Tooltip from '../../../components/list/common/tooltip'
import PRICE_MESSAGE from './priceMessage'
import { getChildInfantText } from '../../../components/list/common/utils'


const getAgeLimitContnet = (wrapper, limitPrefix) => {
    let { label } = wrapper
    return PRICE_MESSAGE.GV_TAG_HOVER.Age_V2.replace(/\[LIMIT_PRE_FIX\]/g, limitPrefix).replace(/\[VALUE\]/g, label)
}

export const getMultiPersonContent = (wrapper, MultiPerson, MultiPersonNew) => {
    const { childNumLimitType, infantNumLimitType } = wrapper;
    if (infantNumLimitType === undefined && childNumLimitType === undefined) { 
        return MultiPerson.replace(/\[VALUE\]/g, wrapper.label).replace(/\[LIMIT_TYPE]/g, wrapper.childBookAdultTicket ? '婴儿' : '儿童婴儿')
    }
    const extraText = getChildInfantText(wrapper, true);
    return MultiPersonNew.replace(/\[VALUE\]/g, wrapper.label).replace(/\[LIMIT_TEXT]/g, extraText)
}

/**
 * GV类型
 */
export const GV_LIMITATION_TYPE = new Enum({
    Age: {
        getTooltip: (wrapper) => {
            let { justOneMatched } = wrapper, limitPrefix = '本产品限'
            if (justOneMatched) {
                limitPrefix = '至少一人满足'
            }
            let content = getAgeLimitContnet(wrapper, limitPrefix)
            return <Tooltip content={content} />
        },
        getMultipleTooltip: (wrapper) => {
            let { justOneMatched } = wrapper, limitPrefix = '限'
            if (justOneMatched) {
                limitPrefix = ''
            }
            return getAgeLimitContnet(wrapper, limitPrefix)
        },
        extRemark: ''
    },

    MultiPerson: {
        getTooltip: (wrapper) => <Tooltip content={getMultiPersonContent(wrapper, PRICE_MESSAGE.GV_TAG_HOVER.MultiPerson, PRICE_MESSAGE.GV_TAG_HOVER.MultiPersonNew)} />,
        extRemark: '',
        getMultipleTooltip: (wrapper) => {
            return getMultiPersonContent(wrapper, PRICE_MESSAGE.GV_TAG_HOVER.MultiPerson, PRICE_MESSAGE.GV_TAG_HOVER.MultiPersonNew)
        }
    },

    Nationality: {
        getTooltip: (wrapper) => <Tooltip content={PRICE_MESSAGE.GV_TAG_HOVER.Nationality.replace(/\[VALUE\]/g, wrapper.label)} />,
        extRemark: ''
    },
    Eligibility: {
        getTooltip: (wrapper) => <Tooltip content={PRICE_MESSAGE.GV_TAG_HOVER[`Eligibility_${wrapper.label}`] || PRICE_MESSAGE.GV_TAG_HOVER.Eligibility.replace(/\[VALUE\]/g, wrapper.label)} />,
        extRemark: ''
    },
    // 国内才有的场景，限制身份证号
    IdCards: {
        getTooltip: (wrapper) => <Tooltip content={wrapper.description} />,
        extRemark: '',
        getMultipleTooltip: (wrapper) => wrapper.description
    },
})

export class GVLimitationsWrapper {
    constructor(restrict) {
      Object.assign(this, restrict);
      this.restrict = GV_LIMITATION_TYPE.get(restrict.type);
      // this.label = restrict.label
      // this.description = restrict.description
      // this.justOneMatched = restrict.justOneMatched
    }
}
