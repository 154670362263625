import Enum from 'enum'
import { UBT_TRIGGER_TYPE } from '../common'

const LOW_PRICE_CALENDAR_CLICK_GROUP = 'flt_oversea_search_home_click'
const HISTORY_SEARCH_RECOMMEND_GROUP = 'o_flt_online_home_recommend'
const LOW_PRICE_REPORT_SHOW_GROUP = 'c_show'
const AIRLINE_PREFERENCE_SHOW_GROUP = 'c_int_airline_preference_show'
const SEND_NOW_COMMON_CLICK = 'c_click'
const RAW_LOW_PRICE_CALENDAR_CLICK = {}
const RAW_HISTORY_SEARCH_RECOMMEND = []
export const CHANNEL_UBT_GROUP_TYPES = new Enum({
    SEND_NOW_CLICK: {
        //即时上报点击
        group: SEND_NOW_COMMON_CLICK,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    LOW_PRICE_CALENDAR_CLICK: {
        //低价日历点击埋点
        group: LOW_PRICE_CALENDAR_CLICK_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: RAW_LOW_PRICE_CALENDAR_CLICK
    },
    HISTORY_SEARCH_RECOMMEND: {
        group: HISTORY_SEARCH_RECOMMEND_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: RAW_HISTORY_SEARCH_RECOMMEND
    },
    LOW_PRICE_REPORT: {
        group: LOW_PRICE_REPORT_SHOW_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: {}
    },
    AIRLINE_PREFERENCE: {
        group: AIRLINE_PREFERENCE_SHOW_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: {}
    }
})
