import { lightUpload } from './lightUbt';

const EVENT_MAPS = new Map();

export const regWindowEvent = (type, func) => {
	if (typeof type !== 'string') throw `the parameter type should be string, but got ${typeof type}`;
	if (typeof func !== 'function') throw `the parameter func should be function, but got ${typeof func}`;

	if (!EVENT_MAPS.has(type)) {
		EVENT_MAPS.set(type, []);
		window.addEventListener(type, () => {
			const allEvents = EVENT_MAPS.get(type);
			if (allEvents && allEvents.length) {
				allEvents.forEach((cur) => {
					try {
						cur();
					} catch (error) {
						lightUpload('regWindowEventError', error);
					}
				});
			}
		});
	}

	const currentEvents = EVENT_MAPS.get(type);
	if (currentEvents.indexOf(func) < 0) {
		EVENT_MAPS.set(type, currentEvents.concat(func));
	}
};
