import React, { useState } from 'react';
import './PriceBox.scss';

export const removeYen = (str = '') => {
	return str.replace('&yen;', '');
};
export const removeYenTostring = (str = '') => {
	return str.replace(/&yen;/g, '¥');
};
const PriceBox = ({ flightPriceItem, travelPackageItem, originPriceItem, limitTime }) => {
	const [goExpandPrice, setGoExpandPrice] = useState(false);
	const [returnExpandPrice, setReturnExpandPrice] = useState(false);
	const [travelExpand, setTravelExpand] = useState(false);
	const clickFn = (status, index) => {
		if (index === 0) {
			setGoExpandPrice(status);
		} else {
			setReturnExpandPrice(status);
		}
	};
	const getStatus = (index) => {
		if (index === 0) {
			return goExpandPrice;
		}
		return returnExpandPrice;
	};
	const travelClickFn = (status)=>{
		setTravelExpand(status);
	}

	return !!flightPriceItem?.length && !!travelPackageItem?.title && window.GlobalSwitches.newTravelPackageLayer ? (
		<>
			{flightPriceItem.map((priceItem, index) => {
				return (
					<div key={index}>
						<div className="price-info">
							<span>{priceItem?.title}</span>
							<span className="position-relative">
								¥<span className="main-price">{removeYen(priceItem?.item) || ''}</span>
								{!!priceItem?.subDataList?.length &&
									(getStatus(index) ? (
										<i
											className="iconf-arrow-up icon-position"
											onClick={() => clickFn(false, index)}
											data-testid={`iconf-arrow-up-${index}`}
										>
											&#xe604;
										</i>
									) : (
										<i
											className="iconf-arrow-down icon-position"
											onClick={() => clickFn(true, index)}
											data-testid={`iconf-arrow-down-${index}`}
										>
											&#xe60c;
										</i>
									))}
							</span>
						</div>
						{((index === 0 && goExpandPrice) || (index === 1 && returnExpandPrice)) && (
							<>
								{(priceItem?.subDataList || []).map((subDataItem, index) => {
									return (
										<div className="price-info little-margin" key={index}>
											<span className="grayText">{subDataItem?.title || ''}</span>
											<span>
											¥
												<span className="main-price">
													{removeYen(subDataItem?.content) || ''}
												</span>
											</span>
										</div>
									);
								})}
							</>
						)}
					</div>
				);
			})}
			{travelPackageItem && (
				<>
				<div className="price-info">
					<span>{travelPackageItem?.title}</span>
					<span className="position-relative">
						¥<span className="main-price">{removeYen(travelPackageItem?.item) || ''}</span>
						{!!travelPackageItem?.subDataList?.length &&
							(travelExpand ? (
								<i
									className="iconf-arrow-up icon-position"
									onClick={() => travelClickFn(false)}
									data-testid={`iconf-arrow-up`}
								>
									&#xe604;
								</i>
							) : (
								<i
									className="iconf-arrow-down icon-position"
									onClick={() => travelClickFn(true)}
									data-testid={`iconf-arrow-down`}
								>
									&#xe60c;
								</i>
							))}
					</span>
				</div>
				{travelExpand && (
					<>
						{(travelPackageItem?.subDataList || []).map((subDataItem, index) => {
							return (
								<div className="price-info little-margin" key={index}>
									<span className="grayText">{subDataItem?.title || ''}</span>
									<span>
									¥
										<span className="main-price">
											{removeYen(subDataItem?.content) || ''}
										</span>
									</span>
								</div>
							);
						})}
					</>
				)}
				</>
			)}

			{limitTime && (
				<div className="price-info">
					<span>{limitTime?.title}</span>
					<span>
						-¥<span className="main-price">{removeYen(limitTime?.item) || ''}</span>
					</span>
				</div>
			)}
		</>
	) : (
		<div className="price-info">
			<span>{originPriceItem?.title}</span>
			<span>
				¥<span className="main-price">{removeYen(originPriceItem?.item) || ''}</span>
			</span>
		</div>
	);
};

export default PriceBox;
