import qs from 'qs';
import { get as JsKit_LocalStorage_Get } from 'x-js-kit/lib/cache/localStorage';

export default class Lib {
	// static initGoogleMapLoadProxy(isGoogleMapInChina) {
	//     if (isGoogleMapInChina) {
	//         const domainMap = {
	//             'maps.googleapis.com': 'maps.google.cn',
	//             'maps.gstatic.com': 'maps.gstatic.cn'
	//         };
	//         window.loadScriptTime = (new Date).getTime();
	//         window.google = {};
	//         google.maps = {};
	//         let googleLoader = null;
	//         Object.defineProperty(google.maps, 'Load', {
	//             get: function () {
	//                 return googleLoader;
	//             },
	//             set: function (value) {
	//                 let fnString = value.toString();
	//                 for (let key in domainMap) {
	//                     if (domainMap.hasOwnProperty(key)) {
	//                         let re = new RegExp(key.replace(/\./, '\\.'), 'g');
	//                         fnString = fnString.replace(re, domainMap[key]);
	//                     }
	//                 }
	//                 googleLoader = window.eval('(' + fnString + ')');
	//             }
	//         });
	//         return
	//     }
	//     delete window.google
	// }

	// static loadGoogleMapJS(url, callback, errorCallback) {
	//     url = url + "&callback=init_google_map"
	//     window.init_google_map = () => {
	//         callback && callback(url);
	//     }
	//     var script = document.createElement("script")
	//     script.type = "text/javascript";
	//     script.src = url;
	//     script.async = false;
	//     script.defer = "defer";
	//     script.onerror = () => {
	//         errorCallback && errorCallback();
	//     }
	//     document.body.appendChild(script);
	// }
	static isFatEnvironment() {
		return window.location.host.includes('.fat');
	}
	static filterNumberInPoiText(txt) {
		if (!txt) {
			return '';
		}
		return txt.replace(/\(\d+\)/, '');
	}
	/**
	 * 从url中获取地图跳转的参数信息
	 */
	static getMapPriceQueryInfoFromUrl() {
		const obj = qs.parse(window.location.search.substring(1));
		if (!obj || obj.sourcepage != 'pricemap' || !obj.sourcedata) {
			//如果当前url中没有，则看reffer中有没有
			if (document.referrer) {
				const matchInfo = document.referrer.match(/\/book\/(\d+)\/s/);
				let orderId = 0;
				if (matchInfo && matchInfo.length) {
					orderId = matchInfo[1];
				}
				if (orderId) {
					const cacheInfo = JsKit_LocalStorage_Get(`pricemap_flag_${orderId}`);
					if (cacheInfo && cacheInfo.value) {
						return cacheInfo.value;
					}
				}
			}
			return null;
		}
		return {
			sourcepage: 'pricemap',
			sourcedata: JSON.parse(decodeURIComponent(obj.sourcedata) || null),
		};
	}
	/**
	 * 判断当前页面是否为地图页转化过来的
	 */
	static isFromMapPrice() {
		//如果是填写页，则直接从缓存中取
		if (window.GlobalOrderData && window.GlobalOrderData.orderId) {
			const cacheObj = JsKit_LocalStorage_Get(`pricemap_flag_${window.GlobalOrderData.orderId}`);
			return !!(cacheObj && cacheObj.value);
		}
		return !!Lib.getMapPriceQueryInfoFromUrl();
	}
	/**
	 * 判断当前页面是否为历史搜索推荐转化过来的
	 */
	static isFromHistorySearch() {
		const obj = qs.parse(window.location.search.substring(1));
		if (window.GlobalOrderData && window.GlobalOrderData.orderId) {
			const cacheObj = JsKit_LocalStorage_Get(`search_history_flag_${window.GlobalOrderData.orderId}`);
			return !!(cacheObj && cacheObj.value);
		}
		if (obj && obj.source_module && obj.source_module == 'recommend_card') {
			return true;
		}
		return false;
	}
	/**
	 * 前端加密证件号
	 * 身份证号码（15位或18位）规则如下：前4位和后3位字符保持不变，掩码其余字符。例如142621198801130002 显示为 1426***********002除身份证以外其他证件规则如下：前1/3位字符和末尾2位字符保持不变，掩码其他字符。G11223344 显示为G11****44敏感信息掩码标准
	 */
	static encryptCardNo(cardType, cardNo) {
		cardNo = (cardNo || '').trim();
		if (!cardNo) {
			return '';
		}
		if (cardType == 1) {
			if (cardNo.length == 15 || cardNo.length == 18) {
				cardNo = cardNo.substr(0, 4) + '*'.repeat(cardNo.length - 7) + cardNo.substr(cardNo.length - 3, 3);
			}
			return cardNo;
		}
		const startLen = Math.floor(cardNo.length / 3);
		if (cardNo.length > startLen + 2) {
			cardNo =
				cardNo.substr(0, startLen) +
				'*'.repeat(cardNo.length - startLen - 2) +
				cardNo.substr(cardNo.length - 2, 2);
		}
		return cardNo;
	}

	/**
	 * 判断键盘按键的keyCode是否为字母和数字
	 */
	static isAlphaNumeric(keyCode) {
		return (
			(keyCode >= 65 && keyCode <= 90) || (keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)
		);
	}
}
