import React, { useState } from 'react';
import Immutable from 'immutable';

import { fetchPost } from '../common';
import CONFIG_URL from '../../constants/list/api';
import PRICE_MESSAGE from '../../constants/list/enums/priceMessage';
import PRODUCT_MESSAGE from '../../constants/list/enums/productMessage';
import Converter from '../../sources/list/converter';
import { Tabs, TabList, Tab, TabPannels, TabPannel } from '../../components/list/common/Tabs';
import { UED } from '../../components/list/popups/styleConst';
import { PRODUCT_CATEGORY_TAG_TYPE } from '../../constants/list/enums/tags';
// import { getSpecialRefund24HoursContent, SpecialRefund24HoursContentType } from '../book/common/genPenalty';
import ServicePackage from './servicePackage';
import { getPenaltyDetailCacheKey } from '../../sources/list/getPenaltyDetailCacheKey';
import INVOICE_TYPE from '../../constants/common/config/invoiceType';
import { getIfScopeDomestic } from '../../sources/list/scope';
import './penalty.scss';

const penaltyDetailCache = {},
	penaltyDetailInProgress = {};

export const getIfPenaltyDetailInProgress = (price) => {
	const cacheKey = getPenaltyDetailCacheKey(price);
	return !!penaltyDetailInProgress[cacheKey];
};

export const getPenaltyDetail = (
	allSegmentList,
	price,
	prevCond,
	penaltyCriteria,
	hasTodayFreeRefund,
	GVLimitationsEnums,
	mileagePointSwitch,
	splitRoundFlightsSwitch,
	setExtraTag,
	setExtraPenaltyTag
) => {
	const luggageVisaKey = mileagePointSwitch ? price.get('luggageVisaKey') : '',
		searchParam = {
			penaltyCriteria,
			luggageVisaKey,
		};


	const callback = ({specialRefund, refundChangePolicyTag}) => {
		const { flag, timePeriod } = specialRefund || {};
		if (flag === 1 && timePeriod === 2) {
			setExtraTag &&
				setExtraTag('SpecialRefund2Hours');
		}

		if (refundChangePolicyTag) {
			setExtraPenaltyTag && setExtraPenaltyTag(refundChangePolicyTag)
		}
	}

	return new Promise((resolve) => {
		if (penaltyCriteria || luggageVisaKey) {
			const cacheKey = getPenaltyDetailCacheKey(price);

			if (penaltyDetailCache[cacheKey]) {
				const { el, ext } = penaltyDetailCache[cacheKey];
				callback({
					specialRefund: ext.specialRefund,
					refundChangePolicyTag: ext.refundChangePolicyTag
				});
				resolve({ el, ext });
			} else {
				let isTheFirstOne = false;
				if (!penaltyDetailInProgress[cacheKey]) {
					penaltyDetailInProgress[cacheKey] = [];
					isTheFirstOne = true;
				}

				penaltyDetailInProgress[cacheKey].push(resolve);

				if (isTheFirstOne) {
					fetchPost(CONFIG_URL.getPenaltyDetail, searchParam)
						.then(res => res && res.data || {})
						.then((json) => {
							if (json.data) {
								if (!json.data.dataList) {
									json.data.dataList = [];
								}

								let {
										penaltyPolicyFlag = 'Default',
										tagName,
										mileagePoint = 0,
										mileageValue = 0,
										pennyRefundFee,
										minRefundFee,
										minRefundFeeSign,
										refundCouponTextList,
										refundCouponTagInfo,
										specialChangeTextDTO,
										specialChangeTextList,
										flightAlterationTextDTO,
										penaltyPolicyFlagDesc,
										policyDisclaimerList,
										specialRefund,
										refundChangePolicyTag
									} = json.data,
									getPenaltyContentFn = getIfScopeDomestic()
										? getDomesticPenaltyContent
										: getPenaltyContent,
									// getPenaltyContentFn = json.data ? getDomesticPenaltyContent : getDomesticPenaltyContent,

									el = json.data.dataList.length
										? getPenaltyContentFn({
												allSegmentList,
												price,
												dataList: json.data.dataList,
												hasTodayFreeRefund,
												GVLimitationsEnums,
												isDefaultPenalty: false,
												penaltyPolicyFlag,
												mileagePoint,
												mileageValue,
												pennyRefundFee,
												splitRoundFlightsSwitch,
												refundCouponTextList,
												refundCouponTagInfo,
												specialChangeTextDTO,
												flightAlterationTextDTO,
												specialChangeTextList,
												penaltyPolicyFlagDesc,
												prevCond,
												policyDisclaimerList,
										  })
										: getParamedDefaultPenaltyContent({
												isDefault: false,
												allSegmentList,
												price,
												hasTodayFreeRefund,
												GVLimitationsEnums,
												mileagePoint,
												mileageValue,
												pennyRefundFee,
												splitRoundFlightsSwitch,
												refundCouponTextList,
												refundCouponTagInfo,
												specialChangeTextDTO,
												specialChangeTextList,
												flightAlterationTip: flightAlterationTextDTO,
												policyDisclaimerList,
										  }),
									ext = {
										tagName,
										penaltyPolicyFlag,
										mileagePoint,
										mileageValue,
										pennyRefundFee,
										minRefundFee,
										minRefundFeeSign,
										prevCond,
										specialRefund,
										refundChangePolicyTag
									};

								penaltyDetailCache[cacheKey] = { el, ext };

								penaltyDetailInProgress[cacheKey].forEach((theResolve) => {
									callback({
										specialRefund,
										refundChangePolicyTag
									})
									theResolve({ el, ext })
								});
								delete penaltyDetailInProgress[cacheKey];
							}
						});
				}
			}
		} else {
			resolve({
				el: getDefaultPenaltyContent(allSegmentList, price, hasTodayFreeRefund, GVLimitationsEnums, prevCond),
				ext: {
					mileagePoint: 0,
					mileageValue: 0,
					tagName: price.getIn(['penalty', 'defaultPenaltyTag']),
					penaltyPolicyFlag: 0,
					pennyRefundFee: 0,
				},
			});
		}
	});
};

/**
 * 生成某一程的退改签Tab对象
 * @param {*某一程对应的退改签对象} penaltyItem
 * @param {*索引号，用于生成KEY} index
 */
const getPenaltyItemTab = (penaltyItem, index) => {
	return <Tab key={index}>{penaltyItem.title}</Tab>;
};

/**
 * 生成某一程的退改签展现内容，比如 单程时就是调用一次该方法，多程/往返时就是多次调用该方法
 * @param {*某一程对应的退改签对象} penaltyItem
 * @param {*索引号，用于生成KEY} index
 */
const getPenaltyItemContent = (penaltyItemList, index) => {
	let penaltyItem = penaltyItemList[index],
		penaltyItemOfImmutable = Immutable.fromJS(penaltyItem),
		onlyOnePenaltyItem = penaltyItemList.length === 1;

	if (!penaltyItemOfImmutable.get('penaltyList')) {
		penaltyItemOfImmutable = penaltyItemOfImmutable.update('penaltyList', () => Immutable.List());
	}

	const hasHeaderIndexList = [];

	penaltyItemOfImmutable
		.get('penaltyList')
		.valueSeq()
		.forEach((penalty, index) => {
			const currentNoShowHours = penalty.get('noShowHours'),
				currentNoShowContent = penalty.get('noShowContent');

			if (typeof currentNoShowHours !== 'undefined' && typeof currentNoShowContent !== 'undefined') {
				if (index === 0) {
					hasHeaderIndexList.push(index);
				} else {
					const prevNoShowHours = penaltyItemOfImmutable.getIn(['penaltyList', index - 1, 'noShowHours']),
						prevNoShowContent = penaltyItemOfImmutable.getIn(['penaltyList', index - 1, 'noShowContent']);

					if (
						prevNoShowHours !== currentNoShowHours ||
						typeof prevNoShowHours === 'undefined' ||
						typeof prevNoShowContent === 'undefined'
					) {
						hasHeaderIndexList.push(index);
					}
				}
			}
		});

	// 退改签th跨行数量计算
	const totalCount = penaltyItemOfImmutable.get('penaltyList').size + hasHeaderIndexList.length;

	// 退改说明
	return (
		<table key={index}>
			<tbody>
				{penaltyItemOfImmutable.get('penaltyList').map((detail, index2) => {
					return [
						index2 == 0 ? (
							<tr key="th">
								<th className="tit" rowSpan={totalCount + 1}>
									<strong>退改签</strong>
								</th>
								{onlyOnePenaltyItem ? null : (
									<td colSpan={2} className="tit-tab">
										<TabList>
											{penaltyItemList.map((data, index) => {
												return getPenaltyItemTab(data, index);
											})}
										</TabList>
									</td>
								)}
							</tr>
						) : null,
						<tr key={index2}>
							<td className="sub-tit" dangerouslySetInnerHTML={{ __html: detail.get('label') }}></td>
							<td className="sub-table">
								<table>
									{detail.get('feeList', Immutable.List()).size ? (
										<tbody>
											{detail.get('feeList').map((fee) => {
												return (
													<tr key={fee.get('condition')}>
														<td
															className="col-1"
															style={{ width: '40%' }}
															dangerouslySetInnerHTML={{ __html: fee.get('condition') }}
														></td>
														<td
															className="col-1"
															style={{ width: '60%' }}
															dangerouslySetInnerHTML={{ __html: fee.get('content') }}
														></td>
													</tr>
												);
											})}
										</tbody>
									) : (
										<tbody>
											{hasHeaderIndexList.includes(index2) ? (
												<tr>
													<th className="col-1 half">
														起飞
														{detail.get('noShowHours') > 0
															? '前' +
															  Converter.formatHourAsDayHour(detail.get('noShowHours'))
															: ''}
														前
													</th>
													<th>
														起飞
														{detail.get('noShowHours') > 0
															? '前' +
															  Converter.formatHourAsDayHour(detail.get('noShowHours'))
															: ''}
														后
													</th>
												</tr>
											) : null}
											<tr>
												<td
													className="col-1 half"
													dangerouslySetInnerHTML={{ __html: detail.get('content') }}
												></td>
												{detail.get('noShowContent') ? (
													<td
														dangerouslySetInnerHTML={{
															__html: detail.get('noShowContent'),
														}}
													></td>
												) : null}
											</tr>
										</tbody>
									)}
								</table>
							</td>
						</tr>,
					];
				})}
			</tbody>
		</table>
	);
};
const getFeeListDetail = (feeList) => {
	const conditions = feeList.map((item, index) => {
			return (
				<th className="col-1 detail-item" key={index} dangerouslySetInnerHTML={{ __html: item.condition }}></th>
			);
		}),
		contents = feeList.map((item, index) => {
			let amountFragment = '';
			if (item.content) {
				amountFragment = item.content;
			} else {
				amountFragment = `<span><span class="price"><dfn>¥</dfn>${item.amount}</span>/人</span>`;
			}
			return <td className="sub-tit" key={index} dangerouslySetInnerHTML={{ __html: amountFragment }}></td>;
		});

	return (
		<table>
			<tbody>
				<tr>{conditions}</tr>
				<tr>{contents}</tr>
			</tbody>
		</table>
	);
};
// const DomesticPenaltyPassengerType = {
//     ADU: '成人票',
//     CHI: '儿童票',
//     BAB: '婴儿票'
// }
const processDomesticPassengerPenaltyItemList = (penaltyItemList, isHaveTravelPackage) => {
	// 把费用类型按照乘客类型(成人、儿童，婴儿)分类

	// let newPenaltyGroup = []
	// Object.keys(DomesticPenaltyPassengerType).map(passengerTypeKey => {
	// let newPenaltyItemList = []
	// penaltyItemList.map((penalty) => {
	//     let { feeList } = penalty
	//     if (feeList && feeList.length) {
	//         let newFeeList = feeList.filter((fee) => {
	//             let { passengerType } = fee
	//             passengerType = 'ADU'
	//             return passengerType == passengerTypeKey
	//         })
	//         if (newFeeList && newFeeList.length) {
	//             hasFeeList = true
	//             newPenaltyItemList.push({
	//                 ...penalty,
	//                 feeList: newFeeList
	//             })
	//         }

	//     } else {
	//         newPenaltyItemList.push(penalty)
	//     }
	// })
	// newPenaltyGroup.push({
	//     title: '成人票',
	//     penaltyList: penaltyItemList
	// })
	// })
	// 这里不做处理，国内显示的都是成人
	return [
		{
			title: '成人票',
			newSubTitle: isHaveTravelPackage ? '(机票+旅游劵包)' : null,
			penaltyList: penaltyItemList,
		},
	];
};

export const DomesticPenaltyItemContent = ({ penaltyItemList, isHaveTravelPackage }) => {
	const multiPenaltyList = processDomesticPassengerPenaltyItemList(penaltyItemList, isHaveTravelPackage);
	const [isCollapsed, setIsCollapsed] = useState(true);
	const handleClick = () => {
		setIsCollapsed(!isCollapsed);
	};
	return multiPenaltyList.map((passengerPenaltyItem) => {
		const { title, penaltyList, newSubTitle } = passengerPenaltyItem;
		const rowSpan = penaltyList.length;
		return penaltyList.map((penalty, index) => {
			const { label, content, feeList, type } = penalty;
			const adultFeeFragment =
				index === 0 ? (
					isHaveTravelPackage ? (
						<th className="tit newWidth" rowSpan={rowSpan}>
							<strong>{title}</strong>
							<div>{newSubTitle}</div>
						</th>
					) : (
						<th className="tit" rowSpan={rowSpan}>
							<strong>{title}</strong>
						</th>
					)
				) : null;
			const labelFragment = <td className="sub-tit" dangerouslySetInnerHTML={{ __html: label }}></td>;
			let contentFragment = null;
			// 退票说明
			const isRefundText = type === 7;
			if (content && (!feeList || feeList.length == 0)) {
				contentFragment = (
					<td className="detail">
						<div
							className={isRefundText && isCollapsed ? 'collapsible' : ''}
							dangerouslySetInnerHTML={{ __html: content }}
						/>
						{isRefundText && isCollapsed && (
							<span className="unfoldText" onClick={handleClick} data-testid="click-unfold-refundText">
								展开更多<i className="iconf-arrow-down unfoldTextIcon">&#xe60c;</i>
							</span>
						)}
					</td>
				);
			} else if (feeList && feeList.length > 0) {
				contentFragment = <td className="sub-table">{getFeeListDetail(feeList)}</td>;
			}
			return (
				<tr key={index}>
					{adultFeeFragment}
					{labelFragment}
					{contentFragment}
				</tr>
			);
		});
	});
};
/**
 * 如果该运价有甄选服务的标签，需要在顶部加上提示
 * @param {*甄选服务的提示加在顶部} todayFreeRefund
 */
// let getTodayFreeRefundContent = (todayFreeRefund) => {
//     return (
//         <React.Fragment>
//             <p>本行程享受预订当天{todayFreeRefund.refundDeadline}前（{todayFreeRefund.timezone}时间）免费退票。{todayFreeRefund.takeOffToday ? '航班起飞后，不可享受免费退' : null}</p>
//             <p>预订当天{todayFreeRefund.refundDeadline}后（{todayFreeRefund.timezone}时间），退改签按以下规则执行：</p>
//         </React.Fragment>
//     )
// }

/**
 * 根据退改标签类型显示提示文案
 * @param {退改标签flag} penaltyPolicyFlag
 */
const getPenaltyPolicyFlagRemark = (penaltyPolicyFlag) => {
	const remark = penaltyPolicyFlag
		? PRICE_MESSAGE.PENALTY_POLICY_FALG_REMARK[penaltyPolicyFlag] ||
		  PRICE_MESSAGE.PENALTY_POLICY_FALG_REMARK['Default']
		: null;
	return remark;
};

const getBaggageTip = (allSegmentList, price, isTableAnd) => {
	let baggageDataList = price.getIn(['baggage', 'dataList']) || Immutable.List(),
		baggageDetailFooter = price.getIn(['baggage', 'footer']),
		baggageDefaultHover = price.getIn(['baggage', 'defaultHover']),
		totalRows = baggageDataList.size + (baggageDetailFooter ? 1 : 0),
		getTheBaggageDetail = (data) => ({
			adult: data.get('adultBaggage'),
			child: data.get('childBaggage'),
			infant: data.get('infantBaggage'),
		}),
		getBaggageDetailTitle = (segmentNo, sequenceNo) => {
			const priceTagEnums = price.get('priceTagEnums'), // 多飞一程，额外的航程信息在 priceTagEnums 里面
				pulsAnotherPriceTagEnum = priceTagEnums.find((tagEnum) => tagEnum.tag.value.getPlusAnotherFlight),
				pulsAnotherFlight = pulsAnotherPriceTagEnum
					? pulsAnotherPriceTagEnum.tag.value.getPlusAnotherFlight(pulsAnotherPriceTagEnum.data)
					: [],
				theSegment = allSegmentList.concat(pulsAnotherFlight)[segmentNo - 1],
				innerFlight = theSegment ? theSegment.getIn(['flightList', sequenceNo - 1]) : null;

			return innerFlight
				? {
						segmentNo,
						sequenceNo,
						departureCityName: innerFlight.get('departureCityName'),
						arrivalCityName: innerFlight.get('arrivalCityName'),
				  }
				: null;
		},
		// 根据航程之外的其他字段进行分组，可以实现跨航程的合并
		getMergedBaggageDetailRows = (baggageDataList, index) => {
			const theBaggageDetail = baggageDataList.get(index),
				judgeIsSame = (baggage, curIndex) =>
					curIndex === index ||
					Immutable.is(
						baggage.delete('segmentNo').delete('sequenceNo'),
						theBaggageDetail.delete('segmentNo').delete('sequenceNo')
					),
				// 此序号之前是否已经出现过，出现过就直接跳过
				prevHasSame = baggageDataList.some(
					(baggage, curIndex) => curIndex < index && judgeIsSame(baggage, curIndex)
				),
				// 当前以及之后的相同行李信息，会对标题进行，其他信息只要出现一次即可
				curAndNextSameDetails = baggageDataList.filter(
					(baggage, curIndex) => curIndex >= index && judgeIsSame(baggage, curIndex)
				),
				// 标题的合并
				mergedTitle = curAndNextSameDetails
					.map((detail) => getBaggageDetailTitle(detail.get('segmentNo'), detail.get('sequenceNo')))
					.filter(Boolean)
					.sort(
						(prev, next) => prev.segmentNo * 10 + prev.sequenceNo - (next.segmentNo * 10 + next.sequenceNo)
					)
					.reduce((prev, next) => {
						// 此处合并逻辑是，相同航程连续航段如果行李额信息相同，就可以将标题中的出发1 - 到达1, 出发2 - 到达2 缩减成 出发1 - 到达2 ，也就是不用再显示中转城市
						const prevSameItemIndexOfSameSegment = prev.findIndex(
							(item) => item.segmentNo === next.segmentNo && item.sequenceNo === next.sequenceNo - 1
						);
						if (prevSameItemIndexOfSameSegment >= 0) {
							// 如果存在前续同航程的连续航段且行李额信息完全相同，就更新前悬航段的信息，因为可能出现多个连续航段有相同行李额信息
							prev[prevSameItemIndexOfSameSegment].sequenceNo = next.sequenceNo;
							prev[prevSameItemIndexOfSameSegment].arrivalCityName = next.arrivalCityName;
						} else {
							prev.push({
								segmentNo: next.segmentNo,
								sequenceNo: next.sequenceNo,
								departureCityName: next.departureCityName,
								arrivalCityName: next.arrivalCityName,
							});
						}

						return prev;
					}, [])
					.reduce(
						(prev, next) =>
							(prev ? prev + '<br/>' : '') + `${next.departureCityName} — ${next.arrivalCityName}`,
						''
					);

			if (prevHasSame || curAndNextSameDetails.size === 0) {
				return null;
			} else {
				return (
					<tr key={index}>
						{index === 0 ? (
							<th className="tit" rowSpan={totalRows}>
								<strong>行李规定</strong>
							</th>
						) : null}

						{/* 标题的合并 */}
						<td className="sub-tit" dangerouslySetInnerHTML={{ __html: mergedTitle }}></td>
						<td className="sub-table">
							<table>
								<tbody>
									{renderDetailOfGroupPassengerTypes(getTheBaggageDetail(theBaggageDetail))}
								</tbody>
							</table>
						</td>
					</tr>
				);
			}
		},
		getColCount = (baggageDetailWithType) => {
			const handBaggage = baggageDetailWithType.getIn(['handBaggage']),
				handBaggageSizeLimit = handBaggage ? handBaggage.getIn(['baggageSizeLimit']) : null,
				checkedBaggage = baggageDetailWithType.getIn(['checkedBaggage']),
				checkedSizeLimit = checkedBaggage ? checkedBaggage.getIn(['baggageSizeLimit']) : null,
				carryOnBaggage = baggageDetailWithType.getIn(['carryOnBaggage']),
				carryOnSizeLimit = carryOnBaggage ? carryOnBaggage.getIn(['baggageSizeLimit']) : null,
				totalWeightBaggage = baggageDetailWithType.getIn(['totalHandBaggage']),
				totalWeightContent = totalWeightBaggage ? totalWeightBaggage.getIn(['baggageContent']) : null;

			const countArr = [
				[carryOnBaggage, carryOnSizeLimit, totalWeightContent],
				[handBaggage, handBaggageSizeLimit, totalWeightContent],
				[checkedBaggage, checkedSizeLimit],
			].map((arr) => arr.filter(Boolean).length);
			return Math.max(...countArr);
		},
		renderDetailOfPassengerType = (index, baggageDetail, passengerType, title, maxColCount) => {
			const baggageDetailWithType = baggageDetail[passengerType];
			const handBaggage = baggageDetailWithType.getIn(['handBaggage']),
				handBaggageSizeLimit = handBaggage ? handBaggage.getIn(['baggageSizeLimit']) : null,
				checkedBaggage = baggageDetailWithType.getIn(['checkedBaggage']),
				checkedSizeLimit = checkedBaggage ? checkedBaggage.getIn(['baggageSizeLimit']) : null,
				carryOnBaggage = baggageDetailWithType.getIn(['carryOnBaggage']),
				carryOnSizeLimit = carryOnBaggage ? carryOnBaggage.getIn(['baggageSizeLimit']) : null,
				totalWeightBaggage = baggageDetailWithType.getIn(['totalHandBaggage']),
				totalWeightContent = totalWeightBaggage ? totalWeightBaggage.getIn(['baggageContent']) : null;

			const tableTitleJsx = (
				<td
					className="col-1 caption"
					rowSpan={[carryOnBaggage, handBaggage, checkedBaggage].filter(Boolean).length}
					dangerouslySetInnerHTML={{ __html: title }}
				></td>
			);
			const handOnLength = [handBaggage, handBaggageSizeLimit, totalWeightContent].filter(Boolean).length;
			const carryOnLength = [carryOnBaggage, carryOnSizeLimit, totalWeightContent].filter(Boolean).length;
			const diff = handOnLength - carryOnLength;
			const getCarryOnContentCol = () => {
				if (carryOnSizeLimit) {
					return 1;
				} else if (totalWeightContent) {
					return diff > 0 ? diff + 1 : 1;
				} else {
					return maxColCount;
				}
			};
			const getCarryOnSizeCol = () => {
				return totalWeightContent ? 1 : Math.max(maxColCount - 2, 1);
			};

			const getHandContentCol = () => {
				if (handBaggageSizeLimit) {
					return 1;
				} else if (!carryOnBaggage) {
					return maxColCount;
				} else {
					return totalWeightContent ? Math.max(maxColCount - 1, 1) : maxColCount;
				}
			};

			const getHandSizeCol = () => {
				if (!carryOnBaggage) {
					return Math.max(maxColCount - 1, 1);
				} else {
					return totalWeightContent ? Math.max(maxColCount - 2, 1) : Math.max(maxColCount - 1, 1);
				}
			};

			const totalWeightJsx = (
				<td rowSpan={2}>
					<div>{totalWeightContent}</div>
				</td>
			);

			return baggageDetail[passengerType] ? (
				<React.Fragment key={index}>
					{carryOnBaggage ? (
						<tr>
							{tableTitleJsx}
							<td className="col-1" colSpan={getCarryOnContentCol()}>
								<div className="luggage-type">个人物品：{carryOnBaggage.getIn(['baggageContent'])}</div>
							</td>
							{!!carryOnSizeLimit && (
								<td className={totalWeightContent ? 'col-1' : ''} colSpan={getCarryOnSizeCol()}>
									<div>{carryOnSizeLimit}</div>
								</td>
							)}
							{totalWeightContent && totalWeightJsx}
						</tr>
					) : null}
					{handBaggage ? (
						<tr>
							{!carryOnBaggage && tableTitleJsx}
							<td className="col-1" colSpan={getHandContentCol()}>
								<div className="luggage-type">手提：{handBaggage.getIn(['baggageContent'])}</div>
							</td>
							{handBaggageSizeLimit ? (
								<td className={totalWeightContent ? 'col-1' : ''} colSpan={getHandSizeCol()}>
									<div>{handBaggageSizeLimit}</div>
								</td>
							) : null}
						</tr>
					) : null}
					{checkedBaggage ? (
						<tr>
							{!carryOnBaggage && !handBaggage && tableTitleJsx}
							<td className="col-1" colSpan={checkedSizeLimit ? 1 : maxColCount}>
								<div className="luggage-type">
									托运：
									{checkedBaggage.getIn(['baggageContent'])}
								</div>
							</td>
							{!!checkedSizeLimit && (
								<td colSpan={Math.max(maxColCount - 1, 1)}>
									<div>{checkedSizeLimit}</div>
								</td>
							)}
						</tr>
					) : null}
				</React.Fragment>
			) : null;
		},
		renderDetailOfGroupPassengerTypes = (baggageDetail) => {
			let getDetailInfo = (passengerType, title, seq) => {
					const handBaggage = baggageDetail[passengerType].getIn(['handBaggage']),
						checkedBaggage = baggageDetail[passengerType].getIn(['checkedBaggage']),
						carryOnBaggage = baggageDetail[passengerType].getIn(['carryOnBaggage']);

					const count = getColCount(baggageDetail[passengerType]);

					return Immutable.fromJS({
						data: {
							handBaggage,
							checkedBaggage,
							carryOnBaggage,
						},
						title,
						passengerType,
						seq,
						count,
					});
				},
				detailList = Immutable.List([]);

			if (baggageDetail['adult']) {
				detailList = detailList.push(getDetailInfo('adult', '成人', 1));
			}

			if (baggageDetail['child']) {
				detailList = detailList.push(getDetailInfo('child', '儿童', 2));
			}

			if (baggageDetail['infant']) {
				detailList = detailList.push(getDetailInfo('infant', '婴儿', 3));
			}

			const maxColCount = detailList.reduce((prev, next) => Math.max(prev, next.get('count')), 0);

			const groupedDetailList = detailList.groupBy((detail) => detail.get('data')),
				finalGroupedBaggage = [];

			groupedDetailList.keySeq().forEach((data) => {
				const theDetailList = groupedDetailList.get(data),
					type = theDetailList.first().get('passengerType'),
					titleList = [];

				theDetailList
					.sortBy((detail) => detail.get('seq'))
					.forEach((detail) => {
						titleList.push(detail.get('title'));
					});

				finalGroupedBaggage.push({ passengerType: type, title: titleList.join('<br/>') });
			});

			return finalGroupedBaggage.map((baggage, index) =>
				renderDetailOfPassengerType(index, baggageDetail, baggage.passengerType, baggage.title, maxColCount)
			);
		},
		baggageTip = baggageDataList.size ? (
			<table className={`${isTableAnd ? 'table-and luggage-rules' : 'luggage-rules'}`}>
				<tbody>
					{baggageDataList.map((data, index) => getMergedBaggageDetailRows(baggageDataList, index))}
					{baggageDetailFooter ? (
						<tr>
							<td colSpan={2} dangerouslySetInnerHTML={{ __html: baggageDetailFooter }}></td>
						</tr>
					) : null}
				</tbody>
			</table>
		) : (
			<table className={`${isTableAnd ? 'table-and luggage-rules' : 'luggage-rules'}`}>
				<tbody>
					<tr>
						<th className="tit" rowSpan={totalRows}>
							<strong>行李规定</strong>
						</th>
						<td className="sub-tit">行李规定</td>
						<td>{baggageDefaultHover}</td>
					</tr>
				</tbody>
			</table>
		);

	return baggageTip;
};

/**
 * 获取购票须知
 * @param {*运价} price
 * @param {*gv信息} GVLimitationsEnums
 */
const getPurchaseNotesTip = (price, GVLimitationsEnums) => {
	const getGVLimitationsContent = (hasSeqNum) =>
			GVLimitationsEnums.map((restrict, index) => (
				<p key={index}>
					{' '}
					{hasSeqNum ? `${index + 1}、` : ''}
					{PRICE_MESSAGE.GV_TAG_HOVER[`Multi_${restrict.label}`] ||
						PRICE_MESSAGE.GV_TAG_HOVER.Multi.replace(/\[VALUE\]/g, restrict.label)}
					{restrict.restrict.value.extRemark}
				</p>
			)),
		productTypeWrapper = price.get('productTypeEnum'),
		isMultiPU = productTypeWrapper.tag === PRODUCT_CATEGORY_TAG_TYPE.MultiPU,
		purchaseNotes = isMultiPU
			? price.get('purchaseNotes')
				? price.get('purchaseNotes').concat(PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.MultiPU)
				: Immutable.List().concat(PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.MultiPU)
			: price.get('purchaseNotes') || Immutable.List(),
		hasGVLimit = GVLimitationsEnums.size > 0,
		hasPurchaseNotes = purchaseNotes.size > 0,
		brandWrapper = price.get('brandWrapper'),
		brandPriceTags =
			brandWrapper && brandWrapper.data && brandWrapper.data.attrs
				? brandWrapper.data.attrs.filter((attr) => attr.title && attr.desc)
				: [],
		anyBrandPriceTag = brandPriceTags.length > 0,
		purchaseNotesTip =
			hasGVLimit || hasPurchaseNotes || anyBrandPriceTag ? (
				<table>
					<tbody>
						<tr>
							<th className="tit" rowSpan={1 + (hasPurchaseNotes ? 1 : 0) + brandPriceTags.length}>
								<strong>购票须知</strong>
							</th>
							{hasGVLimit ? (
								<React.Fragment>
									<td className="sub-tit">购票限制</td>
									<td>{getGVLimitationsContent(GVLimitationsEnums.size > 1)}</td>
								</React.Fragment>
							) : (
								<React.Fragment>
									<td className="hidden-cell"></td>
									<td className="hidden-cell"></td>
								</React.Fragment>
							)}
						</tr>
						{anyBrandPriceTag
							? brandPriceTags.map((tag) => {
									return (
										<tr key={tag.title}>
											<td className="sub-tit">{tag.title}</td>
											<td>{tag.desc}</td>
										</tr>
									);
							  })
							: null}
						{hasPurchaseNotes ? (
							<tr>
								<td className="sub-tit">备注</td>
								<td
									dangerouslySetInnerHTML={{
										__html: purchaseNotes.reduce(
											(prev, next) => (prev ? prev + '<br/><br/>' : '') + next,
											''
										),
									}}
								></td>
							</tr>
						) : null}
					</tbody>
				</table>
			) : null;

	return purchaseNotesTip;
};

const getMileagePointTip = (mileagePoint, mileageValue) => {
	// todo 开关控制是否显示里程积分
	return mileagePoint ? (
		<table>
			<tbody>
				<tr>
					<th className="tit" rowSpan="2">
						<strong>里程</strong>
					</th>
					<td
						dangerouslySetInnerHTML={{
							__html: `航司基础会员可累积航司里程${mileagePoint}${
								mileageValue ? '（价值<dfn>&yen;</dfn>' + mileageValue + '）' : ''
							}<img src="//pic.c-ctrip.com/flight_intl/list/data-source.png" alt="" height="" style="vertical-align: bottom;height: 12px;padding-bottom: 2px;"/><br/>*里程积分信息仅供参考，具体里程以航空公司实际累积为准。`,
						}}
					></td>
				</tr>
			</tbody>
		</table>
	) : null;
};

/**
 *根据退改签接口响应的节点生成整个退改签HOVER对象
 * @param {*整个响应节点，是个数组} dataList
 * @param {*甄选服务的提示加在顶部} hasTodayFreeRefund
 * @param {*是否取默认文案} isDefaultPenalty
 */
let getPenaltyContent = ({
	allSegmentList,
	price,
	dataList,
	// hasTodayFreeRefund,
	GVLimitationsEnums,
	isDefaultPenalty,
	// penaltyPolicyFlag,
	mileagePoint,
	mileageValue,
	// pennyRefundFee,
	splitRoundFlightsSwitch = false,
	refundCouponTextList,
	refundCouponTagInfo,
	specialChangeTextDTO,
	specialChangeTextList,
	flightAlterationTextDTO,
	policyDisclaimerList,
	// penaltyPolicyFlagDesc,
}) => {
	const productTypeWrapper = price.get('productTypeEnum'),
		is24HourRefund = productTypeWrapper.tag === PRODUCT_CATEGORY_TAG_TYPE.SpecialRefund24Hours,
		// specialRefund24HoursTip = is24HourRefund ? getSpecialRefund24HoursContent(isDefaultPenalty, pennyRefundFee, SpecialRefund24HoursContentType.List) : null,
		// todayFreeRefundTip = hasTodayFreeRefund ? getTodayFreeRefundContent(hasTodayFreeRefund) : null,
		// penaltyPolicyFlagRemark = getPenaltyPolicyFlagRemark(penaltyPolicyFlag),
		purchaseNotesTip = getPurchaseNotesTip(price, GVLimitationsEnums),
		mileagePointTip = getMileagePointTip(mileagePoint, mileageValue),
		// 24小时特惠退，并且退改签接口没有返回数据的时候，应该默认退改签内容
		hiddenPenaltyItemContent = isDefaultPenalty && is24HourRefund,
		baggageTip = getBaggageTip(allSegmentList, price, !hiddenPenaltyItemContent),
		tabPanels = [];

	dataList.forEach((data, index) => {
		tabPanels.push(<TabPannel key={index}>{getPenaltyItemContent(dataList, index)}</TabPannel>);
	});

	// 只退代金券说明
	let refundCouponTip = null;
	if (refundCouponTagInfo && refundCouponTextList && refundCouponTextList.length) {
		refundCouponTip = (
			<div className="sub-desc orange-desc">
				<p className="desc-title">· {refundCouponTagInfo}</p>
				<div className="desc-content">
					{refundCouponTextList.map((ele, index) => (
						<div key={index}>{ele}</div>
					))}
				</div>
			</div>
		);
	}

	// 特殊事件改期免收手续费.
	let changeFreeDescTip = null;
	if (specialChangeTextDTO && specialChangeTextDTO.specialChangeText) {
		changeFreeDescTip = (
			<div className="sub-desc">
				<p className="desc-title">· {specialChangeTextDTO.specialChangeTag}</p>
				<div className="desc-content">{specialChangeTextDTO.specialChangeText}</div>
			</div>
		);
	}

	// 首次改签免费、灵活改签展示优化
	let changeFreeDescTitleTip = null;
	if (specialChangeTextList && specialChangeTextList.length > 0) {
		const changeFreeDescTitle = specialChangeTextList.find(({ type }) => type === 1);
		if (changeFreeDescTitle && changeFreeDescTitle.specialChangeText) {
			changeFreeDescTitleTip = <div className="sub-title">{changeFreeDescTitle.specialChangeText}</div>;
		}
		const specialChangeTextListType2 = specialChangeTextList.filter(({ type }) => type === 2);
		if (specialChangeTextListType2 && specialChangeTextListType2.length > 0) {
			changeFreeDescTip = specialChangeTextListType2.map(({ specialChangeTag, specialChangeText }, index) => {
				return (
					<React.Fragment key={index}>
						<div className="sub-desc">
							<p className="desc-title">· {specialChangeTag}</p>
							<div className="desc-content">{specialChangeText}</div>
						</div>
					</React.Fragment>
				);
			});
		}
	}

	let flightAlterationTip = null;
	if (flightAlterationTextDTO && flightAlterationTextDTO.flightAlterationTag) {
		flightAlterationTip = (
			<div className="sub-desc orange-desc">
				<p className="desc-title">· {flightAlterationTextDTO.flightAlterationTag}</p>
				<div className="desc-content">{flightAlterationTextDTO.flightAlterationRemark}</div>
			</div>
		);
	}

	let noticeTip = null;
	if (policyDisclaimerList?.length) {
		noticeTip = (
			<>
				{policyDisclaimerList.map((item, index) => {
					const { title, desc } = item;
					return (
						<div className="sub-desc orange-desc" key={index}>
							<p className="desc-title">· {title}</p>
							<div className="desc-content">{desc}</div>
						</div>
					);
				})}
			</>
		);
	}

	if (dataList.length === 1) {
		return (
			<div className="tooltip tooltip-table">
				<div className="tooltip-content">
					<div className="des-container">
						{changeFreeDescTitleTip}
						{/* {topTip} */}
						{changeFreeDescTip}
						{refundCouponTip}
						{flightAlterationTip}
						{noticeTip}
					</div>
					{hiddenPenaltyItemContent ? null : getPenaltyItemContent(dataList, 0)}
					{baggageTip}
					{mileagePointTip}
					{purchaseNotesTip}
				</div>
			</div>
		);
	} else {
		return (
			<div
				className="tooltip tooltip-table"
				style={splitRoundFlightsSwitch ? UED.tooltipWithOutMargin : UED.tooltipMargin}
			>
				<div className="des-container">
					{changeFreeDescTitleTip}
					{/* {topTip} */}
					{changeFreeDescTip}
					{refundCouponTip}
					{flightAlterationTip}
					{noticeTip}
				</div>
				{hiddenPenaltyItemContent ? null : (
					<Tabs>
						<TabPannels nestTabList={true}>{tabPanels}</TabPannels>
					</Tabs>
				)}

				{baggageTip}
				{mileagePointTip}
				{purchaseNotesTip}
			</div>
		);
	}
};
const getBaggaggeItem = (baggage) => {
	const freeTag = baggage.getIn(['hasFreeBaggage']) ? '免费' : '';
	const prefix = baggage.getIn(['prefix']);
	const title = freeTag ? `${prefix}${freeTag}${baggage.getIn(['title'])}: ` : '';
	let content = title + baggage.getIn(['baggageContent']);
	const baggageSizeLimit = baggage.getIn(['baggageSizeLimit']);
	if (baggageSizeLimit) {
		content = content + '，' + baggageSizeLimit;
	}
	return <React.Fragment>{content}</React.Fragment>;
};
const BaggageKeys = {
	combinedBaggage: {
		title: '手提+托运行李额',
	},
	handBaggage: {
		title: '手提行李额',
	},
	checkedBaggage: {
		title: '托运行李额',
	},
};
const getDomesticBaggageTip = (baggage) => {
	// 国内行李额只有成人
	let adultBaggage = baggage.getIn(['adultBaggage']),
		baggages = [],
		hasCombinedBaggage = adultBaggage.getIn(['combinedBaggage']);

	// 设置每个行李额的title（表头）
	Object.keys(BaggageKeys).forEach((key) => {
		if (adultBaggage.get(key)) {
			let prefix = '';
			// 有组合模式的行李额，其他行李额要加上其中
			if (hasCombinedBaggage && key != 'combinedBaggage') {
				prefix = '其中';
			}
			adultBaggage = adultBaggage.setIn([key, 'prefix'], prefix);
			adultBaggage = adultBaggage.setIn([key, 'title'], BaggageKeys[key].title);

			baggages.push(adultBaggage.get(key));
		}
	});

	if (baggages.length) {
		return (
			<React.Fragment>
				<tr>
					<td className="sub-tit col-2-title" colSpan="2" rowSpan={baggages.length}>
						行李额
					</td>
					<td>{getBaggaggeItem(baggages[0])}</td>
				</tr>
				<tr>
					{baggages[1] && <td>{getBaggaggeItem(baggages[1])}</td>}
					{baggages[2] && <td>{getBaggaggeItem(baggages[2])}</td>}
				</tr>
			</React.Fragment>
		);
	} else {
		return null;
	}
};
const hasMultiSequences = ({ allSegmentList, segmentNo }) => {
	return allSegmentList.some((segment) => {
		if (segment.get('segmentNo') == segmentNo) {
			return segment.get('flightList').size > 1;
		}
		return false;
	});
};

const getDometicSimpleTr = ({ title, content }) => {
	return content ? (
		<tr>
			<td className="sub-tit col-2-title" colSpan="2">
				{title}
			</td>
			<td dangerouslySetInnerHTML={{ __html: content }}></td>
		</tr>
	) : null;
};
export const getInvoiceTip = (invoiceType, extraText) => {
	const content = invoiceType ? INVOICE_TYPE[invoiceType] && INVOICE_TYPE[invoiceType].desc : null;
	const text = invoiceType ? INVOICE_TYPE[invoiceType] && INVOICE_TYPE[invoiceType].content : '';
	return content
		? getDometicSimpleTr({
				title: '报销凭证',
				content: text
					? `${text}${extraText}`
					: `可提供${content}作为报销凭证，凭证金额以实际开具金额为准， 支付成功后可查看订单并开具凭证。${extraText}`,
		  })
		: null;
};
const getTrainTip = () => {
	return (
		<tr>
			<td colSpan="3">
				<p>本产品是特价组合产品，不支持单独退订火车票、机票。退票请联系携程95010处理。</p>
				<p>
					在选择空铁组合产品时，请充分了解换乘距离确定合适的航班、车次。如因航司、铁路延误原因或突发事件、天气原因导致退票或改期，请联系携程95010处理。
				</p>
			</td>
		</tr>
	);
};
const getDomesticCommonTip = (penaltyPolicyFlagRemark) => {
	return (
		<tr>
			<td colSpan="3">
				<div className="sub-desc orange-desc">
					<p className="desc-title">{penaltyPolicyFlagRemark}</p>
				</div>
			</td>
		</tr>
	);
};
let getDomesticPenaltyContent = ({
	allSegmentList, // 所有航班的当前航程
	price,
	dataList,
	penaltyPolicyFlag,
	prevCond,
	isDefaultPenalty,
}) => {
	const tabs = [],
		tabPannels = [];

	let flightWay = prevCond.get('flightWay'),
		flightSeatList = price.getIn(['priceUnitList', 0, 'flightSeatList']),
		theSequenceNoIndex = -1;

	// 国内航班退改签按照航段展示,遍历所有航段
	allSegmentList.forEach((segment) => {
		const segmentNo = segment.get('segmentNo'),
			flightList = segment.get('flightList'),
			hasTrain = segment.get('segmentType') == 'FLIGHT_TRAIN',
			segmentHasAnyTrain = flightList.some((flight) => flight.get('trafficType') == 'TRAIN');

		flightList.forEach((flight) => {
			theSequenceNoIndex++;
			let sequenceNo = flight.get('sequenceNo'),
				title = '',
				trafficType = flight.get('trafficType'),
				marketAirlineCode = flight.get('marketAirlineCode'),
				// 默认退改签统一使用第0个
				penatlyData = isDefaultPenalty ? dataList[0] : dataList[theSequenceNoIndex];

			// 设置每个航段的退改签标签的title
			if (segmentHasAnyTrain) {
				title = '机票退改签说明';
				if (trafficType == 'TRAIN') {
					title = '火车票退改签说明';
				}
			} else {
				let multiSequences = hasMultiSequences({ allSegmentList, segmentNo }),
					theSequenceTitle = `第${sequenceNo}程`;

				switch (flightWay) {
					case 'S':
						if (multiSequences) {
							title = theSequenceTitle;
						}
						break;
					case 'D':
						title = segmentNo == 1 ? '去程' : '返程';
						if (multiSequences) {
							title += theSequenceTitle;
						}
						break;
					case 'M':
						title = theSequenceTitle = `第${segmentNo}程`;
						break;
					default:
						break;
				}
			}

			// 获取每个航段下的其他信息(报销凭证,里程,备注信息)
			const datas = flightSeatList.filter((seat) => {
				return seat.get('sequenceNo') == sequenceNo && seat.get('segmentNo') == segmentNo;
			});
			let data = datas && datas.size > 0 ? datas.get(0) : null,
				// 报销凭证
				invoiceType = data && data.get('invoiceType'),
				// 报销凭证由哪方提供，如果=airline代表需要有特殊文案说明
				providerWay = data && data.get('providerWay'),
				// 里程
				accumulateMileageText = (data && data.get('accumulateMileageText')) || '',
				// 退改签相关
				penaltyList = penatlyData && penatlyData.penaltyList,
				// 其他备注
				penaltyRemark = null;

			// 过滤出退票备注放在下面展示 type == 4,
			penaltyList = penaltyList.filter((penalty) => {
				if (penalty.type == 4) {
					penaltyRemark = penalty;
					return false;
				} else {
					return true;
				}
			});

			let extraText = '';
			if (providerWay === 'Airline') {
				if (marketAirlineCode === '9C') {
					extraText = '（如需行程单，请前往“春秋航空小程序”或“春秋航空”APP获取）';
				}
			}

			// 获取每个航段的行李额相关信息
			const baggageList = price.getIn(['baggage', 'dataList']) || Immutable.List();
			const baggages = baggageList.filter((baggage) => {
				return baggage.get('segmentNo') == segmentNo && baggage.get('sequenceNo') == sequenceNo;
			});
			const baggage = baggages && baggages.size > 0 ? baggages.get(0) : null,
				baggageDesc = baggage && baggage.getIn(['adultBaggage', 'baggageDesc']);

			// 航段标签tab
			tabs.push(title);
			// 获取每个航段的旅套信息
			const seatDatas = flightSeatList.find((seat) => {
				return seat.get('segmentNo') === segmentNo && seat.get('sequenceNo') === sequenceNo;
			});
			const isHaveTravelPackage =
				seatDatas &&
				seatDatas.getIn(['productTypes']) &&
				seatDatas.getIn(['productTypes']).some((item) => item === 'TravelPackage');
			tabPannels.push(
				<div>
					<table>
						<tbody>
							{/* 退改签 */}
							<DomesticPenaltyItemContent
								penaltyItemList={penaltyList}
								isHaveTravelPackage={isHaveTravelPackage}
							/>
							{/* 行李额 */}
							{baggage && getDomesticBaggageTip(baggage)}
							{/* 报销凭证 */}
							{getInvoiceTip(invoiceType, extraText)}
							{/* 里程 */}
							{!hasTrain && accumulateMileageText && accumulateMileageText !== ''
								? getDometicSimpleTr({
										title: '里程积分',
										content: accumulateMileageText,
								  })
								: null}

							{/* 航司备注 */}
							{baggageDesc &&
								getDometicSimpleTr({
									title: '航司备注',
									content: baggageDesc,
								})}
							{/* 备注 */}
							{getDometicSimpleTr({
								title: '备注',
								content: penaltyRemark && penaltyRemark.content,
							})}

							{/* 机铁购票说明 */}
							{hasTrain ? getTrainTip() : null}
							{/* 共有提示 */}
							{getDomesticCommonTip(getPenaltyPolicyFlagRemark(penaltyPolicyFlag))}
						</tbody>
					</table>
				</div>
			);
		});
	});

	return (
		<div className="tooltip tooltip-table domestic-tooltip-table">
			<div className="tooltip-content ">
				{tabPannels.length > 1 ? (
					<Tabs>
						<TabList>
							{tabs.map((tab, index) => {
								return <Tab key={index}>{tab}</Tab>;
							})}
						</TabList>
						<TabPannels nestTabList={true}>
							{tabPannels.map((pannel, index) => {
								return (
									<TabPannel key={index} className={index}>
										{pannel}
									</TabPannel>
								);
							})}
							{tabPannels}
						</TabPannels>
					</Tabs>
				) : (
					tabPannels[0]
				)}
			</div>
		</div>
	);
};
/**
 *生成默认运价信息
 * @param {*运价} price
 * @param {*是否有甄选服务} hasTodayFreeRefund
 * @param {*GV限制信息} GVLimitationsEnums
 */
export const getDefaultPenaltyContent = (
	allSegmentList,
	price,
	hasTodayFreeRefund,
	GVLimitationsEnums,
	prevCond,
	mileagePoint = 0,
	mileageValue = 0,
	pennyRefundFee = undefined,
	splitRoundFlightsSwitch = false
) => {
	return getParamedDefaultPenaltyContent({
		isDefault: true,
		allSegmentList,
		price,
		hasTodayFreeRefund,
		GVLimitationsEnums,
		mileagePoint,
		mileageValue,
		pennyRefundFee,
		splitRoundFlightsSwitch,
		prevCond,
	});
};

export const getDefaultServicePackage = (packageConfig) => {
	return <ServicePackage servicePackage={packageConfig} />;
};
const getParamedDefaultPenaltyContent = ({
	isDefault,
	allSegmentList,
	price,
	hasTodayFreeRefund,
	GVLimitationsEnums,
	mileagePoint = 0,
	mileageValue = 0,
	pennyRefundFee = undefined,
	splitRoundFlightsSwitch,
	refundCouponTextList,
	refundCouponTagInfo,
	specialChangeTextDTO,
	specialChangeTextList,
	flightAlterationTip,
	policyDisclaimerList,
	prevCond,
}) => {
	const domestic = getIfScopeDomestic();
	const defaultPenalty = domestic
			? [price.getIn(['penalty', 'defaultPenalty']).toJS()]
			: [price.getIn(['penalty', 'defaultPenalty'])],
		getPenaltyContentFn = domestic ? getDomesticPenaltyContent : getPenaltyContent;
	return getPenaltyContentFn({
		allSegmentList,
		price,
		dataList: defaultPenalty,
		hasTodayFreeRefund,
		GVLimitationsEnums,
		isDefaultPenalty: isDefault,
		penaltyPolicyFlag: 'Default',
		mileagePoint,
		mileageValue,
		pennyRefundFee,
		splitRoundFlightsSwitch,
		refundCouponTextList,
		refundCouponTagInfo,
		specialChangeTextDTO,
		specialChangeTextList,
		flightAlterationTextDTO: flightAlterationTip,
		policyDisclaimerList,
		prevCond,
	});
};
