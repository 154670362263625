
import React from 'react'
import { PASSENGER_TYPE } from "../../../constants/common/enum/common"
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import { DOMESTIC_PASSENGER_TYPE, DOMESTIC_PASSENGER_TYPE_DESCRIPTION, GEN_SEARCH_FORM_ANIMATION } from '../../../constants/common/enum/searchForm';

let PassengerCount = ({ ...props }) => {
    const {
        newAdtCount,
        newChiCount,
        newInfCount,
        passenger,
        onRestPassengerCount,
        onSetPassengerCount,
        onHandleSubmit,
        dropdownDisplay,
        onSelectActive,
        adultDisable,
        childrenDisable,
        infantDisable,
        warn,
        disableSubmit,
        type,
        domestic,
        onHandleSubmitDomestic
    } = props
    let animateClass = GEN_SEARCH_FORM_ANIMATION.PASSENGER(dropdownDisplay)
    let dropdownList = (topPosition) => (
        <div className="passenger-count-select" style={{ display: dropdownDisplay ? 'block' : 'none', position: 'absolute', top: topPosition, right: '0' }}>
            <div className="passenger-item">
                <div className="content">
                    <i className="iconf-adult">&#xe660;</i>
                    <div className="typename">成人</div>
                    <div className="desc">12岁及以上</div>
                </div>
                <div className="control">
                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.ADULT, newAdtCount, -1) }} className={`btn ${newAdtCount == 0 ? 'disable' : ''}`}>
                        <i className="iconf-count-add">&#xe667;</i>
                    </div>
                    <div className="count-value">{newAdtCount}</div>
                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.ADULT, newAdtCount, +1) }} className={`btn ${adultDisable ? 'disable' : ''}`}>
                        <i className="iconf-count-del">&#xe662;</i>
                    </div>
                </div>
            </div>
            <div className="passenger-item">
                <div className="content">
                    <i className="iconf-adult">&#xe664;</i>
                    <div className="typename">儿童</div>
                    <div className="desc">2岁~12岁(不含)</div>
                </div>
                <div className="control">

                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.CHILD, newChiCount, -1) }} className={`btn ${newChiCount == 0 ? 'disable' : ''}`}>
                        <i className="iconf-count-add">&#xe667;</i>
                    </div>
                    <div className="count-value">{newChiCount}</div>
                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.CHILD, newChiCount, +1) }} className={`btn ${childrenDisable ? 'disable' : ''}`}>
                        <i className="iconf-count-del">&#xe662;</i>
                    </div>
                </div>
            </div>

            <div className="passenger-item last">
                <div className="content">
                    <i className="iconf-adult">&#xe663;</i>
                    <div className="typename">婴儿</div>
                    <div className="desc">14天~2岁(不含)</div>
                </div>
                <div className="control">
                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.INFANT, newInfCount, -1) }} className={`btn ${newInfCount == 0 ? 'disable' : ''}`}>
                        <i className="iconf-count-add">&#xe667;</i>
                    </div>
                    <div className="count-value">{newInfCount}</div>
                    <div onClick={() => { onSetPassengerCount(PASSENGER_TYPE.INFANT, newInfCount, +1) }} className={`btn ${infantDisable ? 'disable' : ''}`}>
                        <i className="iconf-count-del">&#xe662;</i>
                    </div>
                </div>
            </div>
            {warn ? (
                <div className="valid-msg error" dangerouslySetInnerHTML={{ __html: warn }} >

                </div>
            ) : null}

            <div className="btns">
                {disableSubmit ? (
                    <a href="javascript:;" className="btn-sure disabled">确定</a>
                ) : (

                        <a href="javascript:;" onClick={onHandleSubmit} className="btn-sure">确定</a>

                    )}
                <a href="javascript:;" className="btn-cancel" onClick={onRestPassengerCount}>取消</a>
            </div>
        </div>
    )
    let getPassenger = () => {
        let { adtCount, chiCount, infCount } = passenger
        return props.type == 'text' ? (
            <div className={`flt-subclass ${dropdownDisplay ? 'active' : ''} ${animateClass}`}>
                <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'passengerType', text: '乘客类型', passengerCount: [{ adtCount }, { chiCount }, { infCount }] })}>
                    <div className="form-select-v3" onClick={onSelectActive}>
                        <div className="form-select-txt">
                            <span className="text-item">{adtCount}成人</span>
                            <span className="text-item">{chiCount}儿童</span>
                            <span className="text-item">{infCount}婴儿</span>
                        </div>
                        <i className="iconf-arrow-down">&#xe60c;</i>
                        <i className="iconf-arrow-up">&#xe604;</i>
                    </div>
                </UbtBoundary>
                {dropdownList('33px')}
            </div>
        ) : (
                <div className={`form-item-v3-group form-item-v3-stand-out passenger-selector ${dropdownDisplay ? 'active' : ''} ${animateClass}`}>
                    <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'passengerType', text: '乘客类型', passengerCount: [{ adtCount }, { chiCount }, { infCount }] })}>
                        <div className="form-item-v3 flt-field-v3 " onClick={onSelectActive}>
                            <span className="form-label-v3">乘客类型</span>
                            <div className="form-select-input ">
                                <div className="form-select-v3">
                                    <span className="form-select-txt">
                                        <span>{adtCount}成人</span>
                                        <span>{chiCount}儿童</span>
                                        <span>{infCount}婴儿</span>
                                    </span>
                                    <i className="iconf-arrow-down">&#xe60c;</i>
                                    <i className="iconf-arrow-up">&#xe604;</i>
                                </div>
                            </div>
                        </div>
                    </UbtBoundary>
                    {dropdownList(type == 'SMALL' ? '59px' : '69px')}
                </div>
            )
    }
    let generateDomesticPassenger = () => {
        const isSelected = (name) => {
            let select = false
            Object.keys(passenger).map(key => {
                if (name == key && passenger[key] >= 1) {
                    select = true
                }
            })
            return select
        }
        return DOMESTIC_PASSENGER_TYPE.enums.map((item, index) => {
            let selected = isSelected(item.value.name)
            return <div key={index} className={`flt-direct domestic-item ${selected ? 'active' : ''} ${animateClass}`} onClick={() => onHandleSubmitDomestic({ [item.value.name]: !selected ? 1 : 0 })}>
                <i className="ico-checkbox-square"></i>
                <span className="label-tool-tip-wrap">
                    {item.value.display}
                    <div className="label-tool-tip">{item.value.tip}</div>
                </span>
            </div>
        })
    }
    let anyDomesticPassengerHasSelected = () => {
        let hasSelectd = false
        Object.keys(passenger).map((key) => {
            if (passenger[key] >= 1 && key !== 'adtCount') {
                hasSelectd = true
            }
        })
        return hasSelectd
    }
    let getDomesticPassenger = () => {
        return props.type == 'text' ? (
            <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'passengerType', text: '乘客类型' })}>
                <div className="domestic-passenger">
                    {generateDomesticPassenger()}
                    {anyDomesticPassengerHasSelected() ?
                        <div className="child_tips" style={{ float: 'right' }}>
                            {DOMESTIC_PASSENGER_TYPE_DESCRIPTION.LABEL.value}
                            {DOMESTIC_PASSENGER_TYPE_DESCRIPTION.DESCRIPTION.value}
                        </div> : null}
                </div>
            </UbtBoundary>
        ) : (
                <div className="form-item-v3-group-wrap">
                    <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'passengerType', text: '乘客类型' })}>
                        <div className="form-item-v3-group form-item-v3-stand-out">
                            <div className="form-item-v3 flt-field-v3">
                                <span className="form-label-v3">{anyDomesticPassengerHasSelected() ?
                                    <div className="child_tips">
                                        {DOMESTIC_PASSENGER_TYPE_DESCRIPTION.LABEL.value}
                                        {DOMESTIC_PASSENGER_TYPE_DESCRIPTION.DESCRIPTION.value}
                                    </div> : '乘客类型'}</span>
                                <div className="form-select-input form-select-input-domestic" >
                                    {generateDomesticPassenger()}
                                </div>
                            </div>
                        </div>
                    </UbtBoundary>

                </div>
            )
    }
    return (
        <div>
            {
                domestic ? getDomesticPassenger() : getPassenger()
            }
        </div>
    )
}


export default PassengerCount
