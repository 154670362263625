export const UED = {
    title: {
        margin: '20px 0 0',
        textAlign: 'center'
    },
    popupMargin: {
        margin: '30px 10px',
        verticalAlign: 'top',
        textAlign: 'left'
    },
    back: {
        display: 'inline',
        padding: '8px 33px',
        marginRight: '30px'
    },
    order: {
        display: 'inline',
        padding: '8px 33px',
        backgroundColor: '#FFFFFF',
        color: '#398EFE',
        border: '1px solid #398EFE'
    },
    tooltipMargin: {
        margin: '30px 10px',
        verticalAlign: 'top',
        textAlign: 'left'
    },
    tooltipWithOutMargin:{
        verticalAlign: 'top',
        textAlign: 'left'
    }
}
