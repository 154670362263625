import { createSelector } from 'reselect'
import {
    flightsSelector, currentSegmentSeqSelector, containsTaxSelector,
    getActiveRoundTabIndexSelector, getRoundTabModeSwitchSelector, splitRoundFlightsSelector
} from '../../result/flight/baseSelector'
import Converter from '../../../../sources/list/converter'

/**
 * 获取航司矩阵的数据源
 */
export const getAirlineMatrixSelector = createSelector(
    [flightsSelector, splitRoundFlightsSelector, currentSegmentSeqSelector, containsTaxSelector, getRoundTabModeSwitchSelector, getActiveRoundTabIndexSelector],
    (flightList, splitRoundFlights, currentSegmentSeq, containsTax, roundTabModeSwitch, activeRoundTabIndex) => {
        return roundTabModeSwitch && activeRoundTabIndex === 1
            ? Converter.getAirlineMatrix(splitRoundFlights.get('list'), activeRoundTabIndex, containsTax)
            : Converter.getAirlineMatrix(flightList.get('list'), currentSegmentSeq, containsTax)
    })

/**
 * 是否存在任何航司矩阵数据
 */
export const getIfAnyAirlineMatrixDataSelector = createSelector([getAirlineMatrixSelector], matrix => {
    return matrix && matrix.get('data') && matrix.get('data').size > 0
})
