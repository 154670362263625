import CONFIG from '../../constants/channel/config'

export const getCountriesByDCityCode = (dCityCode, hotDistrictList) => {
    let cityHotDistrictList = hotDistrictList && hotDistrictList[dCityCode],
        countries = []
    if (!cityHotDistrictList || !dCityCode) {
        return null
    }
    cityHotDistrictList.map((item) => {
        if (item.typeName == '精选地区') {
            countries = item.countryList.slice(0, 7)
        }
    })
    return countries
    // let countryCodes = configCountries.map(country => country.areaCode)
    // if (!cityHotDistrictList || !dCityCode) {
    //     return null
    // } else {
    //     let backupCountries = []
    //     // 筛选出在配置表的国家code
    //         cityHotDistrictList.forEach(item => {
    //             if (item.typeName != '精选地区')
    //                 item.countryList.forEach(country => {
    //                     if (countryCodes.indexOf(country.areaCode) >= 0) {
    //                         mapCountryCode.push(country.areaCode)
    //                     } else {
    //                         backupCountries.push(country)
    //                     }
    //                 })
    //         })
    //     // 按照配置表排下筛选出的国家code的顺序
    //     countries = configCountries.filter(configCountry => {
    //         if(mapCountryCode.indexOf(configCountry.areaCode) >= 0){
    //             return configCountry
    //         }
    //     })
    //     // 不够配置的数量则后备国家补齐
    //     if (countries.length < configCountries.length && backupCountries.length) {
    //         let backupCount = configCountries.length - countries.length
    //         countries = countries.concat(backupCountries.splice(0, backupCount))
    //     }
    //     return countries
    // }
}
let defaultCity = CONFIG.defaultSearchMapDepartCity
export const getSearchMapCity = (dCity) => {
    if(!dCity) return defaultCity
    if (dCity.Code && dCity.Code.length > 3) {
        // 判定为周边，选中默认城市
        return defaultCity
    } else if (dCity.Type == '3' || dCity.type == 'airport') {
        if (!dCity.ParentCode || !dCity.Name) {
            return defaultCity
        } else {
            return {
                CityId: dCity.ParentCityId || dCity.CityId,
                Code: dCity.ParentCode,
                Name: dCity.ParentName || dCity.Name,
                TimeZone: dCity.TimeZone,
                ParentCode: "",
                text: dCity.ParentName || dCity.Name,
            }
        }
    } else {
        return dCity
    }
}
export const processDCityAndACountryCode = (dCity, aCountry, isOnlyDirect) => {
    if (!dCity || !aCountry) {
        return null
    }
    let newDCity = dCity
    let newCountry = {
        countryCode: aCountry.areaCode
    }
    if (dCity.type == 'airport') {
        newDCity[`code`] = dCity.ParentCode ? dCity.ParentCode : dCity.code
    } else {
        newDCity[`code`] = dCity.Code
    }
    return {
        dCity: newDCity,
        aCity: newCountry,
        isOnlyDirect: isOnlyDirect
    }
}

