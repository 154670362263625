/**
 * 与原生不同的是，如果新对象中某字段为空，则合并后的对象中没有此字段
 */
const assignObjectDelectIfBlank = (originalParams, newParams) => {
	Object.keys(newParams).forEach((newParamKey) => {
		if (newParams[newParamKey] && newParams[newParamKey] !== '') {
			originalParams[newParamKey] = newParams[newParamKey];
		} else if (originalParams[newParamKey] && newParams[newParamKey] === '') {
			delete originalParams[newParamKey];
		}
	});
	return originalParams;
};

export default assignObjectDelectIfBlank;
