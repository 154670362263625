import React from 'react';
import { addEventListener } from 'consolidated-events';
import OwAndRt from './OwAndRt';

class OwAndRtContainer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dCityFocus: false,
            aCityFocus: false,
            dDateFocus: null,
            aDateFocus: null,
            activeExchangeCity: false,
            addRtDateFocus: false,
            focusInputList: [],
            owADateFocus: null,
            dCityHasVal: false,
            aCityHasVal: false,
            dCityActive: null,
            aCityActive: null,
        };
        this.hasSetExpiredFocusState = false;
        this.onSetInputFocus = this.onSetInputFocus.bind(this);
        this.onSetActiveExchangeCity = this.onSetActiveExchangeCity.bind(this);
        this.onFousFormInput = this.onFousFormInput.bind(this);
        this.onClearFormInputList = this.onClearFormInputList.bind(this);
        this.onRtStartDateChange = this.onRtStartDateChange.bind(this);
        this.onRtEndDateChange = this.onRtEndDateChange.bind(this);
        this.onSetOwADateFocus = this.onSetOwADateFocus.bind(this);
        this.onSetACityActive = this.onSetACityActive.bind(this);
        this.onSetDCityActive = this.onSetDCityActive.bind(this);
    }
    componentDidMount() {
        // this.onFousFormInput()
        let thiz = this;
        let inputs = document.getElementById('searchForm').querySelectorAll('input[type="text"]');
        for (let i = 0; i < inputs.length; i++) {
            addEventListener(inputs[i], 'change', e => {
                let target = e.target;
                if (target.value) {
                    if (target.name == 'owDCity') {
                        thiz.setState({
                            dCityHasVal: true,
                        });
                    }
                    if (target.name == 'owACity') {
                        thiz.setState({
                            aCityHasVal: true,
                        });
                    }
                } else {
                    if (target.name == 'owDCity') {
                        thiz.setState({
                            dCityHasVal: false,
                        });
                    }
                    if (target.name == 'owACity') {
                        thiz.setState({
                            aCityHasVal: false,
                        });
                    }
                }
            });
        }
    }

    getHotDistrictsOfTheCityNow(departCityCode, flightWay) {
        let hotDistrictList = this.props.hotDistrictList,
            isDAirport = false,
            departCity = null;
        if (!flightWay) {
            flightWay = this.props.flightWay;
        }
        if (!departCityCode) {
            if (flightWay === 'OW') {
                departCity = this.props.selectedCity.get('owDCity') || null;
            } else if (flightWay === 'RT') {
                departCity = this.props.selectedCity.get('rtDCity') || null;
            }
            isDAirport = departCity && departCity.get('type') ? departCity.get('type') === 'airport' : false;
            departCityCode = departCity && departCity.get('Code') ? departCity.get('Code') : '';
            if (isDAirport) {
                departCityCode = departCity.get('ParentCode') ? departCity.get('ParentCode') : '';
            }
        }
        departCityCode = departCityCode.toUpperCase();

        return hotDistrictList && hotDistrictList[departCityCode] ? hotDistrictList[departCityCode] : [];
    }
    hasHotDistrictOfTheDepartCityNow(departCityCode, arrivalDistrictAreaCode, flightWay) {
        let hasDistrict = false;
        if (!arrivalDistrictAreaCode) {
            arrivalDistrictAreaCode = this.props.arrivalDistrict.areaCode;
        }
        let districtOfDeaprtCity = this.getHotDistrictsOfTheCityNow(departCityCode, flightWay);
        districtOfDeaprtCity.forEach(item => {
            item.countryList.forEach(country => {
                if (country.areaCode == arrivalDistrictAreaCode) {
                    hasDistrict = true;
                    return false;
                }
            });
            if (hasDistrict) {
                return false;
            }
        });
        return hasDistrict;
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.flightWay !== this.props.flightWay && nextProps.flightWay != 'MT') {
            if (this.props.isDistrictSearch) {
                if (!this.hasHotDistrictOfTheDepartCityNow('', '', nextProps.flightWay)) {
                    this.props.changeToDistrictSwitch(false);
                    this.props.clearPoiItem('owACity'); // 该方法可以将单程往返一同清除
                }
            }
        }

        if (nextProps.flightWay !== this.props.flightWay) {
            this.onClearFormInputList();
        }
    }
    onSetACityActive(active) {
        this.onSetDCityActive(null);
        this.setState({
            aCityActive: active,
        });
    }
    onSetDCityActive(active) {
        this.setState({
            dCityActive: active,
        });
    }
    onSetOwADateFocus(focus) {
        this.props.selectFlightWay('RT', { userClick: false });
        this.props.onAddRtDate && this.props.onAddRtDate(true);
        this.setState({
            owADateFocus: focus,
        });
        this.setState({
            addRtDateFocus: focus,
        });
    }
    onSetActiveExchangeCity(value) {
        this.setState({
            activeExchangeCity: value,
        });
    }
    onSetInputFocus(name, value) {
        let states = {
            dCityFocus: false,
            aCityFocus: false,
            dDateFocus: null,
            aDateFocus: null,
        };
        states[`${name}Focus`] = value;
        this.setState(states);
    }
    onClearFormInputList() {
        this.setState({
            focusInputList: [],
        });
    }
    onRtStartDateChange(m, nextInput, isLowPrice, isLowestPrice) {
        this.props.onChangeOwStartDate(m, nextInput, isLowPrice, isLowestPrice);
        if (this.props.flightWay == 'RT' && !m.isAssist) {
            this.onSetInputFocus('aDate', true);
        }
    }
    onRtEndDateChange(m, nextInput, isLowPrice, isLowestPrice) {
        this.props.onChangeOwEndDate(m, nextInput, isLowPrice, isLowestPrice);
        // 非无障碍
        if (!m.isAssist) {
            if (this.props.flightWay == 'RT') {
                this.onSetInputFocus('aDate', false);
            }
        }
    }
    onFousFormInput(inputName, focus) {
        if (inputName && focus == true) {
            let newState = {};
            if (inputName == 'dCity') {
                newState = {
                    focusInputList: ['dCity'],
                };
            } else {
                this.onSetInputFocus(inputName, focus);
                this.state.focusInputList.push(inputName);
                newState = {
                    focusInputList: this.state.focusInputList,
                };
            }

            newState[`${inputName}Focus`] = true;
            this.setState(newState);
        } else if (inputName && focus == false) {
            // 失去焦点 ？？
            let newState = {};
            this.onSetInputFocus(inputName, focus);
            newState[`${inputName}Focus`] = false;
            this.setState(newState);
            if (inputName == 'dDate') {
                this.onSetACityActive(null);
            }
            if (inputName == 'aDate') {
                this.onSetOwADateFocus(false);
                // 确保日期都更新后触发
                // setTimeout(() => {
                //     this.props.setRtADateHide && this.props.setRtADateHide(true);
                // }, 100);
            }
        }
    }

    render() {
        return (
            <OwAndRt
                {...this.props}
                {...this.state}
                onFetchLowPrice={this.props.fetchLowPrice}
                hotCountryList={[]}
                getHotDistrictsOfTheCityNow={this.getHotDistrictsOfTheCityNow.bind(this)}
                hasHotDistrictOfTheDepartCityNow={this.hasHotDistrictOfTheDepartCityNow.bind(this)}
                onSetInputFocus={this.onSetInputFocus}
                onSetActiveExchangeCity={this.onSetActiveExchangeCity}
                onFousFormInput={this.onFousFormInput}
                onClearFormInputList={this.onClearFormInputList}
                onRtStartDateChange={this.onRtStartDateChange}
                onRtEndDateChange={this.onRtEndDateChange}
                onSetOwADateFocus={this.onSetOwADateFocus}
                onSetDCityActive={this.onSetDCityActive}
                onSetACityActive={this.onSetACityActive}
            />
        );
    }
}

export default OwAndRtContainer;
