import Immutable from 'immutable';
import * as types from '../../constants/list/actionTypes/popups.js';
import CONFIG_URL from '../../constants/list/api';
import { fetchPost } from '../common';

export const genFlightComfort = ({ comfort, cacheKey }) => ({ type: types.POPUP_FLIGHT_COMFORT, comfort, cacheKey });
export const genFlightInfoCardComfort = ({ comfort, cacheKey }) => ({ type: types.POPUP_FLIGHT_INFO_CARD_COMFORT, comfort, cacheKey });
export const genTransitVisaRemarkList = transitVisa => ({ type: types.POPUP_TRANSIT_VISA_REMARK_CLIST, transitVisa });
export const genVisaLuggageDirectInfo = info => ({ type: types.POPUP_VISA_LUGGAGE_DIRECT_INFO, info });
export const genClearTransitVisaRemarkList = () => ({ type: types.CLEAR_TRANSIT_VISA_REMARK_LIST });
export const genSetStayTimeoutFlag = stayTimeoutFlag => ({ type: types.STAY_TIME_OUT_FLAG, stayTimeoutFlag });

/**
 * 航班卡片舒适度缓存
 */
let flightComfortCache = {};

let flightComfortCacheV2 = {};
/**
 * 航班卡片舒适度缓存
 */
let flightInfoCache = {};

/**
 * 航班信息浮层过境签说明信息缓存
 */
let transitVisaRemarkListCache = {};

export const getFlightComfortPromise = (params, cacheKey, index) => {
    return new Promise((resolve, _reject) => {
        if (flightComfortCacheV2[cacheKey] && flightComfortCacheV2[cacheKey][index]) {
            resolve(flightComfortCacheV2[cacheKey][index]);
        } else {
            if (!flightComfortCacheV2[cacheKey]) {
                flightComfortCacheV2[cacheKey] = [];
            }
            fetchPost(CONFIG_URL.getFlightComfort, params)
                .then(res => (res && res.data) || {})
                .then(json => {
                    if (json.data === null) {
                        json.data = [];
                    }
                    if (!flightComfortCacheV2[cacheKey]) flightComfortCacheV2[cacheKey] = [];
                    flightComfortCacheV2[cacheKey][index] = json.data;
                    resolve(flightComfortCacheV2[cacheKey][index]);
                });
        }
    });
};

export const getFlightComfort = (params, cacheKey) => dispatch => {
    if (flightComfortCache[cacheKey]) {
        dispatch(genFlightComfort({ comfort: flightComfortCache[cacheKey], cacheKey }));
    } else {
        dispatch(fetchFlightComfort(params, cacheKey));
    }
};

export const setEmptyFlightComfort = cacheKey => dispatch => {
    dispatch(genFlightComfort({ comfort: Immutable.Map(), cacheKey }));
};

// 航班舒适度查询接口
export const fetchFlightComfort = (params, cacheKey) => dispatch => {
    return fetchPost(CONFIG_URL.getFlightComfort, params)
        .then(res => (res && res.data) || {})
        .then(json => {
            if (!json.data) {
                json.data = {};
            }
            flightComfortCache[cacheKey] = json.data;
            dispatch(genFlightComfort({ comfort: json.data, cacheKey }));
        });
};

export const getFlightInfoCardComfort = (params, cacheKey) => dispatch => {
    if (flightInfoCache[cacheKey]) {
        dispatch(genFlightInfoCardComfort({ comfort: flightInfoCache[cacheKey], cacheKey }));
    } else {
        dispatch(fetchFlightInfoCardComfort(params, cacheKey));
    }
};

export const getTransitVisaRemarkList = (params, cacheKey) => dispatch => {
    if (transitVisaRemarkListCache[cacheKey]) {
        dispatch(genTransitVisaRemarkList(transitVisaRemarkListCache[cacheKey]));
    } else {
        dispatch(fetchTransitVisaRemarkList(params, cacheKey));
    }
};

// 查询航班信息浮层舒适度信息
export const fetchFlightInfoCardComfort = (params, cacheKey) => dispatch => {
    return fetchPost(CONFIG_URL.getFlightInfoCardComfort, params)
        .then(res => (res && res.data) || {})
        .then(json => {
            if (!json.data) {
                json.data = {};
            }

            flightInfoCache[cacheKey] = json.data;
            dispatch(genFlightInfoCardComfort({ comfort: json.data, cacheKey }));
        });
};

// 查询航班信息浮层过境签说明信息
export const fetchTransitVisaRemarkList = (params, cacheKey) => dispatch => {
    return fetchPost(CONFIG_URL.getTransitVisaRemarkList, params)
        .then(res => (res && res.data) || {})
        .then(json => {
            if (json.data === null) {
                json.data = [];
            }

            transitVisaRemarkListCache[cacheKey] = json.data;
            dispatch(genTransitVisaRemarkList(json.data));
        });
};
