import getIfFlightAsLowPrice from '../getIfFlightAsLowPrice';

/**
 * 获取航班所有出发/到达机场信息
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 */
const getAllAirportOfFlights = (flights, currentSegmentSeq) => {
	let departAirports = {},
		arrivalAirports = {};

	flights.forEach((flight) => {
		let currentSegment = flight.getIn(['flightSegments', currentSegmentSeq]),
			departureAirportCode = currentSegment.get('departureAirportCode'),
			arrivalAirportCode = currentSegment.get('arrivalAirportCode'),
			minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
			minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']);

		let departAirport = departAirports[departureAirportCode],
			arrivalAirport = arrivalAirports[arrivalAirportCode];

		if (!departAirport || !arrivalAirport) {
			let currentSegment = flight.getIn(['flightSegments', currentSegmentSeq]),
				departureAirportName = currentSegment.get('departureAirportName'),
				arrivalAirportName = currentSegment.get('arrivalAirportName'),
				departureNearbyAirportDistance = currentSegment.get('departureNearbyAirportDistance'),
				departureNearbyAirportName = currentSegment.get('departureNearbyAirportName'),
				arrivalNearbyAirportDistance = currentSegment.get('arrivalNearbyAirportDistance'),
				arrivalNearbyAirportName = currentSegment.get('arrivalNearbyAirportName'),
				defaultAirport = {
					departureAirportCode,
					departureAirportName,
					arrivalAirportCode,
					arrivalAirportName,
					departureNearbyAirportDistance,
					departureNearbyAirportName,
					arrivalNearbyAirportDistance,
					arrivalNearbyAirportName,
					minAvgPriceWithTax: Infinity,
					minAvgPriceWithoutTax: Infinity,
				};

			if (!departAirport) {
				departAirport = departAirports[departureAirportCode] = Object.assign({}, defaultAirport);
			}

			if (!arrivalAirport) {
				arrivalAirport = arrivalAirports[arrivalAirportCode] = Object.assign({}, defaultAirport);
			}
		}

		if (getIfFlightAsLowPrice(flight)) {
			if (minAvgPriceWithTax < departAirport.minAvgPriceWithTax) {
				departAirport.minAvgPriceWithTax = minAvgPriceWithTax;
			}

			if (minAvgPriceWithoutTax < departAirport.minAvgPriceWithoutTax) {
				departAirport.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
			}

			if (minAvgPriceWithTax < arrivalAirport.minAvgPriceWithTax) {
				arrivalAirport.minAvgPriceWithTax = minAvgPriceWithTax;
			}

			if (minAvgPriceWithoutTax < arrivalAirport.minAvgPriceWithoutTax) {
				arrivalAirport.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
			}
		}
	});

	return {
		departure: departAirports,
		arrival: arrivalAirports,
	};
};

export default getAllAirportOfFlights;
