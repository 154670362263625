import { ubtDevTrace } from '@/src/sources/common/lightUbt';

let firstRequestSearch = true;

const signature = (data, CAPTCHA_LOOP_INTERVAL_TIME, CAPTCHA_LOOP_INTERVAL_TIMES) => {
	if (typeof window.signature === 'function') {
		// 初始时就挂载上了
		ubtDevTrace('hotel_captcha_load_when_init', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
		if (firstRequestSearch) {
			ubtDevTrace('hotel_captcha_load_when_init_v2', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
			firstRequestSearch = false;
		}
		return window.signature(data);
	}
	let counter = 0;
	const checkSignature = (resolve) => {
		if (typeof window.signature === 'function') {
			// 等待了多少时间后 挂载上的
			ubtDevTrace('hotel_captcha_load_after_try', {
				time: counter * CAPTCHA_LOOP_INTERVAL_TIME,
				counter,
				interval: CAPTCHA_LOOP_INTERVAL_TIME,
			});
			if (firstRequestSearch) {
				ubtDevTrace('hotel_captcha_load_after_try_v2', {
					time: counter * CAPTCHA_LOOP_INTERVAL_TIME,
					counter,
					interval: CAPTCHA_LOOP_INTERVAL_TIME,
				});
				firstRequestSearch = false;
			}
			return resolve(window.signature(data));
		} else {
			counter++;
			if (counter > CAPTCHA_LOOP_INTERVAL_TIMES) {
				if (firstRequestSearch) {
					ubtDevTrace('hotel_captcha_unload_after_try_v2', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
					firstRequestSearch = false;
				}
				ubtDevTrace('hotel_captcha_unload_after_try', { interval: CAPTCHA_LOOP_INTERVAL_TIME });
				resolve('');
			} else {
				setTimeout(checkSignature, CAPTCHA_LOOP_INTERVAL_TIME, resolve);
			}
		}
	};

	return new Promise(checkSignature);
};

export default signature;
