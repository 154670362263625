
// 频道首页 往返特惠航线
let airlineSelectMap = new Map()

const makeMap = (destMap, keyArray, valArray) => {
  let len = keyArray.length
  if (len !== valArray.length)
    return
  for (let i = 0; i < len; i++) {
    destMap.set(`${keyArray[i]}`, valArray[i])
  }
}

makeMap(airlineSelectMap, [`BJS`, `SHA`, `CAN`, `HGH`, `CTU`, `SZX`, `OTHER`], [`北京`, `上海`, `广州`, `杭州`, `成都`, `深圳香港`, `其他城市`])

const airlineLocationArray = [`欧洲`, `美洲`, `东南亚`, `日韩`, `港澳台`, `其他`]
const airlineLocationNameArray = [`Europe`, `America`, `SoutheastAsia`, `JapanKorean`, `HkMacaoTw`, `Other`]
const singleAirlineLocationArray = [`香港`, `东京`, `首尔`, `曼谷`, `澳门`, `伦敦`, `法兰克福`, `洛杉矶`]
const singleAirlineLocationNameArray = [`HKG`, `TYO`, `SEL`, `BKK`, `MFM`, `LON`, `FRA`, `LAX`]

const defaultSearchMapDepartCity = {
  CityId: 2,
  Code: "SHA",
  Name: "上海",
  ParentCode: "",
  TimeZone: 480,
  text: "上海(SHA)"
}

const CONFIG = { airlineSelectMap, airlineLocationArray, airlineLocationNameArray, singleAirlineLocationArray, singleAirlineLocationNameArray, defaultSearchMapDepartCity }

export default CONFIG
