import React from 'react';
import DiffDaysSelector from './diffDaysSelector'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

export default class CalendarLowPriceHeader extends React.Component {
    constructor(props) {
        super(props)
    }
    componentDidMount() {
        let url = window.location.search
        if(url.includes('nearByDate')) {
            this.props.changeShowTrend(true)
        }
    }
    render() {
        return(
            <div className="cld-tab-title">
                <div className="cld-tabs">
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,'c_tab_trend_to_calender')}>
                    <div className={`tab ${!this.props.isTrend ? "active" : ""}`} onClick={()=>{ this.props.changeShowTrend(false) }}><i className="ico-calendar-line"></i>日历</div>
                    </UbtBoundary>
                    <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,'c_tab_calender_to_trend')}>
                    <div className={`tab ${this.props.isTrend ? "active" : ""}`} onClick={()=>{ this.props.changeShowTrend(true) }}><i className="ico-trend"></i>趋势</div>
                    </UbtBoundary>
                </div>
                {
                    this.props.flightWay === "S" ? null : (
                        <DiffDaysSelector {...this.props} />
                    )
                }

            </div>
        )
    }
}
