import React from 'react';
import moment from 'moment'
import CalendarLPMonthBody from './monthBody.js'
import Converter from '../../../../sources/list/converter'
import CONSTS from '../../../../constants/list/constConfig'
import SelectMonth from './selectMonth'

class RoundCalendarLPMonth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            showIntervalDay: false,
            nextDisabled:false,
            prevDisabled:false
        }
        this.getUrl = this.getUrl.bind(this)
        this.selectMonth = this.selectMonth.bind(this)
        this.toggleIntervalDays = this.toggleIntervalDays.bind(this);
        this.resetButtonDisable = this.resetButtonDisable.bind(this)
    }
    toggleIntervalDays() {
        this.setState({ showIntervalDay: !this.state.showIntervalDay })
    }
    getUrl(depdate, rtdate) {
        let url = window.location.href.replace(/depdate=([0-9]|-)+_([0-9]|-)+/, `depdate=${depdate}_${rtdate}`)

        return Converter.batchUpdateUrlQueryString(url, this.props.toRememberActiveKeys)
    }
    getToolTip(date1, date2) {
        return (
            <div className="tooltip">
                <div className="tooltip-content">
                    <div>
                        <p>{`去程 ${date1.split("-")[1]}/${date1.split("-")[2]}`}</p>
                        <p>{`返程 ${date2.split("-")[1]}/${date2.split("-")[2]}`}</p>
                    </div>
                </div>
            </div>
        )
    }
    resetButtonDisable(currentMonthIndex,cb) {
        let thisMonth = moment().add(currentMonthIndex, "months").month() + 1,
            thisYear = moment().add(currentMonthIndex, "months").year(),
            nextDisabled = moment().add(CONSTS.RECOMMEND.CALENDAR_LP_ROUNG_DAYSLIMIT, "days").subtract(this.props.diffDays, "days").month() === thisMonth - 1
            && moment().add(CONSTS.RECOMMEND.CALENDAR_LP_ROUNG_DAYSLIMIT, "days").subtract(this.props.diffDays, "days").year() === thisYear,
            prevDisabled = moment().month() === thisMonth - 1 && moment().year() === thisYear;
        this.setState({
            nextDisabled,
            prevDisabled
        } , ()=>{
            cb && cb(currentMonthIndex)
        })
    }
    componentWillMount() {
        this.resetButtonDisable(this.props.currentMonthIndex)
    }
    selectMonth(currentMonthIndex) {
        this.resetButtonDisable(currentMonthIndex,this.props.changeCurrentMonth)
    }
    render() {
        let { currentMonthIndex } = this.props;
        //结构基本与单程类似
        
        return (
            <div className="cld-body">
                <div className={`cld-tbl cld-new`}>
                    <SelectMonth currentMonthIndex={currentMonthIndex} {...this.state} selectMonth={this.selectMonth}/>
                    <CalendarLPMonthBody  calendarData={this.props.roundCalendar} searchCriteria={this.props.searchCriteria} getUrl={this.getUrl} getToolTip={this.getToolTip} />
                </div>
            </div>
        )
    }
}

export default RoundCalendarLPMonth;