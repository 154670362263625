import React from 'react'
import Immutable from 'immutable'
import TooltipsImg from '../common/tooltipsImg'

class PriceInvoice extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            Tinvoice: false,
            Finvoice: false,
            Einvoice: false,
            ImgBottom: "inherit",
            ImgLeft: "inherit",
            ImgTop: "inherit"
        }

        this.toogleShowImg = this.toogleShowImg.bind(this)
    }

    toogleShowImg(show, invoiceType, icoKey) {
        let iconRect = document.getElementById(icoKey).getBoundingClientRect();
        let iconTop = iconRect.top;
        let iconOffsetBottom = document.body.scrollHeight - (document.documentElement.scrollTop || document.body.scrollTop) - iconTop
        let avaliableWidthOfInvoiceImg = document.body.clientWidth - document.getElementById(icoKey).getBoundingClientRect().left

        if (parseInt(iconOffsetBottom) < 800) {
            this.setState({ ImgBottom: 0 })
        }

        if (avaliableWidthOfInvoiceImg <= 500) {
            this.setState({ ImgLeft: -300, ImgTop: 20 })
        } else {
            this.setState({ ImgLeft: 'inherit', ImgTop: 'inherit' })
        }

        this.setState({ [`${invoiceType}invoice`]: show })
    }

    render() {
        const { priceUnitList, flightWayEnum, allSegmentList, invoiceTypeEnum, index } = this.props
        let flightWay = flightWayEnum.key,
            mergePriceUnitList = [],
            segmentNos = []

        for (let i = 0; i < allSegmentList.length; i++) {
            segmentNos.push(i)
        }

        invoiceTypeEnum.map(i => {
            let priceUnit = priceUnitList.filter(p => p.get('invoiceType') == i.key),
                flightSeatList = []

            if (priceUnit.size == 1) {
                mergePriceUnitList.push(priceUnit.get(0))
            }
            else {
                // 合并相同报销凭证的座位信息
                priceUnit.map(p => p.get('flightSeatList').map(seat => flightSeatList.push(seat)))
                mergePriceUnitList.push(Immutable.fromJS({
                    invoiceType: priceUnit.getIn([0, 'invoiceType']),
                    flightSeatList: flightSeatList
                }))
            }
        })

        let getSegementDetail = (flightSeatList, number) => {
            let segmentNoName = flightWay == 'D' ? '去返'.charAt(number) : `第${'一二三四五六'.charAt(number)}`,
                currentflightSeat = flightSeatList.filter(f => f.get('segmentNo') == number + 1)

            return currentflightSeat.map((seat, i) => {
                let segmentNo = seat.get('segmentNo') - 1,
                    sequenceNo = seat.get('sequenceNo') - 1,
                    flight = allSegmentList[segmentNo].getIn(['flightList', sequenceNo]),
                    departureCityName = flight.get('departureCityName'),
                    arrivalCityName = flight.get('arrivalCityName')

                return (
                    <div className="trip" key={`${segmentNo}_${sequenceNo}`}>
                        {flightWay == 'S' ? null : i == 0 ? <span className="trip-id">{segmentNoName}程</span> : <span className="trip-id"></span>}
                        <span className="trip-cities">
                            <span className="city">{departureCityName}</span>-<span className="city">{arrivalCityName}</span>
                        </span>
                    </div>
                )

            })

        }

        let getInvoiceType = (invoiceType, key) => {
            let invoice = invoiceTypeEnum.filter(i => i.key == invoiceType).get(0),
                invoiceTitle = invoice.value.title,
                invoiceLink = invoice.value.link,
                invoiceKey = invoice.key,
                imgStyle = {
                    display: this.state[`${invoiceKey}invoice`] ? 'block' : 'none',
                    bottom: this.state.ImgBottom,
                    left: this.state.ImgLeft,
                    top: this.state.ImgTop
                }

            return (
                <div className="invoice-type" key={key}>
                    {invoiceTitle}
                    <i className="ico-info" id={`${invoiceTitle}_${index}_${key}`}
                        onMouseEnter={() => this.toogleShowImg(true, invoiceKey, `${invoiceTitle}_${index}_${key}`)}
                        onMouseLeave={() => this.toogleShowImg(false, invoiceKey, `${invoiceTitle}_${index}_${key}`)}>
                        <div style={{ position: "relative" }}><TooltipsImg link={invoiceLink} style={imgStyle} /></div>
                    </i>
                </div>
            )

        }

        return (
            <div className="tooltip tooltip-multitrip">
                <div className="tooltip-content">
                    {mergePriceUnitList.map((priceUnit, key) => {
                        return (
                            <div className="trip-info" key={key}>
                                <div className="trip-detail">
                                    {segmentNos.map(number => getSegementDetail(priceUnit.get('flightSeatList'), number))}
                                </div>
                                {getInvoiceType(priceUnit.get('invoiceType'), key)}
                            </div>)
                    })}
                </div>
            </div>
        )

    }

}
export default PriceInvoice