import qs from 'qs';
import moment from 'moment';
import Immutable, { fromJS, List, Map, merge } from 'immutable';
import * as LIST_TYPES from '@/src/constants/list/actionTypes';
import * as HotDistrictTypes from '@/src/constants/common/actionTypes/hotDistrict';
import { FilterOptionRetrieverFactory } from '@/src/containers/list/sidebar/filterbar/filterOptionRetriever';
import * as CHANNEL_TYPES from '@/src/constants/channel/actionTypes';
import * as COMMON_TYPES from '@/src/constants/common/actionTypes';
import { MOUSE_ACTION_SOURCE } from '@/src/constants/channel/enum';
import { getFilterTypeDefaultActiveKeys, getInitActiveKeys, getInitFilterTypes } from '@/src/sources/list/filterInit';
import { getModifyTopInfo } from '@/src/components/list/top/search';
import { getMtSegList, setSearchResultToStore } from './listHelper';
import { setLatestSearchHistoryData, setWithExpiry, getWithExpiry } from '@/src/actions/common/searchDataStorageHelper';
import {
	COUNTRY_SOURCE,
	FILTER_GROUP_AIRLINE,
	getFilterTypes,
	HOT_COUNTRY_STAYDAYS,
	NOTICE_LEVEL,
} from '@/src/constants/list/enums';
import { getActiveKeysOfGroupMapSelector } from '@/src/containers/list/sidebar/filterbar/filterbarSelector';
import { LIST_UBT_KEY_TYPES } from '@/src/ubt.v2/list';
import { sortTypeProvider } from '@/src/constants/list/enums/sortTypeProvider';
import CONSTS from '@/src/constants/list/constConfig';
import { getSplitRoundFlightsSwitchSelector } from '@/src/containers/list/result/flight/baseSelector';
import { SPLIT_ROUND_INCLUDE_KEYS } from '@/src/sources/list/splitRoundIncludeKeys';
import { getIfSplitRoundFlightsFromState } from '@/src/containers/list/result/flight/getIfSplitRoundFlights';
import { EMPTY_MAP } from '@/src/sources/common/consts';
import * as searchBoxTypes from '@/src/constants/common/actionTypes/searchBox';
import { FILTER_CLASS_GRADE_TYPE } from '@/src/constants/common/enum/filter';
import { getIfScopeInternational } from '@/src/sources/list/scope';
import { getLowPriceKey } from '@/src/sources/common/lowPriceHelper';
import { performanceTrace } from '@/src/utils/performanceTrace';
import poiHandler from '../common/poi';

const DEFAULT_CLASSGRADE = FILTER_CLASS_GRADE_TYPE['Y_S'].key || 'Y_S';
const TICKET_REQUEST_DEFAULT_CLASSGRADE = [FILTER_CLASS_GRADE_TYPE['Y'].key] || ['Y'];

const getPathForSplitRound = (splitRound, state, ...rest) => [
	...(splitRound && getSplitRoundFlightsSwitchSelector(state) ? ['splitRound'] : []),
	...rest,
];

const getActiveKeysPathForSplitRound = (splitRound, state) =>
	getPathForSplitRound(splitRound, state, 'filters', 'activeKeys');

const rawState = {
	//不同组件通用数据
	common: {
		workingDays: {}, //工作日数据（来自去哪儿节假日接口）
	},
	//过滤条件状态 round_back
	filters: {
		hasRestoreFromUrl: false, // 只在第一次加载航班时候恢复筛选，比如直飞 directflight=1
		showAirlineMatrix: false,
		types: getInitFilterTypes(),
		activeKeys: getInitActiveKeys(),
	},
	//推荐类
	recommend: {
		// 邻近航线
		adjacentAirline: [],
		directRecommendTab: {
			departList: [],
			returnList: [],
		},
		showDirectCalendar: false,
		showFilterNoResult: null,
		directCalendarDeptDate: '', //直飞日历当前选中出发返回日期
		directCalendarRetDate: '',
		directCalendarBaseDate: '', //直飞日历当前查询基准日期（即日历中心）
		directCalendarData: {
			departList: [],
			returnList: [],
		},
		openJawRecommendData: {}, //缺口程推荐数据
		openJawGroundTrafficData: {},
		//两舱推荐
		appendShowMore: '',
		cfRecommendYSShowCabin: false,
		hasCFRecommendYSCabin: false,
		expandExtraRecFlights: false, //两舱推荐，是否展开显示第二区块的推荐类航班
		//虚拟航班
		virtualFlights: {},

		multiCheapest: [],

		// 邻近航线直飞推荐
		adjacentAirlineDirect: [],
		//推荐实验版本
		recommendVersion: 'B',
		// 单程推荐往返
		owRecommendRt: {
			endDate: '',
			hasLoadLowPriceData: false,
			lowPriceData: null,
			closeTip: false,
			userAttr: false,
		},
	},
	//统一推荐接口{ floatSidBarTitle: '', flights: []}
	allRecommendFlights: [],

	// 航班信息 round_back
	flights: {
		intlPrice: {}, // 国际中间页反查
		list: [],
		selected: {
			selectedFlights: [],
			flights: null,
			routeSearchCriteria: [],
			existSuperFlyer: false,
		},
		currentSegmentSeq: 0,
		isFinished: false,
		existDirectReturnTrip: false, // 往返返程是否有直飞
		giftIds: null,
		agreementIds: null,
		channelId: null,
		subChannelId: null,
		/**
		 * 是否需要弹出验证框
		 */
		showAuthCode: false,
	},
	// 舒适度共享信息
	comfortTags: [],
	//浮层
	popups: {
		flightComfort: {},
		flightInfoCardComfort: {},
		transitVisaRemarkList: [],
		visaLuggageDirectInfo: {},
		stayTimeoutFlag: 0,
	},
	//搜索框
	search: {
		searchProgress: 0,
		lastSearchTime: '',
		globalSearchCriteria: {},
		prevCond: null,
		flightWay: 'OW',
		passenger: {
			adtCount: 1,
			chiCount: 0,
			infCount: 0,
		},
		// 仓等
		classGrade: DEFAULT_CLASSGRADE,
		isShowSearchHistoryList: false,
		//oneway, roundtrip date info
		owDDate: null,
		owADate: null,
		rtDDate: null,
		rtADate: null,
		//search verification
		showValidateErrorTip: false,
		showValidateWarnTip: false,
		dDateError: '',
		aDateError: '',
		mtError: {},
		//POI
		poiKey: {},
		poiResult: {},
		poiListAdditionalInfo: {},
		lastPoi: [],
		poiCache: {},
		showCityPicker: {},
		//multi trip date, city and oneway, roundtrip city info
		selectedCity: {
			mtDCity1: null,
			mtACity1: null,
			mtStartDate1: null,
			mtDCity2: null,
			mtACity2: null,
			mtStartDate2: null,
			// mtDCity3: null,
			// mtACity3: null,
			// mtStartDate3: null
		},

		isNeedFocusSecond: false,

		canAddMoreMtSeg: true,

		//搜索历史
		searchHistory: [],
		focusNextInput: '',
		lastModifiedPassengerType: '',
		//低价日历
		lowestPrice: {},
		lowPrice: {},
		lowRPrice: {},
		lowestRPrice: {},
		// 精选地区低价日历
		countryLowestPrice: {},
		countryLowPrice: {},
		countryLowestRPrice: {},
		countryLowRPrice: {},
		// 子机场推荐查看显示城市
		showCityText: false,
		//因为低价日历、各类推荐都会调用无刷新重查，重查同时改变查询框中对应store信息，
		//而查询框中信息的修改会触发改版搜索框的自动重查，所以增加该字段，无刷新重查前先将该字段置为true，重查完成后置为false
		skipAutoReSearch: false,
		searchCriteriaToken: '',
		flightSameCityData: false,
	},
	//热门国家
	hotCountry: {
		arrivalCountry: '', //到达国家
		countrySource: COUNTRY_SOURCE.SEARCH,
		isHotSearch: false, //是否热门国家搜索
		isFreeDept: false, //是否自由出发
		floatDays: 0, //浮动天数
		stayDays: HOT_COUNTRY_STAYDAYS.FREE, //游玩天数
		currentMonthIndex: 0, //当前日历展示月份索引
		selectedDayCalendar: '', //日历选中的出发日期（自由选择）
		inputString: '', //输入框中内容
		selectedTimeItems: [
			{
				text: '任何时间',
				dateRange: [],
				key: 0,
			},
		], //选中的出发时间（非自由）,text是选中项的文字信息（字符串），用于更新页面显示;dateRange保存的是start-end形式保存的时间数组，用于传值,key决定在input中的显示顺序
	},
	// 精选地区
	hotDistrict: {
		isDistrictSearch: '',
		arrivalDistrict: {
			areaCode: '',
			areaName: '',
		},
	},
	//机票需求单
	ticketRequest: {
		flightWay: 'MT',
		selectedCity: {
			mtDCity1: null,
			mtACity1: null,
			mtStartDate1: null,
			mtDCity2: null,
			mtACity2: null,
			mtStartDate2: null,
			// mtDCity3: null,
			// mtACity3: null,
			// mtStartDate3: null
		},
		adultCnt: 1,
		childCnt: 0,
		infantCnt: 0,
		//search verification
		showValidateErrorTip: false,
		mtError: {},
		//POI
		poiKey: {},
		poiResult: {},
		poiListAdditionalInfo: {},
		lastPoi: [],
		poiCache: {},
		showCityPicker: {},
		showAreaCode: false,
		classGrade: [TICKET_REQUEST_DEFAULT_CLASSGRADE],
		//focus第二程
		isNeedFocusSecond: false,
		canAddMoreMtSeg: true,
		contact: {
			name: '',
			areaCode: '86',
			areaText: '中国大陆',
			telephone: '',
			email: '',
			note: '',
		},
		contactError: {
			nameError: '',
			telephoneError: '',
			emailError: '',
		},
		countryCodeList: [],
	},

	//公告
	notice: {
		noticeList: [],
		thirdClassShow: true,
		firstClassPopShow: true,
		noticeSegments: [],
	},

	//广告：
	ads: {
		adList: [],
		adPopupShow: false,
	},
	//低价日历
	calendar_lowprice: {
		showCalendarBody: false, //日历是否展开
		isMonthView: false, //是否为月视图（往返时）
		isTrend: false, //是否展示趋势
		lowPriceDatas: {}, //低价数据
		currentStart: 0, //展开前滑动日历起点（以tab个数计）
		currentMonthIndex: 0, //月视图翻页起点（以月份计算）
		roundDiffDays: 0, //往返时间差（只用于展开后下拉框切换，按天数计算）
		currentWeekIndex: 0, //周视图翻页起点（以天计算）
		showMonth: true, //
		lowPriceChannelToday: {},
		lowerPriceTrendSwitch: true, //趋势开关
		lowerPriceNewStyleSwitch: true, //新样式开关
		lowerPriceSwitchStatus: 0, //是否获取开关 0：未获取 1：正在获取 2：获取完成
		lowPriceTodayByInterface: {
			price: 0,
			totalPrice: 0,
		},
	},
	//礼盒
	gift: [],
	//搜索历史
	history: {},
	containsTax: true, //是否含税
	isBuildUp: false, //是否为往返组合
	sortTypes: [], //排序集合
	// 页面底部信息
	footer: {},
	//从服务端传回的配置信息，比如ABT
	globalSwitch: {
		openFlagShipStoreSwitch: true,
		nightFlightSwitch: false,
		planeAgeSwitch: false,
		adjacentDateSwitch: false,
		mileagePointSwitch: false,
		priceMapSwitch: null,
		owRecommendRtSwitchV2: false,
		bestChoiceFlightsForceTop: false, //精选航班是否需要置顶
		splitRoundFlightsSwitch: false, //往返航班是否分屏显示，已下线，一直是false
		autoFixOnSplitRoundRouteSwitch: false, //往返分屏是否在反查时候自动吸顶
		strictStopQuickLoginSwitch: false, //列表页登陆框是否隐藏快速登录按钮，并且已经快速登录的用户要求弹窗重新登录
		defaultNewUserPriceSortSwitch: false, //新用户默认启用价格升序
		segmentCountAsPriceSortSwitch: false, //是否将中转经停次数当做价格排序的其中一个计算因素
		loadingProgressV2Switch: false,
		splitRoundMaskFlightsSwitch: false, //往返分屏是否需要给去程增加遮罩的开关
		authorizedStoreForceTopSwitch: false, //授权店置顶开关
		roundTabModeSwitch: false, //往返程显示成多tab切换模式开关
		listShowPriceNgsSwitch: false, //列表页显示NGS打分开关
		incrementShowTransVisaSwitch: false, //增值页中转过境签开关
		strictSearchWaitforPenaltyDetailSwitch: false, //严格反查之前是否要等待该运价的退改签接口返回
		adBarSwitch: false, // 是否显示广告
		canNoLoginUserBookSwitch: false, // 是否展示非会员预定入口（不是携程用户也可以登录去预定的入口）
	},
	//购票攻略
	guideLine: {
		priceChangeHistory: null, //变价历史
		pricePrediction: null, //变价预测
		travelTips: null, //旅行贴士
	},
	router: {
		key: 0, //全局使用，用于重查后页面重新渲染
		history: null, //将history存起来，以直接进行浏览器的history.push操作
		loadDate: new Date(), //页面载入时间，存起来以防页面长时间都不进行全局刷新
	},
	fixTheTop: {
		//吸顶 round_back
		fixSortBar: false,
		fixLowPriceCalendar: false,
	},
	splitRound: {
		// 对于往返分屏显示时，用来存 往返返程 相关
	},
	army: '', //伤残军警1
	giftIdList: [], //惊喜礼盒侧边栏
};

SPLIT_ROUND_INCLUDE_KEYS.forEach((splitRoundKey) => {
	rawState.splitRound[splitRoundKey] = {};

	const rawValue = rawState[splitRoundKey],
		rawValueKeys = Object.keys(rawValue);

	rawValueKeys.forEach((innerKey) => {
		const innerRawValue = rawValue[innerKey];

		if (Immutable.isImmutable(innerRawValue)) {
			rawState.splitRound[splitRoundKey][innerKey] = innerRawValue;
		} else {
			rawState.splitRound[splitRoundKey][innerKey] = JSON.parse(JSON.stringify(innerRawValue));
		}
	});
});
rawState.splitRound.flights.acitveSegmentSeq = 0;

const defaultState = Immutable.fromJS(rawState);

export default function list(state = defaultState, action) {
	// log action.type
	LIST_UBT_KEY_TYPES.COMMON_LOG.value.invoke(action, 'action');
	switch (action.type) {
		// 公告
		case LIST_TYPES.NOTICE_ON_CLOSE_THIRD_CLASS:
			return state.setIn(['notice', 'thirdClassShow'], false);
		case LIST_TYPES.NOTICE_ON_CLOSE_FIRST_CLASS_POP:
			return state.setIn(['notice', 'firstClassPopShow'], false);
		case LIST_TYPES.AD_ON_OPEN:
			return state.setIn(['ads', 'adPopupShow'], true);
		case LIST_TYPES.AD_ON_CLOSE: {
			// location.reload()
			// onResearchFlightsWithFullUrl(self.location.href, { newSkipAutoReSearch: false });
			return state.setIn(['ads', 'adPopupShow'], false);
		}
		case LIST_TYPES.NOTICE_GET_NEW_NOTICES: {
			let { segments } = action;
			action.notices.forEach((notice, index) => {
				Object.keys(NOTICE_LEVEL.toJSON()).forEach((enumKey) => {
					if (notice.noticeLevel === NOTICE_LEVEL[enumKey].value.key) {
						action.notices[index].noticeLevel = NOTICE_LEVEL[enumKey];
					}
				});
			});

			let segmentsHasChange = true;
			const ttl = 24 * 60 * 60 * 1000;
			segments.forEach((item) => {
				const { departureCityCode, arrivalCityCode } = item;
				const getSegmentsCode = getWithExpiry(`${departureCityCode}TO${arrivalCityCode}`);
				// 返回航班不一致
				segmentsHasChange =
					getSegmentsCode.departureCityCode !== departureCityCode ||
					getSegmentsCode.arrivalCityCode !== arrivalCityCode;
				setWithExpiry(`${departureCityCode}TO${arrivalCityCode}`, { departureCityCode, arrivalCityCode }, ttl);
			});

			return state
				.setIn(['notice', 'noticeList'], Immutable.fromJS(action.notices))
				.setIn(['notice', 'firstClassPopShow'], segmentsHasChange);
		}
		case LIST_TYPES.AD_GET_NEW_ADS: {
			return state.setIn(['ads', 'adList'], Immutable.fromJS(action.ads));
		}
		// 排序
		// 点击时切换排序项
		case LIST_TYPES.SORT_CLICK_ENTRY:
			return state.update('sortTypes', (sortTypes) => {
				return sortTypes.map((entry) => {
					if (entry.key === action.sortEntryKey) {
						entry.activeIndex = Math.max(1, (entry.activeIndex + 1) % entry.getStatusCount());
					} else {
						entry.activeIndex = 0;
					}

					return entry;
				});
			});
		case LIST_TYPES.SORT_V2_CLICK_ENTRY:
			return state.update('sortTypes', (sortTypes) => {
				return sortTypes.map((entry) => {
					if (entry.key === action.sortEntryKey) {
						entry.activeIndex = action.activeIndex;
					} else {
						entry.activeIndex = 0;
					}

					return entry;
				});
			});
		case LIST_TYPES.SORT_V3_CLICK_ENTRY: {
			const path = getPathForSplitRound(action.splitRound, state, 'sortTypes');

			const curActiveItem = state
				.getIn(path)
				.find((item) => item.activeIndex === action.activeIndex && item.key === action.sortEntryKey);
			if (curActiveItem) {
				return state;
			} else {
				return state.updateIn(path, (sortTypes) => {
					return sortTypes.map((entry) => {
						if (entry.key === action.sortEntryKey) {
							entry.activeIndex = action.activeIndex;
						} else {
							entry.activeIndex = 0;
						}

						return entry;
					});
				});
			}
		}
		//切换含税状态
		case LIST_TYPES.SORT_SWITCH_INCLUDETAX:
			return state.setIn(['containsTax'], !state.getIn(['containsTax']));
		//低价日历
		case LIST_TYPES.CALENDARLP_GET_LOWER_TAB_SWITCH:
			return state
				.setIn(['calendar_lowprice', 'isTrend'], action.showTrend)
				.setIn(['calendar_lowprice', 'showCalendarBody'], true);
		case LIST_TYPES.CALENDARLP_GET_LOWER_NEWSTYLE_SWITCH:
			return state
				.setIn(['calendar_lowprice', 'lowerPriceNewStyleSwitch'], action.newStyle)
				.setIn(['calendar_lowprice', 'showCalendarBody'], true);
		case LIST_TYPES.CALENDARLP_GET_LOWER_SWITCH_STATUS:
			return state.setIn(['calendar_lowprice', 'lowerPriceSwitchStatus'], action.status);
		case LIST_TYPES.CALENDARLP_SHOW_BODY: //展开收起低价日历
			return state
				.setIn(['calendar_lowprice', 'showCalendarBody'], action.isShow)
				.setIn(['fixTheTop', 'fixLowPriceCalendar'], false)
				.setIn(['fixTheTop', 'fixSortBar'], false);
		case LIST_TYPES.CALENDARLP_GET_LPDATA:
			/*更新低价信息
      格式为：
      {
          20xx-xx-xx{
              0:{totalPrice:xxxx,price:xxxx}
              ...
              6:...
          }
      }   即每个日期对应该日期后七天出发的价格数据。单程时直接为日期对应价格数据，不再是一个含有7个成员的对象
      */
			let lpData = state;
			if (action.searchCriteria.get('flightWay') === 'S') {
				action.lpdatas.forEach((item) => {
					const itemDepartDate = item.departDate?.includes('Date')
						? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD')
						: item.departDate;
					let dataItem = {};
					dataItem.price = item.transportPrice;
					dataItem.totalPrice = item.price;
					const oldItem = lpData.getIn(['calendar_lowprice', 'lowPriceDatas', itemDepartDate]);
					if (!oldItem || !oldItem.get('price') || !oldItem.get('totalPrice')) {
						lpData = lpData.setIn(
							['calendar_lowprice', 'lowPriceDatas', itemDepartDate],
							Immutable.fromJS(dataItem)
						);
					}
					if (
						itemDepartDate ===
						moment(action.searchCriteria.getIn(['flightSegments', 0, 'departureDate'])).format('YYYY-MM-DD')
					) {
						lpData = lpData.setIn(
							['calendar_lowprice', 'lowPriceTodayByInterface'],
							Immutable.fromJS(dataItem)
						);
					}
				});
			} else if (action.searchCriteria.get('flightWay') === 'D') {
				let searchedDiffDays = moment(action.searchCriteria.getIn(['flightSegments', 1, 'departureDate'])).diff(
					action.searchCriteria.getIn(['flightSegments', 0, 'departureDate']),
					'days'
				);
				action.lpdatas.forEach((item) => {
					const itemDepartDate = item.departDate?.includes('Date')
						? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD')
						: item.departDate;
					const itemReturnDate = item.returnDate?.includes('Date')
						? moment(new Date(parseInt(item.returnDate.slice(6, 19)))).format('YYYY-MM-DD')
						: item.returnDate;
					let dataItem = {},
						diffDays = moment(itemReturnDate)
							.startOf('day')
							.diff(moment(itemDepartDate).startOf('day'), 'days');
					dataItem.price = item.transportPrice;
					dataItem.totalPrice = item.price;
					const oldItem = lpData.getIn(['calendar_lowprice', 'lowPriceDatas', itemDepartDate, diffDays - 1]);
					if (!oldItem || !oldItem.get('price') || !oldItem.get('totalPrice')) {
						lpData = lpData.setIn(
							['calendar_lowprice', 'lowPriceDatas', itemDepartDate, diffDays - 1],
							Immutable.fromJS(dataItem)
						);
					}
					if (
						itemDepartDate.includes(
							moment(action.searchCriteria.getIn(['flightSegments', 0, 'departureDate'])).format(
								'YYYY-MM-DD'
							)
						) &&
						diffDays === searchedDiffDays
					) {
						lpData = lpData.setIn(
							['calendar_lowprice', 'lowPriceTodayByInterface'],
							Immutable.fromJS(dataItem)
						);
					}
				});
			}
			return lpData;
		//设置 channelId
		case LIST_TYPES.SET_CHANNEL_ID: {
			const channelIdPath = getPathForSplitRound(action.isRouteSearch, state, 'flights', 'channelId');
			const subChannelIdPath = getPathForSplitRound(action.isRouteSearch, state, 'flights', 'subChannelId');

			return state.setIn(channelIdPath, action.channelId).setIn(subChannelIdPath, action.subChannelId);
		}
		//获取工作日数据
		case COMMON_TYPES.COMMON_GET_WORKINGDAYS:
			return state.setIn(['common', 'workingDays'], Immutable.fromJS(action.workingDays));
		//设置当前列表页最低价
		case LIST_TYPES.CALENDARLP_LOWEST_PRICE_TODAY:
			if (action.flightWay === 'S' || action.flightWay === 'M') {
				//原本有价格但是某一批无数据，防止原本数据被覆盖
				let lowestPriceNow = state.getIn(['calendar_lowprice', 'lowPriceDatas', action.todayDate, 'price']),
					lowestTotalPriceNow = state.getIn([
						'calendar_lowprice',
						'lowPriceDatas',
						action.todayDate,
						'totalPrice',
					]),
					result = state;
				if (lowestPriceNow && lowestPriceNow !== 0 && !action.lowestPrice) {
					// result = result
				} else {
					result = result.setIn(
						['calendar_lowprice', 'lowPriceDatas', action.todayDate, 'price'],
						action.lowestPrice
					);
				}
				if (
					(lowestTotalPriceNow && lowestTotalPriceNow !== 0 && !action.lowestTotalPrice) ||
					(lowestTotalPriceNow && lowestTotalPriceNow < action.lowestTotalPrice && action.isRouteSearch)
				) {
					return result;
				} else {
					if (action.flightWay === 'S') {
						// 保存下页面最低价格到查询数据
						setLatestSearchHistoryData(action.lowestTotalPrice);
					}
					return result.setIn(
						['calendar_lowprice', 'lowPriceDatas', action.todayDate, 'totalPrice'],
						action.lowestTotalPrice
					);
				}
			} else if (action.flightWay === 'D') {
				let lowestPriceNow = state.getIn([
						'calendar_lowprice',
						'lowPriceDatas',
						action.todayDate,
						action.diffDays - 1,
						'price',
					]),
					lowestTotalPriceNow = state.getIn([
						'calendar_lowprice',
						'lowPriceDatas',
						action.todayDate,
						action.diffDays - 1,
						'totalPrice',
					]),
					result = state;
				if (lowestPriceNow && lowestPriceNow !== 0 && !action.lowestPrice) {
					// result = result
				} else {
					result = state.setIn(
						['calendar_lowprice', 'lowPriceDatas', action.todayDate, action.diffDays - 1, 'price'],
						action.lowestPrice
					);
				}
				if (
					(lowestTotalPriceNow && lowestTotalPriceNow !== 0 && !action.lowestTotalPrice) ||
					(lowestTotalPriceNow && lowestTotalPriceNow < action.lowestTotalPrice && action.isRouteSearch)
				) {
					return result;
				} else {
					if (state.getIn(['flights', 'currentSegmentSeq']) == '0') {
						// 保存下页面最低价格到查询数据
						setLatestSearchHistoryData(action.lowestTotalPrice);
					}
					return result.setIn(
						['calendar_lowprice', 'lowPriceDatas', action.todayDate, action.diffDays - 1, 'totalPrice'],
						action.lowestTotalPrice
					);
				}
			} else {
				return state;
			}
		case LIST_TYPES.CALENDARLP_LOWEST_PRICE_TODAY_CHANNEL:
			return state.setIn(
				['calendar_lowprice', 'lowPriceChannelToday'],
				Immutable.fromJS({
					lowestPriceChannel: action.lowestPriceChannel,
					lowestTotalPriceChannel: action.lowestTotalPriceChannel,
				})
			);
		case LIST_TYPES.CALENDARLP_CHANGE_CURRENTSTART:
			return state.setIn(['calendar_lowprice', 'currentStart'], Immutable.fromJS(action.currentStart));
		case LIST_TYPES.CALENDARLP_CHANGE_CURRENTMONTH:
			return state.setIn(['calendar_lowprice', 'currentMonthIndex'], Immutable.fromJS(action.currentMonthIndex));
		case LIST_TYPES.CALENDARLP_CHANGE_DIFFDAYS:
			return state.setIn(['calendar_lowprice', 'roundDiffDays'], Immutable.fromJS(action.diffDays));
		case LIST_TYPES.CALENDARLP_CHANGE_CURRENTWEEK:
			return state.setIn(['calendar_lowprice', 'currentWeekIndex'], Immutable.fromJS(action.currentWeekIndex));
		case LIST_TYPES.CALENDARLP_CHANGE_SHOWMONTH:
			return state.setIn(['calendar_lowprice', 'showMonth'], Immutable.fromJS(action.showMonth));
		case LIST_TYPES.CALENDARLP_CHANGE_SHOWTREND:
			return state.setIn(['calendar_lowprice', 'isTrend'], Immutable.fromJS(action.showTrend));
		case LIST_TYPES.CALENDARLP_CLOSE_DETAIL_AND_TREND:
			return state
				.setIn(['calendar_lowprice', 'showCalendarBody'], false)
				.setIn(['calendar_lowprice', 'isTrend'], false);
		//推荐
		case LIST_TYPES.ADJACENT_AIRLINE_RECOMMEND:
			return action.data ? state.setIn(['recommend', 'adjacentAirline'], Immutable.fromJS(action.data)) : state;
		case LIST_TYPES.RECOMMEND_DIRECT_TAB_DATA:
			return action.data
				? state.setIn(['recommend', 'directRecommendTab'], Immutable.fromJS(action.data))
				: state;
		case LIST_TYPES.OPEN_DIRECT_CALENDAR:
			return state.setIn(['recommend', 'showDirectCalendar'], true);
		case LIST_TYPES.CLOSE_DIRECT_CALENDAR:
			return state.setIn(['recommend', 'showDirectCalendar'], false);
		case LIST_TYPES.RECOMMEND_DIRECT_CALENDAR_DATA:
			return state.setIn(['recommend', 'directCalendarData'], Immutable.fromJS(action.data));
		case LIST_TYPES.CHANGE_DIRECT_CALENDAR_DEPDATE:
			return state.setIn(['recommend', 'directCalendarDeptDate'], Immutable.fromJS(action.depDate));
		case LIST_TYPES.CHANGE_DIRECT_CALENDAR_RETDATE:
			return state.setIn(['recommend', 'directCalendarRetDate'], Immutable.fromJS(action.retDate));
		case LIST_TYPES.CHANGE_DIRECT_CALENDAR_BASEDATE:
			return state.setIn(['recommend', 'directCalendarBaseDate'], Immutable.fromJS(action.baseDate));
		case LIST_TYPES.OPEN_JAW_RECOMMEND:
			return state.setIn(['recommend', 'openJawRecommendData'], Immutable.fromJS(action.openJawData));
		case LIST_TYPES.OPEN_JAW_GROUND_TRAFFIC:
			return state.setIn(
				['recommend', 'openJawGroundTrafficData'],
				Immutable.fromJS(action.openJawGroundTrafficData)
			);

		case LIST_TYPES.MULTI_CHEAPEST:
			return state.setIn(['recommend', 'multiCheapest'], action.cheapest);
		case LIST_TYPES.SET_ALL_RECOMMEND_FLIGHTS: {
			let recommendList = action.recommendList;
			if (recommendList && recommendList.length) {
				let newRecommendList = recommendList.map((recommend) => {
					let { title, flights, className } = recommend;
					if (title && flights) {
						return {
							title,
							className,
							flights: Immutable.fromJS(flights),
						};
					}
				});
				return state.setIn(['allRecommendFlights'], newRecommendList);
			} else {
				return state;
			}
		}
		//虚拟航班
		case LIST_TYPES.SET_VIRTUALFLIGHTS:
			// let toBeUpdated = state.getIn(['recommend', 'virtualFlights', action.seq]) || List([])
			return state.setIn(['recommend', 'virtualFlights', action.seq], action.virtualFlights);
		//两舱
		case LIST_TYPES.SET_CFRECOMMENDYS_SHOWCABIN:
			return state.setIn(['recommend', 'cfRecommendYSShowCabin'], true);
		case LIST_TYPES.SET_HAS_CF_RECOMMEND_YS:
			return state.setIn(['recommend', 'hasCFRecommendYSCabin'], action.hasCFRecommendYS);
		case LIST_TYPES.ADJACENT_AIRLINE_DIRECT_RECOMMEND:
			return state.setIn(['recommend', 'adjacentAirlineDirect'], Immutable.fromJS(action.flightList));
		//推荐
		case LIST_TYPES.RECOMMEND_VERSION:
			return state.setIn(['recommend', 'recommendVersion'], action.version);
		case LIST_TYPES.OW_RECOMMEND_RT_VERSION:
			return state.setIn(['recommend', 'owRecommendRt', 'recommendVersion'], action.version);
		case LIST_TYPES.SET_OW_RECOMMEND_RT_END_DATE:
			return state.setIn(['recommend', 'owRecommendRt', 'endDate'], action.date);
		case LIST_TYPES.SET_OW_RECOMMEND_RT_TIP_STATUS:
			return state.setIn(['recommend', 'owRecommendRt', 'closeTip'], action.close);
		case LIST_TYPES.SET_OW_RECOMMEND_RT_USER_ATTR:
			return state.setIn(['recommend', 'owRecommendRt', 'userAttr'], action.userAttr);
		//筛选
		case LIST_TYPES.SET_FILTER_CLASS_GRADE: {
			// 对于 filterV2 不存在舱等筛选框，就不用再设置了
			const existClassGradeFilter = !!state.getIn(['filters', 'activeKeys', 'CLASS_GRADE']);
			return existClassGradeFilter
				? state.setIn(
						['filters', 'activeKeys', 'CLASS_GRADE'],
						action.classGradeEnum ? Immutable.List([action.classGradeEnum]) : Immutable.List()
				  )
				: state;
		}
		case LIST_TYPES.FILTER_CLICK_ITEM: {
			// splitRound
			const { options = {} } = action;
			const { forceExcludeInGroup, splitRound } = options;

			const activeKeysPath = getActiveKeysPathForSplitRound(splitRound, state);

			let filterActiveKeys = state.getIn(activeKeysPath),
				updateFitlerActiveKeys = (filterType) => {
					return filterActiveKeys.update(filterType, (matchedActiveKeys) => {
						let filterControlType = action.filterType.value.type;
						if (filterControlType.value.alwaysKeepOne) {
							return List([action.itemKey]);
						} else {
							let keyIndex = matchedActiveKeys.findIndex((key) => key === action.itemKey);
							if (filterControlType.value.exclusiveInGroup) {
								// 点击 只看直飞 需要清掉同Group的 中转1程，中转2程
								if (keyIndex >= 0) {
									return List();
								} else {
									return List([action.itemKey]);
								}
							} else {
								if (forceExcludeInGroup) {
									// 点击 只看直飞 需要清掉同Group的 中转1程，中转2程
									const checkedOnlyDirectFlight =
										keyIndex >= 0 &&
										!matchedActiveKeys.find((item) => item.key === 'ONE' || item.key === 'MORE');
									if (checkedOnlyDirectFlight) {
										return List();
									} else {
										return List([action.itemKey]);
									}
								} else {
									if (keyIndex >= 0) {
										return matchedActiveKeys.delete(keyIndex);
									} else {
										return matchedActiveKeys.push(action.itemKey);
									}
								}
							}
						}
					});
				};

			filterActiveKeys = updateFitlerActiveKeys(action.filterType.key);

			return state.setIn(activeKeysPath, filterActiveKeys);
		}
		case LIST_TYPES.FILTER_CLICK_LINKED_ITEM: {
			// splitRound
			const { options = {} } = action;
			const { splitRound } = options;

			const activeKeysPath = getActiveKeysPathForSplitRound(splitRound, state);
			const showAirlineMatrixPath = getPathForSplitRound(splitRound, state, 'filters', 'showAirlineMatrix');

			let filterActiveKeys_LinkedItem = state
				.getIn(activeKeysPath)
				.update(action.filterType.key, (_matchedActiveKeys) => {
					return Immutable.fromJS(action.activeKeys);
				});

			let stateAfterSetActiveKeys = state.setIn(activeKeysPath, filterActiveKeys_LinkedItem);

			let groupToActiveKeys = getActiveKeysOfGroupMapSelector(Immutable.Map({ list: stateAfterSetActiveKeys })),
				unionActiveKeys = groupToActiveKeys.get(FILTER_GROUP_AIRLINE.UNION),
				airlineActiveKeys = groupToActiveKeys.get(FILTER_GROUP_AIRLINE.AIRLINE);

			if (unionActiveKeys.size >= 1 || airlineActiveKeys.size >= 2) {
				return stateAfterSetActiveKeys.setIn(showAirlineMatrixPath, true);
			} else {
				return stateAfterSetActiveKeys;
			}
		}
		case LIST_TYPES.FILTER_CLICK_REMOVE: {
			let filterActiveKeys2 = state.getIn(['filters', 'activeKeys']),
				removeActiveKeys = (filterType) => {
					return filterActiveKeys2.update(filterType, (matchedActiveKeys) => {
						let keyIndex = matchedActiveKeys.findIndex((key) => key === action.itemKey);
						if (keyIndex >= 0) {
							return matchedActiveKeys.delete(keyIndex);
						}
					});
				};

			filterActiveKeys2 = removeActiveKeys(action.filterType.key);
			if (action.filterTypeOfRemoveMeanWhile) {
				filterActiveKeys2 = removeActiveKeys(action.filterTypeOfRemoveMeanWhile.key);
			}

			return state.setIn(['filters', 'activeKeys'], filterActiveKeys2);
		}
		case LIST_TYPES.FILTER_CLICK_CLEAR_OF_TYPE: {
			let theActiveKeys = state.getIn(['filters', 'activeKeys']).withMutations((activeKeys) => {
				activeKeys.keySeq().forEach((filterType) => {
					if (filterType === action.filterType.key) {
						activeKeys = activeKeys.set(filterType, Immutable.List());
					}
				});
			});

			return state.setIn(['filters', 'activeKeys'], theActiveKeys);
		}
		case LIST_TYPES.FILTER_CLEAR_ALL: {
			// roundSplit
			const activeKeysPath = getActiveKeysPathForSplitRound(action.splitRound, state);
			return state
				.setIn(
					activeKeysPath,
					getFilterTypeDefaultActiveKeys(
						state.getIn(activeKeysPath),
						state.getIn(['flights', 'list']),
						state.getIn(['search', 'prevCond'])
					)
				)
				.setIn(['containsTax'], action.resetContainsTax ? true : state.get('containsTax'));
		}
		case LIST_TYPES.POPUP_FLIGHT_COMFORT:
			return state.setIn(['popups', 'flightComfort', action.cacheKey], Immutable.fromJS(action.comfort));
		case LIST_TYPES.POPUP_FLIGHT_INFO_CARD_COMFORT:
			return state.setIn(['popups', 'flightInfoCardComfort'], Immutable.fromJS(action.comfort));
		case LIST_TYPES.POPUP_TRANSIT_VISA_REMARK_CLIST:
			return state.setIn(['popups', 'transitVisaRemarkList'], Immutable.fromJS(action.transitVisa));
		case LIST_TYPES.CLEAR_TRANSIT_VISA_REMARK_LIST:
			return state.setIn(['popups', 'transitVisaRemarkList'], []);
		case LIST_TYPES.POPUP_VISA_LUGGAGE_DIRECT_INFO:
			return state.setIn(['popups', 'visaLuggageDirectInfo'], Immutable.fromJS(action.info));
		case LIST_TYPES.SEARCH_INIT_PREV_COND: {
			let { isbuildup: isBuildUp, containstax: containsTax = '1', sort, excludetax } = action.queryParam;
			let { keepFilterActiveKey, needResetCalendar = 'true' } = action;
			isBuildUp = isBuildUp && action.prevCond.flightWay === 'D' && getIfScopeInternational();

			if (excludetax === '0') {
				containsTax = '1';
			} else if (excludetax === '1') {
				containsTax = '0';
			}

			const hash = location.hash.replace('#', ''),
				hashObj = qs.parse(hash);

			if (hashObj && hashObj.ctm_ref === CONSTS.OTHERS.CTM_REF) {
				containsTax = '0';
				sort = 'price_asc';
			}

			const toReserveStatePaths = [
				['search', 'prevCond'],
				['router', 'history'],
				['router', 'key'],
				['globalSwitch'],
				['recommend', 'owRecommendRt', 'closeTip'],
				['calendar_lowprice'],
				['notice'],
				['containsTax'],
				['fixTheTop'],
				['common', 'workingDays'],
				['footer'],
				['filters', 'hasRestoreFromUrl'],
				['splitRound', 'filters', 'hasRestoreFromUrl'],
				['hotDistrict', 'hotDistrictList'],
				['search', 'domesticCityData'],
				['army'],
			];

			let stateAfterRestore = action.needResetAll ? defaultState : state;

			//初始化diffMonth
			let searchedDay = moment(action.prevCond.flightSegments[0].departureDate), //搜索当天日期
				searchedMonthFirst = moment([searchedDay.year(), searchedDay.month(), 1]), //搜索月份的第一天
				todayMonthFirst = moment([moment().year(), moment().month(), 1]), //当前月份第一天
				diffMonths = searchedMonthFirst.diff(todayMonthFirst, 'months'); //搜索月份差（用于初始化月视图位置）
			diffMonths = diffMonths > 0 ? diffMonths : 0;
			stateAfterRestore = stateAfterRestore.setIn(['calendar_lowprice', 'currentMonthIndex'], diffMonths);

			if (action.needResetAll) {
				toReserveStatePaths.forEach((path) => {
					stateAfterRestore = stateAfterRestore.setIn(path, state.getIn(path));
				});
				// 重查不需要情况低价日历数据
				const lowestPrice = state.getIn(['search', 'lowestPrice']);
				const lowPrice = state.getIn(['search', 'lowPrice']);
				const lowRPrice = state.getIn(['search', 'lowRPrice']);
				const lowestRPrice = state.getIn(['search', 'lowestRPrice']);
				const skipAutoReSearch = state.getIn(['search', 'skipAutoReSearch']);

				stateAfterRestore = stateAfterRestore.setIn(['search', 'lowestPrice'], lowestPrice);
				stateAfterRestore = stateAfterRestore.setIn(['search', 'lowPrice'], lowPrice);
				stateAfterRestore = stateAfterRestore.setIn(['search', 'lowRPrice'], lowRPrice);
				stateAfterRestore = stateAfterRestore.setIn(['search', 'lowestRPrice'], lowestRPrice);

                // rtADate 丢失
                const rtADate = state.getIn(['search', 'rtADate']);
                stateAfterRestore = stateAfterRestore.setIn(['search', 'rtADate'], rtADate);
				// const newFlightWay = action.prevCond.flightWay

				if (needResetCalendar) {
					stateAfterRestore = stateAfterRestore.setIn(
						['calendar_lowprice', 'lowPriceDatas'],
						Immutable.fromJS({})
					);
				}

				const extGlobalSwitches = action.prevCond.extGlobalSwitches;
				let roundTabModeSwitch = !!stateAfterRestore.getIn(['globalSwitch', 'roundTabModeSwitch']);

				if (extGlobalSwitches) {
					window.GlobalSwitches.roundTabModeSwitch = !!extGlobalSwitches.roundTabModeSwitch;
					roundTabModeSwitch = window.GlobalSwitches.roundTabModeSwitch;
				}
				let activeKeys =
					keepFilterActiveKey === true ? state.getIn(['filters', 'activeKeys']) : getInitActiveKeys();
				stateAfterRestore = stateAfterRestore
					.setIn(['router', 'key'], stateAfterRestore.getIn(['router', 'key']) + 1)
					.setIn(['filters', 'types'], getInitFilterTypes())
					.setIn(['filters', 'activeKeys'], activeKeys)
					.setIn(['search', 'skipAutoReSearch'], skipAutoReSearch)
					.setIn(['globalSwitch', 'roundTabModeSwitch'], roundTabModeSwitch);
			}

			let airline = action.prevCond.airline,
				prevCondOfImmutable = Immutable.fromJS(action.prevCond),
				prevCond = getModifyTopInfo(prevCondOfImmutable),
				currentSegmentSeq = stateAfterRestore.getIn(['flights', 'currentSegmentSeq']),
				currentSegment = action.prevCond.flightSegments[currentSegmentSeq],
				genSortTypes = () =>
					new sortTypeProvider({
						isBuildUp: !!isBuildUp,
						prevCond: prevCondOfImmutable,
						queryStringSort: sort,
						globalSwitch: stateAfterRestore.getIn(['globalSwitch']),
					}).dump();

			stateAfterRestore = stateAfterRestore
				.setIn(['isBuildUp'], !!isBuildUp)
				.setIn(['flights', 'currentSegmentSeq'], isBuildUp ? 1 : 0)
				.setIn(['search', 'prevCond'], prevCondOfImmutable)
				.setIn(['sortTypes'], genSortTypes())
				.setIn(['splitRound', 'sortTypes'], genSortTypes());

			// splitRound
			if (getSplitRoundFlightsSwitchSelector(stateAfterRestore)) {
				const activeKeysPath = getActiveKeysPathForSplitRound(true, stateAfterRestore),
					typesPath = getPathForSplitRound(true, stateAfterRestore, 'filters', 'types');

				stateAfterRestore = stateAfterRestore
					.setIn(typesPath, getInitFilterTypes())
					.setIn(activeKeysPath, getInitActiveKeys());
			}

			if (!action.needResetAll) {
				stateAfterRestore = stateAfterRestore.setIn(['containsTax'], containsTax !== '0');
			}

			stateAfterRestore = setSearchResultToStore(stateAfterRestore, prevCond, 'search');
			const filterTypes = getFilterTypes();

			if (airline && airline.code) {
				stateAfterRestore = stateAfterRestore.updateIn(
					['filters', 'activeKeys', filterTypes.AIRLINE.key],
					(activeKeys) => {
						return activeKeys?.push(airline.code);
					}
				);
			}

			const setAirportCodeToActiveKey = (airportKey, airportCode) => {
				return (stateAfterRestore = stateAfterRestore.updateIn(
					['filters', 'activeKeys', airportKey],
					(activeKeys) => {
						return activeKeys?.push(airportCode);
					}
				));
			};

			const departureAirportCode = currentSegment.departureAirportCode;
			const arrivalAirportCode = currentSegment.arrivalAirportCode;

			if (!isBuildUp) {
				if (departureAirportCode) {
					setAirportCodeToActiveKey(
						filterTypes.AIRPORT.key,
						`[FILTER_GROUP_AIRPORT.DEPART]${departureAirportCode}`
					);
					setAirportCodeToActiveKey(
						filterTypes.AIRPORT.key,
						`[FILTER_GROUP_AIRPORT.ARRIVE]${departureAirportCode}`
					);
				}

				if (arrivalAirportCode) {
					setAirportCodeToActiveKey(
						filterTypes.AIRPORT.key,
						`[FILTER_GROUP_AIRPORT.DEPART]${arrivalAirportCode}`
					);
					setAirportCodeToActiveKey(
						filterTypes.AIRPORT.key,
						`[FILTER_GROUP_AIRPORT.ARRIVE]${arrivalAirportCode}`
					);
				}
			} else {
				if (departureAirportCode) {
					const goAirportGoDepart = '[FILTER_GROUP_GO_AIRPORT.GO_DEPART]';
					const backAirportBackArrive = '[FILTER_GROUP_BACK_AIRPORT.BACK_ARRIVE]';

					if (filterTypes.BUILD_UP_AIRPORT) {
						// v2
						setAirportCodeToActiveKey(
							filterTypes.BUILD_UP_AIRPORT.key,
							`${goAirportGoDepart}${departureAirportCode}`
						);
						setAirportCodeToActiveKey(
							filterTypes.BUILD_UP_AIRPORT.key,
							`${backAirportBackArrive}${departureAirportCode}`
						);
					} else {
						setAirportCodeToActiveKey(
							filterTypes.GO_AIRPORT.key,
							`${goAirportGoDepart}${departureAirportCode}`
						);
						setAirportCodeToActiveKey(
							filterTypes.BACK_AIRPORT.key,
							`${backAirportBackArrive}${departureAirportCode}`
						);
					}
				}

				if (arrivalAirportCode) {
					const goAirportGoArrive = '[FILTER_GROUP_GO_AIRPORT.GO_ARRIVE]';
					const backAirportBackDepart = '[FILTER_GROUP_BACK_AIRPORT.BACK_DEPART]';

					if (filterTypes.BUILD_UP_AIRPORT) {
						// v2
						setAirportCodeToActiveKey(
							filterTypes.BUILD_UP_AIRPORT.key,
							`${goAirportGoArrive}${arrivalAirportCode}`
						);
						setAirportCodeToActiveKey(
							filterTypes.BUILD_UP_AIRPORT.key,
							`${backAirportBackDepart}${arrivalAirportCode}`
						);
					} else {
						setAirportCodeToActiveKey(
							filterTypes.GO_AIRPORT.key,
							`${goAirportGoArrive}${arrivalAirportCode}`
						);
						setAirportCodeToActiveKey(
							filterTypes.BACK_AIRPORT.key,
							`${backAirportBackDepart}${arrivalAirportCode}`
						);
					}
				}
			}

			return stateAfterRestore;
		}
		case LIST_TYPES.SEARCH_INIT_GLOBAL_SWITCH:
		case LIST_TYPES.SEARCH_INIT_GLOBAL_CONFIG: {
			const { globalSwitch } = action;

			let stateAfterModify = state;

			if (globalSwitch) {
				stateAfterModify = stateAfterModify
					.setIn(['globalSwitch', 'openFlagShipStoreSwitch'], !!globalSwitch.openFlagShipStoreSwitch)
					.setIn(['globalSwitch', 'nightFlightSwitch'], !!globalSwitch.nightFlightSwitch)
					.setIn(['globalSwitch', 'planeAgeSwitch'], !!globalSwitch.planeAgeSwitch)
					.setIn(['globalSwitch', 'hideLowestPriceFlagOfNon9C'], !!globalSwitch.hideLowestPriceFlagOfNon9C) //航司直销，且退改标签=5 （LCC），航司不为春秋（9C）时，则不展示“航司直销”运价标签
					.setIn(['globalSwitch', 'adjacentDateSwitch'], !!globalSwitch.adjacentDateSwitch)
					.setIn(['globalSwitch', 'mileagePointSwitch'], !!globalSwitch.mileagePointSwitch)
					.setIn(['globalSwitch', 'demandFormSwitch'], !!globalSwitch.demandFormSwitch)
					.setIn(['globalSwitch', 'owRecommendRtSwitchV2'], !!globalSwitch.owRecommendRtSwitchV2)
					.setIn(
						['globalSwitch', 'autoFixOnSplitRoundRouteSwitch'],
						!!globalSwitch.autoFixOnSplitRoundRouteSwitch
					)
					// 此处区分搜索国内国际航班两种情况，读取的开关不同，但最终赋值的字段相同，所以内部使用不作区分
					.setIn(
						['globalSwitch', 'strictStopQuickLoginSwitch'],
						getIfScopeInternational()
							? !!globalSwitch.strictStopQuickLoginSwitch
							: !!globalSwitch.d_strictStopQuickLoginSwitch
					)
					.setIn(
						['globalSwitch', 'defaultNewUserPriceSortSwitch'],
						!!globalSwitch.defaultNewUserPriceSortSwitch
					)
					.setIn(
						['globalSwitch', 'segmentCountAsPriceSortSwitch'],
						!!globalSwitch.segmentCountAsPriceSortSwitch
					)
					.setIn(['globalSwitch', 'loadingProgressV2Switch'], !!globalSwitch.loadingProgressV2Switch)
					.setIn(['globalSwitch', 'splitRoundMaskFlightsSwitch'], !!globalSwitch.splitRoundMaskFlightsSwitch)
					.setIn(
						['globalSwitch', 'authorizedStoreForceTopSwitch'],
						!!globalSwitch.authorizedStoreForceTopSwitch
					)
					.setIn(['globalSwitch', 'roundTabModeSwitch'], !!globalSwitch.roundTabModeSwitch)
					.setIn(['globalSwitch', 'listShowPriceNgsSwitch'], !!globalSwitch.listShowPriceNgsSwitch)
					.setIn(
						['globalSwitch', 'incrementShowTransVisaSwitch'],
						!!globalSwitch.incrementShowTransVisaSwitch
					)
					.setIn(
						['globalSwitch', 'strictSearchWaitforPenaltyDetailSwitch'],
						!!globalSwitch.strictSearchWaitforPenaltyDetailSwitch
					)
					.setIn(['globalSwitch', 'adBarSwitch'], !!globalSwitch.adBarSwitch)
					.setIn(['globalSwitch', 'canNoLoginUserBookSwitch'], !!globalSwitch.canNoLoginUserBookSwitch);
			}

			return stateAfterModify;
		}
		case LIST_TYPES.SEARCH_FLIGHTS: {
			let stateAfterUpdate = state;
			const splitRound = getIfSplitRoundFlightsFromState(state),
				isRouteSearch = action.isRouteSearch,
				filterTypes2 = getFilterTypes(),
				isBuildUp = state.get('isBuildUp'),
				currentSegmentSeq = action.currentSegmentSeq,
				hasRestoreFromUrl = state.getIn(['filters', 'hasRestoreFromUrl']),
				splitRoundHasRestoreFromUrl = state.getIn(['splitRound', 'filters', 'hasRestoreFromUrl']);

			if (splitRound && isRouteSearch && currentSegmentSeq === 1) {
				// 针对往返分屏的返程修改信息
				// splitRound
				stateAfterUpdate = state
					// .setIn(['flights', 'isFinished'], true)
					.setIn(['splitRound', 'flights', 'list'], action.flights)
					.setIn(['splitRound', 'flights', 'isFinished'], true)
					// .setIn(['flights', 'isFinished'], true)
					.setIn(['splitRound', 'flights', 'acitveSegmentSeq'], 1)
					.setIn(['splitRound', 'flights', 'currentSegmentSeq'], 1)
					.setIn(
						['splitRound', 'flights', 'existDirectReturnTrip'],
						action.existDirectReturnTrip ? action.existDirectReturnTrip : false
					);
			} else {
				let currentFlights = state.getIn(['flights', 'list']),
					currentProgress = state.getIn(['search', 'searchProgress']),
					firstGetSearchResult = currentFlights.size === 0 && action.flights.size > 0,
					nextProgress = currentProgress,
					isFinished = action.isFinished,
					showAuthCode = action.showAuthCode;

				if (isFinished) {
					nextProgress = 0;
				} else {
					if (firstGetSearchResult) {
						nextProgress = 0;
					}

					nextProgress += 10;

					nextProgress = Math.min(98, nextProgress);
				}

				if (action.flights.size === 0 && currentFlights.size > 0) {
					return state
						.setIn(['flights', 'isFinished'], Immutable.fromJS(isFinished))
						.setIn(['search', 'searchProgress'], nextProgress);
				}

				stateAfterUpdate = state
					.setIn(['search', 'searchProgress'], nextProgress)
					.setIn(['flights', 'list'], action.flights)
					.setIn(['flights', 'isFinished'], isFinished)
					.setIn(
						['flights', 'existDirectReturnTrip'],
						action.existDirectReturnTrip ? action.existDirectReturnTrip : false
					)
					.setIn(['flights', 'currentSegmentSeq'], currentSegmentSeq)
					.setIn(['flights', 'giftIds'], Immutable.fromJS(action.giftIds))
					.setIn(['flights', 'agreementIds'], Immutable.fromJS(action.agreementIds))
					.setIn(['flights', 'showAuthCode'], showAuthCode);
			}

			const activeKeysPath = getActiveKeysPathForSplitRound(splitRound, state);
			const prevCondFromState = state.getIn(['search', 'prevCond']);

			stateAfterUpdate = stateAfterUpdate.updateIn(activeKeysPath, (activeKeys) => {
				activeKeys.keySeq().forEach((filterType) => {
					const theFilterTypeEnum = filterTypes2.get(filterType);
					if (theFilterTypeEnum && (isRouteSearch || splitRound)) {
						if (theFilterTypeEnum && theFilterTypeEnum.value.rememberInSegments) {
							const getRememberInSegments = theFilterTypeEnum.value.getRememberInSegments;
							if (typeof getRememberInSegments === 'function') {
								const currentAcitveKeys = activeKeys.get(filterType);
								activeKeys = activeKeys.set(
									filterType,
									currentAcitveKeys.filter((theActiveKey) => getRememberInSegments(theActiveKey))
								);
							}
						} else {
							const defaultActiveKeys = FilterOptionRetrieverFactory.getInstance(
								theFilterTypeEnum
							).getDefaultActiveKeys(action.flights, prevCondFromState, {
								currentSegmentSeq,
								isBuildUp,
							});
							activeKeys = activeKeys.set(filterType, defaultActiveKeys);
						}
					}
				});

				return activeKeys;
			});

			const executeOnEachFilterType = (callback) => {
				const filterTypes = Object.keys(filterTypes2.toJSON());
				filterTypes.forEach((filterType) => {
					const theFilterTypeEnum = filterTypes2.get(filterType);
					callback(theFilterTypeEnum);
				});
			};

			const updateActiveKeys = (toUpdateState, toUpdateAcitveKeys, path) => {
				return toUpdateState.updateIn(path, (activeKeys) => {
					toUpdateAcitveKeys.forEach((theAcitveKey) => {
						if (activeKeys.indexOf(theAcitveKey) < 0) {
							activeKeys = activeKeys.push(theAcitveKey);
						}
					});
					return activeKeys;
				});
			};

			// 1) 恢复URL中指定的筛选条件
			if (isRouteSearch === false) {
				executeOnEachFilterType((theFilterTypeEnum) => {
					const theFilterTypeEnumValue = theFilterTypeEnum.value,
						getActiveKeysFromPrevCond = theFilterTypeEnumValue.getActiveKeysFromPrevCond;

					if (typeof getActiveKeysFromPrevCond === 'function') {
						const activeKeysFromPrevCond = getActiveKeysFromPrevCond(prevCondFromState);

						if (activeKeysFromPrevCond && activeKeysFromPrevCond.length) {
							stateAfterUpdate = updateActiveKeys(stateAfterUpdate, activeKeysFromPrevCond, [
								...activeKeysPath,
								theFilterTypeEnum.key,
							]);
						}
					}
				});
			}

			// 2) 在非反查时候，根据查询条件设置筛选项的默认值
			if ((!hasRestoreFromUrl || !splitRoundHasRestoreFromUrl) && (isRouteSearch === false || splitRound)) {
				executeOnEachFilterType((theFilterTypeEnum) => {
					const theFilterTypeEnumValue = theFilterTypeEnum.value;

					let acitveKeysFromUrl =
						theFilterTypeEnumValue.urlSearchKey &&
						theFilterTypeEnumValue.getActiveKeysFromUrl({
							searchKey: theFilterTypeEnumValue.urlSearchKey,
							queryParam: qs.parse(window.location.search.substring(1)),
						});

					if (acitveKeysFromUrl && acitveKeysFromUrl.length) {
						if (splitRound && isRouteSearch) {
							// 分屏只在反查时候才设置
							if (!splitRoundHasRestoreFromUrl) {
								stateAfterUpdate = updateActiveKeys(stateAfterUpdate, acitveKeysFromUrl, [
									...activeKeysPath,
									theFilterTypeEnum.key,
								]);
								stateAfterUpdate = stateAfterUpdate.setIn(
									['splitRound', 'filters', 'hasRestoreFromUrl'],
									true
								);
							}
						} else if (!hasRestoreFromUrl) {
							stateAfterUpdate = updateActiveKeys(stateAfterUpdate, acitveKeysFromUrl, [
								...getActiveKeysPathForSplitRound(false, state),
								theFilterTypeEnum.key,
							]);
							stateAfterUpdate = stateAfterUpdate.setIn(['filters', 'hasRestoreFromUrl'], true);
						}
					}
				});
			}

			const nF = stateAfterUpdate.getIn(['flights', 'isFinished']);
			const pF = state.getIn(['flights', 'isFinished']);
			if (nF && nF !== pF) {
				performanceTrace.trace('afterParsingFlights');
			}
			return stateAfterUpdate;
		}
		case LIST_TYPES.SET_IS_RESEARCHING:
			return state.setIn(['isResearching'], action.isResearching);
		case LIST_TYPES.SET_COMFORT_TAGS:
			let currentComfortTags = state.getIn(['comfortTags']);
			if (action.comfortTags.length == 0 && currentComfortTags.size > 0) {
				return state;
			}

			//当前拿到的舒适度信息和已有信息进行合并
			action.comfortTags.forEach((tag) => {
				if (
					currentComfortTags.filter((currentComfortTag) => {
						return (
							currentComfortTag.get('flightNo') === tag.flightNo &&
							currentComfortTag.get('departureCityCode') === tag.departureCityCode &&
							currentComfortTag.get('arrivalCityCode') === tag.arrivalCityCode
						);
					}).size === 0
				) {
					currentComfortTags = currentComfortTags.push(Immutable.fromJS(tag));
				}
			});

			return state.setIn(['comfortTags'], currentComfortTags);
		case LIST_TYPES.SEARCH_SET_LAST_SEARCH_TIME:
			return state.setIn(['search', 'lastSearchTime'], action.time);
		case LIST_TYPES.FILTER_TOGGLE_AIRLINE_MATRIX:
			return state.setIn(['filters', 'showAirlineMatrix'], !state.getIn(['filters', 'showAirlineMatrix']));
		case LIST_TYPES.FILTER_RESET_ACTIVE_KEYS: {
			const { splitRound } = action;
			const filterTypes = getFilterTypes();
			// 只在航司矩阵有用到
			// 只在航司矩阵有用到
			const path = getActiveKeysPathForSplitRound(splitRound, state);
			return state.updateIn(path, (activeKeys) => {
				activeKeys.keySeq().forEach((filterType) => {
					activeKeys = activeKeys.set(
						filterType,
						Immutable.fromJS(action.filterType2ActiveKeys[filterType]) ||
							FilterOptionRetrieverFactory.getInstance(filterTypes.get(filterType)).getDefaultActiveKeys(
								state.getIn(['flights', 'list']),
								state.getIn(['search', 'prevCond']),
								{
									currentSegmentSeq: state.getIn(['flights', 'currentSegmentSeq']),
									isBuildUp: state.getIn(['isBuildUp']),
								}
							)
					);
				});

				return activeKeys;
			});
		}
		//search box
		case LIST_TYPES.SEARCH_ACTIVATE_TABGROUP:
			if (action.stateId == 'ticketRequest') {
				return state.updateIn(['ticketRequest', `${action.tabType}`], (classGrade) =>
					classGrade.indexOf(action.id) < 0
						? classGrade.push(action.id)
						: classGrade.splice(classGrade.indexOf(action.id), 1)
				);
			}
			return state.setIn(['search', `${action.tabType}`], action.id);
		case LIST_TYPES.SHOW_FILTER_NO_RESULT:
			return state.setIn(['recommend', 'showFilterNoResult'], action.reason);
		//search box multi trip
		case CHANNEL_TYPES.ADD_MT_SEG:
			if (state.getIn([action.id, 'canAddMoreMtSeg'])) {
				const selectedCity = state.getIn([action.id, 'selectedCity']);
				let addMtCount = Object.keys(getMtSegList(selectedCity)).length;
				return state.withMutations((state) => {
					state
						.updateIn([action.id, 'selectedCity'], (city) =>
							city
								.set(`mtDCity${addMtCount + 1}`, null)
								.set(`mtACity${addMtCount + 1}`, null)
								.set(`mtStartDate${addMtCount + 1}`, null)
						)
						.updateIn(
							[action.id, 'canAddMoreMtSeg'],
							() => addMtCount < (action.id == 'ticketRequest' ? 9 : 5)
						);
				});
			} else {
				return state;
			}
		// search box delete multi trip
		case CHANNEL_TYPES.DEL_MT_SEG:
			const selectedCity2 = state.getIn([action.id, 'selectedCity']);
			let delMtCount = Object.keys(getMtSegList(selectedCity2)).length,
				selectedCity1 = state.getIn([action.id, 'selectedCity']),
				toAssign = new Map();

			for (let i = action.index + 1; i < delMtCount; i++) {
				toAssign = toAssign
					.set(`mtACity${i}`, selectedCity1.get(`mtACity${i + 1}`))
					.set(`mtDCity${i}`, selectedCity1.get(`mtDCity${i + 1}`))
					.set(`mtStartDate${i}`, selectedCity1.get(`mtStartDate${i + 1}`));
			}

			let SelectedCity = state.getIn([action.id, 'selectedCity']).merge(toAssign);
			let updatedSelectedCity = SelectedCity.delete(`mtACity${delMtCount}`)
				.delete(`mtDCity${delMtCount}`)
				.delete(`mtStartDate${delMtCount}`);

			return state
				.setIn([action.id, 'selectedCity'], updatedSelectedCity)
				.setIn([action.id, 'canAddMoreMtSeg'], delMtCount <= 6);

		//城市选择器，只会显示一个
		case CHANNEL_TYPES.SHOW_HOT_CITY_PICKER:
			return state
				.setIn(['search', 'showCityPicker'], Map().set(`${action.name}${action.id}`, true))
				.setIn([action.id || 'search', 'poiResult'], Immutable.fromJS({}));

		case CHANNEL_TYPES.HIDE_HOT_CITY_PICKER:
			return state
				.setIn(['search', 'showCityPicker'], Map())
				.setIn([action.id || 'search', 'isNeedFocusSecond'], false);
		//交换城市
		case LIST_TYPES.EXCHANGE_MULTI_CITY:
			let mtDCityName = `mtDCity${action.index + 1}`,
				mtACityName = `mtACity${action.index + 1}`;
			let mtOldDCity = state.getIn([action.id || 'search', 'selectedCity', mtDCityName]),
				mtOldACity = state.getIn([action.id || 'search', 'selectedCity', mtACityName]),
				mtExchangedCity = state
					.getIn([action.id || 'search', 'selectedCity'])
					.merge(Map().set(mtDCityName, mtOldACity).set(mtACityName, mtOldDCity));
			return state.setIn(
				[action.id || 'search', 'selectedCity'],
				state.getIn([action.id || '', 'selectedCity']).merge(mtExchangedCity)
			);
		case CHANNEL_TYPES.EXCHANGE_CITY:
			let dCityName = 'owDCity',
				aCityName = 'owACity',
				rDCityName = 'rtDCity',
				rACityName = 'rtACity';

			let oldDCity = state.getIn(['search', 'selectedCity', dCityName]),
				oldACity = state.getIn(['search', 'selectedCity', aCityName]),
				oldRDCity = state.getIn(['search', 'selectedCity', rDCityName]),
				oldRACity = state.getIn(['search', 'selectedCity', rACityName]),
				exchangedCity = state
					.getIn(['search', 'selectedCity'])
					.merge(
						Map()
							.set(dCityName, oldACity)
							.set(aCityName, oldDCity)
							.set(rDCityName, oldRACity)
							.set(rACityName, oldRDCity)
					);
			return state.setIn(
				['search', 'selectedCity'],
				state.getIn(['search', 'selectedCity']).merge(exchangedCity)
			);
		// 修改城市
		case LIST_TYPES.MODIFY_SEARCH_CITY:
			let owDCity = action.cityInfo.get('owDCity'),
				owACity = action.cityInfo.get('owACity'),
				rtDCity = action.cityInfo.get('rtDCity'),
				rtACity = action.cityInfo.get('rtACity'),
				mtDCity1 = action.cityInfo.get('mtDCity1'),
				mtACity1 = action.cityInfo.get('mtACity1'),
				newCity = Map()
					.set('owDCity', owDCity)
					.set('owACity', owACity)
					.set('rtDCity', rtDCity)
					.set('rtACity', rtACity)
					.set('mtDCity1', mtDCity1)
					.set('mtACity1', mtACity1);
			return state
				.setIn(['search', 'selectedCity'], state.getIn(['search', 'selectedCity']).merge(newCity))
				.setIn(['search', 'showCityText'], true)
				.setIn(['search', 'skipAutoReSearch'], true);
		//POI，只会显示一个
		case CHANNEL_TYPES.CHANGE_POI_KEY:
			return state.setIn([action.id || 'search', 'poiKey'], Map().set(action.name, action.key));
		case CHANNEL_TYPES.HIDE_POI_CITY_PICKER:
			return state.setIn([action.id || 'search', 'poiResult'], Immutable.fromJS({}));
		case CHANNEL_TYPES.CHANGE_POI_LIST:
			if (action.key == state.getIn([action.id || 'search', 'poiKey', action.name])) {
				let handlerResult = poiHandler.init(action.key, action.list, 0);
				return state
					.setIn([action.id || 'search', 'poiKey'], Map().set(action.name, action.key))
					.setIn([action.id || 'search', 'poiResult'], fromJS(handlerResult))
					.setIn(
						[action.id || 'search', 'poiListAdditionalInfo'],
						Map({
							existSameNameCity: handlerResult.existSameNameCity,
							Key: handlerResult.Key,
							noResult: handlerResult.noResult,
						})
					);
			} else {
				return state;
			}
		case CHANNEL_TYPES.SELECT_POI_ITEM:
			let toAssignCityInfo = {
				[action.name]: action.city,
			};

			let anotherName = '';
			if (action.name.indexOf('ow') == 0) {
				anotherName = action.name.replace(/ow/g, 'rt');
			} else if (action.name.indexOf('rt') == 0) {
				anotherName = action.name.replace(/rt/g, 'ow');
			}

			if (anotherName) {
				toAssignCityInfo[anotherName] = action.city;
			}

			let searchField = action.id || 'search';

			return state
				.setIn([searchField, 'poiResult'], Immutable.fromJS({}))
				.setIn(
					[searchField, 'selectedCity'],
					state.getIn([searchField, 'selectedCity']).merge(fromJS(toAssignCityInfo))
				)
				.setIn([searchField, 'focusNextInput'], action.focusNextInput);
		case CHANNEL_TYPES.SAVE_LAST_POI:
			return state.setIn([action.id || 'search', 'lastPoi'], action.list);

		// passenger count
		case LIST_TYPES.DECREMENT_PASSENGER:
			let count = state.getIn([action.id || 'search', `${action.passengerType}Cnt`]);
			if (count - 1 < action.min) {
				// count = count
			} else {
				count = count - 1;
			}
			return state
				.setIn([action.id || 'search', `${action.passengerType}Cnt`], count)
				.setIn([action.id || 'search', `lastModifiedPassengerType`], action.passengerType);
		case LIST_TYPES.INCREMENT_PASSENGER:
			let count1 = state.getIn([action.id || 'search', `${action.passengerType}Cnt`]);
			if (count1 + 1 > action.max) {
				// count1 = count1
			} else {
				count1 = count1 + 1;
			}
			return state
				.setIn([action.id || 'search', `${action.passengerType}Cnt`], count1)
				.setIn([action.id || 'search', `lastModifiedPassengerType`], action.passengerType);
		case LIST_TYPES.SET_PASSENGER_COUNT: {
			let path = ['search', 'passenger'],
				passenger = state.getIn(path);
			return state
				.setIn(path, passenger.merge(Immutable.fromJS(action.passenger)))
				.setIn(['search', 'isShowFltPlusHotelTip'], false);
		}

		case LIST_TYPES.CHANGE_CLASS_GRADE: {
			return state.setIn(['search', 'classGrade'], action.classGrade);
		}
		case LIST_TYPES.CHANGE_SEARCHHISTORY_LIST_VISIBLE:
			return state.setIn(['search', 'isShowSearchHistoryList'], !!action.isShow);
		case LIST_TYPES.ON_SEARCH_DATE_CHANGE:
			let searchField1 = action.id || 'search';
			if (action.field.indexOf('mt') !== -1) {
				return state.setIn([searchField1, 'selectedCity', action.field], action.date);
			}
			//单程选择返程日期后跳往返
			if (action.field == 'owADate') {
				return state
					.setIn([searchField1, 'flightWay'], 'RT')
					.setIn([searchField1, 'owADate'], null)
					.setIn([searchField1, 'rtADate'], action.date);
			}
			if (action.field == 'owDDate' || action.field == 'rtDDate') {
				let newSelectedCity = state
					.getIn([searchField1, 'selectedCity'])
					.merge(Map().set('mtStartDate1', action.date));
				return state
					.setIn([searchField1, 'owDDate'], action.date)
					.setIn([searchField1, 'rtDDate'], action.date)
					.setIn([searchField1, 'selectedCity'], newSelectedCity)
					.setIn([searchField1, 'focusNextInput'], action.focusNextInput);
			}
			return state.setIn([searchField1, action.field], action.date);
		//search verification
		case CHANNEL_TYPES.DDATE_ERROR:
			return state
				.setIn(['search', 'showValidateErrorTip'], !!action.error)
				.setIn(['search', 'dDateError'], action.error);
		case CHANNEL_TYPES.ADATE_ERROR:
			return state
				.setIn(['search', 'showValidateErrorTip'], !!action.error)
				.setIn(['search', 'aDateError'], action.error);
		case CHANNEL_TYPES.DCITY_ERROR:
			return state
				.setIn(['search', 'showValidateErrorTip'], !!action.error)
				.setIn(['search', 'dCityError'], action.error);
		case CHANNEL_TYPES.ACITY_ERROR:
			return state
				.setIn(['search', 'showValidateErrorTip'], !!action.error)
				.setIn(['search', 'aCityError'], action.error);
		case CHANNEL_TYPES.OW_RT_CLEAN_ERROR:
			return state
				.setIn(['search', 'dDateError'], '')
				.setIn(['search', 'aDateError'], '')
				.setIn(['search', 'dCityError'], '')
				.setIn(['search', 'aCityError'], '')
				.setIn(['search', 'showValidateErrorTip'], false);
		case CHANNEL_TYPES.MT_DCITY_ERROR:
			let newMtError = state.getIn([action.id || 'search', 'mtError']).merge(
				Map().set(
					action.index,
					Map()
						.merge(state.getIn([action.id || 'search', 'mtError', action.index]))
						.merge(Map().set('dCity', action.error))
				)
			);
			return state
				.setIn([action.id || 'search', 'showValidateErrorTip'], !!action.error)
				.setIn([action.id || 'search', 'mtError'], newMtError);

		case CHANNEL_TYPES.MT_ACITY_ERROR:
			let newMtError1 = state.getIn([action.id || 'search', 'mtError']).merge(
				Map().set(
					action.index,
					Map()
						.merge(state.getIn([action.id || 'search', 'mtError', action.index]))
						.merge(Map().set('aCity', action.error))
				)
			);
			return state
				.setIn([action.id || 'search', 'showValidateErrorTip'], !!action.error)
				.setIn([action.id || 'search', 'mtError'], newMtError1);
		case CHANNEL_TYPES.MT_DDATE_ERROR:
			let newMtError2 = state.getIn([action.id || 'search', 'mtError']).merge(
				Map().set(
					action.index,
					Map()
						.merge(state.getIn([action.id || 'search', 'mtError', action.index]))
						.merge(Map().set('dDate', action.error))
				)
			);
			return state
				.setIn([action.id || 'search', 'showValidateErrorTip'], !!action.error)
				.setIn([action.id || 'search', 'mtError'], newMtError2);
		case CHANNEL_TYPES.MT_CLEAN_ERROR:
			return state
				.setIn([action.id || 'search', 'showValidateErrorTip'], !!action.error)
				.setIn([action.id || 'search', 'mtError'], Map());
		case CHANNEL_TYPES.FOCUS_MT_SECOND_DCITY:
			return state.setIn([action.id, 'isNeedFocusSecond'], true);
		case LIST_TYPES.CANCEL_FOCUS_MT_SECOND_DCITY:
			return state.setIn(['search', 'isNeedFocusSecond'], false);
		//citypicker全局事件
		case CHANNEL_TYPES.MOUSE_DOWN:
			let searchField2 = action.id || 'search';
			let fromCityPicker = action.tag.source == MOUSE_ACTION_SOURCE.CITY_PICKER,
				showCityPicker = state.getIn(['search', 'showCityPicker']);

			if (!fromCityPicker) {
				showCityPicker = EMPTY_MAP;
			}

			return state
				.setIn(['search', 'showCityPicker'], showCityPicker)
				.setIn([searchField2, 'showValidateErrorTip'], false)
				.setIn([searchField2, 'showValidateWarnTip'], false)
				.setIn([searchField2, 'mouseIsDownOnCityPicker'], fromCityPicker)
				.setIn(['ticketRequest', 'showAreaCode'], false);
		case LIST_TYPES.SEARCH_HISTORY:
			return state.setIn(['search', 'searchHistory'], fromJS(action.history));
		case LIST_TYPES.SET_GLOBAL_SEARCH_CRITERIA:
			return state.setIn(['search', 'globalSearchCriteria'], action.globalSearchCriteria);
		case LIST_TYPES.NAME_ERROR:
			let contactError = state.getIn(['ticketRequest', 'contactError']),
				currnetError = !!(action.error || contactError.get('telephoneError') || contactError.get('emailError'));
			return state
				.setIn(['ticketRequest', 'showValidateErrorTip'], !!currnetError)
				.setIn(['ticketRequest', 'contactError', 'nameError'], action.error);
		case LIST_TYPES.TELEPHONE_ERROR:
			return state
				.setIn(
					['ticketRequest', 'showValidateErrorTip'],
					!!(
						action.error ||
						state.getIn(['ticketRequest', 'contactError', 'nameError']) ||
						state.getIn(['ticketRequest', 'contactError', 'emailError'])
					)
				)
				.setIn(['ticketRequest', 'contactError', 'telephoneError'], action.error);
		case LIST_TYPES.EMAIL_ERROR:
			return state
				.setIn(
					['ticketRequest', 'showValidateErrorTip'],
					!!(
						action.error ||
						state.getIn(['ticketRequest', 'contactError', 'nameError']) ||
						state.getIn(['ticketRequest', 'contactError', 'telephoneError'])
					)
				)
				.setIn(['ticketRequest', 'contactError', 'emailError'], action.error);
		case LIST_TYPES.COUNTRY_CODE:
			return state.setIn(['ticketRequest', 'countryCodeList'], action.codes);
		case LIST_TYPES.SHOW_AREA_CODE:
			if (action.code) {
				return state
					.setIn(['ticketRequest', 'showAreaCode'], action.visible)
					.setIn(['ticketRequest', 'contact', 'areaCode'], action.code)
					.setIn(['ticketRequest', 'contact', 'areaText'], action.text);
			}
			return state.setIn(['ticketRequest', 'showAreaCode'], action.visible);
		case LIST_TYPES.INPUT_CONTACT:
			return state.setIn(['ticketRequest', 'contact'], fromJS(action.contact));
		case LIST_TYPES.CLICK_CABIN:
			let classGrade = state.getIn(['ticketRequest', 'classGrade']);
			if (classGrade.indexOf(action.id) < 0) {
				return state.updateIn(['ticketRequest', 'classGrade'], (classGrade) => classGrade.push(action.id));
			} else {
				return state.updateIn(['ticketRequest', 'classGrade'], (classGrade) =>
					classGrade.splice(classGrade.indexOf(action.id), 1)
				);
			}
		case LIST_TYPES.RECOMMEND_GET_TICKET_REQUEST:
			return setSearchResultToStore(state, action.searchResult, 'ticketRequest');
		case LIST_TYPES.SHOW_WARN:
			return state.setIn(['search', 'showValidateWarnTip'], true);
		//日历低价
		case CHANNEL_TYPES.FETCH_LOW_PRICE_SUCCESS: {
			const { dCityCode, aCityCode, list = [], cabinCode } = action;

			let lowestPrice = {},
				resultList = {};
			list.forEach((item) => {
				const itemDepartDate = item.departDate?.includes('Date')
					? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD HH:mm:ss')
					: item.departDate;
				let theMonth = moment(itemDepartDate).format('MM'),
					formatDay = moment(itemDepartDate).format('YYYY-MM-DD');
				resultList[formatDay] = item.price;
				if (!lowestPrice[theMonth] || lowestPrice[theMonth] > item.price) {
					lowestPrice[theMonth] = item.price;
				}
			});
			let lowPriceKey = getLowPriceKey({
				departureCityCode: dCityCode,
				arrivalCityCode: aCityCode,
				cabinCode,
			});
			return state
				.setIn(
					['search', 'lowPrice'],
					state.getIn(['search', 'lowPrice']).merge(fromJS({ [`${lowPriceKey}`]: resultList }))
				)
				.setIn(
					['search', 'lowestPrice'],
					state.getIn(['search', 'lowPrice']).merge(fromJS({ [`${lowPriceKey}`]: lowestPrice }))
				);
		}
		case CHANNEL_TYPES.FETCH_LOW_RPRICE_SUCCESS: {
			let { departureCityCode, arrivalCityCode, pricelist = [], cabinCode } = action,
				stateList = {};
			let lowestRPrice = {};
			let lowPriceKey = getLowPriceKey({
				departureCityCode,
				arrivalCityCode,
				cabinCode,
			});
			pricelist.forEach((item) => {
				const itemDepartDate = item.departDate?.includes('Date')
					? moment(new Date(parseInt(item.departDate.slice(6, 19)))).format('YYYY-MM-DD HH:mm:ss')
					: item.departDate;
				const itemReturnDate = item.returnDate?.includes('Date')
					? moment(new Date(parseInt(item.returnDate.slice(6, 19)))).format('YYYY-MM-DD')
					: item.returnDate;
				let arrivalDate = moment(itemReturnDate).format('YYYY-MM-DD'),
					theMonth = moment(itemReturnDate).format('MM');
				const departDate = moment(itemDepartDate).format('YYYY-MM-DD');
				stateList[`${departDate}-${arrivalDate}`] = item['price'];
				if (
					!lowestRPrice[`${departDate}-${theMonth}`] ||
					lowestRPrice[`${departDate}-${theMonth}`] > item['price']
				) {
					lowestRPrice[`${departDate}-${theMonth}`] = item['price'];
				}
			});
			// 保存单程推往返的数据，只需初始化时的查询数据
			let newState = state;
			let hasOwRecommendRtLowPrice = state.getIn(['recommend', 'owRecommendRt', 'hasLoadLowPriceData']);
			if (!hasOwRecommendRtLowPrice) {
				newState = state
					.setIn(['recommend', 'owRecommendRt', 'lowPriceData'], stateList)
					.setIn(['recommend', 'owRecommendRt', 'hasLoadLowPriceData'], true);
			}
			return newState
				.setIn(
					['search', 'lowRPrice'],
					state.getIn(['search', 'lowRPrice']).merge(fromJS({ [`${lowPriceKey}`]: stateList }))
				)
				.setIn(
					['search', 'lowestRPrice'],
					state.getIn(['search', 'lowestRPrice']).merge(fromJS({ [`${lowPriceKey}`]: lowestRPrice }))
				);
		}
		//精选地区
		case HotDistrictTypes.SET_PRICE_MAP_SWITCH:
			return state.setIn(['globalSwitch', 'priceMapSwitch'], action.priceMapSwitch);
		case HotDistrictTypes.CHANGE_ARRIVAL_DISTRICT:
			return state.setIn(['hotDistrict', 'arrivalDistrict'], action.arrivalDistrict);
		case HotDistrictTypes.SET_HOT_DISTRICT_LIST:
			return state.setIn(['hotDistrict', 'hotDistrictList'], action.districtList);
		case HotDistrictTypes.SWITCH_TO_DISTRICT_SEARCH:
			return state.setIn(['hotDistrict', 'isDistrictSearch'], action.isDistrictSearch);
		//精选地区低价日历
		case HotDistrictTypes.FETCH_COUNTRY_LOW_PRICE_SUCCESS_ACTION:
			return (() => {
				let { aCityCode, list = {}, dCityCode } = action;
				let countryLowestPrice = {};

				Object.keys(list).forEach((p) => {
					let theMonth = moment(p).format('MM');
					if (!countryLowestPrice[theMonth] || countryLowestPrice[theMonth] > list[p]) {
						countryLowestPrice[theMonth] = list[p];
					}
				});
				let oldCountryLowPrice = state.getIn(['search', 'countryLowPrice']).toJS();
				let oldCountryLowestPrice = state.getIn(['search', 'countryLowestPrice']).toJS();
				return state
					.setIn(
						['search', 'countryLowPrice'],
						Immutable.fromJS(Object.assign({}, oldCountryLowPrice, { [`${dCityCode}-${aCityCode}`]: list }))
					)
					.setIn(
						['search', 'countryLowestPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowestPrice, {
								[`${dCityCode}-${aCityCode}`]: countryLowestPrice,
							})
						)
					);
			})();

		case HotDistrictTypes.FETCH_COUNTRY_LOW_R_PRICE_SUCCESS_ACTION:
			return (() => {
				let { dCityCode, aCityCode, list = {} } = action;
				let lowestPrice = {};
				// list {2018-10-11(开始时间):{2019-10-22(返回日期): 1908(往返总价)}}
				// lowestPrice{2018-10-11(开始时间):{'04(返程月份)': 900(最低价)}}
				Object.keys(list).forEach((p) => {
					let priceMap = list[p];
					Object.keys(priceMap).forEach((aDate) => {
						let theAMonth = moment(aDate).format('MM');
						if (!lowestPrice[p]) {
							lowestPrice[p] = {};
						}
						if (
							!lowestPrice[p][theAMonth] ||
							(list[p][aDate] && lowestPrice[p][theAMonth] > list[p][aDate])
						) {
							lowestPrice[p][theAMonth] = list[p][aDate];
						}
					});
				});
				let oldCountryLowRPrice = state.getIn(['search', 'countryLowRPrice']).toJS();
				let oldCountryLowestRPrice = state.getIn(['search', 'countryLowestRPrice']).toJS();
				return state
					.setIn(
						['search', 'countryLowRPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowRPrice, { [`${dCityCode}-${aCityCode}`]: list })
						)
					)
					.setIn(
						['search', 'countryLowestRPrice'],
						Immutable.fromJS(
							Object.assign({}, oldCountryLowestRPrice, { [`${dCityCode}-${aCityCode}`]: lowestPrice })
						)
					);
			})();
		case LIST_TYPES.FLIGHT_BEFORE_ROUTE_SEARCH: {
			let stateAfterModify = state,
				splitRoundFlights = getIfSplitRoundFlightsFromState(state);

			if (action.clearFlights) {
				stateAfterModify = stateAfterModify
					.setIn(['flights', 'list'], Immutable.fromJS([]))
					.setIn(['flights', 'selected', 'selectedFlights'], Immutable.fromJS([]))
					.setIn(['flights', 'selected', 'flights'], null)
					.setIn(['flights', 'isFinished'], false)
					.setIn(['recommend', 'virtualFlights'], Immutable.fromJS({}));
			}

			if (splitRoundFlights) {
				// splitRound
				stateAfterModify = stateAfterModify
					.setIn(['splitRound', 'flights', 'currentSegmentSeq'], 1)
					.setIn(['splitRound', 'flights', 'list'], Immutable.fromJS([]))
					.setIn(['splitRound', 'flights', 'isFinished'], false)
					.setIn(['recommend', 'virtualFlights', 1], Immutable.fromJS([]))
					.setIn(['splitRound', 'flights', 'acitveSegmentSeq'], 1);
			} else {
				stateAfterModify = state
					.setIn(['flights', 'searchProgress'], 0)
					.setIn(['filters', 'showAirlineMatrix'], false);
			}

			return stateAfterModify;
		}
		case LIST_TYPES.SELECTED_FLIGHTS:
			let splitRoundFlights = getIfSplitRoundFlightsFromState(state);
			if (action.selectedflight) {
				let existSuperFlyer = state.getIn(['flights', 'selected', 'existSuperFlyer']);
				if (!existSuperFlyer || splitRoundFlights) {
					existSuperFlyer = action.selectedflight
						.getIn(['itineraryTags'])
						.some((tag) => tag.get('type') == 'SuperFlyer');
				}
				let preFlights = state.getIn(['flights', 'selected', 'flights']);
				if (preFlights) {
					state = state.updateIn(['flights', 'selected', 'selectedFlights'], (selectFlights) =>
						selectFlights.push(preFlights)
					);
				}
				if (splitRoundFlights) {
					state = state.updateIn(
						['splitRound', 'flights', 'selected', 'flights'],
						(_flights) => action.selectedflight
					);
				}
				return state
					.updateIn(['flights', 'selected', 'flights'], (_flights) => action.selectedflight)
					.updateIn(['flights', 'selected', 'routeSearchCriteria'], (routeSearchCriteria) =>
						routeSearchCriteria.push(action.routeSearchCriteria)
					)
					.setIn(['flights', 'selected', 'existSuperFlyer'], existSuperFlyer);
			}
			if (action.targetSegmentSeq == 0) {
				if (splitRoundFlights) {
					state = state.setIn(['splitRound', 'flights', 'selected', 'flights'], null);
				}
				return state
					.setIn(['flights', 'selected', 'flights'], null)
					.updateIn(['flights', 'selected', 'routeSearchCriteria'], (routeSearchCriteria) =>
						routeSearchCriteria.slice(0, action.targetSegmentSeq)
					)
					.setIn(['flights', 'selected', 'existSuperFlyer'], false);
			}

			return state
				.updateIn(['flights', 'selected', 'flights', 'flightSegments'], (flightSegments) =>
					flightSegments.slice(0, action.targetSegmentSeq)
				)
				.updateIn(['flights', 'selected', 'routeSearchCriteria'], (routeSearchCriteria) =>
					routeSearchCriteria.slice(0, action.targetSegmentSeq)
				);
		//热门国家
		case LIST_TYPES.CHANGE_ARRIVAL_COUNTRY:
			return state.setIn(['hotCountry', 'arrivalCountry'], action.arrivalCountry);
		case LIST_TYPES.SWITCH_HOT_SEARCH:
			return state.setIn(['hotCountry', 'isHotSearch'], action.isHotSearch);
		case LIST_TYPES.SWITCH_FREE_PICKER:
			return state.setIn(['hotCountry', 'isFreeDept'], !state.getIn(['hotCountry', 'isFreeDept']));
		case LIST_TYPES.CHANGE_CURRENT_MONTH:
			return state.setIn(['hotCountry', 'currentMonthIndex'], action.currentMonthIndex);
		case LIST_TYPES.CHANGE_SELECTED_DAY:
			return state.setIn(['hotCountry', 'selectedDayCalendar'], action.selectedDay);
		case LIST_TYPES.CHANGE_FLOAT_DAYS:
			return state.setIn(['hotCountry', 'floatDays'], action.floatDays);
		case LIST_TYPES.CHANGE_STAY_DAYS:
			return state.setIn(['hotCountry', 'stayDays'], action.stayDays);
		case LIST_TYPES.CHANGE_SELECTED_TIME_ITEM:
			return state.setIn(['hotCountry', 'selectedTimeItems'], Immutable.fromJS(action.selectedTimeItems));
		case LIST_TYPES.CHANGE_COUNRTY_SOURCE:
			return state.setIn(['hotCountry', 'countrySource'], action.countrySource);
		case LIST_TYPES.CHANGE_INPUT_STRING:
			return state.setIn(['hotCountry', 'inputString'], action.inputString);
		//两舱推荐
		case LIST_TYPES.CHANGE_APPEND_SHOW_MORE_VISIBILITY:
			return state.setIn(['recommend', 'appendShowMore'], action.visible);
		case LIST_TYPES.HIGH_CABIN_TOGGLE_EXTRA_REC_FLIGHTS:
			return state.setIn(
				['recommend', 'expandExtraRecFlights'],
				!state.getIn(['recommend', 'expandExtraRecFlights'])
			);
		//搜索框focus
		case LIST_TYPES.CLEAR_FOCUS_NEXT_INPUT:
			return state.setIn(['search', 'focusNextInput'], '');
		case LIST_TYPES.FLIGHT_GIFT_DETAIL:
			return state.set('gift', Immutable.fromJS(action.data));
		case LIST_TYPES.STAY_TIME_OUT_FLAG:
			return state
				.setIn(['popups', 'stayTimeoutFlag'], action.stayTimeoutFlag)
				.setIn(['splitRound', 'flights', 'acitveSegmentSeq'], 0);
		case LIST_TYPES.PAGE_FOOTER_DATA:
			return state.set('footer', Immutable.fromJS(action.data));
		case LIST_TYPES.SET_GUIDE_LINE: {
			//  priceChangeHistory, pricePrediction, travelTips
			let stateAfterUpdate = state;

			if (action.priceChangeHistory) {
				stateAfterUpdate = stateAfterUpdate.setIn(
					['guideLine', 'priceChangeHistory'],
					Immutable.fromJS(action.priceChangeHistory)
				);
			}

			if (action.pricePrediction) {
				stateAfterUpdate = stateAfterUpdate.setIn(
					['guideLine', 'pricePrediction'],
					Immutable.fromJS(action.pricePrediction)
				);
			}

			if (action.travelTips) {
				if (!action.travelTips.hotSpotsList) {
					action.travelTips.hotSpotsList = [];
				}
				stateAfterUpdate = stateAfterUpdate.setIn(
					['guideLine', 'travelTips'],
					Immutable.fromJS(action.travelTips)
				);
			}

			return stateAfterUpdate;
		}
		case LIST_TYPES.SET_BEST_CHOICE_FLIGHTS_FORCE_TOP: {
			return state.setIn(['globalSwitch', 'bestChoiceFlightsForceTop'], action.forceTop);
		}
		case LIST_TYPES.SET_SEARCH_CRITERIA_TOKEN: {
			return state.setIn(['search', 'searchCriteriaToken'], action.searchCriteriaToken);
		}
		case LIST_TYPES.SET_ROUTER_HISTORY: {
			return state.setIn(['router', 'history'], action.history);
		}
		case LIST_TYPES.SET_SKIP_AUTO_RESEARCH: {
			return state.setIn(['search', 'skipAutoReSearch'], action.skipAutoReSearch);
		}
		case LIST_TYPES.FIX_THE_TOP: {
			const fixTheTop = state.getIn(['fixTheTop']),
				newFixSortBar =
					typeof action.fixSortBar === 'undefined' ? fixTheTop.get('fixSortBar') : action.fixSortBar,
				newFixLowPriceCalendar =
					typeof action.fixLowPriceCalendar === 'undefined'
						? fixTheTop.get('fixLowPriceCalendar')
						: action.fixLowPriceCalendar,
				stateAfterSetFix = state
					.setIn(['fixTheTop', 'fixSortBar'], newFixSortBar)
					.setIn(['fixTheTop', 'fixLowPriceCalendar'], newFixLowPriceCalendar);

			if (
				(newFixSortBar || newFixLowPriceCalendar) &&
				stateAfterSetFix.getIn(['calendar_lowprice', 'showCalendarBody'])
			) {
				return stateAfterSetFix.setIn(['calendar_lowprice', 'showCalendarBody'], false);
			} else {
				return stateAfterSetFix;
			}
		}
		case LIST_TYPES.UPDATE_SELECTED_FLIGHT: {
			const splitRoundFlights = getIfSplitRoundFlightsFromState(state);
			const seq = action.seq;
			const selectedFlight = state.getIn(['flights', 'selected', 'flights']);

			if (splitRoundFlights && seq == 0 && selectedFlight) {
				let flights = action.flights;
				let updateFlight = flights.find(
					(flight) => flight.get('itineraryId') == selectedFlight.get('itineraryId')
				);
				if (updateFlight) {
					return state
						.updateIn(['splitRound', 'flights', 'selected', 'flights'], (_flights) =>
							merge(_flights, updateFlight)
						)
						.updateIn(['flights', 'selected', 'flights'], (_flights) => merge(_flights, updateFlight));
				}
			}
			return state;
		}
		case LIST_TYPES.UPDATE_SWITCH_VALUE: {
			const { key, value: newValue } = action;
			const currentValue = state.getIn(['globalSwitch', key]);
			if (newValue !== currentValue) {
				return state.setIn(['globalSwitch', key], newValue);
			} else {
				return state;
			}
		}
		case LIST_TYPES.SET_ACTIVE_ROUND_TAB_INDEX: {
			return state.setIn(['splitRound', 'flights', 'acitveSegmentSeq'], action.acitveSegmentSeq);
		}
		case searchBoxTypes.SET_DOMESTIC_CITY_DATA:
			return state.setIn(['search', 'domesticCityData'], action.data);
		case searchBoxTypes.SET_FLIGHT_SAMECITY_DATA:
			return state.setIn(['search', 'flightSameCityData'], action.data);
		case LIST_TYPES.SET_ARMY:
			return state.setIn(['army'], action.army ? '1' : '');
		case LIST_TYPES.SET_GIFT_IDS:
			return state.setIn(['giftIdList'], action.ids);
		case LIST_TYPES.FAKE_PRICE_MARK:
			return state.setIn(['flights', 'lgn'], action.payload);
		case LIST_TYPES.SEARCH_INTL_PRICE_LIST: {
			const theFlight = action.payload;
			return state.setIn(['flights', 'intlPrice', theFlight.get('itineraryId')], theFlight.get('priceList'));
		}
		default:
			return state;
	}
}
