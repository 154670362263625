import Enum from 'enum';
import React from 'react';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import Tooltip from '../../../components/list/common/tooltip';
import { PASSENGER_TYPE } from '../../common/enum/common';
import PriceBox from '../../../components/list/result/price/PriceBox';
/**
 * 获取指定乘客类型的价格HOVER行
 * @param {*乘客类型枚举} passengerTypeEnum
 * @param {*乘客人数} passengerCount
 * @param {*最低价对应的价格信息} theMinPriceItem
 * @param {*排序是否含税} sortContainsTax
 */
const getPriceTooltipDetails = (adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax) => {
	let getTheDetail = (passengerCount, passengerTypeEnum) => {
		let passengerType = passengerTypeEnum.value.key;
		if (passengerCount) {
			return (
				<div key={passengerType} className={`passenger-info ${!sortContainsTax ? 'with-tax' : ''}`}>
					<div className="passenger-type">{passengerTypeEnum.value.title}</div>
					<div className="passenger-price">
						<dfn>&yen;</dfn>
						{theMinPriceItem.get(`${passengerType}Price`) +
							(sortContainsTax ? theMinPriceItem.get(`${passengerType}Tax`) : 0)}{' '}
						{sortContainsTax ? null : ` +${theMinPriceItem.get(`${passengerType}Tax`)}税费`}
						<span className="num">&times;{passengerCount}人</span>
					</div>
				</div>
			);
		}
	};
	return [
		getTheDetail(adultCount, PASSENGER_TYPE.ADULT),
		getTheDetail(childCount, PASSENGER_TYPE.CHILD),
		getTheDetail(infantCount, PASSENGER_TYPE.INFANT),
	];
};

export const getDomesticFlightPriceDetail = (
	{
		adultCount,
		childCount,
		infantCount,
		priceItem,
		flightWay,
		cabinEnum,
		allSegmentList,
		isMinPrice,
		renderFlightPrice = false,
		isMinPriceInTheFlight,
		servicePackagItem,
		showCabin,
		index,
		travelPackageWrapper,
	},
	getLotteryInfo,
	getOptmizedTag
) => {
	let result = null;

	let travelPackageList = travelPackageWrapper?.data?.hoverDataList || [];
	let flightPriceItem = travelPackageList.filter((item) => item.type == '10'),
		travelPackageItem = travelPackageList.find((item) => item.type == '11'),
		travelPackageDescribeItem = {
			title: travelPackageList.find((item) => item.type == '12')?.title?.split('|')?.[0],
			item: travelPackageList.find((item) => item.type == '12')?.title?.split('|')?.[1],
		},
		showPriceLayer = !!flightPriceItem?.length && travelPackageItem && window.GlobalSwitches.newTravelPackageLayer,
		limitTime = travelPackageList.find((item) => item.type === '13'),
		discountAfter = travelPackageList.find((item) => item.type === '14');
	const removeYen = (str) => {
		let newStr = str || '';
		return newStr.replace('&yen;', '');
	};

	// 是否有标题，用来区分列表的位置
	// let {title} = priceItem.get('sortTypeGroupEnum').value;
	let getPriceItem = (passengerCount, passengerTypeEnum, soldOut) => {
		let passengerType = passengerTypeEnum.value.key,
			disabledBook = priceItem.get(`${passengerType}DisabledBook`);
		if (passengerCount) {
			const title = passengerTypeEnum.value.title,
				price = priceItem.get(`${passengerType}Price`);
			if (passengerTypeEnum.value.key == 'adult') {
				const travelPackageDescribeItemBox =
					travelPackageDescribeItem?.title && travelPackageDescribeItem?.item ? (
						<>
							<div className="describe-info-line" />
							<div className="describe-info">
								<span>{travelPackageDescribeItem.title}：</span>
							</div>
							<div className="describe-info">
								<span>{travelPackageDescribeItem.item}</span>
							</div>
						</>
					) : null;
				const priceItemList = (flightPriceItem || []).map((PriceItem) => ({
					title: PriceItem?.title || '',
					item: removeYen(PriceItem?.item || ''),
				}));
				const traceData = {
					isTravelPackage: 1,
					source: 1,
					promoteInfoList: [
						...priceItemList,
						{
							title: travelPackageItem?.title || '',
							item: removeYen(travelPackageItem?.item || ''),
						},
					],
				};
				return (
					<div className={`price over-size ${isMinPrice || isMinPriceInTheFlight ? 'low-price' : ''}`}>
						<span className="price" id={`travelPackage_price_${index}`}>
							<dfn>&yen;</dfn>
							{price}
						</span>
						{showPriceLayer && (
							<ReactToolTip
								splitRound={true}
								traceKey="S_FLT_N_Online_Middle_Below_Price_Discount_info"
								traceData={traceData}
								parentId={`travelPackage_price_${index}`}
								key={`travelPackage_price_${index}`}
								toolTipContent={
									<Tooltip extClass="tooltip-bookAndDiscount">
										<div className="content">
											<div className="travelPack-main-title">价格说明</div>
											<div className="travelPack-content-body">
												<PriceBox
													flightPriceItem={flightPriceItem}
													travelPackageItem={travelPackageItem}
													limitTime={limitTime}
												></PriceBox>
												{discountAfter && (
													<div className="after-price-style">
														<span className="after-price-title">
															{discountAfter?.title}
														</span>
														<span className="after-main-price">
															¥<span>{removeYen(discountAfter?.item) || ''}</span>
														</span>
													</div>
												)}
												{travelPackageDescribeItemBox}
											</div>
										</div>
									</Tooltip>
								}
							/>
						)}
						{/* 航班卡片上的运价才有'起', 婴童查询不显示起 */}
						{renderFlightPrice && !(childCount || infantCount) ? <span className="qi">起</span> : null}
					</div>
				);
			} else {
				return (
					<span className={`passenger-type-price ${disabledBook ? 'high-light' : ''}`}>
						{title}
						{soldOut && !renderFlightPrice ? (
							<React.Fragment>票已售罄</React.Fragment>
						) : !disabledBook ? (
							<span className={`price ${isMinPriceInTheFlight ? 'low-price' : ''}`}>&yen;{price}</span>
						) : (
							'不可订'
						)}
					</span>
				);
			}
		}
	};

	let getCabinAndCabinRate = () => {
		let allCurrentSegmentCabinEnumList = priceItem.get('allCurrentSegmentCabinEnumList'),
			// 运价的仓等是不是和搜索的仓等一致
			sameCabinWithSearchCabin = allCurrentSegmentCabinEnumList.every(
				(cabin) => cabinEnum.value.includeKeys.includes(cabin.key) == true
			),
			result = [];
		if (
			sameCabinWithSearchCabin &&
			allSegmentList.every((seg) =>
				seg.get('flightList').every((flight) => flight.get('trafficType') == 'FLIGHT')
			)
		) {
			result = allCurrentSegmentCabinEnumList.map((cabin, index) => {
				let containDiscountRateCabin = priceItem.getIn(['priceUnitList', 0, 'flightSeatList']).find((seat) => {
					let discountRate = seat.get('discountRate');
					return (
						seat.get('segmentNo') == 1 && //单程，情况下才显示运价折扣
						seat.get('sequenceNo') == index + 1 &&
						discountRate &&
						discountRate < 1
					);
				});
				// 中转航班不显示折扣
				if (containDiscountRateCabin && allCurrentSegmentCabinEnumList.size < 2) {
					let discountRate = containDiscountRateCabin.get('discountRate');
					discountRate =
						(discountRate * 10).toString().length > 5 ? (discountRate * 10).toFixed(1) : discountRate * 10;
					return (
						<div className="sub-price-item" key={index}>
							{cabin.value.title}
							{`${discountRate}折`}
						</div>
					);
				} else {
					return (
						<div className="sub-price-item" key={index}>
							{cabin.value.title}
						</div>
					);
				}
			});
		}
		return result;
	};
	// let getLowCabinIsShowOut = () => {
	//     // 搜索低仓，航班运价都是高仓，显示经济舱已经售罄
	//     return !cabinEnum.value.isHighCabin && !containsLowCabin ? <div className="sub-price-item">经济舱已售罄</div> : null
	// }
	let getServicePackage = () => {
		if (servicePackagItem) {
			return (
				<div className="sub-price-item high-light">
					+¥{servicePackagItem.price}
					{servicePackagItem.packageTitle}
				</div>
			);
		}
		return null;
	};

	const infantSoldOut = priceItem.get('infantSoldOut'),
		adultDetail = getPriceItem(adultCount, PASSENGER_TYPE.ADULT),
		childDetail = getPriceItem(childCount, PASSENGER_TYPE.CHILD),
		infantDetail = getPriceItem(infantCount, PASSENGER_TYPE.INFANT, infantSoldOut),
		hasChildOrInfant = childDetail || infantDetail,
		// lowCabinShowOut = renderFlightPrice && getLowCabinIsShowOut(),
		lowCabinShowOut = null,
		wrapperClass = `flight-price ${renderFlightPrice ? 'domestic-flight-price' : 'domestic-cabin-price'}`;

	switch (flightWay) {
		case 'OW':
			// 国内单程航班如果有选择婴儿儿童就显示婴儿儿童价格相关，没有就显示仓等和折扣信息
			result = (
				<div className={wrapperClass}>
					{adultDetail}
					<div className="sub-price-detail">
						{hasChildOrInfant ? (
							<React.Fragment>
								<div className="sub-price-item">
									{childDetail} {infantDetail}
								</div>
							</React.Fragment>
						) : showCabin === false ? null : (
							getCabinAndCabinRate()
						)}
						{lowCabinShowOut}
						{getServicePackage()}
						{getLotteryInfo}
						{getOptmizedTag}
					</div>
				</div>
			);
			break;
		case 'RT':
			result = (
				<div className={wrapperClass}>
					{adultDetail}
					<div className="sub-price-detail">
						{showCabin === false ? null : <div className="sub-price-item">往返总价</div>}
						<div className="sub-price-item">
							{childDetail} {infantDetail}
						</div>
						{lowCabinShowOut}
						{getServicePackage()}
						{/* {getLotteryInfo} */}
						{getOptmizedTag}
					</div>
				</div>
			);
			break;
		case 'MT':
			result = (
				<div className={wrapperClass}>
					{adultDetail}
					<div className="sub-price-detail">
						<div className="sub-price-item">两程总价</div>
						<div className="sub-price-item">
							{childDetail} {infantDetail}
						</div>
						{lowCabinShowOut}
						{getServicePackage()}
						{/* {getLotteryInfo} */}
						{getOptmizedTag}
					</div>
				</div>
			);
			break;
	}
	return result;
};

const getPriceTooltipFooter = (flightWayEnum, theMinPriceItem, sortContainsTax) => {
	return (
		<div className="passenger-info">
			<div className="passenger-type">
				{flightWayEnum.value.priceTooltipTitle}人均{sortContainsTax ? '含税价' : '票价+税费'}
			</div>
			<div className="passenger-price">
				<dfn>&yen;</dfn>
				{theMinPriceItem.get(sortContainsTax ? 'avgPriceWithTax' : 'avgPriceWithoutTax')}{' '}
				{sortContainsTax ? '' : ` +${theMinPriceItem.get('avgTax')}税费`}
			</div>
		</div>
	);
};

export const FLIGHT_WAY = new Enum({
	OW: {
		key: 'S',
		title: '单程',
		priceTooltipTitle: '',
		getBookBtnText: (isExpand, _isBuildUpMode, _currentSegmentSeq) => (isExpand ? '收起' : '预订'),
		getPriceText: (isMutliplePassengerType, sortContainsTax) =>
			`${isMutliplePassengerType ? '人均' : ''}${sortContainsTax ? '含税价' : '票价'}`,
		getTaxText: (isMutliplePassengerType) => `${isMutliplePassengerType ? '人均' : ''}税费`,
		getPriceTooltip: (adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax) => (
			<div className="tooltip tooltip-diffpassenger">
				<div className="tooltip-content">
					{getPriceTooltipDetails(adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax)}
					{getPriceTooltipFooter(FLIGHT_WAY.OW, theMinPriceItem, sortContainsTax)}
				</div>
			</div>
		),
		getDomesticPrice: getDomesticFlightPriceDetail,
	},
	RT: {
		key: 'D',
		title: '往返',
		priceTooltipTitle: '往返',
		getBookBtnText: (isExpand, isBuildUpMode, currentSegmentSeq) =>
			isBuildUpMode ? '预订' : currentSegmentSeq === 0 ? '选为去程' : isExpand ? '收起' : '',
		getPriceText: (isMutliplePassengerType, sortContainsTax) =>
			`往返${isMutliplePassengerType ? '人均' : ''}${sortContainsTax ? '含税价' : '票价'}`,
		getTaxText: (isMutliplePassengerType) => `${isMutliplePassengerType ? '人均' : ''}${'往返税费'}`,
		getPriceTooltip: (adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax) => (
			<div className={`tooltip tooltip-diffpassenger`}>
				<div className="tooltip-content">
					{getPriceTooltipDetails(adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax)}
					{getPriceTooltipFooter(FLIGHT_WAY.RT, theMinPriceItem, sortContainsTax)}
				</div>
			</div>
		),
		getDomesticPrice: getDomesticFlightPriceDetail,
	},
	MT: {
		key: 'M',
		title: '多程(含缺口程)',
		priceTooltipTitle: '多程',
		getBookBtnText: (isExpand, isBuildUpMode, currentSegmentSeq) =>
			isExpand ? '收起' : `选为第${currentSegmentSeq + 1}程`,
		getPriceText: (isMutliplePassengerType, sortContainsTax) =>
			`多程${isMutliplePassengerType ? '人均' : ''}${sortContainsTax ? '含税价' : '票价'}`,
		getTaxText: (isMutliplePassengerType) => `${isMutliplePassengerType ? '人均' : ''}多程税费`,
		getPriceTooltip: (adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax) => (
			<div className="tooltip tooltip-diffpassenger">
				<div className="tooltip-content">
					{getPriceTooltipDetails(adultCount, childCount, infantCount, theMinPriceItem, sortContainsTax)}
					{getPriceTooltipFooter(FLIGHT_WAY.MT, theMinPriceItem, sortContainsTax)}
				</div>
			</div>
		),
		getDomesticPrice: getDomesticFlightPriceDetail,
	},
});

export const PROVINCE = new Enum({
	HK: {
		id: 32,
		name: '香港',
	},
	MACAO: {
		id: 33,
		name: '澳门',
	},
	TW: {
		id: 53,
		name: '台湾',
	},
});

export const COUNTRY = new Enum({
	CN: {
		id: 1,
		name: '中国',
	},
});

/**
 *延时修改内容的类型，此枚举目的在于减少实例数量
 */
export const DEFFRED_CONTENT_TYPE = new Enum({
	PRICE_TAG: 0,
	PENALTY_DETAIL: 1,
	BAGGAGE_DETAIL: 2,
});

/**
 * 中转次数
 */
export const TRANS_COUNT = new Enum({
	ZERO: {
		min: 0,
		max: 0,
		title: '直飞',
	},
	ONE: {
		min: 1,
		max: 1,
		title: '1程中转',
	},
	MORE: {
		min: 2,
		max: 100,
		title: '2程中转及以上',
	},
});

/**
 * 筛选框显示方式，分宽屏、窄屏两种
 */
export const FILTER_DISPLAY_MODE = new Enum({
	WIDE: {
		remark: '宽屏版',
	},
	NARROW: {
		remark: '窄屏版',
	},
});

/**
 * 接送机车型类别
 */
export const CAR_TYPE = new Enum({
	5: {
		text: '奢华',
	},
	10: {
		text: '奔驰',
	},
	100: {
		text: '豪华',
	},
	200: {
		text: '舒适',
	},
	300: {
		text: '经济',
	},
});

/**
 * 接送机航程类别
 */
export const FREE_PICK_WAY = new Enum({
	1: {
		LABEL_WAY: '去程送',
		HOVER_WAY: '送',
	},
	2: {
		LABEL_WAY: '返程接',
		HOVER_WAY: '接',
	},
	BOTH: {
		LABEL_WAY: '往返接送',
		HOVER_WAY: '接送',
	},
});

/**
 * 通港车船票类别
 */
export const BUS_BOAT_TYPE = new Enum({
	bus: '车',
	boat: '船',
	busboat: '车船',
});

export const UBT_SOURCE_RECOMMEND_STORAGE_KEY = 'ubtSourceRecommendStorageKey';

export const D_RECOMMON_GROUP_TYPE = {
	NEAR_BY_RECOMMEND: {
		title: '邻近航线',
		includeKeys: [
			'D_NEAR_FLIGHT', //临近直飞
			'D_NEAR_TIME', //临近时间
			'D_ROUND_NEAR', //往返临近
			'D_ROUND_NEAR_TIME', //往返时间临近
			'D_OPEN_JAW', //缺口程
		],
		className: 'nearby-recommend',
	},
	TRAIN: {
		title: '火车直达',
		includeKeys: ['D_TRAIN'],
		className: '',
	},
	// 暂时隐藏二期做
	// OW_TO_RT: {
	//     title: '往返特惠',
	//     includeKeys: ['D_SINGLE2_ROUND'],
	//     className: 'rt-recommend'
	// }
};
const recommendFlightInAnyGroup = (type) => {
	let recommendKey = Object.keys(D_RECOMMON_GROUP_TYPE).find((key) => {
		let { includeKeys } = D_RECOMMON_GROUP_TYPE[key];
		if (includeKeys && includeKeys.indexOf(type) >= 0) {
			return true;
		}
		return false;
	});
	if (recommendKey) {
		return D_RECOMMON_GROUP_TYPE[recommendKey];
	} else {
		return null;
	}
};
const setFlightIntoGroup = (groups, theGroup, recommendFlight) => {
	let { title, className } = theGroup,
		exitTheGroupFlights = false;

	groups = groups.map((group) => {
		let { flights } = group;
		if (group.title == title) {
			exitTheGroupFlights = true;
			return {
				title,
				className,
				flights: [...flights, recommendFlight],
			};
		} else {
			return group;
		}
	});
	if (!exitTheGroupFlights) {
		groups.push({ title, className, flights: [recommendFlight] });
	}
	return groups;
};
// 对返回的推荐进行分组，设置侧边栏推荐的标题（近邻航线（临近里有多种临近），火车直达）

export const groupDomesticRecommend = (recommendFlightList) => {
	let newGroupList = [];
	if (recommendFlightList && recommendFlightList.length) {
		newGroupList = recommendFlightList.reduce((groups, recommendFlight) => {
			let { recommendType } = recommendFlight;
			let theGroup = recommendFlightInAnyGroup(recommendType);
			if (theGroup) {
				groups = setFlightIntoGroup(groups, theGroup, recommendFlight);
			}
			return groups;
		}, []);
	}
	return newGroupList;
};
