import React from "react"
import { connect } from "react-redux"
import { sendBlockTraceInHome } from '@/src/utils/getSpecialBlockBasicInfo';
import { BlockTypeValue, SubTypeValue } from '@/src/utils/types';
import PassengerCount from "./PassengerCount"
import { PASSENGER_TYPE } from "../../../constants/common/enum/common"
import OutsideClickHandler from '../OutsideClickHandler'
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes'

class PassengerCountContainer extends React.Component {
    constructor(props) {
        super(props)
        let { adtCount, chiCount, infCount } = this.props.passenger
        this.state = {
            dropdownDisplay: null,
            error: false,
            newAdtCount: adtCount,
            newChiCount: chiCount,
            newInfCount: infCount,
            adultDisable: false,
            childrenDisable: false,
            infantDisable: false,
            warn: '',
            disableSubmit: false,
            showInstructions: false
        }
        this.onOutsideClick = this.onOutsideClick.bind(this)
        this.showDropdown = this.showDropdown.bind(this)
        this.hideDropdown = this.hideDropdown.bind(this)
        this.onSelectActive = this.onSelectActive.bind(this)
        this.onRestPassengerCount = this.onRestPassengerCount.bind(this)
        this.onSetPassengerCount = this.onSetPassengerCount.bind(this)
        this.onHandleSubmit = this.onHandleSubmit.bind(this)
        this.onHandleSubmitDomestic = this.onHandleSubmitDomestic.bind(this)
    }
    componentWillMount() {
        this.disablePlus(this.props.adtCount, this.props.chiCount, this.props.infCount)
        this.validateCount(this.props.adtCount, this.props.chiCount, this.props.infCount)

    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.passenger != this.props.passenger) {
            this.setState({
                newAdtCount: nextProps.passenger.adtCount,
                newChiCount: nextProps.passenger.chiCount,
                newInfCount: nextProps.passenger.infCount
            })
            this.disablePlus(nextProps.adtCount, nextProps.chiCount, nextProps.infCount)
            this.validateCount(nextProps.adtCount, nextProps.chiCount, nextProps.infCount)
        }

    }
    showDropdown() {
        this.setState({ dropdownDisplay: !this.state.dropdownDisplay })
    }

    hideDropdown() {
        this.state.dropdownDisplay && this.setState({ dropdownDisplay: false })
    }
    onOutsideClick() {
        this.onRestPassengerCount()
        this.hideDropdown()
    }
    onSelectActive() {
        this.showDropdown()
    }

    onRestPassengerCount() {
        let { adtCount, chiCount, infCount } = this.props.passenger

        this.setState({
            newAdtCount: adtCount,
            newChiCount: chiCount,
            newInfCount: infCount
        })
        this.disablePlus(adtCount, chiCount, infCount)
        this.validateCount(adtCount, chiCount, infCount)
        this.hideDropdown()
    }

    disablePlus(newAdtCount, newChiCount, newInfCount) {
        let adultDisable = false,
            childrenDisable = false,
            infantDisable = false
        if (newAdtCount >= 9) {
            adultDisable = true
            childrenDisable = true
        }
        if (newAdtCount + newChiCount >= 9) {
            adultDisable = true
            childrenDisable = true
        }
        if (newInfCount >= 4) {
            infantDisable = true
        }
        this.setState({
            adultDisable: adultDisable,
            childrenDisable: childrenDisable,
            infantDisable: infantDisable
        })
    }
    validateCount(newAdtCount, newChiCount, newInfCount) {
        let warn = '',
            disableSubmit = false,
            subType = ''

        if ((newChiCount > newAdtCount * 2 || newInfCount > newAdtCount) && newAdtCount > 0) {
            warn = `每名成人最多可携带2名儿童和1名婴儿乘机。`
            subType = SubTypeValue.AdultChildRatio
            disableSubmit = true
        } else if (newAdtCount == 0 && newInfCount == 0 && newChiCount > 0) {
            warn = `12周岁以下儿童乘坐国际航班需有18周岁以上成人陪伴，如需单独乘机，请直接联系航司预订。`
            subType = SubTypeValue.NoAdultPsg
        } else if (newAdtCount == 0 && newInfCount > 0 && newChiCount > 0) {
            warn = `儿童婴儿须有成人陪伴乘机。`
            subType = SubTypeValue.NoAdultPsg
            disableSubmit = true
        } else if (newAdtCount == 0 && newInfCount > 0 && newChiCount == 0) {
            warn = `如需单独预订婴儿票，请致电携程95010。`
            subType = SubTypeValue.InfantNotAllowed
            disableSubmit = true
        } else if (newAdtCount == 0 && newInfCount == 0 && newChiCount == 0) {
            warn = `请选择实际出行人数。`
            subType = SubTypeValue.NoPsgSelected
            disableSubmit = true
        }

        const { flightWay, dCityText = '', aCityText = '', domestic, cabinType} = this.props || {};
        subType && sendBlockTraceInHome({
				domestic,
                adtCount: newAdtCount,
                chiCount: newChiCount,
                infCount: newInfCount,
                flightWay,
                cabinType,
                blockType: BlockTypeValue.Focus,
                subType,
                dCityText,
                aCityText
   		});
       this.setState({
            warn: warn,
            disableSubmit: disableSubmit
        })

    }
    onSetPassengerCount(type, oldCount, addCount) {
        let count = oldCount + addCount
        if (count < 0) {
            return
        }
        let { newAdtCount, newChiCount, newInfCount } = this.state


        if (addCount > 0) {
            switch (type) {
                case PASSENGER_TYPE.ADULT:
                    if (!this.state.adultDisable) {
                        newAdtCount = count
                    }
                    break;
                case PASSENGER_TYPE.CHILD:
                    if (!this.state.childrenDisable) {
                        newChiCount = count
                    }
                    break;
                case PASSENGER_TYPE.INFANT:
                    if (!this.state.infantDisable) {
                        newInfCount = count
                    }
                    break;
                default:
                    break;
            }

        } else {
            switch (type) {
                case PASSENGER_TYPE.ADULT:
                    newAdtCount = count
                    break;
                case PASSENGER_TYPE.CHILD:
                    newChiCount = count
                    break;
                case PASSENGER_TYPE.INFANT:
                    newInfCount = count
                    break;
                default:
                    break;
            }
        }
        this.disablePlus(newAdtCount, newChiCount, newInfCount)
        this.validateCount(newAdtCount, newChiCount, newInfCount)
        this.setState({
            newAdtCount, newChiCount, newInfCount
        })
    }

    onHandleSubmit() {
        let adtCount = this.state.newAdtCount,
            chiCount = this.state.newChiCount,
            infCount = this.state.newInfCount

        this.props.onSubmitPassengerTypeCount({ adtCount, chiCount, infCount }, this.props.domestic)
        this.hideDropdown()
        SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({
            tag: {
                adtCount: adtCount,
                chiCount: chiCount,
                infCount: infCount,
            }
        })
    }
    onHandleSubmitDomestic(passenger) {
        this.props.onSubmitPassengerTypeCount(passenger, this.props.domestic)
        SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT.value.process({ tag: passenger })
    }

    render() {
        return (
            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                <PassengerCount
                    {...this.props}
                    {...this.state}
                    onRestPassengerCount={this.onRestPassengerCount}
                    onSetPassengerCount={this.onSetPassengerCount}
                    onHandleSubmit={this.onHandleSubmit}
                    onHandleSubmitDomestic={this.onHandleSubmitDomestic}
                    onSelectActive={this.onSelectActive}
                />
            </OutsideClickHandler>

        )
    }
}


export default connect(null, null)(PassengerCountContainer)
