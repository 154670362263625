import React from 'react';
// import { View, Text } from '@tarojs/components';

// import { getRoutePath } from '@/src/common/utils/utils';

import { resolveValue, IModal, Handlers } from '../types';
import { useToaster } from '../core/use-toaster';
import { getPositionStyle } from '../core/utils';

import './modaler.scss';

interface Props {
    options: IModal;
    handlers: Handlers;
}

// function Mask({ options, handlers }: Props) {
//     const { id, mask } = options;
//     const { enableClose = true, style, callback } = mask || {};
//     return (
//         <div
//             className="taro-modal-mask"
//             style={{ ...style }}
//             onClick={() => {
//             	enableClose && handlers.close(id);
//             	callback && callback();
//             }}
//             catchMove
//         ></div>
//     );
// }

function Mask({ options }: Props) {
    const { mask } = options;
    const { style } = mask || {};
    return (
        <div
            className="taro-modal-mask"
            style={{ ...style }}
        ></div>
    );
}

function Close({ options, handlers }: Props) {
    const { close, id } = options;
    if (!close) {
        return null;
    } else {
        const { style, position = '', callback } = close;
        const verticalStyle: React.CSSProperties = position.includes('top') ? { top: 0 } : { bottom: 0 };
        const horizontalStyle: React.CSSProperties = position.includes('left') ? { left: 0 } : { right: 0 };
        return (
            <div
                className="modal-close"
                style={{ ...verticalStyle, ...horizontalStyle, ...style }}
                onClick={() => {
                    handlers.close(id);
                    callback && callback();
                }}
            >
                <span className="iconfont-h5 icon-close"></span>
            </div>
        );
    }
}

function Title({ options }: { options: IModal }) {
    const { title } = options;
    if (!title) {
        return null;
    }

    if (typeof title === 'string') {
        return (
            <div className="modal-title">
                <span>{title}</span>
            </div>
        );
    } else {
        return title;
    }
}

function Content({ options }: { options: IModal }) {
    const { content } = options;
    if (!content) {
        return null;
    }
    if (typeof content !== 'string') {
        return resolveValue(content as JSX.Element, options);
    }

    return (
        <div className="modal-content">
            <span>{content}</span>
        </div>
    );
}

function Buttons({ options, handlers }: Props) {
    const { buttons, autoClose, id } = options;

    if (!buttons || buttons.length <= 0) {
        return null;
    }

    function wrapClick(fn) {
        if (autoClose) {
            handlers.close(id);
            fn && fn();
        } else {
            fn && fn();
        }
    }
    return (
        <div className="modal-btns">
            {buttons.map((config) => {
                return (
                    <span
                        key={config.text}
                        className={`modal-btn ${config.class ? config.class : ''}`}
                        style={{ ...config.style }}
                        onClick={() => wrapClick(config.callback)}
                    >
                        {config.text}
                    </span>
                );
            })}
        </div>
    );
}

// {
// 	// toastOptions,
// 	// containerStyle,
// 	// containerClassName,
// }: {
// 	// toastOptions?: ModalOptions;
// 	// containerStyle?;
// 	// containerClassName?;
// }
export const Modaler = () => {
    // const pageName = useMemo(() => getRoutePath(Math.random().toString()), []);
    const pageName = 'booking';
    const { modals = [], handlers } = useToaster({ name: pageName });
    // ${containerClassName || ''}`} style={{ ...containerStyle }}

    return (
        <div className="taro-modal-wrapper">
            {modals &&
                modals.reverse().map((m) => {
                    const { animationClassName = '', position, style, id } = m;
                    const animationCls =
                        animationClassName ||
                        (position === 'bottom' ? 'animate__animated animate__slideInUp animate__faster' : '');

                    return (
                        <div className={`taro-modal-container ${m && m.className ? m.className : ''}`} key={id}>
                            <div className="taro-modal-content">
                                <Mask options={m} handlers={handlers} />
                                <div
                                    className={`flex-content ${animationCls}`}
                                    style={{ ...getPositionStyle(position), ...style }}
                                >
                                    <Close options={m} handlers={handlers} />
                                    <Title options={m} />
                                    <Content options={m} />
                                    <Buttons options={m} handlers={handlers} />
                                </div>
                            </div>
                        </div>
                    );
                })}
        </div>
    );
};
