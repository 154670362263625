
import Enum from 'enum'
import React from 'react'
import { getIEVersion } from '../../../sources/common/getIEVersion'
/**
 * 舱等tab切换数据
 */


export const WARNS = new Enum({
    D_CITY_EMPTY: '请选择出发城市',
    A_CITY_EMPTY: '请选择目的城市',
    D_DATE_EMPTY: '请选择出发日期',
    D_DATE_IS_BEFORE_TODAY: '对不起，出发日期不能早于今天',
    D_DATE_IS_NOT_VALID: '日期格式有误，请检查',
    D_CITY_EQUAL_A_CITY: '您选择的出发城市和到达城市相同，请重新选择',
    SAME_CITY_NOT_AIRLINE: '该城市暂无同城航线',
    NOT_SUPPORT_AIRLINE: '该航段暂不支持往返航线',
    NOT_ALLOW_SAME_AIRPORT: '出发和到达不能为同一机场',
    MORE_THAN_A_YEAR: '对不起，只能查询一年内航班',
    C: '公务舱',
    F: '头等舱'
})
export const SEARCH_FORM_INPUTS = [
    'dCity',
    'aCity',
    'dDate',
    'aDate',
]

export const DOMESTIC_PASSENGER_TYPE = new Enum({
    HAS_CHILD: {
        tip: '2~12岁',
        display: '带儿童',
        name: 'chiCount'
    },
    HAS_BABY: {
        tip: '14天-2岁',
        display: '带婴儿',
        name: 'infCount'
    }
})

export const DOMESTIC_PASSENGER_TYPE_DESCRIPTION = new Enum({
    LABEL: <React.Fragment>儿童婴儿预订说明<i className="iconf-question">&#xe606;</i></React.Fragment>,
    DESCRIPTION: <div className="fd_child_baby_tips">
        <div className="fd_rules_container">
            <div className="fd_tip_item">
                <div className="fd_tip_title fd_tip_title_child">儿童票</div>
                <table className="fd_tb_rule">
                    <tbody><tr>
                        <td className="fd_tb_rule_name">年龄规定</td>
                        <td className="fd_tb_rule_des"> <p>2岁（含）~12岁（不含）</p></td>
                    </tr>
                        <tr>
                            <td className="fd_tb_rule_name">票价</td>
                            <td className="fd_tb_rule_des">
                                <p>成人全价票的50%;</p>
                                <p>免机场管理建设费，燃油费为成人的50%;</p></td>
                        </tr>
                        <tr>
                            <td className="fd_tb_rule_name">证件</td>
                            <td className="fd_tb_rule_des"> <p>身份证、护照、户口簿</p></td>
                        </tr>
                        <tr>
                            <td className="fd_tb_rule_name">注意事项</td>
                            <td className="fd_tb_rule_des">
                                <p>每位成人旅客（满18周岁）最多携带2名儿童;</p>
                                <p className="fd_multi_row">儿童旅客必须有同舱位成人（满18周岁）陪伴乘机，无成人陪伴儿童请提前联系航空公司进行预订。</p>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
            <div className="fd_tip_item">
                <div className="fd_tip_title fd_tip_title_baby">婴儿票</div>
                <table className="fd_tb_rule">
                    <tbody><tr>
                        <td className="fd_tb_rule_name">年龄规定</td>
                        <td className="fd_tb_rule_des"><p>14天（含）~2岁（不含）</p></td>
                    </tr>
                        <tr>
                            <td className="fd_tb_rule_name">票价</td>
                            <td className="fd_tb_rule_des">
                                <p>成人全价票的10%;</p>
                                <p>免机场管理建设费、燃油费;</p></td>
                        </tr>
                        <tr>
                            <td className="fd_tb_rule_name">证件</td>
                            <td className="fd_tb_rule_des"> <p>身份证、护照、户口簿、出生证明</p></td>
                        </tr>
                        <tr>
                            <td className="fd_tb_rule_name">注意事项</td>
                            <td className="fd_tb_rule_des">
                                <p>每位成人旅客最多携带1名婴儿;</p>
                                <p className="fd_multi_row">婴儿票不提供座位，如需单独占用座位，可为婴儿购买儿童票;</p>
                            </td>
                        </tr>
                    </tbody></table>
            </div>
        </div>
        <div className="fd_rules_qa_container">
            <div className="fd_tip_title fd_qa_title">常见问题</div>
            <div className="fd_qa_item">
                <div className="fd_qa_qestion">不满14天的新生儿能乘机吗？</div>
                <div className="fd_qa_answer">新生婴儿抵抗力差，呼吸功能不完善，飞机起飞、降落时气压变化大，易对其造成伤害。因此，航空公司规定出生不足14天的新生婴儿和出生不足90天的早产婴儿不能乘机出行。若仍需要携带新生婴儿乘机，请联系航空公司。</div>
            </div>
            <div className="fd_qa_item">
                <div className="fd_qa_qestion">如何预订更优惠？</div>
                <div className="fd_qa_answer">对于国内航班，儿童、婴儿也可以购买部分价格的成人票。由于部分成人票的折扣比儿童票、婴儿票的折扣更低，因此为儿童、婴儿购买此类低折扣成人票可能更优惠，携程会智能为您推荐更优惠的购买方案。</div>
            </div>
        </div>
    </div>
})
// 统一定义display值为null时是初始状态 没有动画，false 收起， true为展示
const closeAnimate = () => {
    return getIEVersion() == 9 || !GlobalSwitches.searchBoxAnimateSwitch
}
export const GEN_SEARCH_FORM_ANIMATION = {
    CITY: (display, moveType) => {
        if (closeAnimate()) {
            return ''
        }
        // show-animate-only-border 城市选择器无动画，蓝色选中有
        // show-animate 城市选择器有动画，蓝色选中有
        // hide-move-animate 城市选择器移动后关闭，蓝色选中有
        // hide-animate 城市选择器收起动画，蓝色选中收起动画
        let move = moveType && moveType.move,
            diaplayAnimate = moveType && moveType.diaplayAnimate,
            removeHideAnimate = moveType && moveType.removeHideAnimate,
            hide = moveType && moveType.hide
        if (hide) {
            return ''
        }
        return display === null ? '' : (display === true ? (diaplayAnimate ? 'show-animate-only-border' : 'show-animate') : (move ? 'hide-move-animate' : (removeHideAnimate ? '' : 'hide-animate')))
    },
    ACITY: (display, moveType) => {
        if (closeAnimate()) {
            return ''
        }
        // show-animate-only-border 城市选择器无动画，蓝色选中有
        // show-animate 城市选择器有动画，蓝色选中有
        // hide-move-animate 城市选择器移动后关闭，蓝色选中有
        // hide-animate 城市选择器收起动画，蓝色选中收起动画
        let move = moveType && moveType.move,
            diaplayAnimate = moveType && moveType.diaplayAnimate,
            removeHideAnimate = moveType && moveType.removeHideAnimate,
            hide = moveType && moveType.hide
        if (hide) {
            return ''
        }
        return display === null ? '' : (display === true ? (diaplayAnimate ? 'show-animate-only-border' : 'show-animate') : (move ? 'hide-move-animate-a' : (removeHideAnimate ? '' : 'hide-animate')))
    },
    BORDER_TOP: (display) => {
        if (closeAnimate()) {
            return ''
        }
        return display === null ? '' : (display == true ? 'show-animate' : 'hide-animate')
    },
    PASSENGER: (display) => {
        if (closeAnimate()) {
            return ''
        }
        return display === null ? '' : (display === true ? 'show-animate' : 'hide-animate')
    },
    CALENDAR: (display, moveType) => {
        if (closeAnimate()) {
            return ''
        }
        let showAnimate = moveType && moveType.showAnimate

        if (!showAnimate && display) {
            return ""
        }
        return display === null ? '' : (display === true ? 'calendar-active-animate' : 'calendar-fade-animate')
    },
    CLASSGRADE: (display) => {
        if (closeAnimate()) {
            return ''
        }
        return display === null ? '' : (display === true ? 'show-animate' : 'hide-animate')
    }
}
