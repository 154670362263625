import { fetchGet, setGlobalSearchCriteria, setArmyToGlobalSearchCriteria } from '../common';
import URL from '../../constants/list/api';
import { UbtHandler } from '../../ubt.v2/handler';
import store from '../../stores/list';
import { genInitPrevSearchCondSuccess, genSetIsResearching } from './search';
import { genSetSkipAutoReSearch } from './router';
import { updatePageId, PAGE_IDS } from '../../sources/common/updatePageId';
import CONSTS from '../../constants/list/constConfig';
import { genCloseCalendarAndTrend } from './calendarLowprice';
import { LIST_UBT_KEY_TYPES, ListUbtGenerator, LIST_UBT_GROUP_TYPES } from '../../ubt.v2/list';
import { syncFixSelectedFlight } from '../../containers/list/result/syncFixSelectedFlight';
import { setLastGlobalSearchCriteria } from '../../sources/list/lastGlobalSearchCriteria';
import { trimUrlHost, trimUrlPageList } from '../../sources/common/trimUrlHost';
import { getIfHoldDomesticFlightSwitch, getIfScopeInternational } from '../../sources/list/scope';
import { PAGE_LIST_URL } from '../../constants/common';
import { sendUBT } from '../../sources/common/lightUbt';
import { UBT_KEY } from '../../constants/ubtKeys';
import { performanceTrace } from '../../utils/performanceTrace';

let LAST_TRIME_URL = '';
const forceReload = fullUrl => {
  window.location.href = PAGE_LIST_URL + fullUrl;
};

// const parseUrl = (url) => {
//     var anchor = document.createElement("a")
//     anchor.href = url
//
//     return anchor
// }

const getGlobalSearchCriteria = url => {
  return new Promise((resolve, reject) => {
    fetchGet(`${URL.getGlobalSearchCriteria}/${url}`)
      .then(json => json.data)
      .then(searchCriteria => {
        if (searchCriteria && searchCriteria.data) {
          resolve(searchCriteria.data);
        } else {
          reject(new Error('empty response body from getGlobalSearchCriteria.'));
        }
      })
      .catch(e => {
        reject(e);
      });
  });
};

const urlIsBuildUp = url => url && !!(url.indexOf('isbuildup=1') > 0);

const urlIsRound = url => url && (url.indexOf('round') === 0 || url.indexOf('/round') === 0);

const getIfUrlIsBuildUp = (forceBuildUp, trimedUrl) =>
  typeof forceBuildUp === 'boolean'
    ? forceBuildUp
    : !!(urlIsRound(trimedUrl) && urlIsBuildUp(trimedUrl)) ||
    !!(urlIsRound(trimedUrl) && trimUrlHost(window.location.pathname) && urlIsBuildUp(window.location.search));

let onChangeHistoryListener = null;

const unlistenChangeHistory = () => {
  if (typeof onChangeHistoryListener === 'function') {
    onChangeHistoryListener();
  }
};

const listenChangeHistory = (theHistory, onChange) => {
  onChangeHistoryListener = theHistory.listen(historyItem => {
    const { pathname, search } = historyItem;
    onChange(`${pathname}${search}`, { preventPushHistory: true });
  });
};

// 重查后更新页面title
const updateTitle = searchCriteria => {
  try {
    if (searchCriteria) {
      const { flightWay, flightSegments } = searchCriteria;
      if (flightSegments && flightSegments.length) {
        const { departureCityName, departureCityCode, arrivalCityName, arrivalCityCode } = flightSegments[0];
        if (departureCityName && departureCityCode && arrivalCityName && arrivalCityCode) {
          switch (flightWay) {
            case 'S':
              window.document.title = `${departureCityName}(${departureCityCode})到${arrivalCityName}(${arrivalCityCode})机票预订-${departureCityName}(${departureCityCode})到${arrivalCityName}(${arrivalCityCode})单程机票查询-【携程国际机票】`;
              break;
            case 'D':
              window.document.title = `${departureCityName}到${arrivalCityName}机票查询预订, ${departureCityName}到${arrivalCityName}特价往返机票【携程国际机票】`;
              break;
            case 'M':
              window.document.title = `${departureCityName}出发机票预订-${departureCityName}出发多程机票查询【携程国际机票】`;
              break;
            default:
              break;
          }
        }
      }
    }
  } catch (err) {
    UbtHandler.onUploadData('c_error', {
      action: 'update_title',
      error: err,
    });
  }
};

// 检查航程是不是境外的
const checkIfSearchInternational = searchCriteria => {
  let isNotDomestic = false;
  // 澳门台湾香港 provinceId  33 32 53  中国国家id 1
  searchCriteria.flightSegments &&
    searchCriteria.flightSegments.forEach(seg => {
      if (
        seg.departureCountryId != 1 ||
        (seg.departureCountryId == 1 &&
          (seg.departureProvinceId == 33 || seg.departureProvinceId == 32 || seg.departureProvinceId == 53))
      ) {
        isNotDomestic = true;
      }
      if (
        seg.arrivalCountryId != 1 ||
        (seg.arrivalCountryId == 1 &&
          (seg.arrivalProvinceId == 33 || seg.arrivalProvinceId == 32 || seg.arrivalProvinceId == 53))
      ) {
        isNotDomestic = true;
      }
    });
  return isNotDomestic;
};

const appendStartResearchEl = () => {
  // 添加无刷新重查起始时间点
  const id = 'no_refresh_search_start_time';
  let startTime = window.document.querySelector(`#${id}`);
  if (!startTime) {
    startTime = window.document.createElement('input');
    startTime.setAttribute('id', id);
    startTime.setAttribute('type', 'hidden');

    window.document.body.appendChild(startTime);
  }

  startTime.setAttribute('value', +new Date());
};

const removeStartResearchEl = () => {
  // 删掉无刷新重查后渲染出第一个航班的时间点
  let endTime = window.document.querySelector('#no_refresh_search_end_time');
  if (endTime) {
    endTime.parentElement.removeChild(endTime);
  }
};

// 无刷新重查入口，url包含虚拟目录
export const onResearchFlights = (url, opts = {}) => {
  //      /international/search/round-sha-hkg?depdate=2019-05-11_2019-05-17&cabin=c_f&adult=1&child=1&infant=0
  //  =>  round-sha-hkg?depdate=2019-05-11_2019-05-17&cabin=c_f&adult=1&child=1&infant=0
  // const trimedUrl = trimUrlHost(url)
  const trimedUrl = url;

  LAST_TRIME_URL = trimedUrl;
  const onForceReload = () => forceReload(trimedUrl);
  const state = store.getState();
  const pageLoadDate = state.getIn(['list', 'router', 'loadDate']);
  const army = state.getIn(['list', 'army']);
  const originalScopeInternational = getIfScopeInternational();
  setArmyToGlobalSearchCriteria(army);

  const now = new Date();
  const stayDurationInMin = (now - pageLoadDate) / (60 * 1000);
  if (stayDurationInMin >= CONSTS.SEARCH.RESEARCH_WITHOUT_REFRESH_MAX_DURATION_IN_MIN) {
    console.warn(`page has been loaded for too long time: ${stayDurationInMin}, so we force refresh.`);
    onForceReload();
  } else {
    appendStartResearchEl();
    removeStartResearchEl();
    store.dispatch(
      genSetIsResearching(
        true
      ),
    );
    getGlobalSearchCriteria(trimedUrl)
      .then(searchCriteria => {
        store.dispatch(
          genSetIsResearching(
            false
          ),
        );
        if (searchCriteria) {
          // 此变量表示当前要跳转到的航线是否国际
          const targetScopeInternational = checkIfSearchInternational(searchCriteria);

          const ifHoldDomesticFlightSwitch = getIfHoldDomesticFlightSwitch();

          // 此处做兜底，正常来说下面分支不会true：没有打开开关，同时搜索又是搜索国内。
          // 此时强制刷新，后端会跳转到国内站点
          if (!targetScopeInternational && !ifHoldDomesticFlightSwitch) {
            UbtHandler.onUploadData('c_search_city_is_domestic', { url: trimedUrl });
            onForceReload();
            return;
          }

          if (originalScopeInternational !== targetScopeInternational && ifHoldDomesticFlightSwitch) {
            // 打开了新版国内航班开关，同时是国内国际切换，则需要进行页面刷新
            UbtHandler.onUploadData('c_switch_domestic_intl', {
              url: trimedUrl,
              original: originalScopeInternational,
              target: targetScopeInternational,
            });
            onForceReload();
            return;
          }

          const history = store.getState().getIn(['list', 'router', 'history']);
          // 是否新的URL保持往返组合，有两种可能：
          // 1) 新URL就是往返组合，比如自由搭配切到往返组合
          // 2）跳转之前是往返组合，跳转之后依然是往返航程类型，就保持往返组合，但此处要注意的是，从往返组合切到自由搭配也符合 2），这时就从opts优先拿 forceBuildUp
          const { forceBuildUp, preventPushHistory, keepFilterActiveKey } = opts;
          const isBuildUp = getIfUrlIsBuildUp(forceBuildUp, trimedUrl);

          if (history) {
            // 保证有连续两次或者以上修改，只更新最后一次
            if (LAST_TRIME_URL && LAST_TRIME_URL == trimedUrl) {
              // 即时上报埋点
              const handler = ListUbtGenerator(
                LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
                LIST_UBT_KEY_TYPES.SEARCH_WITHOUT_REFRESH,
              );
              LIST_UBT_KEY_TYPES.SEARCH_WITHOUT_REFRESH.value.process(handler, searchCriteria);
              LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({ tag: { upload: true } });

              unlistenChangeHistory();
              if (!preventPushHistory) {
                performanceTrace.trace('beforeHistoryChange');
                // SEO页面强制刷新数据
                if (window.SEOFlag) {
                  // history.push(trimedUrl);
                  onForceReload();
                } else {
                  history.push(trimedUrl);

                }
              }

              listenChangeHistory(history, (theUrl, theOpts) => onResearchFlights(theUrl, theOpts));

              // 此处拿到 GlobalSearchCriteria 后要修改全局变量, 把是否伤残军警也保留到到这个对象里
              setGlobalSearchCriteria(searchCriteria);
              setArmyToGlobalSearchCriteria(army);

              updateTitle(searchCriteria); // 更新title
              syncFixSelectedFlight(false, false);

              updatePageId({
                pageId: PAGE_IDS[`LIST_${searchCriteria.flightWay}`],
              });

              // 清除推荐位埋点信息
              LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.RecommendProduct = {
                IsShow: '',
                RecommendInfo: [],
              };

              store.dispatch(genCloseCalendarAndTrend()); // 先关闭日历和趋势，避免后面一次性设置状态时候日历是打开的，额外的计算工作量
              store.dispatch(
                genInitPrevSearchCondSuccess(
                  searchCriteria,
                  { isbuildup: isBuildUp ? '1' : undefined },
                  { needResetAll: true, keepFilterActiveKey, needResetCalendar: opts.needResetCalendar},
                ),
              );
            }
          } else {
            onForceReload();
          }
        } else {
          onForceReload();
        }
        sendUBT(UBT_KEY.LIST.SEARCH, { isInternational: getIfScopeInternational() }, true);
      })
      .catch(e => {
        store.dispatch(
          genSetIsResearching(
            false
          ),
        );
        UbtHandler.onUploadData('c_error', {
          url: URL.getGlobalSearchCriteria,
          error: e,
        });

        onForceReload();
      });
  }
};

// 无刷新重查入口，url是完整路径
export const onResearchFlightsWithFullUrl = (fullUrl, opts = {}) => {
  // const urlWrapper = parseUrl(fullUrl),
  //     { pathname, search } = urlWrapper,  //  pathname: /international/search/round-sha-hkg       search: ?depdate=2019-05-11_2019-05-17&cabin=c_f&adult=1&child=1&infant=0
  //     url = `${pathname}${search}`        //  /international/search/round-sha-hkg?depdate=2019-05-11_2019-05-17&cabin=c_f&adult=1&child=1&infant=0
  // 推荐类的重查，会改变搜索框的条件，导致搜索框重查一遍，所以这里设置SkipAutoReSearch为true，当接口返回后改变搜索框的值，判断SkipAutoReSearch为true，不触发查询
  if (typeof opts.newSkipAutoReSearch === 'undefined') {
    store.dispatch(genSetSkipAutoReSearch(true));
  }

  setLastGlobalSearchCriteria();
  onResearchFlights(trimUrlPageList(fullUrl), opts);
};
