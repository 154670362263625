import { CABIN } from '@/src/constants/common/enum/common';
import getIfFlightAsLowPrice from '../getIfFlightAsLowPrice';
/**
 * 根据航班获取舱等集合，目前只有在搜索国内航班的筛选框用到
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 * @param {*当前航班集合的查询条件} prevCond
 */
const getAllCabinPriceForFilter = (flights, currentSegmentSeq, prevCond) => {
	let allCabins = {},
		thePrevCondCabin = prevCond.get('cabin'),
		allCabinsInFilter = CABIN.enums.filter((cabinEnum) => cabinEnum.value.avaliableInFilter);

	flights.forEach((flight) => {
		flight.get('priceList').forEach((price) => {
			const currentSegmentCabinEnumList = price.get('currentSegmentCabinEnumList');

			currentSegmentCabinEnumList.valueSeq().forEach((cabinEnum) => {
				const cabinEnumKey = cabinEnum.key;
				const matchedCabinInFilter = allCabinsInFilter.find((cabinEnumInFilter) =>
					cabinEnumInFilter.value.includeKeys.includes(cabinEnumKey)
				);

				if (!matchedCabinInFilter) {
					// console.error(`航班中有不在筛选项中的舱等`, cabinEnum)
				} else {
					let cabinValue = allCabins[matchedCabinInFilter.key];

					if (!cabinValue) {
						cabinValue = allCabins[matchedCabinInFilter.key] = {
							minAvgPriceWithTax: Infinity,
							minAvgPriceWithoutTax: Infinity,
							sameAsPrevCond: matchedCabinInFilter.key === thePrevCondCabin,
						};
					}

					let avgPriceWithTax = price.get('avgPriceWithTax'),
						avgPriceWithoutTax = price.get('avgPriceWithoutTax');

					if (getIfFlightAsLowPrice(flight)) {
						if (avgPriceWithTax < cabinValue.minAvgPriceWithTax) {
							cabinValue.minAvgPriceWithTax = avgPriceWithTax;
						}

						if (avgPriceWithoutTax < cabinValue.minAvgPriceWithoutTax) {
							cabinValue.minAvgPriceWithoutTax = avgPriceWithoutTax;
						}
					}
				}
			});
		});
	});

	allCabinsInFilter.forEach((cabinEnum) => {
		const cabinEnumKey = cabinEnum.key;

		if (!allCabins[cabinEnum.key]) {
			allCabins[cabinEnum.key] = {
				minAvgPriceWithTax: Infinity,
				minAvgPriceWithoutTax: Infinity,
				sameAsPrevCond: cabinEnumKey === thePrevCondCabin,
			};
		}
	});

	return allCabins;
};

export default getAllCabinPriceForFilter;
