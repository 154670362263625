import { createSelector } from 'reselect'
import { flightListSelector, getMinPriceSelector } from '../flight/baseSelector'
import { RECOMMEND_LOWPRICE_POSITION } from '../../../../constants/list/enums/recommend.js'
import { getVisibleDirectFlightsWithMinPriceSelector, visibleFlightsCountSelector } from '../../sidebar/filterbar/filterbarSelector'

export const position = (state, props) => props ? props.position : RECOMMEND_LOWPRICE_POSITION.TOP
export const getMinPrice = (state) => state.getIn(["list", "calendar_lowprice", "lowPriceDatas"])
export const adjacentDateSwitchSelector = (state) => state.getIn(['list', 'globalSwitch', 'adjacentDateSwitch'])

// 是否出现任何航班最低运价有不同，为了避免所有航班最低运价相同全部标红高亮
export const isVisibleFlightsHasDiffMinPrice = createSelector([flightListSelector], (flights) => {
    return flights.map(f => f.getIn(['minPriceWithTax', 'avg'])).toSet().size > 1
})

// 遍历判断单程和往返第一程是否有直飞
export const getFirstSegmentHasDirectFlightSelector = createSelector(flightListSelector, flights =>
    flights.some((flight) =>
        flight.getIn(["flightSegments", 0, 'transferCount']) === 0 && flight.getIn(["flightSegments", 0, 'stopCount']) === 0
    )
)

// 遍历判断往返组合是否都有直飞
export const isBuildUpHasDirectSelector = createSelector(flightListSelector, flights => {
    return flights.some(flight =>
        ((flight.getIn(["flightSegments", 0, 'transferCount']) === 0 && flight.getIn(["flightSegments", 0, 'stopCount']) === 0) &&
            (flight.getIn(["flightSegments", 1, 'transferCount']) === 0 && flight.getIn(["flightSegments", 1, 'stopCount']) === 0))
    )
})

// 当前列表页的最小痛苦指数
export const getMinMiseryIndexSelector = createSelector(flightListSelector, (flightList) => {
    let miseryIndex = Infinity
    flightList.forEach((item) => {
        let containsVirtualFlight = item.get("flightSegments").some((segment) => segment.get("containsVirtualFlight"))
        !containsVirtualFlight ? item.get("priceList").forEach((priceList) => {
            priceList.get("miseryIndex") < miseryIndex ? miseryIndex = priceList.get("miseryIndex") : undefined
        }) : undefined
    })
    return miseryIndex
})
// 当前列表页的最小痛苦指数最低价
export const getMinMiseryPriceSelector = createSelector(flightListSelector, (flightList) => {
    let miseryIndex = Infinity, price = 0;
    flightList.forEach((item) => {
        let containsVirtualFlight = item.get("flightSegments").some((segment) => segment.get("containsVirtualFlight"))
        !containsVirtualFlight ? item.get("priceList").forEach((priceList) => {
            if (priceList.get("miseryIndex") < miseryIndex) {
                miseryIndex = priceList.get("miseryIndex")
                price = priceList.get('adultPrice') + priceList.get('adultTax') +
                    (priceList.get('childPrice') || 0) + (priceList.get('childTax') || 0) +
                    (priceList.get('infantPrice') || 0) + (priceList.get('infantTax') || 0)
            }
        }) : undefined
    })
    return price
})

export const getResultInfoUbtSelector = createSelector([getMinPriceSelector, getMinMiseryPriceSelector,
    getFirstSegmentHasDirectFlightSelector, isBuildUpHasDirectSelector,
    getVisibleDirectFlightsWithMinPriceSelector, visibleFlightsCountSelector
],
    (lowestPricetax, painlowestpricetax, hasDirectFlight, ifAllDirect, dFlowestpricetax, visibleCount) => {
        return {
            LowestPricetax: lowestPricetax.get('totalPrice'),
            Painlowestpricetax: painlowestpricetax,
            HasDirectFlight: hasDirectFlight ? 'Y' : 'N',
            IfAllDirect: ifAllDirect ? 'Y' : 'N',
            DFlowestpricetax: dFlowestpricetax,
            HasResult: visibleCount > 0 ? 'Y' : 'N'
        }
    })
