import React from 'react';
import '@/src/components/Base/reactToolTip';
import Tooltip from '../../../components/list/common/tooltip';
import FreeVipLounge from '../../../components/list/common/freeVipLounge';
import CashBack from '../../../components/list/common/cashBack';
import DeferredContent from '../../../components/list/common/deferredContent';
import { DEFFRED_CONTENT_TYPE, CAR_TYPE, FREE_PICK_WAY, BUS_BOAT_TYPE } from './common';
import CONFIG_URL from '../api';
import PRICE_MESSAGE from './priceMessage';
import '../../../sources/list/converter';
import { ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list/index';
import { PRICE_TAG_POSITION } from './priceTagPosition';
import { BOOKING_TAG_POSITION } from './bookingTagPosition';
import { getRemoteTooltip } from '../../../sources/list/getRemoteTooltip';
import { getGiftToolTip } from '../../../sources/list/getGiftToolTip';

// X前置 超级会员乘机礼遇与钻石会员乘机礼遇 标签hover
import { fetchInitXProductPreposeInfo } from '../../../actions/list/xproduct';
import MembershipPopup from '../../../components/Base/membership';
import varStore from '../varStore';

const { priceReductionConfig } = window.GlobalConfigs;

export const InternationalPriceType = {
	CaSpecialDiscount: {
		// 国航特惠
		rawLabel: '国航特惠',
		bookRawLabel: PRICE_MESSAGE.BOOK_PRICE_TAG.CaSpecialDiscount,
		getLabel: (wrapper) => PRICE_MESSAGE.PRICE_TAG.CaSpecialDiscount.replace(/\[PRICE\]/g, wrapper.data),
		getTooltip: (wrapper, _prevCond, _price, _index) => {
			const tooltipContent = PRICE_MESSAGE.PRICE_TAG_HOVER.CaSpecialDiscount.replace(/\[PRICE\]/g, wrapper.data);
			return <Tooltip content={tooltipContent} />;
		},
		getBookTooltip: (wrapper) =>
			PRICE_MESSAGE.BOOK_PRICE_TAG_HOVER.CaSpecialDiscount.replace(/\[PRICE\]/g, wrapper.data),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.TOP,
	},
	CaSpecialInvite: {
		// 国航特邀
		rawLabel: '国航特邀',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.CaSpecialInvite,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.CaSpecialInvite} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	MuSpecialInvite: {
		// 东航特邀
		rawLabel: '东航特邀',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.MuSpecialInvite,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.MuSpecialInvite} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	PriceReduction: {
		// 立减
		rawLabel: '立减券',
		bookRawLabel: (_wrapper) => {
			let result;
			_wrapper.forEach((item) => {
				if (item.type == 'PriceReduction' && item.data.forUser) {
					result =
						priceReductionConfig[item.data.forUser] &&
						priceReductionConfig[item.data.forUser].priceReductionTit;
				} else if (item.type == 'PriceReduction') {
					result = priceReductionConfig['nondirectional'].priceReductionTit;
				}
			});

			return result;
		},
		getLabel: (_wrapper, _prevCond) => {
			let result = '';
			if (_wrapper.tag.key == 'PriceReduction' && _wrapper.data.forUser) {
				result =
					priceReductionConfig[_wrapper.data.forUser] &&
					priceReductionConfig[_wrapper.data.forUser].priceReductionTit.replace(
						/\[PRICE\]/g,
						_wrapper.data.discountMoney
					);
			} else if (_wrapper.tag.key == 'PriceReduction') {
				result = priceReductionConfig['nondirectional'].priceReductionTit.replace(
					/\[PRICE\]/g,
					_wrapper.data.discountMoney
				);
			}

			return result;
		},
		getTooltip: (_wrapper, _prevCond, _price, _index) => {
			let tooltipContent;
			if (_wrapper.tag.key == 'PriceReduction' && _wrapper.data.forUser) {
				tooltipContent =
					priceReductionConfig[_wrapper.data.forUser] &&
					priceReductionConfig[_wrapper.data.forUser].priceReductionDesc.replace(
						/\[PRICE\]/g,
						_wrapper.data.discountMoney
					);
			} else if (_wrapper.tag.key == 'PriceReduction') {
				tooltipContent = priceReductionConfig['nondirectional'].priceReductionDesc.replace(
					/\[PRICE\]/g,
					_wrapper.data.discountMoney
				);
			}

			return <Tooltip content={tooltipContent} />;
		},
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.BOTTOM,
	},
	FlashSaleDiscount: {
		// 闪购特惠
		rawLabel: '闪购特惠',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.FlashSaleDiscount,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.FlashSaleDiscount} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	MemberOnlyDiscount: {
		// 会员专享
		rawLabel: '会员专享',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.MemberOnlyDiscount,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.MemberOnlyDiscount} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	MuGvDiscount: {
		// 东航GV人数专享
		rawLabel: '东航GV人数专享',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.MuGvDiscount,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.MuGvDiscount} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	BusinessCarTicketGift: {
		// 赠商务车票
		rawLabel: '赠商务车票',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.BusinessCarTicketGift,
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		extractData: (data) => data.map((item) => item.content.replace(/\\/g, '')),
		getTooltip: (wrapper, prevCond, price, index) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => <Tooltip content={data} />}
			/>
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	CarRentalDiscount: {
		// 租车惠
		rawLabel: '租车惠',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.CarRentalDiscount,
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		extractData: (data) => data.map((item) => item.descriptionHtml || item.description),
		getTooltip: (wrapper, prevCond, price, index) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => <Tooltip content={data} />}
			/>
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	CarRentalReduce200RMB: {
		// 租车减200
		rawLabel: '租车减200',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.CarRentalReduce200RMB,
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		extractData: (data) => data.map((item) => item.descriptionHtml || item.description),
		getTooltip: (wrapper, prevCond, price, index) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => <Tooltip content={data} />}
			/>
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	CashBack: {
		// 返现
		rawLabel: '返现',
		getLabel: (wrapper) => {
			const data = wrapper.data || {},
				adultMoney = data.adultMoney || 0,
				childMoney = data.childMoney || 0,
				totalMoney = adultMoney + childMoney;

			return PRICE_MESSAGE.PRICE_TAG.CashBack.replace(/\[VALUE\]/g, totalMoney);
		},
		getTooltip: (wrapper, _prevCond, _price, _index) => {
			const data = wrapper.data || {},
				adultMoney = data.adultMoney || 0,
				childMoney = data.childMoney || 0,
				totalMoney = adultMoney + childMoney,
				cashBack = PRICE_MESSAGE.PRICE_TAG_HOVER.CashBack.replace(/\[VALUE\]/g, totalMoney);

			return <CashBack cashBack={cashBack} adultMoney={adultMoney} childMoney={childMoney} />;
		},
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	FreeBusBoatTicketToHK: {
		// 赠通港车船票
		rawLabel: '含通港车船票',
		getLabel: (wrapper) => {
			if (wrapper.data.length === 1) {
				const name = wrapper.data[0].segmentNo == 1 ? '去程' : '返程';
				return PRICE_MESSAGE.PRICE_TAG.FreeBusBoatTicketToHK.replace(/\[GO_BACK_TYPE\]/g, name).replace(
					/\[BUS_BOAT_TYPE\]/g,
					BUS_BOAT_TYPE[wrapper.data[0].type].value
				);
			}
			if (wrapper.data.length === 2) {
				return PRICE_MESSAGE.PRICE_TAG.FreeBusBoatTicketToHK.replace(/\[GO_BACK_TYPE\]/g, '往返').replace(
					/\[BUS_BOAT_TYPE\]/g,
					BUS_BOAT_TYPE[wrapper.data[0].type].value
				);
			}
		},
		getTooltip: (_wrapper, _prevCond) => <Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.FreeBusBoatTicketToHK} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	FreePickUp: {
		// 赠接送机
		rawLabel: '赠接送机',
		getLabel: (wrapper) => {
			if (wrapper.data.length === 1) {
				const { carType, segmentNo } = wrapper.data[0] || {};
				const catTypeText = CAR_TYPE[carType]?.value.text || '';
				return PRICE_MESSAGE.PRICE_TAG.FreePickUp.replace(/\[CAR_TYPE\]/g, catTypeText).replace(
					/\[FLIGHT_WAY\]/g,
					FREE_PICK_WAY[segmentNo]?.value.LABEL_WAY
				);
			}
			if (wrapper.data.length === 2) {
				const { carType } = wrapper.data[0] || {};
				const { carType: carTypeOne } = wrapper.data[1] || {};
				const catTypeText = carType === carTypeOne ? CAR_TYPE[carType]?.value.text || '' : '';
				return PRICE_MESSAGE.PRICE_TAG.FreePickUp.replace(/\[CAR_TYPE\]/g, catTypeText).replace(
					/\[FLIGHT_WAY\]/g,
					FREE_PICK_WAY.BOTH.value.LABEL_WAY
				);
			}
		},
		getTooltip: (wrapper, _prevCond) => {
			let msg = PRICE_MESSAGE.PRICE_TAG_HOVER.FreePickUp; // 默认模板配置
			if (wrapper.data.length === 1) {
				// 是否需要更新默认模板配置
				const msgInfo = (PRICE_MESSAGE.PRICE_TAG_HOVER.FreePickUpList || []).find(
					(k) => k.typeId == wrapper.data[0].carType
				);

				if (msgInfo) {
					msg = msgInfo.text;
				}
				const { carType, segmentNo } = wrapper.data[0] || {};
				const carTypeText = CAR_TYPE[carType]?.value.text || '';
				return (
					<Tooltip
						content={msg
							.replace(/\[CAR_TYPE\]/g, carTypeText)
							.replace(/\[FLIGHT_WAY\]/g, FREE_PICK_WAY[segmentNo].value.HOVER_WAY)}
					/>
				);
			}
			if (wrapper.data.length === 2) {
				const { carType } = wrapper.data[0] || {};
				const { carType: carTypeOne } = wrapper.data[1] || {};
				const catTypeText = carType === carTypeOne ? CAR_TYPE[carType]?.value.text || '' : '';
				if (carType == carTypeOne) {
					// 是否需要更新默认模板配置
					const msgInfo = (PRICE_MESSAGE.PRICE_TAG_HOVER.FreePickUpList || []).find(
						(k) => k.typeId == carType
					);
					if (msgInfo) {
						msg = msgInfo.text;
					}
				}
				return (
					<Tooltip
						content={msg
							.replace(/\[CAR_TYPE\]/g, catTypeText)
							.replace(/\[FLIGHT_WAY\]/g, FREE_PICK_WAY.BOTH.value.HOVER_WAY)}
					/>
				);
			}
		},
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	FreeVipLounge: {
		// 享贵宾休息室
		rawLabel: '享贵宾休息室',
		getLabel: (wrapper, prevCond) => {
			let go_back_type = '';
			if (prevCond.get('flightWay') === 'D') {
				go_back_type = wrapper.data.length === 1 ? (wrapper.data[0].segmentNo === 1 ? '去程' : '返程') : '往返';
			}
			return PRICE_MESSAGE.PRICE_TAG.FreeVipLounge.replace(/\[FLIGHT_WAY\]/g, go_back_type);
		},
		getTooltip: (wrapper, prevCond) => {
			let text = '';
			if (prevCond.get('flightWay') === 'S') {
				text = wrapper.data[0].airportName
					? `${wrapper.data[0].airportName}${
							wrapper.data[0].terminal ? wrapper.data[0].terminal + '航站楼' : ''
					  }`
					: '';
			} else {
				text = wrapper.data[0].airportName
					? `${wrapper.data[0].segmentNo === 1 ? '去程' : '返程'}${wrapper.data[0].airportName}${
							wrapper.data[0].terminal ? wrapper.data[0].terminal + '航站楼' : ''
					  }`
					: '';
			}

			if (wrapper.data.length === 2) {
				if (wrapper.data[0].airportName && wrapper.data[1].airportName) {
					text = `${wrapper.data[0].segmentNo === 1 ? '去程' : '返程'}${wrapper.data[0].airportName}${
						wrapper.data[0].terminal ? wrapper.data[0].terminal + '航站楼' : ''
					}`;
					text += `，${wrapper.data[1].segmentNo === 1 ? '去程' : '返程'}${wrapper.data[1].airportName}${
						wrapper.data[1].terminal ? wrapper.data[0].terminal + '航站楼' : ''
					}`;
				} else {
					text = '往返';
				}
			}

			return <FreeVipLounge content={PRICE_MESSAGE.PRICE_TAG_HOVER.FreeVipLounge.replace(/\[VALUE\]/g, text)} />;
		},
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	GiftPack: {
		// 大礼包
		rawLabel: '大礼包',
		getLabel: (wrapper) => wrapper.label || PRICE_MESSAGE.PRICE_TAG.GiftPack,
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		extractData: (data) => data,
		getTooltip: (wrapper, prevCond, price, index) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => getGiftToolTip(data)}
			/>
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	Mileage: {
		// 里程
		rawLabel: '赠里程',
		getLabel: (wrapper) => wrapper.label || PRICE_MESSAGE.PRICE_TAG.Mileage,
		getTooltip: (wrapper) => <Tooltip content={wrapper.description} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	GiftBox: {
		// 定制礼盒
		rawLabel: '定制礼盒',
		getLabel: (wrapper) => wrapper.label || PRICE_MESSAGE.PRICE_TAG.GiftBox,
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		extractData: (data) => data,
		getTooltip: (wrapper, prevCond, price, index) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => getGiftToolTip(data)}
			/>
		),
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	SpeedIssueTicket: {
		// 极速出票
		rawLabel: '极速出票',
		getLabel: (index) => (
			<img
				id={index}
				key={index}
				className="tag-advertisement"
				src="https://pic.c-ctrip.com/flight_intl/list/tags/tag-speedticket.png"
				alt="极速出票"
			/>
		),
		getTooltip: (wrapper, _prevCond, _price, _index) => {
			const toolTipContent = PRICE_MESSAGE.PRICE_TAG_HOVER.SpeedIssueTicket.replace(
				/\[VALUE\]/g,
				wrapper.data.ticketingTime
			);
			return <Tooltip extClass="tooltip-quickticket" content={toolTipContent} />;
		},
		position: PRICE_TAG_POSITION.LEFT,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	TodayFreeRefund: {
		// 甄选服务
		rawLabel: '甄选服务',
		getLabel: (index) => (
			<img
				id={index}
				key={index}
				className="tag-advertisement"
				src="https://pic.c-ctrip.com/flight_intl/list/tags/tag-preferservice.png"
				alt="甄选服务"
			/>
		),
		getTooltip: (wrapper, prevCond, price, _index) => {
			const invoice =
					price.get('invoiceTypeEnum').size > 1
						? '多种报销凭证'
						: price.getIn(['invoiceTypeEnum', 0]).value.title,
				toolTipMessage = wrapper.data.takeOffToday
					? PRICE_MESSAGE.PRICE_TAG_HOVER.TodayFreeRefundTakeOffToday
					: PRICE_MESSAGE.PRICE_TAG_HOVER.TodayFreeRefund,
				tooltipContent = toolTipMessage
					.replace(/\[ticketingTime\]/g, wrapper.data.ticketingTime)
					.replace(/\[refundDeadline\]/g, wrapper.data.refundDeadline)
					.replace(/\[timezone\]/g, wrapper.data.timezone)
					.replace(/\[invoice\]/g, invoice);
			return <Tooltip extClass="tooltip-betterservice" content={tooltipContent} />;
		},
		position: PRICE_TAG_POSITION.LEFT,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	PickUpCoupon: {
		// 赠接送机券
		rawLabel: '赠接送机券',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.PickUpCoupon,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.PickUpCoupon} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	GeneralCoupon: {
		// 赠通用券
		rawLabel: '赠通用券',
		getLabel: (wrapper) => wrapper.data.couponLabel,
		showLabelByRefundFee: true, // 要看退改费决定是否显示该券
		getTooltip: (wrapper, _prevCond, _price, _index) => <Tooltip content={wrapper.data.couponDesc} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	IstFastPassReminder: {
		// ist快速中转
		rawLabel: '快速通道',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) => <Tooltip content={wrapper.data} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	IstTransitService: {
		// ist转机服务
		rawLabel: '转机服务',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) => <Tooltip content={wrapper.data} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	SpringMember: {
		// 春秋会员价
		rawLabel: '春秋会员价',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) => <Tooltip content={wrapper.data} />,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
		getUbtTracker: (_ops) => {
			return ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
				LIST_UBT_KEY_TYPES.SPRING_MEMBER_HOVER
			);
		},
	},
	StudentLowestPrice: {
		// 春秋会员价
		rawLabel: '留学生专项低价',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (_wrapper, _prevCond, _price, _index) => null,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	CheckInLuggage: {
		// 春秋会员价
		rawLabel: '托运行李多1件',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (_wrapper, _prevCond, _price, _index) => null,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	NxBuyOneGetOne: {
		// 澳航往返买一送一
		rawLabel: '澳航往返买一送一',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (_wrapper, _prevCond, _price, _index) => <Tooltip content={_wrapper.description} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	SpecialMemberPrice: {
		// 高频用户专享价  P201911060147 【国际】接入定投产品
		rawLabel: '高频用户专享价',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) => <Tooltip content={wrapper.data} />,
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	FreeOnlineCheckIn: {
		// 赠在线值机
		rawLabel: '赠在线值机',
		getLabel: (_wrapper) => PRICE_MESSAGE.PRICE_TAG.FreeOnlineCheckIn,
		getTooltip: (_wrapper, _prevCond, _price, _index) => (
			<Tooltip content={PRICE_MESSAGE.PRICE_TAG_HOVER.FreeOnlineCheckIn} />
		),
		position: PRICE_TAG_POSITION.MIDDLE,
	},
	CheaperBusinessCabin: {
		// 公务舱超低价/头等舱超低价
		rawLabel: '公务舱超低价/头等舱超低价',
		getLabel: (wrapper) => `惠 ${wrapper.label}`,
		getTooltip: (_wrapper, _prevCond, _price, _index) => null,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
		notHover: true,
	},
	Bomb: {
		// 爬虫标记
		rawLabel: '',
		getLabel: (_index) => null,
		getTooltip: (_wrapper, _prevCond, _price, _index) => null,
		position: PRICE_TAG_POSITION.HIDDEN,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	FreeAirportBus: {
		// 赠 跨城机场|往返跨城|去程跨城|返程跨城 巴士
		rawLabel: '赠跨城机场巴士',
		getLabel: (wrapper, prevCond) => {
			if (!wrapper.data || wrapper.data.length == 0) {
				return null;
			}
			let typeName = '跨城机场';
			if (prevCond.get('flightWay') == 'D') {
				const segSet = new Set(wrapper.data.map((k) => k.segmentNo));
				if (segSet.has(1) && segSet.has(2)) {
					typeName = '往返跨城';
				} else if (segSet.has(1) && !segSet.has(2)) {
					typeName = '去程跨城';
				} else if (!segSet.has(1) && segSet.has(2)) {
					typeName = '返程跨城';
				}
			}
			return PRICE_MESSAGE.PRICE_TAG.FreeAirportBus.replace('[TYPE_NAME]', typeName);
		},
		getTooltip: (wrapper, prevCond) => {
			if (!wrapper.data || wrapper.data.length == 0) {
				return null;
			}
			let cityName = '';
			let seg1 = null,
				seg2 = null;
			switch (prevCond.get('flightWay')) {
				case 'S':
					cityName = `${wrapper.data[0].departureCity}—&gt;${wrapper.data[0].arrivalCity}`;
					break;
				case 'D':
					for (const seg of wrapper.data) {
						if (!seg1 && seg.segmentNo == 1) {
							seg1 = seg;
							continue;
						}
						if (!seg2 && seg.segmentNo == 2) {
							seg2 = seg;
							continue;
						}
					}
					if (seg1 && seg2) {
						cityName = `${seg1.departureCity}&lt;—&gt;${seg1.arrivalCity}`;
					} else if (seg1 && !seg2) {
						cityName = `${seg1.departureCity}—&gt;${seg1.arrivalCity}`;
					} else if (!seg1 && seg2) {
						cityName = `${seg2.departureCity}—&gt;${seg2.arrivalCity}`;
					}
					break;
				default:
					break;
			}

			const hoverText = PRICE_MESSAGE.PRICE_TAG_HOVER.FreeAirportBus.replace('[CITY_NAME]', cityName);
			return <Tooltip content={hoverText} />;
		},
		position: PRICE_TAG_POSITION.MIDDLE,
		getUbtTracker: (ops) => {
			return ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.FLT_OVERSEA_SEARCH_RESULT_KB,
				LIST_UBT_KEY_TYPES.FREE_AIRPORT_BUS_HOVER,
				{ content: ops.name }
			);
		},
	},
	AnniversaryActivity: {
		rawLabel: '周年大促',
		getLabel: (index) => (
			<img
				id={index}
				key={index}
				className="tag-advertisement"
				src="//pic.c-ctrip.com/flight_intl/list/tags/tag-anniversary.png"
				alt="周年大促"
			/>
		),
		getTooltip: (_wrapper, _prevCond) => {
			return null;
		},
		position: PRICE_TAG_POSITION.LEFT,
	},
	BusinessEconomicSeat: {
		// 商务经济座
		rawLabel: '商务经济座',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) =>
			wrapper.data && wrapper.data.hover ? <Tooltip content={wrapper.data.hover} /> : null,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
		getUbtTracker: (_ops) => {
			return ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
				LIST_UBT_KEY_TYPES.BUSINESS_ECONOMIC_SEAT_HOVER
			);
		},
	},
	MemberInterests: {
		// 超级会员乘机礼遇
		rawLabel: '超级会员乘机礼遇',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, prevCond, price, _index) => {
			if (!wrapper.label || !wrapper.data) {
				return null;
			}

			if (!window.GlobalSwitches.airAsiaMembershipSwitch || !window.GlobalSwitches.aggPreposeTokenSwitch) {
				return null;
			}

			if (!price.get('routeSearchToken') || price.get('priceUnitList').size >= 2) {
				return null;
			}

			const flightWay = prevCond.get('flightWay');
			if (flightWay != 'S' && flightWay != 'D') {
				return null;
			}

			if (!LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag) {
				LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag = {
					isshow: 0,
					tagtype: '',
					ischoose: 0,
					isshowlayer: 0,
				};
			}

			const isBlackDiamondUser = window.GlobalConfigs.isBlackDiamondUser;
			const isDiamondUser = window.GlobalConfigs.isDiamondUser;
			const ubtAirVipTag = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData.AirVipTag;
			ubtAirVipTag.isshow = 1;
			ubtAirVipTag.tagtype = '超级会员标签';
			if (isDiamondUser) {
				ubtAirVipTag.tagtype = '钻石会员标签';
			}
			if (isBlackDiamondUser) {
				ubtAirVipTag.tagtype = '黑钻会员标签';
			}

			return (
				<DeferredContent
					delayGetPromise={true}
					getPromise={() =>
						fetchInitXProductPreposeInfo(price.get('routeSearchToken'), prevCond, wrapper.data)
					}
					then={(preposeResult) => {
						if (!preposeResult || !preposeResult.policyToken) {
							return null;
						}

						varStore.PriceXProductPreposeInfoMap[preposeResult.routeSearchToken].isShowFlag = true;
						varStore.PriceXProductPreposeInfoMap[preposeResult.routeSearchToken].policyToken =
							preposeResult.policyToken;

						return <MembershipPopup preposeResult={preposeResult} />;
					}}
				/>
			);
		},
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
	},
	FLY_SERIES: {
		// 超惠飞、优选飞、尊享飞
		rawLabel: '系列标签',
		getLabel: (wrapper) => wrapper.label,
		getTooltip: (wrapper, _prevCond, _price, _index) =>
			wrapper.data && wrapper.data.hover ? <Tooltip content={wrapper.data.hover} /> : null,
		position: PRICE_TAG_POSITION.MIDDLE,
		BookPosition: BOOKING_TAG_POSITION.HIDDEN,
		getUbtTracker: (_ops) => {
			return ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
				LIST_UBT_KEY_TYPES.BUSINESS_ECONOMIC_SEAT_HOVER
			);
		},
	},
};
