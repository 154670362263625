import React from 'react';
import moment from 'moment'
import shortid from 'shortid'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'
import { onResearchFlightsWithFullUrl } from '../../../../actions/list/getGlobalSearchCriteria';

const CalendarLPMonthBody = ({ ...props }) => {
    const { calendarData, getUrl, searchCriteria } = props
    //用于渲染表头
    let _weeks = [
        { id: 1, week: '周日' },
        { id: 2, week: '周一' },
        { id: 3, week: '周二' },
        { id: 4, week: '周三' },
        { id: 5, week: '周四' },
        { id: 6, week: '周五' },
        { id: 7, week: '周六' }
    ],
        //用于渲染表格
        _priceTr = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }],
        _priceTd = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }, { id: 5 }, { id: 6 }, { id: 7 }],
        flightWay = searchCriteria.get("flightWay")
    return (
        <table className="table"><tbody>
            <tr>
                {_weeks.map((v, k) =>
                    <th key={k}>{v.week}</th>
                )}
            </tr>
            {_priceTr.map((ritem, rindex) =>
                <tr key={rindex}>
                    {_priceTd.map((ditem, dindex) => {
                        let data = calendarData && calendarData.getIn([rindex + "", dindex + ""])
                        if (data && data.size > 0 && !data.get("empty")) {
                            let lowPriceIsInfinity = data.get("price") >= Infinity
                            let button =
                                <UbtBoundary key={shortid.generate()} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, data.get("lowPrice") ? 'c_select_lp_price_month' : 'c_select_price_month')}>
                                    <td  >
                                        {!data.get("forbid") ? (<div className={'td' + ((data.get("notCurMonth") && !data.get("active") ? ' no-current-month' : '') + (data.get("active") ? ' active' : ''))} id={data.get("date").format("MMMM-DD-YYYY")} onClick={() => {
                                            onResearchFlightsWithFullUrl(getUrl(data.get("date").format("YYYY-MM-DD"), data.get("date2").format("YYYY-MM-DD")))
                                        }}>
                                            {
                                                data.get("holiday") ? (
                                                    <span className={`tag-holiday ${data.get("rest") ? 'rest' : ''}`}>
                                                        <span>{data.get("holidayName")}</span>
                                                    </span>
                                                ) : undefined
                                            }

                                            {
                                                //样式实验B版 往返选中日期不显示日期 实验A版 选中日期显示日期
                                                data.get("active") ? null : <div className="date" style={data.get("priceGone") ? { lineHeight: "30px" } : {}}>{data.get("date").format("YYYY-MM-DD") === moment().format("YYYY-MM-DD") ? "今天" : data.get("date").date()}</div>
                                            }

                                            {
                                                flightWay === 'D' ? (
                                                    <React.Fragment>
                                                        <div className="detail">{data.get("date").format('MM/DD') + ' ' + _weeks[data.get("date").day()].week + ' 去'}</div>
                                                        <div className="detail">{data.get("date2").format('MM/DD') + ' ' + _weeks[data.get("date2").day()].week + ' 返'}</div>
                                                    </React.Fragment>
                                                ) : <div className="detail">{data.get("date").format('MM/DD') + ' ' + _weeks[data.get("date").day()].week}</div>

                                            }

                                            {
                                                !lowPriceIsInfinity ? (data.get("lowPrice") ? (
                                                    <div className="price lowprice"><dfn>&yen;</dfn>{data.get("price")}<i className="tag tag-lowprice">低</i></div>
                                                ) : (
                                                        data.get("noPrice") ? <div className="price">{'点击查询'}</div> : data.get("priceGone") ? "" : <div className="price"><dfn>&yen;</dfn>{data.get("price")}</div>
                                                    )) : <div className="price">{'点击查询'}</div>
                                            }


                                        </div>) : <div className={`td forbid ${data.get("previous") ? ' previous-day' : ''} ${data.get("notCurMonth") ? ' no-current-month' : ''}`}> <div className="date">{data.get("date").date()}</div></div>}
                                    </td>
                                </UbtBoundary>
                            return button
                        } else {
                            return (<td key={shortid.generate()}></td>)
                        }
                    }
                    )}
                </tr>
            )}
        </tbody></table>
    )
}

export default CalendarLPMonthBody;
