import moment from 'moment';
import { UbtHandler } from '../../../ubt.v2/handler';
import { getPageId } from '../../../pages/list/index';

export const sendBasicTrace = ({
	allSegmentList = [],
	priceList,
	minPriceList,
	sortContainsTax,
	inter,
	flightWay,
	passengerType,
}) => {
	const pageId = getPageId(window.GlobalSearchCriteria.scope, window.GlobalSearchCriteria.flightWay);
	const sections = allSegmentList.map((section: any) => section?.toJS && section?.toJS()) || [];
	const sequence = sections.map((section, sectionNo) => {
		const { flightList = [] } = section;
		const isTransfer =
			flightList.length > 1
				? flightList.some((s) => s.stopList?.length > 0)
					? 'TransitWithStop'
					: 'Transfer'
				: flightList[0]?.stopList?.length > 0
				? 'Stop'
				: 'Direct';
		const transitTime: number[] = [];
		flightList.forEach((segment, index) => {
			if (index !== 0) {
				const time = moment(segment.departureDateTime).diff(
					moment(flightList[index - 1].arrivalDateTime),
					'minute'
				);
				transitTime.push(time);
			}
		});
		const transitCity = flightList.map((s) => s.departureCityCode).slice(1);
		return {
			sequence: sectionNo + 1,
			flightType: {
				type: isTransfer,
				transitCount: flightList.length - 1,
				transitTime,
				transitCity,
			},
			segments: flightList.map((segment, segmentNo) => {
				const {
					departureDateTime,
					arrivalDateTime,
					flightNo,
					operateFlightNo,
					marketAirlineCode,
					departureAirportCode,
					departureCityCode,
					departureCityName,
					departureCityId,
					arrivalAirportCode,
					arrivalCityCode,
					arrivalCityName,
					arrivalCityId,
					aircraftName,
				} = segment;
				return {
					segmentno: segmentNo + 1,
					takeofftime: departureDateTime,
					arrivetime: arrivalDateTime,
					flightno: flightNo,
					shareflightno: operateFlightNo || '',
					airline: marketAirlineCode,
					flighttime: moment(arrivalDateTime).diff(moment(departureDateTime), 'minute'),
					from: {
						dport: departureAirportCode,
						citycode: departureCityCode,
						dportname: departureCityName,
						dcityid: departureCityId,
					},
					to: {
						aport: arrivalAirportCode,
						citycode: arrivalCityCode,
						aportname: arrivalCityName,
						acityid: arrivalCityId,
					},
					aircrafttype: aircraftName,
					ismeal: '',
				};
			}),
		};
	});
	let policies = (priceList?.toJS && priceList?.toJS()) || [];
	const useClassTab = window.GlobalSearchCriteria.flightWay === 'S' && !inter;
	let classY = 1;
	let classS = 1;
	let classC = 1;
	policies = policies.map((policy, index) => {
		let classTab = '';
		let floorIndex = index + 1;
		const { cabin, extData } = policy;
		if (useClassTab) {
			if ((cabin === 'Y' || cabin === '@Y-Y') && !extData?.['SPECIAL_CABIN_TAB']) {
				classTab = 'economytab';
				floorIndex = classY++;
			} else if ((cabin === 'Y' || cabin === '@Y-Y') && extData?.['SPECIAL_CABIN_TAB']) {
				classTab = 'specialtab';
				floorIndex = classS++;
			} else if (cabin !== 'Y' && cabin !== '@Y-Y') {
				classTab = 'businesstab';
				floorIndex = classC++;
			}
		}
		return {
			...policy,
			classTab,
			floorIndex,
		};
	});
	let tabNumber = 0;
	classY > 1 && tabNumber++;
	classS > 1 && tabNumber++;
	classC > 1 && tabNumber++;
	const showTab = tabNumber > 1;
	const minPolicies = (minPriceList?.toJS && minPriceList?.toJS()) || [];
	const displayprice = policies.map((policy) => {
		const {
			classTab = '',
			floorIndex,
			avgPriceWithTax,
			avgPriceWithoutTax,
			avgTax,
			productType = {},
			baggage = {},
			penalty = {},
			cabinEnumList = [],
			servicePackage = {},
			priceTags = [],
			defaultCollapse,
			routeSearchToken,
		} = policy;
		const baggageTag = baggage.baggageTag || '';
		const refund = penalty.defaultPenaltyTag || '';
		const classList = cabinEnumList.map((c) => c.key || 'Y').join(',');
		const XproductInformation: any[] = [];
		if (servicePackage?.id) {
			const { id, price } = servicePackage;
			const detail = (
				!inter ? window.GlobalConfigs.d_servicePackage : window.GlobalConfigs.goldPackage || []
			).find((d) => d.packageID === id);
			const { packageTitle = '', products = [] } = detail || {};
			XproductInformation.push({
				xpid: id,
				index: '',
				XproductPrice: String(price),
				XproductName: packageTitle,
				XproductDetails: products.map((p) => p.title),
				isdisplay: '',
				ischoose: '',
			});
		}
		const ChooseX = priceTags.find((t) => t.type === 'ChooseX');
		const xProduct =
			(ChooseX?.data?.relatedPrices || []).map((p, index) => {
				const { price, id, title, remarks } = p.xDetail || {};
				return {
					xpid: id,
					index: String(index + 1),
					XproductPrice: String(price),
					XproductName: title,
					XproductDetails: remarks,
					isdisplay: '',
					ischoose: '',
				};
			}) || [];
		if (xProduct.length) {
			XproductInformation.push(...xProduct);
		}
		return {
			floorIndex,
			classTab: showTab ? classTab : '',
			class: classList,
			isMoreDisplay: defaultCollapse ? 'false' : 'true',
			ticketPrice: sortContainsTax && inter ? avgPriceWithTax : avgPriceWithoutTax,
			hasTax: sortContainsTax && inter ? 'true' : 'false',
			tax: avgTax || 0,
			pricesType: productType?.category || '',
			XproductInformation,
			lowchangeamount: refund,
			lowrefundamount: refund,
			checkedtotalbaggage: baggageTag,
			carryontotalbaggage: baggageTag,
			personalitemtotalbaggage: baggageTag,
			bookingchannel: '',
			flightagency: '',
			islowestpricefloor: minPolicies.some((p) => p.routeSearchToken === routeSearchToken) ? 'true' : 'false',
			isservicepackageproductfloor: servicePackage?.id ? 'true' : 'false',
			ischoos: '',
		};
	});
	const data = {
		triggerType: 'load',
		pagecode: pageId,
		flightClass: inter ? 'I' : 'N',
		flightWay,
		passengerType,
		sequence,
		lowestprice: sortContainsTax && inter ? minPolicies[0]?.avgPriceWithTax : minPolicies[0]?.avgPriceWithoutTax,
		displayprice,
	};
	UbtHandler.onUploadData('O_FLT_Online_List_Flight_Choose_Exposure', data);
};

export const getChildInfantText = (item, isALL) => {
	const { childNumLimitType, infantNumLimitType, minAdultNum } = item;
	let extraText = '';
	if (minAdultNum) {
		extraText = '不少于' + minAdultNum + '成人';
	} else {
		if (isALL) {
			let text = '';
			if (childNumLimitType === 0) {
				text += '儿童';
			}
			if (infantNumLimitType === 0) {
				text += '婴儿';
			}
			if (text) {
				text = `同行${text}不计入人数限制范围`;
			}
			extraText = text;
		} else {
			if (childNumLimitType === 0 && infantNumLimitType !== 0) {
				extraText = '儿童不计';
			}
			if (infantNumLimitType === 0 && childNumLimitType !== 0) {
				extraText = '婴儿不计';
			}
			if (childNumLimitType === 0 && infantNumLimitType === 0) {
				extraText = '婴童不计';
			}
		}
	}
	if (extraText) {
		extraText = `(${extraText})`;
	}
	return extraText;
};
