import React from 'react';

const OBSERVER_OPTIONS = {
	root: null,
	rootMargin: '0px',
	threshold: [0, 1],
};

export class IntersectionNotifier extends React.Component {
	constructor(props) {
		super(props);

		const { onScrollIntoView, loading = <div /> } = props;

		this.onScrollIntoView = onScrollIntoView;
		this.loading = loading;
		this.ref = React.createRef();
	}

	componentDidMount() {
		const ref = this.ref;

		if (ref && ref.current) {
			const observer = new IntersectionObserver((entries) => {
				// 进入屏幕才调用
				const entry = entries[0];
				if (entry && entry.intersectionRatio > 0) {
					this.onScrollIntoView(entry);
					observer.disconnect();
				}
			}, OBSERVER_OPTIONS);

			observer.observe(ref.current);

			return () => observer.disconnect();
		}
	}

	render() {
		const { logKey } = this.props;
		return (
			<div ref={this.ref} data-log-key={logKey}>
				{this.props.loading}
			</div>
		);
	}
}
