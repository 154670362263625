import { fetchPostWithHeaders } from '../actions/common';
import LIST_CONFIG_URL from '../constants/list/api';
import { UbtHandler } from './handler';

export const addClickRec = (ubtKey, ubtRemark) => {
	if (ubtKey) {
		const record = UbtHandler.genClickRec(ubtKey, ubtRemark);
		fetchPostWithHeaders(
			LIST_CONFIG_URL.writeLog,
			{ title: 'ubt', content: JSON.stringify({ url: location.href, data: record }) },
			{}
		);
	}
};

export const watchClickDocument = (ev) => {
	try {
		let target = ev.target;
		if (target) {
			let searchLevel = 0;
			while (target && searchLevel <= 3) {
				const ubtKey = target.getAttribute('u_key');
				const ubtRemark = target.getAttribute('u_remark');

				if (ubtKey) {
					addClickRec(ubtKey, ubtRemark);
					break;
				} else {
					searchLevel++;
					target = target.parentElement;
				}
			}
		}
	} catch (err) {
		console.error(err);
	}
};

export const watchPlainUbt = () => {
	document.removeEventListener('click', watchClickDocument, true);
	document.addEventListener('click', watchClickDocument, true);
};
