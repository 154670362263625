import { useEffect, useReducer } from 'react';
import { IModal } from '../types';
import { eventManager } from './eventManager';

const MODAL_LIMIT = 20;

export enum ActionType {
    ADD_MODAL,
    UPDATE_MODAL,
    UPSERT_MODAL,
    DISMISS_MODAL,
    REMOVE_MODAL,
}

type Action =
    | {
          type: ActionType.ADD_MODAL;
          modal: IModal;
      }
    | {
          type: ActionType.UPSERT_MODAL;
          modal: IModal;
      }
    | {
          type: ActionType.UPDATE_MODAL;
          modal: Partial<IModal>;
      }
    | {
          type: ActionType.DISMISS_MODAL;
          modalId?: string;
      }
    | {
          type: ActionType.REMOVE_MODAL;
          modalId?: string;
      };

interface State {
    modals: IModal[];
}

const modalTimeouts = new Map<IModal['id'], ReturnType<typeof setTimeout>>();

const addToRemoveQueue = (modalId: string) => {
    if (modalTimeouts.has(modalId)) {
        return;
    }

    const timeout = setTimeout(() => {
        modalTimeouts.delete(modalId);
        // dispatch({
        // 	type: ActionType.REMOVE_MODAL,
        // 	modalId: modalId,
        // });
        // eventManager.emit(Event.Show, { type: ActionType.REMOVE_MODAL, modalId });
    }, 1000);

    modalTimeouts.set(modalId, timeout);
};

const clearFromRemoveQueue = (modalId: string) => {
    const timeout = modalTimeouts.get(modalId);
    if (timeout) {
        clearTimeout(timeout);
    }
};

export const reducer = (state: State, action: Action): State => {
    switch (action.type) {
        case ActionType.ADD_MODAL:
            return {
                ...state,
                modals: [action.modal, ...state.modals].slice(0, MODAL_LIMIT),
            };

        case ActionType.UPDATE_MODAL:
            if (action.modal.id) {
                clearFromRemoveQueue(action.modal.id);
            }

            return {
                ...state,
                modals: state.modals.map((t) => (t.id === action.modal.id ? { ...t, ...action.modal } : t)),
            };

        case ActionType.UPSERT_MODAL: {
            const { modal } = action;
            return state.modals.find((t) => t.id === modal.id)
                ? reducer(state, { type: ActionType.UPDATE_MODAL, modal })
                : reducer(state, { type: ActionType.ADD_MODAL, modal });
        }

        case ActionType.DISMISS_MODAL: {
            const { modalId } = action;

            if (modalId) {
                addToRemoveQueue(modalId);
            } else {
                state.modals.forEach((t) => {
                    addToRemoveQueue(t.id);
                });
            }

            return {
                ...state,
                modals: state.modals.map((t) =>
                    t.id === modalId || modalId === undefined
                        ? {
                              ...t,
                              visible: false,
                          }
                        : t,
                ),
            };
        }
        case ActionType.REMOVE_MODAL:
            if (action.modalId === undefined) {
                return {
                    ...state,
                    modals: [],
                };
            }
            return {
                ...state,
                modals: state.modals.filter((t) => t.id !== action.modalId),
            };
        default:
            return state;
    }
};

export const useStore = (modalOptions: { name: string }): State => {
    const { name } = modalOptions;
    const [state, dispatch] = useReducer(reducer, { modals: [] });

    useEffect(() => {
        eventManager.on(name, dispatch);
        return () => {
            eventManager.off(name);
        };
    }, [name]);

    return state;

    // const mergedModals = state.modals.map((t) => ({
    // 	...modalOptions,
    // 	...t,
    // 	style: {
    // 		// ...modalOptions.style,
    // 		...t.style,
    // 	},
    // }));

    // return {
    // 	...state,
    // 	modals: mergedModals,
    // };
};
