import { LUGGAGE_DIRECT_TYPE, TRANSIT_VISA_LUGGAGE_TIP_TYPE } from '../../constants/common/enum/common';
import { isDifferentAirportBetweenFlights } from './isDifferentAirportBetweenFlights';
import { isDifferentTerminalBetweenFlights } from './isDifferentTerminalBetweenFlights';

const defaultInnerFlightProps = {
	stopList: [],
	marketAlliance: '',
	operateAlliance: '',
	operateAirlineCode: '',
	operateAirlineName: '',
	operateFlightNo: '',
	departureAirportEnName: '',
	arrivalAirportEnName: '',
};

const defaultInnerFlightPropKeys = Object.keys(defaultInnerFlightProps);

let LUGGAGE_DIRECT_TYPE_CACHE = {};
LUGGAGE_DIRECT_TYPE.enums.forEach((theDirectType) => {
	LUGGAGE_DIRECT_TYPE_CACHE[theDirectType.key] = theDirectType;
});

/**
 * 计算时长是否小于两小时
 * @param {*时长，以分钟为单位} min
 */
const transferShortTime = (min) => {
	let hours = Math.floor((min % (24 * 60)) / 60);

	return hours < 2;
};

class SegmentHandle {
	/**
	 * 初始化航程信息中的航段信息
	 * @param {航程信息} segment
	 */
	static initTheSegment(segment) {
		segment.flightList.forEach((innerFlight, innerFlightIndex) => {
			defaultInnerFlightPropKeys.forEach((key) => {
				if (typeof innerFlight[key] === 'undefined') {
					innerFlight[key] = defaultInnerFlightProps[key];
				}
			});

			innerFlight.transferDuration = Math.max(0, innerFlight.transferDuration);
			innerFlight.differentAirport = isDifferentAirportBetweenFlights(segment, innerFlightIndex); //是否需要更换机场
			innerFlight.differentTerminal = isDifferentTerminalBetweenFlights(segment, innerFlightIndex); // 相邻两段是同一个机场，并且都有航站楼而且航站楼不同
			innerFlight.luggageDirectTypeEnum =
				LUGGAGE_DIRECT_TYPE_CACHE[innerFlight.luggageDirectType] || LUGGAGE_DIRECT_TYPE.UNKNOWN;
			if (innerFlightIndex < segment.flightList.length - 1)
				innerFlight.transferTimeShort = transferShortTime(innerFlight.transferDuration);

			innerFlight.stopList.forEach((innerStop) => {
				innerStop.luggageDirectTypeEnum =
					LUGGAGE_DIRECT_TYPE_CACHE[innerStop.luggageDirectType] || LUGGAGE_DIRECT_TYPE.UNKNOWN;
			});
		});

		let flightListCount = segment.flightList.length,
			differentAirport = segment.flightList.some((flight) => flight.differentAirport),
			firstSegment = segment.flightList[0],
			lastSegment = segment.flightList[flightListCount - 1],
			stopCount = segment.stopCount,
			transferCount = segment.transferCount,
			{
				departureAirportCode,
				departureAirportName,
				departureAirportShortName,
				departureAirportEnName,
				departureTerminal,
				departureDateTime,
			} = firstSegment,
			{
				arrivalAirportCode,
				arrivalAirportName,
				arrivalAirportShortName,
				arrivalAirportEnName,
				arrivalTerminal,
				arrivalDateTime,
			} = lastSegment,
			hasLuggageTip = segment.flightList.some((flight) => {
				if (flight.luggageDirectType === 1 || flight.luggageDirectType === 2) {
					return true;
				} else {
					return flight.stopList.some((stop) => stop.luggageDirectType === 1 || stop.luggageDirectType === 2);
				}
			}),
			transVisaLuggageTipEnums =
				stopCount || transferCount
					? []
							.concat(differentAirport ? [TRANSIT_VISA_LUGGAGE_TIP_TYPE.TRANSIT] : [])
							.concat(segment.transitVisaRemarkIDs ? [TRANSIT_VISA_LUGGAGE_TIP_TYPE.VISA] : [])
							.concat(hasLuggageTip ? [TRANSIT_VISA_LUGGAGE_TIP_TYPE.LUGGAGE] : [])
					: [];

		return {
			...segment,
			transferAndStopCount: transferCount + stopCount,
			departureAirportShortName: departureAirportShortName || departureAirportName,
			arrivalAirportShortName: arrivalAirportShortName || arrivalAirportName,
			flightListCount,
			departureAirportCode,
			departureAirportName,
			departureAirportEnName,
			departureTerminal,
			departureDateTime,
			arrivalAirportCode,
			arrivalAirportName,
			arrivalAirportEnName,
			arrivalTerminal,
			arrivalDateTime,
			differentAirport,
			transVisaLuggageTipEnums,
		};
	}
}

export default SegmentHandle;
