import {
	getIfPrevCondIsHighCabin,
	getTimeOfDate,
	getStopCount,
	getTransferCount,
	getTransAndStopCount,
} from '@/src/sources/list/highCabinRec';
import { getIfHideBestFliightsLabel } from '../getForBestFlightsPostfix';
import { CUR_SEGMENT_PRICE_OR_MISERY_INDEX, DURATION_OR_NONE, PRICE_OR_MISERY_INDEX } from './SORT_INDICATOR_TYPE';
import { SortIndicatorType } from './types';

/**
 * 排序因子的计算指标
 */
export const SORT_INDICATOR_TYPE: Record<string, SortIndicatorType> = {
	// 该因子用于直飞优先逻辑：比较的两个航班都是直飞就用价格比，否则返回痛苦指数（其中已折算价格和飞行时长）
	PRICE_OR_MISERY_INDEX,
	// 跟 PRICE_OR_MISERY_INDEX 逻辑一致，除了痛苦指数是按照当前程，而不是全程
	CUR_SEGMENT_PRICE_OR_MISERY_INDEX,
	TRANSFER_AND_STOP_COUNT: (prevCond, flight, currentSegmentSeq, isBuildUp, _sortContainsTax) => {
		return getTransAndStopCount(isBuildUp, flight, currentSegmentSeq);
	},
	// 专为往返组合设计：全直飞>只去程直飞或只返程直飞>全不直飞的顺序排序，不计算具体中转/经停次数
	SIMPLE_TRANSFER_AND_STOP_COUNT: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		const firstSegmentTransferCount = getTransferCount(false, flight, 0),
			firstSegmentStopCount = getStopCount(false, flight, 0),
			secondSegmentTransferCount = getTransferCount(false, flight, 1),
			secondSegmentStopCount = getStopCount(false, flight, 1);

		if (
			firstSegmentTransferCount + firstSegmentStopCount + secondSegmentTransferCount + secondSegmentStopCount ===
			0
		) {
			return 0;
		} else if (firstSegmentTransferCount + firstSegmentStopCount === 0) {
			return 1;
		} else if (secondSegmentTransferCount + secondSegmentStopCount === 0) {
			return 1;
		} else {
			return 2;
		}
	},
	TRANSFER_COUNT: (prevCond, flight, currentSegmentSeq, isBuildUp, _sortContainsTax) =>
		getTransferCount(isBuildUp, flight, currentSegmentSeq),
	STOP_COUNT: (prevCond, flight, currentSegmentSeq, isBuildUp, _sortContainsTax) =>
		getStopCount(isBuildUp, flight, currentSegmentSeq),
	PRICE_WITH_TAX: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.get('minPriceWithTax').get('avg'),
	ON_TIME: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		const flightList = flight.getIn(['flightSegments', _currentSegmentSeq, 'flightList']),
			arrivalPunctualityList = flightList
				.map((item) => +item.get('arrivalPunctuality', '0').replace('%', ''))
				.filter((item) => item && item !== '-')
				.toJS();

		return arrivalPunctualityList.length ? arrivalPunctualityList[0] : 0;
	},
	PRICE_WITHOUT_TAX: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.get('minPriceWithoutTax').get('avg'),
	FREE_HK_VISA: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.get('itineraryTags').findIndex((tag) => tag.get('type') === 'HkTransferVisaFreeChoice') >= 0 ? 0 : 1,
	FREE_MACAO_VISA: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.get('itineraryTags').findIndex((tag) => tag.get('type') === 'MacaoTransferVisaFreeChoice') >= 0 ? 0 : 1,
	MISERY_INDEX: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight
			.get('priceList')
			.map((item) => {
				return item.get('miseryIndex');
			})
			.min(),
	// 该因子用于直飞优先逻辑：比较的两个航班都是直飞就用飞行时长比，否则返回0（非直飞用痛苦指数，其中已折算飞行时长）
	DURATION_OR_NONE,
	PURE_TAX: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.get('minPriceWithTax').get('avg') - flight.get('minPriceWithoutTax').get('avg'),
	DURATION: (prevCond, flight, currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		flight.getIn(['flightSegments', currentSegmentSeq, 'duration']),
	DEPART_TIME: (prevCond, flight, currentSegmentSeq, _isBuildUp, _sortContainsTax) =>
		getTimeOfDate(flight.getIn(['flightSegments', currentSegmentSeq, 'flightList', 0, 'departureDateTime'])),
	ARRIVAL_TIME: (prevCond, flight, currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		return (
			flight.getIn(['flightSegments', currentSegmentSeq, 'crossDays'], 0) * 240000 +
			getTimeOfDate(flight.getIn(['flightSegments', currentSegmentSeq, 'flightList', -1, 'arrivalDateTime']))
		);
	},
	HIGH_CABIN_RECOMMEND: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		// 查询条件是否高舱推荐
		const prevCondIsHighCabin = getIfPrevCondIsHighCabin(prevCond);
		if (prevCondIsHighCabin) {
			return flight.get('containsRecommendCabin') ? 1 : 0; // 非推荐航班优先于推荐航班
		} else {
			return 0;
		}
	},
	HIGH_CABIN_PRIORITY: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		// 查询条件是否高舱
		const prevCondIsHighCabin = getIfPrevCondIsHighCabin(prevCond);
		if (prevCondIsHighCabin) {
			return flight.get('containsHighCabin') ? 0 : 1; // 包含高舱航班优先级高于不包含高舱航班
		} else {
			return 0;
		}
	},
	ALL_HIGH_CABIN_PRIORITY: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		// 查询条件是否所有高舱
		const prevCondIsHighCabin = getIfPrevCondIsHighCabin(prevCond);
		if (prevCondIsHighCabin && flight.get('containsRecommendCabin')) {
			return !flight.get('containsLowCabin') ? 0 : 1; // 推荐航班中分割成两块区域公务舱/经济舱
		} else {
			return 0;
		}
	},
	// 为精选优先 排序量身定制，精选优先排序相当于将所有航班拆成两块，每块内部都按照直飞优先排序
	BEST_CHOICE_FLIGHTS_FIRST: (prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		// 因为可能出现 showMain 为false的情况，所以不单是判断枚举是否存在，还要判断标签是否可见
		const isBestChoiceFlight = flight
			.get('itineraryTagEnums')
			.find((wrapper) => wrapper.tag.value.ofBestChoiceFlight && !getIfHideBestFliightsLabel(wrapper));
		return isBestChoiceFlight ? 0 : 1;
	},
	// 当前航程的中转经停次数
	SEGMENT_COUNT: (prevCond, flight, currentSegmentSeq, isBuildUp, _sortContainsTax) => {
		const currentSegmentFlightsListCount = getTransAndStopCount(isBuildUp, flight, currentSegmentSeq);
		return currentSegmentFlightsListCount;
	},

	DOMESTIC_FILTER_GROUP: (_prevCond, flight, _currentSegmentSeq, _isBuildUp, _sortContainsTax) => {
		// 直飞或者中转最低价 -> 中转 ->婴儿儿童不可订 -> 其他交通方式
		const sortTypeGroupEnum =
			flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']) &&
			flight.getIn(['theMinPriceItem', 'sortTypeGroupEnum']).value;
		return sortTypeGroupEnum && sortTypeGroupEnum.sortIndex;
	},
};
