import { lightUpload } from "../common/lightUbt"
import { fetchPost } from "../../actions/common"
import CONFIG_URL from "../../constants/common/api"

const METRIC_NAME = 'outerLowPrice'
const LOWPRICE_CHANGE_TYPE_KEY = 'Type'
const LOWPRICE_SOURCE_KEY = 'Source'
const LOW_PRICE_PAGE_TYPE_KEY = 'Page'
const LOW_PRICE_CHANGE_RATIO_KEY = 'category'

// export interface LogOuterLowPriceParam {
//     key: string,
//     source: string
// }

const calcRatio = (outerPrice, currentPrice) => {
    if (outerPrice && currentPrice) {
        // 先计算出最基本的百分比，比如外露 1200，当前最低 1300， rawRatio = (1300-1200)/1200 = 0.0833
        const rawRatio = (currentPrice - outerPrice) / outerPrice
        // 再以0.05为分隔线，不足向上补齐到0.05的整数倍，即 0.0833 向上补齐成 0.1，统计数即为 10%，
        // 需要注意 rawRatio可能是负数，负数的向上取整会倾向0，所以先取绝对值，再乘符号位
        const approximateRatio = Math.ceil(Math.abs(rawRatio) / .05) * .05 * (rawRatio > 0 ? 1 : -1)
        // 设定最大值，即超过 100% 通通计100%，目的是减少 hickwall的 tag 数量
        if (approximateRatio >= 1) {
            return 1
        } else if (approximateRatio <= -1) {
            return -1
        } else {
            return +approximateRatio.toFixed(2)
        }
    } else {
        return 0
    }
}

export const logOuterLowPrice = (param) => {
    const { key, source, outerPrice = 0, currentPrice = 0 } = param
    const changeRatio = calcRatio(outerPrice, currentPrice)

    lightUpload(key, { ...param, changeRatio })

    fetchPost(CONFIG_URL.recordMetric, {
        metric: METRIC_NAME,
        tags: {
            [LOWPRICE_CHANGE_TYPE_KEY]: key,
            [LOWPRICE_SOURCE_KEY]: source,
            [LOW_PRICE_PAGE_TYPE_KEY]: 'intl',
            [LOW_PRICE_CHANGE_RATIO_KEY]: changeRatio
        }
    })
}
