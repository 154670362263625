import React from 'react'
import PopDialog from '../../list/common/PopDialog'
import { UED } from '../../list/popups/styleConst';


const MultiDomesticFlightAlertContent = ({ setShowMultiFlightAlertPopup }) => {
    return (<div className="basic-alert domestic-search-alert" style={UED.popupMargin}>
        <div className="alert-body ">
            国内目前仅支持两程查询，若您需要预订更多行程，可以分开预订。
        </div>
        <div className="alert-footer">
            <div className="btn-group">
                <a href="javascript:;" className="btn" onClick={() => { setShowMultiFlightAlertPopup(false) }}>知道了</a>
            </div>
        </div>
    </div>)
}
const MultiDomesticFlightAlertPopup = ({ setShowMultiFlightAlertPopup }) => {
    return <PopDialog contents={MultiDomesticFlightAlertContent({ setShowMultiFlightAlertPopup })} />
}
export default MultiDomesticFlightAlertPopup
