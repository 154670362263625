
/**
    * 判断两个航段是否同一个机场
    * @param {*航程} segment
    * @param {*指定前一个航段索引号，从0开始，判断该航段和下一个航段之间是否换机场} thePrevFlightIndex
    */
export const isDifferentAirportBetweenFlights = (segment, thePrevFlightIndex) => {
    let prevFlight = segment.flightList[thePrevFlightIndex],
        nextFlight = segment.flightList[thePrevFlightIndex + 1]

    if (prevFlight && nextFlight) {
        return prevFlight.arrivalAirportCode !== nextFlight.departureAirportCode
            && ((prevFlight.trafficType && nextFlight.trafficType) ?
                (prevFlight.trafficType == 'FLIGHT' && nextFlight.trafficType == 'FLIGHT')
                : true
            )
    } else {
        return false
    }
}