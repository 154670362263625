import { countryLowPriceUrl, FlightSameCityDetect } from '../channel/gatewayApi';
import { API_ROOT } from '../common';

// 获取abtest某个KEY值
export const getSwitchOpened = `${API_ROOT}/switch/getSwitchOpened`;

// 节假、工作日查询接口
export const getWorkingDays = `${API_ROOT}/base/getWorkingDays`;

export const getYearWorkingDays = `${API_ROOT}/base/getHoliday`;

// 上报HICKWALL埋点
export const recordMetric = `${API_ROOT}/log/recordMetric`;

export default {
	// 精选地区低价日历
	countryLowPriceUrl,
	// 获取精选地区
	// districtListUrl,
	// 放开部分同城市查询搜索
	FlightSameCityDetect,
	// 获取abtest某个KEY值
	getSwitchOpened,
	// 节假、工作日查询接口
	getWorkingDays,
	getYearWorkingDays,
	// 获取国内城市面板数据
	// getDomesticCity: `${GLOBAL_PREFIX}/api/poi/get`,

	recordMetric,
};
