import Immutable from 'immutable';

const getMtSegList = (selectedCityState: any) => {
	let keys = selectedCityState.keySeq(),
		list = {};
	keys.forEach((key) => {
		if (key.indexOf('mt') == 0) {
			let index = key.substr(-1) - 1;
			if (!list[index]) {
				list[index] = {};
			}
			let value = selectedCityState.getIn([key]);
			if (value && value.isValid && !value.isValid()) {
				value = null;
			}
			switch (key.substr(2, 1)) {
				case 'D':
					if (value) {
						list[index].dCityText =
							value.keySeq().size > 0
								? value.get('text')
									? value.get('text')
									: `${value.get('Name')}(${value.get('Code')})`
								: '';
					} else {
						list[index].dCityText = '';
					}
					break;
				case 'A':
					if (value) {
						list[index].aCityText =
							value.keySeq().size > 0
								? value.get('text')
									? value.get('text')
									: `${value.get('Name')}(${value.get('Code')})`
								: '';
					} else {
						list[index].aCityText = '';
					}
					break;
				case 'S':
					list[index].StartDate = value;
					break;
				default:
					break;
			}
		}
	});
	return Immutable.fromJS(list);
};

export default getMtSegList;
