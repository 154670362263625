import * as TYPES from '../../constants/list/actionTypes'
import CONFIG_URL from '../../constants/list/api'
import { fetchPost, fetchGet } from '../common'
import { fetchJsonpGet } from '../../sources/common/fetchJsonpGet'

export const genSwitchCalendarShow = (isShow) => ({ type: TYPES.CALENDARLP_SHOW_BODY, isShow })
export const genCloseCalendarAndTrend = () => ({ type: TYPES.CALENDARLP_CLOSE_DETAIL_AND_TREND })
export const genGetLPDatas = (lpdatas, searchCriteria) => ({ type: TYPES.CALENDARLP_GET_LPDATA, lpdatas, searchCriteria })
export const genWorkingDays = workingDays => ({ type: TYPES.CALENDARLP_GET_WORKINGDAYS, workingDays })
export const fetchLPDatas = (params, searchCriteria) => dispatch => {
    let url = CONFIG_URL.getCalendarL
    return fetchPost(url, params)
        .then(res => res && res.data || {})
        .then(json => {
            dispatch(genGetLPDatas(json.priceList || [], searchCriteria))
        })
}
export const fetchWorkingDays = (startDate, endDate) => dispatch => {
    if (startDate && endDate) {
        let url = CONFIG_URL.getWorkingDays + `?y1=${startDate.getFullYear()}&m1=${startDate.getMonth() + 1}&d1=${startDate.getDate()}&y2=${endDate.getFullYear()}&m2=${endDate.getMonth() + 1}&d2=${endDate.getDate()}`
        return fetchJsonpGet(url)
            .then(res => res && res.data || {})
            .then(json => {
                dispatch(genWorkingDays(json.data || []))
            })
    }
}


export const genTodayLowPrice = (lowestPrice, lowestTotalPrice, todayDate, flightWay, diffDays, currentSegmentSeq, isRouteSearch) => ({ type: TYPES.CALENDARLP_LOWEST_PRICE_TODAY, lowestPrice, lowestTotalPrice, todayDate, flightWay, diffDays, currentSegmentSeq, isRouteSearch})
export const genTodayLowPriceChannel = (lowestPriceChannel, lowestTotalPriceChannel) => ({ type: TYPES.CALENDARLP_LOWEST_PRICE_TODAY_CHANNEL, lowestPriceChannel, lowestTotalPriceChannel })
export const genChangeCurrentStart = currentStart => ({ type: TYPES.CALENDARLP_CHANGE_CURRENTSTART, currentStart })
export const genChangeCurrentMonth = currentMonthIndex => ({ type: TYPES.CALENDARLP_CHANGE_CURRENTMONTH, currentMonthIndex })
export const genChangeDiffDays = diffDays => ({ type: TYPES.CALENDARLP_CHANGE_DIFFDAYS, diffDays })
export const genChangeCurrentWeek = currentWeekIndex => ({ type: TYPES.CALENDARLP_CHANGE_CURRENTWEEK, currentWeekIndex })
export const genChangeShowMonth = showMonth => ({ type: TYPES.CALENDARLP_CHANGE_SHOWMONTH, showMonth })
export const genChangeShowTrend = showTrend => ({ type: TYPES.CALENDARLP_CHANGE_SHOWTREND, showTrend })

export const genGetLowerPriceTabSwitch = showTrend => ({ type: TYPES.CALENDARLP_GET_LOWER_TAB_SWITCH, showTrend })
export const genGetLowerPriceSwitchStatus = status => ({ type: TYPES.CALENDARLP_GET_LOWER_SWITCH_STATUS, status })
export const genGetLowerPriceNewStyleSwitch = newStyle => ({ type: TYPES.CALENDARLP_GET_LOWER_NEWSTYLE_SWITCH, newStyle })

const fetchLowerPriceTabSwitch = () => {
    return fetchGet(`${CONFIG_URL.getSwitchOpened}?switchName=lowerPriceTabSwitch`)
        .then(res => {
            return res.data
        })
        .then(json => {
            return json.data || false
        })
        .catch(() => {
            return false
        })
}
const fetchLowerPriceNewStyleSwitch = () => {
    return fetchGet(`${CONFIG_URL.getSwitchOpened}?switchName=lowerPriceNewStyleSwitch`)
        .then(res => {
            return res.data
        })
        .then(json => {
            return json.data || false
        })
        .catch(() => {
            return false
        })
}
export const fetchLowerPriceAllSwitch = () => dispatch => {
    dispatch(genGetLowerPriceSwitchStatus(1))
    return Promise.all([fetchLowerPriceTabSwitch(), fetchLowerPriceNewStyleSwitch()]).then((res) => {
        dispatch(genGetLowerPriceTabSwitch(res[0] || false))
        dispatch(genGetLowerPriceNewStyleSwitch(res[1] || false))
        dispatch(genGetLowerPriceSwitchStatus(2))
    }).catch(() => {
        dispatch(genGetLowerPriceSwitchStatus(2))
    })
}
