import Enum from 'enum';
import { getIfScopeInternational } from '../../../sources/list/scope';
import { InternationalPriceType } from './internationalPriceType';
import { DomesticPriceType } from './domesticPriceType';

const rawPriceTagType = getIfScopeInternational() ? InternationalPriceType : DomesticPriceType;

/**
 * 运价标签，显示在运价级别
 */
export const PRICE_TAG_TYPE = new Enum(rawPriceTagType);

export class PriceTagWrapper {
	constructor(tag) {
		this.tag = PRICE_TAG_TYPE.get(tag.type);
		this.label = tag.label;
		this.description = tag.description;

		if (this.tag.value.initData) {
			this.data = this.tag.value.initData(tag.data || {});
		} else {
			this.data = tag.data || {};
		}
	}
}
