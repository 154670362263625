import Enum from 'enum';

export const FLIGHT_SEGMENT_TYPE = new Enum({
	FLIGHT: '直飞/中转',
	FLIGHT_TRAIN: '空铁',
	FLIGHT_BUS: '空巴',
});

/**
 * 国内航班分组显示，有以下区块
 * 1.直飞---无分组标题
 * 2.中转组合----有分组标题
 * 3.婴儿，儿童不可订----有分组标题
 * 4.其他出行方案----有分组标题（目前无trian - bus组合）
 * !!important 业务场景下新增一种类型时，注意key、value下的key尽量保持一致
 */

export const PRICE_SORT_TYPE_GROUP = new Enum({
	FLIGHT: {
		key: 'FLIGHT',
		title: '',
		desc: [],
		showInSideBar: false,
		sortIndex: 1,
	},
	TRANSFER: {
		key: 'TRANSFER',
		title: '中转组合',
		modalTitle: '中转购买须知',
		desc: [
			'每段航班均需缴纳机建和燃油税',
			'建议购买同一航空公司的中转联程机票，便于确认机票，及发生航班延误时保障权益',
			'因天气、航空公司、铁路部门及突发事件等原因，可能影响乘客登机或乘车。请确认换乘距离和中转时间，以便确定合适的航班、车次',
			'因航空公司、铁路方面原因或不可抗力原因，影响乘客行程所产生的损失，属于携程旅行网免责范畴之内。请及时关注天气及路况信息',
			'如发生航班延误，您有权索要延误证明以便于与航空公司协商处理',
		],
		classname: 'transfer-combine',
		showInSideBar: true,
		sortIndex: 2,
	},
	OTHER_TRANSFER_TYPE: {
		key: 'OTHER_TRANSFER_TYPE',
		title: '更多出行方案',
		desc: [
			'因天气、航空公司、铁路部门、巴士公司及突发事件等原因，可能影响乘客登机或乘车。请确认换乘距离和中转时间，以便确定合适的航班、车次',
			'因航空公司、铁路部门、巴士公司方面原因或不可抗力原因，影响乘客行程所产生的损失，属于携程旅行网免责范畴之内。请及时关注天气及路况信息请及时关注天气及路况信息',
			'如遇汽车票系统原因，导致汽车票出票失败，携程将会为您自动取消订单并全额退款',
		],
		classname: 'journey-plan',
		showInSideBar: true,
		showInSideBarTitle: '<p>更多</p><p>出行方案</p>',
		sortIndex: 4,
	},
	CHILD_DISABLED: {
		key: 'CHILD_DISABLED',
		title: '儿童不可订',
		desc: [],
		showInSideBar: false,
		sortIndex: 3,
	},
	INFANT_DISABLED: {
		key: 'INFANT_DISABLED',
		title: '婴儿不可订',
		desc: [],
		showInSideBar: false,
		sortIndex: 3,
	},
	CHILD_OR_INFANT_DISABLED: {
		key: 'CHILD_OR_INFANT_DISABLED',
		title: '儿童/婴儿不可订',
		desc: [],
		showInSideBar: false,
		sortIndex: 3,
	},
	PREFERENTIAL_TRANSIT: {
		key: 'PREFERENTIAL_TRANSIT',
		title: '',
		desc: [],
		showInSideBar: false,
		sortIndex: 0,
	},
});
