import { createSelector } from 'reselect'

const getAds = (state) => state.getIn(["list", "ads", "adList"])

export const getAllAds = createSelector(
    [ getAds ], 
    (adList) => {
      // id = 5100 为澳航对应的广告
      //   return adList.filter((item) => item.get("id") === '5100')
        return adList
    }
)
