import { fetchGet } from '../common';
import CONFIG_URL from '../../constants/common/api';
import * as types from '../../constants/common/actionTypes/searchBox';

// 获取精选地区
export const genSetDomesticCityData = (data) => ({ type: types.SET_DOMESTIC_CITY_DATA, data });
export const genFlightSameCityDetectData = (data) => ({ type: types.SET_FLIGHT_SAMECITY_DATA, data });

//获取放开部分同城市查询搜索
export const fetchFlightSameCityDetectData = (source, cityCode) => (dispatch) => {
	return fetchGet(CONFIG_URL.FlightSameCityDetect, { source: source, cityCode: cityCode })
		.then((res) => res && res.data)
		.then((res) => {
			dispatch(genFlightSameCityDetectData(res));
		});
};
