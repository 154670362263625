import moment from 'moment';
import { QueryStringKeys } from '../sources/list/queryStringKeys';

export const getOffsetDay = (date) => {
	let dDate = moment(date),
		offset = 1,
		today = moment(moment().format('YYYY-MM-DD'));
	if (dDate.isValid()) {
		offset = dDate.diff(today, 'days');
	}
	return offset;
};
export const domesticOnewaySeoSearchLink = (segment) => {
	// Online 国内列表页航班查询URL 规则
	// http://conf.ctripcorp.com/pages/viewpage.action?pageId=141312011
	let url = '',
		{ dCityCode, aCityCode, date } = segment,
		offset = 1;
	if (dCityCode && aCityCode) {
		url += `/booking/${dCityCode}-${aCityCode}`;

		offset = getOffsetDay(date);
		url += `-day-${offset}.html`;
	}

	return url;
};
const flightWayUrlPatternMap = {
	S: 'oneway',
	D: 'round',
	M: 'multi',
};
export const internationalSeoSearchLink = ({ segments, flightWay }) => {
	// http://conf.ctripcorp.com/pages/viewpage.action?pageId=154913436 大致参考，不能完全对应上

	let url = '',
		flightWayUrlPattern = flightWayUrlPatternMap[flightWay];
	if (segments && segments.length && flightWayUrlPattern) {
		url += `/international/${flightWayUrlPattern}-`;
		let cityPairs = [],
			offsets = [];
		segments.map((seg) => {
			let { dCityCode, aCityCode, date } = seg;
			cityPairs.push(`${dCityCode}-${aCityCode}`);
			offsets.push(getOffsetDay(date));
		});

		url += `${cityPairs.join('-')}?dateOffset=${offsets.join('_')}`;
	}
	return url;
};
const LOW_PRICE_REFER = 'low_price_refer';
// 低价数据的session操作
export const setLowPriceFlightSession = (flight) => {
	sessionStorage.setItem(LOW_PRICE_REFER, JSON.stringify(flight));
};
export const getLowPriceFlightSession = () => {
	return JSON.parse(sessionStorage.getItem(LOW_PRICE_REFER));
};
export const removeLowPriceFlightSession = () => {
	sessionStorage.removeItem(LOW_PRICE_REFER);
};

export const getLowPriceReferFromSessionBySegment = (segment, clearLowPriceReffer) => {
	let { arriveCityCode, departCityCode, departDate, returnDate, price, flightWay, source, containsTax } =
		getLowPriceFlightSession() || {};

	if (
		segment.departureDate == departDate &&
		segment.departCityCode == departCityCode &&
		segment.arriveCityCode == arriveCityCode &&
		segment.segmentFlightWay == flightWay &&
		returnDate
			? segment.returnDate == returnDate
			: true
	) {
		clearLowPriceReffer && removeLowPriceFlightSession();
		return {
			[QueryStringKeys.OuterLowPriceContainsTax]: containsTax,
			[QueryStringKeys.OuterLowPriceValue]: price,
			[QueryStringKeys.OuterLowPriceSource]: source,
		};
	} else {
		return null;
	}
};
