import { createSelector } from 'reselect';
import { NOTICE_LEVEL } from '../../../../constants/list/enums';

const getNoticeList = (state) => state.getIn(['list', 'notice', 'noticeList']);
const getThirdClassShow = (state) => state.getIn(['list', 'notice', 'thirdClassShow']);

export const getVisibleNotices = createSelector([getNoticeList, getThirdClassShow], (noticeList, thirdClassShow) => {
	if (!thirdClassShow) {
		return noticeList.filter((t) => t.get('noticeLevel') !== NOTICE_LEVEL.THIRDCLASS);
	} else {
		return noticeList;
	}
});
