import { lightUpload } from '../sources/common/lightUbt';
import { genFlatUbtVal } from './genFlatUbtVal';

const UPLOAD_MIN_INTERVAL = 1000,
	UPLOAD_HISTORY_CLEAN_INTERVAL = 5000;

export class UbtHandler {
	static clickSeq = 0;
	static lastCleanDate = new Date(2018, 0, 1);
	static uploadHistory = {};
	static onUploadData(group, data) {
		try {
			if (group && (data || data === 0) && !data.__skip__) {
				data = genFlatUbtVal(data);

				// clean cache
				if (new Date() - UbtHandler.lastCleanDate >= UPLOAD_HISTORY_CLEAN_INTERVAL) {
					UbtHandler.lastCleanDate = new Date();
					const keys = Object.keys(UbtHandler.uploadHistory);
					keys.forEach((key) => {
						const date = new Date(UbtHandler.uploadHistory[key]);
						if (new Date() - date >= UPLOAD_HISTORY_CLEAN_INTERVAL) {
							delete UbtHandler.uploadHistory[key];
						}
					});
				}

				const historyKey = `${group}____${data}`;
				let lastUploadDate = UbtHandler.uploadHistory[historyKey];

				if (!lastUploadDate) {
					const defaultDate = +new Date(2018, 0, 1);
					UbtHandler.uploadHistory[historyKey] = defaultDate;
					lastUploadDate = defaultDate;
				}

				if (new Date() - lastUploadDate <= UPLOAD_MIN_INTERVAL) {
					return;
				}

				UbtHandler.uploadHistory[historyKey] = +new Date();
				lightUpload(group, data);
			}
		} catch (error) {
			lightUpload('onUploadDataError', error);
		}
	}

	static uploadSequencedUbt(key, data, seq, cache) {
		let cacheKey = JSON.stringify({ key, data }),
			theCache = cache[cacheKey];

		if (theCache && new Date() - theCache.datetime <= 1000) {
			return;
		} else {
			cache[cacheKey] = { datetime: new Date() };
		}

		let keys = Object.keys(cache);
		if (keys.length >= 200) {
			keys.forEach((key) => {
				if (new Date() - cache[key].datetime > 1000) {
					delete cache[key];
				}
			});
		}

		UbtHandler.onUploadData(key, { seq: seq.value++, datetime: new Date().toString(), ...data });
	}

	static genClickRec(ubtKey, ubtRemark) {
		return {
			seq: UbtHandler.clickSeq++,
			key: ubtKey,
			remark: ubtRemark || undefined,
			time: new Date(),
		};
	}
}
