import React from 'react';
import classnames from 'classnames';
import { sendVetTrace } from '@/src/sources/common/lightUbt';
import FILTER_CONTROL_TYPE from '@/src/constants/list/enums/filter/FILTER_CONTROL_TYPE';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '@/src/ubt.v2/list';
import { getFilterItemPrice } from '@/src/sources/common/getFilterItemPrice';

const getIfKeyIsActive = (activeKeys, fiterItem) => {
	return (
		(activeKeys && activeKeys.indexOf(fiterItem) >= 0) ||
		activeKeys.some(
			(item) => item.key === fiterItem || (item.key === 'ZERO' && fiterItem && fiterItem.key === 'ZERO')
		)
	);
};

// 筛选项生成器
const innerFilterOptionsGenerator = (filter, containsTax, options) => {
	// 如下两个参数都是搜索国内航班：
	// groupTitle：中转/经停/空铁 的文字颜色会有区别，所以使用该字段直接传入 Element
	// noPriceText：当选项禁用且无价格时，筛选项价格位置的文字
	let { onCloseMenu, splitRound, currentSegmentSeq, splitRoundFlightsSwitch, groupTitle, noPriceText = '' } = options,
		filterType = filter.get('type'),
		filterControlType = filterType.value.type,
		isRadio = filterControlType === FILTER_CONTROL_TYPE.RADIO_BUTTON, // 是否radio
		classNameOfTitleForChoose = filterControlType.value.classNameOfTitleForChoose,
		groups = filter.get('groups'),
		activeKeys = filter.get('activeKeys'),
		clickHandler = filter.get('clickHandler'),
		handleClickItem = clickHandler.handleClickItem,
		allOptionGroups = [], // 将选项组根据 col, seq 进行分组 [{col:0, seq:0, group: {...} }, {col:0, seq:1, group: {...}} ]
		genOptions = (theFilterItem, allFilterItems) => {
			const { group, col, seq } = theFilterItem,
				items = group.get('items'),
				id = group.get('type').value.id,
				aboveGroupsOfSameCol = allFilterItems.filter((filterItem) => {
					return filterItem.col === col && filterItem.seq < seq && filterItem.group.get('items').size;
				}),
				originalTitle = group.get('title'),
				title =
					originalTitle && aboveGroupsOfSameCol.find((item) => item.group.get('title') === originalTitle)
						? ''
						: originalTitle, // 避免同列重复title
				existAboveGroupOfSameCol = !!aboveGroupsOfSameCol.length, // 存在同一列的其他group
				mustShowNormalGroupTitle = onCloseMenu && existAboveGroupOfSameCol, // 更多中的“其他”组，只包含 看不含税价  筛选项
				currentGroupTitle = title ? (
					<li className="caption" style={mustShowNormalGroupTitle ? { paddingTop: 5 } : {}}>
						<strong>{groupTitle || title}</strong>
					</li>
				) : null;

			return {
				topCaption: onCloseMenu && !mustShowNormalGroupTitle ? currentGroupTitle : null,
				options: (
					<ul
						id={id.replace(/\./g, '__').toLowerCase()}
						className={classnames({ 'item-group': true, 'no-items': !items.size })}
						key={id}
					>
						{(!onCloseMenu || mustShowNormalGroupTitle) && currentGroupTitle ? currentGroupTitle : null}
						{items.map((item, k2) => {
							let key = item.get('key'),
								active = key === 'containsTax' ? !containsTax : getIfKeyIsActive(activeKeys, key), //此处为V2的 看不含税价 做了特殊判断
								filterItemPrice = getFilterItemPrice(item, containsTax),
								overrideClickHandlerKey = item.get('clickHandler'), // 覆盖默认行为的 clickHandler
								overrideHandler = overrideClickHandlerKey ? options[overrideClickHandlerKey] : null,
								enable = item.get('enable'),
								onClickHandler = enable
									? overrideHandler ||
									  handleClickItem.bind(clickHandler, item, activeKeys, { splitRound: splitRound })
									: null;

							return (
								<UbtBoundary
									key={k2}
									tracker={ListUbtGenerator(
										LIST_UBT_GROUP_TYPES.FILTER_ITEM_CLICK,
										LIST_UBT_KEY_TYPES.FILTER_ITEM_CLICK,
										item,
										isRadio,
										active,
										activeKeys,
										'',
										'',
										currentSegmentSeq,
										splitRoundFlightsSwitch
									)}
								>
									<li
										u_key="filter_toggle_entry"
										u_remark={`点击筛选项[${item.get('groupType').value.id}/${item.get(
											'titleForChoose'
										)}]`}
										className={classnames({ disabled: !enable, active })}
										onClick={enable ? onClickHandler : null}
									>
										<span className="form-label">
											{<i className={classNameOfTitleForChoose}></i>}
											{item.get('getLogoForChoose') ? item.get('getLogoForChoose')() : null}
											{item.get('titleForChoose')}
										</span>
										<div className="price">
											{filterItemPrice ? (
												<React.Fragment>
													{' '}
													<dfn>&yen;</dfn>
													{filterItemPrice}
												</React.Fragment>
											) : (
												noPriceText
											)}
										</div>
										{item.get('nearbyAirportDistance') ? (
											<div className="nearby">
												距{item.get('nearbyAirportName')}直线{item.get('nearbyAirportDistance')}
												km
											</div>
										) : undefined}
									</li>
								</UbtBoundary>
							);
						})}
					</ul>
				),
			};
		};

	groups.valueSeq().forEach((option) => {
		const groupCol = option.get('col'),
			groupSubSeq = option.get('seq');

		allOptionGroups.push({ col: groupCol, seq: groupSubSeq, group: option });
	});

	let optionsList = [];
	// 将相同分组的筛选项放在一起
	Array.from(new Set(allOptionGroups.map((item) => item.col)))
		.sort((prev, next) => prev - next)
		.forEach((col) => {
			let optionsOfCol = [],
				optionsOfCurrentCol = allOptionGroups.filter((item) => item.col === col);

			// 至少一个group中包含筛选项，才需要显示该列
			if (optionsOfCurrentCol.some((option) => option.group.get('items').size)) {
				optionsOfCurrentCol
					.sort((prev, next) => prev.seq - next.seq)
					.filter((item) => item.group.get('items').size || item.group.get('alwaysKeepTitle'))
					.forEach((item, index, options) => {
						const theOption = genOptions(item, options);
						// V2需要一直显示航司联盟的title，中转城市所属国家/地区的title
						if (index < options.length - 1) {
							optionsOfCol.push({ ...theOption, splitLine: <div className="split-line"></div> });
						} else {
							optionsOfCol.push(theOption);
						}
					});

				// filterV2 才将caption显示在顶部关闭按钮旁边
				const firstCaption = onCloseMenu
					? optionsOfCol
							.map((item) => item.topCaption)
							.filter(Boolean)
							.find(Boolean)
					: null;

				if (optionsOfCol.length) {
					optionsList.push(
						<div className={`filter-options-same-col same-col-${col + 1}`} key={col}>
							{firstCaption ? <ul className="title-container">{firstCaption}</ul> : null}
							{optionsOfCol.map((item, innerIndex) => (
								<React.Fragment key={innerIndex}>
									{item.options}
									{item.splitLine}
								</React.Fragment>
							))}
						</div>
					);
				}
			}
		});

	return (
		<div className={`filter-options col-${optionsList.length}`}>
			{optionsList}
			{onCloseMenu ? <i className="close-icon" onClick={onCloseMenu}></i> : null}
		</div>
	);
};

// 筛选项生成器
export const filterOptionsGenerator = (
	filter,
	containsTax,
	options = {
		onCloseMenu: null,
		onToggleContainsTax: null,
		splitRound: false,
		currentSegmentSeq: 0,
		splitRoundFlightsSwitch: false,
	}
) => {
	return innerFilterOptionsGenerator(filter, containsTax, options);
};

/**
 * 生成无HOVER筛选项V2，目前只有直飞项，只会返回定义中genOptions()的items第一个
 */
export const filterFlatOptionsV2Generator = (
	allItems,
	activeKeys,
	clickHandler,
	anyFlightIsDirect,
	lableForTitle,
	{ splitRoundFlightsSwitch = false, splitRound = false, currentSegmentSeq = 0 }
) => {
	const firstItem = allItems && allItems.count() ? allItems.first() : null;
	if (firstItem) {
		const handleClickItem = clickHandler.handleClickItem,
			key = firstItem ? firstItem.get('key') : '',
			active = getIfKeyIsActive(activeKeys, key),
			enable = anyFlightIsDirect; // 直飞的可用性应该只受限于整个筛选前的查询结果中是否有直飞航班

		return (
			<UbtBoundary
				tracker={ListUbtGenerator(
					LIST_UBT_GROUP_TYPES.FILTER_ITEM_CLICK,
					LIST_UBT_KEY_TYPES.FILTER_ITEM_CLICK,
					firstItem,
					false,
					active,
					activeKeys,
					'ONLY_DIRECT_FLIGHT',
					lableForTitle,
					currentSegmentSeq,
					splitRoundFlightsSwitch
				)}
			>
				<li
					u_key="filter_toggle_entry"
					u_remark={`点击筛选项[${firstItem.get('groupType').value.id}/${lableForTitle}]`}
					className={classnames({ disabled: !enable })}
					onClick={() => {
						sendVetTrace({
							key: 'online_flight_filter_item_click_test',
						});
						enable ? handleClickItem.bind(clickHandler, firstItem, activeKeys, { splitRound })() : null;
					}}
				>
					<div className="filter-key no-options">
						<span className="form-label">
							<i className={active ? 'active ico-checkbox' : 'ico-checkbox'}></i>
							{lableForTitle}
						</span>
					</div>
				</li>
			</UbtBoundary>
		);
	} else {
		return (
			<li className="disabled">
				<div className="filter-key no-options">
					<span className="form-label">
						<i className="ico-checkbox"></i>
						{lableForTitle}
					</span>
				</div>
			</li>
		);
	}
};
