import PRODUCT_MESSAGE from './productMessage'

export const getIfHideBestFliightsLabel = (wrapper) => {
    return wrapper.data && wrapper.data.showMain === false
}

export const getBestFlightsLabel = (key, wrapper, { emptyIfHide }) => {  //配合UBT，可能出现标签不显示，但需要上报埋点的情况，此时 标签显示调用该方法 emptyIfHide: true, ubt调用该方法 emptyIfHide: false
    if (emptyIfHide && getIfHideBestFliightsLabel(wrapper)) {
        return ''
    }

    return PRODUCT_MESSAGE.BEST_CHOICE[key] || ''
}

// 增加 适合儿童  / 五星航司 的后缀
export const getForBestFlightsPostfix = (wrapper, { emptyIfHide }) => {     //配合UBT，可能出现标签不显示，但需要上报埋点的情况，此时 标签显示调用该方法 emptyIfHide: true, ubt调用该方法 emptyIfHide: false
    if (emptyIfHide && wrapper.data && wrapper.data.showExtra === false) {
        return ''
    }

    if (wrapper.data.forChildOrInfant) {
        const { childCount, infantCount } = wrapper.extData
        //精选航班
        if (childCount || infantCount) {
            const forChildOrInfantPostfix = (childCount && infantCount) ? PRODUCT_MESSAGE.BEST_CHOICE.ForChildAndInfantPostfix
                : (childCount ? PRODUCT_MESSAGE.BEST_CHOICE.ForChildPostfix : PRODUCT_MESSAGE.BEST_CHOICE.ForInfantPostfix)

            return forChildOrInfantPostfix
        } else {
            return ''
        }
    } else if (wrapper.data.isFiveStar) {
        return PRODUCT_MESSAGE.BEST_CHOICE.FiveStarPostfix
    } else {
        return ''
    }
}