import React from 'react'
import PRODUCT_MESSAGE from './productMessage'
import { ITINERARY_TAG_POSITION } from './itineraryTagPosition'
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list';
import { getForBestFlightsPostfix, getBestFlightsLabel, getIfHideBestFliightsLabel } from './getForBestFlightsPostfix';
import { getForBestFlightsHoverFragment } from './getForBestFlightsHoverFragment';

const getBestFlightsTooltip = (bestChoiceFlightType, wrapper) => {
    const hoverTitle = PRODUCT_MESSAGE.BEST_CHOICE_HOVER.CommonTitle,
        hoverText1 = PRODUCT_MESSAGE.BEST_CHOICE_HOVER[bestChoiceFlightType],
        hoverText2 = getForBestFlightsHoverFragment(wrapper),
        hoverContent = [hoverText1, hoverText2].filter(Boolean).join('，')

    return (
        <div className='tooltip'>
            <div>{hoverTitle}</div>
            <div style={{ minWidth: 220 }}>{hoverContent}</div>
        </div>
    )
}

const getLabelAndPostfix = (key, wrapper, emptyIfHide) => {
    const label = getBestFlightsLabel(key, wrapper, { emptyIfHide }),
        postfix = getForBestFlightsPostfix(wrapper, { emptyIfHide })

    return label + postfix
}

const getBestChoiceTagClassName = (wrapper) => {
    return `tag-best-choice-flights${getIfHideBestFliightsLabel(wrapper) ? ' hide-tag' : ''}`
}

const getTagEl = (id, wrapper, key) => {
    return <span id={id} className={getBestChoiceTagClassName(wrapper)}>{getLabelAndPostfix(key, wrapper, true)}<i></i></span>
}

//精选航班
export const BEST_CHOICE_FLIGHT_TYPE = {
    DirectCheapestChoice: {
        //精选·直飞低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.DirectCheapestChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('DirectCheapestChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'DirectCheapestChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('DirectCheapestChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        fullfill: (itineryaryTagData, priceList) => {
            //站点计算直飞低价的逻辑就是航班里有含税运价是所有运价中最低，如果通过筛选把这个运价隐藏了，这个标签就不再显示，直接过滤掉该行程标签
            return priceList.some(price => price.get('avgPriceWithTax') === itineryaryTagData.value)
        },
        ofBestChoiceFlight: true
    },
    CheapestChoice: {
        //精选·当日低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.CheapestChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('CheapestChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'CheapestChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('CheapestChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        fullfill: (itineryaryTagData, priceList) => {
            //站点计算当日低价的逻辑就是航班里有含税运价是所有运价中最低，如果通过筛选把这个运价隐藏了，这个标签就不再显示，直接过滤掉该行程标签
            return priceList.some(price => price.get('avgPriceWithTax') === itineryaryTagData.value)
        },
        ofBestChoiceFlight: true    //精选航班标签要标记下，用来记录埋点
    },
    CheaperTimeSavingChoice: {
        //精选·便宜省时
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.CheaperTimeSavingChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('CheaperTimeSavingChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'CheaperTimeSavingChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('CheaperTimeSavingChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        fullfill: (itineryaryTagData, priceList) => {
            //站点计算便宜省时的逻辑就是航班里有痛苦指数是所有运价中最低，如果通过筛选把这个运价隐藏了，这个标签就不再显示，直接过滤掉该行程标签
            return priceList.some(price => price.get('miseryIndex') === itineryaryTagData.value)
        },
        ofBestChoiceFlight: true
    },
    HigherCostPerformance: {
        //精选·高性价比
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.HigherCostPerformance,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('HigherCostPerformance', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'HigherCostPerformance')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('HigherCostPerformance', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    ShortestTimeChoice: {
        //精选·最省时 (以前叫 精选·耗时短)
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.ShortestTimeChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('ShortestTimeChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'ShortestTimeChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('ShortestTimeChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    DepartAtEveningLowPrice: {
        //精选·晚上出发低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.DepartAtEveningLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('DepartAtEveningLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'DepartAtEveningLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('DepartAtEveningLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    DomesticeTransferLowPrice: {
        //精选·国内中转低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.DomesticeTransferLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('DomesticeTransferLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'DomesticeTransferLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('DomesticeTransferLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    FiveStarAirlineLowPrice: {
        //精选·五星航司低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.FiveStarAirlineLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('FiveStarAirlineLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'FiveStarAirlineLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('FiveStarAirlineLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    HkTransferVisaFreeChoice: {
        //精选·中转免签玩香港
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.HkTransferVisaFreeChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('HkTransferVisaFreeChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'HkTransferVisaFreeChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('HkTransferVisaFreeChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    MacaoTransferVisaFreeChoice: {
        //精选·中转免签玩澳门
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.MacaoTransferVisaFreeChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('MacaoTransferVisaFreeChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'MacaoTransferVisaFreeChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('MacaoTransferVisaFreeChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    ArriveAtAfternoonLowPrice: {
        //精选·下午到达低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.ArriveAtAfternoonLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('ArriveAtAfternoonLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'ArriveAtAfternoonLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('ArriveAtAfternoonLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    DepartAtForenoonLowPrice: {
        //精选·上午出发低价
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.DepartAtForenoonLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('DepartAtForenoonLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'DepartAtForenoonLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('DepartAtForenoonLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    MinorShortestTimeChoice: {
        //精选·耗时短
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.MinorShortestTimeChoice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('MinorShortestTimeChoice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'MinorShortestTimeChoice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('MinorShortestTimeChoice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    MinorFiveStarAirlineLowPrice: {
        //精选·五星航司
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.MinorFiveStarAirlineLowPrice,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('MinorFiveStarAirlineLowPrice', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'MinorFiveStarAirlineLowPrice')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('MinorFiveStarAirlineLowPrice', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    MinorNonNightFlight: {
        //精选·避开红眼
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.MinorNonNightFlight,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('MinorNonNightFlight', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'MinorNonNightFlight')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('MinorNonNightFlight', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },
    HotSaleFlight: {
        //精选·热销航班
        seq: 100,
        group: 'FlightLeftTag',
        title: PRODUCT_MESSAGE.BEST_CHOICE.HotSaleFlight,
        getTag: (_wrapper, index) => (
            <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.BEST_CHOICE_FLIGHTS, LIST_UBT_KEY_TYPES.BEST_CHOICE_FLIGHTS_HOVER, `${getLabelAndPostfix('HotSaleFlight', _wrapper, false)}`)}>
                {getTagEl(index, _wrapper, 'HotSaleFlight')}
            </UbtBoundary>),
        getIfHideTag: (wrapper) => getIfHideBestFliightsLabel(wrapper),
        getTooltip: (_index, _wrapper) => getBestFlightsTooltip('HotSaleFlight', _wrapper),
        position: ITINERARY_TAG_POSITION.LEFT,
        submitOnBook: true,
        ofBestChoiceFlight: true
    },

}
