import Enum from 'enum'
import { UbtHandler } from './handler'

let getCommonBindPropInfo = (handlerType, children, tracker) => {
    let { [handlerType]: handlerOfProp } = children.props,
        handler = (args) => {
            if (handlerOfProp && typeof handlerOfProp === 'function') {
                handlerOfProp(args)
            }

            tracker.onEvent(handlerType, children, args)
        }

    return { [handlerType]: handler }
}

export const UBT_EVENT_TYPE = new Enum({
    LOAD: {
        getBindPropInfo: (children, tracker) => {
            // load就是即刻触发
            tracker.onEvent('onLoad', children, null)
            return {}
        }
    },
    CLICK: {
        getBindPropInfo: (children, tracker) => {
            return getCommonBindPropInfo('onClick', children, tracker)
        }
    },
    MOUSE_DOWN: {
        getBindPropInfo: (children, tracker) => {
            return getCommonBindPropInfo('onMouseDown', children, tracker)
        }
    },
    MOUSE_ENTER: {
        getBindPropInfo: (children, tracker) => {
            return getCommonBindPropInfo('onMouseEnter', children, tracker)
        }
    },
    CUSTOM: {
        getBindPropInfo: (_children, _tracker) => {
            return {}
        }
    }
})

export const UBT_TRIGGER_TYPE = new Enum({
    NOW: {
        afterProcess: (group) => {
            //即刻上报
            UbtHandler.onUploadData(group.value.group, group.value.rawData)
        },
        beforeUnload: (_group) => { }
    },
    UNLOAD: {
        afterProcess: (_group) => { },
        beforeUnload: (group) => {
            //beforeunload时候上报
            UbtHandler.onUploadData(group.value.group, group.value.rawData)
        }
    }
})
