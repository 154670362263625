import { useMemo } from 'react';

import { useStore, ActionType } from './store';
// import { ModalOptionsInner } from './types';
import { eventManager } from './eventManager';

export const useToaster = (toastOptions: { name: string }) => {
    const { modals } = useStore(toastOptions);
    const { name } = toastOptions;

    const handlers = useMemo(
        () => ({
            close: (modalId: string) => {
                eventManager.emit(name, { type: ActionType.REMOVE_MODAL, modalId });
            },
            dismiss: (modalId: string) => {
                // dispatch({
                // 	type: ActionType.DISMISS_MODAL,
                // 	modalId,
                // });
            },
        }),
        [name],
    );

    return {
        modals,
        handlers,
    };
};
