import { getIfFlightAsLowPrice } from '../getIfFlightAsLowPrice';
import getDurtionEnumOfFlight from './getDurtionEnumOfFlight';

const minAvgPriceWithTaxFieldName = 'minAvgPriceWithTax',
	minAvgPriceWithoutTaxFieldName = 'minAvgPriceWithoutTax';

const getAllTransDurationOfFlights = (flights, currentSegmentSeq) => {
	let timespanList = {};

	flights.forEach((flight) => {
		// 直飞航班无需考虑到中转时长的筛选项中
		const { durationEnum } = getDurtionEnumOfFlight(flight, currentSegmentSeq);

		if (durationEnum) {
			const key = durationEnum.key,
				minPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
				minPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']);

			if (!timespanList[key]) {
				timespanList[key] = {
					key,
					[minAvgPriceWithoutTaxFieldName]: Infinity,
					[minAvgPriceWithTaxFieldName]: Infinity,
				};
			}

			if (getIfFlightAsLowPrice(flight)) {
				let timespanItem = timespanList[key];

				if (minPriceWithoutTax < timespanItem[minAvgPriceWithoutTaxFieldName]) {
					timespanItem[minAvgPriceWithoutTaxFieldName] = minPriceWithoutTax;
				}

				if (minPriceWithTax < timespanItem[minAvgPriceWithTaxFieldName]) {
					timespanItem[minAvgPriceWithTaxFieldName] = minPriceWithTax;
				}
			}
		}
	});

	let result = Object.keys(timespanList).map((timespanKey) => {
		return timespanList[timespanKey];
	});

	return result;
};

export default getAllTransDurationOfFlights;
