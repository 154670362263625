import { POI_TYPE } from "../../constants/channel/enum"

//增加附属字段
let addExtField = (key, poiList, depth) => {
    if (depth > 1) {
        return poiList
    } else {
        poiList.forEach(item => {
            item.ShortSpell = item.ShortSpell || ""
            item.formattedShortSpell = item.ShortSpell
            item.Datas = item.Datas || []
            item.Nearby = item.Nearby || []

            item.value = item.Name
            item.Key = key || ""

            item.inChina = item.Country == "中国"

            let isEnglish = new RegExp("[a-zA-zs]+").test(key)

            if (isEnglish) {
                item.keyIsPinYin =
                    item.Type !== 3 &&
                    item.ShortSpell &&
                    item.ShortSpell.toUpperCase().indexOf(key.toUpperCase()) >= 0
                if (item.keyIsPinYin) {
                    let index = item.ShortSpell.toUpperCase().indexOf(key.toUpperCase())
                    item.formattedSpellStart = item.ShortSpell.substr(0, index).toUpperCase()
                    item.formattedSpellEnd = item.ShortSpell.substr(index + key.length).toUpperCase()

                }
                // else {
                //     item.keyIsPinYin = item.Type !==3 && item.EName && item.EName.indexOf(lowerKey) >= 0
                //     if(!item.keyIsPinYin) {
                //         item.keyIsPinYin = item.Type !==3 && item.Spell && item.Spell.indexOf(lowerKey) >= 0
                //     }
                //     if (item.keyIsPinYin) {
                //         item.extInfo = item.ShortSpell
                //     }
                // }
            } else {
                item.keyIsChinese =
                    item.Type !== 3 && item.Name && item.Name.indexOf(key) >= 0
                if (item.keyIsChinese) {
                    let index = item.Name.indexOf(key)
                    item.formattedSpellStart = item.Name.substr(0, index)
                    item.formattedSpellEnd = item.Name.substr(
                        index + key.length
                    )
                }
            }
            item.ShortSpell = item.ShortSpell.toUpperCase()

            let theType = POI_TYPE.get(item.Type + "")
            item.typeName = theType ? theType.value.text : ""
            if (item.SupportFlightSearch) {
                item.canSelect = true
            }
            else {
                item.canSelect = theType ? theType.value.canSelect : false
            }

            item.isAround = item.Type === 7 ? true : null

            if (item.Datas.length) {
                addExtField(key, item.Datas, depth + 1)
            }

            if (item.Nearby.length) {
                addExtField(key, item.Nearby, depth + 1)
            }
        })

        return poiList
    }
}

//检查是否在同一国家有重名城市且不同省份
let checkIfExistSameNameCity = cities => {
    let existSameNameCity = false
    let mapCity = {}
    try {
        if (cities) {
            for (var i = 0; i < cities.length; i++) {
                let city = cities[i]
                if (
                    city.Country &&
                    city.Name &&
                    city.Province &&
                    !city.isAround &&
                    !city.isNearBy &&
                    !city.inChina
                ) {
                    let key = city.Country + "|" + city.Name
                    let value = mapCity[key] || ""
                    let province = "," + city.Province + ","
                    if (value && value.indexOf(province) == -1) {
                        existSameNameCity = true
                        break
                    } else {
                        mapCity[key] = value + province
                    }
                }
            }
        }
    } catch (e) {
        //
    }

    return existSameNameCity
}

// 拼接机场和临近
let splitSubInformation = (key, poiList) => {
    let poiResult = {},
        poi = []

    poiList &&
        poiList.forEach((item, i) => {
            item = JSON.parse(JSON.stringify(item))

            let j = i + 1
            item.Level = 1
            if (item.Datas.length || item.Nearby.length) {
                item.Datas = item.Datas.filter(d => {
                    if (
                        d.Type !== 8 &&
                        d.Type !== 10 &&
                        d.Type !== 11
                    ) {
                        return d
                    }
                })
                item.Nearby = item.Nearby.filter(n => {
                    if (
                        n.Type !== 8 &&
                        n.Type !== 10 &&
                        n.Type !== 11
                    ) {
                        return n
                    }
                })
            }
            switch (item.Type) {
                case 1: //国家
                case 2: //省份
                    if (item.Datas && item.Datas.length > 0) {
                        item.Datas.map((d, q) => {
                            (d.Level = 2),
                                d.isNearBy == true,
                                (d.key = `${d.Name}(${d.Code})-${j}${q}`),
                                (d.text = `${d.Name}(${d.Code})`)
                        })
                    }
                    item.isCountry = item.Type === 1
                    item.key = item.Name + "-" + j
                    item.text = item.Name
                    poi.push(item)
                    break
                case 3: //机场
                    let parentName = "",
                        parentEName = "",
                        parentCode = "",
                        parentCityId = "",
                        timeZone = 480 // 默认为北京时间
                    if (item.Datas && item.Datas.length > 0) {
                        parentName = item.Datas[0].Name
                        parentEName = item.Datas[0].EName
                        parentCode = item.Datas[0].Code
                        parentCityId = item.Datas[0].CityId
                        timeZone = item.Datas[0].TimeZone
                    }
                    item.ParentName = parentName
                    item.ParentEName = parentEName
                    item.ParentCode = parentCode
                    item.ParentCityId = parentCityId
                    item.TimeZone = timeZone
                    // if(item.inChina) {
                    //     item.key = item.Province+"("+item.Name+")"+"("+item.Code+")"
                    // }
                    // else {
                    item.key = `${item.ParentName}(${item.Name})(${item.Code})-${j}`
                    item.text = `${item.ParentName}(${item.Name})(${item.Code})`
                    // }
                    if (item.Nearby.length) {
                        item.Nearby.map((n, q) => {
                            (n.Level = 2),
                                (n.ParentName = parentName),
                                (n.ParentEName = parentEName),
                                (n.ParentCode = parentCode),
                                (n.ParentCityId = parentCityId),
                                (n.isNearBy = true),
                                (n.TimeZone = n.Datas && n.Datas.length > 0 ? n.Datas[0].TimeZone : 480),
                                (n.key = `${item.ParentName}(${n.Name})(${n.Code})-${j}${q}`),
                                (n.text = `${item.ParentName}(${n.Name})(${n.Code})`)
                        })
                        let Nearby = item.Nearby
                        item.Nearby = []
                        poi.push(item)
                        poi = poi.concat(Nearby)
                    } else {
                        poi.push(item)
                    }
                    break
                case 0: //景点
                case 4: //无机场城市，不可选
                case 9://火车站(非Datas或NearBy中提取)
                case 5: //有机场城市，可选
                    let handleTrainFillBack = function (poiItem, j) {
                        if (poiItem.Type == 9) {
                            poiItem.key = `${poiItem.Name}(${poiItem.parentCode ? poiItem.parentCode : (poiItem.Datas && poiItem.Datas.length ? poiItem.Datas[0].Code : poiItem.Code || '')})-${j}`
                            poiItem.text = `${poiItem.Name}(${poiItem.parentCode ? poiItem.parentCode : (poiItem.Datas && poiItem.Datas.length ? poiItem.Datas[0].Code : poiItem.Code || '')})`
                        }
                    },
                        handleTrainInnerFillBack = function (poiItem, innerItem, j, q) {
                            if (innerItem.Type == 5) {
                                innerItem.key = `${innerItem.Name}(${innerItem.Code})-${j}${q}`
                                innerItem.text = `${innerItem.Name}(${innerItem.Code})`
                                innerItem.ParentCode = innerItem.Code
                            }

                            if (innerItem.Type == 3) {
                                innerItem.ParentCode = innerItem.Code
                                if (innerItem.Datas && innerItem.Datas[0]) {
                                    innerItem.key = `${innerItem.Datas[0].Name}(${innerItem.Name})(${innerItem.Code})-${j}${q}`
                                    innerItem.text = `${innerItem.Datas[0].Name}(${innerItem.Name})(${innerItem.Code})`
                                } else {
                                    innerItem.key = `${innerItem.Name}(${innerItem.Code})-${j}${q}`
                                    innerItem.text = `${innerItem.Name}(${innerItem.Code})`
                                }
                            }

                            innerItem.parentType = 9
                        }
                    if (item.Type == 0) {
                        item.key = `${item.Name}(${item.Country})(${item.Province})-${j}`
                        item.text = `${item.Name}(${item.Country})(${item.Province})`
                    } else {
                        if (item.Code) {
                            item.key = `${item.Name}(${item.Code})-${j}`
                            item.text = `${item.Name}(${item.Code})`
                        } else {
                            item.key = `${item.Name}-${j}`
                            item.text = `${item.Name}`
                        }
                    }
                    if (item.Type == 9)
                        handleTrainFillBack(item, j)
                    if (item.canSelect) {
                        if (item.Datas.length || item.Nearby.length) {
                            item.hasAirports = true
                            item.Datas.map((d, q) => {
                                d.Level = 2
                                d.ParentName = item.Name
                                d.ParentEName = item.EName
                                d.ParentCode = item.Code ? item.Code : d.Datas && d.Datas.length > 0 ? d.Datas[0].Code : null
                                d.ParentCityId = item.CityId
                                d.TimeZone = item.TimeZone ? item.TimeZone : 480
                                d.key = `${item.Name}(${d.Name})(${d.Code})-${j}${q}`
                                d.text = `${item.Name}(${d.Name})(${d.Code})`
                                if (item.Type == 9)
                                    handleTrainInnerFillBack(item, d, j, q)
                            })
                            item.Nearby.map((n, q) => {
                                n.Level = 2
                                n.ParentName = item.Name
                                n.ParentEName = item.EName
                                n.isNearBy = true
                                n.ParentCode = item.Code ? item.Code : n.Datas && n.Datas.length > 0 ? n.Datas[0].Code : null
                                n.ParentCityId = item.CityId
                                n.TimeZone = n.Datas && n.Datas.length > 0 ? n.Datas[0].TimeZone : 480
                                n.key = `${item.Name}(${n.Name})(${n.Code})-${j}${q}`
                                n.text = `${item.Name}(${n.Name})(${n.Code})`
                                if (item.Type == 9)
                                    handleTrainInnerFillBack(item, n, j, q)
                            })
                            let Datas = item.Datas,
                                Nearby = item.Nearby
                            item.Datas = []
                            item.Nearby = []
                            poi.push(item)
                            poi = poi.concat(Datas, Nearby)
                        } else {
                            poi.push(item)
                        }
                    } else {
                        if (item.Datas.length || item.Nearby.length) {
                            item.Datas.map((d, q) => {
                                d.Level = 2
                                d.ParentName = item.Name
                                d.ParentEName = d.Datas && d.Datas.length > 0 ? d.Datas[0].EName : null
                                d.ParentCode = d.Datas && d.Datas.length > 0 ? d.Datas[0].Code : null
                                d.TimeZone = d.Datas && d.Datas.length > 0 ? d.Datas[0].TimeZone : 0
                                d.key = `${item.Name}(${d.Name})(${d.Code})-${j}${q}`
                                d.text = `${item.Name}(${d.Name})(${d.Code})`
                                if (item.Type == 9)
                                    handleTrainInnerFillBack(item, d, j, q)
                            })
                            item.Nearby.map((n, q) => {
                                n.Level = 2
                                n.isNearBy = true
                                n.ParentName = item.Name
                                n.ParentEName = n.Datas && n.Datas.length > 0 ? n.Datas[0].EName : null
                                n.ParentCode = n.Datas && n.Datas.length > 0 ? n.Datas[0].Code : null
                                n.TimeZone = n.Datas && n.Datas.length > 0 ? n.Datas[0].TimeZone : 0
                                n.key = `${n.Datas && n.Datas.length > 0 ? n.Datas[0].Name : ''}(${n.Name})(${n.Code})-${j}${q}`
                                n.text = `${n.Datas && n.Datas.length > 0 ? n.Datas[0].Name : ''}(${n.Name})(${n.Code})`
                                if (item.Type == 9)
                                    handleTrainInnerFillBack(item, n, j, q)
                            })
                        }
                        poi.push(item)
                    }
                    break
                case 7: //临近
                    if (item.Datas.length || item.Nearby.length) {
                        item.isAround = true
                    }
                    item.key = `${item.Name}及周边城市-${j}`
                    item.text = `${item.Name}及周边城市`
                    item.TimeZone = item.Datas && item.Datas.length > 0 ? item.Datas[0].TimeZone : 480
                    poi.push(item)
                    break
                case 8:
                case 10:
                case 11:
                    break
            }
        })

    poiResult.existSameNameCity = checkIfExistSameNameCity(poi)
    poiResult.Key = key
    poiResult.poiList = poi || []
    if (poiList && poiList.length < 1) {
        poiResult.noResult = true
    }
    else {
        poiResult.noResult = false
    }

    return poiResult
}

let extractOutTrains = function (poiList) {
    let filterOut = function (list, parentCode) {
        if (!list || !list.length)
            return { list: [], trains: [] }
        let trains = []
        return {
            list: list.filter((item, _index) => {
                if (item.Type == 9) {
                    item.parentCode = item.Datas && item.Datas.length ? item.Datas[0].Code : parentCode
                    trains.push(item)
                    return false
                }
                return true
            }), trains
        }
    },
        res = []
    poiList && poiList.forEach((item, _i) => {
        let datas = filterOut(item.Datas, item.Code),
            nearBy = filterOut(item.Nearby, item.Code)
        res.push({ ...item, Datas: datas.list, Nearby: nearBy.list })
        res = res.concat(datas.trains)
        res = res.concat(nearBy.trains)
    })
    return res
}

let handler = {
    init(key, poiList, depth) {
        let tmpList = []
        if (Array.isArray(poiList.Data))
            tmpList = poiList.Data
        else
            tmpList = []
        let data = extractOutTrains(tmpList)
        let readablePoiList = addExtField(key, data, depth)
        // let topN = takeTop(readablePoiList)

        return splitSubInformation(key, readablePoiList)
    }
}

export default handler
