import Enum from 'enum';
import { UBT_TRIGGER_TYPE } from '../common';
import { getPrevCondFromGlobal } from '../../actions/common';
import { DataRetriever } from './dataRetriver';
import LibHelper from '../../utils/lib';
import { HIT_PRE_SEARCH } from '../../sources/common/localStorageKey';

const COMMON_CLICK_GROUP = 'flt_oversea_search_click',
	LOW_PRICE_CALENDAR_CLICK_GROUP = 'flt_oversea_search_result_new_online_click',
	FILTER_ITEM_CLICK_GROUP = 'c_filter',
	COMMON_MOUSE_ENTER_GROUP = 'flt_oversea_search_hover',
	UPLOAD_NOW_MOUSE_ENTER = 'h_',
	BASIC_DATA_FLOW_GROUP = 'flt_oversea_search_result_new_online_basic',
	COMMON_LOG_GROUP = 'flt_oversea_search_result_online_debug',
	FLT_OVERSEA_SEARCH_RESULT_KB_GROUP = 'flt_oversea_search_result_kb_hover',
	SEARCH_DATA_SUBMIT_GROUP = 'flt_oversea_homepage_online_basic',
	BEST_CHOICE_FLIGHTS_GROUP = 'flt_oversea_search_tag',
	RECOMMEND_QRCODE_GROUP = 'c_int_lowprice_card_show',
	BRAND_PRICE_GROUP = 'c_brand_price',
	FLIGHT_ITEM_SHOW_GROUP = 'c_flight_item_show',
	CHOOSE_X_PRICE_GROUP = 'c_choose_price',
	SEARCH_DATA_STATISTICS_GROUP = 'flt_oversea_search_statistics',
	SELECTED_FLIGHTS = 'c_reselect_flights',
	MATRIX_TOGGLE_CLICK = 'c_matrix_toggle_click',
	MATRIX_ITEM_CLICK = 'c_matrix_item_click',
	FLIGHT_DETAIL_CLICK = 'c_popup_flight_detail',
	SUB_AIRPORT_LOAD_CLICK = 'c_sub_airport',
	SORT_CLICK = 'c_sort',
	FLIGHT_CARD_DEPARTURE_CLICK = 'c_tolist_flightcard',
	FLIGHT_CARD_ARRIVE_CLICK = 'c_backlist_flightcard',
	BACK_TO_SEARCH_CLICK = 'c_back_to_search',
	REQUIREMENT_FORM_CLICK = 'c_requirement_form',
	BOOK_BUTTON_CLICK = 'c_book_botton',
	FLIGHT_DETAIL_TOLIST_CHOOSE_BUTTON_CLICK = 'c_tolist_flight_detail_choose',
	FLIGHT_DETAIL_BACKLIST_CHOOSE_BUTTON_CLICK = 'c_backlist_flight_detail_choose',
	FLIGHT_DETAIL_INBOUND_TAB_CLICK = 'c_backlist_flight_detail_inbound_tab',
	FLIGHT_DETAIL_OUTBOUND_TAB_CLICK = 'c_backlist_flight_detail_outbound_tab',
	SPLIT_ROUND_CLICK_BUILD_UP_GROUP = 'c_split_round_click_build_up',
	SPLIT_ROUND_CLICK_FREE_STYLE_GROUP = 'c_split_round_click_free_style',
	SNATCH_BOOKING_SHOW = 'c_int_list_rob_show',
	FIRST_SEG_FLIGHT_CARD_CLICK = 'c_first_flight_seg_before_finished',
	FLIGHT_LIST_DATA_GROUP = 'c_flight_list_data',
	BOOK_COUNT_REASON_CLICK = 'c_click_book_count_reason';

const hitChannelPreSearch = sessionStorage.getItem(HIT_PRE_SEARCH);
if (hitChannelPreSearch) {
	sessionStorage.removeItem(HIT_PRE_SEARCH);
}
const RAW_COMMON_CLICK = {},
	RAW_LOW_PRICE_CALENDAR_CLICK = {},
	FILTER_ITEM_CLICK = {},
	RAW_COMMON_MOUSE_ENTER = {},
	RAW_UPLOAD_NOW_MOUSE_ENTER = {},
	COMMON_LOG = {},
	TONGCHENG_REMARK_RAW_DATA = { __skip__: true },
	SEARCH_DATA = {},
	SEARCH_DATA_STATISTICS = {},
	BEST_CHOICE_FLIGHTS_DATA = { tags_flt: [], tags_hover: {} },
	RECOMMEND_QRCODE_UPLOAD_NOW_LOAD = {},
	BRAND_PRICE_DATA = { __skip__: true },
	FLIGHT_ITEM_SHOW_DATA = { __skip__: true },
	SELECTED_FLIGHTS_DATA = {},
	MATRIX_TOGGLE_CLICK_DATA = {},
	MATRIX_ITEM_CLICK_DATA = {},
	FLIGHT_DETAIL_CLICK_DATA = {},
	SUB_AIRPORT_CLICK_DATA = {},
	SORT_CLICK_DATA = {},
	FLIGHT_CARD_DEPARTURE_CLICK_DATA = {},
	FLIGHT_CARD_ARRIVE_CLICK_DATA = {},
	FLIGHT_LIST_DATA = {
		totalDirectFlightCount: 0,
		totalTransferFlightCount: 0,
		showDirectFlightCount: 0,
		showTransferFlightCount: 0,
		showVisaFlightCount: 0,
		showNoVisaFlightCount: 0,
		segmemtNo: 0,
		flightWay: '',
	},
	BOOK_COUNT_REASON_CLICK_DATA = {};

const prevCond = getPrevCondFromGlobal();

const RAW_BASIC_DATA_FLOW = Object.assign(
	{
		hit_presearch: hitChannelPreSearch ? 1 : 0,
		price_predict: {
			entrance_show: '0',
			float_show: '0',
			price_prediction: '0',
			price_change: '0',
			travel_tips: '0',
			travel_tips_holidays: '0',
			travel_tips_visa: '0',
			travel_tips_pois: '0',
		},
		ow_recommend_rt: {
			ad_roundway_show: '0',
			ad_roundway_date: 0,
			ad_roundway_moredate: 0,
			ad_roundway_moredate_backdate: 0,
			ad_roundway_moredate_confirm: 0,
		},
		ad_bar: {
			MIDDLE: [],
			ASIDE: [],
			BOTTOM: [],
		},
		source_module: (() => {
			if (LibHelper.isFromMapPrice()) {
				return 'mh_search';
			}
			if (LibHelper.isFromHistorySearch()) {
				return 'recommend_card';
			}
			return '';
		})(),
		//亚航超级会员..
		uidgrade: window.GlobalConfigs.userLevelCode || '',
		isSVIP: window.GlobalConfigs.isSuperVipUser ? 1 : 0,
	},
	prevCond.cabin.indexOf('C') >= 0 || prevCond.cabin.indexOf('F') >= 0
		? {
				FC_rec_SY: {
					Close: 0, //点击收起推荐航班的次数
					Open: 0, //点击展开推荐航班的次数
					RecClass: '', //所有推荐航班舱位连接起来，比如 YSF，推荐定义是 非查询条件舱位 的航班
					RecDfNum: 0, //所有推荐直飞航班数目，推荐定义是 非查询条件舱位 的航班
					SearchClass: prevCond.cabin, //查询条件的舱位
					SearchDfNum: 0, //查询条件舱位的航班中直飞航班数目
					SearchTransNum: 0, //查询条件舱位的航班中中转航班数目
				},
		  }
		: {},
	{
		RecommendProduct: {
			IsShow: '',
			RecommendInfo: [],
		},
	},
	DataRetriever.getBasicDataFromPrevCond(prevCond)
); //离开页面上报

export const LIST_UBT_GROUP_TYPES = new Enum({
	COMMON_CLICK: {
		//基础点击埋点
		group: COMMON_CLICK_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_COMMON_CLICK,
	},
	FILTER_ITEM_CLICK: {
		//即时上报的点击埋点
		group: FILTER_ITEM_CLICK_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: FILTER_ITEM_CLICK,
	},
	COMMON_MOUSE_ENTER: {
		//基础HOVER埋点
		group: COMMON_MOUSE_ENTER_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_COMMON_MOUSE_ENTER,
	},
	UPLOAD_NOW_MOUSE_ENTER: {
		//即时上报HOVER埋点
		group: UPLOAD_NOW_MOUSE_ENTER,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: RAW_UPLOAD_NOW_MOUSE_ENTER,
	},
	BASIC_DATA_FLOW: {
		//基础上报业务数据
		group: BASIC_DATA_FLOW_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_BASIC_DATA_FLOW,
	},
	FLIGHT_LIST_FLOW: {
		group: FLIGHT_LIST_DATA_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: FLIGHT_LIST_DATA,
		resetFlightListData: () => {
			FLIGHT_LIST_DATA.totalDirectFlightCount = 0;
			FLIGHT_LIST_DATA.totalTransferFlightCount = 0;
			FLIGHT_LIST_DATA.showDirectFlightCount = 0;
			FLIGHT_LIST_DATA.showTransferFlightCount = 0;
			FLIGHT_LIST_DATA.showVisaFlightCount = 0;
			FLIGHT_LIST_DATA.showNoVisaFlightCount = 0;
			FLIGHT_LIST_DATA.segmemtNo = 0;
			FLIGHT_LIST_DATA.flightWay = '';
		},
	},
	COMMON_LOG: {
		//自定义上报的日志信息
		group: COMMON_LOG_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: COMMON_LOG,
	},
	/**
	 * .赠跨城机场巴士专用埋点
	 */
	FLT_OVERSEA_SEARCH_RESULT_KB: {
		group: FLT_OVERSEA_SEARCH_RESULT_KB_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	LOW_PRICE_CALENDAR_CLICK: {
		//低价日历点击埋点
		group: LOW_PRICE_CALENDAR_CLICK_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_LOW_PRICE_CALENDAR_CLICK,
	},
	SPLIT_ROUND_CLICK_BUILD_UP: {
		//往返分屏点击往返组合
		group: SPLIT_ROUND_CLICK_BUILD_UP_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	SPLIT_ROUND_CLICK_FREE_STYLE: {
		//往返分屏点击自由搭配
		group: SPLIT_ROUND_CLICK_FREE_STYLE_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	//通程航班描述文案显示就上报
	TONGCHENG_REMARK: {
		group: 'c_tongcheng_flight_remark',
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: TONGCHENG_REMARK_RAW_DATA,
	},
	//购票攻略
	GUIDE_LINE: {
		group: BASIC_DATA_FLOW_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_BASIC_DATA_FLOW,
	},
	//单程推荐往返
	OW_RECOMMEND_RT: {
		group: BASIC_DATA_FLOW_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: RAW_BASIC_DATA_FLOW,
	},
	// 查询数据上报
	SEARCH_DATA_SUBMIT: {
		group: SEARCH_DATA_SUBMIT_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: SEARCH_DATA,
	},
	// 查询数据统计及上报
	SEARCH_DATA_STATISTICS_SUBMIT: {
		group: SEARCH_DATA_STATISTICS_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: SEARCH_DATA_STATISTICS,
	},
	// 精选航班位置信息统计
	BEST_CHOICE_FLIGHTS: {
		group: BEST_CHOICE_FLIGHTS_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: BEST_CHOICE_FLIGHTS_DATA,
	},
	RECOMMEND_QRCODE: {
		group: RECOMMEND_QRCODE_GROUP,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: RECOMMEND_QRCODE_UPLOAD_NOW_LOAD,
	},
	//东航品牌运价
	BRAND_PRICE: {
		group: BRAND_PRICE_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: BRAND_PRICE_DATA,
	},
	FLIGHT_ITEM_SHOW: {
		group: FLIGHT_ITEM_SHOW_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: FLIGHT_ITEM_SHOW_DATA,
	},
	//国内：三选X运价
	CHOOSE_X_PRICE: {
		group: CHOOSE_X_PRICE_GROUP,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: BRAND_PRICE_DATA,
	},
	//已选航班
	SELECTED_FLIGHTS: {
		group: SELECTED_FLIGHTS,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: SELECTED_FLIGHTS_DATA,
	},
	//航司矩阵，切换可见
	MATRIX_TOGGLE: {
		group: MATRIX_TOGGLE_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: MATRIX_TOGGLE_CLICK_DATA,
	},
	//航司矩阵，点击筛航司/中转次数等点击选项
	MATRIX_ITEM: {
		group: MATRIX_ITEM_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: MATRIX_ITEM_CLICK_DATA,
	},
	//航班详情
	FLIGHT_DETAIL: {
		group: FLIGHT_DETAIL_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: FLIGHT_DETAIL_CLICK_DATA,
	},
	//子机场推荐
	SUB_AIRPORT: {
		group: SUB_AIRPORT_LOAD_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: SUB_AIRPORT_CLICK_DATA,
	},
	SORT_CLICK: {
		group: SORT_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: SORT_CLICK_DATA,
	},
	FLIGHT_CARD_DEPARTURE_CLICK: {
		group: FLIGHT_CARD_DEPARTURE_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: FLIGHT_CARD_DEPARTURE_CLICK_DATA,
	},
	FLIGHT_CARD_ARRIVE_CLICK: {
		group: FLIGHT_CARD_ARRIVE_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: FLIGHT_CARD_ARRIVE_CLICK_DATA,
	},
	FIRST_SEG_FLIGHT_CARD_CLICK: {
		group: FIRST_SEG_FLIGHT_CARD_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	BACK_TO_SEARCH_CLICK: {
		//回到搜索点击埋点
		group: BACK_TO_SEARCH_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	REQUIREMENT_FORM_CLICK: {
		//筛选下面的需求单埋点
		group: REQUIREMENT_FORM_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	BOOK_BUTTON_CLICK: {
		//预订按钮点击埋点
		group: BOOK_BUTTON_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	FLIGHT_DETAIL_INBOUND_TAB_CLICK: {
		group: FLIGHT_DETAIL_INBOUND_TAB_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	FLIGHT_DETAIL_OUTBOUND_TAB_CLICK: {
		group: FLIGHT_DETAIL_OUTBOUND_TAB_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	FLIGHT_DETAIL_TOLIST_CHOOSE_BUTTON_CLICK: {
		//预定按钮点击埋点
		group: FLIGHT_DETAIL_TOLIST_CHOOSE_BUTTON_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	FLIGHT_DETAIL_BACKLIST_CHOOSE_BUTTON_CLICK: {
		//预定按钮点击埋点
		group: FLIGHT_DETAIL_BACKLIST_CHOOSE_BUTTON_CLICK,
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	SNATCH_BOOKING_SHOW: {
		group: SNATCH_BOOKING_SHOW,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: {},
	},
	TRIGGER_NOW_CLICK: {
		group: '',
		trigger: UBT_TRIGGER_TYPE.NOW,
		rawData: {},
	},
	BOOK_COUNT_REASON_CLICK: {
		group: BOOK_COUNT_REASON_CLICK,
		trigger: UBT_TRIGGER_TYPE.UNLOAD,
		rawData: BOOK_COUNT_REASON_CLICK_DATA,
	},
});
