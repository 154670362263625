import React, { FC, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { keys } from 'ramda';
import immutable from 'immutable';
import { showContentListStorage } from '@/src/utils/storage';
import {
	onCloseNoticeThirdClass,
	onFetchNotices,
	onCloseNoticeFirstClassPop,
} from '../../../../actions/list/notice.js';
import { getVisibleNotices } from './noticeSelector.js';

import NoticePop from '@/src/components/list/top/notice/noticePop';
import NoticeList from '@/src/components/Base/notice/noticeList';
import { getRouterKeySelector } from '../../result/flight/baseSelector';
import { UbtHandler } from '../../../../ubt.v2/handler.js';

type NoticeContainerProps = {
	noticeList: immutable.List<immutable.Record<{ content: string; title: string; contentRank: number }>>;
	searchCriteria: immutable.Record<{ flightSegments: any[] }>;
	fetchNotices: (params: any) => void;
	routerKey: string;
};
const NoticeContainer: FC<NoticeContainerProps> = ({ noticeList, searchCriteria, fetchNotices, routerKey }) => {
	const [isShowDialog, setIsShowDialog] = useState(false);

	useEffect(() => {
		// 初始化请求公告接口
		const params: any[] = [];
		// @ts-expect-error
		if (searchCriteria && searchCriteria.size > 0) {
			searchCriteria.get('flightSegments').forEach((item) => {
				params.push({
					departureCityCode: item.get('departureCityCode'),
					departureDate: item.get('departureDate'),
					arrivalCityCode: item.get('arrivalCityCode'),
				});
			});
		}
		fetchNotices(params);
	}, [searchCriteria, routerKey, fetchNotices]);

	useEffect(() => {
		const showContentMap: Record<string, boolean | undefined> = showContentListStorage.get({});
		const contentMap = noticeList
			.filter((item) => item.get('contentRank') === 1) // 只有特级通告才会弹窗
			.reduce<Record<string, boolean | undefined>>((prev, next) => {
				return { ...prev, [`${next.get('title')}|${next.get('content')}`]: true };
			}, {});
		const allContentMap = { ...showContentMap, ...contentMap };
		// 存在任意一个唯有展示过
		const isShow = keys(allContentMap).length > keys(showContentMap).length;
		if (isShow) {
			setIsShowDialog(true);
			showContentListStorage.set(allContentMap);
		}
	}, [noticeList]);

	return (
		(noticeList && noticeList.size > 0 && (
			<div className="notice-box">
				<NoticePop
					{...{
						notices: noticeList,
						isShowDialog,
						onConfirm: () => {
							setIsShowDialog(false);
						},
					}}
				/>
				<NoticeList
					{...{
						noticeList,
						showDialog: () => {
							setIsShowDialog(true);
						},
					}}
				/>
			</div>
		)) ||
		null
	);
};

const mapStateToProps = (state) => ({
	noticeList: getVisibleNotices(state),
	searchCriteria: state.getIn(['list', 'search', 'prevCond']),
	routerKey: getRouterKeySelector(state),
});

const mapDispatchToProps = (dispatch) => ({
	onCloseThirdClass: () => {
		dispatch(onCloseNoticeThirdClass());
	},
	fetchNotices: (params) => {
		UbtHandler.onUploadData('fetch_notices', { remark: 'fetchNotices' });
		dispatch(onFetchNotices(params));
	},
	onCloseFirstClassPop: () => {
		UbtHandler.onUploadData('close_crital_warn_dialog', { remark: '关闭紧急通告弹窗' });
		dispatch(onCloseNoticeFirstClassPop());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(NoticeContainer);
