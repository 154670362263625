import Immutable from 'immutable'
import { FilterItemHandlerFactory } from './filterItemHandler'
import CONSTS from '../../../../constants/list/constConfig'
import { getFilterTypes } from '../../../../constants/list/enums';

/**
 * 过滤项生成器工厂
 */
export class FilterOptionRetrieverFactory {
    static getInstance(filterType) {
        const filterTypes = getFilterTypes()

        switch (filterType) {
            case filterTypes.PLUS_ONE:
                return new FilterOptionRetriever_PlusOne(filterType)
            case filterTypes.TRANS_COUNT:
                return new FilterOptionRetriever_TransCount(filterType)
            case filterTypes.AIRLINE:
                return new FilterOptionRetriever_Airline(filterType)
            case filterTypes.TIME:
                return new FilterOptionRetriever_Time(filterType)
            case filterTypes.GO_TIME:
                return new FilterOptionRetriever_GoTime(filterType)
            case filterTypes.BACK_TIME:
                return new FilterOptionRetriever_BackTime(filterType)
            case filterTypes.TRANS_CITY:
                return new FilterOptionRetriever_TransCity(filterType)
            case filterTypes.TRANS_TIMESPAN:
                return new FilterOptionRetriever_TransTimespan(filterType)
            case filterTypes.AIRPORT:
                return new FilterOptionRetriever_Airport(filterType)
            case filterTypes.GO_AIRPORT:
                return new FilterOptionRetriever_GoAirport(filterType)
            case filterTypes.BACK_AIRPORT:
                return new FilterOptionRetriever_BackAirport(filterType)
            case filterTypes.CLASS_GRADE:
                return new FilterOptionRetriever_ClassGrade(filterType)
            case filterTypes.OTHER:
                return new FilterOptionRetriever_Other(filterType)
            default:
                return new FilterOptionRetriever(filterType)
        }
    }
}

/**
 * 过滤项生成器的基类
 */
class FilterOptionRetriever {
    constructor(filterType) {
        this.filterType = filterType
    }

    //可以通过配置隐藏某个GROUP
    isGroupHidden(group) {
        return group.type.value.id && CONSTS.FILTER.HIDDEN_GROUPS_IDS.includes(group.type.value.id)
    }

    /**
     * 生成筛选项运价的航班，正常来说航班所有运价都需要用来参与生成筛选项上的最低价，下面情况除外：
     *    搜索高舱C、F、C_F，查询结果运价是搜索舱等之外的舱等
     */
    getFlightsWrapperOfGenFilterPrice(flights, currentSegmentSeq, prevCond, isBuildUp) {
        let filteredFlights = [],
            prevCondCabinIsHighCabin = prevCond.get('cabinEnum').value.isHighCabin

        flights.forEach(flight => {
            let priceList = flight.get('priceList'),
                //只有在搜索高舱时候的推荐运价才不参与最低运价计算
                anyPriceIsRecommendCabin = prevCondCabinIsHighCabin && priceList.some(price => price.get('isRecommendCabin'))

            //如果航班包含推荐运价，则继续查找是否包含非推荐运价，如果包含则该航班可用来生成运价，否则不允许
            if (anyPriceIsRecommendCabin) {
                let priceListOfNotRecommendCabin = priceList.filter(price => price.get('isRecommendCabin') === false)
                if (priceListOfNotRecommendCabin.size) {
                    filteredFlights.push(flight.set('priceList', priceListOfNotRecommendCabin))
                }
            } else {
                filteredFlights.push(flight)
            }
        })

        filteredFlights = Immutable.fromJS(filteredFlights)

        return this.getFlightsWrapper(filteredFlights, currentSegmentSeq, prevCond, isBuildUp)
    }

    getFlightsWrapper(flights, currentSegmentSeq, prevCond, isBuildUp) {
        let theFlightsWrapperMap = Immutable.Map()

        if (this.getVisible(isBuildUp, prevCond.get('flightWay'), flights)) {
            this.filterType.value.groups.forEach(group => {
                theFlightsWrapperMap = theFlightsWrapperMap.set(group.type, flights.size ? group.getFlightsWrapper(flights, currentSegmentSeq, prevCond, isBuildUp) : {})
            })
        }

        return theFlightsWrapperMap
    }

    getFlatGroups(flights, flightsWrapper, currentSegmentSeq, prevCond, isBuildUp) {
        let theResultMap = {}

        if (this.getVisible(isBuildUp, prevCond.get('flightWay'), flights)) {
            this.filterType.value.groups.forEach(group => {
                if (this.isGroupHidden(group) === false) {
                    const groupType = group.type,
                        groupCol = group.col || 0,   //用来确定该group在展开菜单所处列的区块号，比如 直飞/中转 就分3列：直飞/中转，中转城市，中转时长
                        groupSubSeq = group.seq || 0 //相同列区块内的索引号

                    theResultMap[group.type] = {
                        type: groupType,
                        col: groupCol,
                        seq: groupSubSeq,
                        alwaysKeepTitle: group.alwaysKeepTitle,
                        title: groupType.value.getTitle(prevCond, currentSegmentSeq),
                        items: flights.size ? group.getFlatItems(flightsWrapper.get(groupType) || flights, currentSegmentSeq) : []
                    }
                }
            })
        }

        return Immutable.fromJS(theResultMap)
    }

    getVisible(isBuildUp, flightWay, flights) {
        return this.filterType.value.getVisible(isBuildUp, flightWay, flights)
    }

    getDefaultActiveKeys(flights, prevCond, { currentSegmentSeq, isBuildUp }) {
        return Immutable.fromJS(this.filterType.value.getDefaultActiveKeys(flights, prevCond, { currentSegmentSeq, isBuildUp }))
    }

    getClickHandler() {
        return FilterItemHandlerFactory.getInstance(this.filterType)
    }
}

class FilterOptionRetriever_PlusOne extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_TransCount extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_Airline extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_Time extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_GoTime extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_BackTime extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_TransCity extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_TransTimespan extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_Airport extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_GoAirport extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_BackAirport extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_ClassGrade extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

class FilterOptionRetriever_Other extends FilterOptionRetriever {
    constructor(filterType) {
        super(filterType)
    }
}

