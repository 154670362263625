import React from 'react'
import moment from 'moment'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list'

export default class SelectMonth extends React.Component {
    constructor(props) {
        super(props);
        this.onPrevSelectMonth = this.onPrevSelectMonth.bind(this)
        this.onNextSelectMonth = this.onNextSelectMonth.bind(this)

    }
    onPrevSelectMonth() {
        let { prevDisabled, currentMonthIndex } = this.props;
        if (prevDisabled) return false
        this.props.selectMonth(--currentMonthIndex)
    }
    onNextSelectMonth() {
        let { nextDisabled, currentMonthIndex } = this.props;
        if (nextDisabled) return false
        this.props.selectMonth(++currentMonthIndex)
    }
    render() {
        const { nextDisabled, prevDisabled, currentMonthIndex } = this.props

        return (
            <div className="cld-select-month">
                <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_last_month')}>
                    <div className={`arrow-l ${prevDisabled ? 'disabled' : ''}`} onClick={this.onPrevSelectMonth}><i className="arrow-left-bold"></i></div>
                </UbtBoundary>
                <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_next_month')}>
                    <div className={`arrow-r ${nextDisabled ? 'disabled' : ''}`} onClick={this.onNextSelectMonth}><i className="arrow-right-bold"></i></div>
                </UbtBoundary>
                <span className="cld-year">{moment().add(currentMonthIndex, 'months').year()}年</span>
                <em className="cld-month">{moment().add(currentMonthIndex, 'months').month() + 1}月</em>
            </div>

        )
    }

}
