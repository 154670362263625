import React from 'react'
import { connect } from 'react-redux'
import { getRoundCalendarTrend} from './calendarLPSelector.js'
import TrendCalendarLowPrice from '../../../../components/list/top/calendarLowprice/trend'

class TrendCalendarContainer extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <TrendCalendarLowPrice {...this.props} />
        )
    }
}

const mapStateToProps = (state,props) => ({
    ...props,
    trendCalendar:getRoundCalendarTrend(state),
})

const mapDispatchToProps = _dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(TrendCalendarContainer)