import React, { PropsWithChildren } from 'react';
import { ubtDevTrace, ubtError } from '@/src/sources/common/lightUbt';

type ErrorBoundaryProps = PropsWithChildren<{
	fallback?: any;
}>;

type ErrorBoundaryStart = {
	error: Error | null;
	errorInfo: any;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryStart> {
	constructor(props) {
		super(props);

		this.state = {
			error: null,
			errorInfo: null,
		};
	}

	componentDidCatch(error, errorInfo) {
		this.setState({
			error: error,
			errorInfo: errorInfo,
		});
	}

	render() {
		const { fallback, children } = this.props;
		const { errorInfo, error } = this.state;
		if (error) {
			ubtError(error);
		}
		if (errorInfo) {
			ubtDevTrace('flt_online_error_boundary', {
				error: error ? error.toString() : '',
				stack: errorInfo.componentStack,
			});
			return fallback || null;
		}
		return children;
	}
}

export default ErrorBoundary;
