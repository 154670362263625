export const genFlatUbtVal = rawData => {
    let data = '{}'

    if (typeof rawData !== 'string') {
        if (typeof rawData.formatData === 'function') {
            data = rawData.formatData(rawData)
        }

        if (rawData instanceof Error) {
            data = `message: ${rawData.message}\nstack: ${rawData.stack}`
        } else {
            data = JSON.stringify(rawData)
        }
    } else {
        data = rawData
    }

    return data
}