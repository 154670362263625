import Enum from 'enum'
import { UBT_TRIGGER_TYPE } from '../common'

const SEND_NOW_COMMON_CLICK = 'c_click'
const SEARCH_DATA_INIT_GROUP = 'search_box_init'
const RAW_SEARCH_DATA_INIT = {}

export const SEARCH_BOX_UBT_GROUP_TYPES = new Enum({
    SEND_NOW_CLICK: {
        //即时上报点击
        group: SEND_NOW_COMMON_CLICK,
        trigger: UBT_TRIGGER_TYPE.NOW,
        rawData: {}
    },
    SEARCH_DATA_INIT:{
        group: SEARCH_DATA_INIT_GROUP,
        trigger: UBT_TRIGGER_TYPE.UNLOAD,
        rawData: RAW_SEARCH_DATA_INIT
    }
})