
import * as TYPES from '../../constants/common/actionTypes'
import CONFIG_URL from '../../constants/common/api'
import { fetchGet } from '../common'

const genWorkingDays = workingDays => ({ type: TYPES.COMMON_GET_WORKINGDAYS, workingDays })


export const fetchYearWorkingDays = _ => dispatch => {
    let url = CONFIG_URL.getYearWorkingDays
        return fetchGet(url)
            .then(res => res && res.data || {})
            .then(json => {
                dispatch(genWorkingDays(json.data || {}))
            })
}
