/**
 * 修改日期组件
 */
import React from 'react';
import moment from 'moment';
import cls from 'classnames';
import shortid from 'shortid';
import { formatWeek, addTimezone } from '../../utils/dateHelper';
import { CalendarRoundTripDays } from './searchBox/RtDays';
import { getLowPriceKey } from '../../sources/common/lowPriceHelper';
import DatePicker from './DatePicker/async';

const isQingMing = (date) => {
	const qingMingDay = Math.floor((date.year() % 100) * 0.2442 + 4.81) - Math.floor((date.year() % 100) / 4);
	return '040' + qingMingDay === date.format('MMDD');
};

class ModifyDateInfoV2 extends React.Component {
	constructor() {
		super();
		this.state = {
			focused: false,
		};

		this.picker = React.createRef();
		this.getFocus = this.getFocus.bind(this);
		this.loseFocus = this.loseFocus.bind(this);
		this.handleDateChange = this.handleDateChange.bind(this);
		this.fetchLowPriceHelper = this.fetchLowPriceHelper.bind(this);
		this.renderDay = this.renderDay.bind(this);
		this.renderDayV2 = this.renderDayV2.bind(this);
		this.getDayTop = this.getDayTop.bind(this);
		this.getPriceDom = this.getPriceDom.bind(this);
	}

	fetchLowPriceHelper(props) {
		let dCityCode = props.selectedCity.getIn(['owDCity', 'Code']),
			aCityCode = props.selectedCity.getIn(['owACity', 'Code']),
			dCodeAndACode = `${dCityCode}-${aCityCode}`,
			{ domestic, cabin } = props;

		if (!(props.lowPrice && props.lowPrice.get(dCodeAndACode) ? props.lowPrice.get(dCodeAndACode) : null)) {
			props.fetchLowPrice(dCityCode, aCityCode, { domestic, cabin });
		}
	}

	fetchCountryLowPriceHelper(props) {
		let dCity = props.selectedCityAndCountry['dCity'],
			dCityCode = dCity && dCity['code'] ? dCity['code'] : '',
			aCity = props.selectedCityAndCountry['aCity'],
			aCityCode = aCity && aCity['countryCode'] ? aCity['countryCode'] : '',
			dCodeAndACode = `${dCityCode}-${aCityCode}`,
			flightWay = props.flightWay;
		if (!dCityCode || !aCityCode) {
			return;
		}
		let countryLowPrice = props.countryLowPrice || null;
		let countryLowRPrice = props.countryLowRPrice || null;
		let theCountryLowPrice = countryLowPrice ? countryLowPrice[dCodeAndACode] : null;
		let thisCountryLowRPrice = countryLowRPrice ? countryLowRPrice[dCodeAndACode] : null;
		// 去程价格
		if (flightWay == 'OW') {
			if (!theCountryLowPrice && this.props.mode == 'departTime') {
				props.fetchCountryLowPrice(dCityCode, aCityCode, flightWay);
			}
			// 多程低价也显示在单程出发返程选择框时
			if (!thisCountryLowRPrice && this.props.mode == 'arrivalTime') {
				props.fetchCountryLowPrice(dCityCode, aCityCode, 'RT');
			}
		}
		// 返程价格
		if (!countryLowRPrice && !thisCountryLowRPrice && flightWay == 'RT')
			props.fetchCountryLowPrice(dCityCode, aCityCode, flightWay);
	}

	fetchLowRPriceHelper(props) {
		const { selectedCity, domestic } = props;
		if (selectedCity) {
			let dCityCode = props.selectedCity.getIn(['owDCity', 'Code']),
				aCityCode = props.selectedCity.getIn(['owACity', 'Code']),
				dCodeAndACode = `${dCityCode}-${aCityCode}`,
				cabin = props.cabin,
				flightWay = 'D';

			if (dCityCode && aCityCode) {
				let flightSegmentList = [
					{
						arrivalCityCode: aCityCode,
						departureCityCode: dCityCode,
						departureDate: moment().format('YYYY-MM-DD'),
					},
					{
						arrivalCityCode: dCityCode,
						departureCityCode: aCityCode,
						departureDate: moment().add(1, 'month').format('YYYY-MM-DD'),
					},
				];

				if (
					!(props.lowRPrice && props.lowRPrice.get(dCodeAndACode)
						? props.lowRPrice.get(dCodeAndACode).size
						: null)
				) {
					props.fetchLowRPrice(flightSegmentList, cabin, flightWay, domestic);
				}
			}
		}
	}

	componentDidMount() {
		setTimeout(() => {
			if (
				this.props.selectedCity &&
				this.props.selectedCity.size &&
				this.props.showLowPrice &&
				this.props.flightWay == 'OW' &&
				this.props.mode == 'departTime'
			) {
				this.fetchLowPriceHelper(this.props);
			}
			if (
				this.props.selectedCity &&
				this.props.selectedCity.size &&
				this.props.flightWay != 'MT' &&
				this.props.mode == 'departTime' &&
				this.props.showLowPrice
			) {
				this.fetchLowRPriceHelper(this.props);
			}
			if (this.props.selectedCityAndCountry && this.props.showCountryLowPrice) {
				this.fetchCountryLowPriceHelper(this.props);
			}
		}, 2000);
	}

	handleInputFocus(callback) {
		setTimeout(() => {
			if (this.picker?.current && this.picker.current.querySelector) {
				const pickerEl = this.picker.current.querySelector('input');
				if (pickerEl) {
					pickerEl.focus();
					callback && callback();
				}
			}
			// if (this.picker && this.picker.querySelector && this.picker.querySelector('input')) {
			//   this.picker.querySelector('input').focus();
			//   callback && callback();
			// }
		}, 0);
	}

	componentWillReceiveProps(nextProp) {
		if (!this.props.needFocus && nextProp.needFocus) {
			//TODO focus and clear
			this.handleInputFocus(() => {
				nextProp.clearFocusNextInput && nextProp.clearFocusNextInput();
			});
		}
		if (nextProp.selectedCity && nextProp.selectedCity.size) {
			let dCity = nextProp.selectedCity.get('owDCity'),
				aCity = nextProp.selectedCity.get('owACity'),
				currentDCity = this.props.selectedCity.get('owDCity'),
				currentACity = this.props.selectedCity.get('owACity'),
				dCityCode = dCity && dCity.get('Code'),
				aCityCode = aCity && aCity.get('Code'),
				currentACityCode = currentACity && currentACity.get('Code'),
				currentDCityCode = currentDCity && currentDCity.get('Code');

			if (nextProp.showLowPrice && nextProp.flightWay == 'OW') {
				if (
					dCity &&
					aCity &&
					(dCityCode != currentDCityCode ||
						aCityCode != currentACityCode ||
						(nextProp.flightWay == 'OW' && this.props.flightWay == 'RT') ||
						nextProp.cabin !== this.props.cabin)
				) {
					this.fetchLowPriceHelper(nextProp);
				}
			}
			if (nextProp.flightWay != 'MT' && nextProp.mode == 'departTime' && nextProp.showLowPrice) {
				if (
					(dCity && aCity && (dCityCode != currentDCityCode || aCityCode != currentACityCode)) ||
					nextProp.cabin !== this.props.cabin
				) {
					this.fetchLowRPriceHelper(nextProp);
				}
			}
		}

		let countryCode =
				this.props.selectedCityAndCountry &&
				this.props.selectedCityAndCountry.aCity &&
				this.props.selectedCityAndCountry.aCity.countryCode
					? this.props.selectedCityAndCountry.aCity.countryCode
					: '',
			countryDCityCode =
				this.props.selectedCityAndCountry &&
				this.props.selectedCityAndCountry.dCity &&
				this.props.selectedCityAndCountry.dCity.code
					? this.props.selectedCityAndCountry.dCity.code
					: '';
		if (nextProp.selectedCityAndCountry && nextProp.showCountryLowPrice) {
			if (
				nextProp.selectedCityAndCountry.aCity &&
				nextProp.selectedCityAndCountry.dCity &&
				(nextProp.flightWay != this.props.flightWay ||
					nextProp.selectedCityAndCountry.aCity.countryCode != countryCode ||
					nextProp.selectedCityAndCountry.dCity.code != countryDCityCode ||
					nextProp.selectedCityAndCountry.isOnlyDirect != this.props.selectedCityAndCountry.isOnlyDirect ||
					nextProp.fetchCountryLowPrice != this.props.fetchCountryLowPrice)
			) {
				this.fetchCountryLowPriceHelper(nextProp);
			}
		}
	}

	shouldComponentUpdate(nextProp) {
		if (
			this.props.needFocus !== nextProp.needFocus ||
			this.props.focusNextInput !== nextProp.focusNextInput ||
			this.props.date !== nextProp.date ||
			this.props.errorMsg !== nextProp.errorMsg ||
			this.props.min !== nextProp.min ||
			this.props.start !== nextProp.start ||
			this.props.end !== nextProp.end ||
			this.props.timeZone !== nextProp.timeZone ||
			this.props.lowPrice != nextProp.lowPrice ||
			this.props.countryLowPrice != nextProp.countryLowPrice ||
			this.props.countryLowRPrice != nextProp.countryLowRPrice ||
			this.props.label != nextProp.label
		) {
			return true;
		} else {
			return false;
		}
	}

	getFocus(_e) {
		this.setState({ focused: true });
		if (this.props.onInputFocus) {
			this.props.onInputFocus();
		}
	}

	loseFocus(_e) {
		this.setState({ focused: false });
		if (this.props.onInputBlur) {
			this.props.onInputBlur();
		}
	}

	getLowPriceKey() {
		const { selectedCity, cabin } = this.props;
		if (!selectedCity) {
			return '';
		}
		let dCityCode = selectedCity.getIn(['owDCity', 'Code']),
			aCityCode = selectedCity.getIn(['owACity', 'Code']);
		return getLowPriceKey({ departureCityCode: dCityCode, arrivalCityCode: aCityCode, cabinCode: cabin });
	}

	handleDateChange(dayinfo) {
		const { lowPrice, showLowPrice, lowestPrice, lowRPrice, lowestRPrice, flightWay, mode } = this.props;
		let theLowPrice = null,
			isLowestPrice = false,
			{ date, isAssist } = dayinfo,
			lowPriceKey = this.getLowPriceKey();

		if (showLowPrice && showLowPrice() && lowPrice && flightWay == 'OW' && mode == 'departTime') {
			let theExistedLowPrice = lowPrice && lowPrice.get(lowPriceKey) ? lowPrice.get(lowPriceKey) : null;
			if (theExistedLowPrice) {
				let dateStr = moment(date).format('YYYY-MM-DD');

				theLowPrice = theExistedLowPrice.get(dateStr);
				isLowestPrice =
					lowestPrice &&
					lowestPrice.get(lowPriceKey) &&
					lowestPrice.getIn([lowPriceKey, moment(date).format('MM')]) == theLowPrice;
			}
		}

		if (showLowPrice && showLowPrice() && lowRPrice && flightWay != 'MT' && mode == 'arrivalTime') {
			let theExistedLowPrice = lowRPrice && lowRPrice.get(lowPriceKey) ? lowRPrice.get(lowPriceKey) : null;

			if (theExistedLowPrice) {
				let departDateStr = moment(this.props.start).format('YYYY-MM-DD');
				let dateStr = moment(date).format('YYYY-MM-DD');
				theLowPrice = theExistedLowPrice.get(`${departDateStr}-${dateStr}`);
				isLowestPrice =
					lowestRPrice &&
					lowestRPrice.get(lowPriceKey) &&
					lowestRPrice.getIn([lowPriceKey, `${departDateStr}-${moment(date).format('MM')}`]) == theLowPrice;
			}
		}
		// 非 无障碍模式
		if (!isAssist) {
			this.loseFocus();
		}

		this.props.onDateChange(dayinfo, this.props.focusNextInput, theLowPrice, isLowestPrice);
		if (this.props.handleEnter) {
			this.props.handleEnter();
		}
	}

	getDayTop(dayinfo) {
		const { flightWay, mutilTripNumber, workingDays } = this.props;
		// if (!((lowPrice&&flightWay=='OW'&&mode=='departTime')||(lowRPrice&&flightWay!='MT'&&mode=='arrivalTime')))
		//     return
		let dateTop = null,
			otherfest = null,
			holiday = null,
			{ date, salarfest, lunarfest } = dayinfo;
		let workingInfoDept = workingDays && workingDays.get(moment(date).format('YYYY-MM-DD'));
		if (workingInfoDept && workingInfoDept.get('stat') === 3) {
			otherfest = (
				<span key="2" className="date-holiday">
					休
				</span>
			);
		} else if (workingInfoDept && workingInfoDept.get('stat') === 4) {
			otherfest = (
				<span key="2" className="date-fest">
					班
				</span>
			);
		}
		let qingming = workingInfoDept && workingInfoDept.get('holidayName') == '清明节' ? '清明节' : '';

		if (qingming && isQingMing(moment(date))) {
			salarfest = qingming;
		}
		holiday = (salarfest || ('' + lunarfest ? lunarfest : '')) ? (
			<span key="2" className="date-holiday">
				{salarfest || ('' + lunarfest ? lunarfest : '')}
			</span>
		) : (
			otherfest
		);
		switch (flightWay) {
			case 'OW':
				if(holiday) {
					dateTop = holiday;
				} else if (
					date.getFullYear() == new Date().getFullYear() &&
					date.getMonth() == new Date().getMonth() &&
					date.getDate() == new Date().getDate()
				) {
					dateTop = (
						<span key="5" className="date-fest">
							今天
						</span>
					);
				} 
				break;
			case 'RT':
				// 日期头部优先级，去程/返程-今天-节日
				if (dayinfo.start && dayinfo.end && dayinfo.start == dayinfo.end) {
					dateTop = (
						<span className="date-fest" key="4">
							去/返
						</span>
					);
				} else if (dayinfo.start) {
					dateTop = (
						<span className="date-fest" key="4">
							去程
						</span>
					);
				} else if (dayinfo.end) {
					dateTop = (
						<span className="date-fest" key="4">
							返程
						</span>
					);
				} else if (holiday) {
					dateTop = holiday;
				} else if (
					date.getFullYear() == new Date().getFullYear() &&
					date.getMonth() == new Date().getMonth() &&
					date.getDate() == new Date().getDate()
				) {
					dateTop = (
						<span key="5" className="date-fest">
							今天
						</span>
					);
				} 
				break;
			case 'MT':
				if (dayinfo.start && dayinfo.end && dayinfo.start == dayinfo.end) {
					dateTop = (
						<span className="date-fest" key="4">
							第{mutilTripNumber}/{mutilTripNumber + 1}程
						</span>
					);
				} else if (dayinfo.start) {
					dateTop = (
						<span className="date-fest" key="4">
							第{mutilTripNumber}程
						</span>
					);
				} else if (dayinfo.end) {
					dateTop = (
						<span className="date-fest" key="4">
							第{mutilTripNumber + 1}程
						</span>
					);
				} else if (holiday) {
					dateTop = holiday;
				} else if (
					date.getFullYear() == new Date().getFullYear() &&
					date.getMonth() == new Date().getMonth() &&
					date.getDate() == new Date().getDate()
				) {
					dateTop = (
						<span key="5" className="date-fest">
							今天
						</span>
					);
				}
		}

		return dateTop;
	}

	isLowCabin() {
		const { cabin } = this.props;
		return /(Y|S|Y_S_C_F)_?(Y|S)?$/.test(cabin);
	}

	getPriceDom(dayinfo) {
		const {
			lowPrice,
			showLowPrice,
			selectedCityAndCountry,
			lowestPrice,
			lowRPrice,
			lowestRPrice,
			flightWay,
			mode,
			countryLowPrice,
			countryLowestPrice,
			countryLowestRPrice,
			countryLowRPrice,
			start,
		} = this.props;
		// if (!((lowPrice&&flightWay=='OW'&&mode=='departTime')||(lowRPrice&&flightWay!='MT'&&mode=='arrivalTime')))
		//     return
		let theLowPrice = null,
			thePriceTag = null,
			isLowestPrice = false,
			priceDoms = null,
			dayDom = null,
			dateTop = null,
			resPrice = null,
			{ date } = dayinfo,
			lowPriceKey = this.getLowPriceKey();

		if (showLowPrice && showLowPrice() && lowPrice && flightWay == 'OW' && mode == 'departTime') {
			let theExistedLowPrice = lowPrice && lowPrice.get(lowPriceKey) ? lowPrice.get(lowPriceKey) : null;
			if (theExistedLowPrice) {
				let dateStr = moment(date).format('YYYY-MM-DD');

				theLowPrice = theExistedLowPrice.get(dateStr);
				thePriceTag = theLowPrice ? <dfn>¥</dfn> : null;
				isLowestPrice =
					lowestPrice &&
					lowestPrice.get(lowPriceKey) &&
					lowestPrice.getIn([lowPriceKey, moment(date).format('MM')]) == theLowPrice;
				let addonData = lowPrice.get(lowPriceKey);
				let resstr = addonData.get(dateStr);
				let priceClass = cls({
					price: true,
					'lowest-price': isLowestPrice,
				});
				if (resstr) {
					resPrice = resstr;
					priceDoms = (
						<span className={priceClass} key={shortid.generate()}>
							{thePriceTag}
							{resstr}
						</span>
					);
				}
				// return priceDoms
			}
		}

		if (showLowPrice && showLowPrice() && lowRPrice && flightWay != 'MT' && mode == 'arrivalTime') {
			let theExistedLowPrice = lowRPrice && lowRPrice.get(lowPriceKey) ? lowRPrice.get(lowPriceKey) : null;

			if (theExistedLowPrice) {
				let departDateStr = moment(this.props.start).format('YYYY-MM-DD');
				let dateStr = moment(date).format('YYYY-MM-DD');
				theLowPrice = theExistedLowPrice.get(`${departDateStr}-${dateStr}`);
				thePriceTag = theLowPrice ? <dfn>¥</dfn> : null;
				isLowestPrice =
					lowestRPrice &&
					lowestRPrice.get(lowPriceKey) &&
					lowestRPrice.getIn([lowPriceKey, `${departDateStr}-${moment(date).format('MM')}`]) == theLowPrice;
				let priceClass = cls({
					price: true,
					'lowest-price': isLowestPrice,
				});
				if (theLowPrice) {
					resPrice = theLowPrice;
					priceDoms = (
						<span className={priceClass} key={shortid.generate()}>
							{thePriceTag}
							{theLowPrice}
						</span>
					);
				}
				// return priceDoms
			}
		}

		let dCityCode =
				selectedCityAndCountry && selectedCityAndCountry.dCity && selectedCityAndCountry.dCity.code
					? selectedCityAndCountry.dCity.code
					: null,
			aCountryCode =
				selectedCityAndCountry && selectedCityAndCountry.aCity && selectedCityAndCountry.aCity.countryCode
					? selectedCityAndCountry.aCity.countryCode
					: null;
		if (
			dCityCode &&
			aCountryCode &&
			countryLowPrice &&
			flightWay == 'OW' &&
			mode == 'departTime' &&
			this.isLowCabin()
		) {
			//lowprice,lowestPrice {SHA-JP:{20180-11-11:680}}

			let departDateStr = moment(date).format('YYYY-MM-DD'),
				departMonth = moment(date).format('MM'),
				theLowPrice = countryLowPrice[`${dCityCode}-${aCountryCode}`],
				theExistedLowPrice = theLowPrice && theLowPrice[departDateStr] ? theLowPrice[departDateStr] : null,
				thePriceTag = theExistedLowPrice ? <dfn>¥</dfn> : null,
				theLowestPrice = countryLowestPrice[`${dCityCode}-${aCountryCode}`],
				isLowestPrice =
					(theLowestPrice && theLowestPrice[departMonth] ? theLowestPrice[departMonth] : null) ==
					theExistedLowPrice;
			let priceClass = cls({
				price: true,
				'lowest-price': isLowestPrice,
			});
			if (theLowPrice) {
				resPrice = theExistedLowPrice;

				priceDoms = (
					<span className={priceClass} key={shortid.generate()}>
						{thePriceTag}
						{theExistedLowPrice}
					</span>
				);
			}
		}

		if (dCityCode && aCountryCode && countryLowRPrice && mode == 'arrivalTime') {
			// {2018-10-11(开始时间):{2019-10-22(返回日期): 1908(往返总价)}}
			// lowestPrice{2018-10-11(开始时间):{'04(返程月份)': 900(最低价)}}

			let aDateStr = moment(date).format('YYYY-MM-DD'),
				dDate = moment(start).format('YYYY-MM-DD'),
				departMonth = moment(date).format('MM');
			if (!countryLowRPrice[`${dCityCode}-${aCountryCode}`]) {
				return [dateTop, dayDom, priceDoms];
			}
			let theLowPrice = countryLowRPrice[`${dCityCode}-${aCountryCode}`][dDate] || null;
			let theExistedLowPrice = '';
			if (theLowPrice && theLowPrice[aDateStr]) {
				theExistedLowPrice = theLowPrice[aDateStr];
			}
			let theLowestPrice = countryLowestRPrice[`${dCityCode}-${aCountryCode}`][dDate],
				thePriceTag = theExistedLowPrice ? <dfn>¥</dfn> : null,
				isLowestPrice =
					(theLowestPrice && theLowestPrice[departMonth] ? theLowestPrice[departMonth] : null) ==
					theExistedLowPrice;
			let priceClass = cls({
				price: true,
				'lowest-price': isLowestPrice,
			});
			if (theLowPrice) {
				resPrice = theExistedLowPrice;

				priceDoms = (
					<span className={priceClass} key={shortid.generate()}>
						{thePriceTag}
						{theExistedLowPrice}
					</span>
				);
			}
		}
		return {
			priceDoms,
			resPrice,
		};
	}

	renderDay(dayinfo) {
		let priceDoms = null,
			dayDom = null,
			dateTop = null,
			{ date } = dayinfo;

		dayDom = (
			<span key="1" className="date-d">
				{date.getDate()}
			</span>
		);
		dateTop = this.getDayTop(dayinfo);
		priceDoms = this.getPriceDom(dayinfo).priceDoms;

		return [dateTop, dayDom, priceDoms];
	}

	renderDayV2(dayinfo) {
		let { flightWay, mode, start, multiSelectedDates, multiSelectedDateIndex } = this.props;
		let dayDom = null,
			dateTop = null,
			priceDom = this.getPriceDom(dayinfo),
			{ date } = dayinfo,
			priceDoms = null;

		dayDom = (
			<span key="1" className="date-d">
				{date.getDate()}
			</span>
		);
		dateTop = this.getDayTop(dayinfo);
		priceDoms = priceDom.priceDoms;

		let toolTip = '';
		if (flightWay == 'OW' || flightWay == 'RT') {
			let startStr = start && moment(start).format('MM-DD');
			if (mode == 'departTime' && flightWay == 'RT') {
				toolTip = (
					<div key={6} style={{ width: '48px', marginLeft: '-30px' }} className="date-tool-tip">
						<span className="tool-tip-arrow"></span>
						<div>选去程</div>
					</div>
				);
			} else if (mode == 'arrivalTime') {
				let dDate = moment(start),
					aDate = moment(date);
				toolTip = (
					<div key={6} className="date-tool-tip" style={{ width: '100px', marginLeft: '-54px' }}>
						<span className="tool-tip-arrow"></span>
						<div>
							{startStr}去, {moment(date).format('MM-DD')}返
						</div>
						<CalendarRoundTripDays dDate={dDate} aDate={aDate} />
					</div>
				);
			}
		} else if (flightWay == 'MT') {
			multiSelectedDates &&
				multiSelectedDates.forEach((multiDate, i) => {
					let eDate = moment(date);
					if (
						multiDate &&
						eDate.format('YYYY') == multiDate.format('YYYY') &&
						eDate.format('MM') == multiDate.format('MM') &&
						eDate.format('DD') == multiDate.format('DD')
					) {
						dateTop = (
							<span key="5" className="date-fest">
								第{i + 1}程
							</span>
						);
					}
				});

			toolTip = (
				<div key={6} style={{ width: '48px', marginLeft: '-30px' }} className="date-tool-tip">
					<span className="tool-tip-arrow"></span>
					<div>选第{multiSelectedDateIndex + 1}程</div>
				</div>
			);
		}
		return [dateTop, dayDom, priceDoms, toolTip];
	}

	render() {
		const {
			date,
			label,
			mode,
			errorMsg,
			min,
			max,
			timeZone,
			flightWay,
			width,
			errorClass,
			start,
			end,
			showWeek,
			title,
			footerTips,
			onNextMomthClick,
			onPrevMomthClick,
			outsideClassName,
			mutilTripNumber,
			dateTips,
		} = this.props;

		let displayWeek = '';
		if (!date) {
			displayWeek = '';
		} else {
			displayWeek = formatWeek(date) || '';
		}
		let minDate = addTimezone(min || moment(), timeZone);
		if (
			moment(moment().format('YYYY-MM-DD')).subtract(2, 'days').format('YYYY-MM-DD') ===
			minDate.format('YYYY-MM-DD')
		) {
			// 不让选择今天之前两天的日期
			minDate = min;
		}
		return (
			<div
				u_key="date_picker"
				u_remark={`日期选择框[flightWay:${flightWay},${
					typeof mutilTripNumber !== 'undefined' ? 'mutilTripNumber:' + mutilTripNumber + ',' : ''
				}date:${date ? date.format('YYYY-MM-DD') : ''},mode:${mode}]`}
				className={cls(
					'modifyDate',
					errorClass,
					{
						'depart-date': mode === 'departTime',
						'return-date': mode !== 'departTime',
					},
					outsideClassName
				)}
				style={{ display: 'inline-block', width: width || '100%' }}
				ref={this.picker}
			>
				<div>
					<DatePicker
						min={minDate.toDate()}
						max={max && addTimezone(max, timeZone).toDate()}
						start={start}
						end={end}
						autoHide={true}
						selected={date && date.toDate()}
						placeholder={label}
						months={2}
						isfill
						lang="cn"
						name={mode}
						onFocus={this.getFocus}
						onBlur={this.loseFocus}
						onChange={this.handleDateChange}
						renderDay={
							this.props.skin && this.props.skin == 'date-custom-v2' ? this.renderDayV2 : this.renderDay
						}
						festival={false}
						title={title}
						footerTips={footerTips}
						onNextMomthClick={onNextMomthClick}
						onPrevMomthClick={onPrevMomthClick}
						offset={this.props.offset || { top: 14 }}
						widthWarp={true}
						inline={this.props.inline}
						calendarAddon={this.props.calendarAddon}
						mode={this.props.calendarMode}
						onCalendarHide={this.props.onCalendarHide}
						skin={this.props.skin}
						multiSelectedDates={this.props.multiSelectedDates}
						multiSelectedDateIndex={this.props.multiSelectedDateIndex}
						showRangeOnMouseEnter={this.props.showRangeOnMouseEnter}
						readOnly={this.props.readOnly}
						positionEle={this.props.positionEle}
						showAnimateClass={this.props.showAnimateClass}
						hideAnimateClass={this.props.hideAnimateClass}
						showCalendar={this.props.showCalendar}
					>
						<input
							className={`form-input-v2 ${flightWay == 'RT' ? 'return' : 'depart'}-date`}
							aria-label={label}
							placeholder={label}
						/>
						{errorMsg ? <div className="form-error-msg">{errorMsg}</div> : <div></div>}
					</DatePicker>
					<span style={{ display: showWeek === false ? 'none' : 'inline-block' }} className="week">
						{displayWeek}
					</span>
					{dateTips}
				</div>
			</div>
			//  </UbtBoundary>
		);
	}
}

export default ModifyDateInfoV2;
