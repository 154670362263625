import mergeConfig from '../../../constants/common/mergeConfig'
import Q_CONFIG_KEYS from '../../../constants/list/qConfigKeys'

const CONSTS = {
    TRANSITPU_CONFIRM: {
        MAIN_TITLE: '请认真阅读以下信息',
        SUB_TITLE: '您选择了多票组合产品，关于航班中转，您需要了解以下信息：',
        VISA_CAPTION: '1.备齐过境类签证',
        VISA_HEADER: `确保备齐换乘地过境类签证`,
        VISA_CONTENT: `在换乘地<span className='highlight'>“[CITY_LIST]”</span>需重新出入关，请注意查询相关过境地签证政策，确保备齐所需签证后再预订。`,
        BOARDING_CAPTION: '2.重新办理登机',
        BOARDING_HEADER: `换乘需重新办理登机手续`,
        BORADING_CONTENT: `多票组合产品为多个独立航班，在换乘地<span className='highlight'>“[CITY_LIST]”</span>需重新办理登机手续，请合理安排换乘时间。因乘客不合理安排换乘时间、证件不齐等原因导致无法登机，需乘机人自行承担责任。`,
        LUGGAGE_CAPTION: '3.重新托运行李',
        LUGGAGE_HEADER: `在换乘地需重新托运行李`,
        LUGGAGE_CONTENT: `在换乘地<span className='highlight'>“[CITY_LIST]”</span>需提取并重新托运行李，不同航班行李额（重量/数量）可能有差异，预订前请仔细查看行李规定。`,
        TW_VISA_TIP: '（自2019年8月1日起，中国大陆公民台湾自由行个人旅游签注暂停办理）'
    },
    SUPER_FLYER_CONFIRM: {
        INFO: '购票前请先到值机柜台确认出票后仍有时间值机(支付成功后需5分钟完成出票)。',
        HINT: '若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。',
        CONFIRM_BUTTON: '已确认可值机，去预定',
        CANCEL_BUTTON: '重新选择航班',
    },
    D_SUPER_FLYER_CONFIRM: {
        INFO: '<p>1.购票前请先到值机柜台确认出票后仍有时间值机（支付成功后需5分钟完成出票）。</p><p>2.若出票失败，订单自动取消并全额退款，若已出票，退改损失需自行承担。</p>',
        HINT: null,
        CONFIRM_BUTTON: '已确认可值机，去预订',
        CANCEL_BUTTON: '重新选择航班',
        TITLE: '请务必确认'
    },
    FLIGHT_COMFORT: {
        CHEAP_CABIN: '廉航机票通常不提供免费餐食及免费托运行李额，且根据航空公司规定不能退改签或退改收费较高。'
    }
}

export default mergeConfig(Q_CONFIG_KEYS.POPUP, CONSTS)
