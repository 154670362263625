import Enum from 'enum'
/**
 * 公告级别
 */
export const NOTICE_LEVEL = new Enum({
    FIRSTCLASS: {
        key: 1,
        divClass: "notice-board lv1",
        canClose: false,
        noticeWord: "紧急公告："
    },
    SECONDCLASS: {
        key: 2,
        divClass: "notice-board lv2",
        canClose: false,
        noticeWord: "重要公告："
    },
    THIRDCLASS: {
        key: 3,
        divClass: "notice-board lv3",
        canClose: true,
        noticeWord: ""
    }
})