import React from 'react';
import ReactPortalToolTip from '@ctrip/flight-online-tooltips';
import ErrorBoundary from '@/src/components/Base/ErrorBoundary';
import { UbtHandler } from '../../ubt.v2/handler';
import { TOOLTIP_MARGIN } from '../../constants/list/enums/tooltip';


const HOVERPOSITION = {
	LEFT: 'left',
	RIGHT: 'right',
	TOP: 'top',
	BOTTOM: 'bottom',
};
// 参数说明：
// toolTipContent: hover显示的内容
// parentId: 与需要hover的地方的Id保持一致
// display: 需要hover的地方显示的样式, 可以写为'block'或者'inline', 默认'inline'
// delayTime: 组件延迟出现的毫秒数
// - delayTime 展示延迟
// - callbackFunc tooltips展示回调
// - showWhenMouted 初始渲染直接展示
class ReactToolTip extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			show: false,
			position: HOVERPOSITION.BOTTOM,
			marginTop: null,
			forceLeft: null,
		};
		this.toggleTooltip = this.toggleTooltip.bind(this);
		this.appearToolTip = this.appearToolTip.bind(this);
		this.disappearToolTip = this.disappearToolTip.bind(this);
		this.delay = null;
		this.isMout = false;
	}

	toggleTooltip(visibility) {
		this.isMout &&
			this.setState({
				show: visibility,
			});
		if (visibility) {
			//埋点相关
			// if (this.props.ubtKey) {
			//     ubt.onCommonHover(this.props.ubtKey)
			// }
			const { parentId, group } = this.props;
			let toolTipPortal = document.getElementById(`${group || 'main'}-${parentId}`), //document.getElementsByClassName('ToolTipPortal')[0],
				parent = document.getElementById(`${this.props.parentId}`);
			if (toolTipPortal && parent) {
				let toolTipRect = parent.getBoundingClientRect(),
					toolTipTop = toolTipRect.top + parent.offsetHeight,
					contentHeight = toolTipPortal.offsetHeight,
					bodyHeight = document.documentElement.clientHeight;

				if (bodyHeight - toolTipTop < contentHeight + 15 && toolTipTop > contentHeight) {
					this.isMout &&
						this.setState({
							position: HOVERPOSITION.TOP,
							marginTop: null,
							forceLeft: null,
						});
				} else {
					this.isMout &&
						this.setState({
							position: HOVERPOSITION.BOTTOM,
							marginTop: null,
							forceLeft: null,
						});
				}
			}
		}
	}

	appearToolTip() {
		let delayTime = this.props.delayTime ? this.props.delayTime : 100;
		this.delay = setTimeout(() => {
			this.toggleTooltip(true);
		}, delayTime);
	}

	disappearToolTip() {
		this.toggleTooltip(false);
		clearTimeout(this.delay);
	}

	contentElWheel(ev) {
		ev.stopPropagation();
	}

	componentDidMount() {
		this.isMout = true;
		let _this = this,
			id = this.props.parentId || '',
			el = id && document.getElementById(id);
		if (el) {
			el.addEventListener(
				'mouseenter',
				(_e) => {
					_this.appearToolTip();
					if (_this.props.callbackFunc) _this.props.callbackFunc();
				},
				false
			);
			el.addEventListener(
				'mouseleave',
				(_e) => {
					_this.disappearToolTip();
				},
				false
			);
		}
		let contentEl = document.getElementById('empty-tool-tip-inline-div-' + id);
		if (contentEl) {
			contentEl.addEventListener('wheel', this.contentElWheel);
		}
		if (this.props.showWhenMouted) {
			_this.appearToolTip();
		}
	}

	componentDidUpdate() {
		let contentEl = document.getElementById('empty-tool-tip-inline-div-' + (this.props.parentId || ''));
		if (contentEl) {
			contentEl.removeEventListener('wheel', this.contentElWheel);
			contentEl.addEventListener('wheel', this.contentElWheel);
		}
	}

	componentWillUnmount() {
		this.isMout = false;
		let _this = this,
			id = this.props.parentId || '',
			el = id && document.getElementById(id);

		if (el) {
			el.removeEventListener(
				'mouseenter',
				function (_e) {
					_this.appearToolTip();
				},
				true
			);
			el.removeEventListener(
				'mouseleave',
				function (_e) {
					_this.disappearToolTip();
				},
				true
			);
		}
		clearTimeout(this.delay);
		let contentEl = document.getElementById('empty-tool-tip-inline-div-' + id);
		if (contentEl) {
			contentEl.removeEventListener('wheel', this.contentElWheel);
		}
	}

	render() {
		const {
			toolTipContent,
			parentId,
			display,
			group,
			position,
			align,
			forceLeft,
			forceRight,
			stopWheelPropagation,
			container,
			traceKey,
			traceData,
		} = this.props;
		const style = {
			display: display || 'inline',
		};

		let hoverPosition = position || this.state.position,
			margin = TOOLTIP_MARGIN[hoverPosition].value;

		let toolTipStyle = {
			style: {
				margin,
				padding: 10,
				paddingTop: 10,
				marginTop: this.state.marginTop || margin.split(' ')[0],
				paddingBottom: 10,
				paddingLeft: 5,
				paddingRight: 5,
				transition: 'none',
				zIndex: 9999,
				background: 'transparent',
				boxShadow: 'none',
				forceLeft: this.state.forceLeft || forceLeft,
				forceRight,
			},
			arrowStyle: {
				borderColor: false,
				boxShadow: 'none',
			},
		};

		if (this.state.show && traceKey && traceData) {
			UbtHandler.onUploadData(traceKey, traceData);
		}

		return (
			<ErrorBoundary>
				<div style={style} className="empty-tool-tip-inline">
					<ReactPortalToolTip
						active={this.state.show}
						position={hoverPosition}
						align={align}
						useHover={true}
						group={group || 'main'}
						parent={`#${parentId}`}
						tooltipTimeout={0}
						style={toolTipStyle}
						container={container}
					>
						<div id={`${group || 'main'}-${parentId}`}>
							{stopWheelPropagation ? (
								<div id={`empty-tool-tip-inline-div-${parentId || ''}`}>{toolTipContent}</div>
							) : (
								toolTipContent
							)}
						</div>
					</ReactPortalToolTip>
				</div>
			</ErrorBoundary>
		);
	}
}

// list页面根容器.main 。 因为nextjs渲染时全局Provider不在最外层。
export const withContainer = (selector) => (props) =>
{
	const container = window.document.querySelector(selector);
	if (!container) return null;
	return <ReactToolTip {...{ ...props, container }} />;
}

export default withContainer('.main');
