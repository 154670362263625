import CONFIG_URL from '../../constants/list/api'
import { fetchGet } from '../common'

// 获取用户星级

export const fetchUserStar = (callback) =>  {
    return fetchGet(`${CONFIG_URL.getUserStarInfo}`)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data === undefined) {
                json.data = 0
            }
            callback(json.data)
        })
        .catch(() => { callback(0) })
}
