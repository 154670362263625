import moment from 'moment';
import { DomesticCityCheck } from '../../../../sources/channel/DomesticCityProvider';
import { FILTER_CLASS_GRADE_TYPE } from '../../../../constants/common/enum/filter';
import { LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { addClickRec } from '@/src/ubt.v2/addClickRec';

//导出一些共用的方法
export const popNextFormItem = (el, elForm, formItemCls = 'form-item-v2') => {
	if (elForm) {
		let elFormItem = elForm.getElementsByClassName(formItemCls);
		let i,
			len = elFormItem.length,
			elInput;
		for (i = 0; i < len; i++) {
			elInput = elFormItem[i].querySelector('input');
			if (elInput && el.name == elInput.name) break;
		}
		do {
			i = i + 1;
			elInput = elFormItem[i] && elFormItem[i].querySelector('input');
		} while (elFormItem[i] && (!elInput || elInput.type !== 'text'));
		if (elInput && !elInput.value) elInput.focus();
		return false;
	}
	return true;
};

//城市
export const getCityText = (selectedCity, name) => {
	let city = selectedCity.get(name);
	if (city) {
		return !city.isEmpty()
			? city.get('text')
				? city.get('text')
				: `${city.get('Name')}(${city.get('Code')})`
			: '';
	} else {
		return '';
	}
};

//change date
export const onDateChange =
	(
		dateField,
		changeDate
		// _hasLowprice,
		// _isLowestPrice,
	) =>
	(date, focusNextInput, hasLowprice, isLowestPrice) => {
		const momentDate = moment(date.date);
		changeDate(dateField, momentDate, focusNextInput);
		LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_DATE_CAHNGE.value.process();
		if (hasLowprice && isLowestPrice) {
			LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_DATE_CAHNGE.value.process();
			LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_ON_LP_DATE_SELECT.value.process();
		}
		LIST_UBT_KEY_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.process(dateField);

		addClickRec('click_date', `选择日期[name:${dateField},date:${momentDate.format('YYYY-MM-DD')}]`);
	};

//获取城市
export const getSearchCityText = (seg, field, showCityText) => {
	let hideFourCode = (code) => {
		if (code.length) return code.length >= 4 ? '' : `(${code})`;
		return `(${code})`;
	};

	if ((seg.get(`${field}TrainStationCode`) || seg.get(`${field}TrainStationName`)) && !showCityText) {
		return {
			text: `${seg.get(`${field}TrainStationName`)}${hideFourCode(seg.get(`${field}CityCode`))}`,
			code: `${seg.get(`${field}TrainStationCode`)}1`,
			name: `${seg.get(`${field}TrainStationName`)}`,
		};
	}
	if ((seg.get(`${field}AirportCode`) || seg.get(`${field}AirportName`)) && !showCityText) {
		return {
			text: `${seg.get(`${field}CityName`)}(${seg.get(`${field}AirportName`)})${hideFourCode(
				seg.get(`${field}AirportCode`)
			)}`,
			code: `${seg.get(`${field}AirportCode`)}0`,
			name: `${seg.get(`${field}CityName`)}`,
			isAirport: true,
			ParentCode: `${seg.get(`${field}CityCode`)}`,
		};
	}
	return {
		text: `${seg.get(`${field}CityName`)}${hideFourCode(seg.get(`${field}CityCode`))}`,
		code: `${seg.get(`${field}CityCode`)}`,
		name: `${seg.get(`${field}CityName`)}`,
	};
};

//获取用户搜索信息
let cache = {};
export const getModifyTopInfo = (prevCond, showCityText) => {
	// if (cache["searchResult"] && !showCityText) {
	//     return cache["searchResult"]
	// }

	let getSearchInfo = (flightWay, prevCond) => {
		if (flightWay !== 'MT') {
			let firstSegment = prevCond.getIn(['flightSegments', 0]),
				secondSegment = prevCond.getIn(['flightSegments', 1]),
				depInfo = getSearchCityText(firstSegment, 'departure', showCityText),
				arrInfo = getSearchCityText(firstSegment, 'arrival', showCityText);
			return {
				departureText: depInfo.text,
				dCityCode: depInfo.code,
				dCityName: depInfo.name,
				dCityTimeZone: firstSegment.get('departureCityTimeZone'),
				dParentCode: depInfo.ParentCode ? depInfo.ParentCode : '',
				isDAirport: depInfo.isAirport ? depInfo.isAirport : false,
				arrivalText: arrInfo.text,
				aCityCode: arrInfo.code,
				aCityName: arrInfo.name,
				aParentCode: arrInfo.ParentCode ? arrInfo.ParentCode : '',
				isAAirport: arrInfo.isAirport ? arrInfo.isAirport : false,
				aCityTimeZone: firstSegment.get('arrivalCityTimeZone'),
				departureDate: firstSegment.get('departureDate'),
				departureCityId: firstSegment.get('departureCityId'),
				arrivalCityId: firstSegment.get('arrivalCityId'),
				arrivalDate: secondSegment ? secondSegment.get('departureDate') : '',
				dIsDomestic: DomesticCityCheck.ByCountryNameAndProvinceId(
					firstSegment.get('departureCountryName'),
					firstSegment.get('departureProvinceId')
				),
				aIsDomestic: DomesticCityCheck.ByCountryNameAndProvinceId(
					firstSegment.get('arrivalCountryName'),
					firstSegment.get('arrivalProvinceId')
				),
				adultCount: prevCond.get('adultCount'),
				childCount: prevCond.get('childCount'),
				infantCount: prevCond.get('infantCount'),
				cabin: FILTER_CLASS_GRADE_TYPE[prevCond.get('cabin')].value.title,
				classGrade: prevCond.get('cabin'),
			};
		} else {
			let mtInfoArray = [];
			prevCond.get('flightSegments').forEach((seg) => {
				let mtDepInfo = getSearchCityText(seg, 'departure', showCityText),
					mtArrInfo = getSearchCityText(seg, 'arrival', showCityText);
				mtInfoArray.push({
					departureText: mtDepInfo.text,
					dCityCode: mtDepInfo.code,
					dCityName: mtDepInfo.name,
					dCityTimeZone: seg.get('departureCityTimeZone'),
					aCityTimeZone: seg.get('arrivalCityTimeZone'),
					departureCityId: seg.get('departureCityId'),
					arrivalCityId: seg.get('arrivalCityId'),
					arrivalText: mtArrInfo.text,
					aCityCode: mtArrInfo.code,
					aCityName: mtArrInfo.name,
					departureDate: seg.get('departureDate'),
					dIsDomestic: DomesticCityCheck.ByCountryNameAndProvinceId(
						seg.get('departureCountryName'),
						seg.get('departureProvinceId')
					),
					aIsDomestic: DomesticCityCheck.ByCountryNameAndProvinceId(
						seg.get('arrivalCountryName'),
						seg.get('arrivalProvinceId')
					),
				});
			});

			return {
				mtInfoArray: mtInfoArray,
				adultCount: prevCond.get('adultCount'),
				childCount: prevCond.get('childCount'),
				infantCount: prevCond.get('infantCount'),
				cabin: FILTER_CLASS_GRADE_TYPE[prevCond.get('cabin')].value.title,
				classGrade: prevCond.get('cabin'),
			};
		}
	};

	let flightWay = prevCond.get('flightWayEnum').key,
		searchInfo = getSearchInfo(flightWay, prevCond);

	//cache result
	cache['searchResult'] = {
		flightWay,
		...searchInfo,
	};
	return {
		flightWay,
		...searchInfo,
	};
};

// 获取搜索是否是  3程及以上多程 并且 国内+国际
// export const isMultiPUDomesticInternational = () => {
// 	let hasDomestic = false,
// 		hasInternational = false;
// 	const flightSegments = window?.GlobalSearchCriteria?.flightSegments || [];
// 	if (flightSegments?.length > 2) {
// 		flightSegments?.forEach((segment) => {
// 			const { departureCountryId, arrivalCountryId, departureProvinceId, arrivalProvinceId } = segment || {};
// 			if (!(hasDomestic && hasInternational)) {
// 				if (
// 					departureCountryId !== 1 ||
// 					(departureCountryId === 1 &&
// 						(departureProvinceId === 33 || departureProvinceId === 32 || departureProvinceId === 53)) ||
// 					arrivalCountryId !== 1 ||
// 					(arrivalCountryId === 1 &&
// 						(arrivalProvinceId === 33 || arrivalProvinceId === 32 || arrivalProvinceId === 53))
// 				) {
// 					hasInternational = true;
// 				}
// 				if (
// 					departureCountryId === 1 &&
// 					departureProvinceId !== 33 &&
// 					departureProvinceId !== 32 &&
// 					departureProvinceId !== 53 &&
// 					arrivalCountryId === 1 &&
// 					arrivalProvinceId !== 33 &&
// 					arrivalProvinceId !== 32 &&
// 					arrivalProvinceId !== 53
// 				) {
// 					hasDomestic = true;
// 				}
// 			}
// 		});
// 	}
// 	return hasDomestic && hasInternational;
// };
