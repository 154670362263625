import React from 'react';
import { connect } from 'react-redux';

import Poi from '../poi/async';

class City extends React.Component {
	constructor() {
		super();
		this.state = {
			isFocused: false,
		};

		this.onFocus = this.onFocus.bind(this);
		this.onBlur = this.onBlur.bind(this);
		this.onChangePoi = this.onChangePoi.bind(this);
	}

	onFocus() {
		if (this.props.onInputFocus) {
			this.props.onInputFocus();
		}
		this.setState({ isFocused: true });
	}
	onBlur() {
		if (this.props.onInputBlur) {
			this.props.onInputBlur();
		}
		this.setState({ isFocused: false });
	}
	onSelectDistrictError(cityName) {
		if (this.props.onDistrictError) {
			const areaName = this.props.arrivalDistrict.areaName;
			this.props.onDistrictError(
				`暂不支持${cityName.substr(0, cityName.indexOf('('))}飞往${areaName},请更换出发地或者到达地`,
				' '
			);
		}
	}
	onRemoveDistrictError() {
		this.props.onDistrictError('', '');
	}
	onChangePoi(name, item) {
		this.props.changePoi(name, item, this.props.id, this.props.focusNextInput);
	}
	render() {
		const { label, name, mode, cityText, errorMsg, warnMsg, flightWay } = this.props;

		const citySelectorClassName = 'cflt-city-picker-selector-v2',
			poiClassName = 'cflt-poi-selector-v2',
			airPortTag = '邻近机场：';

		return (
			<Poi
				{...this.props}
				mode={mode || 'D'}
				name={name}
				cityText={cityText}
				changePoi={this.onChangePoi}
				currentFlightWay={flightWay}
				errorMsg={errorMsg}
				warnMsg={warnMsg}
				label={label}
				onFocus={this.onFocus}
				onBlur={this.onBlur}
				onRemoveDistrictError={this.onRemoveDistrictError.bind(this)}
				onSelectDistrictError={this.onSelectDistrictError.bind(this)}
				citySelectorClassName={citySelectorClassName}
				poiClassName={poiClassName}
				airPortTag={airPortTag}
				activeDomesticCityPanelTab={this.props.domestic}
			/>
		);
	}
}
const mapStateToProps = (state) => {
	const domesticCityData =
		state.getIn(['channel', 'search', 'domesticCityData']) ||
		(state && state.getIn(['list', 'search', 'domesticCityData']));
	return {
		// 国内城市面板数据
		domesticCityData: domesticCityData,
		showDomesticCity: true,
	};
};

export default connect(mapStateToProps, null)(City);
