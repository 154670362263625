import Enum from 'enum';
import React from 'react';
import Tooltip from '../../../components/list/common/tooltip';
import DeferredContent from '../../../components/list/common/deferredContent';
import { DEFFRED_CONTENT_TYPE } from './common';
import CONFIG_URL from '../api';
import PRODUCT_MESSAGE from './productMessage';
import { getRemoteTooltip } from '../../../sources/list/getRemoteTooltip';
import { getGiftToolTip } from '../../../sources/list/getGiftToolTip';
import { isRealLogin } from '../../../sources/list/getFragment';
import { getIfScopeInternational } from '../../../sources/list/scope';
import { ITINERARY_TAG_POSITION } from './itineraryTagPosition';
import { BEST_CHOICE_FLIGHT_TYPE } from './bestChoiceFlightType';
import styles from './itineraryTagType.module.scss';

export const getFlyerToolTip = (key, wrapper, minute) => {
	const isInternational = getIfScopeInternational();
	return (
		<div key={key} className="tooltip tooltip-fltsuper">
			<div className="tooltip-content">
				{wrapper.tooltipTitle && (
					<div
						className={isInternational ? styles['tlp-title'] : 'tlp-title'}
						dangerouslySetInnerHTML={{ __html: wrapper.tooltipTitle }}
					/>
				)}
				{isInternational
					? wrapper.tooltipContent && (
							<div className="tlp-txt" dangerouslySetInnerHTML={{ __html: wrapper.tooltipContent }} />
						)
					: minute && minute !== '0'
						? wrapper.tooltipContent && (
								<div
									className="tlp-txt"
									dangerouslySetInnerHTML={{ __html: replaceMinute(wrapper.tooltipContent, minute) }}
								/>
							)
						: wrapper.tooltipContentDefault && (
								<div
									className="tlp-txt"
									dangerouslySetInnerHTML={{ __html: wrapper.tooltipContentDefault }}
								/>
							)}
			</div>
		</div>
	);
};

const replaceMinute = (text, minute) => {
	return text.replace(/5/g, minute);
};

/**
 * 行程标签，显示在航组级别
 */
export const ITINERARY_TAG_TYPE = new Enum({
	...BEST_CHOICE_FLIGHT_TYPE,
	SuperFlyer: {
		seq: 1,
		// 超级飞人
		getTag: (_wrapper, index) => (
			<div
				key={index}
				className="warning"
				id={index}
				dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.FLYER_RELATED.SuperFlyer.tag }}
			></div>
		),
		getTooltip: (index, _wrapper) =>
			getFlyerToolTip(index, PRODUCT_MESSAGE.FLYER_RELATED.SuperFlyer, _wrapper?.description),
		position: ITINERARY_TAG_POSITION.MIDDLE,
		submitOnBook: false,
	},
	Flyer: {
		seq: 2,
		// 飞人
		getTag: (_wrapper, index) => (
			<div
				key={index}
				className="warning"
				id={index}
				dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.FLYER_RELATED.Flyer.tag }}
			></div>
		),
		getTooltip: (index, _wrapper) =>
			getFlyerToolTip(index, PRODUCT_MESSAGE.FLYER_RELATED.Flyer, _wrapper?.description),
		position: ITINERARY_TAG_POSITION.MIDDLE,
		submitOnBook: false,
	},
	DelayedTime: {
		seq: 3,
		// 预计延误时间，只会显示第一程的出发预计研发时间，且优先级低于超飞、飞人，即出现超飞、飞人时就不显示延误时间
		getTag: (wrapper, index) => (
			<span key={index} id={index} className="tag delayed-tip">
				预计 <span className="delayed-time">{wrapper.data}</span> 起飞
			</span>
		),
		getTooltip: (_index, _wrapper) => null,
		position: ITINERARY_TAG_POSITION.MIDDLE,
		submitOnBook: false,
	},
	FlightGeneralCoupon: {
		seq: 9,
		// 航班通用券
		group: 'FlightLeftTag', // 同一个group最多只会显示一个tag，优先显示seq最小的那个（可能同时出现航程通用券和航班礼盒，此时只要显示航班通用券）
		httpMethod: 'POST',
		getUrlParams: (data) => {
			return [data.couponId];
		},
		getUrl: (_tag) => CONFIG_URL.sendCoupons,
		getTag: (wrapper, index) => {
			return (
				<DeferredContent
					key={index}
					promise={
						isRealLogin ? getRemoteTooltip(wrapper) : Promise.resolve({ [wrapper.data.couponId]: true })
					}
					loading={<span id={index} />}
					then={(data) => {
						return data[wrapper.data.couponId] ? (
							<span className="tag-light-orange" id={index}>
								<React.Fragment>
									{wrapper.data.couponLabel + (isRealLogin ? '' : '(登录即享)')}
									<i></i>
								</React.Fragment>
							</span>
						) : null;
					}}
				/>
			);
		},
		getTooltip: (index, wrapper) => (
			<Tooltip content={wrapper.data.couponDesc + (isRealLogin ? '' : '(登录即享)')} />
		),
		position: ITINERARY_TAG_POSITION.LEFT,
	},
	FlightGift: {
		seq: 10,
		// 航班礼盒
		group: 'FlightLeftTag', // 同一个group最多只会显示一个tag，优先显示seq最小的那个（可能同时出现航程通用券和航班礼盒，此时只要显示航班通用券）
		getUrlParams: (data) => {
			return { giftIds: data };
		},
		getUrl: (_tag) => CONFIG_URL.getGiftDetail,
		getTag: (_wrapper, index) => (
			<span key={index} id={index} className="tag-light-orange">
				{PRODUCT_MESSAGE.ITINERARY_TAG.FlightGift}
				<i></i>
			</span>
		),
		getTooltip: (index, wrapper) => (
			<DeferredContent
				key={`${DEFFRED_CONTENT_TYPE.PRICE_TAG}_${index}`}
				promise={getRemoteTooltip(wrapper)}
				loading={<Tooltip content="loading..." />}
				then={(data) => getGiftToolTip(data)}
			/>
		),
		position: ITINERARY_TAG_POSITION.LEFT,
		submitOnBook: false,
	},
	TongChengFlight: {
		isTongCheng: true,
		seq: 11,
		position: ITINERARY_TAG_POSITION.NONE,
	},
	ApprovedVirtualFlight: {
		isTongCheng: true,
		seq: 11,
		position: ITINERARY_TAG_POSITION.NONE,
	},
	G5_TC_Flight: {
		isTongCheng: true,
		// 华夏通程
		seq: 11,
		position: ITINERARY_TAG_POSITION.NONE,
	},
	G5_LC_Flight: {
		// 华夏联程
		seq: 11,
		position: ITINERARY_TAG_POSITION.NONE,
	},
	GeneralFlight: {
		// 通用航班
		seq: 11,
		position: ITINERARY_TAG_POSITION.NONE,
	},
});

export class ItineryaryTagWrapper {
	constructor(tag) {
		this.tag = ITINERARY_TAG_TYPE.get(tag.type);
		this.data = tag.data || {};
		this.label = tag.label || '';
		this.extData = tag.extData || {};
		this.description = tag.description || '';
	}
}
