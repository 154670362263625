import { ubtDevTrace } from '@/src/sources/common/lightUbt';
import { UbtHandler } from '../handler';
import { UBT_EVENT_TYPE } from '../common';
import { CHANNEL_UBT_GROUP_TYPES } from './groupTypes';
import { CHANNEL_UBT_KEY_TYPES } from './keyTypes';

const customEvent = {};  // 自定义用户事件

const regCustomEvent = (eventName, callback) => {
  if (eventName && callback && typeof callback === 'function') {
    customEvent[eventName] = callback;
  }
};

export const invokeCustomEvent = (eventName, args) => {
  let callback = customEvent[eventName];
  if (callback && typeof callback === 'function') {
    callback(args);
  }
};

const groupList = [CHANNEL_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, CHANNEL_UBT_GROUP_TYPES.LOW_PRICE_REPORT]; // 已注册的 CHANNEL_UBT_GROUP_TYPES 列表，靠自定义方式收集的group需要自己添加

Object.keys(CHANNEL_UBT_KEY_TYPES.toJSON()).forEach((key => {
  let typeEnum = CHANNEL_UBT_KEY_TYPES.get(key);
  if (typeEnum) {
    let typeEnumValue = typeEnum.value;

    if (typeEnumValue.listen === UBT_EVENT_TYPE.CUSTOM) {
      let eventName = key;

      regCustomEvent(eventName, (ev) => {
        typeEnumValue.process(eventName, null, ev);
      });
    }
  }
}));

const regGroup = (group) => {
  if (groupList.includes(group) === false) {
    groupList.push(group);
  }
};

/**
 * 注册页面离开事件、初始化自定义事件
 */
export const initUbt = () => {
  window.addEventListener('beforeunload', (_ev) => {
    groupList.forEach(group => {
      group.value.trigger.value.beforeUnload(group);
    });
  });
  window.addEventListener('pageshow', (e) => {
    const navigationType = performance.getEntriesByType('navigation')[0]?.type;
    if (e.persisted || navigationType === 'back_forward') {
      ubtDevTrace('back_forward_navigation', { 'isBFCache': e.persisted, navigationType });
    }
  });
};

export class CommonUbtHandler extends UbtHandler {
  constructor(groupEnum, keyEnum, tag) {
    super();

    this.groupEnum = groupEnum;
    this.keyEnum = keyEnum;
    this.rawData = this.groupEnum.value.rawData;
    this.tag = tag;

    regGroup(groupEnum);
  }

  onEvent(eventType, source, ev) {
    this.onProcess(eventType, source, ev);
  }

  onProcess(eventType, source, ev) {
    let preventAfterProcess = this.keyEnum.value.process(this, source, ev);
    // 会有些奇葩场景，比如process中动态修改key直接上报，就不用再走常规 afterProcess 了
    if (preventAfterProcess !== false) {
      this.groupEnum.value.trigger.value.afterProcess(this.groupEnum);
    }
  }
}

export const ChannelUbtGenerator = (groupEnum, keyEnum, ...tag) => {
  return new CommonUbtHandler(groupEnum, keyEnum, tag);
};
