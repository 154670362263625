/* eslint-disable react/jsx-no-target-blank */
import React from 'react';
import '../../styles/protection/smallstyle.scss';
import { UbtBoundary, ChannelUbtGenerator, CHANNEL_UBT_GROUP_TYPES, CHANNEL_UBT_KEY_TYPES } from '../../ubt.v2/channel';
import { ExposeStat } from '../../ubt.v2/exposeStat';

const SmallSixProtection = ({ globalConfigs }) => {
	const {
		protectionJumpUrl = 'https://contents.ctrip.com/activitysetupapp/mkt/index/pc#flight',
		protectionImgV2 = {},
	} = globalConfigs || {};
	const isBookPage = false;

	const onTabClick = (text) => {
		let pageId = window.document.getElementById('page_id').value || '';
		CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK.value.process({
			tag: { type: 'c_service_protection', text: `${pageId}_${text}` },
		});
	};

	return (
		<ExposeStat ubtKey="six_protection_small_ubt">
			<div className={`six_protection_small ${isBookPage ? 'book' : ''}`}>
				<UbtBoundary
					tracker={ChannelUbtGenerator(
						CHANNEL_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						CHANNEL_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{ type: 'c_service_assurance', text: '六重保障' }
					)}
				>
					<div>
						{isBookPage ? <hr /> : ''}
						<div className="six_protection_body">
							<div className="six_protection_logo">
								<a href={protectionJumpUrl} target="_blank">
									<img src={protectionImgV2.logo} style={{ width: 168 }} alt="安心定放心飞" />
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.xingcheng} alt="行程保障" />
								<a href={protectionJumpUrl} target="_blank" onClick={() => onTabClick('点击行程保障')}>
									行程保障
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.jiage} alt="价格保障" />
								<a href={protectionJumpUrl} target="_blank" onClick={() => onTabClick('点击价格保障')}>
									价格保障
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.kefu} alt="7*24小时客服" />
								<a
									href={protectionJumpUrl}
									target="_blank"
									onClick={() => onTabClick('点击7*24小时客服')}
								>
									7*24小时客服
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.yingji} alt="应急援助" />
								<a href={protectionJumpUrl} target="_blank" onClick={() => onTabClick('点击应急援助')}>
									应急援助
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.anxin} alt="安心1 + 1" />
								<a href={protectionJumpUrl} target="_blank" onClick={() => onTabClick('点击安心1+1')}>
									安心1 + 1
								</a>
							</div>
							<div className="six_protection_content">|</div>
							<div className="six_protection_content">
								<img src={protectionImgV2.jiaotong} alt="组合交通出行" />
								<a
									href={protectionJumpUrl}
									target="_blank"
									onClick={() => onTabClick('点击组合交通出行')}
								>
									组合交通出行
								</a>
							</div>
						</div>
					</div>
				</UbtBoundary>
			</div>
		</ExposeStat>
	);
};

export default SmallSixProtection;
