import React, { useCallback } from 'react'
import { IntersectionNotifier } from '../components/Base/intersetionNotifier'
import { lightUpload } from '../sources/common/lightUbt'

export const ExposeStat = ({ ubtKey, children }) => {
    const uploadUbt = useCallback(() => {
        ubtKey && lightUpload(ubtKey, {})
    }, [ubtKey])

    return (
        <IntersectionNotifier onScrollIntoView={uploadUbt} loading={children} />)
}
