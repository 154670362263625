import { routerReducer } from 'react-router-redux'; // ConnectedRouter as Router,
import { createStore, applyMiddleware } from 'redux';
import { thunk } from 'redux-thunk';
import { combineReducers } from 'redux-immutable';
import { composeWithDevTools } from '@redux-devtools/extension';
import rootReducers from '../../reducers/channel';

const reducers = combineReducers(Object.assign(rootReducers, { router: routerReducer }));

let enhancer = applyMiddleware(thunk);

if (process.env.NODE_ENV === 'development') {
	enhancer = composeWithDevTools(applyMiddleware(thunk));
}

const store = createStore(reducers, enhancer);

export default store;
