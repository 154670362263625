import React from 'react'

export default class TooltipsImg extends React.Component {
    constructor(props) {
        super(props)
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.link !== this.props.link) {
            document.querySelectorAll(".tooltipImage").src = ""
        }
    }
    render() {
        const { link, alt = '' } = this.props
        let links = link.split('|')

        return (
            <div className="tooltip tooltip-img" style={this.props.style}>
                <div className="tooltip-content">
                    {links.map((linkSrc, index) => {
                        return <div key={index}>
                            <img className="img tooltipImage" src={linkSrc} alt={alt} />
                        </div>
                    })}
                </div>
            </div>
        )
    }
}
