import React from 'react'
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES , UbtHandler } from '../../../ubt.v2/list'
import store from '../../../stores/list'
import { genSortV3ClickEntry } from '../../../actions/list'
import { BEST_CHOICE_FLIGHTS_SORT_CONFIG } from './bestChoiceFlightsSortConfig';
import { SortCache } from '../../../sources/list/sortCache';
import { getRoundTabModeSwitchSelector, getActiveRoundTabIndexSelector } from '../../../containers/list/result/flight/baseSelector'
import { scopeObject } from '../../../sources/list/scopeObject'

const genSortClickEntryWithCache = (sortEntry, activeIndex) => {
    const { cacheStatus, key } = sortEntry
    SortCache.saveCache(cacheStatus ? key : '', activeIndex)

    const state = store.getState()
    const activeRoundTabIndex = getActiveRoundTabIndexSelector(state)
    const roundTabMode = getRoundTabModeSwitchSelector(state)

    const splitRound = roundTabMode && activeRoundTabIndex === 1

    const prevActiveEntry = [...sortEntry.allOtherEntryList, sortEntry].find(entry => entry.activeIndex)
    if (prevActiveEntry) {
        const { key: prevActiveEntryKey, activeIndex: prevActiveIndex } = prevActiveEntry
        UbtHandler.onUploadData('c_click_sort', { prevKey: prevActiveEntryKey, prevIndex: prevActiveIndex, curKey: key, curIndex: activeIndex })
    }

    return genSortV3ClickEntry(key, activeIndex, splitRound)
}

/**
 *
精选航班的枚举类型：

直飞低价	DirectCheapestChoice
当日低价	CheapestChoice
便宜省时	CheaperTimeSavingChoice
高性价比	HigherCostPerformance
最省时	ShortestTimeChoice
晚上出发低价	DepartAtEveningLowPrice
国内中转低价	DomesticeTransferLowPrice
五星航司低价	FiveStarAirlineLowPrice
中转免签玩香港	HkTransferVisaFreeChoice
中转免签玩澳门	MacaoTransferVisaFreeChoice
下午到达低价	ArriveAtAfternoonLowPrice
上午出发低价	DepartAtForenoonLowPrice
 */

const genSortOptionClassName = (sortEntryBase, currentSortIndex) => sortEntryBase.activeIndex === currentSortIndex ? 'active sortbar-option' : 'sortbar-option'

const genSortV2OptionTick = (sortEntryBase, currentSortIndex) => sortEntryBase.activeIndex === currentSortIndex ? <span className='tick' /> : null

class sortTypeEntryBase {
    constructor(key, cacheStatus) {
        this.activeIndex = 0    //排序header上多次点击，切换的先后顺序，约定 0 表示未激活状态
        this.key = key
        this.allOtherEntryList = []     //除了该项之外的其他排序项集合
        this.cacheStatus = cacheStatus
    }

    getStatusCount() {
        return this.getContainerClassNamesOfStatus().length    //总共有几种状态
    }

    getContainerClassNamesOfStatus() {
        throw new Error('not implement')
    }

    getQueryStringValuesOfStatus() {
        throw new Error('not implement')
    }

    // eslint-disable-next-line no-unused-vars
    getSortIndicatorsOfStatus() {
        throw new Error('not implement')
    }

    /**
     * 在各种排序模式下，各种精选航班类型的置顶优先级，目前只有按照优先级排在前三的航班置顶
     * 注：每个类型的精选航班都可能多于一个，且直飞优先排序时，只能置顶直飞的精选航班
     */
    getForceTopBestChoiceFlightsSorts() {
        return []
    }

    /**
     * 精选航班置顶逻辑，直飞优先排序时，只能置顶直飞的精选航班
     */
    doFilterIfForceTopBestChoiceFlight(_flight, _currentSegmentSeq, _isBuildUp) {
        return true
    }

    /**
     * 是否需要显示，目前主要有两类情况：往返组合禁掉部分排序/两舱禁掉部分排序
     */
    getIsVisible(_isBuildUp, _prevCond, _globalSwitch) {
        return true
    }

    render(isBuildUp, prevCond, globalSwitch, currentSegmentSeq) {
        if (!this.getIsVisible(isBuildUp, prevCond, globalSwitch)) {
            return null
        } else {
            const inner = this.renderInner(currentSegmentSeq),
                isActive = this.activeIndex > 0

            return (
                <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.COMMON_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK, this.key)}>
                    <li u_key='sort_header_entry' u_remark={`排序项[${this.key}]，点击之前activeIndex:[${this.activeIndex}]`}
                        className={`sort-item ${isActive ? 'active' : ''} ${this.getContainerClassNamesOfStatus()[this.activeIndex] || ''}`}>
                        {inner}
                    </li>
                </UbtBoundary>
            )
        }
    }

    renderInner() {
        throw new Error('not implement')
    }

    _onSetActive(index) {
        this.allOtherEntryList.forEach(entry => entry.activeIndex = 0)
        this.activeIndex = index
    }

    setQueryStringSort(queryStringSort) {
        if (queryStringSort) {
            // 往返分屏不需要 价格由低到高的排序
            const index = this.getQueryStringValuesOfStatus().indexOf(queryStringSort)
            if (index >= 0) {
                this._onSetActive(index)

                return true     //表示从url成功恢复了排序
            }
        }

        return false
    }

    // 设置为激活状态，目前只有恢复缓存时候使用
    forceSetActive(index) {
        this._onSetActive(index)
    }

    getRecommendSortIndicators() {
        return scopeObject({
            i: [
                { weight: 1, type: 'HIGH_CABIN_RECOMMEND' },
                { weight: 1, type: 'ALL_HIGH_CABIN_PRIORITY' }
            ],
            d: [
                { weight: 1, type: 'DOMESTIC_FILTER_GROUP' }
            ]
        })
    }
}

// 国内专用，准点率
class sortTypeEntry_OnTime_Desc extends sortTypeEntryBase {
    constructor() {
        super('ontime_desc', true)
    }

    getContainerClassNamesOfStatus() {
        return [
            'sort-item ontime',
            'sort-item ontime active'
        ]
    }

    getQueryStringValuesOfStatus() {
        return [
            '',
            'ontime_desc'
        ]
    }

    getSortIndicatorsOfStatus({ isBuildUp, currentSegmentSeq }) {
        return [
            [],
            [...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
            { weight: 1, type: 'TRANSFER_AND_STOP_COUNT' },
            { weight: -1, type: 'ON_TIME' },
            { weight: 1, type: 'PRICE_WITH_TAX' },
            { weight: 1, type: 'DEPART_TIME' },
            { weight: 1, type: 'ARRIVAL_TIME' },
            { weight: 1, type: 'DURATION' }
            ].filter(Boolean)
        ]
    }

    getForceTopBestChoiceFlightsSorts() {
        return [
            [],
            BEST_CHOICE_FLIGHTS_SORT_CONFIG.price[0]
        ]
    }

    renderInner(currentSegmentSeq) {
        return <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, this.key, currentSegmentSeq)}>
            <span onClick={_ => store.dispatch(genSortClickEntryWithCache(this, 1))}>准点率高-低</span>
        </UbtBoundary>
    }
}

class sortTypeEntry_DirectFlight extends sortTypeEntryBase {
    constructor() {
        super('direct_flight', true)
        this.activeIndex = 1    //默认直飞优先激活
    }

    getContainerClassNamesOfStatus() {
        return [
            'direct-arrive',
            'direct-arrive active'
        ]
    }

    getQueryStringValuesOfStatus() {
        return [
            '',
            'directfirst'
        ]
    }

    getSortIndicatorsOfStatus({ isBuildUp, currentSegmentSeq }) {
        if (isBuildUp) {
            return [
                [],
                [
                    ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                    { weight: 1, type: 'SIMPLE_TRANSFER_AND_STOP_COUNT' },
                    { weight: 1, type: 'CUR_SEGMENT_PRICE_OR_MISERY_INDEX' },
                    { weight: 1, type: 'FREE_MACAO_VISA' },
                    { weight: 1, type: 'FREE_HK_VISA' },
                    { weight: 1, type: 'DURATION_OR_NONE' },
                    { weight: 1, type: 'PURE_TAX' }
                ]
            ]
        } else {
            return [
                [],
                [
                    ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                    { weight: 1, type: 'TRANSFER_AND_STOP_COUNT' },
                    { weight: 1, type: 'CUR_SEGMENT_PRICE_OR_MISERY_INDEX' },
                    { weight: 1, type: 'FREE_MACAO_VISA' },
                    { weight: 1, type: 'FREE_HK_VISA' },
                    { weight: 1, type: 'DURATION_OR_NONE' },
                    { weight: 1, type: 'DEPART_TIME' },
                    { weight: 1, type: 'ARRIVAL_TIME' }
                ]
            ]
        }
    }

    getForceTopBestChoiceFlightsSorts() {
        return [
            [],
            BEST_CHOICE_FLIGHTS_SORT_CONFIG.direct_flight[0]
        ]
    }

    // 直飞优先排序时，只置顶直飞的航班
    // 往返组合是去程返程都要直飞，否则是当前程要直飞
    doFilterIfForceTopBestChoiceFlight(flight, currentSegmentSeq, isBuildUp) {
        const getIsDirectFlight = segmentSeq => flight.getIn(['flightSegments', segmentSeq, 'transferAndStopCount']) === 0
        return isBuildUp ? (getIsDirectFlight(0) && getIsDirectFlight(1)) : getIsDirectFlight(currentSegmentSeq)
    }

    renderInner(currentSegmentSeq) {
        return <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, this.key, currentSegmentSeq)}>
            <span onClick={_ => store.dispatch(genSortClickEntryWithCache(this, 1))}>直飞优先</span>
        </UbtBoundary>
    }
}

class sortTypeEntry_LowPrice_Asc extends sortTypeEntryBase {
    constructor() {
        super('price_asc', true)
        this.activeIndex = scopeObject({ i: 0, d: 1 })      //国内默认激活
    }

    getContainerClassNamesOfStatus() {
        return [
            'sort-item ticket-price',
            'sort-item ticket-price active'
        ]
    }

    getQueryStringValuesOfStatus() {
        return [
            '',
            'price_asc'
        ]
    }

    getSortIndicatorsOfStatus({ containsTax, isBuildUp, currentSegmentSeq, segmentCountAsPriceSortSwitch }) {
        return [
            [],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                segmentCountAsPriceSortSwitch ? { weight: 1, type: 'SEGMENT_COUNT' } : null,
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: 'DURATION' }
            ].filter(Boolean)
        ]
    }

    getForceTopBestChoiceFlightsSorts() {
        return [
            [],
            BEST_CHOICE_FLIGHTS_SORT_CONFIG.price[0]
        ]
    }

    renderInner(currentSegmentSeq) {
        return <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, this.key, currentSegmentSeq)}>
            <span onClick={_ => store.dispatch(genSortClickEntryWithCache(this, 1))}>低价优先</span>
        </UbtBoundary>
    }
}

class sortTypeEntry_DepartTime_Asc extends sortTypeEntryBase {
    constructor() {
        super('depart_time_asc', true)
    }

    getIsVisible(isBuildUp, _prevCond, _globalSwitch) {
        return !isBuildUp
    }

    getContainerClassNamesOfStatus() {
        return [
            'sort-item time',
            'sort-item time active'
        ]
    }

    getQueryStringValuesOfStatus() {
        return [
            '',
            'deptime_asc'
        ]
    }

    getSortIndicatorsOfStatus({ containsTax, isBuildUp, currentSegmentSeq }) {
        return [
            [],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                { weight: 1, type: 'DURATION' }
            ]
        ]
    }

    getForceTopBestChoiceFlightsSorts() {
        return [
            [],
            BEST_CHOICE_FLIGHTS_SORT_CONFIG.depart_time[0]
        ]
    }

    renderInner(currentSegmentSeq) {
        return <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, this.key, currentSegmentSeq)}>
            <span onClick={_ => store.dispatch(genSortClickEntryWithCache(this, 1))}>起飞时间早-晚</span>
        </UbtBoundary>
    }
}

class sortTypeEntry_Duration_Asc extends sortTypeEntryBase {
    constructor() {
        super('duration_asc', true)
    }

    getIsVisible(isBuildUp, _prevCond, _globalSwitch) {
        return !isBuildUp
    }

    getContainerClassNamesOfStatus() {
        return [
            'sort-item consume',
            'sort-item consume active'
        ]
    }

    getQueryStringValuesOfStatus() {
        return ['', 'duration_asc']
    }

    getSortIndicatorsOfStatus({ containsTax, isBuildUp, currentSegmentSeq }) {
        return [
            [],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: 1, type: 'DURATION' },
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' }
            ]
        ]
    }

    getForceTopBestChoiceFlightsSorts() {
        return [
            [],
            BEST_CHOICE_FLIGHTS_SORT_CONFIG.flight_duration[0]
        ]
    }

    renderInner(currentSegmentSeq) {
        return <UbtBoundary key={this.key} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, this.key, currentSegmentSeq)}>
            <span onClick={_ => store.dispatch(genSortClickEntryWithCache(this, 1))}>耗时短优先</span>
        </UbtBoundary>
    }
}

// 更多排序，也就是剩下的排序选项都放进来
class sortTypeEntry_More extends sortTypeEntryBase {
    constructor() {
        super('more', true)
    }

    getIsVisible(isBuildUp, _prevCond, _globalSwitch) {
        return !isBuildUp
    }

    getContainerClassNamesOfStatus() {
        return [
            'sort-item',
            'sort-item active depart-desc',
            'sort-item active arrival-asc',
            'sort-item active arrival-desc',
            scopeObject({ i: null, d: 'sort-item active duration-asc' }),   // 国内更多排序有   耗时短-长
            'sort-item active duration-desc',
            'sort-item active price-desc'
        ].filter(Boolean)
    }

    getQueryStringValuesOfStatus() {
        return ['',
            'deptime_desc',
            'arrivaltime_asc',
            'arrivaltime_desc',
            scopeObject({ i: null, d: 'duration-asc' }),
            'duration_desc',
            'price_desc'
        ].filter(val => val !== null)
    }

    getSortIndicatorsOfStatus({ containsTax, isBuildUp, currentSegmentSeq, segmentCountAsPriceSortSwitch }) {
        return [
            [],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: -1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                { weight: 1, type: 'DURATION' }
            ],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                { weight: 1, type: 'DURATION' }
            ],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: -1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                { weight: 1, type: 'DURATION' }
            ],
            scopeObject({
                i: null,
                d: [
                    ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                    { weight: 1, type: 'DURATION' },
                    { weight: 1, type: 'DEPART_TIME' },
                    { weight: 1, type: 'ARRIVAL_TIME' },
                    { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' }
                ],
            }),
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: -1, type: 'DURATION' },
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' }
            ],
            [
                ...this.getRecommendSortIndicators(isBuildUp, currentSegmentSeq),
                { weight: -1, type: containsTax ? 'PRICE_WITH_TAX' : 'PRICE_WITHOUT_TAX' },
                segmentCountAsPriceSortSwitch ? { weight: 1, type: 'SEGMENT_COUNT' } : null,
                { weight: 1, type: 'DEPART_TIME' },
                { weight: 1, type: 'ARRIVAL_TIME' },
                { weight: 1, type: 'DURATION' }
            ].filter(Boolean)
        ].filter(Boolean)
    }

    getForceTopBestChoiceFlightsSorts() {
        return new Array(scopeObject({ i: 6, d: 7 })).fill([])
    }

    renderInner(currentSegmentSeq) {
        const indicatorList = [
            '更多排序',
            '起飞晚-早',
            '到达早-晚',
            '到达晚-早',
            scopeObject({ i: null, d: '耗时短-长' }),
            '耗时长-短',
            '价格高-低'
        ].filter(Boolean)
            .map((label, index) => <React.Fragment key={index}>{label}</React.Fragment>),
            queryStringValues = this.getQueryStringValuesOfStatus()     //queryString同时用作埋点

        return (
            <span>
                {indicatorList[this.activeIndex]}
                <i className="iconf-arrow-up">&#xe604;</i>
                <i className="iconf-arrow-down">&#xe60c;</i>

                <div className='sortbar-options-wrapper'>
                    <ul className='sortbar-options'>
                        {indicatorList
                            .map((indicator, index) => {
                                return index === 0 ? null : (
                                    <UbtBoundary key={index} tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.SORT_CLICK, LIST_UBT_KEY_TYPES.SORT_CLICK_NOW, queryStringValues[index], currentSegmentSeq)}>
                                        <li className={genSortOptionClassName(this, index)} onClick={_ => store.dispatch(genSortClickEntryWithCache(this, index))}>{genSortV2OptionTick(this, index)}
                                            {indicator}
                                        </li>
                                    </UbtBoundary>)
                            })}
                    </ul>
                </div>
            </span>)
    }
}

export {
    sortTypeEntry_OnTime_Desc,
    sortTypeEntry_DirectFlight,
    sortTypeEntry_LowPrice_Asc,
    sortTypeEntry_DepartTime_Asc,
    sortTypeEntry_Duration_Asc,
    sortTypeEntry_More
}
