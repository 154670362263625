export const SEARCH_INIT_GLOBAL_SWITCH = 'SEARCH_INIT_GLOBAL_SWITCH';
export const SEARCH_INIT_GLOBAL_CONFIG = 'SEARCH_INIT_GLOBAL_CONFIG';
export const SEARCH_INIT_PREV_COND = 'SEARCH_INIT_PREV_COND';
export const SEARCH_FLIGHTS = 'SEARCH_FLIGHTS';

export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';
export const SEARCH_ACTIVATE_TABGROUP = 'SEARCH_ACTIVATE_TABGROUP';
export const CHANGE_FLIGHTWAY = 'CHANGE_FLIGHTWAY';

export const SET_SEARCH_CRITERIA_TOKEN = 'SET_SEARCH_CRITERIA_TOKEN';
export const INCREMENT_PASSENGER = 'INCREMENT_PASSENGER';
export const DECREMENT_PASSENGER = 'DECREMENT_PASSENGER';

export const CHANGE_SEARCHHISTORY_LIST_VISIBLE = 'CHANGE_SEARCHHISTORY_LIST_VISIBLE';
export const ON_SEARCH_DATE_CHANGE = 'ON_SEARCH_DATE_CHANGE';
export const FOCUS_CITYPICKER = 'FOCUS_CITYPICKER';
export const BLUR_CITYPICKER = 'BLUR_CITYPICKER';
export const SEARCH_HISTORY = 'SEARCH_HISTORY';
export const SET_GLOBAL_SEARCH_CRITERIA = 'SET_GLOBAL_SEARCH_CRITERIA';
export const CLEAR_FOCUS_NEXT_INPUT = 'CLEAR_FOCUS_NEXT_INPUT';
export const MODIFY_SEARCH_CITY = 'MODIFY_SEARCH_CITY';
export const SEARCH_SET_LAST_SEARCH_TIME = 'SEARCH_SET_LAST_SEARCH_TIME';
export const SET_COMFORT_TAGS = 'SET_COMFORT_TAGS';
export const SET_IS_RESEARCHING = 'SET_IS_RESEARCHING';
export const EXCHANGE_MULTI_CITY = 'EXCHANGE_MULTI_CITY';
export const SHOW_WARN = 'SHOW_WARN';
export const SET_GUIDE_LINE = 'SET_GUIDE_LINE';
export const SET_FILTER_CLASS_GRADE = 'SET_FILTER_CLASS_GRADE';

export const CANCEL_FOCUS_MT_SECOND_DCITY = 'CANCEL_FOCUS_MT_SECOND_DCITY';
export const SET_BEST_CHOICE_FLIGHTS_FORCE_TOP = 'SET_BEST_CHOICE_FLIGHTS_FORCE_TOP';
export const SET_PASSENGER_COUNT = 'SET_PASSENGER_COUNT';
export const CHANGE_CLASS_GRADE = 'CHANGE_CLASS_GRADE';

export const SEARCH_INTL_PRICE_LIST = 'SET_INTL_PRICE_LIST';
export const FAKE_PRICE_MARK = 'FAKE_PRICE_MARK';