import store from '@/src/stores/list';
import {
	sortTypeEntry_OnTime_Desc,
	sortTypeEntry_DirectFlight,
	sortTypeEntry_LowPrice_Asc,
	sortTypeEntry_DepartTime_Asc,
	sortTypeEntry_Duration_Asc,
	sortTypeEntry_More,
} from './sortTypeEntry';
import { SortCache } from '../../../sources/list/sortCache';
import { UbtHandler } from '../../../ubt.v2/list';
import { getIfSwitchOpenAsync } from '../../../sources/common/getIfSwitchOpenAsync';
import { genSortV3ClickEntry } from '../../../actions/list';
import { getIfScopeInternational } from '../../../sources/list/scope';

class sortTypeProvider {
	constructor({ isBuildUp, prevCond, queryStringSort, globalSwitch }) {
		this.isBuildUp = isBuildUp;
		this.prevCond = prevCond;
		this.queryStringSort = queryStringSort;
		this.globalSwitch = globalSwitch;
		this.defaultNewUserPriceSortSwitch = globalSwitch.get('defaultNewUserPriceSortSwitch');
	}

	dump() {
		let hasSetSortFromQueryString = false; // 是否从url恢复排序
		let sortTypeEntryList = getIfScopeInternational()
			? [
					new sortTypeEntry_DirectFlight(),
					new sortTypeEntry_LowPrice_Asc(),
					new sortTypeEntry_DepartTime_Asc(),
					new sortTypeEntry_Duration_Asc(),
					new sortTypeEntry_More(),
			  ]
			: [
					new sortTypeEntry_LowPrice_Asc(),
					new sortTypeEntry_OnTime_Desc(),
					new sortTypeEntry_DepartTime_Asc(),
					new sortTypeEntry_More(),
			  ];

		sortTypeEntryList.forEach((entry, index) => {
			entry.allOtherEntryList = sortTypeEntryList.filter((_, theIndex) => theIndex !== index);
			if (this.queryStringSort) {
				let successSetSort = entry.setQueryStringSort(this.queryStringSort);

				if (successSetSort) {
					hasSetSortFromQueryString = successSetSort;
					UbtHandler.onUploadData('c_use_sort_from_query_string', { sort: this.queryStringSort });
				}
			}
		});

		if (!hasSetSortFromQueryString) {
			// 从url恢复排序的优先级更高，如果已经从url恢复了排序，就不读缓存的排序了
			const sortCache = SortCache.getCache();
			if (sortCache) {
				const { key, index } = sortCache;
				if (key && index) {
					let theCachedSortEntry = sortTypeEntryList.find(
						(entry) =>
							entry.key === key && entry.getIsVisible(this.isBuildUp, this.prevCond, this.globalSwitch)
					);
					if (theCachedSortEntry) {
						getIfSwitchOpenAsync('rememberListSortV2Switch').then((remember) => {
							if (remember) {
								// ABT命中，需要恢复
								const totalStatusCount = theCachedSortEntry.getStatusCount();
								if (index > 0 && index < totalStatusCount) {
									UbtHandler.onUploadData('c_read_list_sort_from_cache', { key, index });
									store.dispatch(genSortV3ClickEntry(key, index, false));
								} else {
									UbtHandler.onUploadData('c_invalid_list_sort_from_cache', {
										key,
										index,
										totalStatusCount,
									});
								}
							} else {
								UbtHandler.onUploadData('c_ab_refuse_use_list_sort_from_cache', {});
							}
						});
					}
				}
			}
		}

		// 前面没有设置默认排序，且当前是新用户，就把默认排序改成低价优先
		if (
			this.prevCond.getIn(['extensionAttributes', 'isFlightIntlNewUser']) &&
			this.defaultNewUserPriceSortSwitch &&
			!hasSetSortFromQueryString
		) {
			hasSetSortFromQueryString = true;
			const priceEntry = sortTypeEntryList.find((entry) => entry.key === 'price');
			if (priceEntry) {
				priceEntry.forceSetActive(1);
				UbtHandler.onUploadData('c_new_user_default_use_price_sort', true);
			}
		}

		return sortTypeEntryList;
	}
}

export { sortTypeProvider };
