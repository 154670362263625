import React from 'react';
import moment from 'moment';
import Converter from '../../../../sources/list/converter';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../../ubt.v2/list';
import { onResearchFlightsWithFullUrl } from '../../../../actions/list/getGlobalSearchCriteria';

export default class TrendCalendarLowPrice extends React.Component {
    constructor(props) {
        super(props);
        this.onClickLeft = this.onClickLeft.bind(this);
        this.onClickRight = this.onClickRight.bind(this);
        this.getLinePosition = this.getLinePosition.bind(this);
        this.onChangeMonth = this.onChangeMonth.bind(this);
        this.jumpUrl = this.jumpUrl.bind(this);
        this.hideSearch = this.hideSearch.bind(this);
        this.onTrendAnimationEnd = this.onTrendAnimationEnd.bind(this);
        this.pageShowNums = 19; //页面配置显示条数 不参与视图更新
        this.moveShowNums = 10; //每次移动柱子的数目
        this.defaultIndex = 10; //进入页面焦点索引 不参与视图更新
        this.columnWidth = 56; //柱状图中每一项宽度 不参与视图更新
        this.activeIndex = 0; //焦点索引  不参与视图更新
        this.dataCount = 0; //总共的项  不参与视图更新
        this.firstIndex = 0; //显示第一项索引  不参与视图更新
        this.animationMonthIndex = 0; //显示动画效果月份索引 不参与视图更新
        this.state = {
            position: 0, //当前趋势位移
            canShowRealHeight: false, //是否显示趋势柱状图实际高度 （用于延迟动画效果）
            showSearch: true, //是否显示点击搜索字样 需求 初始当前选中状态显示点击搜索 任意柱状图hover后改为false
            linePosition: new Array(12).fill(0), //水平线文字的位移
            isTrendAnimationEnd: true, //是否已经完成动画效果 完成是 去掉动画class 进入趋势默认不做动画 点击锚点后置为false 开始动画效果
        };
    }
    hideSearch() {
        if (this.state.showSearch) {
            this.setState({
                showSearch: false,
            });
        }
    }
    onTrendAnimationEnd() {
        this.setState({
            isTrendAnimationEnd: true,
        });
    }
    onChangeMonth(index, monthIndex, lowprice) {
        if (index > this.defaultIndex) {
            this.firstIndex = index - this.defaultIndex + 1;
        } else if (index < this.defaultIndex) {
            this.firstIndex = index;
        } else {
            this.firstIndex = this.defaultIndex;
        }
        this.animationMonthIndex = monthIndex;
        this.setState(
            {
                position: -(this.firstIndex - 1) * this.columnWidth,
                linePosition: this.getLinePosition(),
                isTrendAnimationEnd: lowprice == -1,
            },
            () => {
                if (!this.state.canShowRealHeight) {
                    this.setState({
                        canShowRealHeight: true,
                    });
                }
            },
        );
    }
    onClickLeft() {
        if (this.firstIndex <= 1) return;
        if (this.firstIndex - this.moveShowNums >= 1) {
            this.firstIndex = this.firstIndex - this.moveShowNums;
        } else {
            this.firstIndex = 1;
        }
        this.setState({
            position: -(this.firstIndex - 1) * this.columnWidth,
            linePosition: this.getLinePosition(),
        });
    }
    onClickRight() {
        if (this.firstIndex + this.pageShowNums - 1 >= this.dataCount) return;
        if (this.firstIndex + this.pageShowNums + this.moveShowNums <= this.dataCount + 1) {
            this.firstIndex = this.firstIndex + this.moveShowNums;
        } else {
            this.firstIndex = this.dataCount - this.pageShowNums + 1;
        }
        this.setState({
            position: -(this.firstIndex - 1) * this.columnWidth,
            linePosition: this.getLinePosition(),
        });
    }
    getLinePosition() {
        let linePosition = new Array(12).fill(0),
            curTime = moment(),
            curYear = curTime.year(),
            curMonth = curTime.month(),
            firstIndexTime = moment().add(this.firstIndex - 1, 'days'),
            firstMonth = firstIndexTime.month(),
            firstYears = firstIndexTime.year(),
            curMonthDiffDays = firstIndexTime.date() - 1,
            month = moment([firstYears, firstMonth, 1]).diff(moment([curYear, curMonth, 1]), 'months');
        if (month <= 0) {
            curMonthDiffDays = moment([firstYears, firstMonth, firstIndexTime.date()]).diff(moment([curYear, curMonth, curTime.date()]), 'days');
        }
        linePosition[month] = curMonthDiffDays * this.columnWidth;
        return linePosition;
    }
    componentWillMount() {
        let { trendCalendar = undefined } = this.props || {};
        if (!trendCalendar) return;
        this.activeIndex = trendCalendar.get('activeIndex') || 0;
        this.dataCount = trendCalendar.get('count') || 0;
        if (this.activeIndex > this.defaultIndex) {
            this.firstIndex = this.activeIndex - this.defaultIndex + 1;
        } else if (this.activeIndex <= this.defaultIndex) {
            this.firstIndex = 1;
        }
        this.setState(
            {
                position: -(this.firstIndex - 1) * this.columnWidth,
                linePosition: this.getLinePosition(),
            },
            () => {
                if (!this.state.canShowRealHeight) {
                    this.setState({
                        canShowRealHeight: true,
                    });
                }
            },
        );
    }
    componentDidMount() {}
    componentWillReceiveProps(nextProps) {
        if (nextProps.diffDays != this.props.diffDays) {
            let { trendCalendar = undefined } = nextProps || {};
            if (!trendCalendar) return;
            this.activeIndex = trendCalendar.get('activeIndex') || 0;
            this.dataCount = trendCalendar.get('count') || 0;
            if (this.firstIndex + this.pageShowNums - 1 > this.dataCount && this.dataCount > this.pageShowNums) {
                this.firstIndex = this.dataCount - this.pageShowNums + 1;
            }
            this.setState(
                {
                    position: -(this.firstIndex - 1) * this.columnWidth,
                    linePosition: this.getLinePosition(),
                },
                () => {
                    if (!this.state.canShowRealHeight) {
                        this.setState({
                            canShowRealHeight: true,
                        });
                    }
                },
            );
            this.setState({
                canShowRealHeight: false,
            });
        }
    }
    componentDidUpdate() {
        if (!this.state.canShowRealHeight) {
            setTimeout(() => {
                this.setState({
                    canShowRealHeight: true,
                });
            });
        }
    }
    jumpUrl(depdate, rtdate) {
        let flightWay = this.props.flightWay,
            url = window.location.href;
        if (flightWay === 'S') {
            url = window.location.href.replace(/depdate=([0-9]|-)+/, `depdate=${depdate}`).replace('&nearByDate', '');
        } else if (flightWay === 'D') {
            url = window.location.href.replace(/depdate=([0-9]|-)+_([0-9]|-)+/, `depdate=${depdate}_${rtdate}`);
        }

        const newUrl = Converter.batchUpdateUrlQueryString(url, this.props.toRememberActiveKeys);
        onResearchFlightsWithFullUrl(newUrl);
    }
    render() {
        let { trendCalendar, isTrend } = this.props;
        if (!trendCalendar || !isTrend) return null;
        let positionStyle = {
                marginLeft: this.state.position + 'px',
            },
            addedAnimatedStatus = false;
        return (
            <div className="cld-body">
                <div className="cld-trend">
                    <div className="trend-tag-group">
                        {(() => {
                            let i = 0;
                            return trendCalendar.get('months').map((item, index) => {
                                if (i < 3) {
                                    i++;
                                    let data = item.get('data');
                                    if (data.size <= 0) return null;
                                    return (
                                        <UbtBoundary
                                            key={index}
                                            tracker={ListUbtGenerator(
                                                LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK,
                                                LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK,
                                                'c_lowest_month_trend',
                                            )}
                                        >
                                            <div
                                                key={index}
                                                onClick={() => this.onChangeMonth(item.get('thisMonthLowestIndex'), item.get('monthIndex'), item.get('thisMonthLowestPrice'))}
                                                className="trend-tag"
                                            >
                                                {item.get('month')}低价之选{' '}
                                                {item.get('thisMonthLowestPrice') == -1 ? (
                                                    '暂无数据'
                                                ) : (
                                                    <React.Fragment>
                                                        <dfn>&yen;</dfn>
                                                        {item.get('thisMonthLowestPrice')}
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </UbtBoundary>
                                    );
                                }
                                return null;
                            });
                        })()}
                    </div>

                    <div className="trend-chart">
                        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_leftmore_trend')}>
                            <div className={`pre-btn ${this.firstIndex == 1 ? 'disabled' : ''}`} onClick={this.onClickLeft}>
                                <i className="arrow-left-bold"></i>
                            </div>
                        </UbtBoundary>
                        <UbtBoundary tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK, LIST_UBT_KEY_TYPES.LOW_PRICE_CALENDAR_CLICK, 'c_rightmore_trend')}>
                            <div className={`next-btn ${this.firstIndex + this.pageShowNums - 1 >= this.dataCount ? 'disabled' : ''}`} onClick={this.onClickRight}>
                                <i className="arrow-right-bold"></i>
                            </div>
                        </UbtBoundary>
                        <div className="chart-cpt">
                            {this.props.flightWay === 'S' ? null : <p>去程</p>}
                            {this.props.flightWay === 'S' ? null : <p>返程</p>}
                        </div>

                        <div className="chart-body">
                            <div className="for-transform" style={positionStyle}>
                                {trendCalendar.get('months').map((item, index) => {
                                    let monthIndex = item.get('monthIndex');
                                    return (
                                        <div className="chart-month" key={index}>
                                            {item.get('thisMonthLowestHeight') == -1 ? null : (
                                                <div
                                                    className="lowprice-line"
                                                    style={{
                                                        textIndent: this.state.linePosition[index] + 'px',
                                                        bottom: item.get('thisMonthLowestHeight') + (this.props.flightWay === 'S' ? 16 : 46),
                                                    }}
                                                >
                                                    {item.get('month')}低价之选
                                                </div>
                                            )}
                                            <div className="chart-columns">
                                                {item.get('data').map((day, k) => {
                                                    let isAddAnimated = false;
                                                    if (
                                                        !this.state.isTrendAnimationEnd &&
                                                        !addedAnimatedStatus &&
                                                        this.animationMonthIndex == monthIndex &&
                                                        day.get('lowPrice') &&
                                                        day.get('dayIndex') >= this.firstIndex &&
                                                        day.get('dayIndex') < this.firstIndex + this.pageShowNums
                                                    ) {
                                                        isAddAnimated = true;
                                                        addedAnimatedStatus = true;
                                                    }
                                                    return (
                                                        <div
                                                            className={`column ${day.get('active') ? 'active' : ''}`}
                                                            key={`${k}`}
                                                            onClick={() => this.jumpUrl(day.get('depdate'), day.get('rtdate'))}
                                                            onMouseOver={this.hideSearch}
                                                        >
                                                            <div className="col-val">
                                                                <div
                                                                    className={`c-price ${day.get('lowPrice') ? 'lowprice' : ''}`}
                                                                    style={{
                                                                        bottom: `${(this.state.canShowRealHeight
                                                                            ? day.get('price')
                                                                                ? day.get('height')
                                                                                : item.get('thisMonthLowestHeight') == -1
                                                                                ? 110
                                                                                : item.get('thisMonthLowestHeight')
                                                                            : 110) + 4}px`,
                                                                    }}
                                                                >
                                                                    {!day.get('active') && day.get('lowPrice') && <div className="c-price-desc">低价之选</div>}
                                                                    {day.get('active') && day.get('price') && this.props.flightWay !== 'S' && (
                                                                        <div className="c-price-desc">
                                                                            往返
                                                                            <br />
                                                                            总价
                                                                        </div>
                                                                    )}
                                                                    {day.get('price') ? (
                                                                        <React.Fragment>
                                                                            <dfn>&yen;</dfn>
                                                                            {day.get('price')}
                                                                        </React.Fragment>
                                                                    ) : (
                                                                        <div className="c-no-price">无价格</div>
                                                                    )}
                                                                </div>

                                                                <div
                                                                    className={'c-process' + (isAddAnimated ? ' animated-pulse' : '')}
                                                                    onAnimationEnd={this.onTrendAnimationEnd}
                                                                    style={{
                                                                        height: `${
                                                                            this.state.canShowRealHeight
                                                                                ? day.get('price')
                                                                                    ? day.get('height')
                                                                                    : item.get('thisMonthLowestHeight') == -1
                                                                                    ? 110
                                                                                    : item.get('thisMonthLowestHeight')
                                                                                : 110
                                                                        }px`,
                                                                    }}
                                                                >
                                                                    {day.get('price') ? null : <i className="ico-search2"></i>}
                                                                    <div className="c-search" style={day.get('active') && this.state.showSearch ? { display: 'block' } : {}}>
                                                                        点击查询
                                                                    </div>
                                                                </div>
                                                                <div className="c-week">{day.get('week')}</div>
                                                            </div>
                                                            <div className="col-date">
                                                                <p>{day.get('date')}</p>
                                                                {this.props.flightWay === 'S' ? null : <p>{day.get('dateR')}</p>}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
