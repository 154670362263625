const globalCacheVersion = '2020-11-18'; // 修改后忽略之前所有的缓存
const minute = 60000;
const hour = 60 * minute;
const day = 24 * hour;
const preKey = 'FLT_PC_STORAGE_';

type StorageOpt<T> = {
	key: () => string;
	expires: () => number;
	version: string;
	default?: () => T;
};

export class Storage<T> {
	opt: StorageOpt<T>;
	constructor(opt: StorageOpt<T>) {
		this.opt = opt;
	}

	getKey() {
		return preKey + this.opt.key();
	}

	getCacheVersion() {
		return globalCacheVersion + this.opt.version;
	}

	get(def?: any) {
		const ret = localStorage.getItem(this.getKey());
		const defaultValue = (this.opt.default && this.opt.default()) || def;
		if (!ret) return defaultValue;
		try {
			const retObj = JSON.parse(ret);
			if (
				this._getTime() - retObj.timeStamp > this.opt.expires() || // 过期了
				(retObj.version && retObj.version !== this.getCacheVersion()) // 缓存有变
			) {
				this.remove();

				return defaultValue;
			} else {
				return retObj.data;
			}
		} catch (error) {
			return defaultValue;
		}
	}

	set(data: T) {
		localStorage.setItem(
			this.getKey(),
			JSON.stringify({
				data,
				version: this.getCacheVersion(),
				timeStamp: this._getTime(),
			}),
		);
	}

	setAttr(atrr: any, atrrData: any) {
		const data = this.get({});
		data[atrr] = atrrData;
		this.set(data);
	}

	getAttr(atrr: any, defaultValue: any) {
		return this.get({})[atrr] || defaultValue;
	}

	modify(fn: (obj: any) => any) {
		this.set(fn(this.get()));
	}

	remove() {
		localStorage.removeItem(this.getKey());
	}

	_getTime() {
		return new Date().getTime();
	}
}

// 保险拦截次数记录
export const showContentListStorage = new Storage({
	version: '2022-06-06', // 版本，修改之后可以忽略以前的缓存，特别是数据结构有变化的情况，应修改版本号
	key: () => 'showContentList',
	expires: () => 1 * day,
});
