import React from 'react'

// 获取廉航超经舱权益信息
export const genLccService = price => {
    if (price && price.priceTags && price.priceTags.length) {
        const theLccService = price.priceTags.find(priceTag => priceTag.type === 'LccSuperEconomyService' && priceTag.data)

        return theLccService
    } else {
        return null
    }
}

// 获取廉航超经舱hover文案
export const genLccServicePartHover = (price, segmentNo, sequenceNo) => {
    if (price) {
        const lccServiceWrapper = price.get('lccServiceWrapper')
        if (lccServiceWrapper) {
            const data = lccServiceWrapper.data,
                matchedCurrent = data && data.flightIndexList.find(item => {
                    return (segmentNo === Infinity && sequenceNo === Infinity) || (item.segmentNo === segmentNo && item.sequenceNo === sequenceNo)
                }),
                serviceList = matchedCurrent ? data.serviceList : null

            if (matchedCurrent && serviceList) {
                const ul = (
                    <ul className="lcc-service-remark">
                        {serviceList.map(item => (<li key={item.key}><span className="name">{item.key}：</span> <span className="remark">{item.value}</span></li>))}
                    </ul>
                )

                return ul
            } else {
                return null
            }
        } else {
            return null
        }
    } else {
        return null
    }
}