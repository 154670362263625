export const getIfPrevCondIsHighCabin = prevCond => prevCond.get('cabinEnum').value.isHighCabin

const getFieldOf = (flight, segmentSeq, field) => {
    return flight.getIn(['flightSegments', segmentSeq, field])
}

/**
 * 将日期和时间中的时间部分提取出来，转换成整数，排序时候只取出发/到达时间比较，
 * 日期格式：2018-11-02 14:28:05
 */
export const getTimeOfDate = (dateTimeString) => {
    const match = dateTimeString && dateTimeString.match(/[\d\-]+\s([\d:]+)/)
    if (match && match.length === 2) {
        return +match[1].replace(/:/g, '')
    } else {
        console.error(`bad format of dateTimeString: ${dateTimeString}, required format: 2018-01-01 00:00:00`)
        return NaN
    }
}

export const getStopCount = (isBuildUp, flight, segmentSeq) => {
    return isBuildUp ? (getFieldOf(flight, 0, 'stopCount') + getFieldOf(flight, 1, 'stopCount')) : getFieldOf(flight, segmentSeq, 'stopCount')
}

export const getTransferCount = (isBuildUp, flight, segmentSeq) => {
    return isBuildUp ? (getFieldOf(flight, 0, 'transferCount') + getFieldOf(flight, 1, 'transferCount')) : getFieldOf(flight, segmentSeq, 'transferCount')
}

export const getTransAndStopCount = (isBuildUp, flight, segmentSeq) => {
    return getTransferCount(isBuildUp, flight, segmentSeq) + getStopCount(isBuildUp, flight, segmentSeq)
}