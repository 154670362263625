import moment from 'moment';
import Immutable from 'immutable';
import { CALENDAR_WORK_STATUS as WORK_STATUS } from '../../constants/channel/enum';
import { formatWeek } from '../../utils/dateHelper';

const MOMENT_DATE_FORMAT = 'YYYY-MM-DD';
const lunarInfo = [
  0x04bd8,
  0x04ae0,
  0x0a570,
  0x054d5,
  0x0d260,
  0x0d950,
  0x16554,
  0x056a0,
  0x09ad0,
  0x055d2,
  0x04ae0,
  0x0a5b6,
  0x0a4d0,
  0x0d250,
  0x1d255,
  0x0b540,
  0x0d6a0,
  0x0ada2,
  0x095b0,
  0x14977,
  0x04970,
  0x0a4b0,
  0x0b4b5,
  0x06a50,
  0x06d40,
  0x1ab54,
  0x02b60,
  0x09570,
  0x052f2,
  0x04970,
  0x06566,
  0x0d4a0,
  0x0ea50,
  0x06e95,
  0x05ad0,
  0x02b60,
  0x186e3,
  0x092e0,
  0x1c8d7,
  0x0c950,
  0x0d4a0,
  0x1d8a6,
  0x0b550,
  0x056a0,
  0x1a5b4,
  0x025d0,
  0x092d0,
  0x0d2b2,
  0x0a950,
  0x0b557,
  0x06ca0,
  0x0b550,
  0x15355,
  0x04da0,
  0x0a5d0,
  0x14573,
  0x052d0,
  0x0a9a8,
  0x0e950,
  0x06aa0,
  0x0aea6,
  0x0ab50,
  0x04b60,
  0x0aae4,
  0x0a570,
  0x05260,
  0x0f263,
  0x0d950,
  0x05b57,
  0x056a0,
  0x096d0,
  0x04dd5,
  0x04ad0,
  0x0a4d0,
  0x0d4d4,
  0x0d250,
  0x0d558,
  0x0b540,
  0x0b5a0,
  0x195a6,
  0x095b0,
  0x049b0,
  0x0a974,
  0x0a4b0,
  0x0b27a,
  0x06a50,
  0x06d40,
  0x0af46,
  0x0ab60,
  0x09570,
  0x04af5,
  0x04970,
  0x064b0,
  0x074a3,
  0x0ea50,
  0x06b58,
  0x055c0,
  0x0ab60,
  0x096d5,
  0x092e0,
  0x0c960,
  0x0d954,
  0x0d4a0,
  0x0da50,
  0x07552,
  0x056a0,
  0x0abb7,
  0x025d0,
  0x092d0,
  0x0cab5,
  0x0a950,
  0x0b4a0,
  0x0baa4,
  0x0ad50,
  0x055d9,
  0x04ba0,
  0x0a5b0,
  0x15176,
  0x052b0,
  0x0a930,
  0x07954,
  0x06aa0,
  0x0ad50,
  0x05b52,
  0x04b60,
  0x0a6e6,
  0x0a4e0,
  0x0d260,
  0x0ea65,
  0x0d530,
  0x05aa0,
  0x076a3,
  0x096d0,
  0x04bd7,
  0x04ad0,
  0x0a4d0,
  0x1d0b6,
  0x0d250,
  0x0d520,
  0x0dd45,
  0x0b5a0,
  0x056d0,
  0x055b2,
  0x049b0,
  0x0a577,
  0x0a4b0,
  0x0aa50,
  0x1b255,
  0x06d20,
  0x0ada0,
];

export default class DateUtil {
  /**
   * 获取指定日期的农历日期
   * @param {*日期} date
   */
  static getLunarInfo(date) {
    //传回农历 y 年闰月的天數
    let lYearDays = y => {
      var i,
        sum = 348;
      for (i = 0x8000; i > 0x8; i >>= 1) sum += lunarInfo[y - 1900] & i ? 1 : 0;
      return sum + leapDays(y);
    };

    //传回农历 y 年闰月的天數
    let leapDays = y => {
      if (leapMonth(y)) return lunarInfo[y - 1900] & 0x10000 ? 30 : 29;
      else return 0;
    };

    //传回农历 y 年闰哪个月 1-12 , 沒闰传回 0
    let leapMonth = y => {
      return lunarInfo[y - 1900] & 0xf;
    };

    //传回农历 y 年m月的总天数
    let monthDays = (y, m) => {
      return lunarInfo[y - 1900] & (0x10000 >> m) ? 30 : 29;
    };

    //公历转农历
    let convertToLunar = objDate => {
      let i,
        leap = 0,
        temp = 0;
      let baseDate = moment.utc('1900-01-31');

      objDate = moment.utc(objDate);

      let offset = (objDate - baseDate) / 86400000;

      let result = {};
      result.dayCyl = offset + 40;
      result.monCyl = 14;

      for (i = 1900; i < 2050 && offset > 0; i++) {
        temp = lYearDays(i);
        offset -= temp;
        result.monCyl += 12;
      }

      if (offset < 0) {
        offset += temp;
        i--;
        result.monCyl -= 12;
      }

      result.year = i;
      result.yearCyl = i - 1864;

      leap = leapMonth(i); //闰哪个月
      result.isLeap = false;

      for (i = 1; i < 13 && offset > 0; i++) {
        //闰月
        if (leap > 0 && i == leap + 1 && result.isLeap == false) {
          --i;
          result.isLeap = true;
          temp = leapDays(result.year);
        } else {
          temp = monthDays(result.year, i);
        }

        //解除闰月
        if (result.isLeap == true && i == leap + 1) {
          result.isLeap = false;
        }

        offset -= temp;
        if (result.isLeap == false) {
          result.monCyl++;
        }
      }

      if (offset == 0 && leap > 0 && i == leap + 1)
        if (result.isLeap) {
          result.isLeap = false;
        } else {
          result.isLeap = true;
          --i;
          --result.monCyl;
        }

      if (offset < 0) {
        offset += temp;
        --i;
        --result.monCyl;
      }

      result.month = i;
      result.day = offset + 1;
      return result;
    };

    return convertToLunar(date);
  }

  /**
   * 获取指定日期的农历假期
   * @param {*日期} date
   */
  static getLunarHoliday(date, configHoliday) {
    let lunarDate = DateUtil.getLunarInfo(
      moment.utc(date.format('YYYY-MM-DD')),
    );

    let lunarFestivals = {
      '0101': '春节',
      '0115': '元宵',
      '0505': '端午',
      '0815': '中秋',
    };
    if (configHoliday) {
      lunarFestivals = configHoliday;
    }

    if (lunarDate.month == 12) {
      let eva = lunarInfo[lunarDate.year - 1900] & (0x10000 >> 12) ? 30 : 29;
      eva = '12' + eva;
      lunarFestivals[eva] = '除夕';
    }

    let lday = lunarDate.day | 0;
    let lmonth = lunarDate.month;
    let key =
      '' + (lmonth < 10 ? '0' : '') + lmonth + (lday < 10 ? '0' : '') + lday;

    if (key in lunarFestivals) {
      return lunarFestivals[key];
    }

    return null;
  }

  /**
   * 获取指定日期的阳历假期
   * @param {*日期} date
   */
  static getSolarFestivals(date, configHoliday) {
    let qingMingDay =
      Math.floor((date.year() % 100) * 0.2442 + 4.81) -
      Math.floor((date.year() % 100) / 4);
    let solarFestivals = {
      '0101': '元旦',
      '0501': '劳动',
      '1001': '国庆',
    };
    if (configHoliday) {
      solarFestivals = configHoliday;
    }
    solarFestivals['040' + qingMingDay] = '清明';

    let sday = date.date() | 0;
    let smonth = (date.month() + 1) | 0;
    let key =
      '' + (smonth < 10 ? '0' : '') + smonth + (sday < 10 ? '0' : '') + sday;

    if (key in solarFestivals) {
      return solarFestivals[key];
    }

    return null;
  }

  static getHolidayTips(text) {
    switch (text) {
      case '除夕':
        return 'chuxi';
      case '春节':
        return 'chunjie';
      case '元宵':
        return 'yuanxiao';
      case '清明':
        return 'qingming';
      case '五一':
        return 'wuyi';
      case '端午':
        return 'duanwu';
      case '国庆':
        return 'guoqing';
      case '中秋':
        return 'zhongqiu';
      default:
        return '';
    }
  }

  /**
   * 获取指定日期的星期/今天/明天/后天的图片URL
   * @param {*日期} date
   */
  static getWeekdayTipImg(date) {
    if (date.isValid()) {
      let getToday = () => moment().startOf('day'),
        weekDay = date.isoWeekday(),
        today = getToday(),
        tomorrow = getToday().add(1, 'day'),
        afterTomorrow = getToday().add(2, 'day'),
        weekDayPicArray = ['', 'mon', 'tue', 'wed', 'thu', 'fir', 'sat', 'sun'];

      let pic = weekDayPicArray[weekDay];
      if (date.diff(today) === 0) {
        pic = 'today';
      } else if (date.diff(tomorrow) === 0) {
        pic = 'tomorrow';
      } else if (date.diff(afterTomorrow) === 0) {
        pic = 'aftertomorrow';
      }

      return pic;
    }
  }

  /**
   * 获取指定日期的节假日信息
   * @param {*假期集合} holidays
   * @param {*日期} date
   */
  static getCalendarText(holidays, date) {
    let calender = {
        holidayText: '',
        holidayTipImg: '',
        workStatus: WORK_STATUS.NONE,
      },
      dateStr = moment(date).format(MOMENT_DATE_FORMAT),
      workDays = ['2017-09-30'],
      weekDayPic = DateUtil.getWeekdayTipImg(date),
      today = moment();
    // 已过期的日期应不显示节日名称及休字文案
    if (today.isAfter(date) && !today.isSame(date, 'day')) {
      calender.holidayText = date.date();
      return calender;
    }
    if (holidays) {
      Object.keys(holidays).map(key => {
        let rangeArr = holidays[key].range.split('-');
        if (
          moment(dateStr).isSame(
            moment(
              [key.slice(0, 4), key.slice(4, 6), key.slice(6, 8)].join('-'),
            ),
          )
        ) {
          calender.holidayText = holidays[key].text;
          calender.workStatus = WORK_STATUS.NOT_WORK;
        } else if (
          moment(date)
            .hour(0)
            .isBetween(moment(rangeArr[0]), moment(rangeArr[1]), null, '(]')
        ) {
          calender.workStatus = WORK_STATUS.NOT_WORK;
        }
      });
    }

    if (workDays.indexOf(dateStr) >= 0) {
      calender.workStatus = WORK_STATUS.WORK;
    }

    if (!calender.holidayText && date) {
      let lunarHoliday = DateUtil.getLunarHoliday(date),
        solarFestivals = DateUtil.getSolarFestivals(date);

      calender.holidayText = date.date();

      if (lunarHoliday) {
        calender.holidayText = lunarHoliday;
        calender.workStatus = WORK_STATUS.NOT_WORK;
      }

      if (solarFestivals) {
        calender.holidayText = solarFestivals;
        calender.workStatus = WORK_STATUS.NOT_WORK;
      }
    }

    calender.holidayTipImg =
      DateUtil.getHolidayTips(calender.holidayText) || weekDayPic;

    return calender;
  }

  static CACHED_CALENDAR_TEXT = {};

  /**
   * 获取指定日期的节假日信息
   * @param {*假期集合} holidays
   * @param {*日期} date
   */
  static getCalendarTextWithImmutable(date) {
    const dateStr = moment(date).format(MOMENT_DATE_FORMAT),
      cached = DateUtil.CACHED_CALENDAR_TEXT[dateStr];
    if (cached) {
      return cached;
    } else {
      let calender = {
          holidayText: '',
          holidayTipImg: '',
          workStatus: WORK_STATUS.NONE,
        },
        workDays = ['2017-09-30'],
        weekDayPic = DateUtil.getWeekdayTipImg(date),
        today = moment();

      // 已过期的日期应不显示节日名称及休字文案
      if (today.isAfter(date) && !today.isSame(date, 'day')) {
        calender.holidayText = date.date();
        return (DateUtil.CACHED_CALENDAR_TEXT[dateStr] = Immutable.fromJS(
          calender,
        ));
      }

      if (workDays.indexOf(dateStr) >= 0) {
        calender.workStatus = WORK_STATUS.WORK;
      }

      if (!calender.holidayText && date) {
        let lunarHoliday = DateUtil.getLunarHoliday(date),
          solarFestivals = DateUtil.getSolarFestivals(date);

        calender.holidayText = date.date();

        if (lunarHoliday) {
          calender.holidayText = lunarHoliday;
          calender.workStatus = WORK_STATUS.NOT_WORK;
        }

        if (solarFestivals) {
          calender.holidayText = solarFestivals;
          calender.workStatus = WORK_STATUS.NOT_WORK;
        }
      }

      calender.holidayTipImg =
        DateUtil.getHolidayTips(calender.holidayText) || weekDayPic;

      return (DateUtil.CACHED_CALENDAR_TEXT[dateStr] = Immutable.fromJS(
        calender,
      ));
    }
  }

  static getDateTipText = date => {
    let dateTip = '';
    let salarHolidays = {
      '0101': '元旦',
      '0214': '情人节',
      '0501': '劳动节',
      '0601': '儿童节',
      '0910': '教师节',
      '1001': '国庆节',
      '1225': '圣诞节',
    };
    let lunarHolidays = {
      '0101': '春节',
      '0505': '端午节',
      '0815': '中秋节',
      '1230': '除夕',
    };
    let lunarHoliday = DateUtil.getLunarHoliday(date, lunarHolidays),
      solarFestivals = DateUtil.getSolarFestivals(date, salarHolidays);

    if (moment(date).format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')) {
      dateTip = '今天';
    } else if (
      moment(date).format('YYYY-MM-DD') ==
      moment()
        .add(1, 'day')
        .format('YYYY-MM-DD')
    ) {
      dateTip = '明天';
    } else if (
      moment(date).format('YYYY-MM-DD') ==
      moment()
        .add(2, 'day')
        .format('YYYY-MM-DD')
    ) {
      dateTip = '后天';
    } else if (solarFestivals) {
      dateTip =
        solarFestivals == '清明' ? solarFestivals + '节' : solarFestivals;
    } else if (lunarHoliday) {
      dateTip = lunarHoliday;
    } else {
      dateTip = formatWeek(moment(date));
    }
    return dateTip;
  };
}
