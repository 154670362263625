import React from 'react';
import Cursor from 'immutable-cursor';
import classnames from 'classnames';
import Immutable, { List } from 'immutable';

import Converter from '@/src/sources/list/converter';
import getCabinListTitle from '@/src/sources/list/converter/getCabinListTitle';
import getCabinHoverContent from '@/src/sources/list/converter/getCabinHoverContent';
import { PRICE_TAG_POSITION } from '@/src/constants/list/enums/priceTagPosition';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '@/src/ubt.v2/list';

import { getFlightTransAndStopCount } from '@/src/sources/list/getFragment';
import { getIfScopeDomestic } from '@/src/sources/list/scope';

import AsyncPopupFlightComfortContainer from '@/src/containers/list/result/PopupFlightComfortContainer/async';
import AsyncFlightComfortContainer from '@/src/containers/list/result/flight/ComfortContainer/async';

import ReactToolTip from '@/src/components/Base/reactToolTip';
import Tooltip from '../common/tooltip';
import { PriceFragment } from '../common/priceFragment';
import { BatchGetVisas, FlightSummaryFragment } from '../common/visaFragment';

// isRecommend: 是否是多程超低价推荐
// isShowSegmentNo: 是否展示航程数,往返不展示，多程和超低价推荐展示
const FlightSummary = (props) => {
	let {
		flights,
		reSelect,
		isRecommend,
		isShowSegmentNo,
		penaltyTag,
		prevCond,
		changeComfortPopupIndex,
		comfortPopupIndex,
		globalSwitch,
		passengerRestrictionTag,
		searchCriteriaToken,
		showTongChengRemark,
	} = props;

	let allSegmentList = [],
		flightSegments = flights.get('flightSegments'),
		priceList = flights.get('priceList'),
		cleanItineraryId = flights.get('itineraryId').replace(/[,\|]/gi, ''),
		tongChengFlightEnum = flights.get('tongChengFlightEnum'),
		flightInfos = [];

	// 航程是否小于当前选中航程（有时候batchsearch会把所有程都返回）
	const getIfTheSegIsInCurrentSeg = (theSegIndex) => {
		return theSegIndex < props.currentSegmentSeq;
	};
	flightSegments.forEach((item, index) => {
		if (isRecommend || getIfTheSegIsInCurrentSeg(index)) {
			allSegmentList.push(item);
			flightInfos.push({
				tongChengFlightEnum,
				segment: item,
				currentSegmentSeq: index,
				cleanItineraryId,
				priceList,
				showTongChengRemark,
			});
		}
	});

	const getFlightsListContent = () => {
		return flightSegments.map((segment, index) => {
			if (!isRecommend && !getIfTheSegIsInCurrentSeg(index)) {
				return null;
			}
			const crossDays = segment.get('crossDays'),
				getCrossdayContent = ({ arrivalDateTime, crossDays }) => {
					let date = Converter.removeSecond(arrivalDateTime);
					return getIfScopeDomestic()
						? `到达时间为第${crossDays + 1}天, ${date}`
						: `抵达时间为当地时间：${date}`;
				};
			const genCrossDaysTooltip = () => {
				let content = getCrossdayContent({
					arrivalDateTime: segment.get('arrivalDateTime'),
					crossDays,
				});
				return (
					<ReactToolTip
						key={`mult-crossDays-${cleanItineraryId}-${index}`}
						parentId={`mult_crossDays${cleanItineraryId}_${index}`}
						toolTipContent={<Tooltip content={content} />}
					/>
				);
			};

			let flightListCursor = Cursor.from(segment, 'flightList', (newSeg) => (segment = newSeg)),
				getFlightListOf = (prop) =>
					flightListCursor
						.deref()
						.map((f) => f.get(prop))
						.reduce((prev, next) => prev.concat(next), List([]))
						.toSet()
						.toList(),
				airlineList = getFlightListOf('marketAirlineCode'),
				flightNoList = getFlightListOf('flightNo'),
				stopTextList = [],
				transferAndStopCount = segment.get('transferAndStopCount'),
				transferCount = segment.get('transferCount'),
				addToStopTextList = (tag, cityName) => {
					stopTextList.push({
						tag: tag,
						cityName: cityName,
					});
				};

			let airlineCount = airlineList.size,
				flightNoCount = flightNoList.size,
				currentSegmentSeq = index;

			flightListCursor.deref().map((flightList, index) => {
				let innerStopList = flightList.get('stopList');
				if (innerStopList.size) {
					innerStopList.forEach((innerStop) => {
						addToStopTextList('停', innerStop.get('cityName'));
					});
				}

				if (index < flightListCursor.deref().size - 1) {
					addToStopTextList('转', flightList.get('arrivalCityName'));
				}
			});
			let flightListOfSegment = segment.get('flightList');

			let getFlightDetail = () =>
				FlightSummaryFragment({
					onFlightDetailClick: () => {
						BatchGetVisas({
							flightDetailInfos: flightInfos,
							onPopupFlightDetail: props.onPopupFlightDetail,
							currentSegmentSeq,
						});
					},
				});

			return (
				<div className="flt-info" key={index}>
					{isShowSegmentNo ? <span className="num">{index + 1}</span> : null}

					<div className="flt-airline">
						{segment.get('airlineName')}

						<UbtBoundary
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
								LIST_UBT_KEY_TYPES.FLIGHT_COMFORT_HOVER
							)}
						>
							<div className="plane" id={`comfort${cleanItineraryId}-${index}`}>
								{airlineCount === 1 ? (
									flightListOfSegment.map((flight, index) => (
										<AsyncFlightComfortContainer
											key={flight.get('flightNo')}
											tongChengFlightEnum={tongChengFlightEnum}
											onMouseOver={() => changeComfortPopupIndex(index)}
											flight={flight}
										/>
									))
								) : (
									<div className="plane">
										<span className="plane-multi">{airlineCount}个航司</span>
										<span className="plane-multi">{flightNoCount}程航班</span>
									</div>
								)}
							</div>
						</UbtBoundary>
					</div>

					<div className="flt-detail">
						<div className="depart-box">
							<div className="flt-date">
								{Converter.removeYearAndSecond(segment.get('departureDateTime'))}
							</div>
							<div className="flt-airport">
								{segment.get('departureAirportName')}
								<span className="terminal" id={`departureTerminal${cleanItineraryId}-${index}`}>
									{segment.get('departureTerminal')}
								</span>
							</div>
						</div>

						<UbtBoundary
							skip={transferAndStopCount === 0}
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
								LIST_UBT_KEY_TYPES.FLIGHT_INFO_HOVER
							)}
						>
							<div className="arrow-box">
								{getFlightTransAndStopCount(tongChengFlightEnum, transferCount, transferAndStopCount)}
								<div className="transfer-info-group">
									<div className="horizontal-center">
										{stopTextList.map((stop, stopIndex) => (
											<div
												key={`stop_${stopIndex}_${cleanItineraryId}`}
												className="transfer-info"
											>
												<i>{stop.tag}</i>
												{stop.cityName}
											</div>
										))}
									</div>
								</div>
							</div>
						</UbtBoundary>

						<div className="arrive-box">
							<div className="flt-date">
								{Converter.removeYearAndSecond(segment.get('arrivalDateTime'))}
							</div>
							<div className="flt-airport">
								{segment.get('arrivalAirportName')}
								<span className="terminal" id={`arrivalTerminal${cleanItineraryId}-${index}`}>
									{segment.get('arrivalTerminal')}
								</span>
							</div>
							{crossDays ? (
								<React.Fragment>
									<span id={`mult_crossDays${cleanItineraryId}_${index}`} className="day">
										{crossDays > 0 ? '+' : ''}
										{crossDays}天
									</span>
									{genCrossDaysTooltip()}
								</React.Fragment>
							) : null}
						</div>
					</div>

					{!props.isRecommend ? getFlightDetail() : null}

					{props.isRecommend ? (
						<div className="flt-extra">
							<div className="flt-consuming">
								{Converter.formatMinAsDayHourMin(segment.get('duration'))}
							</div>
							{getFlightDetail()}
						</div>
					) : null}

					{!props.isRecommend ? (
						<UbtBoundary
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.SELECTED_FLIGHTS,
								LIST_UBT_KEY_TYPES.SELECTED_FLIGHTS,
								prevCond.get('flightWay'),
								cleanItineraryId,
								index
							)}
						>
							<div
								className="flt-edit"
								u_key="modify_flight"
								u_remark={`修改已选航班[itineraryId:${cleanItineraryId}]`}
								onClick={() => reSelect(index)}
							>
								<a href="javascript:;" className="edit">
									修改航班
								</a>
							</div>
						</UbtBoundary>
					) : null}
					<ReactToolTip
						key={`comfort${cleanItineraryId}-${index}`}
						parentId={`comfort${cleanItineraryId}-${index}`}
						toolTipContent={
							<AsyncPopupFlightComfortContainer
								changeComfortPopupIndex={changeComfortPopupIndex}
								key={`comfort${cleanItineraryId}-${index}`}
								segment={segment}
								priceList={priceList}
								currentSegmentSeq={currentSegmentSeq}
								comfortPopupIndex={comfortPopupIndex}
								cacheKey={`comfort${cleanItineraryId}-${index}`}
							/>
						}
					/>
					{segment.get('departureTerminal') ? (
						<ReactToolTip
							key={`departureTerminal-${cleanItineraryId}`}
							parentId={`departureTerminal${cleanItineraryId}-${index}`}
							toolTipContent={
								<Tooltip
									content={
										segment.get('departureTerminal') == 'I'
											? '国际航站楼'
											: `${segment.get('departureTerminal')}号航站楼`
									}
								/>
							}
						/>
					) : null}
					{segment.get('arrivalTerminal') ? (
						<ReactToolTip
							key={`arrivalTerminal-${cleanItineraryId}`}
							parentId={`arrivalTerminal${cleanItineraryId}-${index}`}
							toolTipContent={
								<Tooltip
									content={
										segment.get('arrivalTerminal') == 'I'
											? '国际航站楼'
											: `${segment.get('arrivalTerminal')}号航站楼`
									}
								/>
							}
						/>
					) : null}
				</div>
			);
		});
	};

	const getFlightsListInfo = () => {
		let price = priceList.get(0),
			productTypeWrapper = price.get('productTypeEnum'),
			priceTagEnums = price.get('priceTagEnums'),
			tagEnumsOfLeft = priceTagEnums.filter((wrapper) => wrapper.tag.value.position === PRICE_TAG_POSITION.LEFT),
			tagEnumsOfMiddle = priceTagEnums.filter(
				(wrapper) => wrapper.tag.value.position === PRICE_TAG_POSITION.MIDDLE
			),
			cabinEnumList = isRecommend
				? price.get('allSegsCabinsEnum').reduce((prev, next) => prev.concat(next), Immutable.List())
				: price.get('cabinEnumList'),
			isDifferentCabin = cabinEnumList.some((cabin) => prevCond.get('cabin').includes(cabin.key) === false),
			manyCabins = cabinEnumList.toSet().size > 1,
			lccServiceWrapper = price.get('lccServiceWrapper');
		const priceFragment = new PriceFragment({
				price,
				index: -1,
				prevCond,
				allSegmentList,
				penaltyTag,
				cleanItineraryId,
				globalSwitch,
				searchCriteriaToken,
			}),
			seatTypeId = `seatType_${cleanItineraryId}`;

		return (
			<div className="recmd-extra-box">
				<div className="tag-advertisement">
					{tagEnumsOfLeft.map((wrapper) => wrapper.tag.value.getLabel(wrapper.tag.key))}
					{tagEnumsOfLeft.map((wrapper) => (
						<ReactToolTip
							parentId={wrapper.tag.key}
							key={wrapper.tag.key}
							toolTipContent={wrapper.tag.value.getTooltip(wrapper, prevCond, price)}
						/>
					))}
				</div>
				<div className="seat-type">
					<span
						id={seatTypeId}
						className={classnames({
							type: true,
							highlight: isDifferentCabin || manyCabins,
							abbr: manyCabins,
							'lcc-service-tag': lccServiceWrapper,
						})}
					>
						{getCabinListTitle(cabinEnumList, flightSegments, lccServiceWrapper)}
					</span>
					{manyCabins || lccServiceWrapper ? (
						<ReactToolTip
							parentId={seatTypeId}
							toolTipContent={getCabinHoverContent(
								flightSegments,
								cabinEnumList,
								price,
								manyCabins
							)}
						/>
					) : null}
					{productTypeWrapper.tag.value.isNone ? null : (
						<div className="special">
							{productTypeWrapper.tag.value.getLabel(
								productTypeWrapper,
								`${productTypeWrapper.tag.key}_${cleanItineraryId}_tag`
							)}
							<ReactToolTip
								key={`${productTypeWrapper.tag.key}_${cleanItineraryId}_tag`}
								parentId={`${productTypeWrapper.tag.key}_${cleanItineraryId}_tag`}
								toolTipContent={productTypeWrapper.tag.value.getLabelTooltip(productTypeWrapper, price)}
							/>
						</div>
					)}
				</div>
				<div className="conditions-v3" style={{ display: 'inline' }}>
					<div className="seat-info">
						{priceFragment.PenaltyFragment}
						<UbtBoundary
							tracker={ListUbtGenerator(
								LIST_UBT_GROUP_TYPES.UPLOAD_NOW_MOUSE_ENTER,
								LIST_UBT_KEY_TYPES.NGS_SCORE_HOVER
							)}
						>
							{priceFragment.NgsFragment}
						</UbtBoundary>
						{priceFragment.InvoiceFragment}
						{passengerRestrictionTag}
					</div>
				</div>

				<div className="seat-tags">
					{tagEnumsOfMiddle.map((wrapper, index) => [
						<span
							key={`${index}_0`}
							id={`${wrapper.tag.key}_${index}`}
							className="tag"
							dangerouslySetInnerHTML={{
								__html: wrapper.tag.value.getLabel(wrapper),
							}}
						></span>,
						<ReactToolTip
							key={`${index}_1`}
							parentId={`${wrapper.tag.key}_${index}`}
							toolTipContent={wrapper.tag.value.getTooltip(wrapper, prevCond)}
						/>,
					])}
				</div>
			</div>
		);
	};

	return (
		<div className="flt-selected-list">
			{getFlightsListContent()}
			{props.isRecommend ? getFlightsListInfo() : null}
		</div>
	);
};

export default React.memo(FlightSummary);
