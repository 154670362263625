import CONFIG_URL from "../../constants/common/api"
import { fetchGet } from "../../actions/common"
import { UbtHandler } from "../../ubt.v2/handler"

const switchOpenMap = {}
const promiseInQueneList = {}

// 异步获取开关状态，一般是产品要求特定场合才请求AB分流
export const getIfSwitchOpenAsync = switchName => {
    if (switchName) {
        let cachedValue = switchOpenMap[switchName]
        if (typeof cachedValue !== 'undefined') {
            return Promise.resolve(cachedValue)
        } else {
            let inQuenePromise = promiseInQueneList[switchName]
            if (inQuenePromise) {
                return inQuenePromise
            } else {
                promiseInQueneList[switchName] = inQuenePromise = fetchGet(`${CONFIG_URL.getSwitchOpened}?switchName=${switchName}`)
                    .then(res => res && res.data || {})
                    .then(json => {
                        const opened = !!json.data
                        switchOpenMap[switchName] = opened
                        delete promiseInQueneList[switchName]

                        UbtHandler.onUploadData('c_get_if_switch_open', { switchName, opened })

                        return opened
                    })
                    .catch(err => {
                        delete promiseInQueneList[switchName]
                        UbtHandler.onUploadData('c_get_if_switch_open_has_error', { switchName, err })

                        return false
                    })

                return inQuenePromise
            }
        }

    } else {
        return Promise.resolve(false)
    }
}
