import React, { FC } from 'react';
import { connect } from 'react-redux';
import { onOpenAdPop, onCloseAdPop } from '../../../actions/list/notice';
import { getAllAds } from './adSelector';
import PopDialog from '../common/PopDialog';

import { AdListProps } from './types';

const getPopContents = (ruleId, scenes, onConfirm) => {
	if (scenes.includes('13')) {
		const airportDiscountConfigs = window.GlobalConfigs.airportDiscountConfigs || [];
		const apDiscountConfig = airportDiscountConfigs.find((item) => item?.ruleId.includes(ruleId || ''));
		const { ruleList, activityTitle } = apDiscountConfig;
		return (
			<div className="ad-container">
				<i></i>
				<i className="close-icon" onClick={onConfirm}></i>
				<div className="pop-title">{activityTitle}</div>
				<div className="ad-body">
					{ruleList.map((rule, index) => {
						return (
							<div key={index} className="rule-item">
								<div className="rule-title">{rule.title}</div>
								<div className="rule-content" dangerouslySetInnerHTML={{ __html: rule.content }}></div>
							</div>
						);
					})}
				</div>
			</div>
		);
	}
	return null;
};

export const Ad: FC<AdListProps> = ({ adList, isShow, handleAdPopup, onConfirm }) => {
	if (!adList || !adList.length) return null;

	return (
		<>
			{adList.map((item) => {
				const { id, content, scenes } = item;
				return (
					<div key={id}>
						<div className="ad-box">
							<img className="ad-img" src={content} onClick={handleAdPopup} alt="" />
						</div>
						{isShow ? <PopDialog contents={getPopContents(id, scenes, onConfirm)} /> : null}
					</div>
				);
			})}
		</>
	);
};

const mapStateToProps = (state) => ({
	adList: getAllAds(state).toJS(),
	isShow: state.getIn(['list', 'ads', 'adPopupShow']),
});

const mapDispatchToProps = (dispatch) => ({
	handleAdPopup: () => {
		dispatch(onOpenAdPop());
	},
	onConfirm: () => {
		dispatch(onCloseAdPop());
	},
});

export default connect(mapStateToProps, mapDispatchToProps)(Ad);
