import React from 'react'

const styles = {
    tooltipMargin: {
        verticalAlign: 'top',
        textAlign: 'left'
    }
}

export default class FreeVipLounge extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        const { content } = this.props

        return (
            <div className={'tooltip toopltip-lounge'} style={styles.tooltipMargin}>
                <img src="//pic.c-ctrip.com/flight_intl/temp/tooltip_lounge.jpg" width="418" height="182" alt=""/>
                <div className="tooltip-content">
                    <span className="tag-green">免费WiFi</span>
                    <span className="tag-green">舒适宽敞</span>
                    <span className="tag-green">美味小食</span>
                    <span className="tag-green">饮料畅饮</span>
                    <p>{content}</p>
                </div>
            </div>
        )
    }
}
