import React, { FC } from 'react';
import { List } from 'immutable';

const groupNoticeByLevel = (notices: List<any>) => {
	return notices.reduce((acc, current) => {
		let idx;
		if (current.get('title')) {
			if (current.get('contentRank') > 0) {
				idx = current.get('contentRank');
			} else {
				idx = current.get('noticeLevel');
			}
			if (!acc[idx]) {
				acc[idx] = [];
			}
			acc[idx].push(current.toJS());
		}
		return acc;
	}, {});
};

export const PopContents: FC<{ notices: List<any>; onConfirm: () => void }> = ({ notices, onConfirm }) => {
	const groupNotice = groupNoticeByLevel(notices);
	return (
		<div className="notice_pop">
			<i className="notice_close_icon" onClick={onConfirm}></i>
			<div className="notice_title warning">出行提醒</div>
			<div className="notice_maincontent">
				{Object.values(groupNotice)?.map((notice) => {
					const levelToStr = ['lv1', 'lv2', 'lv3'];
					// @ts-expect-error
					const level = levelToStr[parseInt(notice[0]?.contentRank)];
					const imgSrc =
						{
							lv1: 'https://pic.c-ctrip.com/flight_intl/book/svgs/red.svg',
							lv2: 'https://pic.c-ctrip.com/flight_intl/book/svgs/orange.svg',
							lv3: 'https://pic.c-ctrip.com/flight_intl/book/svgs/green.svg'
						}[level] || '';
					const txtColor = { lv1: '#f5190a', lv2: '#ff7700', lv3: '#00b87a' }[level] || '';
					// @ts-expect-error
					return notice?.map((message, index) => (
						<div key={index} className="notice_subcontent">
							<div className="notice_header">
								<div className={`notice_header_title ${level}`}>
									<img style={{ height: '14.67px', width: '14.67px' }} src={imgSrc || ''} />
									<div style={{ marginLeft: '4px' }}>{message.title}</div>
								</div>
								{message.formatCtripUpdateTime && (
									<div className="notice_header_title">
										<div style={{ color: '#9999' }}>更新日期: {message.formatCtripUpdateTime}</div>
										<div style={{ color: 'red', marginLeft: '2px' }}>
											{message.recently ? '最近' : null}
										</div>
									</div>
								)}
							</div>
							<div
								className="notice_content"
								dangerouslySetInnerHTML={{
									__html:
										((message?.formatContent ||
										'')
											.replace(/<HL>/g, `<a style="color: ${txtColor}">`)
											.replace(/<\/HL>/g, '</a>') ||
										message.content).replace(/\n/g, '<br/>')
								}}
							></div>
							<div style={{ color: 'rgb(0, 0, 0, 0.25)', fontSize: '14px' }}>
								{message.source ? `(来源: ${message.source})` : null}
							</div>
						</div>
					));
				})}
				<div>以上信息仅供参考</div>
			</div>
			<div className="notice-footer">
				<div className="btn-group" onClick={onConfirm}>
					知道了
				</div>
			</div>
		</div>
	);
};
