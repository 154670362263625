import moment from 'moment';
import { Ubt, ubtGlobalState } from './index';
import store from '../stores/channel';
import { sendTrace } from '../sources/common/lightUbt';

const getSourceType = (poiCityInfo) => {
	if (
		poiCityInfo &&
		poiCityInfo.get('source') &&
		(poiCityInfo.get('source') == '1' || poiCityInfo.get('source') == '2')
	) {
		return poiCityInfo.get('source');
	}
	if (poiCityInfo && poiCityInfo.get && poiCityInfo.get('POIID')) {
		return '2';
	}
	return '3';
};

export class ChannelUbt extends Ubt {
	constructor(props) {
		super(props);
		// 埋点对象
		// 通用点击类
		this.commonClickUbt = {};
		// 通用hover类
		this.commonHoverUbt = {};
		// 埋点key
		this.COMMON_CLICK_UBT_KEY = 'flt_oversea_search_home_click';
		this.CHANNEL_BASIC_KEY = 'flt_oversea_homepage_online_basic';
		this.HOT_COUNTRY_SEARCH = 'c_hotsearch';
	}
	// 热门国家埋点 TODO
	reportHotCountryToApi(countryName, countrySource) {
		let flightWay = store.getState().getIn(['channel', 'search', 'currentFlightWay']),
			selectedCity = store.getState().getIn(['channel', 'poi', 'selectedCity']);
		// TODO
		var content = {
			content: {
				searchway: countrySource,
				flightway: flightWay == 'OW' ? 'S' : 'D',
				from:
					flightWay == 'OW'
						? selectedCity.get('owDCity')
							? selectedCity.getIn(['owDCity', 'Name'])
							: ''
						: selectedCity.get('rtDCity')
						? selectedCity.getIn(['rtDCity', 'Name'])
						: '', //this._parseCityName(this.$DCity.value()),
				to: countryName, //this.$ACity.value(),
				searchdate: store.getState().getIn(['channel', 'hotCountry', 'inputString']),
			},
		};
		this.reportToApi(content, this.HOT_COUNTRY_SEARCH);
	}
	cityToJSON(city) {
		city = (city + '').split('|'); //city ==== "Shanghai|上海(SHA)|2|SHA|480"
		return {
			CityID: city[2] || '',
			CityCode: city[3] || '',
			CityName: city[1] || '',
		};
	}
	//上报通用埋点
	reportCommonBasicToApi(ops) {
		this.reportToApi(this.getCommonBasicUbtData(ops), this.CHANNEL_BASIC_KEY);
	}
	getCommonBasicUbtData(_ops) {
		let channelState = store.getState().get('channel'),
			currentFlightWay = channelState.getIn(['search', 'currentFlightWay']),
			owDDate = channelState.getIn(['search', 'owDDate']),
			rtDDate = channelState.getIn(['search', 'rtDDate']),
			rtEndDate = channelState.getIn(['search', 'rtEndDate']),
			getMtSegList = channelState.getIn(['search', 'getMtSegList']),
			selectedCity = channelState.getIn(['poi', 'selectedCity']),
			owDCity = selectedCity.get('owDCity'),
			owACity = selectedCity.get('owACity'),
			rtDCity = selectedCity.get('rtDCity'),
			rtACity = selectedCity.get('rtACity'),
			isSearchMapCountry = channelState.getIn(['hotDistrict', 'isDistrictSearch']),
			mapCountryInfo = null;
		if (isSearchMapCountry) {
			mapCountryInfo = {
				CityID: '',
				CityCode: channelState.getIn(['hotDistrict', 'arrivalDistrict']).areaCode,
				CityName: channelState.getIn(['hotDistrict', 'arrivalDistrict']).areaName,
				source: channelState.getIn(['hotDistrict', 'arrivalDistrict']).source
					? channelState.getIn(['hotDistrict', 'arrivalDistrict']).source
					: '3',
			};
		}
		let mtSegList = getMtSegList();
		let ret = {
			triggertype: 'Action',
			iscountry: isSearchMapCountry ? 'Y' : 'N',
			direct: channelState.getIn(['search', 'directflight']) ? 'Y' : 'N',
			class: (channelState.getIn(['search', 'classGrade']) || '').replace('_', ''),
			airline: channelState.getIn(['airline', 'airlineSelected']),
			passengertype: {
				adult: channelState.getIn(['search', 'adtCount']),
				children: channelState.getIn(['search', 'chiCount']),
				infant: channelState.getIn(['search', 'infCount']),
			},
			FlightClass: 'I', //渠道 国际机票(I)
			FlightWay: 'S', //航程类型
			Sequence: [], //具体的航班信息
		};
		switch (currentFlightWay) {
			case 'OW':
				ret.FlightWay = 'S';
				if (isSearchMapCountry) {
					ret.Sequence.push({
						From: {
							CityID: owDCity.get('CityId'),
							CityCode: owDCity.get('Code'),
							CityName: owDCity.get('Name'),
							source: getSourceType(owDCity),
						},
						StartTime: moment(owDDate).format('YYYY-MM-DD'),
						To: mapCountryInfo,
					});
				} else {
					ret.Sequence.push({
						From: {
							CityID: owDCity.get('CityId'),
							CityCode: owDCity.get('Code'),
							CityName: owDCity.get('Name'),
							source: getSourceType(owDCity),
						},
						StartTime: moment(owDDate).format('YYYY-MM-DD'),
						To: {
							CityID: owACity.get('CityId'),
							CityCode: owACity.get('Code'),
							CityName: owACity.get('Name'),
							source: getSourceType(owACity),
						},
					});
				}

				break;
			case 'RT':
				ret.FlightWay = 'D';
				if (isSearchMapCountry) {
					ret.Sequence.push({
						From: {
							CityID: rtDCity.get('CityId'),
							CityCode: rtDCity.get('Code'),
							CityName: rtDCity.get('Name'),
							source: getSourceType(rtDCity),
						},
						StartTime: moment(rtDDate).format('YYYY-MM-DD'),
						To: mapCountryInfo,
					});
					ret.Sequence.push({
						From: mapCountryInfo,
						StartTime: moment(rtEndDate).format('YYYY-MM-DD'),
						To: {
							CityID: rtDCity.get('CityId'),
							CityCode: rtDCity.get('Code'),
							CityName: rtDCity.get('Name'),
							source: getSourceType(rtDCity),
						},
					});
				} else {
					ret.Sequence.push({
						From: {
							CityID: rtDCity.get('CityId'),
							CityCode: rtDCity.get('Code'),
							CityName: rtDCity.get('Name'),
							source: getSourceType(rtDCity),
						},
						StartTime: moment(rtDDate).format('YYYY-MM-DD'),
						To: {
							CityID: rtACity.get('CityId'),
							CityCode: rtACity.get('Code'),
							CityName: rtACity.get('Name'),
							source: getSourceType(rtACity),
						},
					});
					ret.Sequence.push({
						From: {
							CityID: rtACity.get('CityId'),
							CityCode: rtACity.get('Code'),
							CityName: rtACity.get('Name'),
							source: getSourceType(rtACity),
						},
						StartTime: moment(rtEndDate).format('YYYY-MM-DD'),
						To: {
							CityID: rtDCity.get('CityId'),
							CityCode: rtDCity.get('Code'),
							CityName: rtDCity.get('Name'),
							source: getSourceType(rtDCity),
						},
					});
				}

				break;
			case 'MT':
				ret.FlightWay = 'M';
				mtSegList.forEach((seg, index) => {
					index = parseInt(index);
					if (selectedCity.get(`mtDCity${index + 1}`) && selectedCity.get(`mtACity${index + 1}`)) {
						ret.Sequence.push({
							From: {
								CityID: selectedCity.getIn([`mtDCity${index + 1}`, 'CityId']),
								CityCode: selectedCity.getIn([`mtDCity${index + 1}`, 'Code']),
								CityName: seg.get('dCityText'),
								source: getSourceType(selectedCity.getIn([`mtDCity${index + 1}`])),
							},
							StartTime: moment(seg.get('StartDate')).format('YYYY-MM-DD'),
							To: {
								CityID: selectedCity.getIn([`mtACity${index + 1}`, 'CityId']),
								CityCode: selectedCity.getIn([`mtACity${index + 1}`, 'Code']),
								CityName: seg.get('aCityText'),
								source: getSourceType(selectedCity.getIn([`mtACity${index + 1}`])),
							},
						});
					}
				});
				break;
			default:
				break;
		}
		let poiNoResult = ubtGlobalState.poi.poiNoResult;
		if (poiNoResult && poiNoResult.length > 0) {
			//把POI无结果埋点加上
			ret.POISearch = poiNoResult;
			ubtGlobalState.poi.poiNoResult = [];
		}

		return JSON.stringify(ret);
	}
	reportCityPanelUbt(data) {
		let flightWay = store.getState().getIn(['channel', 'search', 'currentFlightWay']);
		if (flightWay == 'MT') {
			return;
		}
		reportUbtTracklog('o_flt_citylist', data);
		sendTrace('o_flt_citylist', data);
	}
}

export default new ChannelUbt();
