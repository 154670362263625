import { fetchGet } from '../common'
import * as types from '../../constants/list/actionTypes/hotCountry.js'
import CONFIG_URL from '../../constants/list/api'

//获取热门国家列表
export const fetchHotCountrys = () => {
    return fetchGet(CONFIG_URL.getHotCountryListUrl)
        .then(res => res && res.data || {})
}

//更改热门国家到达国家
export const genChangeArrivalCountry = arrivalCountry => ({ type: types.CHANGE_ARRIVAL_COUNTRY, arrivalCountry })
//切换至热门国家发现低价状态
export const genSwitchHotSearch = isHotSearch => ({ type: types.SWITCH_HOT_SEARCH, isHotSearch })
//切换时间选择器类型
export const genSwitchFreePicker = () => ({ type: types.SWITCH_FREE_PICKER })
//更改日历当前月份
export const genChangeHotCountryMonth = (currentMonthIndex) => ({ type: types.CHANGE_CURRENT_MONTH, currentMonthIndex })
//更改日历选中日期
export const genChangeSelectedDay = (selectedDay) => ({ type: types.CHANGE_SELECTED_DAY, selectedDay })
//修改浮动天数
export const genChangeFloatDays = (floatDays) => ({ type: types.CHANGE_FLOAT_DAYS, floatDays })
//修改出游天数
export const genChangeStayDays = (stayDays) => ({ type: types.CHANGE_STAY_DAYS, stayDays })
//修改选中时间范围信息
export const genChangeSelectedTimeItem = (selectedTimeItems) => ({ type: types.CHANGE_SELECTED_TIME_ITEM, selectedTimeItems })
//修改国家来源
export const genChangeCountrySource = (countrySource) => ({ type: types.CHANGE_COUNRTY_SOURCE, countrySource })
//修改input内容
export const genChangeInputString = (inputString) => ({ type: types.CHANGE_INPUT_STRING, inputString })
