import React from 'react';
import classnames from 'classnames';
import { genLccServicePartHover } from '../../common/genLccService';

const genCabinHoverTooltipOfSeatsFragment = (price, item, index, cabinClassName, getCabinTitleFromItem) => {
	const lccServiceWrapper = price.get('lccServiceWrapper'),
		{ segmentNo, sequenceNo } = item,
		isLccServiceOfCurrentSequence =
			lccServiceWrapper &&
			lccServiceWrapper.data &&
			lccServiceWrapper.data.flightIndexList &&
			lccServiceWrapper.data.flightIndexList.find(
				(index) =>
					(segmentNo === Infinity && sequenceNo === Infinity) ||
					(segmentNo === index.segmentNo && sequenceNo === index.sequenceNo)
			);

	return (
		<div className={classnames({ 'seat-type': true, 'lcc-service-cabin': isLccServiceOfCurrentSequence })}>
			<span className={cabinClassName}>{getCabinTitleFromItem(item, index)}</span>
			{genLccServicePartHover(price, segmentNo, sequenceNo)}
		</div>
	);
};

export default genCabinHoverTooltipOfSeatsFragment;
