import { SPLIT_ROUND_INCLUDE_KEYS } from "./splitRoundIncludeKeys"

export const calcSplitRoundSelector = (splitRoundFlightsSwitch, selector, stateAndProp, calcWhenTurnOff) => {
    if (splitRoundFlightsSwitch || calcWhenTurnOff) {
        if (typeof selector !== 'function') {
            throw `selector is supposed to be function, but got: ${typeof selector}`
        }

        const uniformStateAndProp = Array.isArray(stateAndProp) ? stateAndProp : [stateAndProp]
        const rawState = uniformStateAndProp[0]
        const rawProp = uniformStateAndProp[1]
        let stateAfterReset = rawState

        if (splitRoundFlightsSwitch) {
            SPLIT_ROUND_INCLUDE_KEYS.forEach(key => {
                stateAfterReset = stateAfterReset.setIn(['list', key], rawState.getIn(['list', 'splitRound', key]))
            })
        }
        return selector(...([stateAfterReset, rawProp].filter(Boolean)))
    } else {
        return null
    }
}
