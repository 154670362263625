import qs from 'qs';
import { Base64 } from 'js-base64';

/**
 * 向列表页url后携带json中写入参数信息,返回重写参数后的新url
 * @param {*要写入的参数列表} params
 * @param {*当前URL} url
 */
const addParamsToListUrl = (params: Record<string, string>, url: string) => {
	let urlParamsString = qs.parse(url.split('?')[1]).params;
	if (urlParamsString) {
		let urlParams = '';
		try {
			urlParams = JSON.parse(Base64.decode(urlParamsString));
		} catch (err) {
			return url;
		}
		return url.replace(
			/&params=(.)+/,
			'&params=' + Base64.encodeURI(JSON.stringify(Object.assign(urlParams, params)))
		);
	}
	let urlSplitByHash = url.split('#');
	return (
		urlSplitByHash[0] +
		'&params=' +
		Base64.encodeURI(JSON.stringify(params)) +
		`${urlSplitByHash.length > 1 ? '#' + urlSplitByHash[1] : ''}`
	);
};

export default addParamsToListUrl;
