import moment from 'moment';
import { ChannelUbt } from './channelUbt';
import { ubtGlobalState } from './index';

// poi埋点
export class poiUbt extends ChannelUbt {
	constructor(props) {
		super(props);
		this.POI_REQUEST_KEY = 'fltintl_poi_request';
		this.POI_SELECT_KEY = 'fltintl.poi';
		this.NEW_POI_SELECT_KEY = 'O_FLT_Citysearch';
		this.SEARCH_BOX_KEY = 'c_search_box_select';
		this.SEARCH_BOX_KEY_CLICK = 'c_search_box_click';
		this.NAME_ALIAS_SEARCH = 'c_namealias_search';
		this.userinput = '';
		this.requestTime = '';
		this.successfulTimeControl = true;
		this.noResultTimeControl = true;

		this.ubtObj = { totalIndex: 0 };
	}

	//poi无结果记录
	//0是有结果、1是POI接口报错、3是查询无结果
	//格式："POISearch":[{"InputCity":"fdsffsfsd","POIstatus":3},{"InputCity":"fdsffsfsdf","POIstatus":3}]
	markPoiNoResult(userInput) {
		this.userinput = userInput;
		if (!this.noResultTimeControl) {
			return;
		}
		this.noResultTimeControl = false;
		setTimeout(() => {
			let that = this;
			let obj = {
				InputCity: that.userinput,
				POIstatus: 3,
			};
			this.reportToApi(obj, this.POI_REQUEST_KEY);
			ubtGlobalState.poi.poiNoResult = [...ubtGlobalState.poi.poiNoResult, obj];
			this.noResultTimeControl = true;
		}, 500);
	}

	//poi请求成功上报
	poiRequestSuccess(userInput) {
		if (userInput == '') {
			return;
		}
		this.userinput = userInput;
		if (!this.successfulTimeControl) {
			return;
		}
		this.successfulTimeControl = false;
		setTimeout(() => {
			let that = this;
			let obj = {
				Input: that.userinput,
				POIstatus: 0,
				ServerRequestTime: moment().valueOf(),
			};
			this.requestTime = obj.ServerRequestTime;
			this.reportToApi(obj, this.POI_REQUEST_KEY);
			this.successfulTimeControl = true;
		}, 300);
	}

	//点击选择poi项后上报
	//@optionData:{UpName:上一级菜单名 Type:类型 key:选中项}
	selectPoiItem(optionData, _poiResult, isClick) {
		//TODO mode 0:mousedown点击 1:keydown 2:其他
		let clickNumFirst = '',
			clickNumSecond = '',
			newObj = {};
		//新需求埋点
		if (optionData) {
			clickNumFirst = optionData.firstLvlIndex || '';
			clickNumSecond = optionData.totalIndex || '';
		}

		//新对象
		newObj.IsClick = isClick ? 1 : 0;
		newObj.InputCity = `${this.userinput}`;
		newObj.HasResult = 1;
		newObj.TotalNum = `${this.ubtObj.first || ''}`;
		newObj.ClickNum_first = clickNumFirst;
		newObj.ClickNum_second = clickNumSecond;
		newObj.TotalNum_second = `${this.ubtObj.totalIndex || ''}`;
		this.reportToApi(newObj, this.NEW_POI_SELECT_KEY);
		this.reportToApi(
			{
				type: 'selectPoi',
				poiType: optionData.type == 'province' ? 'country' : optionData.type,
				text: optionData.text,
			},
			this.SEARCH_BOX_KEY
		);

		// 上报搜索城市别名的埋点
		optionData.NameAlias &&
			this.reportToApi({ type: 'nameAliasSearch', text: '国际频道首页搜索城市别名' }, this.NAME_ALIAS_SEARCH);
	}
	selectFromCityPannel(text) {
		this.reportToApi(
			{
				type: 'cityPickerSelect',
				value: 'city',
				selectCity: text,
			},
			this.SEARCH_BOX_KEY_CLICK
		);
	}
	switchContinent(text) {
		this.reportToApi({ type: 'cityPickerSwitchContinent', text }, this.SEARCH_BOX_KEY_CLICK);
	}
}

export default new poiUbt();
