import { List } from 'immutable'
import { createSelector } from 'reselect'
import { CABIN } from "../../../../constants/common/enum/common"
import {
    containsTaxSelector, prevCondSelector, flightsSelector, currentSegmentSeqSelector, isBuildUpSelector,
    flightSelector, getHighCabinExpandExtraRecFlightsSelector,
    getSplitRoundFlightsSwitchSelector, getFixSortBarSelector, selectAnyFlightsSelector, getIfScopeInternationalSelector
} from './baseSelector'
import { wrapTheSegment } from './wrapTheSegment';
import { getAllFlightsWithMinPriceSelector, getSortedVisibleFlightsSelector, getIfDirectFlightSortSelector } from '../../sidebar/filterbar/filterbarSelector';
import { ITINERARY_TAG_TYPE, ItineryaryTagWrapper } from '../../../../constants/list/enums/itineraryTagType';
import { getIfPrevCondIsHighCabin } from '../../../../sources/list/highCabinRec';
import { HIGH_CABIN_REC_MSG } from '../../../../constants/list/msgs/highCabinRecMsg';
import { calcIfFixSplitFlights } from './calcIfFixSplitFlights';
import { EMPTY_ARRAY } from '../../../../sources/common/consts';
/**
 * 获取当前航组的行程标签信息
 */
export const getItineraryTagsSelector = createSelector(flightSelector, flight => flight.get('itineraryTags'))

/**
 * 当前所选择的航组ID
 */
export const getItineraryIdSelector = createSelector(flightSelector, flight => flight.get('itineraryId'))

/**
 * 当前所选择的航程礼盒ID
 */
export const giftIdsSelector = createSelector(flightSelector, flight => flight.get('giftIds'))

/**
 * 是否任何航组包含两舱推荐经济舱
 */
export const getContainsFCRecommendYS = createSelector(flightsSelector, flights => flights.get('list').some(flight => flight.get('isFCRecommendYS') || flight.get('isCFRecommendYS')))

/**
 * 获取当前航组的行程标签信息，已经把夜间航班封装在内
 */
export const getAllFlightsToItineraryTagEnumsSelector = createSelector(
    [getIfScopeInternationalSelector, getAllFlightsWithMinPriceSelector, currentSegmentSeqSelector, isBuildUpSelector, giftIdsSelector],
    (scopeInternational, flightList, currentSegmentSeq, isBuildUpMode, giftIds) => {
        let getItineraryTagEnumsOfSegmentSeq = (flight, segmentSeq) => {
            const itineraryTagEnums = flight.get('itineraryTagEnums'),
                // 是否存在飞人或超级飞人
                itineraryTagExistFlyer = itineraryTagEnums.find(tagEnum => tagEnum && tagEnum.tag && (tagEnum.tag.key === 'SuperFlyer' || tagEnum.tag.key === 'Flyer')),
                // 预计延误时间，国内航班才有，格式是 HH:MM
                delayedTime = !scopeInternational && !itineraryTagExistFlyer && segmentSeq === 0 && flight.getIn(['flightSegments', segmentSeq, 'flightList', 0, 'delayedTime'])

            return itineraryTagEnums
                .concat(List(giftIds ? [new ItineryaryTagWrapper({ type: ITINERARY_TAG_TYPE.FlightGift.key, data: giftIds })] : []))
                .concat(List(delayedTime ? [new ItineryaryTagWrapper({ type: ITINERARY_TAG_TYPE.DelayedTime.key, data: delayedTime })] : []))
                .reduce((prev, next) => prev.concat(next), List())
                .sort(wrapper => wrapper.tag.value.seq)
        }

        let allFlightsToItineraryTagEnumsDict = {}

        flightList.valueSeq().forEach(flight => {
            let theItineraryTagEnums = null
            if (!isBuildUpMode) {
                theItineraryTagEnums = [getItineraryTagEnumsOfSegmentSeq(flight, currentSegmentSeq)]
            } else {
                theItineraryTagEnums = [getItineraryTagEnumsOfSegmentSeq(flight, 0), getItineraryTagEnumsOfSegmentSeq(flight, 1).filter(wrapper => wrapper.tag.value.showOnBuildUpReturn)]
            }

            allFlightsToItineraryTagEnumsDict[flight.get('itineraryId')] = theItineraryTagEnums
        })

        return allFlightsToItineraryTagEnumsDict
    })

/**
 * 当前所选择的航程运价
 */
export const getPriceListSelector = createSelector(flightSelector, flight => flight.get('priceList'))

/**
 * 获取当前航班是否两舱推经济舱
 */
export const getIsFCRecommendYSSelector = createSelector(flightSelector, flight => flight.get('isFCRecommendYS'))

/**
 * 获取所有航班的当前航程，如果是往返组合则返回一个长度为2的航程数组
 */
export const getAllFlightsToSegmentListDictSelector = createSelector([getAllFlightsWithMinPriceSelector, prevCondSelector, containsTaxSelector, currentSegmentSeqSelector],
    (flightList, prevCond, sortContainsTax, currentSegmentSeq) => {
        let totalSegmentCount = prevCond.get('flightSegments').size
        let allFlightsToSegmentListDict = {}

        flightList.valueSeq().forEach(flight => {
            let segmentList = []
            Array(currentSegmentSeq + 1).fill(0).forEach((val, index) => {
                let theSegment = flight.getIn(['flightSegments', index])

                segmentList.push(wrapTheSegment(theSegment, index, sortContainsTax, totalSegmentCount, flight.get('theMinPriceItem'), flight.get('priceList')))
            })
            allFlightsToSegmentListDict[flight.get('itineraryId')] = segmentList
        })

        return allFlightsToSegmentListDict
    })

/**
* 获取所有已选航程(包含当前程)集合
*/
export const getAllSegmentsSelector = createSelector([flightSelector, currentSegmentSeqSelector], (flight, currentSegmentSeq) => {
    return flight.get('flightSegments').filter((segment, index) => index <= currentSegmentSeq)
})

/**
 * 获取是否显示华夏通程的描述信息：单程、往返组合、往返的返程
 */
export const getShowTongChengRemarkSelector = createSelector([currentSegmentSeqSelector, isBuildUpSelector, prevCondSelector],
    (currentSegmentSeq, isBuildUpMode, prevCond) => {

        if (prevCond) {
            const flightWay = prevCond.get('flightWay')
            return flightWay === 'S' || isBuildUpMode || (flightWay === 'D' && currentSegmentSeq === 1)
        } else {
            return false
        }
    })

/**
 * 获取是否显示航班通用优惠券：单程、往返组合、往返的返程
 */
export const getShowFlightCouponTagSelector = createSelector([currentSegmentSeqSelector, isBuildUpSelector, prevCondSelector],
    (currentSegmentSeq, isBuildUpMode, prevCond) => {
        // return false
        if (prevCond) {
            const flightWay = prevCond.get('flightWay')
            return flightWay === 'S' || isBuildUpMode || (flightWay === 'D' && currentSegmentSeq === 1)
        } else {
            return false
        }
    })

/**
 * 查询条件是否是高舱：C_F / C / F
 */
export const getIfPrevCondHighCabinSelector = createSelector([prevCondSelector], prevCond => {
    return getIfPrevCondIsHighCabin(prevCond)
})

/**
 * 两舱推荐时候是否需要显示提示语以及区块显示等逻辑，
 * 目前只有在 单程、往返自由搭配、多程 的第一程、第二程，以及往返组合 时候有效
 */
export const getIfHighCabinNecessary = createSelector([], () => {
    return true
})

/**
 * 前一个航班是非推荐航班(前一个航班可以为空)，后一个航班是推荐航班
 */
const getHighCabinNonRecToRecIndex = visibleFlights => visibleFlights.findIndex((flight, index) => (index === 0 || !visibleFlights.getIn([index - 1, 'containsRecommendCabin'])) && flight.get('containsRecommendCabin'))


/**
 * 前一个航班是推荐航班(前一个航班不能为空)，后一个航班是非推荐航班
 */
const getHighCabinRecToNonRecIndex = visibleFlights => visibleFlights.findIndex((flight, index) => (index > 0 && visibleFlights.getIn([index - 1, 'containsRecommendCabin'])) && !flight.get('containsRecommendCabin'))

const calcHighCabinExtraRecFlightsInfo = (visibleFlights, includeEqual4Flights) => {
    //第一个分割点，肯定是非推荐航班到推荐航班分割点
    const nonRecToRecIndex = getHighCabinNonRecToRecIndex(visibleFlights)
    //推荐航班到非推荐航班分割点
    const recToNonRecIndex = getHighCabinRecToNonRecIndex(visibleFlights)

    //第二个分割点，需要考虑当前是否直飞优先排序，是的话两类分割点都OK：
    // 1) 推荐航班到非推荐航班分割点
    // 2) 直飞到非直飞航班分割点
    let the2ndSplitIndex = get1stRecAreaEndIndex(visibleFlights)
    if (the2ndSplitIndex === -1) {
        the2ndSplitIndex = recToNonRecIndex === -1 ? visibleFlights.length : recToNonRecIndex
    }
    const nonRecAreaFlightsCount = the2ndSplitIndex - nonRecToRecIndex,
        maxCountWhenHide = 4    //超出4条航班就要折叠
    if (nonRecToRecIndex >= 0 && (includeEqual4Flights ? nonRecAreaFlightsCount >= maxCountWhenHide : nonRecAreaFlightsCount > maxCountWhenHide)) {
        return {
            nonRecToRecIndex,
            recToNonRecIndex: the2ndSplitIndex,
            maxCountWhenHide
        }
    } else {
        return false
    }
}

/**
 * 两舱推荐，第二个推荐航班区块的航班数是否大于4，以决定是否需要显示对应操作 展开/收缩 的文案
 * 此处逻辑需要注意：
 * 对航班进行分块与折叠是基于正常排序（排序逻辑已经考虑到两舱的推荐/非推荐优先级）后的航班（称为F1）的分割点，排序后的区块航班称为F2，
 * 问题是，F1和F2已经有区别，真正显示到界面是F2，分割点也要以F2为准
 * 【此处是针对F1的分割点计算】
 */
const getNormalSortHighCabinExtraRecFlightsSelector = createSelector([getIfHighCabinNecessary, getIfPrevCondHighCabinSelector, getSortedVisibleFlightsSelector,
    isBuildUpSelector, currentSegmentSeqSelector, getIfDirectFlightSortSelector],
    (highCabinNecessary, prevCondHighCabin, visibleFlights, isBuildUp, currentSegmentSeq, isDirectFlightSort) => {
        if (highCabinNecessary && prevCondHighCabin) {
            return calcHighCabinExtraRecFlightsInfo(visibleFlights, false, isBuildUp, currentSegmentSeq, isDirectFlightSort)
        } else {
            return null
        }
    })

/**
 * 两舱推荐航班的两个处理，1）会将非推荐航班进行重新区块内排序 2）再将推荐航班进行折叠
 *
 * 1）
 * 比如搜索F，查询出来的F航班大于12（假定现在有25个F航班），且当前非直飞优先排序，就需要将F航班根据当前排序条件排序后，
 * 再拆成两部分，第一部分10条，第二部分15条，两部分作为首、尾，中间是推荐航班
 * 如果查询出来的F航班小于或等于12，则不需要拆出第二部分，都作为第一部分即可
 *
 * 2）
 * 如果推荐航班区块后面还有其他非推荐航班区块，比如：
 *  1] 查询F，直飞优先排序，推荐航班之后有FC中转航班，
 *  2] 查询F，非直飞优先排序，F航班数目25（大于12），推荐航班之后有15个航班
 * 且推荐航班数目大于4，就默认显示4个推荐航班，且在第四个航班后面加上 展开/收缩 航班
 */
export const getHighCabinHideSortVisibleFlightsSelector = createSelector([getIfHighCabinNecessary, getIfPrevCondHighCabinSelector,
    getSortedVisibleFlightsSelector, getIfDirectFlightSortSelector, getNormalSortHighCabinExtraRecFlightsSelector, getHighCabinExpandExtraRecFlightsSelector],
    (highCabinNecessary, prevCondHighCabin, visibleFlights, directFlightSort, hideSomeRecFlights, expandExtraRecFlights) => {
        if (highCabinNecessary && prevCondHighCabin) {
            const nonRecFlights = visibleFlights.filter(flight => !flight.get('containsRecommendCabin'))    //非推荐航班
            const recFlights = visibleFlights.filter(flight => flight.get('containsRecommendCabin'))    //推荐航班

            if (hideSomeRecFlights && !expandExtraRecFlights) {
                const { nonRecToRecIndex, recToNonRecIndex, maxCountWhenHide } = hideSomeRecFlights
                //如果第二个区块，即推荐航班区块航班数大于4，就要把超出部分航班默认折叠
                visibleFlights = visibleFlights.slice(0, nonRecToRecIndex + maxCountWhenHide).concat(visibleFlights.slice(recToNonRecIndex))
            }

            if (directFlightSort) {
                return visibleFlights
            } else {
                const maxNonRecSize = 12,  //非推荐航班大于12条才要拆开显示
                    splitLenth = 10
                if (nonRecFlights.size > maxNonRecSize) {
                    const top10NonRecFlights = nonRecFlights.slice(0, splitLenth),
                        restNonRecFlights = nonRecFlights.slice(splitLenth)

                    return top10NonRecFlights.concat(recFlights.slice(0, expandExtraRecFlights ? Infinity : 4)).concat(restNonRecFlights)
                } else {
                    return visibleFlights
                }
            }
        } else {
            return visibleFlights
        }
    })

/**
 * 两舱推荐，第二个推荐航班区块的航班数是否大于4，以决定是否需要显示对应操作 展开/收缩 的文案
 * 此处逻辑需要注意：
 * 对航班进行分块与折叠是基于正常排序（排序逻辑已经考虑到两舱的推荐/非推荐优先级）后的航班（称为F1）的分割点，排序后的区块航班称为F2，
 * 问题是，F1和F2已经有区别，真正显示到界面是F2，分割点也要以F2为准
 * 【此处是针对F2的分割点计算】
 */
const getAfterHighCabinProcessExtraRecFlightsSelector = createSelector([getIfHighCabinNecessary, getIfPrevCondHighCabinSelector,
    getSortedVisibleFlightsSelector, getHighCabinHideSortVisibleFlightsSelector, getIfDirectFlightSortSelector, isBuildUpSelector, currentSegmentSeqSelector],
    (highCabinNecessary, prevCondHighCabin, visibleFlightsBeforeSortHighCabin, visibleFlightsAfterSortHighCabin, isDirectFlightSort, isBuildUp, currentSegmentSeq) => {
        if (highCabinNecessary && prevCondHighCabin) {
            return calcHighCabinExtraRecFlightsInfo(visibleFlightsAfterSortHighCabin, !!(visibleFlightsAfterSortHighCabin.size < visibleFlightsBeforeSortHighCabin.size), isBuildUp, currentSegmentSeq, isDirectFlightSort)
        } else {
            return false
        }
    })

/**
 * 两舱推荐，
 * 当第二区块的推荐航班多于4个航班时：
 * 默认折叠隐藏超出部分航班，此时显示 展开
 * 展开后 显示 收起
 * 此处返回显示上面 操作文案 的 index
 */
export const getHighCabinHideOrShowFlightsHandlerIndexSelector = createSelector([getIfHighCabinNecessary, getAfterHighCabinProcessExtraRecFlightsSelector,
    getHighCabinExpandExtraRecFlightsSelector],
    (highCabinNecessary, hideSomeExtraRecFlights, expandExtraRecFlights) => {
        if (highCabinNecessary && hideSomeExtraRecFlights) {
            const { nonRecToRecIndex, recToNonRecIndex, maxCountWhenHide } = hideSomeExtraRecFlights
            if (expandExtraRecFlights) {
                return recToNonRecIndex
            } else {
                return nonRecToRecIndex + maxCountWhenHide
            }
        } else {
            return -1
        }
    })

/**
 * 两舱推荐提示语，例如：为您提供更多时间和航司选择，补充更多舱位航班
 */
export const getHighCabinRecommendMainTipSelector = createSelector([getIfHighCabinNecessary, prevCondSelector, getIfPrevCondHighCabinSelector, getHighCabinHideSortVisibleFlightsSelector],
    (highCabinNecessary, prevCond, prevCondHighCabin, visibleFlights) => {
        const prevCondCabinEnum = prevCond.get('cabinEnum')
        if (!highCabinNecessary || !prevCondHighCabin) return null
        //分两种情况：
        // 1）查询结果中有非推荐航班
        // 2）查询结果中没有查询到非推荐类航班
        // 注：非推荐航班就是查询舱等条件内的航班，比如搜索F，查询到的F航班；搜索FC，查询到的F航班/C航班
        const nonRecommendCabinFlights = visibleFlights.filter(flight => !flight.get('containsRecommendCabin'))
        if (nonRecommendCabinFlights.size) {
            return HIGH_CABIN_REC_MSG.MAIN_TIP_HEADER.HAS_DIRECT_FLIGHT_OF_NON_REC
        } else {
            // 见上面的情况3：无非推荐航班
            if (prevCondCabinEnum.value.title.includes('公务/头等舱')) {
				return (
					HIGH_CABIN_REC_MSG.MAIN_TIP_HEADER.NO_FLIGHTS_OF_NON_REC.replace(
						/\[CABIN\]/,
						prevCondCabinEnum.value.title
					) + '部分航段为公务/头等舱，或全部航段为经济舱的机票'
				);
			}
			// 见上面的情况3：无非推荐航班
			return HIGH_CABIN_REC_MSG.MAIN_TIP_HEADER.NO_FLIGHTS_OF_NON_REC.replace(
				/\[CABIN\]/,
				prevCondCabinEnum.value.title
			);
        }
    })

/**
 * 查找指定索引号之间航班的所有舱位名
 */
const getAllCabinTitlessBetweenIndexs = (flights, startIndex, endIndex, filtCabins = []) => {
    const allFlightsBetweenIndexs = flights.slice(startIndex, endIndex)
    let allCabinTitles = []
    allFlightsBetweenIndexs.valueSeq().forEach(flight => {
        flight.get('priceList').valueSeq().forEach(price => {
            price.get('allCurrentSegmentCabinEnumList').forEach(cabinEnum => {
                if (filtCabins.includes(cabinEnum.key)) return
                allCabinTitles.push(cabinEnum.value.title.replace('舱', ''))
            })
        })
    })

    return Array.from(new Set(allCabinTitles)) // 去重
}


// 推荐其它高仓的index
const getHighCabinRecOtherHighCabinsIndex = (visibleFlights) => visibleFlights.findIndex((flight) => {
    return flight.get('containsRecommendCabin') && !flight.get('containsLowCabin')
})

// 推荐低舱的index
const getHighCabinRecLowCabinsIndex = (visibleFlights) => visibleFlights.findIndex((flight) => {
    return flight.get('containsRecommendCabin') && flight.get('containsLowCabin')
})

// 前一个航班是推荐航班(前一个航班不能为空)，后一个航班是非推荐航班
const getHighCabinRecToNonRecCabinsIndex = (visibleFlights) => visibleFlights.findIndex((flight, index) => {
    return (index > 0 && visibleFlights.getIn([index - 1, 'containsRecommendCabin'])) && !flight.get('containsRecommendCabin')
})

function get1stRecAreaEndIndex(visibleFlights) {
    const recOtherHighCabinsIndex = getHighCabinRecOtherHighCabinsIndex(visibleFlights)
    const recLowCabinsIndex = getHighCabinRecLowCabinsIndex(visibleFlights)
    const recToNonRecCabinsIndex = getHighCabinRecToNonRecCabinsIndex(visibleFlights)
    let index = -1
    if (recOtherHighCabinsIndex !== -1) { // 高仓推荐区域存在
        if (recLowCabinsIndex !== -1) {
            index = recLowCabinsIndex
        } else if (recToNonRecCabinsIndex !== -1) {
            index = recToNonRecCabinsIndex
        }
    } else if (recLowCabinsIndex !== -1) { // 低舱推荐区域存在
        if (recToNonRecCabinsIndex !== -1) {
            index = recToNonRecCabinsIndex
        }
    }
    return index
}

/**
 * 两舱推荐区块分隔提示语，
 * 第二个区块：推荐其它高舱，如果没有，不显示
 * 第三个区块：推荐其它低舱，如果没有，不显示
 * 第四个区块：如果不是直飞优先排序，剩余的非推荐航班放到这个区块
 * */
export const getHighCabinRecommendGroupTipListSelector = createSelector([getIfHighCabinNecessary, getIfDirectFlightSortSelector,
    getIfPrevCondHighCabinSelector, getHighCabinHideSortVisibleFlightsSelector, getSortedVisibleFlightsSelector, isBuildUpSelector, currentSegmentSeqSelector, prevCondSelector],
    (highCabinNecessary, directFlightSort, prevCondHighCabin, afterHighCabinProcessVisibleFlights, sortedVisibleFlights, isBuildUp, currentSegmentSeq, prevCond) => {
        if (!highCabinNecessary || !prevCondHighCabin) return EMPTY_ARRAY
        let groupTipList = []
        // 带下划线命名的变量索引号是指进行第二区块折叠前的计算结果，因为不能拿剔除后航班计算舱位和中转/经停等，要拿完整的航班信息
        const recOtherHighCabinsIndex = getHighCabinRecOtherHighCabinsIndex(afterHighCabinProcessVisibleFlights)
        const recLowCabinsIndex = getHighCabinRecLowCabinsIndex(afterHighCabinProcessVisibleFlights)
        const _recLowCabinsIndex = getHighCabinRecLowCabinsIndex(sortedVisibleFlights)
        const recToNonRecCabinsIndex = getHighCabinRecToNonRecCabinsIndex(afterHighCabinProcessVisibleFlights)
        const _recToNonRecCabinsIndex = getHighCabinRecToNonRecCabinsIndex(sortedVisibleFlights)
        const searchCabinEnum = prevCond.get('cabinEnum')
        if (recOtherHighCabinsIndex >= 0 && ['C', 'F'].includes(searchCabinEnum.key)) { // 推荐其它高舱tip
            const recCabin = CABIN.get(searchCabinEnum.key === 'C' ? 'F' : 'C')

            groupTipList.push({
                index: recOtherHighCabinsIndex,
                iconClassName: 'ico-directflt',
                content: recCabin.value.title + ' 航班',
                directFlight: false,
                cabinTitles: recCabin.value.title
            })
        }

        if (recLowCabinsIndex >= 0) { // 推荐其它低舱tip
            let _recLowCabinsEndIndex = sortedVisibleFlights.size
            if (_recToNonRecCabinsIndex >= 0) {
                _recLowCabinsEndIndex = _recToNonRecCabinsIndex
            }
            const allLowCabinTitles = getAllCabinTitlessBetweenIndexs(sortedVisibleFlights, _recLowCabinsIndex, _recLowCabinsEndIndex, ['C', 'F'])
            groupTipList.push({
                index: recLowCabinsIndex,
                iconClassName: 'ico-directflt',
                content: allLowCabinTitles.join('/') + '舱 航班',
                directFlight: false,
                cabinTitles: allLowCabinTitles
            })
        }

        if (recToNonRecCabinsIndex >= 0) { // 非推荐舱折叠后的tip
            const allCabinTitles = getAllCabinTitlessBetweenIndexs(sortedVisibleFlights, _recToNonRecCabinsIndex, sortedVisibleFlights.size)
            groupTipList.push({
                index: recToNonRecCabinsIndex,
                iconClassName: 'ico-seattype',
                content: searchCabinEnum.value.title + ' 航班',
                directFlight: false,
                cabinTitles: allCabinTitles
            })
        }

        return groupTipList
    })

// 往返分屏是否需要将左右往返航班列表进行拆开滚动条且吸顶：筛选排序要吸顶、已经选择去程航班
export const getIfFixSplitFlightsSelector = createSelector([getSplitRoundFlightsSwitchSelector, getFixSortBarSelector, selectAnyFlightsSelector],
    (splitRoundFlightsSwitch, fixSortBar, selectAnyFlights) => {
        return calcIfFixSplitFlights(splitRoundFlightsSwitch, fixSortBar, selectAnyFlights)
    })

export const getIntlPriceList = createSelector([flightsSelector, flightSelector], (flights, flight) => flights.getIn(['intlPrice', flight.get('itineraryId')]));
