const CONSTS = {
    BAGGAGE: {
        DEFAULT_DETAIL_HOVER: '行李尺寸限制请咨询航司',
        DEFAULT_DETAIL_FOOTER_HOVER: '行李尺寸限制请咨询航司',
        DEFAULT_HAS_BAGGAGE_SERVICE_HOVER: '后续页面提供购买行李额服务',
        DEFAULT_NO_BAGGEAGE_HOVER: '行李额信息请在点击“预订”按钮后，在下一个页面查看'
    },
    TABS_D: ["国内热门", "国际•中国港澳台热门", "亚洲", "欧洲", "美洲", "非洲", "大洋洲"],
    TABS: ["国际•中国港澳台热门", "国内热门", "亚洲", "欧洲", "美洲", "非洲", "大洋洲"],
    TABS_WITHOUT_DOMESTIC: ["国际•中国港澳台热门", "亚洲", "欧洲", "美洲", "非洲", "大洋洲"],
    HOT_DISTRICT_MAP_TAB: {
        '精选地区': '国际•中国港澳台热门',
        '亚洲': '亚洲',
        '欧洲': '欧洲',
        '北美洲': '美洲',
        '非洲': '非洲',
        '大洋洲': '大洋洲'
    },
    TAB_MAP_HOT_DISTRICT: {
        '国际•中国港澳台热门': '精选地区',
        '亚洲': '亚洲',
        '欧洲': '欧洲',
        '美洲': '北美洲',
        '非洲': '非洲',
        '大洋洲': '大洋洲'
    },
    PROVINCE_ID: {
        TW: 53
    }
}

export default CONSTS
