import Immutable from 'immutable';
import { getFilterTypes } from '../../constants/list/enums/filter';
import { FilterOptionRetrieverFactory } from '../../containers/list/sidebar/filterbar/filterOptionRetriever';
import { getPrevCondFromGlobal } from '../../actions/common';
import { getIfBuildUpFromUrl } from './getIfBuildUpFromUrl';

// 排除掉不可见筛选类型：
// 1) 配置为不可见
// 2) 往返组合特定的筛选类型
export const getAllFilterTypes = () => {
	const isBuildUp = getIfBuildUpFromUrl(),
		prevCond = getPrevCondFromGlobal(),
		filterTypes = getFilterTypes();

	const _allFilterTypes = Object.keys(filterTypes.toJSON())
		.map((type) => filterTypes.get(type))
		.filter((type) => type.value.getVisible(isBuildUp, prevCond.flightWay, null));

	return _allFilterTypes;
};

export const getInitActiveKeys = () => {
	let allFilterTypes = getAllFilterTypes();
	let maps = {};

	allFilterTypes.forEach((theFilterType) => {
		maps[theFilterType] = [];
	});

	return Immutable.fromJS(maps);
};

export const getInitFilterTypes = () => {
	let allFilterTypes = getAllFilterTypes();
	let list = [];

	allFilterTypes.forEach((theFilterType) => {
		list.push({
			seq: theFilterType.value.seq,
			type: theFilterType,
			title: theFilterType.value.title,
		});
	});

	return Immutable.fromJS(list);
};

export const getFilterTypeDefaultActiveKeys = (activeKeys, flights, prevCond) => {
	const filterTypes = getFilterTypes();

	return activeKeys.withMutations((activeKeys) => {
		activeKeys.keySeq().forEach((filterType) => {
			activeKeys = activeKeys.set(
				filterType,
				FilterOptionRetrieverFactory.getInstance(filterTypes.get(filterType)).getDefaultActiveKeys(
					flights,
					prevCond,
					{ whileCleanFilter: true }
				)
			);
		});
	});
};
