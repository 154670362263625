import { getIfScopeDomestic } from '../list/scope'
/**
 * 计算运价均值等
 * @param {运价，plain对象} price 
 * @param {查询条件信息，Immutable对象} prevCond  
 */
export const calcPrice = (price) => {
    const prevCond = window.GlobalSearchCriteria,
        adultCount = prevCond.adultCount,
        childCount = prevCond.childCount,
        infantCount = prevCond.infantCount,
        totalPassengerCount = adultCount + childCount + infantCount,
        adultPrice = Math.ceil(price.adultPrice) || 0,
        childPrice = Math.ceil(price.childPrice) || 0,
        infantPrice = Math.ceil(price.infantPrice) || 0,
        sortPrice = Math.ceil(price.sortPrice) || 0,
        adultTax = Math.ceil(price.adultTax) || 0,
        childTax = Math.ceil(price.childTax) || 0,
        infantTax = Math.ceil(price.infantTax) || 0,
        totalPrice = getIfScopeDomestic() ? sortPrice : adultPrice * adultCount + childPrice * childCount + infantPrice * infantCount,
        totalTax = getIfScopeDomestic() ? 0 : adultTax * adultCount + childTax * childCount + infantTax * infantCount,
        totalPriceWithTax = Math.ceil(totalPrice + totalTax),
        avgTax = Math.ceil(totalTax / totalPassengerCount),
        avgPriceWithoutTax = getIfScopeDomestic() ? totalPrice : Math.ceil(totalPrice / totalPassengerCount),
        avgPriceWithTax = getIfScopeDomestic() ? totalPrice :  Math.ceil((totalPrice + totalTax) / totalPassengerCount),
        priceProps = {
            avgTax,
            avgPriceWithoutTax,
            avgPriceWithTax,
            totalPrice,
            totalPriceWithTax,
            priceWithoutTax: {
                ADULT: adultPrice,
                CHILD: childPrice,
                INFANT: infantPrice,
                avg: avgPriceWithoutTax
            },
            tax: {
                ADULT: adultTax,
                CHILD: childTax,
                INFANT: infantTax,
                avg: avgTax
            },
            priceWithTax: {
                ADULT: adultPrice + adultTax,
                CHILD: childPrice + childTax,
                INFANT: infantPrice + infantTax,
                avg: avgPriceWithTax
            }
        }

    return priceProps
}