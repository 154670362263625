import { sendTrace } from '@/src/sources/common/lightUbt';
import { Ubt, ubtGlobalState } from './index';
import store from '../stores/list';
import PRODUCT_MESSAGE from '../constants/list/enums/productMessage';


export class ListUbt extends Ubt {
	constructor(props) {
		super(props);
		//埋点对象
		//通用点击类
		this.commonClickUbt = {};
		//通用hover类
		this.commonHoverUbt = {};
		//埋点key
		this.COMMON_CLICK_UBT_KEY = 'flt_oversea_search_click';
		this.COMMON_HOVER_UBT_KEY = 'flt_oversea_search_hover';
		this.LIST_BASIC_KEY = 'flt_oversea_search_result_new_online_basic';
		this.HOT_COUNTRY_SEARCH = 'c_hotsearch';

		this.productTag = new Set([]);
		this.priceTag = new Set([]);
		this.restrictionTag = new Set([]);
	}

	//注册事件
	registerEvent() {
		const onleaveReport = () => {
			this.reportCommonClickToApi();
			this.reportCommonHoverToApi();
			this.reportCommonBasicToApi('Exit');
		};

		// const onloadReport = () => {
		//     this.reportSearchHistory()
		// }

		if (document.addEventListener) {
			window.addEventListener('beforeunload', onleaveReport);
			// window.addEventListener("onload", onloadReport)
		} else {
			console.log('离开页面注册出错!');
		}
	}

	//上报通用埋点
	reportCommonBasicToApi(type) {
		this.reportToApi(this.getCommonBasicUbtData(type), this.LIST_BASIC_KEY);
	}
	//从航班列表搜集标签
	setPriceAndProductTag(priceList, itineraryTagEnums) {
		priceList.forEach((price) => {
			let priceTagEnums = price.get('priceTagEnums'),
				productTypeWrapper = price.get('productTypeEnum'),
				prodTagStr = PRODUCT_MESSAGE.PRODUCT_TAG[productTypeWrapper.tag.key];
			prodTagStr && this.productTag.add(prodTagStr);
			priceTagEnums.forEach((tag) => {
				this.priceTag.add(tag.label);
			});
			if (price.get('restrictionList').size) {
				let GVLimitationsEnums = price.get('GVLimitationsEnums');
				GVLimitationsEnums.forEach((gv) => {
					this.restrictionTag.add(gv.label);
				});
			}
		});

		itineraryTagEnums.forEach((tag) => {
			if (tag.size > 0) {
				tag.forEach((ItineryaryTagWrapper) => {
					let prodTagStr = PRODUCT_MESSAGE.ITINERARY_TAG[ItineryaryTagWrapper.tag.key];
					if (prodTagStr) {
						this.productTag.add(prodTagStr.replace(/<[^>]*>/g, '').replace(/<\/[^>]*>/g, ''));
					}
				});
			}
		});
		// console.log('test', [...this.priceTag], [...this.productTag])
	}

	getCommonData(type) {
		let prevCond = store.getState().getIn(['list', 'search', 'prevCond']),
			getFlightSegments = function (segs) {
				let res = [];
				segs.forEach(function (seg) {
					res.push({
						From: {
							CityName: seg.get('departureCityName'),
							CityCode: seg.get('departureCityCode'),
							CityID: '',
						},
						To: {
							CityName: seg.get('arrivalCityName'),
							CityCode: seg.get('arrivalCityCode'),
							CityID: '',
						},
						StartTime: seg.get('departureDate'),
					});
				});
				return res;
			};
		let obj = {
			FlightClass: 'I',
			FlightWay: prevCond.get('flightWay'),
			Class: prevCond.get('cabin'),
			Airline: window.GlobalSearchCriteria.airline ? window.GlobalSearchCriteria.airline.code : 'N',
			PassengerType: {
				Adult: prevCond.get('adultCount'),
				Children: prevCond.get('childCount'),
				Infant: prevCond.get('infantCount'),
			},
			Sequence: getFlightSegments(prevCond.get('flightSegments')),
			TriggerType: type,
			Producttags: [...this.productTag].join(','),
			Servicetags: [...this.priceTag].join(','),
			Restrictiontags: [...this.restrictionTag].join(','),
		};
		let is_Crawler = prevCond.get('Is_Crawler');
		let crawlerType = prevCond.get('CrawlerType');
		if (typeof is_Crawler != 'undefined') {
			obj.Is_Crawler = is_Crawler;
		}
		if (typeof crawlerType != 'undefined') {
			obj.CrawlerType = crawlerType;
		}
		return obj;
	}

	getCommonBasicUbtData(type) {
		let ret = {};
		//poi
		let poiNoResult = ubtGlobalState.poi.poiNoResult;
		if (poiNoResult && poiNoResult.length > 0) {
			//把POI无结果埋点加上
			ret.POISearch = poiNoResult;
			ubtGlobalState.poi.poiNoResult;
		}

		//通用数据
		let commonData = this.getCommonData(type);

		let RecommendProduct = ubtGlobalState.RecommendProduct,
			NoResultRecommend = ubtGlobalState.NoResultRecommend;
		ret.FC_rec_SY = ubtGlobalState.FC_rec_SY;
		//两舱存储到填写页使用
		ret.FC_rec_SY.SearchClass &&
			localStorage.setItem(
				'_CFRecommendYSUBT',
				JSON.stringify({
					SearchClass: ret.FC_rec_SY.SearchClass,
					RecClass: ret.FC_rec_SY.RecClass,
				})
			);

		ret.RecommendProduct = RecommendProduct;
		ret.NoResultRecommend = NoResultRecommend;
		if (ubtGlobalState.Search_no_result) ret.Search_no_result = ubtGlobalState.Search_no_result;
		return JSON.stringify({ ...ret, ...commonData });
	}

	//热门国家埋点 TODO
	reportHotCountryToApi(countryName, countrySource) {
		let flightWay = store.getState().getIn(['list', 'search', 'flightWay']),
			selectedCity = store.getState().getIn(['list', 'search', 'selectedCity']);
		//TODO
		var content = {
			content: {
				searchway: countrySource,
				flightway: flightWay == 'OW' ? 'S' : 'D',
				from:
					flightWay == 'OW'
						? selectedCity.get('owDCity')
							? selectedCity.getIn(['owDCity', 'Name'])
							: ''
						: selectedCity.get('rtDCity')
						? selectedCity.getIn(['rtDCity', 'Name'])
						: '', //this._parseCityName(this.$DCity.value()),
				to: countryName, //this.$ACity.value(),
				searchdate: store.getState().getIn(['list', 'hotCountry', 'inputString']),
			},
		};
		this.reportToApi(content, this.HOT_COUNTRY_SEARCH);
	}
	// 城市面板选择埋点
	reportCityPanelUbt(data) {
		let flightWay = store.getState().getIn(['list', 'search', 'prevCond', 'flightWay']);
		if (flightWay == 'MT') {
			return;
		}
		sendTrace('o_flt_citylist', data);
	}
}

export default new ListUbt();
