import { Map, List } from 'immutable'
import { createSelector } from 'reselect'
import { isBuildUpSelector, flightTrainFlightsSelector, currentSegmentSeqSelector } from '../flight/baseSelector'
import { getMinMiseryIndexSelector } from './baseRecommendSelector'

//折叠
const getAppendShowMore = (state) => {
    return state.getIn(["list", "recommend", 'appendShowMore'])
}

export const selectAppendShowMore = createSelector(
    getAppendShowMore,
    appendShowMore => appendShowMore
)

//是否要推荐
const isNeedRecommend = createSelector([isBuildUpSelector], (isBuildUp) => {
    return !isBuildUp
})

//空铁最低痛苦指数
export const flightTrainLowestMiseryIndex = createSelector([flightTrainFlightsSelector], (flightTrains) => {
    let res = Infinity, foundIndex = 0
    flightTrains.forEach((item, index) => {
        item.get('priceList').forEach(price => {
            let misery = price.get('miseryIndex')
            if (misery < res) {
                res = misery
                foundIndex = index
            }
        })
    })
    return Map({ res, foundIndex })
})

const isLowerMiseryIndexThanFlightTrain = createSelector([flightTrainLowestMiseryIndex, getMinMiseryIndexSelector, currentSegmentSeqSelector], (flightTrainLowestMiseryItem, currentLowestMisery, currentSegmentSeq) => {
    if (currentSegmentSeq !== 0) return true
    return flightTrainLowestMiseryItem.get('res') < currentLowestMisery
})


//空铁组合
export const flightTrainCombinationSelector = createSelector([isNeedRecommend, isLowerMiseryIndexThanFlightTrain, flightTrainFlightsSelector, flightTrainLowestMiseryIndex], (isNeedRecommend, isLowerMiseryIndexThanFlightTrain, flightTrains, flightTrainLowestMiseryIndex) => {
    if (!isNeedRecommend || !isLowerMiseryIndexThanFlightTrain)
        return List([])
    return flightTrains.size > 0 ? flightTrains.unshift(flightTrains.get(flightTrainLowestMiseryIndex.get('foundIndex'))).splice(flightTrainLowestMiseryIndex.get('foundIndex') + 1, 1).map((flightTrain, index) => {
        if (index > 0)
            return flightTrain.set('_isFoldHide', true)
        return flightTrain
    }) : flightTrains
})
