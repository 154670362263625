import Enum from 'enum';

/**
 * 筛选控件类型：复选框或单选框
 */
const FILTER_CONTROL_TYPE = new Enum({
	/**
	 * 直接在筛选框展现复选框（不是HOVER后显示）
	 */
	FLAT_CHECK_BOX: {
		pop: false,
		hasClearButtonForPreview: true,
		classNameOfTitleForChoose: 'ico-checkbox',
		exclusiveInGroup: false,
		alwaysKeepOne: false,
	},
	/**
	 * 复选框
	 */
	CHECK_BOX: {
		pop: true,
		hasClearButtonForPreview: true,
		classNameOfTitleForChoose: 'ico-checkbox',
		exclusiveInGroup: false,
		alwaysKeepOne: false,
	},
	/**
	 * 单选复选框，奇葩控件
	 */
	SINGLE_SELECT_CHECK_BOX: {
		pop: true,
		hasClearButtonForPreview: true,
		classNameOfTitleForChoose: 'ico-checkbox',
		exclusiveInGroup: true,
		alwaysKeepOne: false,
	},
	/**
	 * 单选框
	 */
	RADIO_BUTTON: {
		pop: true,
		hasClearButtonForPreview: false,
		classNameOfTitleForChoose: 'ico-radio',
		exclusiveInGroup: true,
		alwaysKeepOne: true,
	},
});

export default FILTER_CONTROL_TYPE;
