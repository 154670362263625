import { getIfScopeDomestic } from '../sources/list/scope';

const handleLongName = function (name, isRemoveRoundBrackets, charLimit, outRangeDisplay = `...`) {
	let rRemoveRoundBrackets = /\(([^)]+)\)/g;
	let counter = 0,
		index,
		removed = rRemoveRoundBrackets.exec(name),
		sliceStart = removed ? removed.index : removed,
		res = isRemoveRoundBrackets && sliceStart ? name.slice(0, sliceStart) : name,
		tail = sliceStart === null ? '' : name.slice(sliceStart),
		isLong = false;
	for (let i = 0, len = res.length; i < len; ++i) {
		index = i;
		var charCode = res.charCodeAt(i);
		if (charCode > 0xff) {
			// char > 1 byte since charCodeAt returns the UTF-16 value
			counter += 2;
		} else {
			counter++;
		}
		if (counter >= charLimit) {
			isLong = true;
			index = i;
			break;
		}
	}
	if (isLong) {
		res = res.slice(0, index) + outRangeDisplay;
	}
	return isRemoveRoundBrackets ? res + tail : res;
};

function isObject(value) {
	const type = typeof value;
	return value != null && (type == 'object' || type == 'function');
}

/**
 * @since 0.1.0
 * @category Function
 * @param {Function} func The function to debounce.
 * @param {number} [wait=0] The number of milliseconds to delay.
 * @param {Object} [options={}] The options object.
 * @param {boolean} [options.leading=false]
 *  Specify invoking on the leading edge of the timeout.
 * @param {number} [options.maxWait]
 *  The maximum time `func` is allowed to be delayed before it's invoked.
 * @param {boolean} [options.trailing=true]
 *  Specify invoking on the trailing edge of the timeout.
 * @returns {Function} Returns the new debounced function.
 *
 */
function debounce(func, wait, options) {
	let lastArgs, lastThis, maxWait, result, timerId, lastCallTime;

	let lastInvokeTime = 0;
	let leading = false;
	let maxing = false;
	let trailing = true;

	if (typeof func != 'function') {
		throw new TypeError('Expected a function');
	}
	wait = +wait || 0;
	if (isObject(options)) {
		leading = !!options.leading;
		maxing = 'maxWait' in options;
		maxWait = maxing ? Math.max(+options.maxWait || 0, wait) : maxWait;
		trailing = 'trailing' in options ? !!options.trailing : trailing;
	}

	function invokeFunc(time) {
		const args = lastArgs;
		const thisArg = lastThis;

		lastArgs = lastThis = undefined;
		lastInvokeTime = time;
		result = func.apply(thisArg, args);
		return result;
	}

	function leadingEdge(time) {
		// Reset any `maxWait` timer.
		lastInvokeTime = time;
		// Start the timer for the trailing edge.
		timerId = setTimeout(timerExpired, wait);
		// Invoke the leading edge.
		return leading ? invokeFunc(time) : result;
	}

	function remainingWait(time) {
		const timeSinceLastCall = time - lastCallTime;
		const timeSinceLastInvoke = time - lastInvokeTime;
		const timeWaiting = wait - timeSinceLastCall;

		return maxing ? Math.min(timeWaiting, maxWait - timeSinceLastInvoke) : timeWaiting;
	}

	function shouldInvoke(time) {
		const timeSinceLastCall = time - lastCallTime;
		const timeSinceLastInvoke = time - lastInvokeTime;

		// Either this is the first call, activity has stopped and we're at the
		// trailing edge, the system time has gone backwards and we're treating
		// it as the trailing edge, or we've hit the `maxWait` limit.
		return (
			lastCallTime === undefined ||
			timeSinceLastCall >= wait ||
			timeSinceLastCall < 0 ||
			(maxing && timeSinceLastInvoke >= maxWait)
		);
	}

	function timerExpired() {
		const time = Date.now();
		if (shouldInvoke(time)) {
			return trailingEdge(time);
		}
		// Restart the timer.
		timerId = setTimeout(timerExpired, remainingWait(time));
	}

	function trailingEdge(time) {
		timerId = undefined;

		// Only invoke if we have `lastArgs` which means `func` has been
		// debounced at least once.
		if (trailing && lastArgs) {
			return invokeFunc(time);
		}
		lastArgs = lastThis = undefined;
		return result;
	}

	function cancel() {
		if (timerId !== undefined) {
			clearTimeout(timerId);
		}
		lastInvokeTime = 0;
		lastArgs = lastCallTime = lastThis = timerId = undefined;
	}

	function flush() {
		return timerId === undefined ? result : trailingEdge(Date.now());
	}

	function pending() {
		return timerId !== undefined;
	}

	function debounced(...args) {
		const time = Date.now();
		const isInvoking = shouldInvoke(time);

		lastArgs = args;
		lastThis = this;
		lastCallTime = time;

		if (isInvoking) {
			if (timerId === undefined) {
				return leadingEdge(lastCallTime);
			}
			if (maxing) {
				// Handle invocations in a tight loop.
				timerId = setTimeout(timerExpired, wait);
				return invokeFunc(lastCallTime);
			}
		}
		if (timerId === undefined) {
			timerId = setTimeout(timerExpired, wait);
		}
		return result;
	}
	debounced.cancel = cancel;
	debounced.flush = flush;
	debounced.pending = pending;
	return debounced;
}

// 搜索框url获取方法
// 单程往返
const getSingletripAndRoundtripURL = (queryObj) => {
	// 单程
	if (!queryObj.dCode || !queryObj.aCode) return;
	if (!queryObj.aDate) {
		return `oneway-${queryObj.dCode.toLowerCase()}-${queryObj.aCode.toLowerCase()}?depdate=${queryObj.dDate.format(
			'YYYY-MM-DD'
		)}${queryObj.directflight ? `&directflight=1` : ''}${queryObj.airline ? `&airline=${queryObj.airline}` : ''}`;
	} else {
		// 往返
		return `round-${queryObj.dCode.toLowerCase()}-${queryObj.aCode.toLowerCase()}?depdate=${queryObj.dDate.format(
			'YYYY-MM-DD'
		)}_${queryObj.aDate.format('YYYY-MM-DD')}${queryObj.directflight ? `&directflight=1` : ''}${
			queryObj.airline ? `&airline=${queryObj.airline}` : ''
		}`;
	}
};
const getDomesticCityUrl = (dCity, aCity) => {
	return `${dCity.cityCode}${dCity.airportCode ? ',' + dCity.airportCode : ''}-${aCity.cityCode}${
		aCity.airportCode ? ',' + aCity.airportCode : ''
	}`;
};

const getDomesticSingletripAndRoundtripURL = (queryObj) => {
	let url = '',
		{ dCity, aCity, changeTime } = queryObj;
	// 单程

	if (!aCity.cityCode || !dCity.cityCode) return;
	let cityUrl = getDomesticCityUrl(dCity, aCity);

	if (!queryObj.aDate) {
		url = `oneway/${cityUrl}?date=${queryObj.dDate.format('YYYY-MM-DD')}`;
		if (changeTime) {
			url = url + `&ct=${changeTime}`;
		}
	} else {
		// 往返
		url = `roundtrip/${cityUrl}?date=${queryObj.dDate.format('YYYY-MM-DD')},${queryObj.aDate.format('YYYY-MM-DD')}`;
	}
	return url.toLowerCase();
};

// 多程
const getMultitripURL = (queryArr) => {
	let cityCodeString = '',
		departDateString = '';
	// 处理往返的特殊情况
	if (queryArr.length === 2 && queryArr[0].dCode === queryArr[1].aCode && queryArr[0].aCode === queryArr[1].dCode) {
		if (queryArr[0].StartDate && queryArr[1].StartDate) {
			return `round-${queryArr[0].dCode.toLowerCase()}-${queryArr[0].aCode.toLowerCase()}?depdate=${queryArr[0].StartDate.format(
				'YYYY-MM-DD'
			)}_${queryArr[1].StartDate.format('YYYY-MM-DD')}`;
		}
	}
	queryArr.forEach((item) => {
		if (item.dCode && item.aCode && item.StartDate) {
			cityCodeString += `-${item.dCode.toLowerCase()}-${item.aCode.toLowerCase()}`;
			departDateString += `${item.StartDate.format('YYYY-MM-DD')}_`;
		}
	});
	if (departDateString) departDateString = departDateString.slice(0, -1);
	return `multi-${cityCodeString.slice(1)}?depdate=${departDateString}`;
};

const getDomesticMultitripURL = (queryArr) => {
	let cityCodeUrls = [],
		departDates = [];
	queryArr.forEach((item, i) => {
		// 国内多程只有两程
		if (i < 2) {
			let { dCity, aCity, date } = item;
			cityCodeUrls.push(getDomesticCityUrl(dCity, aCity));
			departDates.push(date.format('YYYY-MM-DD'));
		}
	});
	return `multiple/${cityCodeUrls.join('-').toLowerCase()}?date=${departDates.join(',')}`;
};

const pad = function (num) {
	return `${num < 10 ? '0' + num : num}`;
};

/**
 * 辅助函数：抽离出来方便虚拟航班设置最小价格项
 * @param {*搜索条件} prevCond
 * @param {*可见运价} visiblePriceList
 * @param {*需要设置的航班} flight
 * @param {*是否含税} isContainsTax
 */
const setMinPriceItemOfFlight = (prevCond, visiblePriceList, flight, isContainsTax) => {
	// 此处设置航班的最低运价，分几种情况：
	// 1）当前航班下面不存在任何资质限制类运价（比如限留学生购买），则所设置的最低运价就是该航班所有运价中的最低价
	// 2）当前航班下面存在资质限制类运价，但不全是资质限制类运价，则要排除掉这些限制类运价后求最低价
	// 3）当前航班的所有运价都是资质限制类运价，则所设置最低运价是全部运价中的最低价
	let priceListOfRestrictionIsNotEligibility = visiblePriceList.filter((price) => {
			if (getIfScopeDomestic()) {
				// 国内限制类不参与最低价排序,由后台下发字段dAllowAsLowestPrice
				return price.get('dAllowAsLowestPrice');
			} else {
				let restrictionList = price.get('restrictionList');
				return !restrictionList.some((restriction) => restriction.get('type') === 'Eligibility');
			}
		}),
		anyPriceOfRestrictionIsEligibility = priceListOfRestrictionIsNotEligibility.size < visiblePriceList.size,
		allPriceOfRestrictionIsEligibility = priceListOfRestrictionIsNotEligibility.size === 0,
		theMinPrice = null, // 符合条件的最低价
		theMinPriceCache, // 最低价，当没有符合条件的最低价时备用
		toLoopPriceList = null;

	// 婴童搜索
	// const {child, infant} = qs.parse(location.search.slice(1));
	const child = prevCond.get('childCount');
	const infant = prevCond.get('infantCount');

	//没有限制资质运价，或者全部都是限制资质运价，就用全部运价查找最低运价
	if (!anyPriceOfRestrictionIsEligibility || allPriceOfRestrictionIsEligibility) {
		toLoopPriceList = visiblePriceList;
	} else {
		toLoopPriceList = priceListOfRestrictionIsNotEligibility;
	}

	const priceField = isContainsTax ? 'avgPriceWithTax' : 'avgPriceWithoutTax';

	toLoopPriceList.forEach((price) => {
		if (!theMinPriceCache || price.get(priceField) < theMinPriceCache.get(priceField)) theMinPriceCache = price;
		if (!theMinPrice || price.get(priceField) < theMinPrice.get(priceField)) {
			// if (+child && +infant) {
			//     if (!price.get('childDisabledBook') && !price.get('infantDisabledBook')) theMinPrice = price;
			// } else if (+child) {
			//     if (!price.get('childDisabledBook')) theMinPrice = price;
			// } else if (+infant) {
			//     if (!price.get('infantDisabledBook')) theMinPrice = price;
			// }
			if ((child && price.get('childDisabledBook')) || (infant && price.get('infantDisabledBook'))) {
				void 0;
			} else {
				theMinPrice = price;
			}
		}
	});
	if (!theMinPrice) theMinPrice = theMinPriceCache;

	return flight
		.set('minPriceRouteSearchToken', theMinPrice.get('routeSearchToken'))
		.set('minPriceWithoutTax', theMinPrice.get('priceWithoutTax'))
		.set('minPriceWithTax', theMinPrice.get('priceWithTax'))
		.set('taxOfMinPriceWithoutTax', theMinPrice.get('tax'))
		.set('theMinPriceItem', theMinPrice)
		.set('priceList', visiblePriceList);
};

const popNextFormItem = (el, elForm, formItemCls = 'form-item-v2', focusHasVal) => {
	if (elForm && el) {
		let elFormItem = elForm.getElementsByClassName(formItemCls);
		let i,
			len = elFormItem.length,
			elInput;
		for (i = 0; i < len; i++) {
			elInput = elFormItem[i].querySelector('input');
			if (elInput && el && el.name && elInput.name && el.name == elInput.name) break;
		}
		do {
			i = i + 1;
			elInput = elFormItem[i] && elFormItem[i].querySelector('input');
		} while (elFormItem[i] && (!elInput || elInput.type !== 'text'));
		if (elInput) {
			if (focusHasVal === true) {
				elInput.focus();
			} else {
				if (!elInput.value) {
					elInput.focus();
				}
			}
		}
		return false;
	}
	return true;
};

const noop = () => {};

//是否出发城市是境内：国家是中国，除港澳台
const getIsDepartCountryDomestic = (flight) => {
	return (
		flight.get('departureCountryName') === '中国' && [32, 33, 53].indexOf(flight.get('departureProvinceId')) === -1
	);
};

//是否到达城市是境内：国家是中国，除港澳台
const getIsArrivalCountryDomestic = (flight) => {
	return flight.get('arrivalCountryName') === '中国' && [32, 33, 53].indexOf(flight.get('arrivalProvinceId')) === -1;
};

const getDocumentScrollTop = () => {
	let t = 0;
	if (document.documentElement && document.documentElement.scrollTop) {
		t = document.documentElement.scrollTop;
	} else if (document.body) {
		t = document.body.scrollTop;
	}
	return t;
};

// 判断指定元素有没有内容，一般用来判断是否渲染完成
const isRenderComplete = (keyClassName) => {
	if (typeof keyClassName !== 'string') return false;
	return (
		document.getElementsByClassName(keyClassName).length &&
		document.getElementsByClassName(keyClassName)[0].offsetHeight
	);
};

export function focusNextElement(target = document.activeElement) {
	const focusable =
		'a:not([disabled]), button:not([disabled]), input[type=text]:not([disabled]), [tabindex]:not([disabled]):not([tabindex="-1"])';
	const focusableElement = [...document.querySelectorAll(focusable)]
		?.filter((element) => element.offsetWidth > 0 || element.offsetHeight > 0 || element === target)
		?.sort((a, b) => {
			const at = a.getAttribute('tabindex') || '0';
			const bt = b.getAttribute('tabindex') || '0';
			return bt - at;
		});
	const index = focusableElement?.indexOf(target);
	if (index && index < focusableElement?.length - 1) {
		focusableElement[index + 1].focus();
	}
}

export {
	handleLongName,
	debounce,
	getDocumentScrollTop,
	getSingletripAndRoundtripURL,
	getDomesticSingletripAndRoundtripURL,
	getMultitripURL,
	getDomesticMultitripURL,
	pad,
	setMinPriceItemOfFlight,
	popNextFormItem,
	noop,
	getIsDepartCountryDomestic,
	getIsArrivalCountryDomestic,
	isRenderComplete,
};
