import React from 'react'

const SearchHotel = ({
    setSearchHotel,
    searchHotel
}) => {
    const { showFlightHotelSwitch, searchHotelV2Switch } = window.GlobalSwitches
    if (!showFlightHotelSwitch) return null

    return (<div onClick={() => { setSearchHotel(!searchHotel) }} className={`flt-direct ${searchHotel ? 'active' : ''}`}>
        {searchHotelV2Switch ? 
            <React.Fragment>
                <i className="ico-checkbox-square"></i>机票+酒店 <span className="tag-orange">更优惠</span>
            </React.Fragment> : 
            <React.Fragment>
                <i className="ico-checkbox-square"></i>同时搜索酒店
            </React.Fragment>
        }
    </div>)
}

export default SearchHotel
