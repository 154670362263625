import React from "react"
import { connect } from "react-redux"
import { addEventListener } from "consolidated-events"
import Multi from "./Multi"

class MultiContainer extends React.Component {
    constructor(props) {
        super(props)
        let seqItemStatus = []

        this.props.getMtSegList().map((seq, index) => {
            seqItemStatus.push({
                dCityFocus: null,
                aCityFocus: null,
                dDateFocus: !!(this.props.mtDDateIsPass && index == 0),
                activeExchangeCity: false,
                dCityActive: null,
                aCityActive: null
            })
        })
        this.state = {
            seqItemStatus: seqItemStatus,

        }
        this.onSetInputFocus = this.onSetInputFocus.bind(this)
        this.onHandleAddMtSeg = this.onHandleAddMtSeg.bind(this)
        this.onHandleDelMtSeg = this.onHandleDelMtSeg.bind(this)
        this.onSetActiveExchangeCity = this.onSetActiveExchangeCity.bind(this)
        this.onSetACityActive = this.onSetACityActive.bind(this)
        this.onSetDCityActive = this.onSetDCityActive.bind(this)
    }
    componentDidMount() {
        let thiz = this
        let inputs = document.getElementById('searchForm').querySelectorAll('input[type="text"]')
        let seqItemStatus = this.state.seqItemStatus
        for (let i = 0; i < inputs.length; i++) {
            addEventListener(
                inputs[i],
                "change",
                (e) => {
                    let target = e.target
                    if (target.value && target.name) {
                        if (target.name.indexOf('mtDCity') >= 0) {
                            let index = target.name.substr(target.name.length - 1) - 1
                            if (seqItemStatus[index]) {
                                seqItemStatus[index][`dCityHasVal`] = true
                            }
                        }
                        if (target.name.indexOf('mtACity') >= 0) {
                            let index = target.name.substr(target.name.length - 1) - 1
                            if (seqItemStatus[index]) {
                                seqItemStatus[index][`aCityHasVal`] = true
                            }
                        }
                    } else if (!target.value && target.name) {
                        if (target.name.indexOf('mtDCity') >= 0) {
                            let index = target.name.substr(target.name.length - 1) - 1
                            if (seqItemStatus[index]) {
                                seqItemStatus[index][`dCityHasVal`] = false
                            }
                        }
                        if (target.name.indexOf('mtACity') >= 0) {
                            let index = target.name.substr(target.name.length - 1) - 1
                            if (seqItemStatus[index]) {
                                seqItemStatus[index][`aCityHasVal`] = false
                            }
                        }
                    }
                    thiz.setState({
                        seqItemStatus: seqItemStatus
                    })
                }
            )
        }
    }

    onSetInputFocus(name, value, i) {
        let seqItemStatus = this.state.seqItemStatus
        switch (name) {
            case 'dCity':
                seqItemStatus[i][`dCityFocus`] = value
                break
            case 'aCity':
                seqItemStatus[i][`aCityFocus`] = value
                
                break
            case 'dDate':
                seqItemStatus[i][`dDateFocus`] = value
                break
        }
        this.setState({
            seqItemStatus: seqItemStatus
        })
    }
    onSetACityActive(index, active) {
        let seqItemStatus = this.state.seqItemStatus
        if (seqItemStatus[index]) {
            seqItemStatus[index][`aCityActive`] = active
        }
        this.setState({
            seqItemStatus: seqItemStatus
        })
    }
    onSetDCityActive(index, active) {
        let seqItemStatus = this.state.seqItemStatus
        if (seqItemStatus[index]) {
            seqItemStatus[index][`dCityActive`] = active
        }
        this.setState({
            seqItemStatus: seqItemStatus
        })
    }
    onHandleAddMtSeg() {
        this.state.seqItemStatus.push({
            dCityFocus: null,
            aCityFocus: null,
            dDateFocus: null,
            activeExchangeCity: false,
            dCityActive: null,
            aCityActive: null
        })
        this.setState({
            seqItemStatus: this.state.seqItemStatus
        })
        this.props.onAddMtSeg()
    }
    onHandleDelMtSeg(i) {
        this.props.onDelMtSeg(i)
        this.state.seqItemStatus.splice(i, 1)
        this.setState({
            seqItemStatus: this.state.seqItemStatus
        })
    }

    onSetActiveExchangeCity(i, value) {
        let seqItemStatus = this.state.seqItemStatus

        seqItemStatus[i][`activeExchangeCity`] = value

        this.setState({
            seqItemStatus: seqItemStatus
        })
    }
    render() {
        return (
            <Multi
                {...this.props}
                seqItemStatus={this.state.seqItemStatus}
                onSetInputFocus={this.onSetInputFocus}
                onHandleAddMtSeg={this.onHandleAddMtSeg}
                onHandleDelMtSeg={this.onHandleDelMtSeg}
                onSetActiveExchangeCity={this.onSetActiveExchangeCity}
                onSetACityActive={this.onSetACityActive}
                onSetDCityActive={this.onSetDCityActive}
            />
        )
    }
}


export default connect(null, null)(MultiContainer)
