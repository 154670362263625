import { fetchPost } from '../common'
import CONFIG_URL from '../../constants/list/api'
import varStore from '../../constants/list/varStore'

export function fetchInitXProductPreposeInfo(routeSearchToken, prevCond, airlineMemberToken) {
    let params = {
        routeSearchToken: routeSearchToken,
        searchCondition: prevCond
    }

    // X前置会员权益技改新增加 airlineMemberToken 参数
    if (airlineMemberToken) {
        params = {
            ...params,
            airlineMemberToken
        }
    }

    //状态缓存，防止多次调用
    varStore.PriceXProductPreposeInfoMap[routeSearchToken] =
        varStore.PriceXProductPreposeInfoMap[routeSearchToken] || {}
    const cacheObj = varStore.PriceXProductPreposeInfoMap[routeSearchToken]
    if (cacheObj.isLoading) {
        return cacheObj.promise
    }
    if (cacheObj.isLoaded) {
        return Promise.resolve(cacheObj.data || {})
    }

    // 开始请求
    cacheObj.isLoading = true
    cacheObj.data = null
    cacheObj.promise = fetchPost(CONFIG_URL.getXProductPrepose, params)
        .then(res => (res || {}).data)
        .then(
            d => {
                cacheObj.isLoading = false
                cacheObj.isLoaded = true
                if (!d || !d.data || !d.data.policyToken) {
                    return {}
                }
                cacheObj.data = d.data
                return d.data
            },
            () => {
                cacheObj.isLoading = false
                cacheObj.isLoaded = true
                cacheObj.data = null
            }
        )
    return cacheObj.promise
}
