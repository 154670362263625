import Enum from 'enum'
import React from 'react'

/**
 * 倾斜度舒适度
 */
export const COMFORT_CATEGORY = new Enum({
    COMFORT: '舒适',
    NORM: '标准',
    NARROW: '狭窄'
})

/**
 * 舱位
 */
export const CABIN_TYPE = new Enum({
    Y: '经济舱',
    S: '超级经济舱',
    F: '头等舱',
    C: '公务舱'
})

/**
 * 舒适度图标
 */
export const DEVICE_TYPE = new Enum({
    // hasMeal: {
    //     getLabel: (label) => <span className="device" key={label}><i className="ico-food-b"></i>餐饮</span>
    // },
    mealMsg: {
      getLabel: (label) => <span className="device" key={label}><i className="ico-food-b"></i>{label}</span>
  },
    wifi: {
        getLabel: (label) => <span className="device" key={label}><i className="ico-wifi-b"></i>{label}</span>
    },
    power: {
        getLabel: (label) => <span className="device" key={label}><i className="ico-power-b"></i>{label}</span>
    },
    entertainEquipment: {
        getLabel: (label) => <span className="device" key={label}><i className="ico-video-b"></i>{label}</span>
    }
})

/**
 * 舒适度图标
 */
export const SEAT_COMFORT_CATEGORY = new Enum({
    seatTilt: {
        getLabel: (label) => <div className="seat-item" key='seatTilt'><i className="ico-seat-angle"></i><div>{`${label.get('value') == 0 ? '暂无数据' : label.get('value') + '°'}`}</div><div dangerouslySetInnerHTML={{ __html: `${Math.round(label.get('value')) == 0 ? '&nbsp;' : COMFORT_CATEGORY[label.get('comfortCategory')].value}` }}></div></div>
    },
    seatWidth: {
        getLabel: (label) => <div className="seat-item" key='seatWidth'><i className="ico-seat-width"></i><div>{`${Math.round(label.get('value')) == 0 ? '暂无数据' : Math.round(label.get('value')) + 'cm'}`}</div><div dangerouslySetInnerHTML={{ __html: `${Math.round(label.get('value')) == 0 ? '&nbsp;' : COMFORT_CATEGORY[label.get('comfortCategory')].value}` }}></div></div>
    },
    seatPitch: {
        getLabel: (label) => <div className="seat-item" key='seatPitch'><i className="ico-seat-distance"></i><div>{`${Math.round(label.get('value')) == 0 ? '暂无数据' : Math.round(label.get('value')) + 'cm'}`}</div><div dangerouslySetInnerHTML={{ __html: `${Math.round(label.get('value')) == 0 ? '&nbsp;' : COMFORT_CATEGORY[label.get('comfortCategory')].value}` }}></div></div>
    }
})
/**
 * 舒适度图标
 */
export const DEVICE_TYPE_V2 = new Enum({
    hasMeal: {
        getLabel: (label) => <li key={label} className="food">餐食</li>
    },
    wifi: {
        getLabel: (label) => <li key={label} className="wifi">{label}</li>
    },
    power: {
        getLabel: (label) => <li key={label} className="electric">{label}</li>
    },
    entertainEquipment: {
        getLabel: (label) => <li key={label} className="entertainment">{label}</li>
    }
})

/**
 * 舒适度图标
 */
export const SEAT_COMFORT_CATEGORY_V2 = new Enum({
    seatTilt: {
        getLabel: (label) => <li className="chair-angle" key='seatTilt'>{label.get('value')}°&nbsp;({COMFORT_CATEGORY[label.get('comfortCategory')].value})</li>
    },
    seatWidth: {
        getLabel: (label) => <li className="chair-width" key='seatWidth'>{Math.round(label.get('value'))}cm&nbsp;({COMFORT_CATEGORY[label.get('comfortCategory')].value})</li>
    },
    seatPitch: {
        getLabel: (label) => <li className="chair-legnth" key='seatPitch'>{Math.round(label.get('value'))}cm&nbsp;({COMFORT_CATEGORY[label.get('comfortCategory')].value})</li>
    }
})
export class deviceTypeWrapper {
    constructor(label, key) {
        this.type = DEVICE_TYPE.get(key)
        this.label = label
    }
}

export class seatCategoryWrapper {
    constructor(label, key) {
        this.type = SEAT_COMFORT_CATEGORY.get(key)
        this.label = label
    }
}
export class deviceTypeWrapperV2 {
    constructor(label, key) {
        this.type = DEVICE_TYPE_V2.get(key)
        this.label = label
    }
}

export class seatCategoryWrapperV2 {
    constructor(label, key) {
        this.type = SEAT_COMFORT_CATEGORY_V2.get(key)
        this.label = label
    }
}
