import mergeConfig from '../../common/mergeConfig'
import Q_CONFIG_KEYS from '../qConfigKeys'

const PRICE_MESSAGE = {
    PRICE_TAG: {
        CaSpecialDiscount: '下单再减&yen;[PRICE]',
        CaSpecialInvite: '国航特邀',
        MuSpecialInvite: '东航特邀',
        MuGvDiscount: '东航GV人数专享',
        FlashSaleDiscount: '闪购特惠',
        MemberOnlyDiscount: '会员专享',
        BusinessCarTicketGift: '赠商务车票',
        CarRentalDiscount: '租车惠',
        CarRentalReduce200RMB: '租车减200',
        CashBack: '返现&yen;[VALUE]',
        FreeBusBoatTicketToHK: '含[GO_BACK_TYPE]通港[BUS_BOAT_TYPE]票',
        FreePickUp: '赠[CAR_TYPE][FLIGHT_WAY]机',
        FreeVipLounge: '赠[FLIGHT_WAY]贵宾休息室',
        GiftPack: '大礼包',
        GiftBox: '定制礼盒',
        SpeedIssueTicket: '极速出票',
        TodayFreeRefund: '甄选服务',
        PickUpCoupon: '赠接送机券',
        GeneralCoupon: '赠&yen;[PRICE][NAME]',
        FreeOnlineCheckIn: '送在线值机',
        CheaperPlusAnotherTrip: '多飞一程更便宜',
        FreeAirportBus: "赠[TYPE_NAME]巴士",
        SpringMember: "春秋会员价",
        MemberInterests: "[MEMBER_TYPE]会员乘机礼遇",
        Mileage: '赠里程',
    },
    PRICE_TAG_HOVER: {
        CaSpecialDiscount: '预订此航空公司特惠产品，每位乘机人下单时可享受<dfn>¥</dfn>[PRICE]优惠。<br>如需退订，<dfn>¥</dfn>[PRICE]特惠活动差额不退；<br>如需改签，需补齐<dfn>¥</dfn>[PRICE]特惠活动差额，更改后航班不再享受此优惠。',
        CaSpecialInvite: '国航特邀',
        MuSpecialInvite: '东航特邀',
        MuGvDiscount: '东航GV人数专享',
        FlashSaleDiscount: '闪购特惠',
        MemberOnlyDiscount: '会员专享',
        BusinessCarTicketGift: '赠商务车票',
        CarRentalDiscount: '租车惠',
        CarRentalReduce200RMB: '租车减200',
        CashBack: '出行24小时后携程账户返现RMB [VALUE]元。预订时未登录或机票发生退改则不享受此返现活动。',
        CashBack_Way_One: '填写Email，乘机后10天内完成返现邮件确认，乘机后10天内将获得[VALUE]元返现至您的返现账户。',
        CashBack_Way_Two: '乘机前关注微信公众号“携程旅行网”，并绑定您的携程账号，乘机后7天内通过携程官方微信推送的返现信息，对航班进行评分，乘机后10天内将获得[VALUE]元返现至您的返现账户。',
        FreeBusBoatTicketToHK: '全程选择带有包含通港车船票相关标签的航班，可免费领取特定航程通港车票或通港船票一份，仅限在乘机人填写页勾选通港车票或通港船票时使用，每位旅客仅限当天预订时使用一次。',
        FreePickUp: '订购此机票，额外享受免费国内[CAR_TYPE][FLIGHT_WAY]机服务，往返程中两程均展示此标签实现赠送，单程价值最高可达￥600',
        FreePickUpList: [],
        FreeVipLounge: '此贵宾专享产品除机票外，另可享[VALUE]贵宾休息室服务。休息室详情请点击“预订”按钮后，在预订页面查看。',
        GiftPack: '大礼包',
        GiftBox: '定制礼盒',
        SpeedIssueTicket: '<img class="ico-quick-ticket" alt="支付成功后，预计[VALUE]分钟内完成出票" src="//pic.c-ctrip.com/fltcommon/search_result/ico-speed.png">支付成功后，预计[VALUE]分钟内完成出票',
        TodayFreeRefund: '<div class="bs-title">— 甄选服务 —</div><ul class="bs-cont"><li><img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-speed-gold.png" />支付成功后，预计[ticketingTime]分钟内完成出票</li><li> <img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-ticket.png" />提供”[invoice]”作为报销凭证</li><li><img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-wallet.png" />预计当天[refundDeadline]([timezone]时间)前可免费退票</li></ul>',
        TodayFreeRefundTakeOffToday: '<div class="bs-title">— 甄选服务 —</div><ul class="bs-cont"><li><img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-speed-gold.png" />支付成功后，预计[ticketingTime]分钟内完成出票</li><li> <img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-ticket.png" />提供”[invoice]”作为报销凭证</li><li><img class="bs-icon" src="//pic.c-ctrip.com/fltcommon/search_result/ico-wallet.png" />预计当天[refundDeadline]([timezone]时间)前可免费退票，航班起飞后，不可享受免费退</li></ul>',
        PickUpCoupon: '订购此机票，即享接送机券服务',
        GeneralCoupon: '订购此机票，赠￥[PRICE][NAME]服务',
        FreeOnlineCheckIn: '无需操作，提前占座，省去外语值机繁琐步骤。',
        SpecialRefund24HoursList: '本行程享受24小时特惠退，支付完成24小时内退票，供应商仅收取小额退票服务费。具体退改签信息请点击“预订”按钮后，在下一页面查看',
        SpecialRefund24HoursBook: '本行程享受24小时特惠退，支付完成24小时内退票，供应商仅收取小额退票服务费。',
        FormatSpecialRefund24HoursWithoutFee: '本行程享受24小时特惠退，支付完成24小时内退票，退票免费。支付完成24小时后，以具体的退改签规则为准。',
        FormatSpecialRefund24HoursWithFee: '本行程享受24小时特惠退，支付完成24小时内退票，供应商仅收取退票服务费¥[VAL]/人。支付完成24小时后，以具体的退改签规则为准。',
        CheaperPlusAnotherTrip: '第一程([SEG_1_DEPART_CITY_NAME]-[SEG_1_ARRIVE_CITY_NAME]) + 第二程([SEG_2_DEPART_CITY_NAME]-[SEG_2_ARRIVE_CITY_NAME]) = &yen;[PRICE]',
        CheaperPlusAnotherTripTooltipTitle: '第二程指定航班：[SEG_2_DEPART_DATE]出发，[SEG_2_DEPART_CITY_NAME]-[SEG_2_ARRIVE_CITY_NAME]',
        FreeAirportBus: `
        <strong>赠送[CITY_NAME]跨城机场巴士</strong><br/>
        <strong>服务说明：</strong><br/>
        1.使用规则：巴士车票仅限出行当日使用，不预留座位，先到先得，请提前30分钟到达车站。<br/>
        2.乘车方式：旅客凭有效登机证件（限本人使用）到各城市乘车地点进行身份验证，经巴士柜台工作人员验证后乘车。<br/>
        <strong>退改说明：</strong><br/>
        巴士车票不得取消或修改。<br/>
        <strong>补充说明：</strong><br/>
        1.若机票退票改期，则无法再使用此巴士服务。<br/>
        2.由于旅客自身预留时间不足、巴士承运公司问题或其他不可抗力原因导致旅客不能正常乘坐后续的航班，属于携程免责范围。`,
        SpringMember: "此价格为春秋会员专享，填写完整信息注册即可享受",
        MacauFlight: "该活动适用于两人同行的场景，展示价格为享买一送一活动后的人均价格，优惠仅适用于票面价部分（不含税费），儿童和成人同价"
    },
    GV_TAG_HOVER: {
        Title: '本产品需同时满足以下条件才可预订',
        MultiPerson: '本产品限[VALUE](同行婴儿不计入人数限制范围)可订',
        MultiPersonNew: '本产品限[VALUE][LIMIT_TEXT]可订',
        Age: '本产品限[VALUE]才可以预订',
        Age_V2: '[LIMIT_PRE_FIX][VALUE]可订',
        Nationality: '本产品限[VALUE]可订',
        Eligibility: '本产品限[VALUE]可订',
        Eligibility_留学生: '本产品限留学生预订：购买此留学生票需提供与目的地相符的学生签证、学生证或入学通知书扫描件',
        Eligibility_学生: '本产品限学生预订：购买此学生票需提供与目的地相符的学生签证、学生证或入学通知书扫描件',
        Eligibility_海员: '本产品限海员预订：购买此海员票需提供海员证,护照复印件和授权委托书',
        Eligibility_移民: '本产品限移民预订：购买此移民票需提供移民签证的复印件',
        Eligibility_劳工: '本产品限劳工预订：购买此劳工票需提供劳工签证的复印件（劳工指出国务工人员，他们需取得雇佣公司的授权许可 ，并获取大使馆签发的劳务签证）',
        Multi: '限[VALUE]预订',
        Multi_留学生: '限留学生预订：购买此留学生票需提供与目的地相符的学生签证、学生证或入学通知书扫描件',
        Multi_学生: '限学生预订：购买此学生票需提供与目的地相符的学生签证、学生证或入学通知书扫描件',
        Multi_海员: '限海员预订：购买此海员票需提供海员证,护照复印件和授权委托书',
        Multi_移民: '限移民预订：购买此移民票需提供移民签证的复印件',
        Multi_劳工: '限劳工预订：购买此劳工票需提供劳工签证的复印件（劳工指出国务工人员，他们需取得雇佣公司的授权许可 ，并获取大使馆签发的劳务签证）'
    },
    PENALTY_POLICY_FALG_REMARK: {
        '1': '*携程不加收任何退改服务费，具体费用按航空公司官网规定收取',
        '2': '*携程不加收任何退改服务费，具体费用按航空公司规定收取',
        '3': '*携程不加收任何退改服务费，具体费用按产品提供方规定收取',
        '4': '*携程不加收任何退改服务费，具体费用按产品提供方规定收取',
        '5': '*携程不加收任何退改服务费，具体费用按产品提供方规定收取',
        Default: '*携程不加收任何退改服务费，具体费用按产品提供方规定收取'
    },
    BOOK_PRICE_TAG: {
        CaSpecialDiscount: '国航特惠'
    },
    BOOK_PRICE_TAG_HOVER: {
        CaSpecialDiscount: '预订此航空公司特惠产品，每位乘机人已享受立减[PRICE]元的特惠。如需退订，[PRICE]元立减金额不退；如需改期，需补齐[PRICE]元立减金额，更改后航班不可再享受立减特惠。',
    }
}

export default mergeConfig(Q_CONFIG_KEYS.PRICE_MSG, PRICE_MESSAGE)
