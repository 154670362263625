import React, { useState } from 'react';
import './domesticPriceType.css';

const TravelPackageComponent = ({ invoiceType }) => {
	const { desc, package: packageContent } = window.GlobalConfigs.travelpackage || {};
	const [foldMap, setFoldMap] = useState({});
	const invoiceData = invoiceType ? (invoiceType === 'F' ? desc.invoice.full : desc.invoice.difference) : {};
	if(!window.GlobalConfigs.travelpackage) {
		return null;
	} 
	const clickFn = (index) => {
		setFoldMap((pre) => {
			return {
				...pre,
				[index]: !pre[index],
			};
		});
	};
	return (
		<>
			<div className="triangle"></div>
			<div className="travelPackageBox">
				<div className="travelpackage">
					<div className="travelpackage-desc">
						<div className="travelpackage__title">{desc.title}</div>
						<div className="travelpackage-desc__content paddingTop">
							{desc.content.title + desc.content.desc}
						</div>
						<div className="travelpackage-desc__invoice">
							<div className="travelpackage-desc__content">{desc.invoice.title}</div>
							<div className={'travelpackage-desc__invoice-content'}>
								<div className="yellowFont">{invoiceData.title}</div>
								<div className="normalFont">{invoiceData.content}</div>
							</div>
						</div>
					</div>
					<div className="travelpackage-package">
						<div className="travelpackage__title">{packageContent.title}</div>
						<div className="travelpackage-desc__content2">{packageContent.desc}</div>
						{packageContent.content.map((item, index) => {
							return (
								<div className="travelpackage-package__content-item" key={index}>
									<div className="show-item">
										<div className="item-price">
											<div className="item-price-desc">{item.price.desc}</div>
											<div className="item-price-content">{item.price.content}</div>
										</div>
										<div className="item-content">
											<div className="item-tittle">{item.title}</div>
											<div className="item-date">{item.time}</div>
										</div>
									</div>
									<div className="item-desc">
										{item.desc}
										<div onClick={() => clickFn(index)}>
											{foldMap[index] ? (
												<i className="iconf-arrow-up">&#xe604;</i>
											) : (
												<i className="iconf-arrow-down">&#xe60c;</i>
											)}
										</div>
									</div>
									{foldMap[index] && (
										<div className="detail-content">
											{item.content.map((item, index) => {
												return (
													<div className="detail-content-item" key={index}>
														{item}
													</div>
												);
											})}
										</div>
									)}
								</div>
							);
						})}
					</div>
				</div>
			</div>
		</>
	);
};
export default TravelPackageComponent;
