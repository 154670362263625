import moment from 'moment';
import Immutable, { fromJS, List, Map } from 'immutable';
import { setPersistSearchLocalStorage, setSearchHistoryData } from '../../actions/common/searchDataStorageHelper';
import getMtSegList from './getMtSegList';

const getFlight = (flight) => {
	let owDCity = fromJS({
			text: flight.departureText,
			Code: flight.dCityCode,
			TimeZone: flight.dCityTimeZone,
			Name: flight.dCityName,
			CityId: flight.departureCityId,
			ParentCode: flight.dParentCode,
			domestic: flight.dIsDomestic,
		}),
		owACity = fromJS({
			text: flight.arrivalText,
			Code: flight.aCityCode,
			TimeZone: flight.aCityTimeZone,
			Name: flight.aCityName,
			CityId: flight.arrivalCityId,
			domestic: flight.aIsDomestic,
		});
	// 检查航程是否有机场

	if (flight.isDAirport && flight.dParentCode) {
		let dCityCode =
			flight.isDAirport && flight.dCityCode.lastIndexOf('0') > 0
				? flight.dCityCode.substr(0, flight.dCityCode.lastIndexOf('0'))
				: flight.dCityCode;
		let dType = flight.isDAirport && flight.dCityCode.lastIndexOf('0') > 0 ? 'airport' : '';
		let dTypeId = flight.isDAirport && flight.dCityCode.lastIndexOf('0') > 0 ? '3' : '';
		owDCity = fromJS({
			text: flight.departureText,
			Code: dCityCode,
			TimeZone: flight.dCityTimeZone,
			Name: flight.dCityName,
			CityId: flight.departureCityId,
			type: dType,
			ParentCode: flight.dParentCode,
			Type: dTypeId,
			domestic: flight.dIsDomestic,
		});
	}
	if (flight.isAAirport && flight.aParentCode) {
		let aCityCode =
			flight.isAAirport && flight.aCityCode.lastIndexOf('0') > 0
				? flight.aCityCode.substr(0, flight.aCityCode.lastIndexOf('0'))
				: flight.aCityCode;
		let aType = flight.isAAirport && flight.aCityCode.lastIndexOf('0') > 0 ? 'airport' : '';
		let aTypeId = flight.isAAirport && flight.aCityCode.lastIndexOf('0') > 0 ? '3' : '';
		owACity = fromJS({
			text: flight.arrivalText,
			Code: aCityCode,
			TimeZone: flight.aCityTimeZone,
			Name: flight.aCityName,
			CityId: flight.arrivalCityId,
			type: aType,
			ParentCode: flight.aParentCode,
			Type: aTypeId,
			domestic: flight.aIsDomestic,
		});
	}
	return {
		owDCity,
		owACity,
	};
};

/**
 * 还原state
 * @param {*初始state} state
 * @param {*查询结果} searchResult
 * @param {*} stateField
 */
const setSearchResultToStore = (state, searchResult = {}, stateField) => {
	let mtInfoArray = searchResult.mtInfoArray || [],
		selectedCities = state.getIn([stateField, 'selectedCity']),
		newClassGrade = [],
		storageData = {};
	let newSearchState = state,
		len = mtInfoArray.length,
		canAddMoreMtSeg = len < 6;
	let checkDate = (d) => {
		if (!d) return null;
		else return moment(d);
	};
	if (len > 0) {
		for (let index = 0; index < len; index++) {
			let mtInfo = mtInfoArray[index];
			if (index == 0) {
				// let owDCity = fromJS({ text: mtInfo.departureText, Code: mtInfo.dCityCode, TimeZone: mtInfo.dCityTimeZone, CityId: mtInfo.departureCityId }),
				//     owACity = fromJS({ text: mtInfo.arrivalText, Code: mtInfo.aCityCode, TimeZone: mtInfo.aCityTimeZone, CityId: mtInfo.arrivalCityId })
				let { owDCity, owACity } = getFlight(mtInfo);
				selectedCities = selectedCities
					.set('owDCity', owDCity)
					.set('owACity', owACity)
					.set('rtDCity', owDCity)
					.set('rtACity', owACity);
				let dDate = checkDate(mtInfo.departureDate);
				newSearchState = state.setIn(['search', 'owDDate'], dDate).setIn(['search', 'rtDDate'], dDate);
				storageData = {
					owDDate: dDate.format('YYYY-MM-DD'),
					rtDDate: dDate.format('YYYY-MM-DD'),
				};
			}
			selectedCities = selectedCities
				.set(
					`mtDCity${index + 1}`,
					Map()
						.set('text', mtInfo.departureText)
						.set('Code', mtInfo.dCityCode)
						.set('Name', mtInfo.dCityName)
						.set('TimeZone', mtInfo.dCityTimeZone)
						.set('CityId', mtInfo.departureCityId)
						.set('domestic', mtInfo.dIsDomestic)
				)
				.set(
					`mtACity${index + 1}`,
					Map()
						.set('text', mtInfo.arrivalText)
						.set('Code', mtInfo.aCityCode)
						.set('Name', mtInfo.aCityName)
						.set('TimeZone', mtInfo.aCityTimeZone)
						.set('CityId', mtInfo.arrivalCityId)
						.set('domestic', mtInfo.aIsDomestic)
				)
				.set(`mtStartDate${index + 1}`, checkDate(mtInfo.departureDate));
		}
	} else {
		// search需要回填单程,往返信息
		if (stateField === 'search') {
			// let owDCity = fromJS({ text: searchResult.departureText, Code: searchResult.dCityCode, TimeZone: searchResult.dCityTimeZone, Name: searchResult.dCityName, CityId: searchResult.departureCityId, ParentCode: searchResult.dParentCode }),
			//     owACity = fromJS({ text: searchResult.arrivalText, Code: searchResult.aCityCode, TimeZone: searchResult.aCityTimeZone, Name: searchResult.aCityName, CityId: searchResult.arrivalCityId })
			// if (searchResult.isDAirport && searchResult.dParentCode) {
			//     let dCityCode = searchResult.isDAirport && searchResult.dCityCode.lastIndexOf('0') > 0 ? searchResult.dCityCode.substr(0, searchResult.dCityCode.lastIndexOf('0')) : searchResult.dCityCode
			//     let dType = searchResult.isDAirport && searchResult.dCityCode.lastIndexOf('0') > 0 ? "airport" : ""
			//     let dTypeId = searchResult.isDAirport && searchResult.dCityCode.lastIndexOf('0') > 0 ? "3" : ""
			//     storageOwDCity = { text: searchResult.departureText, Code: dCityCode, TimeZone: searchResult.dCityTimeZone, Name: searchResult.dCityName, CityId: searchResult.departureCityId, type: dType, ParentCode: searchResult.dParentCode, Type: dTypeId }
			// }
			let { owDCity, owACity } = getFlight(searchResult);

			if (searchResult.flightWay === 'OW') {
				selectedCities = selectedCities
					.set('owDCity', owDCity)
					.set('owACity', owACity)
					.set('rtDCity', owDCity)
					.set('rtACity', owACity);
				let dDate = checkDate(searchResult.departureDate);
				newSearchState = state.setIn(['search', 'owDDate'], dDate).setIn(['search', 'rtDDate'], dDate);
				storageData = {
					owDDate: dDate.format('YYYY-MM-DD'),
					owADate: '',
					rtDDate: dDate.format('YYYY-MM-DD'),
				};
			}

			if (searchResult.flightWay === 'RT') {
				selectedCities = selectedCities
					.set('rtDCity', owDCity)
					.set('rtACity', owACity)
					.set('owDCity', owDCity)
					.set('owACity', owACity);
				newSearchState = state
					.setIn(['search', 'rtDDate'], checkDate(searchResult.departureDate))
					.setIn(['search', 'rtADate'], checkDate(searchResult.arrivalDate))
					.setIn(['search', 'owDDate'], checkDate(searchResult.departureDate));
				storageData.rtDDate = searchResult.departureDate;
				storageData.rtADate = searchResult.arrivalDate;
				storageData.owDDate = searchResult.departureDate;
			}
		}

		selectedCities = selectedCities
			.set(
				'mtDCity1',
				fromJS({
					text: searchResult.departureText,
					Code: searchResult.dCityCode,
					TimeZone: searchResult.dCityTimeZone,
					Name: searchResult.dCityName,
					CityId: searchResult.departureCityId,
					domestic: searchResult.dIsDomestic,
				})
			)
			.set(
				'mtACity1',
				fromJS({
					text: searchResult.arrivalText,
					Code: searchResult.aCityCode,
					Name: searchResult.aCityName,
					TimeZone: searchResult.aCityTimeZone,
					CityId: searchResult.arrivalCityId,
					domestic: searchResult.aIsDomestic,
				})
			)
			.set('mtStartDate1', checkDate(searchResult.departureDate));
		// .set('mtStartDate2', checkDate(searchResult.arrivalDate))
	}
	if (stateField === 'ticketRequest') {
		newClassGrade = searchResult.classGrade && searchResult.classGrade.split('_');
	}
	let classGrade = stateField == 'ticketRequest' ? List(newClassGrade) : searchResult.classGrade;
	// 保存下查询数据,同步 频道首页查询数据
	if (stateField === 'search') {
		storageData.selectedCity = selectedCities.toJS();

		storageData.flightWay = searchResult.flightWay;
		storageData.infantCount = searchResult.infantCount;
		storageData.childCount = searchResult.childCount;
		storageData.adultCount = searchResult.adultCount;

		storageData.classGrade = classGrade;
		setPersistSearchLocalStorage(storageData);
		setSearchHistoryData(storageData);
	}
	const passenger = {
		adtCount: searchResult.adultCount,
		chiCount: searchResult.childCount,
		infCount: searchResult.infantCount,
	};
	return newSearchState
		.setIn([stateField, 'selectedCity'], selectedCities)
		.setIn([stateField, 'passenger'], Immutable.fromJS(passenger))
		.setIn([stateField, 'classGrade'], classGrade)
		.setIn([stateField, 'flightWay'], searchResult.flightWay)
		.setIn([stateField, 'canAddMoreMtSeg'], canAddMoreMtSeg);
};

export { getMtSegList, setSearchResultToStore };
