import { sendTrace } from '@/src/sources/common/lightUbt';

// 存储埋点相关的内容
export const ubtGlobalState = {
	// poi相关埋点
	poi: {
		poiNoResult: [],
	},
	RecommendProduct: {
		SourceInfo: [],
		TopInfo: [],
		CentralFirstInfo: [],
		CentralSecondInfo: [],
		CentralThirdInfo: [],
	},
	/* 两舱推荐经济舱：仅满足两舱推荐经济舱时传值，否则为空 */
	FC_rec_SY: {},
	NoResultRecommend: [],
	Search_no_result: null,
};

// ubt里面是一些埋点公共的方法

export class Ubt {
	constructor(_props) {
		// 埋点对象
		// 通用点击类
		this.commonClickUbt = {};
		// 通用hover类
		this.commonHoverUbt = {};
	}
	// ubt通用点击hook
	addUbtHook(ubt) {
		return (e) => {
			e.target && e.target.setAttribute && e.target.setAttribute('data-ubt-key', ubt);
		};
	}

	// 注册事件
	registerEvent() {
		const report = () => {
			this.reportCommonClickToApi();
			this.reportCommonHoverToApi();
		};
		if (document.addEventListener) {
			window.addEventListener('beforeunload', report);
		} else {
			console.log('离开页面注册出错!');
		}
	}
	// 点击类通用埋点
	onCommonClick(e) {
		if (e.target.length && e.target[0].getAttribute) {
			this.setDataUbtKey(e.target[0]);
		} else if (e.target && e.target.getAttribute) {
			this.setDataUbtKey(e.target);
		}
	}

	// hover通用埋点
	onCommonHover(key) {
		let ubtKey = `c_${key}_hover`;
		this.commonHoverUbt[ubtKey] = 1 + (this.commonHoverUbt[ubtKey] || 0);
	}

	// 点击类通用埋点计数
	setDataUbtKey(target) {
		let key = target.getAttribute('data-ubt-key');
		if (!key) {
			key = target.getAttribute('data-ubt');
		}

		if (key) {
			this.commonClickUbt[key] = 1 + (this.commonClickUbt[key] || 0);
		}
	}
	// 上报点击类通用埋点计数
	reportCommonClickToApi() {
		this.reportToApi(this.commonClickUbt, this.COMMON_CLICK_UBT_KEY);
	}

	// 上报HOVER类通用埋点计数
	reportCommonHoverToApi() {
		this.reportToApi(this.commonHoverUbt, this.COMMON_HOVER_UBT_KEY);
	}

	// eslint-disable-next-line class-methods-use-this
	reportToApi(obj, key) {
		sendTrace(key, obj);
	}
}

export default new Ubt();
