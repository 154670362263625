// import { getRoutePath } from '@/src/common/utils/utils';
import { ModalOptions, IModal } from '../types';
import { genId } from './utils';
import { ActionType } from './store';
import { eventManager } from './eventManager';

const createModal = (opts: ModalOptions): IModal => ({
    createdAt: Date.now(),
    autoClose: true,
    // animationClassName: 'animation-default',
    ...opts,
    id: opts?.id || genId(),
});

const createHandler = (options) => {
    // const pageName = getRoutePath();
    const pageName = 'booking';
    const modal = createModal(options);
    // dispatch({ type: ActionType.UPSERT_MODAL, modal });
    eventManager.emit(pageName, { type: ActionType.UPSERT_MODAL, modal });
    return modal.id;
};

export const showModal = (opts: ModalOptions) => createHandler(opts);

export const closeModal = (modalId?: string) => {
    // const pageName = getRoutePath();
    const pageName = 'booking';
    eventManager.emit(pageName, { type: ActionType.REMOVE_MODAL, modalId });
};
