export const getFlightAircraftSizeList = (flight, currentSegmentSeq) => {
    let currentSegment = flight.getIn(['flightSegments', currentSegmentSeq])
    let aircraftSizeList = []

    if (currentSegment) {
        const flightList = currentSegment.get('flightList').valueSeq()
        const mainSegment = flightList.find(flightItem => flightItem.get('mainSegment') && !flightItem.get('containsVirtualFlight'))     // 国内：主航段
        const nonVirtualFlightSegmentList = flightList.filter(flightItem => !flightItem.get('containsVirtualFlight'))     // 国际：非虚拟航班航段

        return mainSegment
            ? [mainSegment.get('aircraftSize', '')].filter(Boolean)
            : nonVirtualFlightSegmentList.map(flightItem => flightItem.get('aircraftSize', '')).toJS().filter(Boolean)
    }

    return aircraftSizeList
}