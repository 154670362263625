import { InternalAxiosRequestConfig } from 'axios';
import { ubtError } from '@/src/sources/common/lightUbt';

// offline 需求，把url上token参数带到接口header中
const offlineInterceptor = (config: InternalAxiosRequestConfig<any>) => {
	try {
		const { token } = window.offlineInfo || {};
		if (token) {
			const { headers } = config;
			return {
				...config,
				headers: {
					...headers,
					'offline-record-token': token,
				},
			};
		}
	} catch (e) {
		ubtError(e);
	}
	return config;
};

export default offlineInterceptor;
