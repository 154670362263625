import Immutable from 'immutable';
import genCabinHoverTooltipFragment from './genCabinHoverTooltipFragment';
import getCabinListTitle from './getCabinListTitle';

/**
 * 生成所有航程的舱等HOVER内容
 * @param
 * @param {*舱等集合} cabinEnumList
 */
const getAllSegsCabinHoverContent = (prevCond, cabinEnumList: Immutable.List<any>, flightSegments, price) => {
	let flightIndex = -1;
	const flightList = prevCond
		.get('flightSegments')
		.filter((seg, index) => index < flightSegments.length)
		.map((seg, index) => {
			flightIndex++;

			return {
				index,
				departureCityName: seg.get('departureCityName'),
				arrivalCityName: seg.get('arrivalCityName'),
				cabinEnum: cabinEnumList.size > 1 ? cabinEnumList.get(flightIndex) : cabinEnumList.get(0),
			};
		});

	return genCabinHoverTooltipFragment(flightList, price, (flight, innerIndex) => {
		return flight.cabinEnum ? getCabinListTitle(flight.cabinEnum, [flightSegments[innerIndex]]) : '';
	});
};

export default getAllSegsCabinHoverContent;
