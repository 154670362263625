/**
 * 获取航班所有报销凭证类型，以及对应的最低价
 * @param {*航班集合} flights
 */
const getAllInvoiceTypesOfFlights = (flights) => {
	let invoiceTypeWithPrice = {}; // { T:  {minAvgPriceWithTax: 1100, minAvgPriceWithoutTax: 1100}, F:  {minAvgPriceWithTax: 1200, minAvgPriceWithoutTax: 1200}}

	flights.forEach((flight) => {
		flight.get('priceList').forEach((price) => {
			const invoiceTypeList = price.get('invoiceType', '_').split('_');
			invoiceTypeList.forEach((invoiceType) => {
				const avgPriceWithTax = price.getIn(['priceWithTax', 'avg']);
				const avgPriceWithoutTax = price.getIn(['priceWithoutTax', 'avg']);

				if (!invoiceTypeWithPrice[invoiceType]) {
					invoiceTypeWithPrice[invoiceType] = {
						minAvgPriceWithTax: Infinity,
						minAvgPriceWithoutTax: Infinity,
					};
				}

				if (avgPriceWithTax < invoiceTypeWithPrice[invoiceType].minAvgPriceWithTax) {
					invoiceTypeWithPrice[invoiceType].minAvgPriceWithTax = avgPriceWithTax;
				}

				if (avgPriceWithoutTax < invoiceTypeWithPrice[invoiceType].minAvgPriceWithoutTax) {
					invoiceTypeWithPrice[invoiceType].minAvgPriceWithoutTax = avgPriceWithoutTax;
				}
			});
		});
	});

	return invoiceTypeWithPrice;
};

export default getAllInvoiceTypesOfFlights;
