export const H5GATEWAY_HOST =
	window.runtimeEnv === 'pro' ? 'https://m.ctrip.com' : 'https://gateway.m.fws.qa.nt.ctripcorp.com';

// 日历低价单程返程
export const getCalendarLowestPrice = `${H5GATEWAY_HOST}/restapi/soa2/15380/bjjson/FlightIntlAndInlandLowestPriceSearch`;

// 获取精选地区
// export const districtListUrl = `${H5GATEWAY_HOST}/restapi/soa2/15095/json/flightCityPageData`;

// 精选地区低价日历
export const countryLowPriceUrl = `${H5GATEWAY_HOST}/restapi/soa2/15095/json/flightMultiRotesLowPriceCalendar`;

// 低价速报, 低价地图， 模糊搜索
export const fuzzySearch = `${H5GATEWAY_HOST}/restapi/soa2/19728/json/fuzzySearch`;

export const FlightSameCityDetect = `${H5GATEWAY_HOST}/restapi/soa2/15095/json/FlightSameCityDetect`;
