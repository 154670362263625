import React, { useEffect, useCallback, useState, useRef } from 'react';
import { ExposeStat } from '@/src/ubt.v2/exposeStat';
import PopDialog from '../../common/PopDialog';
import { fetchPost } from '../../../../actions/common';
import CONFIG_URL from '../../../../constants/list/api';
import { lightUpload, lightUploadSpeed } from "../../../../sources/common/lightUbt";

/**
 * 安全弹窗
 */
export const SecurityPopup = ({ onReSearch, requestName = '' }) => {
	const [disabledResearch, setDisabledResearch] = useState(true);
	const verification = useRef<any>(null);
	const slidObj = useRef<any>(null);

	const successHandle = useCallback((e, res) => {
		lightUpload('c_verified_success_handle', {
			text: '反爬虫验证通过',
			洗白结果: JSON.stringify(res.data),
		});

		setDisabledResearch(false);
	}, []);

	useEffect(() => {
		if (!window.jigsawVerification) {
			return;
		}
		lightUploadSpeed({
			speed: 'c_verification_open',
		});
		// eslint-disable-next-line new-cap
		if (!slidObj.current) {
			slidObj.current = new window.jigsawVerification({
				id: 'J_slider_verification', // 验证码DIV的id
				appId: '100008370', // appId
				businessSite: 'search_airticketscivil_online_pic', // businessSite
				width: '330px', // 验证码div宽度,高度是根据宽度变化的，有比例关系
				/**
				 * 状态监听函数
				 * @param state 0：出现拼图验证码；1：出现选图标验证码；2：选图标验证码消失
				 */
				stateChange: function (state) {
					// ~2.3.0中添加
					if (state == 0) {
						// console.log("拼图显示");
						lightUploadSpeed({
							speed: 'c_verification_result_handle',
							desc: '拼图显示'
						});
					} else if (state == 1) {
						// console.log("选图标验证码出现");
						lightUploadSpeed({
							speed: 'c_verification_result_handle',
							desc: '选图标验证码出现'
						});
					} else if (state == 2) {
						// console.log("选图标验证码消失");
						lightUploadSpeed({
							speed: 'c_verification_result_handle',
							desc: '选图标验证码消失'
						});
					}
				},
				// 拼图错误后调用的函数
				onJigsawFail: function () {
					lightUploadSpeed({
						speed: 'c_verification_result_handle',
						desc: '拼图错误'
					});
					console.log('fail');
				},
				// 选图标错误后调用的函数
				onSelectFail: function () {
					lightUploadSpeed({
						speed: 'c_verification_result_handle',
						desc: '选图标错误'
					});
					console.log('select fail');
				},
				resultHandler: function (e) {
					lightUpload('c_verification_result_handle', {
						checkState: e.checkState,
					});
					lightUploadSpeed({
						speed: 'c_verification_result_handle',
						desc: e.checkState,
					});
					if (e.checkState === 'error' || e.checkState === 'overtime' || e.checkState === 'false') {
						setTimeout(function () {
							slidObj.refresh();
							if (verification.current && verification.current.firstChild) {
								verification.current.firstChild.style.display = 'block';
							}
						}, 200);
					}
					if (e.checkState === 'success' || e.checkState === 'hidden') {
						// 验证成功
						fetchPost(CONFIG_URL.getVerifyPicAuthCode, {
							version: e.version,
							token: e.token,
							rid: e.rid,
						}).then((res) => {
							const data = res && res.data && res.data.data;
							if (data && data.verifyTokenResult === 'true') {
								if (data.updateWhiteListResult === '0') {
									successHandle(e, res);
									lightUploadSpeed({
										speed: 'c_verified_success_handle',
										desc: '洗白成功',
										requestName
									});
								} else {
									verification.current.innerHTML =
										'<div class="alert-body">你的账号存在异常风险或请求次数过多，请稍后重新尝试。</div>';
									successHandle(e, res);
									lightUploadSpeed({
										speed: 'c_verified_success_handle',
										desc: '洗白失败',
										requestName
									});
								}
							} else {
								lightUploadSpeed({
									speed: 'c_verified_success_handle',
									desc: '洗白异常',
									requestName
								});
								setTimeout(() => {
									slidObj.refresh();
								}, 1000);
							}
						});
					}
					if (e.checkState === 'default') {
						setTimeout(() => {
							if (verification.current && verification.current.firstChild) {
								verification.current.firstChild.style.display = 'block';
							}
						}, 200);
					}
				},
			});
		}
		return () => {
			if (slidObj.current) {
				slidObj.current.clear();
				slidObj.current = null;
			}
			if (verification.current) {
				verification.current.clear();
				verification.current = null;
			}
		};
	}, [successHandle]);

	return (
		<PopDialog
			contents={
				<ExposeStat ubtKey="c_security_popup_show">
					<div className="basic-alert alert-giftinfo" style={{ width: '340px' }}>
						<div className="alert-title">为保障您的安全访问，请完成以下操作</div>
						<div className="alert-body">
							<div id="J_slider_verification" ref={verification}></div>
						</div>
						<div className="alert-footer">
							<div className="btn-group">
								<button
									disabled={disabledResearch}
									className={'btn' + (disabledResearch ? ' disable' : '')}
									onClick={() => onReSearch()}
								>
									重新搜索
								</button>
							</div>
						</div>
					</div>
				</ExposeStat>
			}
		/>
	);
};
