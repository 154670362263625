const PRICE_TAG_OF_BRAND = 'Brand'
const PRICE_TAG_OF_CHOOSE_X = 'ChooseX'

const getPriceTagOfType = (price, type) => {
    if (price && type && price.priceTags && price.priceTags.length) {
        const theTag = price.priceTags.find(priceTag => priceTag.type === type && priceTag.data)

        return theTag
    } else {
        return null
    }
}

// 获取品牌运价信息
export const getBrandPriceTag = price => getPriceTagOfType(price, PRICE_TAG_OF_BRAND)

// 获取三选X运价信息
export const getChooseXPriceTag = price => getPriceTagOfType(price, PRICE_TAG_OF_CHOOSE_X)

// 此函数返回是否需要对内嵌价格进行字段设置，目前也就品牌运价的内嵌价格是完整价格（三选X的内嵌价格只有少量字段，因为其他字段都跟
export const ifFullRelatedPrice = price => !!getBrandPriceTag(price)

// 获取内嵌价格（包含：品牌运价，三选X）
const getRelatedPrices = (price, type) => {
    const allowedPriceTag = getPriceTagOfType(price, type)

    // 是否存在允许包含内嵌价格的 PriceTag
    if (allowedPriceTag) {
        // 可能会有内嵌运价，点击选择运价时候展开显示，内嵌运价不包括当前运价自身，但展开时候又会并排显示
        if (allowedPriceTag.data.relatedPrices && allowedPriceTag.data.relatedPrices.length) {
            return allowedPriceTag.data.relatedPrices
        } else {
            return null
        }
    } else {
        return null
    }
}

export const getRefinedRelatedPrices = price => {
    const brandRelatedPrices = getRelatedPrices(price, PRICE_TAG_OF_BRAND)
    const chooseXRelatedPrices = getRelatedPrices(price, PRICE_TAG_OF_CHOOSE_X)

    if (brandRelatedPrices) {
        return brandRelatedPrices
    } else if (chooseXRelatedPrices) {
        // 此处需要注意，品牌运价的 relatedPrices 字段是完整的价格信息
        // 而三选X的 relatedPrices 只是包含少量必备的X详情信息，所以需要将运价自身的字段复制
        return chooseXRelatedPrices.map(innerPrice => {
            return {
                ...price,
                priceTags: [],
                ...innerPrice
            }
        })
    } else {
        return null
    }
}

export const getPriceTagOfIncludingRelatedPrice = price => {
    return getBrandPriceTag(price) || getChooseXPriceTag(price)
}