import CONSTS from '../../constants/common/constConfig'

/**
* 初始化运价中的行李额部分
* @param {行李额} baggage 
*/
export const initTheBaggage = (baggage, pageStep) => {
    if (!baggage.dataList) {
        baggage.dataList = []
    }

    let hasBaggageService = baggage.hasBaggageService

    if ((baggage.dataList.length === 0) && pageStep !== '2') {
        // S2页面不需要提示后续行李额
        baggage.defaultHover = CONSTS.BAGGAGE.DEFAULT_NO_BAGGEAGE_HOVER
    } else {
        if (baggage.dataList.every(data =>
            //包括成人儿童婴儿的手提行李、托运行李，都是没有尺寸才需要有 温馨提示
            !(data.adultBaggage && data.adultBaggage.checkedBaggage && data.adultBaggage.checkedBaggage.hasSizeLimit) &&
            !(data.adultBaggage && data.adultBaggage.handBaggage && data.adultBaggage.handBaggage.hasSizeLimit) &&
            !(data.childBaggage && data.childBaggage.checkedBaggage && data.childBaggage.checkedBaggage.hasSizeLimit) &&
            !(data.childBaggage && data.childBaggage.handBaggage && data.childBaggage.handBaggage.hasSizeLimit) &&
            !(data.infantBaggage && data.infantBaggage.checkedBaggage && data.infantBaggage.checkedBaggage.hasSizeLimit) &&
            !(data.infantBaggage && data.infantBaggage.handBaggage && data.infantBaggage.handBaggage.hasSizeLimit))) {
            baggage.footer = `温馨提示: ${CONSTS.BAGGAGE.DEFAULT_DETAIL_FOOTER_HOVER}`
        } else {
            //样式待美化，牛皇皇说先不改
            // baggage.dataList.forEach(detail => {
            //     if (detail.adultBaggage && detail.adultBaggage.handBaggage && !detail.adultBaggage.handBaggage.hasSizeLimit && detail.adultBaggage.handBaggage.baggageContent) {
            //         detail.adultBaggage.handBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }
            //     if (detail.adultBaggage && detail.adultBaggage.checkedBaggage && !detail.adultBaggage.checkedBaggage.hasSizeLimit && detail.adultBaggage.checkedBaggage.baggageContent) {
            //         detail.adultBaggage.checkedBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }

            //     if (detail.childBaggage && detail.childBaggage.handBaggage && !detail.childBaggage.handBaggage.hasSizeLimit && detail.childBaggage.handBaggage.baggageContent) {
            //         detail.childBaggage.handBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }
            //     if (detail.childBaggage && detail.childBaggage.checkedBaggage && !detail.childBaggage.checkedBaggage.hasSizeLimit && detail.childBaggage.checkedBaggage.baggageContent) {
            //         detail.childBaggage.checkedBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }

            //     if (detail.infantBaggage && detail.infantBaggage.handBaggage && !detail.infantBaggage.handBaggage.hasSizeLimit && detail.infantBaggage.handBaggage.baggageContent) {
            //         detail.infantBaggage.handBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }
            //     if (detail.infantBaggage && detail.infantBaggage.checkedBaggage && !detail.infantBaggage.checkedBaggage.hasSizeLimit && detail.infantBaggage.checkedBaggage.baggageContent) {
            //         detail.infantBaggage.checkedBaggage.baggageContent += `,${defaultBaggageDetailHover}`
            //     }
            // })
        }
    }

    if (hasBaggageService && pageStep != '2') {
        baggage.footer = baggage.footer ? baggage.footer + `，${CONSTS.BAGGAGE.DEFAULT_HAS_BAGGAGE_SERVICE_HOVER}` : `温馨提示: ${CONSTS.BAGGAGE.DEFAULT_HAS_BAGGAGE_SERVICE_HOVER}`
    }

    return baggage
}