import { genAntiCrawlerHeader } from '../../sources/list/genAntiCrawlerHeader';
import { lightUpload } from '../../sources/common/lightUbt';

class QuickSearchFlightsCache {
	static get current() {
		const fieldName = '_QuickSearchFlightsCache_';
		if (!window[fieldName]) {
			window[fieldName] = { key: '', promise: null };
		}

		return window[fieldName];
	}
}

const genKey = (prevCond) => genAntiCrawlerHeader(prevCond).sign;

export const getQuickSearchFlightsCache = (prevCond) => {
	const key = genKey(prevCond);
	const cache = QuickSearchFlightsCache.current;

	if (cache && cache.key === key && cache.promise) {
		lightUpload('use_quick_search_flights_cache', {});
		return cache;
	} else {
		lightUpload('no_valid_quick_search_flights_cache', {});
		return null;
	}
};

export const addCacheToQuickSearchFlights = (prevCond, promise) => {
	const key = genKey(prevCond); // 此处需要根据prevCond生成，主要是航程信息
	const cache = QuickSearchFlightsCache.current;

	cache.key = key;
	cache.promise = promise;

	const { SEARCH } = window.GlobalConfigs.listConstConfig || {};
	const { QUICK_SEARCH_FLIGHTS_VAILD_DURATION_IN_MS = 5000 } = SEARCH || {};

	setTimeout(() => {
		cache.key = '';
		cache.promise = null;
	}, QUICK_SEARCH_FLIGHTS_VAILD_DURATION_IN_MS);
};
