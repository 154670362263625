import * as ENUM from '../../../../constants/list/enums'
import store from '../../../../stores/list'
import { genClickItem, genClickLinkedItem, genClickRemove } from '../../../../actions/list/filter'
import { getFilterTypes } from '../../../../constants/list/enums';

export class FilterItemHandlerFactory {
    static getInstance(filterType) {
        const filterTypes = getFilterTypes()

        switch (filterType) {
            case filterTypes.PLUS_ONE:
                return new FilterItemHandler_PlusOne()
            case filterTypes.TRANS_COUNT:
                return new FilterItemHandler_TransCount()
            case filterTypes.AIRLINE:
                return new FilterItemHandler_Airline()
            case filterTypes.TIME:
                return new FilterItemHandler_Time()
            case filterTypes.GO_TIME:
                return new FilterItemHandler_GoTime()
            case filterTypes.BACK_TIME:
                return new FilterItemHandler_BackTime()
            case filterTypes.TRANS_CITY:
                return new FilterItemHandler_TransCity()
            case filterTypes.TRANS_TIMESPAN:
                return new FilterItemHandler_TransTimespan()
            case filterTypes.AIRPORT:
                return new FilterItemHandler_Airport()
            case filterTypes.GO_AIRPORT:
                return new FilterItemHandler_GoAirport()
            case filterTypes.BACK_AIRPORT:
                return new FilterItemHandler_BackAirport()
            case filterTypes.CLASS_GRADE:
                return new FilterItemHandler_ClassGrade()
            case filterTypes.OTHER:
                return new FilterItemHandler_Other()
            case filterTypes.BUILD_UP_AIRPORT:
                return new FilterItemHandler_BuildUpAirport()
            case filterTypes.BUILD_UP_TIME:
                return new FilterItemHandler_BuildUpTime()
            default:
                return new FilterItemHandler()
        }
    }
}

/**
 * 筛选具体选项点击事件处理
 */
class FilterItemHandler {
    onHandlClickItem(filterItem, filterType, options) {
        store.dispatch(genClickItem(filterType, filterItem.get('groupType'), filterItem.get('key'), options))
    }

    getFinalKeysOnClickLinkItem(filterItem, activeKeys, isClickParent, currentItemKey, subItemsKeyInParent, parentItemKeyInSub) {
        let itemKeyIsActive = activeKeys.includes(currentItemKey),
            finalActiveKeys = []

        //点击的是父项
        if (isClickParent) {
            if (itemKeyIsActive) {
                //点击已选父项，去掉该父项以及所有包含的子项
                activeKeys.valueSeq().forEach(key => {
                    if (key !== currentItemKey && !filterItem.get(subItemsKeyInParent).includes(key) && !finalActiveKeys.includes(key)) {
                        finalActiveKeys.push(key)
                    }
                })
            } else {
                //点击未选父项，增加该父项以及所有包含子项
                finalActiveKeys.push(currentItemKey)
                filterItem.get(subItemsKeyInParent).valueSeq().forEach(item => finalActiveKeys.push(item))

                activeKeys.valueSeq().forEach(key => {
                    if (!finalActiveKeys.includes(key)) {
                        finalActiveKeys.push(key)
                    }
                })
            }
        } else { //点击的是子项
            //点击的是已选子项，所属父项可能已选，也可能没选
            if (itemKeyIsActive) {
                let parent = filterItem.get(parentItemKeyInSub),
                    parentIsActive = activeKeys.includes(parent)

                //所属主项已选，清掉所属主项以及该子项
                //所属主项未选，清掉该子项
                activeKeys.valueSeq().forEach(key => {
                    if ((parentIsActive ? key !== parent : true) && key !== currentItemKey && !finalActiveKeys.includes(key)) {
                        finalActiveKeys.push(key)
                    }
                })
            } else {
                //点击的是未选子项，不管主项，直接增加该子项即可
                finalActiveKeys.push(currentItemKey)

                activeKeys.valueSeq().forEach(key => {
                    if (!finalActiveKeys.includes(key)) {
                        finalActiveKeys.push(key)
                    }
                })
            }
        }

        return finalActiveKeys
    }

    onHandlClickLinkItem(filterItem, filterType, parentGroup, activeKeys, subItemsKeyInParent, parentItemKeyInSub, options) {
        let isClickParent = filterItem.get('groupType') === parentGroup,
            itemKey = filterItem.get('key'),  //当前点击项的KEY，可能是父项KEY，也可能是子项KEY
            finalActiveKeys = this.getFinalKeysOnClickLinkItem(filterItem, activeKeys, isClickParent, itemKey, subItemsKeyInParent, parentItemKeyInSub)

        store.dispatch(genClickLinkedItem(filterType, finalActiveKeys, options))
    }

    onHandlClickClearLinkItem(filterItem, filterType, parentGroup, activeKeys, subItemsKeyInParent, parentItemKeyInSub) {
        let itemKey = filterItem.get('key'),  //当前点击项的KEY，只会是父项KEY
            finalActiveKeys = this.getFinalKeysOnClickLinkItem(filterItem, activeKeys, false, itemKey, subItemsKeyInParent, parentItemKeyInSub)

        store.dispatch(genClickLinkedItem(filterType, finalActiveKeys))
    }

    onHandlClickClear(filterItem, filterType) {
        store.dispatch(genClickRemove(filterType, filterItem.get('groupType'), filterItem.get('key')))
    }

    handleClickItem(_filterItem, _activeKeys, _options) {
    }

    handleClickClear(_filterItem, _activeKeys) {
    }
}

class FilterItemHandler_PlusOne extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().PLUS_ONE, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().PLUS_ONE)
    }
}

class FilterItemHandler_TransCount extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        const groupTypeKey = filterItem.get('groupType').key
        if (groupTypeKey === 'CITY' || groupTypeKey === 'COUNTRY') {
            super.onHandlClickLinkItem(filterItem, getFilterTypes().TRANS_COUNT, ENUM.FILTER_GROUP_TRANS_CITY.COUNTRY, _activeKeys, 'cityCodeList', 'countryKey', options)
        } else {
            super.onHandlClickItem(filterItem, getFilterTypes().TRANS_COUNT, options)
        }
    }

    handleClickFlatItem(filterItem, _activeKeys, options) {
        //目前只有外露 “只看直飞” 会触发该方法
        super.onHandlClickItem(filterItem, getFilterTypes().TRANS_COUNT, Object.assign({ forceExcludeInGroup: true }, options))
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().TRANS_COUNT)
    }
}

class FilterItemHandler_Airline extends FilterItemHandler {
    handleClickItem(filterItem, activeKeys, options) {
        super.onHandlClickLinkItem(filterItem, getFilterTypes().AIRLINE, ENUM.FILTER_GROUP_AIRLINE.UNION, activeKeys, 'airlineList', 'alliance', options)
    }

    handleClickClear(filterItem, activeKeys) {
        super.onHandlClickClearLinkItem(filterItem, getFilterTypes().AIRLINE, ENUM.FILTER_GROUP_AIRLINE.UNION, activeKeys, 'airlineList', 'alliance')
    }
}

class FilterItemHandler_Time extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().TIME, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().TIME)
    }
}

class FilterItemHandler_BuildUpTime extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().BUILD_UP_TIME, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().BUILD_UP_TIME)
    }
}

class FilterItemHandler_GoTime extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().GO_TIME, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().GO_TIME)
    }
}

class FilterItemHandler_BackTime extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().BACK_TIME, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().BACK_TIME)
    }
}

class FilterItemHandler_TransCity extends FilterItemHandler {
    handleClickItem(filterItem, activeKeys, options) {
        super.onHandlClickLinkItem(filterItem, getFilterTypes().TRANS_CITY, ENUM.FILTER_GROUP_TRANS_CITY.COUNTRY, activeKeys, 'cityCodeList', 'countryKey', options)
    }

    handleClickClear(filterItem, activeKeys) {
        super.onHandlClickClearLinkItem(filterItem, getFilterTypes().TRANS_CITY, ENUM.FILTER_GROUP_TRANS_CITY.COUNTRY, activeKeys, 'cityCodeList', 'countryKey')
    }
}

class FilterItemHandler_TransTimespan extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().TRANS_TIMESPAN, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().TRANS_TIMESPAN)
    }
}

class FilterItemHandler_Airport extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().AIRPORT, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().AIRPORT)
    }
}

class FilterItemHandler_BuildUpAirport extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().BUILD_UP_AIRPORT, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().BUILD_UP_AIRPORT)
    }
}

class FilterItemHandler_GoAirport extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().GO_AIRPORT, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().GO_AIRPORT)
    }
}

class FilterItemHandler_BackAirport extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().BACK_AIRPORT, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().BACK_AIRPORT)
    }
}

class FilterItemHandler_ClassGrade extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().CLASS_GRADE, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().CLASS_GRADE)
    }
}

class FilterItemHandler_Other extends FilterItemHandler {
    handleClickItem(filterItem, _activeKeys, options) {
        super.onHandlClickItem(filterItem, getFilterTypes().OTHER, options)
    }

    handleClickClear(filterItem, _activeKeys) {
        super.onHandlClickClear(filterItem, getFilterTypes().OTHER)
    }
}
