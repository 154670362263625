import * as types from '../../constants/list/actionTypes/recommend.js'
import CONFIG_URL from '../../constants/list/api'
import { fetchPost, fetchGet } from '../common'

export const genAllRecommendFlights = data => ({ type: types.GET_ALL_RECOMMEND_FLIGHTS, data })
export const genDirectRecommend = data => ({ type: types.RECOMMEND_DIRECT_TAB_DATA, data })
export const genAdjacentAirline = data => ({ type: types.ADJACENT_AIRLINE_RECOMMEND, data })
export const getTicketRequest = searchResult => ({ type: types.RECOMMEND_GET_TICKET_REQUEST, searchResult })
export const genCountryCodes = codes => ({ type: types.COUNTRY_CODE, codes })
export const genChangeNameError = error => ({ type: types.NAME_ERROR, error })
export const genChangeTelephoneError = error => ({ type: types.TELEPHONE_ERROR, error })
export const genChangeEmialError = error => ({ type: types.EMAIL_ERROR, error })
export const genShowAreaCode = (visible, text, code) => ({ type: types.SHOW_AREA_CODE, visible, text, code })
export const genInputContact = contact => ({ type: types.INPUT_CONTACT, contact })
export const genClickCabin = id => ({ type: types.CLICK_CABIN, id })
//直飞日历
export const genDirectCalendar = data => ({ type: types.RECOMMEND_DIRECT_CALENDAR_DATA, data })
export const openDirectCalendar = () => ({ type: types.OPEN_DIRECT_CALENDAR })
export const closeDirectCalendar = () => ({ type: types.CLOSE_DIRECT_CALENDAR })
export const changeDirectCalendarDepDate = (depDate) => ({ type: types.CHANGE_DIRECT_CALENDAR_DEPDATE, depDate })
export const changeDirectCalendarRetDate = (retDate) => ({ type: types.CHANGE_DIRECT_CALENDAR_RETDATE, retDate })
export const changeDirectCalendarBaseDate = (baseDate) => ({ type: types.CHANGE_DIRECT_CALENDAR_BASEDATE, baseDate })
//两舱推荐折叠
export const genChangeAppendShowMoreVisibility = visible => ({ type: types.CHANGE_APPEND_SHOW_MORE_VISIBILITY, visible })
export const genMultiCheapest = cheapest => ({ type: types.MULTI_CHEAPEST, cheapest })

//存储空铁组合
// export const genSetVirtualFlights = (virtualFlights, seq) => ({ type: types.SET_VIRTUALFLIGHTS, virtualFlights, seq })

export const genSetCFRecommendYSShowCabin = () => ({ type: types.SET_CFRECOMMENDYS_SHOWCABIN })
export const genSetHasCFRecommendYS = (hasCFRecommendYS) => ({ type: types.SET_HAS_CF_RECOMMEND_YS, hasCFRecommendYS })

//缺口程推荐
export const genOpenJawRecommend = (openJawData) => ({ type: types.OPEN_JAW_RECOMMEND, openJawData })
export const genOpenJawGroundTraffic = (openJawGroundTrafficData) => ({ type: types.OPEN_JAW_GROUND_TRAFFIC, openJawGroundTrafficData })

export const genAdjacentAirlineDirectFlights = flightList => ({ type: types.ADJACENT_AIRLINE_DIRECT_RECOMMEND, flightList })

export const genRecommendVersion = (version) => ({ type: types.RECOMMEND_VERSION, version })
//设置所有推荐航班数据
export const genSetAllRecommendFlights = (recommendList) => ({ type: types.SET_ALL_RECOMMEND_FLIGHTS, recommendList })

// 获取直飞推荐数据
// 单程推荐往返
export const genSetOwRecommendRtEndDate = (date) => ({ type: types.SET_OW_RECOMMEND_RT_END_DATE, date })
export const genSetOwRecommendRtTipStatus = (close) => ({ type: types.SET_OW_RECOMMEND_RT_TIP_STATUS, close })
export const genSetOwRecommendUserAttr = (userAttr) => ({ type: types.SET_OW_RECOMMEND_RT_USER_ATTR, userAttr })

// 获取直飞推荐数据
export const fetchDirectRecommend = (params) => dispatch => {
    return fetchPost(CONFIG_URL.getDirectRecommend, params)
        .then(res => res && res.data || {})
        .then(json => {
            dispatch(genDirectRecommend(json.data))
        })
}

//获取直飞日历数据
export const fetchDirectCalendar = (params) => dispatch => {
    return fetchPost(CONFIG_URL.getDirectCalendar, params)
        .then(res => res && res.data || {})
        .then(json => {
            dispatch(genDirectCalendar(json.data || []))
        })
}

// 单程/往返获取临近低价推荐数据(临近城市)
export const fetchAdjacentCityLowPrice = (params) => dispatch => {
    return fetchPost(CONFIG_URL.getAdjacentCityLowPrice, params)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data === null) {
                json.data = []
            }
            dispatch(genAdjacentAirline(json.data))
        })
}

// 获取所有国家区号
export const fetchCountryCodes = () => dispatch => {
    return fetchGet(CONFIG_URL.getCountryCodes)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data === null) {
                json.data = []
            }
            dispatch(genCountryCodes(json.data))
        })
}


// 提交需求单
export const fetchRequireForm = (params) => () => {
    return fetchPost(CONFIG_URL.saveRequireForm, params)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.msg == 'success') {
                window.location.href = (!window.GlobalSwitches || window.GlobalSwitches.demandFormSwitch) ? "/international/search/demand/success" : "//flights.ctrip.com/international/FlightQuerySucceed.aspx"
            }
        })
}

//缺口程推荐获取航班信息
export const fetchOpenJawRecommend = (params) => dispatch => {
    return fetchPost(CONFIG_URL.getOpenJawRecommend, params)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data == null) {
                json.data = {}
            }
            dispatch(genOpenJawRecommend(json.data))
        })
}

//缺口程推荐获取地面交通信息
export const fetchOpenJawGroudTraffic = (params) => dispatch => {
    let url = CONFIG_URL.getOpenJawGroundTraffic + `?departureCityCode=${params.departureCityCode}&arrivalCityCode=${params.arrivalCityCode}&departureDate=${params.departureDate}`
    return fetchPost(url, params)
        .then(res => res && res.data || {})
        .then(json => {
            dispatch(genOpenJawGroundTraffic(json.data))
        })
}

// 获取邻近航线直飞航班
export const fetchAdjacentAirlineDirectFlights = (params) => dispatch => {
    return fetchPost(CONFIG_URL.getAdjacentCityDirectFlights, params)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data == null) {
                json.data = []
            }
            dispatch(genAdjacentAirlineDirectFlights(json.data))
        })
}
// 单程推荐往返 用户维度取值
export const fetchUserAttr = (params) => dispatch => {
    return fetchPost(CONFIG_URL.checkUserAttr, params)
        .then(res => res && res.data || {})
        .then(json => {
            if (json.data) {
                dispatch(genSetOwRecommendUserAttr(json.data))
            }
        }).catch(() => {
            dispatch(genSetOwRecommendUserAttr(false))
        })
}

// 获取所有推荐航班
export const fetchAllRecommendFlights = ({ prevCond, minMiseryIndexOfAllFlights, minPriceOfAllFlights, hasAnyFlight, firstSegmentHasDirect, secondSegmentHasDirect }) => dispatch => {
    // 拼接请求参数
    let flightSegmentList = []
    prevCond.get('flightSegments').forEach((flightSegment, index) => {
        let segmentParam = {
            arrivalCityCode: flightSegment.get("arrivalCityCode"),
            departureCityCode: flightSegment.get("departureCityCode"),
            departureDate: flightSegment.get("departureDate"),
            hasDirect: index === 0 ? firstSegmentHasDirect : secondSegmentHasDirect
        }

        flightSegmentList.push(segmentParam)
    })

    return fetchPost(CONFIG_URL.getAllRecommendFlights, {
        flightSegmentList,
        flightWay: prevCond.get('flightWay'),
        minMiseryIndex: minMiseryIndexOfAllFlights,
        minPrice: minPriceOfAllFlights,
        noSearchResult: !hasAnyFlight
    })
        .then(res => res && res.data || {})
        .then(json => {
            if (json && json.data) {
                const { recommendInfoList, recommendTitle } = json.data
                if (recommendTitle && recommendInfoList && recommendInfoList.length) {
                    dispatch(genSetAllRecommendFlights([{ title: recommendTitle, flights: recommendInfoList }]))
                } else {
                    //
                }
            }
        })
}

// 两舱折叠/显示第二区块推荐航班超出部分（默认4个以外航班）
export const genToggleHighCabinExpandExtraRecFlights = () => ({ type: types.HIGH_CABIN_TOGGLE_EXTRA_REC_FLIGHTS })
