import Enum from 'enum'

export const AIRCRAFT_SIZE = new Enum({
    L: {
        title: '宽体',
        sizeTitle: '大型机'
    },
    M: {
        title: '窄体',
        sizeTitle: '中型机'
    },
    S: {
        title: '窄体',
        sizeTitle: '小型机'
    }
})