import React from 'react'
import Immutable from 'immutable'
import { fetchPost } from '../common'
import CONFIG_URL from '../../constants/list/api'
// import { LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list';

let VisaLuggageDetailCache = {},
    VisaLuggageDetailInProgress = {}
// AllVisaLuggage = []    //保存过境签和非过境签的数量，便于统计上报埋点


const parseVisaLuggage = (visaLuggage, segmentNo) => {
    if (!visaLuggage) {
        return {
            visaLuggageDirectInfoList: [],
            tagName: '',
            segmentNo,
            visaToolTip: '',
            leakedVisaInfoList: [],
            leakedVisaTagTypeCode: ''
        }
    }

    let { leakedVisaInfoList, visaLuggageDirectInfoList } = visaLuggage
    let visaToolTip = '',
        // hasVisaTag = false,
        tagName = '',
        leakedVisaTagTypeCode = ''

    // 这个数据用于航班详情里过境签底部提示的展示
    visaLuggageDirectInfoList = Immutable.fromJS(visaLuggageDirectInfoList)

    // 航班级别是否需要过境签和不需要过境签（NEED_VISA=》所有运价的都需要过境签，NOT_NEED_VISA=》所有运价中转航班都不需要过境签）
    // leakedVisaTagSwitch在开关打开的情况下，会外露到航班卡片上
    // 所有的运价在该中转点上都需要过境签在该中转点上显示’需要过境签‘
    // leakedVisaInfoList = {
    //     leakedVisaTagSwitch   是否需要使用新的过境签返回提示,这个开关只控制航班卡片上是否需要外露过境签和无需过境签的提示，航班详情里不受此开关控制
    //     leakedVisaTagType     新的过境签返回值 NOT_NEED_VISA NEED_VISA 需要和 不需要都是取严的
    //     segmentNo    航程
    //     visaInfoList  [{
    // "sequence": 0,
    // "visaContentList": [
    // 	"string"
    // ]
    // }
    for (let i = 0; i < leakedVisaInfoList.length; i++) {
        let leakedVisaInfo = leakedVisaInfoList[i]
        let { leakedVisaTagSwitch, leakedVisaTagType, visaInfoList } = leakedVisaInfo
        if (leakedVisaTagSwitch && segmentNo == leakedVisaInfo.segmentNo) {
            if (leakedVisaTagType) {
                if (leakedVisaTagType == "NOT_NEED_VISA") {
                    tagName = '无需过境签'
                }
                if (leakedVisaTagType == "NEED_VISA") {
                    tagName = '过境需签证'

                }
                // if((leakedVisaTagType == "NEED_VISA" || leakedVisaTagType == "NEED_VISA") &&AllVisaLuggage.indexOf(cacheKey) < 0){
                //     AllVisaLuggage.push(cacheKey)
                //     LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({tag: leakedVisaTagType})
                // }
                leakedVisaTagTypeCode = leakedVisaTagType
                visaToolTip = []
                if (visaInfoList && visaInfoList.length) {
                    for (let i = 0; i < visaInfoList.length; i++) {
                        visaToolTip = visaToolTip.concat(visaInfoList[i].visaContentList)
                    }
                }

            } else {
                tagName = ''
                visaToolTip = []
            }
        }
    }


    return {
        visaLuggageDirectInfoList,
        tagName,
        segmentNo,
        visaToolTip,
        leakedVisaInfoList,
        leakedVisaTagTypeCode
    }
}

export const getVisaLuggageDetail = (luggageVisaKeyList, segmentNo) => {
    if (luggageVisaKeyList.length > 0) {
        let cacheKey = JSON.stringify(luggageVisaKeyList)
        return new Promise((resolve, _reject) => {
            if (VisaLuggageDetailCache[cacheKey]) {
                resolve(parseVisaLuggage(VisaLuggageDetailCache[cacheKey], segmentNo))
            } else {
                let isTheFirstOne = false
                if (!VisaLuggageDetailInProgress[cacheKey]) {
                    VisaLuggageDetailInProgress[cacheKey] = []
                    isTheFirstOne = true
                }

                VisaLuggageDetailInProgress[cacheKey].push({ resolve, segmentNo })

                if (isTheFirstOne) {
                    fetchPost(CONFIG_URL.getVisaLuggageDirectInfo, luggageVisaKeyList)
                        .then(res => res && res.data || {})
                        .then(json => {
                            if (json.data && (json.data.visaLuggageDirectInfoList || json.data.leakedVisaContentList || json.data.leakedVisaTagType)) {
                                let visaLuggage = json.data
                                VisaLuggageDetailCache[cacheKey] = visaLuggage

                                VisaLuggageDetailInProgress[cacheKey].forEach(({ resolve: theResolve, segmentNo: theSegmentNo }) => theResolve(parseVisaLuggage(visaLuggage, theSegmentNo)))
                                delete VisaLuggageDetailInProgress[cacheKey]
                            } else {
                                VisaLuggageDetailCache[cacheKey] = null

                                VisaLuggageDetailInProgress[cacheKey].forEach(({ resolve: theResolve, segmentNo: theSegmentNo }) => theResolve(parseVisaLuggage(null, theSegmentNo)))
                                delete VisaLuggageDetailInProgress[cacheKey]
                            }
                        })
                }
            }
        })
    }
}
const genVisaItemWithTitle = (title, content) => {
    return <div className="exp-dl">
        <div className="exp-dt">{title}</div>
        <div className="exp-dd">{content}</div>
    </div>
}
// 过境签的提示
export const getExtendVisaTip = (visaLuggage, segmentNo) => {
    let isSameVisa = true,
        hasMultiPuPrice = false,
        hasSinglePuPrice = false,
        visa = Immutable.fromJS([]),
        multiPuVisa = Immutable.fromJS([]),
        singlePuVisa = Immutable.fromJS([]),
        visaContent = [],
        notVisaContent = []
    if (visaLuggage && visaLuggage.size > 0) {
        for (let i = 0; i < visaLuggage.size; i++) {
            let current = visaLuggage.get(i),
                visaList = current.get('visaList')

            if (current.get('isMultiPriceUnit')) hasMultiPuPrice = true
            else hasSinglePuPrice = true

            if (!visaList || visaList.size == 0) continue

            visaList = visaList.filter(visa => visa.get('segmentNo') == segmentNo && visa.get('messageList') && visa.get('messageList').size > 0)

            if (visaList.size > 0) {
                if (current.get('isMultiPriceUnit')) {
                    visaList.forEach(visa => {
                        const exist = multiPuVisa
                            .find(item => (item.messageList || []).join('') === (visa.get('messageList').toJS() || []).join('') && item.needVisa === visa.get('needVisa'))

                        if (!exist) {
                            multiPuVisa = multiPuVisa.concat({
                                messageList: visa.get('messageList').toJS(),
                                needVisa: visa.get('needVisa')
                            })
                        }
                    })
                } else {
                    visaList.forEach(visa => {
                        singlePuVisa = singlePuVisa.concat({
                            messageList: visa.get('messageList').toJS(),
                            needVisa: visa.get('needVisa')
                        })
                    })
                }
            }
        }

        if (multiPuVisa.size > 0 || singlePuVisa.size > 0) {
            if (hasMultiPuPrice && hasSinglePuPrice) isSameVisa = Immutable.is(singlePuVisa, multiPuVisa)
            if (isSameVisa) {
                if (singlePuVisa.size > 0) visa = singlePuVisa
                else visa = multiPuVisa

                visa.map((v, index) => {
                    let item = <div key={`visa_${index}`} className="exp-text">{v.messageList}</div>
                    v.needVisa ? visaContent.push(item) : notVisaContent.push(item)
                })


            }
            else {
                if (hasMultiPuPrice && multiPuVisa.size == 0) {
                    notVisaContent.push(genVisaItemWithTitle('“多票产品”：', <div className="exp-text">不需要办理该国/地区过境类签证。</div>))
                }
                else {
                    let multiNeedVisa = [],
                        multiNotNeedVisa = []
                    multiPuVisa.map((v, index) => {
                        let item = <div key={`multiPuVisa_${index}`} className="exp-text">{v.messageList}</div>
                        v.needVisa ? multiNeedVisa.push(item) : multiNotNeedVisa.push(item)
                    })

                    multiNeedVisa.length && visaContent.push(genVisaItemWithTitle('“多票产品”：', multiNeedVisa))
                    multiNotNeedVisa.length && notVisaContent.push(genVisaItemWithTitle('“多票产品”', multiNotNeedVisa))

                }
                if (hasSinglePuPrice && singlePuVisa.size == 0) {
                    visaContent.push(genVisaItemWithTitle('其他产品：', <div className="exp-dd">不需要办理该国/地区过境类签证。</div>))
                }
                else {
                    let singleNeedVisa = [],
                        singleNotNeedVisa = []
                    singlePuVisa.map((v, index) => {
                        let item = <div key={`visa_${index}`} className="exp-text">{v.messageList}</div>
                        visa.needVisa ? singleNeedVisa.push(item) : singleNotNeedVisa.push(item)
                    })
                    singleNeedVisa.length && visaContent.push(genVisaItemWithTitle('其他产品：', singleNeedVisa))
                    singleNotNeedVisa.length && notVisaContent.push(genVisaItemWithTitle('其他产品：', singleNotNeedVisa))
                }

            }

        }
    }
    return {
        visaContent,
        notVisaContent
    }
}
