export const CALENDARLP_SHOW_BODY = 'CALENDARLP_SHOW_BODY'
export const CALENDARLP_GET_LPDATA = 'CALENDARLP_GET_LPDATA'
export const CALENDARLP_GET_WORKINGDAYS = 'CALENDARLP_GET_WORKINGDAYS'
export const CALENDARLP_LOWEST_PRICE_TODAY = 'CALENDARLP_LOWEST_PRICE_TODAY'
export const CALENDARLP_CHANGE_CURRENTSTART = 'CALENDARLP_CHANGE_CURRENTSTART'
export const CALENDARLP_CHANGE_CURRENTMONTH = 'CALENDARLP_CHANGE_CURRENTMONTH'
export const CALENDARLP_CHANGE_DIFFDAYS = 'CALENDARLP_CHANGE_DIFFDAYS'
export const CALENDARLP_CHANGE_CURRENTWEEK = 'CALENDARLP_CHANGE_CURRENTWEEK'
export const CALENDARLP_CHANGE_SHOWMONTH = 'CALENDARLP_CHANGE_SHOWMONTH'
export const CALENDARLP_LOWEST_PRICE_TODAY_CHANNEL = 'CALENDARLP_LOWEST_PRICE_TODAY_CHANNEL'
export const CALENDARLP_CHANGE_SHOWTREND = 'CALENDARLP_CHANGE_SHOWTREND'
export const CALENDARLP_GET_LOWER_TAB_SWITCH = 'CALENDARLP_GET_LOWER_TAB_SWITCH'
export const CALENDARLP_GET_LOWER_SWITCH_STATUS = 'CALENDARLP_GET_LOWER_SWITCH_STATUS'
export const CALENDARLP_GET_LOWER_NEWSTYLE_SWITCH = 'CALENDARLP_GET_LOWER_NEWSTYLE_SWITCH'
export const CALENDARLP_CLOSE_DETAIL_AND_TREND = 'CALENDARLP_CLOSE_DETAIL_AND_TREND'