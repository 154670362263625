/**
 * 列表页查询的是国内还是国际航线，不要export暴露出去
 */
const PageScope = {
    International: 'i',    // 国际
    Domestic: 'd'           // 国内
}

/**
 * 获取列表页是查询国内航班还是国际航班：
 * i: 国际
 * d: 国内
 */
export const getScope = () => {
    // 暂时加默认值，后期改成从全局变量
    return window.GlobalSearchCriteria ? (window.GlobalSearchCriteria.scope || '') : ''
}

export const genScopeHeader = (scope) => {
    return scope ? { scope } : {}
}

export const getScopeValue = scopeInternational => scopeInternational ? PageScope.International : PageScope.Domestic

export const ifScopeValueInternational = scopeValue => scopeValue === PageScope.International

/**
 * 列表页是否查询的是国际航班
 */
export const getIfScopeInternational = () => getScope() !== PageScope.Domestic

/**
 * 列表页是否查询的是国内航班
 */
export const getIfScopeDomestic = () => getScope() === PageScope.Domestic

/**
 * 是否打开新版国内航班开关
 */
export const getIfHoldDomesticFlightSwitch = () => window.GlobalSwitches && !!window.GlobalSwitches.d_holdSearchDomesticFlightsSwitch
