import getIfFlightAsLowPrice from '../getIfFlightAsLowPrice';
import { getFlightAircraftSizeList } from '../getMainSegmentAirCraftSize';

/**
 * 获取航班所有机型尺寸
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 */
const getAllAircraftSizeOfFlights = (flights, currentSegmentSeq) => {
	let airCraftSizeMap = {};

	flights.forEach((flight) => {
		const aircraftSizeList = getFlightAircraftSizeList(flight, currentSegmentSeq);
		aircraftSizeList.forEach((aircraftSize) => {
			if (aircraftSize) {
				if (getIfFlightAsLowPrice(flight)) {
					const minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
						minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']);

					if (!airCraftSizeMap[aircraftSize]) {
						airCraftSizeMap[aircraftSize] = {
							minAvgPriceWithTax,
							minAvgPriceWithoutTax,
						};
					} else {
						if (minAvgPriceWithTax < airCraftSizeMap[aircraftSize].minAvgPriceWithTax) {
							airCraftSizeMap[aircraftSize].minAvgPriceWithTax = minAvgPriceWithTax;
						}

						if (minAvgPriceWithoutTax < airCraftSizeMap[aircraftSize].minAvgPriceWithoutTax) {
							airCraftSizeMap[aircraftSize].minAvgPriceWithoutTax = minAvgPriceWithoutTax;
						}
					}
				}
			}
		});
	});

	return airCraftSizeMap;
};

export default getAllAircraftSizeOfFlights;
