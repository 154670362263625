import React from 'react';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import TooltipsImg from './tooltipsImg';
import Tooltip from './tooltip';
import DeferredContent from './deferredContent';
import { getPenaltyDetail, getDefaultPenaltyContent, getDefaultServicePackage } from '../../../actions/list/penalty';
import PriceInvoice from '../popups/priceInvoice';
import { PRICE_TAG_POSITION } from '../../../constants/list/enums/priceTagPosition';
import { getNgsPromise } from '../../../actions/list/getNgsPromise';
import { getIfScopeDomestic } from '../../../sources/list/scope';

const tooltipsImgComponentDict = {};
const DomesticTagTypeClassNameMap = {
	NONE: '',
	WARN: 'warn',
	INFO: 'strong',
};
export class PriceFragment {
	constructor(props) {
		this.props = props;

		this.initPenaltyAndBaggage();
		this.initServicePackage();
		this.initInvoceFragment();
		this.initNgs();
	}

	initPenaltyAndBaggage() {
		const {
			price,
			index,
			prevCond,
			allSegmentList,
			penaltyTag,
			extraPenaltyTag,
			brandPricePenaltyTag,
			cleanItineraryId,
			globalSwitch,
			setExtraTag,
			setExtraPenaltyTag,
		} = this.props;

		let mileagePointSwitch = globalSwitch.get('mileagePointSwitch'),
			penaltyCriteria = price.getIn(['penalty', 'penaltyCriteria']),
			//是否有甄选服务
			theTodayFreeRefundWrapper = price
				.get('priceTagEnums')
				.filter((wrapper) => wrapper.tag.value.position === PRICE_TAG_POSITION.LEFT)
				.find((wrapper) => wrapper.tag.key === 'TodayFreeRefund'),
			hasTodayFreeRefund = theTodayFreeRefundWrapper ? theTodayFreeRefundWrapper.data : null,
			GVLimitationsEnums = price.get('GVLimitationsEnums'),
			defaultPenaltyTooltip = (
				<Tooltip childrenReplaceRoot={true}>
					{getDefaultPenaltyContent(
						allSegmentList,
						price,
						hasTodayFreeRefund,
						GVLimitationsEnums,
						prevCond,
						0,
						0,
						undefined,
						false
					)}
				</Tooltip>
			);

		let getPenaltyDetailPromise = () =>
				getPenaltyDetail(
					allSegmentList,
					price,
					prevCond,
					penaltyCriteria,
					hasTodayFreeRefund,
					GVLimitationsEnums,
					mileagePointSwitch,
					false,
					setExtraTag,
					setExtraPenaltyTag
				),
			thePenaltyDetailThen = (penaltyDetail, _ext) => (
				<Tooltip childrenReplaceRoot={true}>{penaltyDetail}</Tooltip>
			),
			PenaltyAndBaggageTooltip = ({ id }) => {
				return (
					<ReactToolTip
						stopWheelPropagation={true}
						parentId={id}
						key={id}
						toolTipContent={
							<DeferredContent
								key={id}
								getPromise={getPenaltyDetailPromise}
								loading={defaultPenaltyTooltip}
								then={thePenaltyDetailThen}
							/>
						}
					/>
				);
			};
		// 特殊事件改期免收手续费.
		const changeFreeTag = price.getIn(['penalty', 'specialChangeTagInfo']) || '',
			defaultPenaltyTag = extraPenaltyTag || price.getIn(['penalty', 'defaultPenaltyTag']),
			baggageTag = price.getIn(['baggage', 'baggageTag']),
			baggageIconType = price.getIn(['baggage', 'iconType']),
			tagType = extraPenaltyTag ? '' : price.getIn(['penalty', 'tagType']),
			domestic = getIfScopeDomestic(),
			penaltyRenderTag = domestic ? (
				<span dangerouslySetInnerHTML={{
					__html: defaultPenaltyTag,
				}}></span>
			) : (
				`退改签${changeFreeTag ? ` (${changeFreeTag})` : ''}`
			);

		this.PenaltyFragment = penaltyTag ? (
			<div className="rules" id={`penalty_${cleanItineraryId}_${index}`}>
				{<PenaltyAndBaggageTooltip {...{ id: `penalty_${cleanItineraryId}_${index}` }} />}
				{brandPricePenaltyTag || (
					<React.Fragment>
						<span className={`highlight ${DomesticTagTypeClassNameMap[tagType]}`}>{penaltyRenderTag}</span>
						<span className="spacing">|</span>
						<span className={`highlight ${DomesticTagTypeClassNameMap[baggageIconType]}`}>
							{domestic ? baggageTag : '行李规定'}
						</span>
					</React.Fragment>
				)}
			</div>
		) : null;
	}

	initServicePackage() {
		const { price, index } = this.props;
		let servicePackage = price.get('servicePackage');

		if (servicePackage) {
			let servicePackageConfig = getIfScopeDomestic()
				? window.GlobalConfigs.d_servicePackage
				: window.GlobalConfigs.goldPackage || [];
			servicePackageConfig.forEach((gpItem) => {
				let configPackageID = gpItem.packageID;
				let packageID = servicePackage.get('id');
				if (configPackageID === packageID) {
					if (gpItem.products) {
						let product = gpItem.products.map(function (pItem, pIndex) {
							return (
								<React.Fragment key={pIndex}>
									<span className="item-icon">
										<img src={pItem.iconUrl} alt=""></img>
									</span>
									<span className="item-text">{pItem.title}</span>
								</React.Fragment>
							);
						});

						let defaultServicePackageTooltip = (
							<Tooltip childrenReplaceRoot={true}>{getDefaultServicePackage(gpItem)}</Tooltip>
						);
						this.ServicePackagItem = {
							...gpItem,
							price: servicePackage.get('price'),
						};
						this.ServicePackageFragment = (
							<div
								className="servicePackage"
								id={`servicePackage_${index}`}
								key={`servicePackage_${packageID}`}
							>
								<ReactToolTip
									stopWheelPropagation={true}
									parentId={`servicePackage_${index}`}
									key={`servicePackage_${index}`}
									toolTipContent={
										<DeferredContent
											key={`servicePackage_${index}`}
											loading={defaultServicePackageTooltip}
										/>
									}
								/>
								{product}
							</div>
						);
					}
				}
			});
		}
	}

	initInvoceFragment() {
		const { price, index, prevCond, allSegmentList } = this.props;

		let flightWayEnum = prevCond.get('flightWayEnum').value,
			invoiceTitle = price.getIn(['invoiceTypeEnum', 0]).value.title || '',
			invoiceExtraClass = price.getIn(['invoiceTypeEnum', 0]).value.className || '',
			invoiceLink = price.getIn(['invoiceTypeEnum', 0]).value.link,
			priceUnitList = price.get('priceUnitList'),
			tooltipsImgComponent =
				tooltipsImgComponentDict[invoiceLink] ||
				(tooltipsImgComponentDict[invoiceLink] = <TooltipsImg link={invoiceLink} />),
			// id中有+选择器会解析错误
			singleInvoiceId = `${invoiceTitle.replace('+', '')}_${index}`;

		this.InvoiceFragment =
			price.get('invoiceTypeEnum').size > 1 ? (
				<div className="voucher">
					<span id={`MultiInvoice-${index}`}>
						<span className="abbr">多种报销凭证</span>
					</span>
					<ReactToolTip
						key={`MultiInvoice-${index}`}
						parentId={`MultiInvoice-${index}`}
						toolTipContent={
							<PriceInvoice
								key={`MultiInvoice-${index}`}
								priceUnitList={priceUnitList}
								flightWayEnum={flightWayEnum}
								allSegmentList={allSegmentList}
								invoiceTypeEnum={price.get('invoiceTypeEnum')}
								index={index}
							/>
						}
					/>
				</div>
			) : (
				<div className="voucher" id={singleInvoiceId}>
					<span className={`abbr ${invoiceExtraClass}`}>{invoiceTitle}</span>
					<ReactToolTip parentId={singleInvoiceId} toolTipContent={tooltipsImgComponent} />
				</div>
			);
	}

	initNgs() {
		const { price, index, cleanItineraryId, globalSwitch, searchCriteriaToken, allSegmentList } = this.props,
			airlineCodes =
				allSegmentList && allSegmentList.length
					? allSegmentList.map((segment) => segment.get('airlineCode'))
					: []; //出票航司

		let listShowPriceNgsSwitch = globalSwitch.get('listShowPriceNgsSwitch');

		let theNgsLabelThen = (el, _ext) => el && el[0],
			theNgsContentThen = (el, _ext) => el && el[1] && <Tooltip childrenReplaceRoot={true}>{el[1]}</Tooltip>,
			getNgsTooltip = (id) => {
				return (
					<ReactToolTip
						stopWheelPropagation={true}
						parentId={id}
						key="tooltip"
						toolTipContent={
							<DeferredContent
								key={id}
								delayGetPromise={true}
								getPromise={getNgsPromise(price, searchCriteriaToken, airlineCodes)}
								loading={null}
								then={theNgsContentThen}
							/>
						}
					/>
				);
			};

		const id = `ngs_${cleanItineraryId}_${index}`;
		this.NgsFragment = listShowPriceNgsSwitch ? (
			<span className="ngs-root">
				<span id={id}>
					<DeferredContent
						key="label"
						delayGetPromise={true}
						getPromise={getNgsPromise(price, searchCriteriaToken, airlineCodes)}
						loading={null}
						then={theNgsLabelThen}
					/>
				</span>
				{getNgsTooltip(id)}
			</span>
		) : null;
	}
}
