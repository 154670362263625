import { getIfScopeDomestic } from '../list/scope';
import { sendPV } from './lightUbt';

export const updatePageId = (item) => {
	sendPV(item);
};
export const updatePageIdAndPagePv = (pageId) => {
	// 更新一次算一次pv
	// http://docs.ubt.ctripcorp.com/books/ubt-manual/dev-guide/js/faq/pageid
	sendPV({
		pageId,
	});
};

export const PAGE_IDS = getIfScopeDomestic()
	? Object.freeze({
			LIST_S: '10320673302',
			LIST_D: '10320673304',
			LIST_M: '10320673306',
	  })
	: Object.freeze({
			LIST_S: '10320672927',
			LIST_D: '10320672928',
			LIST_M: '10320672929',
	  });

export const getListPageIdByUrl = (url) => {
	if (url) {
		if (url.indexOf('oneway') > 0) {
			return PAGE_IDS.LIST_S;
		} else if (url.indexOf('round') > 0) {
			return PAGE_IDS.LIST_D;
		} else if (url.indexOf('multi') > 0) {
			return PAGE_IDS.LIST_M;
		} else {
			console.error(`unknown list page url: ${url}`);
		}
	} else {
		console.error(`url is required`);
	}

	return '';
};
