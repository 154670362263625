import { defaultFlightProps } from '../list/defaultFlightProps';
import { ITINERARY_TAG_TYPE, ItineryaryTagWrapper } from '../../constants/list/enums/itineraryTagType';
import SegmentHandle from './segmentHandle';
import { initThePrice } from './initThePrice';
import { INVOICE_TYPE, CABIN } from '../../constants/common/enum/common';
import { FILTER_ELIGIBILITY_TYPE } from '../../constants/list/enums/eligibility';
import { CABIN_OF_VIRTUAL_FLIGHT } from '../../constants/list/enums/cabinOfVirtualFlight';
import { LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../ubt.v2/list';
import { getRefinedRelatedPrices } from './genRelatedPrices';
import { getIfFlightAsLowPrice } from '../list/getIfFlightAsLowPrice';
import { getIfScopeDomestic } from '../list/scope';
import { PRICE_SORT_TYPE_GROUP, FLIGHT_SEGMENT_TYPE } from '../../constants/list/enums/domesticPriceSortTypeGroup';

const defaultFlightPropKeys = Object.keys(defaultFlightProps);

const ITINERARY_TAG_TYPE_KEYS = Object.keys(ITINERARY_TAG_TYPE.toJSON());

const getCabinEnumOf = (cabin) =>
	CABIN.get(cabin) || CABIN_OF_VIRTUAL_FLIGHT.get(cabin) || CABIN_OF_VIRTUAL_FLIGHT.get('OtherClass');
const union = (arr1 = [], arr2 = []) => {
	let arr = arr1.concat(arr2);
	return Array.from(new Set(arr));
};
const intersection = (a = [], b = []) => a.filter((item) => b.includes(item));
const scopeDomestic = getIfScopeDomestic();

export const initTheFlights = (flights, currentSegmentSeq, ifHighCabinNecessary, isBuildUp) => {
	const prevCond = window.GlobalSearchCriteria,
		prevCabinEnum = prevCond.cabinEnum,
		// [国际航班的备注（国内航班不做此特殊处理，如果搜索高舱，返回的一个航班同时有高舱和低舱，则把这个航班当成非推荐航班）]
		// [A1]
		// 查询条件是否是高舱等，是的话就在航班同时包含查询条件高舱等和查询条件外高舱等时候，把查询条件外高舱等运价剔除掉，比如
		// 搜索 C 出来一个航班 既有 C 舱等运价，又有F舱等运价，这时候把F运价剔除；
		// 搜索 F 出来一个航班 既有 C 舱等运价，又有F舱等运价，这时候把C运价剔除；
		// 旧逻辑就这样处理，觉得可能是因为推荐类运价会在专门区块有提示区，如果上面这种情况就会出现推荐类运价和非推荐类运价同时出现在一个航班，
		// 不管该航班是在哪个区块，都可能让用户误解
		prevCondHighCabin = prevCabinEnum.value.isHighCabin,
		adultCount = prevCond.adultCount,
		childCount = prevCond.childCount,
		infantCount = prevCond.infantCount,
		totalSegmentCount = prevCond.flightSegments.length,
		totalPassengerCount = adultCount + childCount + infantCount;

	/**
            RecClass: '',   //所有推荐航班舱位连接起来，比如 YSF，推荐定义是 非查询条件舱位 的航班
            RecDfNum: 0,    //所有推荐直飞航班数目，推荐定义是 非查询条件舱位 的航班
            SearchClass: prevCond.cabin,    //查询条件的舱位
            SearchDfNum: 0, //查询条件舱位的航班中直飞航班数目
            SearchTransNum: 0 //查询条件舱位的航班中中转航班数目
     */

	let basicDataRawData = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData,
		isFcRecSy = false,
		theRecClass = [],
		theRecDfNum = 0,
		theSearchDfNum = 0,
		theSearchTransNum = 0;

	LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({
		resetData: {
			flightWay: prevCond.flightWay,
			segmemtNo: currentSegmentSeq,
			totalTransferFlightCount: 0,
			totalDirectFlightCount: 0,
			showDirectFlightCount: 0,
			showTransferFlightCount: 0,
			showVisaFlightCount: 0,
			showNoVisaFlightCount: 0,
		},
	});

	if (prevCondHighCabin && basicDataRawData && basicDataRawData.FC_rec_SY) {
		//两舱埋点，每次拿到航班结果都初始化埋点航班
		isFcRecSy = true;
		if (ifHighCabinNecessary) {
			basicDataRawData.FC_rec_SY = Object.assign({}, basicDataRawData.FC_rec_SY, {
				RecClass: '',
				RecDfNum: 0,
				SearchDfNum: 0,
				SearchTransNum: 0,
			});
		}
	}

	flights.forEach((flight, _index) => {
		defaultFlightPropKeys.forEach((key) => (flight[key] = flight[key] || defaultFlightProps[key]));

		//枚举转换
		flight.itineraryTagEnums = [];
		flight.itineraryTags.forEach((tag) => {
			const tagType = tag.type;
			if (ITINERARY_TAG_TYPE_KEYS.includes(tagType)) {
				if (['TongChengFlight', 'G5_TC_Flight', 'G5_LC_Flight', 'ApprovedVirtualFlight'].includes(tagType)) {
					flight.tongChengFlightEnum = new ItineryaryTagWrapper(tag); // 华夏通程
				} else {
					tag.extData = {
						adultCount,
						childCount,
						infantCount,
					};
					flight.itineraryTagEnums.push(new ItineryaryTagWrapper(tag));
				}
			} else {
				console.error(
					`unknown flight itineraryTag: ${tag.type}, all known values: ${ITINERARY_TAG_TYPE_KEYS.join(',')}`
				);
			}
		});

		let flightSegments = flight.flightSegments,
			flightSegmentsLength = flightSegments.length;

		for (let i = 0; i < flightSegmentsLength; i++) {
			const flightSegment = flightSegments[i];
			let theHandledSegment = SegmentHandle.initTheSegment(flightSegment);
			flight.flightSegments.splice(i, 1, theHandledSegment);
			// 统计中转和直飞航班数量
			if (flightSegment.segmentNo == currentSegmentSeq + 1 || isBuildUp) {
				if (flightSegment.transferCount > 0) {
					LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({
						totalTransferFlightCount: 1,
					});
				} else {
					LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW.value.process({
						totalDirectFlightCount: 1,
					});
				}
			}
		}

		let minPriceWithTax = null,
			minPriceWithoutTax = null,
			taxOfMinPriceWithoutTax = null, //不含税最低运价的税费
			priceOfMinPriceWithoutTax = null, //不含税最低运价的票价
			totalOfMinPriceWithTax = null, //税最低运价的票价和税费总价
			containsRecommendCabin = false, //是否包含推荐舱位运价
			containsNonRecommendCabin = false, //是否包含非推荐舱位运价
			containsHighCabin = false, //是否包含高舱位运价
			containsLowCabin = false, //是否包含低舱位运价
			currentSegmentSegmentType =
				flight.flightSegments[currentSegmentSeq] && flight.flightSegments[currentSegmentSeq].segmentType,
			currentSegment = flight.flightSegments[currentSegmentSeq],
			allPriceIsHighCabin = true,
			initFlightPrice = () => {
				// 此处需要考虑到品牌运价会出现内嵌的价格，这些价格信息也要参与到航班最低运价的计算
				let unionedRelatedPrices = flight.priceList;
				flight.priceList.forEach((price) => {
					const relatedPrices = getRefinedRelatedPrices(price);
					if (relatedPrices) {
						unionedRelatedPrices = unionedRelatedPrices.concat(relatedPrices);
					}
				});

				unionedRelatedPrices.forEach((price) => {
					//当婴儿儿童价格没有返回时表示不可订
					if (childCount > 0) {
						if (typeof price['childPrice'] === 'undefined') {
							price['childDisabledBook'] = true;
						}
					}
					if (infantCount > 0) {
						if (typeof price['infantPrice'] === 'undefined') {
							price['infantDisabledBook'] = true;
						}
					}

					price = initThePrice(price);
					//此处不设置价格枚举，否则此文件被Worker中使用时候需要引入太多依赖
					let adultPrice = price.adultPrice,
						childPrice = price.childPrice,
						infantPrice = price.infantPrice,
						sortPrice = price.sortPrice,
						adultTax = price.adultTax,
						childTax = price.childTax,
						infantTax = price.infantTax,
						// 国内总价均价，都是成人价, 没有税费
						totalPrice = scopeDomestic
							? sortPrice
							: adultPrice * adultCount + childPrice * childCount + infantPrice * infantCount,
						totalTax = adultTax * adultCount + childTax * childCount + infantTax * infantCount,
						avgTax = Math.ceil(totalTax / totalPassengerCount),
						avgPriceWithoutTax = scopeDomestic ? totalPrice : Math.ceil(totalPrice / totalPassengerCount),
						avgPriceWithTax = scopeDomestic
							? totalPrice
							: Math.ceil((totalPrice + totalTax) / totalPassengerCount);

					// 标记运价余票数目是否小于等于成人+儿童数量减2（用来标记是否需要显示 抢订）
					if (price.ticketCount && price.ticketCount <= adultCount + childCount + 2) {
						price.notMore2Tickets = true;
					}

					if (minPriceWithoutTax == null || avgPriceWithoutTax < minPriceWithoutTax.avg) {
						minPriceWithoutTax = {
							ADULT: adultPrice,
							CHILD: childPrice,
							INFANT: infantPrice,
							avg: avgPriceWithoutTax,
						};
						priceOfMinPriceWithoutTax = minPriceWithoutTax;
						taxOfMinPriceWithoutTax = {
							ADULT: adultTax,
							CHILD: childTax,
							INFANT: infantTax,
							avg: avgTax,
						};
					}

					if (minPriceWithTax == null || avgPriceWithTax < minPriceWithTax.avg) {
						minPriceWithTax = {
							ADULT: adultPrice + adultTax,
							CHILD: childPrice + childTax,
							INFANT: infantPrice + infantTax,
							avg: avgPriceWithTax,
						};
						totalOfMinPriceWithTax = minPriceWithTax;
					}

					if (price.cabin.length === 1 && totalSegmentCount > 1) {
						price.cabin = Array(totalSegmentCount).fill(price.cabin).join('|');
					}

					//是否最后航程
					let isLastSegment = prevCond.flightSegments.length === currentSegmentSeq + 1,
						allCabinsInPrice = price.cabin.split('|'), // [Y, S-F]
						mainCabinEnumList = allCabinsInPrice
							.map((c) => (c.includes('-') ? c.split('-').find((c2) => c2.startsWith('@')) : c))
							.map((c) => getCabinEnumOf(c.replace('@', ''))),
						cabinList = [],
						allSegsCabins = [],
						relatedSegments = isLastSegment
							? flight.flightSegments
							: [flight.flightSegments[currentSegmentSeq]];

					relatedSegments.forEach((segment) => {
						let flightListCount = segment?.flightList.length,
							segmentIndex = segment?.segmentNo - 1,
							cabinsInSegment = (allCabinsInPrice[segmentIndex] || '').split('-');

						if (cabinsInSegment[0] && cabinsInSegment.length === 1 && flightListCount > 1) {
							allCabinsInPrice[segmentIndex] = Array(flightListCount).fill(cabinsInSegment[0]).join('-');
						}

						//虚拟航班舱等处理
						if (segment?.containsVirtualFlight) {
							let vfArr = (allCabinsInPrice[segmentIndex] || '').split('-');
							segment?.flightList.forEach((f, i) => {
								if (f.virtualFlight) {
									vfArr[i] = 'T';
								}
							});
							allCabinsInPrice[segmentIndex] = vfArr.join('-');
						}
					});

					if (isLastSegment) {
						cabinList = allCabinsInPrice
							.map((cabins) => cabins.split('-'))
							.reduce((prev, next) => prev.concat(next), []);
					} else {
						cabinList = (allCabinsInPrice[currentSegmentSeq] || '').split('-');
					}
					const allCurrentSegmentCabins = (allCabinsInPrice[currentSegmentSeq] || '')
						.split('-')
						.map((cabin) => cabin.replace('@', ''));
					const allCurrentSegmentCabinEnumList = allCurrentSegmentCabins.map((cabinKey) =>
						getCabinEnumOf(cabinKey)
					);
					allSegsCabins = allCabinsInPrice.map((cabins) => cabins.split('-'));

					let currentSegmentCabins = allCabinsInPrice[currentSegmentSeq], //当前程舱等
						currentSegmentCabinsArray = currentSegmentCabins ? currentSegmentCabins.split('-') : [],
						mainCabinInLatestSegment = currentSegmentCabinsArray.find((cabin) => cabin.startsWith('@')),
						mainCabin = (mainCabinInLatestSegment || currentSegmentCabinsArray[0] || '').replace('@', ''),
						getCabinEnum = (cabin) => {
							cabin = cabin.replace('@', '');
							return getCabinEnumOf(cabin);
						};

					price.currentSegmentCabinEnumList = currentSegmentCabinsArray.map(getCabinEnum);
					price.mainCabinEnum = mainCabin ? getCabinEnumOf(mainCabin) : null;
					price.mainCabinEnumList = mainCabinEnumList;
					price.allCurrentSegmentCabinEnumList = allCurrentSegmentCabinEnumList;
					price.cabinEnumList = cabinList.map((c) => getCabinEnum(c));
					price.allSegsCabinsEnum = allSegsCabins.map((c) => c.map((cab) => getCabinEnum(cab)));
					price.invoiceTypeEnum = price.invoiceType
						.split(',')
						.filter((element, index, self) => self.indexOf(element) === index)
						.map((c) => INVOICE_TYPE.get(c))
						.filter((invoce) => invoce);
					if (price.invoiceTypeEnum.length) {
						price.invoiceType = price.invoiceTypeEnum.map((theEnum) => theEnum.key).join(',');
					} else {
						price.invoiceType = 'NONE';
						price.invoiceTypeEnum = [INVOICE_TYPE.NONE];
					}

					price.eligibilityEnum = FILTER_ELIGIBILITY_TYPE.get(price.eligibility);
					//当查询出来的运价的任何一舱等不在查询条件舱等中，该运价就是推荐运价
					//影响就是不参与到筛选舱等的生成，但需要参与其他筛选项生成
					let includeCabinKeys = prevCabinEnum.value.includeKeys;
					// 国内航班当成是永远不含推荐，因为不因为含推荐而显示区块划分
					let isRecommendCabin = scopeDomestic
						? false
						: prevCondHighCabin
						? union(allCurrentSegmentCabins, includeCabinKeys).length !== includeCabinKeys.length
						: !includeCabinKeys.includes(mainCabin);
					if (includeCabinKeys.length === 1 && includeCabinKeys[0] === 'C') {
						// 单独搜索公务舱时，C+F=C
						isRecommendCabin = !(
							union(allCurrentSegmentCabins, ['C', 'F']).length === 2 &&
							allCurrentSegmentCabins.includes('C')
						);
					}

					price.isRecommendCabin = isRecommendCabin;

					if (isRecommendCabin) {
						containsRecommendCabin = true;
					} else {
						containsNonRecommendCabin = true;
					}

					if (price.mainCabinEnum?.value.isHighCabin) {
						containsHighCabin = true; //航班是否包含高舱运价
					}

					if (intersection(allCurrentSegmentCabins, ['Y', 'S']).length) {
						containsLowCabin = true; //航班是否包含低舱运价
						allPriceIsHighCabin = false;
					}

					if (scopeDomestic) {
						// 对所有运价进行分类，用于分组展示和排序

						// 1.中转特惠优先置顶
						if (price.preferentialTransit) {
							price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('PREFERENTIAL_TRANSIT');
						}

						// 2.婴儿儿童不可订优先级最高，先对运价进行婴儿儿童不可订分类

						let childDisabledBook = price.childDisabledBook,
							infantDisabledBook = price.infantDisabledBook,
							childOrInfantDisabledBook = infantDisabledBook || childDisabledBook;

						// 运价如果为婴儿儿童不可订，则为婴儿儿童不可订
						if (childOrInfantDisabledBook && infantCount > 0 && childCount > 0) {
							// 搜索儿童和婴儿，儿童或者儿童不可订
							price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('CHILD_OR_INFANT_DISABLED');
						} else if (childDisabledBook && infantCount <= 0) {
							// 搜索儿童，儿童不可订
							price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('CHILD_DISABLED');
						} else if (infantDisabledBook && childCount <= 0) {
							// 搜索婴儿，婴儿不可订
							price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('INFANT_DISABLED');
						}

						// 3.给没有归类的运价进行分类
						if (!price.sortTypeGroupEnum) {
							switch (currentSegmentSegmentType) {
								case FLIGHT_SEGMENT_TYPE.FLIGHT_TRAIN.key:
								case FLIGHT_SEGMENT_TYPE.FLIGHT_BUS.key:
									price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('OTHER_TRANSFER_TYPE');
									break;
								case FLIGHT_SEGMENT_TYPE.FLIGHT.key:
									if (currentSegment.transferCount > 0) {
										price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('TRANSFER');
									} else {
										price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('FLIGHT');
									}
									break;
								default:
									price.sortTypeGroupEnum = PRICE_SORT_TYPE_GROUP.get('FLIGHT');
							}
						}
					}
				});
			};

		initFlightPrice();
		flight.containsRecommendCabin = containsRecommendCabin;
		flight.isFCRecommendYS = prevCondHighCabin ? containsRecommendCabin : false;
		flight.containsHighCabin = containsHighCabin;
		flight.containsLowCabin = containsLowCabin;
		flight.allPriceIsHighCabin = allPriceIsHighCabin;

		// [国际航班需要做此特殊处理，因为国内航班的 containsRecommendCabin 永远为 false]
		//如果查询的是高舱，同时一个航班既有推荐舱位又有非推荐舱位，则剔除推荐舱位，理由见顶部[A1]
		//查询高舱，AGG会保证一个批次返回的运价不会同时包含高舱和低舱，但跨批次时候AGG不保证，站点做二次过滤
		//但可能出现查询F出来一个航班同时有F和C，此时C运价直接被扔掉
		if (prevCondHighCabin && containsRecommendCabin && containsNonRecommendCabin) {
			flight.priceList = flight.priceList.filter((price) => !price.isRecommendCabin);
			flight.containsRecommendCabin = false; //扔掉推荐运价后修正航班是否包含推荐运价字段
			flight.isFCRecommendYS = false;
			//要重新计算下航班最低价
			minPriceWithTax = null;
			minPriceWithoutTax = null;
			taxOfMinPriceWithoutTax = null;
			priceOfMinPriceWithoutTax = null;
			totalOfMinPriceWithTax = null;
			initFlightPrice();
		}
		if (scopeDomestic) {
			// 国内抢
			// （1） 价格维度：为该航班下的最低运价（附带的X产品费用不进入计算）
			// （2） 余票量纬度：1<=余票量<=3
			// （3） 不存在其他最低价格不满足余票量纬度的情况
			minPriceWithTax &&
				flight.priceList.forEach((price) => {
					if (
						price.priceWithTax.avg == minPriceWithTax.avg &&
						price.ticketCount <= 3 &&
						price.ticketCount >= 1
					) {
						price.rapidBook = true;
					}
					let exitOtherLowestPriceIsNotRapidBook = flight.priceList.some((otherPrice) => {
						return minPriceWithTax.avg == otherPrice.priceWithTax.avg && price.ticketCount > 3;
					});

					if (exitOtherLowestPriceIsNotRapidBook) {
						price.rapidBook = false;
					}
				});
		} else {
			// 国际抢
			// 运价显示 抢订 按钮的条件有三个：
			// 1）该运价在所属航班中含税价最低
			// 2）该运价的余票数量少于等于搜索条件的成人+儿童+2
			// 3）该航班下如果其他运价价格与该运价相同，则其他同价格运价也要满足条件2
			minPriceWithTax &&
				flight.priceList.forEach((price) => {
					if (minPriceWithTax.avg === price.priceWithTax.avg && price.notMore2Tickets) {
						// 此处查找其他与该运价相同的价格的运价，判断其是否余票数符合条件
						if (
							flight.priceList.every(
								(otherPrice) =>
									otherPrice === price ||
									minPriceWithTax.avg !== otherPrice.priceWithTax.avg ||
									otherPrice.notMore2Tickets
							)
						) {
							price.rapidBook = true; // 预订按钮显示 “抢订”
						}
					}
				});
		}

		flight.minPriceWithoutTax = minPriceWithoutTax;
		flight.minPriceWithTax = minPriceWithTax;
		flight.taxOfMinPriceWithoutTax = taxOfMinPriceWithoutTax;
		flight.priceOfMinPriceWithoutTax = priceOfMinPriceWithoutTax;
		flight.totalOfMinPriceWithTax = totalOfMinPriceWithTax;
		//筛选直接放过的航班：虚拟航班
		flight.skipFilter = !!flight.virtualFlight;
		flight._ifFlightAsLowPrice = getIfFlightAsLowPrice(flight);

		if (isFcRecSy) {
			const isDirectFlight = flight.flightSegments.every((segment) => segment.transferAndStopCount === 0);

			if (flight.containsRecommendCabin) {
				const mainCabinList = flight.priceList.map((price) => price.mainCabinEnum.key);
				for (let i = 0; i < mainCabinList.length; i++) {
					const cabin = mainCabinList[i];
					if (theRecClass.indexOf(cabin) < 0) {
						theRecClass.push(cabin);
					}
				}

				//是否直飞
				if (isDirectFlight) {
					theRecDfNum++;
				}
			} else {
				if (isDirectFlight) {
					theSearchDfNum++;
				} else {
					theSearchTransNum++;
				}
			}
		}
	});

	if (ifHighCabinNecessary) {
		sessionStorage.removeItem('FC_rec_SY');
		if (isFcRecSy) {
			const fcRecSy = Object.assign({}, basicDataRawData.FC_rec_SY, {
				RecClass: theRecClass.join(''),
				RecDfNum: theRecDfNum,
				SearchDfNum: theSearchDfNum,
				SearchTransNum: theSearchTransNum,
			});

			basicDataRawData.FC_rec_SY = fcRecSy;
			sessionStorage.FC_rec_SY = JSON.stringify({ FC_rec_SY: fcRecSy });
		}
	}

	return flights;
};
