import Cookies from 'js-cookie';

export const getSessionId = () => {
    const bfaString = Cookies.get('_bfa');
    if (!bfaString) {
        return '';
    }
    const bfaArr = bfaString.split('.');
    if (!bfaArr || bfaArr.length <= 6) {
        return '';
    }
    return `${bfaArr[6]}`;
};