import MD5 from 'md5.js';

const genCacheKey = (key) => `bomb__${key}`;

/**
 * 根据查询条件信息生成反爬虫Header
 * @param {*查询条件信息} prevCond
 */
export const genAntiCrawlerHeader = (prevCond) => {
	let tripRoute = '',
		sign = '';

	typeof prevCond.toJS === 'function' && (prevCond = prevCond.toJS());

	prevCond.flightSegments.forEach((segment) => {
		let departureCityCode = segment.departureCityCode,
			arrivalCityCode = segment.arrivalCityCode,
			departureDate = segment.departureDate;

		tripRoute += departureCityCode + arrivalCityCode + departureDate;
	});

	const calcKey = prevCond.transactionID + tripRoute;
	const cacheKey = genCacheKey(calcKey);

	sign = window.sessionStorage.getItem(cacheKey);
	if (!sign) {
		sign = new MD5().update(calcKey).digest('hex');
		window.sessionStorage.setItem(cacheKey, sign);
	}

	return {
		sign,
	};
};
