import React from 'react'

export const UbtBoundary = ({ children, tracker, skip = false }) => {
    if (children && tracker && !skip) {
        let { keyEnum } = tracker,
            listen = keyEnum.value.listen

        if (!Array.isArray(listen)) {
            listen = [listen]
        }

        let bindPropInfo = {}

        listen.forEach(entry => {
            bindPropInfo = Object.assign(bindPropInfo, entry.value.getBindPropInfo(children, tracker))
        })

        return React.cloneElement(children, bindPropInfo)
    } else {
        return children || null
    }
}
