import React, { useEffect, useState, useRef } from 'react'


const useRoundTripDays = ({ dDate, aDate }) => {
    const [rtDays, setRtDays] = useState(null)
    useEffect(() => {
        if (dDate && aDate) {
            if (dDate && aDate && dDate.isValid() && aDate.isValid() && aDate.isSameOrAfter(dDate)) {
                setRtDays(`${aDate.diff(dDate, 'days') + 1}`)
            } else {
                setRtDays(null)
            }
        } else {
            setRtDays(null)
        }
    }, [dDate, aDate])

    return rtDays
}
const RoundTripDays = ({ dDate, aDate, onShow, flightWay }) => {
    const rtDays = useRoundTripDays({ dDate, aDate }),
        rtDaysRef = useRef(),
        [left, setLeft] = useState(0)
    useEffect(() => {
        if (rtDaysRef && rtDaysRef.current) {
            setLeft(-rtDaysRef.current.offsetWidth / 2)
        }
        rtDays ? onShow(true) : onShow(false)
    }, [rtDays, rtDaysRef, flightWay])

    return rtDays != null && rtDays > 1 ? <div ref={rtDaysRef} style={{ left: left + 'px', display: 'inline-block', padding: '3px 4px' }} className="rt-days">{rtDays}天</div> : null

}
const CalendarRoundTripDays = ({ dDate, aDate }) => {
    const rtDays = useRoundTripDays({ dDate, aDate })
    return rtDays != null ? <div>往返{rtDays}天</div> : null
}
export { RoundTripDays, CalendarRoundTripDays }

