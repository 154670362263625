import Immutable from 'immutable'

let MEMOIZE_LIST = {},
    CACHE_COUNT = 2

/**
 * 通过缓存，减少相同参数的指定方法重复执行
 * 备注：只能缓存最后一次执行的结果，之前的会扔掉（减轻GC压力）
 * @param {*缓存方法KEY} key
 * @param {*缓存方法自身} func
 * @param {*缓存方法参数} args
 */
let memoize = (key, func, ...args) => {
    if (!MEMOIZE_LIST[key]) {
        MEMOIZE_LIST[key] = []
    }

    let cachedFuncList = MEMOIZE_LIST[key],
        execFunc = () => {
            let result = func(...args)
            cachedFuncList.push({ args, result })

            if (cachedFuncList.length > CACHE_COUNT) {
                cachedFuncList.splice(0, cachedFuncList.length - CACHE_COUNT)
            }

            return result
        },
        finalResult = null,
        theExactMatchCacheFunc = cachedFuncList.find(cacheFunc => cacheFunc.args && args && cacheFunc.args.every((argInCache, indexInCache) => {
            return (argInCache === args[indexInCache])
                || (argInCache instanceof Immutable.List && args[indexInCache] instanceof Immutable.List && Immutable.is(argInCache, args[indexInCache]))
                || (argInCache instanceof Immutable.Map && args[indexInCache] instanceof Immutable.Map && Immutable.is(argInCache, args[indexInCache]))
                || (argInCache instanceof Array && args[indexInCache] instanceof Array && argInCache.length === args.length && argInCache.every((argEl, indexEl) => argEl === args[indexInCache][indexEl]))
        }))

    if (theExactMatchCacheFunc) {
        finalResult = theExactMatchCacheFunc.result
    } else {
        finalResult = execFunc()
    }

    return finalResult
}

export default memoize

/**
 * 将类进行Memoize化包装，将其方法可进行本地缓存，减少重复计算
 * @param {*类名KEY} memoizeKey
 * @param {*目标类} target
 */
export const memoizeWrapper = (memoizeKey, target) => {
    let wrapper = {},
        keys = Object.getOwnPropertyNames(target),
        reservedKeys = ['prototype', 'caller', 'arguments']

    for (let index in keys) {
        let key = keys[index]

        if (reservedKeys.indexOf(key) >= 0) {
            continue
        }

        if (target.hasOwnProperty(key)) {
            if (typeof target[key] === 'function') {
                let execFunc = target[key]
                wrapper[key] = (...args) => {
                    return memoize(`${memoizeKey}.${key}`, execFunc, ...args)
                }
            } else {
                wrapper[key] = target[key]
            }
        }
    }

    return wrapper
}
