import Enum from 'enum'
import mergeConfig from '../mergeConfig'
import Q_CONFIG_KEYS from '../../book/common/qConfigKeys'

// hover文案默认值
const _HOVER_MESSAGE = {
    NO_VISA_RISK_MUILT_PU: {
        title: '换票换乘',
        text: ['<span class=\'f-red\'>【换票换乘-行李提醒】</span>换乘时行李额（公斤/件数）可能有差异，请确认行李额再出行。<br />',
            '<span class=\'f-red\'>【换票换乘-时长提醒】</span>因需重新办理登机手续，请确保在[CITY]换乘时预留足够的换乘时间，因乘客不合理安排换乘时长导致无法登机或出行，需乘机人自行承担责任。<br />']
    },
    HAS_VISA_RISK_MUILT_PU: {
        title: '换票换乘',
        text: ['<span class=\'f-red\'>【换票换乘-签证提醒】</span>该行程因需要在“[CITY]”重新出入关，可能需要换乘地所属国家或地区的过境类签证，签证政策因乘客国籍而异，请自行确认相关国家或地区过境政策，因证件不齐等原因导致无法登机或出行，需乘机人自行承担责任。[EXT_VISA_TIP]<br />',
            '<span class=\'f-red\'>【换票换乘-行李提醒】</span>换乘时行李额（公斤/件数）可能有差异，请确认行李额再出行。<br />',
            '<span class=\'f-red\'>【换票换乘-时长提醒】</span>因需重新出入关和办理登机手续，请确保在[CITY]换乘时预留足够的换乘时间，因乘客不合理安排换乘时长导致无法登机或出行，需乘机人自行承担责任。<br />']
    },
    TRANSFER_LUGGAGE: {
        preview: '行李非直达',
        title: '自取行李',
        typeName: '自取行李',
        tipTitle: '托运行李',
        text: '行李非直达，需在机场重新办理托运'
    },
    TRANSFER_LUGGAGE_V2: {
        preview: '重新托运行李',
        title: '自取行李',
        tipTitle: '重新托运行李',
        typeName: '自取行李',
        text: '行李非直达，需在机场重新办理托运'
    },
    DIRECT_LUGGAGE: {
        preview: '行李直达',
        title: '行李直达',
        tipTitle: '托运行李',
        typeName: '行李直达',
        text: '行李直达，无需在机场重新办理托运'
    },
    DIRECT_LUGGAGE_V2: {
        preview: '行李直达',
        title: '行李直达',
        tipTitle: '行李直达',
        typeName: '行李直达',
        text: '无需在机场重新办理托运'
    },
    CONSULT_LUGGAGE: {
        preview: '',
        title: '咨询发始地',
        tipTitle: '咨询发始地',
        typeName: '中转行李提醒',
        text: '是否需要办理中转托运，需咨询始发地值机柜台'
    },
    DIFFERENT_TERMINAL: {
        title: '中转不同航站楼',
        text: '此行程需要从航站楼[ARRIVAL]换乘[DEPARTURE]，请合理安排您的时间和行程。'
    },
    TRANSFER_AIRPORT: {
        title: '中转换机场',
        text: '此行程中转需要从[AIRAIRPORT]到[DEPAIRPORT]，请注意安排转机时间。'
    },
    SHORT_TIME_TRANSFER: {
        title: '[TYPE]时间短',
        text: '此行程[CITY][TYPE]时间短，请提前了解[TYPE]流程，合理安排时间，以免[TIP]。'
    },
    PRE_TRAMS_VIRTUAL_FLIGHT: {
        title: '换火车',
        fullTitle: '机场内换乘火车',
        text: '可机场内换乘火车，请乘客根据车站内对应机场/火车方向指示牌换乘'
    },
    NEXT_TRAMS_VIRTUAL_FLIGHT: {
        title: '换飞机',
        fullTitle: '火车换乘飞机',
        text: '可机场内换乘火车，请乘客根据车站内对应机场/火车方向指示牌换乘'
    },
    BOOK_PRODUCT_TAG: {
        Exclusive: '旅游优惠券(200000积分)'
    },
    BOOK_PRODUCT_TAG_HOVER: {
        Exclusive: '<strong>旅行套餐包含：</strong><p style="line-height:1.8; padding-bottom:10px;">价值<span class="f-orange">200000</span>积分的旅游优惠券一张+机票一张。</p><strong>旅游优惠券使用说明：</strong><p style=" line-height:1.8; padding-bottom:10px;">购买旅游度假产品订单满<span class="price"><dfn>¥</dfn>5000</span>减<span class="price"><dfn>¥</dfn>100</span>、满<span class="price"><dfn>¥</dfn>10000</span>减<span class="price"><dfn>¥</dfn>200</span>、满<span class="price"><dfn>¥</dfn>20000</span>减<span class="price"><dfn>¥</dfn>400</span>、满<span class="price"><dfn>¥</dfn>50000</span>减<span class="price"><dfn>¥</dfn>1000</span>、满<span class="price"><dfn>¥</dfn>100000</span>减<span class="price"><dfn>¥</dfn>2000</span>，每订单每个优惠券限用一次。不适用于签证、欧铁、用车、顶级游、门票、景点+酒店套餐及不参与优惠券活动的特例产品。优惠券不可与其他优惠活动同时使用，请登陆后输入代码确认可否使用。（有效期至[TIME]）</p><p style=" line-height:1.8;">注：本产品特供网络及手机客户端用户专享。仅提供旅行套餐发票。</p>',
    },
    FLIGHT_INFO: {
        IATA_HEADER: 'IATA号：'
    }
}

const HOVER_MESSAGE = new Enum(mergeConfig(Q_CONFIG_KEYS.BOOK_FLIGHT_HOVER_MESSAGE, _HOVER_MESSAGE))

export default HOVER_MESSAGE
