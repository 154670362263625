import { createSelector } from 'reselect';
import Immutable from 'immutable';
import getMtSegList from '@/src/reducers/list/getMtSegList';
import { isDomesticFlight } from '../../../sources/common/isDomesticFlight';

// classgrade tab active props
const getActiveTabId = (state, type, id) => {
	return state.getIn(['list', id || 'search', type]);
};

export const getSearchActiveTabId = createSelector(getActiveTabId, (activeId) => activeId);

// search component props
const getSelectedSearchProps = (state) => {
	return state.getIn(['list', 'search']);
};

export const getSearchProps = createSelector(getSelectedSearchProps, (searchProps) => searchProps);
export const selectedCitySelector = (state) => state.getIn(['list', 'search', 'selectedCity']);
export const getMtSegListSelector = (state) => (selectedCityPath) => {
	const list = getMtSegList(state.getIn(selectedCityPath || ['list', 'search', 'selectedCity']));
	return Immutable.fromJS(list);
};
export const currentFlightWaySelector = (state) => state.getIn(['list', 'search', 'flightWay']);
export const domesticCityDataSelector = (state) => state.getIn(['list', 'search', 'domesticCityData']);

// 搜索框中的城市/地区是否都是中国大陆（涉及中国港澳台就属于国际航线）
export const searchBoxOnlyDomesticSelector = createSelector(
	[selectedCitySelector, getMtSegListSelector, currentFlightWaySelector],
	(selectedCity, getMtSegList, flightWay, domesticSearchPage) => {
		return isDomesticFlight(selectedCity, getMtSegList(), flightWay, domesticSearchPage);
	}
);

const globalSearchCriteria = (state) => state.getIn(['list', 'search', 'prevCond']);

export const getGlobalSearchCriteria = createSelector(
	globalSearchCriteria,
	(globalSearchCriteria) => globalSearchCriteria
);
export const getGlobalSearchCriterial = createSelector(
	[globalSearchCriteria],
	(globalSearchCriteria) => globalSearchCriteria
);

export const displayClassGradeSelector = createSelector([getSearchProps, searchBoxOnlyDomesticSelector], (search) => {
	return search.get('classGrade');
});

export const displayPassengerSelector = createSelector([getSearchProps, searchBoxOnlyDomesticSelector], (search) => {
	return search.get('passenger').toJS();
});

export const selectedCity = (state) => state.getIn(['list', 'search', 'selectedCity']);

export const flightWaySelector = (state) => state.getIn(['list', 'search', 'flightWay']);

export const getWorkingDays = (state) => state.getIn(['list', 'common', 'workingDays']);

export const getHotDistrict = (state) => state.getIn(['list', 'hotDistrict']);
