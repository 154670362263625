// 舒适度接口
export const POPUP_FLIGHT_COMFORT = 'POPUP_FLIGHT_COMFORT';

// 查询航班信息浮层舒适度信息
export const POPUP_FLIGHT_INFO_CARD_COMFORT = 'POPUP_FLIGHT_INFO_CARD_COMFORT';

// 查询航班信息浮层过境签说明信息
export const POPUP_TRANSIT_VISA_REMARK_CLIST = 'POPUP_TRANSIT_VISA_REMARK_CLIST';

// 清空过境签信息
export const CLEAR_TRANSIT_VISA_REMARK_LIST = 'CLEAR_TRANSIT_VISA_REMARK_LIST';

//停留超时
export const STAY_TIME_OUT_FLAG = 'STAY_TIME_OUT_FLAG';

// 航班过境签/行李直挂说明
export const POPUP_VISA_LUGGAGE_DIRECT_INFO = 'POPUP_VISA_LUGGAGE_DIRECT_INFO';
