import Enum from 'enum'

//往返时出游天数枚举
export const HOT_COUNTRY_STAYDAYS = new Enum({
    FREE: {
        text: '不限',
        maxDays: null,
        minDays: null
    },
    THREE2FIVE: {
        text: '3-5天',
        maxDays: 5,
        minDays: 3
    },
    SIX2EIGHT: {
        text: '6-8天',
        maxDays: 8,
        minDays: 6
    },
    NINE2ELEVEN: {
        text: '9-11天',
        maxDays: 11,
        minDays: 9
    },
    TWELVE2FIFTEEN: {
        text: '12-15天',
        maxDays: 15,
        minDays: 12
    }
})
//跳转时ctm内容枚举：搜索的poi点击为search，热门城市国家选择器点击为suggestion
export const COUNTRY_SOURCE = new Enum({
    SEARCH: {
        ctm: '#ctm_ref=fli_hp_sb_findlprice_t'
    },
    SUGGESTION: {
        ctm: '#ctm_ref=fli_hp_sb_findlprice_b'
    }
})