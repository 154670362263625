export default {
    mapSearch: {
        FETCH_INIT_MAP_URL: "MAP_SEARCH_FETCH_INIT_MAP_URL",
        INIT_MAP_URL: "MAP_SEARCH_INIT_MAP_URL",
        UPDATE_ISLOADED_GOOGLEMAP: "MAP_SEARCH_UPDATE_ISLOADED_GOOGLEMAP",
        INIT_DATA: "MAP_SEARCH_INIT_DATA",
        FLIGHT_WAY_CHANGE: "MAP_SEARCH_FLIGHT_WAY_CHANGE",
        CITY_CHANGE: "MAP_SEARCH_CITY_CHANGE",
        DATE_CHANGE: "MAP_SEARCH_DATE_CHANGE",
        ISONLYDIRECT_CHANGE:"MAP_SEARCH_ISONLYDIRECT_CHANGE",
        FETCH_INIT_CITY_PRICE: "MAP_SEARCH_FETCH_INIT_CITY_PRICE",
        INIT_CITY_PRICE: "MAP_SEARCH_INIT_CITY_PRICE",
        CITY_PRICE_FETCH_DONE: "MAP_SEARCH_CITY_PRICE_FETCH_DONE",
        FETCH_INIT_COUNTRY_LIST: "MAP_SEARCH_FETCH_INIT_COUNTRY_LIST",
        INIT_COUNTRY_LIST: "MAP_SEARCH_INIT_COUNTRY_LIST",
        FETCH_COUNTRY_LOW_PRICE_SUCCESS_ACTION: 'MAP_FETCH_COUNTRY_LOW_PRICE_SUCCESS_ACTION',
        FETCH_COUNTRY_LOW_R_PRICE_SUCCESS_ACTION: 'MAP_FETCH_COUNTRY_LOW_R_PRICE_SUCCESS_ACTION'
    }
}