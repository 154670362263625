import { fetchPost } from '../common'
import * as types from '../../constants/list/actionTypes/notice.js'
import CONFIG_URL from '../../constants/list/api'

// 顶部公告对应actions，为了避免命名冲突，ACTION统一使用NOTICE开头

/**
 * 关闭第三级公告
 */
export const onCloseNoticeThirdClass = () => ({ type: types.NOTICE_ON_CLOSE_THIRD_CLASS })

/**
 * 关闭一级公告弹框
 */
export const onCloseNoticeFirstClassPop = () => ({ type: types.NOTICE_ON_CLOSE_FIRST_CLASS_POP })

/**
 * 打开广告弹框
 */
export const onOpenAdPop = () => ({ type: types.AD_ON_OPEN })

/**
 * 关闭广告弹宽
 */
export const onCloseAdPop = () => ({ type: types.AD_ON_CLOSE })

/**
 * 请求公告和广告接口
 */
export const onFetchNotices = (params) => dispatch => {
    const url = window.GlobalSwitches?.getSearchNotifyOrHornContentSwitch ? CONFIG_URL.getNotices : CONFIG_URL.getAdsAndNotices;
    return fetchPost(url, params)
        .then(res => res && res.data || {})
        .then(json => {
            const check = window.GlobalSwitches?.getSearchNotifyOrHornContentSwitch ? !!json : !!json && !!json.data
            if (check) {
            const noticeData = window.GlobalSwitches?.getSearchNotifyOrHornContentSwitch ? json.data : json.data.notificationList;
            dispatch(getNewNotices(noticeData || [], params))
            window.GlobalSwitches?.getSearchNotifyOrHornContentSwitch ?  null : dispatch(getNewAds(json.data.adList || []))
            }
        })
}

/**
 * 更新公告信息
 */
export const getNewNotices = (notices, segments) => ({ type: types.NOTICE_GET_NEW_NOTICES, notices, segments })

/**
 * 更新广告消息
 */
export const getNewAds = (ads) => ({type: types.AD_GET_NEW_ADS, ads})
