import { getTransAndStopCount } from '@/src/sources/list/highCabinRec';
import { SortIndicatorType } from './types';

/**
 * 该因子用于直飞优先逻辑：比较的两个航班都是直飞就用价格比，否则返回痛苦指数（其中已折算价格和飞行时长）
 */
export const PRICE_OR_MISERY_INDEX: SortIndicatorType = (
	prevCond,
	flight,
	currentSegmentSeq,
	isBuildUp,
	sortContainsTax
) => {
	const transferAndStopCount = getTransAndStopCount(isBuildUp, flight, currentSegmentSeq);
	if (transferAndStopCount === 0) {
		return flight.get(sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax').get('avg');
	}
	return flight
		.get('priceList')
		.map((price) => {
			return price.get('eligibility') !== 'NOR' ? 10000000 : price.get('miseryIndex');
		})
		.min();
};

/**
 * 跟 PRICE_OR_MISERY_INDEX 逻辑一致，除了痛苦指数是按照当前程，而不是全程
 */
export const CUR_SEGMENT_PRICE_OR_MISERY_INDEX: SortIndicatorType = (
	prevCond,
	flight,
	currentSegmentSeq,
	isBuildUp,
	sortContainsTax
) => {
	// const transferAndStopCount = getTransAndStopCount(isBuildUp, flight, currentSegmentSeq);
	// if (transferAndStopCount === 0) {
	// return flight.get(sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax').get('avg');
	// } else {
	// 	return flight
	// 		.get('priceList')
	// 		.map((price) => {
	// 			return price.get('eligibility') !== 'NOR' ? 10000000 : price.get('curSegmentMiseryIndex');
	// 		})
	// 		.min();
	// }
	// https://tripflt.atlassian.net/browse/RDFE-58888 产品需求，对齐app,不使用痛苦指数参与排序
	return flight.get(sortContainsTax ? 'minPriceWithTax' : 'minPriceWithoutTax').get('avg');
};

/**
 * 该因子用于直飞优先逻辑：比较的两个航班都是直飞就用飞行时长比，否则返回0（非直飞用痛苦指数，其中已折算飞行时长）
 */
export const DURATION_OR_NONE: SortIndicatorType = (
	prevCond,
	flight,
	currentSegmentSeq,
	isBuildUp,
	_sortContainsTax
) => {
	// const transferAndStopCount = getTransAndStopCount(isBuildUp, flight, currentSegmentSeq);
	// if (transferAndStopCount === 0) {
	// 	if (isBuildUp) {
	// 		return (
	// 			flight.getIn(['flightSegments', 0, 'duration']) + flight.getIn(['flightSegments', 1, 'duration'])
	// 		);
	// 	} else {
	// 		flight.getIn(['flightSegments', currentSegmentSeq, 'duration']);
	// 	}
	// } else {
	// 	return 0;
	// }
	// https://tripflt.atlassian.net/browse/RDFE-58888 产品需求，对齐app,不管直飞还是中专经停，总时长都参与排序
	if (isBuildUp) {
		return flight.getIn(['flightSegments', 0, 'duration']) + flight.getIn(['flightSegments', 1, 'duration']);
	} else {
		return flight.getIn(['flightSegments', currentSegmentSeq, 'duration']);
	}
};
