
import React from 'react'
import { CABIN_INTERNATIONAL, CABIN_DOMESTIC } from "../../../constants/common/enum/common"
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import { GEN_SEARCH_FORM_ANIMATION } from '../../../constants/common/enum/searchForm';

let ClassGrade = ({ ...props }) => {
    const {
        classGrade,
        onSelectedClassGrade,
        dropdownDisplay,
        onSelectActive,
        type,
        domestic
    } = props
    const getClassGradeKey = (classGrade) => {
        let classGradeKey = classGrade
        if ((classGrade == 'Y' || classGrade == 'S') && !domestic) {
            classGradeKey = 'Y_S'
        }
        return classGradeKey
    }
    let animateClass = GEN_SEARCH_FORM_ANIMATION.CLASSGRADE(dropdownDisplay)
    let classGradeOptions = domestic ? CABIN_DOMESTIC : CABIN_INTERNATIONAL

    const getClassGradeOption = (classGrade) => {
        let classGradeOption = classGradeOptions.get(getClassGradeKey(classGrade))
        if (!classGradeOption) {
            // 国内国际航线切换时,没有对应的仓等默认选中不限仓等,或者经济超级经济舱
            classGradeOption = classGradeOptions.get('Y_S_C_F') || classGradeOptions.get('Y_S')
        }
        return classGradeOption

    }
    const classGradeOption = getClassGradeOption(classGrade),
        classGradeOptionVal = classGradeOption.value


    return (
        <div className={`${type == 'SELECT' ? 'form-item-v3-group form-item-v3-stand-out classGrade-selector' : 'flt-subclass'} ${dropdownDisplay ? 'active' : ''} ${animateClass}`} >
            <UbtBoundary tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK, { type: 'classGrade', text: '舱等', value: classGrade })}>
                {type == 'SELECT' ?
                    <div className="form-item-v3 flt-field-v3" onClick={onSelectActive}>
                        <div className="form-select-input ">
                            <div className="form-select-v3">
                                <span className="form-select-txt">
                                    <span>{classGradeOptionVal}</span>
                                </span>
                                <i className="iconf-arrow-down">&#xe60c;</i>
                                <i className="iconf-arrow-up">&#xe604;</i>
                            </div>

                        </div>
                    </div> :
                    <div className="form-select-v3" onClick={onSelectActive}>
                        <span>{classGradeOptionVal}</span>
                        <i className="iconf-arrow-down">&#xe60c;</i>
                        <i className="iconf-arrow-up">&#xe604;</i>
                    </div>
                }
            </UbtBoundary>
            <div className="class-grade-select" style={{ display: dropdownDisplay ? 'block' : 'none', position: 'absolute', top: type == 'SELECT' ? '59px' : '33px', right: '0' }}>
                <ul>
                    {classGradeOptions.enums.map((item, index) => {
                        return (
                            <UbtBoundary key={index} tracker={SearchBoxUbtGenerator(SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK, SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_SELECT, { type: 'classGrade', text: item.value, value: item.key })}>
                                <li className={`${item.key === classGrade ? 'active' : ''}`} onClick={() => { onSelectedClassGrade(item.key) }}>
                                    {item.key === getClassGradeKey(classGrade) ? <div><span className="iconf-check">&#xe666;</span></div> : <div></div>}
                                    <div>{item.value}</div>
                                </li>
                            </UbtBoundary>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}


export default ClassGrade
