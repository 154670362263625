import { createSelector } from 'reselect'
import { List } from 'immutable'
import { RECOMMEND_LOWPRICE_POSITION, ADJACENT_AIRLINE_DIRECT_TYPE } from '../../../../constants/list/enums/recommend.js'
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector'
import { prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector } from '../flight/baseSelector'
import { position, getMinMiseryIndexSelector } from './baseRecommendSelector'

const adjacentAirlineInitSelector = state => state.getIn(["list", "recommend", "adjacentAirline"])
const adjacentAirlineDirectInitSelector = state => state.getIn(["list", "recommend", "adjacentAirlineDirect"])

// 按与出发/到达城市直线距离
const sortNearbyCityDistance = (prev, next) => prev.get('nearbyCityDistance') - next.get('nearbyCityDistance')

// 有低价>无低价数据，有低价数据按照痛苦指数排
const sortLowPrice = (prev, next) => {
    if (prev.get('price') && next.get('price')) return prev.get('miseryIndex') - next.get('miseryIndex')
    if (prev.get('price')) return -1
    if (next.get('price')) return 1
}

// 往返直飞>仅去程直飞
const sortDirectType = (prev, next) => {
    if (!prev.get('directType') && !next.get('directType')) return 0
    else if (prev.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.ROUND.key && next.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.ROUND.key) return 0
    else if (prev.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.DEPARTURE.key && next.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.DEPARTURE.key) return 0
    else if (prev.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.ROUND.key) return -1
    else if (next.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.ROUND.key) return 1
    else if (prev.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.DEPARTURE.key) return -1
    else if (next.get('directType') == ADJACENT_AIRLINE_DIRECT_TYPE.DEPARTURE.key) return -1
}

// 根据多个排序规则进行排序
const sortMultiCond = (prev, next, sortFuncs) => {
    let compareResult = 0
    for (let i = 0; i < sortFuncs.length; i++) {
        let compareResultNow = sortFuncs[i](prev, next)
        if (compareResultNow !== 0) {
            compareResult = compareResultNow
            break
        }
    }
    return compareResult
}

// 只有邻近航线推荐时
export const adjacentAirlineSelector = createSelector([adjacentAirlineInitSelector, prevCondSelector, getMinMiseryIndexSelector, searchNoResultRecommendSelector],
    (adjacentAirline, prevCond, minMiseryIndex, searchNoResultRecommendSelector) => {
        const miseryIndex = prevCond.get("flightWay") === 'S' ? 200 : 400
        // 邻近城市筛选和排序规则
        const cityLowPriceFilter = (item) => minMiseryIndex - item.get("miseryIndex") > miseryIndex
        const cityLowPriceSort = (prev, next) => {
            if (prev.get("miseryIndex") < next.get("miseryIndex")) { return -1 }
            else if (prev.get("miseryIndex") > next.get("miseryIndex")) { return 1 }
            else { return prev.get("nearbyCityDistance") < next.get("nearbyCityDistance") ? -1 : 1 }
        }

        // 无结果时推荐
        if (searchNoResultRecommendSelector && adjacentAirline.size > 0) return adjacentAirline.sort(cityLowPriceSort)

        // 临近航线的筛选和排序
        if (adjacentAirline.size > 0) {
            adjacentAirline = adjacentAirline.filter(cityLowPriceFilter)
            adjacentAirline = adjacentAirline.sort(cityLowPriceSort)
            return adjacentAirline
        }

        return List([])
    })


// 只有邻近航线直飞推荐时
export const adjacentAirlineDirectSelector = createSelector([adjacentAirlineDirectInitSelector, prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector],
    (adjacentAirlineDirect, prevCond, currentSegmentSeq, isBuildUp) => {
        let sortFuncs = [],
            flightSegments = isBuildUp ? prevCond.getIn(['flightSegments', 0]) : prevCond.getIn(['flightSegments', currentSegmentSeq]),
            departureCityCode = flightSegments.get('departureCityCode'),
            arrivalCityCode = flightSegments.get('arrivalCityCode'),
            flightWay = prevCond.get('flightWay')

        // 按出发邻近>到达邻近
        let sortNearCity = (prev, next) => {
            if (prev.get('arrivalCityCode') == arrivalCityCode && next.get('arrivalCityCode') == arrivalCityCode) return 0
            else if (prev.get('departureCityCode') == departureCityCode && next.get('departureCityCode') == departureCityCode) return 0
            else if (prev.get('arrivalCityCode') == arrivalCityCode) return -1
            else if (next.get('arrivalCityCode') == arrivalCityCode) return 1
        }

        if (flightWay == 'S') sortFuncs = [sortNearCity, sortNearbyCityDistance]
        else if (flightWay == 'D') sortFuncs = [sortDirectType, sortNearCity, sortNearbyCityDistance]

        return adjacentAirlineDirect.sort((prev, next) => {
            return sortMultiCond(prev, next, sortFuncs)
        })

    })

// 合并两种推荐的数据
export const combineAdjacentAirlineAndDirectSelector = createSelector([adjacentAirlineInitSelector, adjacentAirlineDirectInitSelector, prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector],
    (adjacentAirline, adjacentAirlineDirect, prevCond, currentSegmentSeq, isBuildUp) => {
        let result = List([]),
            sortFuncs = [],
            flightSegments = isBuildUp ? prevCond.getIn(['flightSegments', 0]) : prevCond.getIn(['flightSegments', currentSegmentSeq]),
            departureCityCode = flightSegments.get('departureCityCode'),
            arrivalCityCode = flightSegments.get('arrivalCityCode'),
            flightWay = prevCond.get('flightWay')
        let combine = (prev, next) => { // 推荐两条相同的航线的时候，合并航线
            if (prev.get('departureCityCode') == next.get('departureCityCode') &&
                prev.get('arrivalCityCode') == next.get('arrivalCityCode')) {
                return prev.merge(next)
            }
            return null
        }

        // 按出发邻近>到达邻近
        let sortNearCity = (prev, next) => {
            if (prev.get('arrivalCityCode') == arrivalCityCode && next.get('arrivalCityCode') == arrivalCityCode) return 0
            else if (prev.get('departureCityCode') == departureCityCode && next.get('departureCityCode') == departureCityCode) return 0
            else if (prev.get('arrivalCityCode') == arrivalCityCode) return -1
            else if (next.get('arrivalCityCode') == arrivalCityCode) return 1
        }

        for (let index = 0; index < adjacentAirline.size; index++) {
            let airline = adjacentAirline.get(index)
            for (let index2 = 0; index2 < adjacentAirlineDirect.size; index2++) {
                let airlineDirect = adjacentAirlineDirect.get(index2),
                    theSame = combine(airline, airlineDirect)
                if (theSame) {
                    result = result.push(theSame)
                    adjacentAirline = adjacentAirline.splice(index, 1)
                    adjacentAirlineDirect = adjacentAirlineDirect.splice(index2, 1)
                    index--
                    index2--
                }
            }
        }

        result = result.concat(adjacentAirlineDirect).concat(adjacentAirline)

        if (flightWay == 'S') sortFuncs = [sortLowPrice, sortNearCity, sortNearbyCityDistance]
        else if (flightWay == 'D') sortFuncs = [sortLowPrice, sortDirectType, sortNearCity, sortNearbyCityDistance]

        return result.sort((prev, next) => {
            return sortMultiCond(prev, next, sortFuncs)
        })

    })

// 判断是否有两种推荐
export const adjacentAirlineFormatSelector = createSelector([adjacentAirlineInitSelector, adjacentAirlineDirectInitSelector, adjacentAirlineSelector, adjacentAirlineDirectSelector, combineAdjacentAirlineAndDirectSelector,
    position, searchNoResultRecommendSelector, prevCondSelector, currentSegmentSeqSelector, isBuildUpSelector],
    (adjacentAirlineInit, adjacentAirlineDirectInit, adjacentAirline, adjacentAirlineDirect, combineAdjacentAirlineAndDirect, position, searchNoResult, prevCond, currentSegmentSeq, isBuildUp) => {
        let result = List([]),
            flightWay = prevCond.get('flightWay')
        if (adjacentAirlineInit.size > 0 && adjacentAirlineDirectInit.size > 0) {
            result = combineAdjacentAirlineAndDirect
        }
        else if (adjacentAirlineInit.size > 0) {
            result = adjacentAirline
        }
        else if (adjacentAirlineDirectInit.size > 0) {
            result = adjacentAirlineDirect
        }

        if (flightWay == 'S' || (flightWay == 'D' && currentSegmentSeq == 0) || isBuildUp) {
            // 单程，往返第一程，往返组合展示推荐
            if (searchNoResult) return result

            // 头部推荐返回第一条推荐
            if (position === RECOMMEND_LOWPRICE_POSITION.TOP && result.size > 0) {
                return result.get(0)
            }
            // 腰部推荐返回除第一条外的推荐
            if (position === RECOMMEND_LOWPRICE_POSITION.MIDDLE && result.size > 1) {
                return result.shift()
            }

        }
        return List([])
    })