import PRODUCT_MESSAGE from './productMessage'

// 增加 适合儿童  / 五星航司 的HOVER片段
export const getForBestFlightsHoverFragment = (wrapper) => {
    if (wrapper.data.forChildOrInfant) {
        const { childCount, infantCount } = wrapper.extData
        //精选航班
        if (childCount || infantCount) {
            const forChildOrInfantPostfix = (childCount && infantCount) ? PRODUCT_MESSAGE.BEST_CHOICE_HOVER.ForChildAndInfantPostfix
                : (childCount ? PRODUCT_MESSAGE.BEST_CHOICE_HOVER.ForChildPostfix : PRODUCT_MESSAGE.BEST_CHOICE_HOVER.ForInfantPostfix)

            return forChildOrInfantPostfix
        } else {
            return ''
        }
    } else if (wrapper.data.isFiveStar) {
        return PRODUCT_MESSAGE.BEST_CHOICE_HOVER.FiveStarPostfix
    } else {
        return ''
    }
}