import React from 'react';
import moment from 'moment';
// import qs from 'qs'
// import { DomesticCityCheck } from '../../../sources/channel/DomesticCityProvider'
import FlightWaySwitch from './FlightWaySwitch';
import OwAndRtContainer from './OwAndRtContainer';
import PassengerCountContainer from './PassengerCountContainer';
import ClassGradeContainer from './ClassGradeContainer';
import MultiContainer from './MultiContainer';
import SearchHotel from './SearchHotel';
// import { WARNS } from "../../../constants/common/enum/searchForm"
// import { getSingletripAndRoundtripURL, getMultitripURL } from '../../../utils/commonUtils'
// import { VIRTUAL_DIR_ROOT } from '../../../constants/common'
import { UbtBoundary } from '../../../ubt.v2/boundary';
import { SearchBoxUbtGenerator } from '../../../ubt.v2/searchBox/handler';
import { SEARCH_BOX_UBT_GROUP_TYPES } from '../../../ubt.v2/searchBox/groupTypes';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../../ubt.v2/searchBox/keyTypes';
import MultiDomesticFlightAlertPopup from './MultiDomesticFlightAlertPopup';

// 汉化datepicker
moment.locale();

const SearchBox = (props) => {
	const {
		selectFlightWay,
		flightWay,
		passenger,
		onSubmitPassengerTypeCount,
		classGrade,
		directflight,
		setDirectFlight,
		onClassGradeChange,
		onSearchFormSubmit,
		showSubmitBtn,
		isDistrictSearch,
		showDirectFlight,
		clearAutoReSearch,
		skipAutoReSearch,
		domestic,
		searchHotel,
		setSearchHotel,
		onMouseOverSearchButton,
		showMultiFlightAlertPopup,
		setShowMultiFlightAlertPopup,
	} = props;

	const onFromSubmit = (event) => {
		onSearchFormSubmit(event, props);
		return false;
	};
	const clearAutoSearch = () => {
		if (skipAutoReSearch) clearAutoReSearch();
	};

	return (
		<form
			id="searchForm"
			method="post"
			acceptCharset="gb2312"
			onSubmit={onFromSubmit}
			onMouseDown={clearAutoSearch}
		>
			<div className={'modify-search-v3 search-multi-trip-v3'}>
				<div className="modify-search-box">
					<div className="search-form">
						<div className="clearfix search-form-top">
							<FlightWaySwitch domestic={domestic} selected={flightWay} handleClick={selectFlightWay} />
							{!isDistrictSearch || (flightWay == 'MT' && !domestic) || domestic ? (
								<ClassGradeContainer
									domestic={domestic}
									classGrade={classGrade}
									onClassGradeChange={onClassGradeChange}
								/>
							) : null}
							{flightWay == 'MT' ? (
								<PassengerCountContainer
									domestic={domestic}
									passenger={passenger}
									flightWay={flightWay}
									cabinType={classGrade.toUpperCase()}
									onSubmitPassengerTypeCount={onSubmitPassengerTypeCount}
									type="text"
								/>
							) : null}
							{flightWay != 'MT' && showDirectFlight === true && !domestic ? (
								<div
									onClick={() => {
										setDirectFlight(!directflight);
									}}
									className={`flt-direct ${directflight ? 'active' : ''}`}
								>
									<i className="ico-checkbox-square"></i>仅看直飞
								</div>
							) : null}
							{domestic ? (
								<SearchHotel searchHotel={searchHotel} setSearchHotel={setSearchHotel} />
							) : null}
						</div>
						{flightWay !== 'MT' ? <OwAndRtContainer {...props} /> : <MultiContainer {...props} />}
					</div>
				</div>
				<UbtBoundary
					tracker={SearchBoxUbtGenerator(
						SEARCH_BOX_UBT_GROUP_TYPES.SEND_NOW_CLICK,
						SEARCH_BOX_UBT_KEY_TYPES.SEND_NOW_CLICK,
						{ type: 'flightSearch', text: '搜索' }
					)}
				>
					{showSubmitBtn === true ? (
						<button className="search-btn" type="submit" onMouseOver={onMouseOverSearchButton}>
							<i className="iconf-search-btn">&#xe603;</i>
							搜索
						</button>
					) : null}
				</UbtBoundary>
			</div>
			{showMultiFlightAlertPopup && (
				<MultiDomesticFlightAlertPopup setShowMultiFlightAlertPopup={setShowMultiFlightAlertPopup} />
			)}
		</form>
	);
};

export default SearchBox;
