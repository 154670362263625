import { FILTER_TRANS_TIMESPAN_TYPE } from '@/src/constants/list/enums/filter';

const getDurtionEnumOfFlight = (flight, currentSegmentSeq) => {
	let filterTypesOfTransTimeSpan = Object.keys(FILTER_TRANS_TIMESPAN_TYPE.toJSON()).map((filterType) =>
		FILTER_TRANS_TIMESPAN_TYPE.get(filterType)
	);
	let currentSegment = flight.getIn(['flightSegments', currentSegmentSeq]),
		duration = 0,
		durationEnum = null;

	currentSegment
		.get('flightList')
		.valueSeq()
		.forEach((innerFlight) => {
			duration += innerFlight.get('transferDuration');
			innerFlight
				.get('stopList')
				.valueSeq()
				.forEach((stop) => {
					duration += stop.get('duration');
				});
		});

	if (duration) {
		filterTypesOfTransTimeSpan.forEach((theType) => {
			if (duration >= theType.value.min && duration < theType.value.max) {
				durationEnum = theType;
				return false;
			}
		});
	}

	return { duration, durationEnum };
};

export default getDurtionEnumOfFlight;
