import * as TYPES from '../../constants/list/actionTypes'

export const genClickItem = (filterType, groupType, itemKey, options) => ({ type: TYPES.FILTER_CLICK_ITEM, filterType, groupType, itemKey, options })
export const genClickLinkedItem = (filterType, activeKeys, options) => ({ type: TYPES.FILTER_CLICK_LINKED_ITEM, filterType, activeKeys, options })
export const genClickRemove = (filterType, groupType, itemKey, filterTypeOfRemoveMeanWhile) => ({ type: TYPES.FILTER_CLICK_REMOVE, filterType, groupType, itemKey, filterTypeOfRemoveMeanWhile })
export const genClickClearOfType = (filterType) => ({ type: TYPES.FILTER_CLICK_CLEAR_OF_TYPE, filterType })
export const genClickClearAll = (resetContainsTax, splitRound) => ({ type: TYPES.FILTER_CLEAR_ALL, resetContainsTax, splitRound })
export const genToggleAirlineMatrix = () => ({ type: TYPES.FILTER_TOGGLE_AIRLINE_MATRIX })
export const genResetActiveKeys = (filterType2ActiveKeys, splitRound) => ({ type: TYPES.FILTER_RESET_ACTIVE_KEYS, filterType2ActiveKeys, splitRound })
export const genShowFilterNoResultWrapper = (reason) => ({ type: TYPES.SHOW_FILTER_NO_RESULT, reason })
