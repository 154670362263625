import { lightSearchFlights } from '../../actions/list/lightSearchFlights';
import { addCacheToQuickSearchFlights } from '../../actions/list/quickSearchFlightsCache';

// 该文件早于list.js的执行和初始化，就开始调用获取航班数据的接口
// 在serach.js中真正获取接口时就可以直接拿此处的 promise，但对查询条件和有效期都有限定
// 此处对 prevCond 进行初始化，也就是html输出所没有定义好的
(() => {
	const prevCond = window.GlobalSearchCriteria;
	const fetchFlightPromise = lightSearchFlights(prevCond);
	addCacheToQuickSearchFlights(prevCond, fetchFlightPromise);
})();
