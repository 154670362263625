import Enum from 'enum'
import { UBT_EVENT_TYPE } from '../common'
import { UbtHandler } from '../handler'
import { SEARCH_BOX_UBT_GROUP_TYPES } from './groupTypes'

/**
 * 确保某数据一定包含指定属性路径，比如确保 data 对象一定包含 data.p1.p2 ，如果属性不存在就初始化为 传入的默认对象
 * @param {待设置的数据} data
 * @param {待设置的数据，主要是考虑到空对象、空数组、数字0等情况} defaultValue
 * @param {根据顺序排序的属性名称，比如 'p1', p2' 就是确保 data 对象包含 data.p1.p2 属性} propsArray
 */

/**
 * UBT信息的默认处理，适合单一KEY的简单加1机制
 * @param {KEY的路径} path
 * @param {处理器} handler
 */

let searchBoxInitData =  SEARCH_BOX_UBT_GROUP_TYPES.SEARCH_DATA_INIT.value.rawData
export const SEARCH_BOX_UBT_KEY_TYPES = new Enum({

    // 点击即上报
    SEND_NOW_CLICK: {
        listen: UBT_EVENT_TYPE.MOUSE_DOWN,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_search_box_click`, handler.tag)
            return false
        }
    },

    SEND_NOW_SELECT: {
        listen: UBT_EVENT_TYPE.MOUSE_DOWN,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_search_box_select`, handler.tag)
            return false
        }
    },
    SEARCH_BOX_INIT: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            Object.assign(searchBoxInitData, handler)
        }
    },
    SEARCH_BOX_INIT_UPLOAD_NOW: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`search_box_init`, handler)
            return false
        }
    },
    SEARCH_BOX_SUBMIT: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`search_box_submit`, handler)
            return false
        }
    }
})
