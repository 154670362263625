import { getIfScopeInternational } from "./scope"

/**
 * 使用范围：对象的二选一
 */
export const scopeMapping = (mappingColNames, target) => {
    if (!(Array.isArray(mappingColNames) && mappingColNames.length && mappingColNames.every(colName => colName && typeof colName.i === 'string' && typeof colName.d === 'string'))) {
        console.error('mappingColNames: ', mappingColNames)
        throw `mappingColNames 应该是非空数组，且元素结构是 {i: 'internationalColName', d: 'domesticColName'}`
    }

    if (!(target && typeof target === 'object')) {
        console.error('target: ', target)
        throw `target 应该是Object对象`
    }

    const result = target
    const isInternational = getIfScopeInternational()
    mappingColNames.forEach(colName => {
        const iName = colName.i
        const dName = colName.d

        if (!isInternational) {
            result[iName] = result[dName]
        }

        delete result[dName]
    })

    return result
}