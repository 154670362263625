import { ubtDevTrace } from '@/src/sources/common/lightUbt';
import { UbtHandler } from '../handler';
import { UBT_EVENT_TYPE } from '../common';
import { LIST_UBT_GROUP_TYPES } from './groupTypes';
import { watchPlainUbt } from '../addClickRec';

// import { LIST_UBT_KEY_TYPES } from './keyTypes'
const customEvent = {}; // 自定义用户事件

const regCustomEvent = (eventName, callback) => {
	if (eventName && callback && typeof callback === 'function') {
		customEvent[eventName] = callback;
	}
};

export const invokeCustomEvent = (eventName, args) => {
	let callback = customEvent[eventName];
	if (callback && typeof callback === 'function') {
		callback(args);
	}
};

const groupList = [
	LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW,
	LIST_UBT_GROUP_TYPES.COMMON_LOG,
	LIST_UBT_GROUP_TYPES.TONGCHENG_REMARK,
	LIST_UBT_GROUP_TYPES.GUIDE_LINE,
	LIST_UBT_GROUP_TYPES.FLIGHT_LIST_FLOW,
	LIST_UBT_GROUP_TYPES.BOOK_COUNT_REASON_CLICK,
]; // 已注册的 LIST_UBT_GROUP_TYPES 列表，靠自定义方式收集的group需要自己添加

import(/* webpackChunkName: "list.ubt.keyTypes" */ './keyTypes').then((result) => {
	const { LIST_UBT_KEY_TYPES } = result;

	Object.keys(LIST_UBT_KEY_TYPES.toJSON()).forEach((key) => {
		let typeEnum = LIST_UBT_KEY_TYPES.get(key);
		if (typeEnum) {
			let typeEnumValue = typeEnum.value;

			if (typeEnumValue.listen === UBT_EVENT_TYPE.CUSTOM) {
				let eventName = key;

				regCustomEvent(eventName, (ev) => {
					typeEnumValue.process(eventName, null, ev);
				});
			}
		}
	});
});

const regGroup = (group) => {
	const index = groupList.indexOf(group);
	if (index >= 0) {
		groupList.splice(index, 1);
	}

	groupList.push(group);
};

/**
 * 注册页面离开事件、初始化自定义事件
 */
export const initUbt = () => {
	watchPlainUbt(); // 简单点击埋点，上报到clog

	window.addEventListener('beforeunload', (_ev) => {
		groupList.forEach((group) => {
			group.value.trigger.value.beforeUnload(group);
		});
	});
	window.addEventListener('pageshow', (e) => {
		const navigationType = performance.getEntriesByType('navigation')[0]?.type;
		if (e.persisted || navigationType === 'back_forward') {
			ubtDevTrace('back_forward_navigation', { isBFCache: e.persisted, navigationType });
		}
	});
};

export class CommonUbtHandler extends UbtHandler {
	constructor(groupEnum, keyEnum, tag) {
		super();

		this.groupEnum = groupEnum;
		this.keyEnum = keyEnum;
		this.rawData = this.groupEnum.value.rawData;
		this.tag = tag;

		regGroup(groupEnum);
	}

	onEvent(eventType, source, ev) {
		this.onProcess(eventType, source, ev);
	}

	onProcess(eventType, source, ev) {
		let preventAfterProcess = this.keyEnum.value.process(this, source, ev || { type: eventType });
		//会有些奇葩场景，比如process中动态修改key直接上报，就不用再走常规 afterProcess 了
		if (preventAfterProcess !== false) {
			this.groupEnum.value.trigger.value.afterProcess(this.groupEnum);
		}
	}
}

const HANDLER_CACHE = {};

// let count = 0
export const ListUbtGenerator = (groupEnum, keyEnum, ...tag) => {
	const key = `${groupEnum.key}__${keyEnum.key}`;
	if (!HANDLER_CACHE[key]) {
		HANDLER_CACHE[key] = [];
	}

	const existed = HANDLER_CACHE[key].find(
		(item) => item.tag.length === tag.length && item.tag.every((theTag, theIndex) => theTag === tag[theIndex])
	);
	if (existed) {
		// console.warn(`cache.count:${count++}`)
		return existed;
	}
	const instance = new CommonUbtHandler(groupEnum, keyEnum, tag);
	HANDLER_CACHE[key].push(instance);
	return instance;
};
