import React, { Component } from 'react';

import { getIfScopeDomestic } from '../../sources/list/scope';

class PackageDetail extends Component {
  render() {
    var { servicePackage } = this.props;
    const domesticScope = getIfScopeDomestic();
    return servicePackage ? (
      <div className="svc-detail-box domestic-svc-detail-box ">
        {domesticScope ? (
          <h2>金牌服务包</h2>
        ) : (
          <div className="detail-title">
            <strong>
              <img src="//pic.c-ctrip.com/flight_intl/list/icons/service-package.png" />
            </strong>
          </div>
        )}
        <div className="detail-contend">
          {servicePackage.products.map((item, index) => {
            return (
              <div className="detail-item" key={index}>
                <div className="detail-item-text">
                  <span className="detail-item-icon">
                    <img src={item.iconUrl} alt={item.title}></img>
                  </span>
                  <span className="text">{item.title}</span>
                </div>
                <div className="detail-item-desc">{item.description}</div>
              </div>
            );
          })}

          <div className="detail-item detail-coupon-item">
            <div className="detail-item-text item-desc-title">
              {domesticScope ? (
                `优惠券详细说明`
              ) : (
                <React.Fragment>
                  <div className="detail-center-line"></div> 优惠券说明{' '}
                  <div className="detail-center-line"></div>
                </React.Fragment>
              )}
            </div>
            {servicePackage.usageDesc.map((desc, index) => {
              return (
                <div className="detail-item-desc" key={index}>
                  {desc}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    ) : (
      <div className="svc-detail-box">加载中...</div>
    );
  }
}

export default class ServicePackage extends Component {
  state = {
    useageDesc: '',
  };

  getSvcDetail() {
    //FLT33GNON-3060 获取金牌服务包详情，目前读配置，暂时无需发起网络请求
    /* var dataOrThen = fetchSVCDetail({ id: '' })
    !dataOrThen.then
      ? this.setState({ useageDesc: dataOrThen })
      : dataOrThen.then(data => {
        this.setState({ useageDesc: data })
      }) */

    return true;
  }

  renderTitle(servicePackage, _typeIs) {
    if (!servicePackage) {
      return (
        <div className="svc-box">
          <span className="title-price">
            <strong>&yen;{servicePackage.price + ' 金牌服务'}</strong>
          </span>
        </div>
      );
    }

    const products = servicePackage.products;
    return (
      <div className="svc-box">
        {products.map((item, index) => {
          return (
            <span className="item-container" key={index}>
              <span className="item-icon">
                <img src={item.iconUrl} alt={item.title}></img>
              </span>
              <span className="item-text">{item.title}</span>
            </span>
          );
        })}
      </div>
    );
  }

  render() {
    var { servicePackage } = this.props;

    return <PackageDetail servicePackage={servicePackage}></PackageDetail>;
  }
}
