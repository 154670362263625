import React from 'react';
import { showModal, closeModal } from '@/src/components/Modal';
import { SecurityPopup } from '@/src/components/list/popups/SecurityPopup';

// 同一时间只能1个
let captchaModal: any = null;

export const showCaptchaModal = ({ onReSearch, requestName }) => {
	setTimeout(() => {
		if (captchaModal) {
			closeModal(captchaModal);
			captchaModal = null;
		}
		return (captchaModal = showModal({
			mask: {
				enableClose: false,
			},
			content: (
				<SecurityPopup
					onReSearch={() => {
						closeModal(captchaModal);
						onReSearch();
					}}
					requestName={requestName}
				></SecurityPopup>
			),
			position: 'middle',
			style: {
				width: 'auto',
				padding: 0,
			},
		}));
	});
};

export function clearCaptchaModal() {
	if (captchaModal) {
		captchaModal = null;
	}
}
