// import { ModalPosition } from '../types';
// import constant from '@/src/common/constant/constant.text';

export const genId = (() => {
    let count = 0;
    return () => {
        return (++count).toString();
    };
})();

export const getPositionStyle = (position?: 'bottom'): React.CSSProperties | null => {
    if (position === 'bottom') {
        return {
            position: 'absolute',
            bottom: 0,
            left: 0,
            width: '100vw',
            padding: 0,
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            // paddingBottom: `${constant.SAFE_AREA.bottom ? constant.SAFE_AREA.bottom : 0}px`,
        };
    }
    return null;
};

// export const getPositionStyle = (position: ModalPosition, offset = 0): React.CSSProperties => {
// 	const top = position.includes('bottom');
// 	const verticalStyle: React.CSSProperties = top ? { top: 0 } : { bottom: 0 };
// 	const horizontalStyle: React.CSSProperties = position.includes('center')
// 		? {
// 				justifyContent: 'center',
// 		  }
// 		: position.includes('right')
// 		? {
// 				justifyContent: 'flex-end',
// 		  }
// 		: {};
// 	return {
// 		left: 0,
// 		right: 0,
// 		display: 'flex',
// 		position: 'absolute',
// 		transform: `translateY(${offset * (top ? 1 : -1)}px)`,
// 		...verticalStyle,
// 		...horizontalStyle,
// 	};
// };
