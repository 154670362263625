
import Enum from 'enum'
import Q_CONFIG_KEYS from '../qConfigKeys'
import mergeConfig from "../../common/mergeConfig";

export const DOMESTIC_TRAIN_CABIN = mergeConfig(Q_CONFIG_KEYS.DOMESTIC_TRAIN_CABIN, {
    //虚拟航班
    T: {
        virtualFlight: true,
        title: '火车',
        shortTitle: '火车',
        includeKeys: ['T'],
        filterVisibleKey: 'T',
        isHighCabin: false
    },
    FirstClass: {
        virtualFlight: true,
        title: '一等座',
        shortTitle: '一等座',
        includeKeys: ['FirstClass'],
        filterVisibleKey: 'T',
        isHighCabin: false
    },
    EconomyClass: {
        virtualFlight: true,
        title: '二等座',
        shortTitle: '二等座',
        includeKeys: ['EconomyClass'],
        filterVisibleKey: 'EconomyClass',
        isHighCabin: false
    },
    BusinessClass: {
        virtualFlight: true,
        title: '商务座',
        shortTitle: '商务座',
        includeKeys: ['BusinessClass'],
        filterVisibleKey: 'BusinessClass',
        isHighCabin: false
    },
    HardBasedClass: {
        virtualFlight: true,
        title: '硬座',
        shortTitle: '硬座',
        includeKeys: ['HardBasedClass'],
        filterVisibleKey: 'HardBasedClass',
        isHighCabin: false
    },
    HardBerthClass: {
        virtualFlight: true,
        title: '硬卧',
        shortTitle: '硬卧',
        includeKeys: ['HardBerthClass'],
        filterVisibleKey: 'HardBerthClass',
        isHighCabin: false
    },
    SoftSleeperClass: {
        virtualFlight: true,
        title: '软卧',
        shortTitle: '软卧',
        includeKeys: ['SoftSleeperClass'],
        filterVisibleKey: 'SoftSleeperClass',
        isHighCabin: false
    },
    OtherClass: {
        virtualFlight: true,
        title: '火车票',
        shortTitle: '火车票',
        includeKeys: ['OtherClass'],
        filterVisibleKey: 'OtherClass',
        isHighCabin: false
    },

})
export const CABIN_OF_VIRTUAL_FLIGHT = new Enum(DOMESTIC_TRAIN_CABIN)