import React from 'react'

export const MtFlightWayTips = () =>{
    return(
        <div className="s_mt_tips_details_container">
                <div className="s_mt_tips_details">
                    <div className="mt_tips_des">多程指同时预订两段或两段以上航班，适用于以下场景：</div>
                    <div className="mt_tips_item">
                      <div className="mt_tips_title">
                        <span className="mt_tips_group_title_dot"></span>
                        前往多个目的地
                      </div>
                      <div className="mt_tips_group">
                        <div className="mt_tips_group_item">
                          去程：上海&nbsp;-&nbsp;<span className="mt_tips_group_item_hightline">东京</span>
                        </div>
                        <div className="mt_tips_group_item">
                          返程：<span className="mt_tips_group_item_hightline">大阪</span>&nbsp;-&nbsp;上海
                        </div>
                      </div>
                    </div>
                    <div className="mt_tips_item">
                      <div className="mt_tips_title">
                        <span className="mt_tips_group_title_dot"></span>
                        出发地和返回地不同
                      </div>
                      <div className="mt_tips_group">
                        <div className="mt_tips_group_item">
                          去程：<span className="mt_tips_group_item_hightline">上海</span>&nbsp;-&nbsp;东京
                        </div>
                        <div className="mt_tips_group_item">
                          返程：东京&nbsp;-&nbsp;<span className="mt_tips_group_item_hightline">杭州</span>
                        </div>
                      </div>
                    </div>
                    <div className="mt_tips_item">
                      <div className="mt_tips_title">
                        <span className="mt_tips_group_title_dot"></span>
                        多段行程自由搜
                      </div>
                      <div className="mt_tips_group">
                        <div className="mt_tips_group_item">
                          第&nbsp;1&nbsp;程：上海&nbsp;-&nbsp;<span>东京</span>
                        </div>
                        <div className="mt_tips_group_item">
                          第&nbsp;2&nbsp;程：<span>东京</span>&nbsp;-&nbsp;普吉岛
                        </div>
                        <div className="mt_tips_group_item">
                          第&nbsp;3&nbsp;程：普吉岛&nbsp;-&nbsp;<span>上海</span>
                        </div>
                      </div>
                    </div>
                </div>
            </div>
            
    )
}

export const DomesticMtFlightWayTips = () =>{
  return (<div className="s_mt_tips_details_container">
  <div className="s_mt_tips_details">
      <div className="mt_tips_des">国内机票多程适用于以下场景：</div>
      <div className="mt_tips_item">
      <div className="mt_tips_title">
          <span className="mt_tips_group_title_dot"></span>
          前往多个目的地
      </div>
      <div className="mt_tips_group">
          <div className="mt_tips_group_item">
          去程：上海&nbsp;-&nbsp;<span className="mt_tips_group_item_hightline">北京</span>
          </div>
          <div className="mt_tips_group_item">
          返程：<span className="mt_tips_group_item_hightline">天津</span>&nbsp;-&nbsp;上海
          </div>
      </div>
      </div>
      <div className="mt_tips_item">
      <div className="mt_tips_title">
          <span className="mt_tips_group_title_dot"></span>
          出发地和返回地不同
      </div>
      <div className="mt_tips_group">
          <div className="mt_tips_group_item">
          去程：<span className="mt_tips_group_item_hightline">上海</span>&nbsp;-&nbsp;<span>北京</span>
          </div>
          <div className="mt_tips_group_item">
          返程：<span>北京</span>&nbsp;-&nbsp;<span className="mt_tips_group_item_hightline">杭州</span>
          </div>
      </div>
      </div>
  </div>
</div>)
}