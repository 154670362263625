import moment from 'moment'

/**
 * @d expect moment object
 * return week string as '周一...周日'
 */
export const formatWeek = d => {
    if (!d) return ""
    let weekDay = ~~d.format("e")
    return `周${'日一二三四五六'.charAt(weekDay)}`
}

//这个是原来的日期函数
export const getDateDiff = function(dateTimeStamp) {
    var minute = 1000 * 60
    var hour = minute * 60
    var day = hour * 24
    // var halfamonth = day * 15
    var month = day * 30

    var now = new Date().getTime()
    var diffValue = now - dateTimeStamp
    if (diffValue < 0) {
        //若日期不符则弹出窗口告之
        //alert("结束日期不能小于开始日期！");
    }
    var monthC = diffValue / month
    var weekC = diffValue / (7 * day)
    var dayC = diffValue / day
    var hourC = diffValue / hour
    var minC = diffValue / minute
    let result = ""
    if (monthC >= 1) {
        result = parseInt(monthC) + "个月前"
    } else if (weekC >= 1) {
        result = parseInt(weekC) + "周前"
    } else if (dayC >= 1) {
        result = parseInt(dayC) + "天前"
    } else if (hourC >= 1) {
        result = parseInt(hourC) + "小时前"
    } else if (minC >= 1) {
        result = parseInt(minC) + "分钟前"
    } else result = "刚刚查询"
    return result
}

export const addTimezone = (m, timeZone = 480) => {
    let hours = Math.round((timeZone -  moment().utcOffset()) / 60)
    return moment(m).add(hours, 'h').startOf('day')
}
export const isFromNowOnAndWithinOneYear = (d, timeZone) => {
    let m = moment(d), today = addTimezone(moment(), timeZone).format('YYYY-MM-DD')
    if(m.diff(today) < 0 || m.diff(today, 'years', true) > 1)
        return false
    return true
}

export const isFromNowOn = (d, timeZone) => {
    let m = moment(d), today = addTimezone(moment(), timeZone).format('YYYY-MM-DD')
    if(m.diff(today) < 0)
        return false
    return true
}

