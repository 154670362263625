/**
 * 列表页每个运价对应的X产品前置信息
 * {
 *      [routeSearchToken]:{
 *          policyToken:"",
 *          isShowFlag:false
 *          isLoaded:false
 *          isLoading:false
 *          data:null
 *          promise:null
 *      }
 * }
 */
const PriceXProductPreposeInfoMap = {}

export default { PriceXProductPreposeInfoMap }