const CONFIG_KEYS = {
    PASSENGER_RULE_MESSAGE: 'bookPassengerRuleMessageConfig',
    PASSENGER_CARD_MESSAGE: 'bookPassengerCardMessageConfig',
    PASSENGER_CARD_CONFIG: 'bookPassengerCardConfig',
    CONTACT_RULE_MESSAGE: 'bookContactRuleMessageConfig',
    PASSENGER_COUNT_DIALOG_RULE_MESSAGE: 'bookPassengerCountRuleMessageConfig',
    PASSENGER_LIST_RULE_MESSAGE: 'bookPassengerListRuleMsgConfig',
    UPSELL_CONFIG: 'bookUpsellConfig',
    SUBMIT_ERROR_CONFIG: 'bookSubmitErrorConfig',
    SECURITY_DIALOG_RULE_MESSAGE: 'bookSecurityDialogRuleMessageConfig',
    BOOK_FLIGHT_HOVER_MESSAGE: 'bookFlightHoverMessage',
    BOOK_ABROAD_CONFIG:'bookAbroadConfig',
    BOOK_INCREMENT_CONFIG:'incrementConfig',
    INVOICE_TYPE_CONFIG: 'invoiceTypeConfig',
    CERTIFICATE_TYPE_CONFIG: 'certificateTypeConfig'
}

export default CONFIG_KEYS