import { calcPrice } from './calcPrice';
import { initTheBaggage } from './initTheBaggage';

const defaultPriceProps = {
    restrictionList: [],
    priceTags: [],
    adultPrice: 0,
    adultTax: 0,
    childPrice: 0,
    childTax: 0,
    infantPrice: 0,
    infantTax: 0,
    productType: { category: 'None' },
    transferCheckInCities: [],
    ticketCount: 0,
    penalty: {},
    childDisabledBook: false,  //儿童不可订
    infantDisabledBook: false   //婴儿不可订
}

const defaultPricePropKeys = Object.keys(defaultPriceProps)

/**
* 对单条运价进行处理，注意：此处尚未设置运价的枚举信息，需要单独调用 setPriceEnums
* @param {*某一条运价} price
*/
export const initThePrice = (price, pageStep) => {
    defaultPricePropKeys.forEach(key => {
        if (typeof price[key] === 'undefined') {
            price[key] = defaultPriceProps[key]
        }
    })

    // 搜索国内航班，产品类型是 dCategory
    if (price.productType && price.productType.dCategory) {
        price.productType.category = price.productType.dCategory
    }
    else if (price.policyFlag && (price.policyFlag == 1 || price.policyFlag == 2)) {
        // 搜索国内航班,产品为套餐拼团/特惠拼团,有policyFlag,优先级低于dCategory
        price.productType = {
          category: 'ScatteredGroup',
          policyFlag: price.policyFlag
        }
    }


    //枚举转换
    //将通港车船票拆分为两个标签
    price.priceTags.forEach((item, _index) => {
        // dtype 是搜索国内航班时，运价标签的type字段名
        if (item.dtype) {
            item.type = item.dtype
            delete item.dtype
        }

        if (item.type === 'FreeBusBoatTicketToHK' && item.data.length === 2 && item.data[0].type !== item.data[1].type) {
            price.priceTags.push(Object.assign({}, item, { data: [item.data[0]] }))
            price.priceTags.push(Object.assign({}, item, { data: [item.data[1]] }))
        }
    })

    price.priceTags = price.priceTags.filter(item => !(item.type === 'FreeBusBoatTicketToHK' && item.data.length === 2 && item.data[0].type !== item.data[1].type))
    price.baggage = initTheBaggage(price.baggage, pageStep)

    const priceProps = calcPrice(price)

    Object.keys(priceProps).forEach(key => {
        price[key] = priceProps[key]
    })

    return price
}
