import { createSelector } from 'reselect';
import Immutable, { List } from 'immutable';
import moment from 'moment';
import { RECOMMEND_LOWPRICE_POSITION } from '../../../../constants/list/enums/recommend.js';
import { searchNoResultRecommendSelector } from './searchNoResultRecommendSelector';
import {
	isSearchSubAirportSelector,
	prevCondSelector,
	containsTaxSelector,
	getMinPriceSelector
} from '../flight/baseSelector';
import { getMinPrice, adjacentDateSwitchSelector } from './baseRecommendSelector';

const position = (state, props) => (props ? props.position : RECOMMEND_LOWPRICE_POSITION.TOP);

const dateMinPrice = createSelector(
	getMinPrice,
	prevCondSelector,
	isSearchSubAirportSelector,
	(minPrice, prevCond, isSearchSubAirport) => {
		if (isSearchSubAirport) return Immutable.fromJS([]);
		if (prevCond.get('flightWay') === 'D') {
			let startDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
				dateMinPrice = [];

			minPrice.keySeq().forEach((date) => {
				let minPriceOfDate = minPrice.get(date);
				if (typeof minPriceOfDate.forEach === 'function') {
					minPriceOfDate.forEach((item, index) => {
						if (item && item.get && Math.abs(moment(date).diff(moment(startDate), 'days')) <= 7) {
							dateMinPrice.push({
								departureDate: moment(date).format('YYYY-MM-DD HH:MM:SS'),
								departureCityName: prevCond.getIn(['flightSegments', 0, 'departureCityName']),
								returnDate: moment(date)
									.add(index + 1, 'days')
									.format('YYYY-MM-DD HH:MM:SS'),
								arrivalCityName: prevCond.getIn(['flightSegments', 0, 'arrivalCityName']),
								price: item.get('price'),
								tax: item.get('totalPrice') - item.get('price')
							});
						}
					});
				}
			});
			return Immutable.fromJS(dateMinPrice);
		}
		return Immutable.fromJS([]);
	}
);

export const adjacentDateSelector = createSelector(
	dateMinPrice,
	position,
	getMinPriceSelector,
	containsTaxSelector,
	prevCondSelector,
	searchNoResultRecommendSelector,
	adjacentDateSwitchSelector,
	(dateMinPrice, position, minPrice, includeTax, prevCond, searchNoResultRecommendSelector, adjacentDateSwitch) => {
		// 加上开关
		if (!adjacentDateSwitch) return List([]);
		// 临近日期筛选规则
		const dateLowPriceFilter = (item) => {
			if (includeTax) {
				if (item.get('tax') + item.get('price') + 1 > minPrice.get('totalPrice')) return false;
			} else {
				if (item.get('price') + 1 > minPrice.get('price')) return false;
			}
			let startDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
				endDate = prevCond.getIn(['flightSegments', 1, 'departureDate']),
				searchDifferDate = moment(endDate).diff(startDate, 'days'),
				recommendDifferDate = moment(item.get('returnDate')).diff(item.get('departureDate'), 'days');
			if (searchDifferDate === 1 && recommendDifferDate !== 1 && recommendDifferDate !== 2) return false;
			if (searchDifferDate === 2 && recommendDifferDate !== 2 && recommendDifferDate !== 3) return false;
			if (Math.abs(recommendDifferDate - searchDifferDate) > 1) return false;
			return true;
		};
		// 临近日期排序规则
		const dateLowPriceSort = (prev, next) => {
			let prevDepartDate = prev.get('departureDate'),
				prevReturnDate = prev.get('returnDate'),
				nextDepartDate = next.get('departureDate'),
				nextReturnDate = next.get('returnDate'),
				departDate = prevCond.getIn(['flightSegments', 0, 'departureDate']),
				returnDate = prevCond.getIn(['flightSegments', 1, 'departureDate']);

			if (includeTax) {
				if (prev.get('tax') + prev.get('price') < next.get('tax') + next.get('price')) return -1;
				if (prev.get('tax') + prev.get('price') > next.get('tax') + next.get('price')) return 1;
			} else {
				if (prev.get('price') < next.get('price')) return -1;
				if (prev.get('price') > next.get('price')) return 1;
			}
			if (prev.get('departureDate') !== next.get('departureDate')) {
				let prevFromCurrent = moment(prevDepartDate).diff(moment(departDate), 'days'),
					nextFromCurrent = moment(nextDepartDate).diff(moment(departDate), 'days'),
					dayIndex = 0,
					count = 1;
				while (count) {
					if (prevFromCurrent === dayIndex) {
						return -1;
					} else if (nextFromCurrent === dayIndex) {
						return 1;
					}
					count % 2 === 0 ? (dayIndex = -dayIndex + 1) : (dayIndex = -dayIndex);
					count++;
				}
			}
			if (prev.get('returnDate') !== next.get('returnDate')) {
				let prevFromCurrent = moment(prevReturnDate).diff(moment(returnDate), 'days'),
					nextFromCurrent = moment(nextReturnDate).diff(moment(returnDate), 'days'),
					dayIndex = 0,
					count = 1;
				while (count) {
					if (prevFromCurrent === dayIndex) {
						return -1;
					} else if (nextFromCurrent === dayIndex) {
						return 1;
					}
					count % 2 === 0 ? (dayIndex = -dayIndex + 1) : (dayIndex = -dayIndex);
					count++;
				}
			}
		};

		if (prevCond.get('flightWay') === 'D') {
			let dateLowPrice = Immutable.fromJS([]);
			// 无结果时推荐
			if (searchNoResultRecommendSelector) {
				dateLowPrice = dateMinPrice;
				dateLowPrice = dateLowPrice
					.sort(dateLowPriceSort)
					.filter((item) => item.get('tax') + item.get('price') != 0);
				return dateLowPrice.size > 0 ? dateLowPrice : List([]);
			}
			// 临近日期的筛选和排序
			if (dateMinPrice.size > 0) {
				dateLowPrice = dateMinPrice;
				dateLowPrice = dateLowPrice.filter(dateLowPriceFilter);
				dateLowPrice = dateLowPrice.sort(dateLowPriceSort);
			}

			// 头部推荐
			if (position === RECOMMEND_LOWPRICE_POSITION.TOP) {
				return dateLowPrice.size > 0 ? dateLowPrice.get(0) : List([]);
			}
			// 腰部推荐
			if (position === RECOMMEND_LOWPRICE_POSITION.MIDDLE) {
				return dateLowPrice.size > 1 ? dateLowPrice.shift() : List([]);
			}
		}
		return List([]);
	}
);
