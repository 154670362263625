import React from 'react';
import Tooltip from '../../../components/list/common/tooltip';
import genCabinHoverTooltipOfSeatsFragment from './genCabinHoverTooltipOfSeatsFragment';

const genCabinHoverTooltipFragment = (list, price, getCabinTitleFromItem) => {
	return (
		<Tooltip color="orange" extClass="tooltip-multitrip">
			{list.map((item, innerIndex) => (
				<div key={innerIndex} className="trip-info">
					<div className="trip-detail">
						<span className="trip-cities">
							<span className="city">{item.departureCityName}</span>-
							<span className="city">{item.arrivalCityName}</span>
						</span>
					</div>
					{genCabinHoverTooltipOfSeatsFragment(price, item, innerIndex, 'cabin', getCabinTitleFromItem)}
				</div>
			))}
		</Tooltip>
	);
};

export default genCabinHoverTooltipFragment;
