import moment from 'moment';
import { fetchGet } from '../common';
import * as types from '../../constants/common/actionTypes/hotDistrict';
import CONFIG_URL from '../../constants/common/api';

// let hasFetchHotDistrictsList = false;

// 获取精选地区
// export const fetchHotDistricts = (dispatch) => {
// 	return fetchPost(CONFIG_URL.districtListUrl, { DataSearchType: 2 })
// 		.then((res) => (res ? res.data : null))
// 		.then((res) => {
// 			if (!res) return;
// 			hasFetchHotDistrictsList = true;
// 			dispatch(genSetHotDistrict(getHotCountryListFromSOA(res)));
// 		});
// };

// let getHotCountryListFromSOA = (res) => {
// 	if (
// 		!res ||
// 		!res.recommendInfoList ||
// 		!res.recommendInfoList.length ||
// 		!res.areaInfoList ||
// 		!res.areaInfoList.length
// 	) {
// 		return null;
// 	}
// 	const cityCountryListDic = {};
// 	const areaInfoListDic = {};
// 	res.areaInfoList.forEach((area) => {
// 		areaInfoListDic[area.areaCode] = area;
// 	});
// 	res.recommendInfoList.forEach((city) => {
// 		if (!city.hotContinentInfo || !city.hotContinentInfo.length) {
// 			return;
// 		}
// 		const hotList = [];

// 		city.hotContinentInfo.forEach((hot) => {
// 			if (!hot.hotAreaInfo || !hot.hotAreaInfo.areaCode || !hot.hotAreaInfo.areaCode.length) {
// 				return;
// 			}
// 			const countryList = [];
// 			hot.hotAreaInfo.areaCode.forEach((countryCode) => {
// 				if (areaInfoListDic[countryCode]) {
// 					countryList.push(areaInfoListDic[countryCode]);
// 				}
// 			});
// 			if (!countryList.length) {
// 				return;
// 			}
// 			hotList.push({
// 				typeName: hot.continentName,
// 				type: hot.type,
// 				countryList: countryList,
// 			});
// 		});
// 		cityCountryListDic[city.departCityCode] = hotList;
// 	});
// 	return cityCountryListDic;
// };

export const fetchCountryLowPrice = (dCityCode, aCountryCode, flightWay) => {
	return (dispatch) => {
		if (!(flightWay == 'OW' || flightWay == 'RT')) return;
		fetchGet(CONFIG_URL.countryLowPriceUrl, {
			startDate: moment().format('YYYY-MM-DD'),
			endDate: moment().add(90, 'days').format('YYYY-MM-DD'),
			tripType: flightWay === 'OW' ? 1 : 2,
			departCode: dCityCode,
			arriveCode: aCountryCode,
		})
			.then((res) => {
				if (res.data && res.data.calendar && res.data.calendar.length) {
					let result = res.data.calendar;
					let lowPriceData = {};
					if (flightWay === 'OW') {
						for (let i = 0; i < result.length; i++) {
							let item = result[i];
							lowPriceData[item.segments[0].date] = item.totalPrice;
						}
						dispatch(fetchCountryLowPriceSucessAction(dCityCode, aCountryCode, lowPriceData));
					} else if (flightWay === 'RT') {
						for (let i = 0; i < result.length; i++) {
							let item = result[i];
							// {2018-10-11(开始时间):{2019-10-22(返回日期): 1908(往返总价)}}
							if (!lowPriceData[item.segments[0].date]) {
								lowPriceData[item.segments[0].date] = {};
							}
							if (!lowPriceData[item.segments[0].date][item.segments[1].date]) {
								lowPriceData[item.segments[0].date][item.segments[1].date] = {};
							}
							lowPriceData[item.segments[0].date][item.segments[1].date] = item.totalPrice;
						}
						dispatch(fetchCountryLowRPriceSucessAction(dCityCode, aCountryCode, lowPriceData));
					}
				} else {
					// console.error("");
				}
			})
			.catch((e) => {
				console.error(e);
			});
	};
};

export const fetchCountryLowPriceSucessAction = (dCityCode, aCityCode, list) => {
	return {
		type: types.FETCH_COUNTRY_LOW_PRICE_SUCCESS_ACTION,
		dCityCode,
		aCityCode,
		list,
	};
};
export const fetchCountryLowRPriceSucessAction = (dCityCode, aCityCode, list) => {
	return {
		type: types.FETCH_COUNTRY_LOW_R_PRICE_SUCCESS_ACTION,
		dCityCode,
		aCityCode,
		list,
	};
};
// 更精选地区，到达地区
export const genChangeArrivalDistrict = (arrivalDistrict) => ({ type: types.CHANGE_ARRIVAL_DISTRICT, arrivalDistrict });
export const genSetHotDistrict = (districtList) => ({ type: types.SET_HOT_DISTRICT_LIST, districtList });
// 切换到精选地区状态
export const genSwitchToDistrictSearch = (isDistrictSearch) => ({
	type: types.SWITCH_TO_DISTRICT_SEARCH,
	isDistrictSearch,
});
export const genSetPriceMapSwitch = (priceMapSwitch) => ({ type: types.SET_PRICE_MAP_SWITCH, priceMapSwitch });

// export const fetPriceMapSwitch = (switchName) => (dispatch) => {
// 	return fetchGet(CONFIG_URL.getSwitchOpened, { switchName: switchName })
// 		.then((res) => (res && res.data) || {})
// 		.then((json) => {
// 			dispatch(genSetPriceMapSwitch(json.data));
// 			if (json.data) {
// 				if (!hasFetchHotDistrictsList) {
// 					fetchHotDistricts(dispatch);
// 				}
// 			}
// 		});
// };
