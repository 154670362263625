import React from 'react'

export const FakeSearchBox = () => {
    return <React.Fragment>
        <div className='fake-search-box'>
            <div className='r0c0' />
            <div className='r0c1' />
            <div className='r0c2' />
            <div className='r0c3' />

            <div className='r1c0' />
            <div className='r1c1' />
            <div className='r1c2' />
            <div className='r1c3' />
            <div className='r1c4' />
        </div>
    </React.Fragment>
}