/**
 * 触发window的scroll事件
 */
export const triggerElementScroll = (el) => {
    if (el && el.dispatchEvent) {
        const ev = document.createEvent("UIEvents")
        ev.initUIEvent("scroll", true, true, window, 1)

        el.dispatchEvent(ev)
    }
}

/**
 * window滚屏到顶部
 */
export const windowScrollToTop = (y) => {
    window.scrollTo(0, y)
    // triggerElementScroll(window)
}

/**
 * 触发离开页面事件
*/
export const triggerBeforeUnload = () => {
    let event

    if (typeof (Event) === 'function') {
        event = new Event('beforeunload')
    } else {
        event = document.createEvent('Event')
        event.initEvent('beforeunload', true, true)
    }

    dispatchEvent(event)
}