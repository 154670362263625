import mergeConfig from "../../common/mergeConfig";
import Q_CONFIG_KEYS from '../qConfigKeys'

const _BEST_CHOICE_FLIGHTS_SORT_CONFIG = {
    direct_flight: [
        [
            "CheapestChoice",
            "DirectCheapestChoice",
            "HotSaleFlight",
            "CheaperTimeSavingChoice",
            "HigherCostPerformance",
            "FiveStarAirlineLowPrice",
            "ArriveAtAfternoonLowPrice",
            "ShortestTimeChoice",
            "DepartAtForenoonLowPrice",
            "DepartAtEveningLowPrice",
            "DomesticeTransferLowPrice",
            "HkTransferVisaFreeChoice",
            "MacaoTransferVisaFreeChoice"
        ]
    ],
    best_choice: [
        []
    ],
    depart_time: [
        [],
        []
    ],
    arrive_time: [
        [],
        []
    ],
    price: [
        [],
        []
    ],
    flight_duration: [
        [],
        []
    ]
}

export const BEST_CHOICE_FLIGHTS_SORT_CONFIG = mergeConfig(Q_CONFIG_KEYS.BEST_CHOICE_FLIGHTS_SORT, _BEST_CHOICE_FLIGHTS_SORT_CONFIG)