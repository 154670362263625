import { CABIN } from '../../../constants/common/enum/common';

/**
 * 获取舱等名字，针对 南航的超经舱，要显示成  明珠经济舱
 * @param {舱等} cabin
 * @param {共享航班执行航司或销售航司} airlinCode
 */
const getCabinTitle = (cabin, airlinCode) => {
	if (airlinCode === 'CZ' && cabin === CABIN.S) {
		return { title: '明珠经济舱', shortTitle: '明珠经济', virtualFlight: false };
	} else {
		return {
			title: cabin.value?.title,
			shortTitle: cabin.value?.shortTitle,
			virtualFlight: !!cabin.value?.virtualFlight,
		};
	}
};

export default getCabinTitle;
