export const ADJACENT_AIRLINE_RECOMMEND = 'ADJACENT_AIRLINE_RECOMMEND'
export const RECOMMEND_GET_TICKET_REQUEST = 'RECOMMEND_GET_TICKET_REQUEST'
export const NAME_ERROR = 'NAME_ERROR'
export const TELEPHONE_ERROR = 'TELEPHONE_ERROR'
export const EMAIL_ERROR = 'EMAIL_ERROR'
export const COUNTRY_CODE = 'COUNTRY_CODE'
export const SHOW_AREA_CODE = 'SHOW_AREA_CODE'
export const INPUT_CONTACT = 'INPUT_CONTACT'
export const CLICK_CABIN = 'CLICK_CABIN'
export const RECOMMEND_DIRECT_TAB_DATA = 'RECOMMEND_DIRECT_TAB_DATA'
export const GET_ALL_RECOMMEND_FLIGHTS = 'GET_ALL_RECOMMEND_FLIGHTS'
//直飞日历
export const RECOMMEND_DIRECT_CALENDAR_DATA = 'RECOMMEND_DIRECT_CALENDAR_DATA'
export const OPEN_DIRECT_CALENDAR = 'OPEN_DIRECT_CALENDAR'
export const CLOSE_DIRECT_CALENDAR = 'CLOSE_DIRECT_CALENDAR'
export const CHANGE_DIRECT_CALENDAR_DEPDATE = 'CHANGE_DIRECT_CALENDAR_DEPDATE'
export const CHANGE_DIRECT_CALENDAR_RETDATE = 'CHANGE_DIRECT_CALENDAR_RETDATE'
export const CHANGE_DIRECT_CALENDAR_BASEDATE = 'CHANGE_DIRECT_CALENDAR_BASEDATE'
export const MULTI_CHEAPEST = 'MULTI_CHEAPEST'

//两舱推荐
export const CHANGE_APPEND_SHOW_MORE_VISIBILITY = 'CHANGE_APPEND_SHOW_MORE_VISIBILITY'

//虚拟航班
export const SET_VIRTUALFLIGHTS = 'SET_VIRTUALFLIGHTS'

//两舱
export const SET_CFRECOMMENDYS_SHOWCABIN = 'SET_CFRECOMMENDYS_SHOWCABIN'
export const SET_HAS_CF_RECOMMEND_YS = 'SET_HAS_CF_RECOMMEND_YS'

//缺口程推荐
export const OPEN_JAW_RECOMMEND = 'OPEN_JAW_RECOMMEND'
export const OPEN_JAW_GROUND_TRAFFIC = 'OPEN_JAW_GROUND_TRAFFIC'

// 邻近航线直飞推荐
export const ADJACENT_AIRLINE_DIRECT_RECOMMEND = 'ADJACENT_AIRLINE_DIRECT_RECOMMEND'


//推荐实验版本
export const RECOMMEND_VERSION = "RECOMMEND_VERSION"

export const OW_RECOMMEND_RT_VERSION = "OW_RECOMMEND_RT_VERSION"


// 单程推荐往返
export const SET_OW_RECOMMEND_RT_END_DATE = "SET_OW_RECOMMEND_RT_END_DATE"
export const SET_OW_RECOMMEND_RT_TIP_STATUS = "SET_OW_RECOMMEND_RT_TIP_STATUS"
export const SET_OW_RECOMMEND_RT_USER_ATTR = "SET_OW_RECOMMEND_RT_USER_ATTR"


//两舱折叠/显示第二区块推荐航班超出部分（默认4个以外航班）
export const HIGH_CABIN_TOGGLE_EXTRA_REC_FLIGHTS = 'HIGH_CABIN_TOGGLE_EXTRA_REC_FLIGHTS'

//设置所有推荐航班
export const SET_ALL_RECOMMEND_FLIGHTS = 'SET_ALL_RECOMMEND_FLIGHTS'