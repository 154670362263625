import React from 'react';
import Tooltip from '../../../components/list/common/tooltip';
import genCabinHoverTooltipOfSeatsFragment from './genCabinHoverTooltipOfSeatsFragment';
import genCabinHoverTooltipFragment from './genCabinHoverTooltipFragment';
import getCabinTitle from './getCabinTitle';
/**
 * 生成指定航程的舱等HOVER内容
 * @param {*指定航程的航班中转集合} flightList
 * @param {*舱等集合} cabinEnumList
 */
const getCabinHoverContent = (relatedSegmentList, cabinEnumList, price, manyCabins) => {
	if (manyCabins) {
		const flightList: any[] = [];
		const cabinArr = cabinEnumList.toJS();

		let flightIndex = -1;
		relatedSegmentList.forEach((relatedSegment) => {
			relatedSegment
				.get('flightList')
				.valueSeq()
				.forEach((flight) => {
					flightIndex++;
					const airlineCode = flight.get('operateAirlineCode') || flight.get('marketAirlineCode');

					flightList.push({
						index: flightIndex,
						airlineCode,
						departureCityName: flight.get('departureCityName'),
						arrivalCityName: flight.get('arrivalCityName'),
						cabinEnum: cabinEnumList.size > 1 ? cabinArr[flightIndex] : cabinEnumList.first(),
						segmentNo: relatedSegment.get('segmentNo'),
						sequenceNo: flight.get('sequenceNo'),
					});
				});
		});

		return genCabinHoverTooltipFragment(flightList, price, (flight) => {
			return flight.cabinEnum ? getCabinTitle(flight.cabinEnum, flight.airlineCode).title : '';
		});
	} else if (price.get('lccServiceWrapper')) {
		// 针对廉航超经舱权益，即便是单舱等也要HOVER，此时HOVER出来不显示航程城市名的拼接，hover的title用：可享权益
		return (
			<Tooltip color="orange" extClass="tooltip-multitrip">
				{genCabinHoverTooltipOfSeatsFragment(
					price,
					{ segmentNo: Infinity, sequenceNo: Infinity },
					0,
					'lcc-service-title',
					() => '可享权益'
				)}
			</Tooltip>
		);
	} else {
		return null;
	}
};

export default getCabinHoverContent;
