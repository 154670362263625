import { UBT_SOURCE_RECOMMEND_STORAGE_KEY } from '@/src/constants/list/enums/common';

export const getSourceInfo = () => {
	try {
		let sourceData = sessionStorage.getItem(UBT_SOURCE_RECOMMEND_STORAGE_KEY);
		if (sourceData) {
			sourceData = JSON.parse(sourceData);
			return sourceData;
		}
	} catch (e) {
		return '';
	}

	return '';
};

export const setAdjacentRecommendType = (data) => {
	if (typeof data == 'object') {
		data = JSON.stringify(data);
	}
	sessionStorage.setItem(UBT_SOURCE_RECOMMEND_STORAGE_KEY, data);
};

export const getAdjacentRecommendType = () => {
	let sourceData = getSourceInfo();
	// @ts-expect-error
	return sourceData && sourceData.Type;
};

export const clearAdjacentRecommendType = () => {
	setAdjacentRecommendType('');
};

export const SessionHandler = {
	getSourceInfo,
	setAdjacentRecommendType,
	getAdjacentRecommendType,
	clearAdjacentRecommendType,
};
