/**
 * list reducer helper fn
 * Some reuse logic can be placed here...
 */
// state.getIn(selectedCityPath || ['list', 'search', 'selectedCity'])
// 多程信息辅助函数
const getMtSegList = (selectedCity) => {
	let list = {};

	selectedCity &&
		selectedCity.forEach((value, key) => {
			if (key.indexOf('mt') == 0) {
				let index = key.replace(/[^0-9]/gi, '') - 1;
				let valueIsNotEmpty = value && (value._isAMomentObject || !value.isEmpty());
				if (!list[index]) {
					list[index] = {};
				}
				// let value = state.getIn(['list', 'search','selectedCity']).get(key)
				switch (key.substr(2, 1)) {
					case 'D':
						if (value) {
							list[index].dCityText = valueIsNotEmpty
								? value.get('text')
									? value.get('text')
									: `${value.get('Name')}(${value.get('Code')})`
								: '';
							list[index].TimeZone = valueIsNotEmpty ? value.get('TimeZone') : '';
						} else {
							list[index].dCityText = '';
							list[index].TimeZone = '';
						}
						break;
					case 'A':
						if (value) {
							list[index].aCityText = valueIsNotEmpty
								? value.get('text')
									? value.get('text')
									: `${value.get('Name')}(${value.get('Code')})`
								: '';
						} else {
							list[index].aCityText = '';
						}
						break;
					case 'S':
						list[index].StartDate = value;
						break;
					default:
						break;
				}
			}
		});
	return list;
};

export default getMtSegList;
