import Enum from 'enum';
import { scopeObject } from '../../../sources/list/scopeObject';
import { InternationalProductType } from './internationalProductType';
import { DomesticProductType } from './domesticProductType';

/**
 * 产品类型
 */
export const PRODUCT_CATEGORY_TAG_TYPE = new Enum(scopeObject({ i: InternationalProductType, d: DomesticProductType }));

export class ProductCategoryWrapper {
  constructor(tag) {
    const invalid = tag.category && !PRODUCT_CATEGORY_TAG_TYPE.get(tag.category); // 不存在的枚举值
    if (invalid) {
      console.error(`unknown product type: ${tag.category}, all known proudct types: ${PRODUCT_CATEGORY_TAG_TYPE.enums.map(item => item.key).join(', ')}`);
      tag.category = '';
    }

    this.tag = tag.category ? PRODUCT_CATEGORY_TAG_TYPE.get(tag.category) : PRODUCT_CATEGORY_TAG_TYPE.None;
    this.name = tag.name;
    this.icon = tag.icon;
    this.description = tag.description;
    this.data = tag.data;
    this.policyFlag = tag.policyFlag;
  }
}
