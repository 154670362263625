import moment from 'moment';

class Converter {
	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 10-24
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static extractDateRemoveYear(str, format = 'MM-DD') {
		let m = moment(str);
		if (str && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 周二
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static remianWeek(dateStr) {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return `周${'一二三四五六日'.charAt(m.isoWeekday() - 1)}`;
		} else {
			return '';
		}
	}

	/**
	 * 提取包含日期时间的字符串中的日期部分，比如 2017-10-24 10:23:59 中的 2017-10-24
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23:30} str
	 */
	static extractDate(str, format = 'YYYY-MM-DD') {
		let m = moment(str);
		if (str && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 提取包含日期时间的字符串中的时间部分，比如 2017-10-24 10:23 中的 10:23
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static extractTime(dateStr, format = 'HH:mm') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 将分钟为单位的时长转换成 XXdXXhXXm
	 * @param {*时长，以分钟为单位} min
	 */
	static formatMinAsDayHourMinForShort(min) {
		let days = Math.floor(min / (24 * 60)),
			hours = Math.floor((min % (24 * 60)) / 60),
			mins = min % 60;

		return `${days > 0 ? days + 'd' : ''}${hours > 0 ? hours + 'h' : ''}${mins > 0 ? mins + 'm' : ''}`;
	}

	/**
	 * 取包含这dateStr个月内的三个月后的时间
	 */
	static getThreeMonthsLater(format = 'YYYY年MM月DD日') {
		let m = moment().add(3, 'M').subtract(moment().date(), 'days');
		if (m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 获取中文数字
	 * @param {*阿拉伯数字} 根据阿拉伯数字返回中文数字
	 */
	static getNumber(count) {
		return `${'一两三四五六'.charAt(count - 1)}`;
	}

	/**
	 * 获取多程程数目
	 * @param {*阿拉伯数字} 根据阿拉伯数字返回多程程数目
	 */
	static getMTNumber(count) {
		return `第${'一二三四五六'.charAt(count)}程`;
	}

	/**
	 * 获取往返程数目
	 * @param {*阿拉伯数字} 根据阿拉伯数字返回返程数目
	 */
	static getRTNumber(count) {
		return `${'去返'.charAt(count)}`;
	}

	/**
	 * 去掉日期时间的字符串中的年份和时分秒部分，比如 2017-10-24 10:23:00 变成 10月24日
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static remainMonthAndDay(dateStr, format = 'MM月DD日') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 提取包含日期时间的字符串中的小时、分钟部分，并转换成整数，比如 2017-11-8 15:39 转换成  1539
	 * @param {*日期格式的字符串，比如 2017-11-8 15:38} dateStr
	 */
	static extractTimeAsInt(dateStr) {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.hour() * 100 + m.minute();
		} else {
			return NaN;
		}
	}

	/**
	 * 将分钟为单位的时长转换成 XX天XX小时XX分
	 * @param {*时长，以分钟为单位} min
	 */
	static formatMinAsDayHourMin(min) {
		let days = Math.floor(min / (24 * 60)),
			hours = Math.floor((min % (24 * 60)) / 60),
			mins = min % 60;

		return `${days > 0 ? days + '天' : ''}${hours > 0 ? hours + '小时' : ''}${mins > 0 ? mins + '分' : ''}`;
	}
	/**
	 * 将分钟为单位的时长转换成 XX小时XX分
	 * @param {*时长，以分钟为单位} min
	 */
	static formatMinAsHourMin(min) {
		let hours = Math.floor(min / 60),
			mins = min % 60;

		return `${hours > 0 ? hours + '小时' : ''}${mins > 0 ? mins + '分' : ''}`;
	}
	/**
	 * 去掉日期时间的字符串中的年份和秒部分，比如 2017-10-24 10:23:00 变成 10月24日 10:23
	 * @param {*日期格式的字符串，比如 2017-10-24 10:23} dateStr
	 */
	static removeYearAndSecond(dateStr, format = 'MM月DD日 HH:mm') {
		let m = moment(dateStr);
		if (dateStr && m.isValid()) {
			return m.format(format);
		} else {
			return '';
		}
	}

	/**
	 * 根据航站楼字段转换中文名
	 * @param {*航站楼标记} terminal
	 */
	static getTerminalName(terminal) {
		return terminal == 'I' ? '国际航站楼' : terminal;
	}

	/**
	 * 将过长的字符串转为带省略号的字符串
	 * @param {*字符串} str
	 * @param {*航站楼标记} maxLength
	 */
	static getOmitString(str, maxLength) {
		if (maxLength > str.length) return str;
		return `${str.substring(0, maxLength)}...`;
	}

	/**
	 * 将小时为单位的时长转换成 XX天XX小时
	 * @param {*时长，以小时为单位} hour
	 */
	static formatHourAsDayHour(hour) {
		return hour ? Converter.formatMinAsDayHourMin(hour * 60) : '';
	}
}

export default Converter;
