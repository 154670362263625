import moment from 'moment';
import qs from 'qs';
import { isFromNowOn } from '../../utils/dateHelper';
import { setSearchDateIsPassed } from '../../actions/common/searchDataStorageHelper';
import { getSingletripAndRoundtripURL } from '../../utils/commonUtils';
import { SEARCH_BOX_UBT_KEY_TYPES } from '../../ubt.v2/searchBox/keyTypes';
import { PAGE_LIST_URL } from '../common';

export default class Helper {
    /**
     * 获取搜索框默认查询时间或者 判断搜索框默认查询时间是否过期如果过期返回一个新的日期
     */
    static getSearchDate = ({
        dDate = null,
        dTimeZone = 480,
        aDate = null,
        aTimeZone = 480,
        flightWay,
        flights = [],
    }) => {
        let defaultDate = {
            dDate: moment()
                .add(1, 'days')
                .format('YYYY-MM-DD'),
            aDate: moment()
                .add(4, 'days')
                .format('YYYY-MM-DD'),
        };
        let isPassed = false,
            aDateIsExpired = false,
            dDateIsExpired = false;

        let newDate = null;
        if (!dDate && !aDate && flightWay != 'MT') {
            return defaultDate;
        }

        // 1、若去程日期过期、无返程日期（单程）：去程日期=当前日期+1天
        // 2、若去回程日期均过期：去程日期=当前日期+1天，返程日期=去程日期+3天
        // 3、若去程日期过期、返程日期等于今天：去程日期=今天，返程日期为今天
        // 4、若去程日期过期、返程日期大于今天：去程日期=当前日期+1天，返程日期不变
        if (flightWay == 'OW') {
            if (dDate && !isFromNowOn(dDate, dTimeZone)) {
                newDate = {
                    dDate: defaultDate.dDate,
                };
                isPassed = true;
                dDateIsExpired = true;
            }
        } else if (flightWay == 'RT' && dDate && aDate) {
            if (!isFromNowOn(dDate, dTimeZone) && !isFromNowOn(aDate, aTimeZone)) {
                newDate = defaultDate;
                isPassed = true;
                dDateIsExpired = true;
                aDateIsExpired = true;
            } else if (!isFromNowOn(dDate, dTimeZone) && aDate == moment().format('YYYY-MM-DD')) {
                newDate = {
                    dDate: moment().format('YYYY-MM-DD'),
                    aDate: moment().format('YYYY-MM-DD'),
                };
                isPassed = true;
                dDateIsExpired = true;
            } else if (!isFromNowOn(dDate, dTimeZone) && moment(aDate).isAfter(moment().format('YYYY-MM-DD'))) {
                newDate = {
                    dDate: defaultDate.dDate,
                    aDate: aDate,
                };
                isPassed = true;
                dDateIsExpired = true;
            }
        } else if (flightWay == 'MT' && flights) {
            let fisrtSegIsPass = false;
            flights.forEach((flight, index) => {
                if (index == 0) {
                    fisrtSegIsPass = !isFromNowOn(flight.departureDate, flight.departureCityTimeZone);
                    isPassed = true;
                    if (fisrtSegIsPass) {
                        newDate = {
                            mtDates: [defaultDate.dDate],
                        };
                    }
                } else {
                    if (fisrtSegIsPass) {
                        newDate.mtDates.push(
                            moment()
                                .add(1 + 3 * index, 'days')
                                .format('YYYY-MM-DD'),
                        );
                    }
                }
            });
        }
        if (isPassed) {
            SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT.value.process({
                timeExpired: 1,
            });
        }
        return newDate
            ? {
                  ...newDate,
                  dDateIsExpired,
                  aDateIsExpired,
              }
            : {
                  dDate,
                  aDate,
              };
    };
    static validateDateAndJumpOneway = () => {
        let getCityCode = (seg, field) => {
            if (seg[`${field}AirportCode`] || seg[`${field}AirportName`]) {
                return `${seg[`${field}AirportCode`]}0`;
            }
            return `${seg[`${field}CityCode`]}`;
        };
        let inValidDate = window.GlobalSearchCriteria.flightSegments.some(item => {
            if (!isFromNowOn(item.departureDate, item.departureCityTimeZone)) return true;
        });

        if (inValidDate) {
            // SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT_UPLOAD_NOW.value.process({
            //     timeExpired: 1
            // })
            let dCode = getCityCode(window.GlobalSearchCriteria.flightSegments[0], 'departure'),
                aCode = getCityCode(window.GlobalSearchCriteria.flightSegments[0], 'arrival');

            let query = qs.parse(
                    window.location.search &&  window.location.search[0] === '?' ?  window.location.search.substring(1) :  window.location.search,
                ),
                { subchannelid, channelid } = query,
                channelParams = subchannelid ? `&subchannelid=${subchannelid}` : '',
                mainChannelParams = channelid ? `&channelid=${channelid}` : '';
            let url = `/${getSingletripAndRoundtripURL({
                dCode,
                aCode,
                dDate: moment().add(7, 'days'),
                airline: window.GlobalSearchCriteria.airline ? window.GlobalSearchCriteria.airline.code : '',
                directflight: !!window.GlobalSearchCriteria.directFlight,
            })}&cabin=${window.GlobalSearchCriteria.cabin.toLowerCase()}&adult=${
                window.GlobalSearchCriteria.adultCount
            }&child=${window.GlobalSearchCriteria.childCount}&infant=${window.GlobalSearchCriteria.infantCount}${channelParams}${mainChannelParams}`;
            //url redirect
            location.href = PAGE_LIST_URL + url;
        }
    };
    static validateDateAndJump = () => {
        // depdate
        let flightWay = window.GlobalSearchCriteria.flightWay,
            {
                departureDate,
                departureCityTimeZone,
                arrivalCityTimeZone,
            } = window.GlobalSearchCriteria.flightSegments[0],
            isValid = true,
            newSearchDate = '';
        switch (flightWay) {
            case 'S':
                if (!isFromNowOn(departureDate, departureCityTimeZone)) {
                    isValid = false;
                    newSearchDate = this.getSearchDate({
                        dDate: departureDate,
                        dTimeZone: departureCityTimeZone,
                        flightWay: 'OW',
                    }).dDate;
                }
                break;
            case 'D':
                let arrivalDate = window.GlobalSearchCriteria.flightSegments[1].departureDate;
                if (
                    !isFromNowOn(departureDate, departureCityTimeZone) ||
                    !isFromNowOn(arrivalDate, arrivalCityTimeZone)
                ) {
                    isValid = false;
                    let newSearchDateObj = this.getSearchDate({
                        dDate: departureDate,
                        dTimeZone: departureCityTimeZone,
                        aDate: arrivalDate,
                        aTimeZone: arrivalCityTimeZone,
                        flightWay: 'RT',
                    });
                    newSearchDate = newSearchDateObj.dDate + '_' + newSearchDateObj.aDate;
                }
                break;
            case 'M':
                if (!isFromNowOn(departureDate, departureCityTimeZone)) {
                    isValid = false;
                    let newSearchDateObj = this.getSearchDate({
                        flights: window.GlobalSearchCriteria.flightSegments,
                        flightWay: 'MT',
                    });
                    if (newSearchDateObj.mtDates && newSearchDateObj.mtDates.length) {
                        newSearchDate = newSearchDateObj.mtDates.join('_');
                    }
                }
                break;
        }
        if (!isValid && newSearchDate) {
            // 记录下搜索日期已经过期
            // SEARCH_BOX_UBT_KEY_TYPES.SEARCH_BOX_INIT_UPLOAD_NOW.value.process({
            //     timeExpired: 1
            // })
            setSearchDateIsPassed();

            const setParamToListUrl = ({ key, value }, url) => {
                let urlParams = qs.parse(url.split('?')[1]);
                if (urlParams[key]) {
                    urlParams[key] = value;
                }
                return url.split('?')[0] + '?' + qs.stringify(urlParams);
            };

            window.location.href = setParamToListUrl({ key: 'depdate', value: newSearchDate }, window.location.href);
            // location.href = "/international/search" +
        }
    };
}
