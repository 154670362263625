export enum TraceActionValues {
	/**
	 * 接口网络失败
	 */
	NetworkError = 'networkError',
	/**
	 * 接口成功
	 */
	Success = 'success',
	/**
	 * 请求成功但无结果
	 */
	NoResult = 'noResult',
	/**
	 * 接口服务失败
	 */
	FetchError = 'fetchError',
	/**
	 * 页面退出
	 */
	Break = 'break',
}

export type BlockBasicInfo = {
	/**
	 * 是否国际 0国内、1国际
	 */
	isInternational: string;
	/**
	 * 航程类型
	 */
	tripType: string;
	/**
	 * 舱位类型
	 */
	cabinType?: string; // TODO: 舱等类型，目前填写页拿不到舱等信息
	/**
	 * 航线
	 */
	ODRoutes: string;
	/**
	 * 乘客数量
	 */
	passengerCount?: string; // TODO: 乘客数量类型
	/**
	 *  品牌 'Ctrip'
	 */
	appSource: string;
	/**
	 * 客户端类型： 'Online',
	 */
	platform: string;
	/**
	 * 页面pageid
	 */
	pageId?: string;
	/*
	 * 前端、BFF、AGG排查用，预定流程不重查不变
	 */
	txid?: string;
	/**
	 * 自定义json字符串 如运营和产品需要的订单号需要加{"orderId","12123232271"}
	 */
	moreInfo?: string;
	/**
	 * 语言
	 */
	locale?: string;
};
// readonly ["pop", "toast", "focus", "empty", "error"];
export enum BlockTypeValue {
	// 弹框
	Pop = 'pop',
	// Toast
	Toast = 'toast',
	// 锚定
	Focus = 'focus',
	// 无结果
	Empty = 'empty',
	// error（错误页，如无网络、业务强制阻断）
	Error = 'error',
}
// readonly ["block", "tip"];
export enum InterruptTypeValue {
	/**
	 * 强拦
	 */
	Block = 'block',
	/**
	 * 弱拦
	 */
	Tip = 'tip',
}

export enum TripTypeValue {
	ONE_WAY = 'OW',
	ROUND_TRIP = 'RT',
}

export enum MainTypeValue {
	Search = 'Search',
	RouteStrictSearch = 'RouteStrictSearch',
	BookingValidation = 'BookingValidation',
	PassengerCheck = 'PassengerCheck',
	ContactCheck = 'ContactCheck',
	XRelated = 'X-related',
	CreateOrder = 'CreateOrder',
	Reservation = 'Reservation',
	TimeOut = 'TimeOut',
	Other = 'Other',
}

export enum SubTypeValue {
	// 首页搜索缺失出发/到达城市
	MissingCity = 'MissingCity',
	// 出发到达机场相同
	SameAirport = 'SameAirport',
	// 相同出发到底城市
	SameCity = 'SameCity',
	// 城市错误（e.g 提示一程中需为国际城市）
	CityError = 'CityError',
	// 不支持特定航线
	NotSupportRoute = 'NotSupportRoute',
	// 日期格式有误，请检查'
	WrongDate = 'WrongDate',
	// 缺失出发日期
	MissingDate = 'MissingDate',
	// 出发日期不能早于今天
	ExpiredDate = 'ExpiredDate',
	// 返程日期早于去程日期
	DepartureDateLaterThanArrivalDate = 'DepartureDateLaterThanArrivalDate',
	// 国内多程超过2程
	ChinaMultiTripOverTwo = 'ChinaMultiTripOverTwo',
	// 多程的 后面一程的出发日期不能早于前面一程的出发日期
	MutiDepartDateLaterThanNextTrip = 'MutiDepartDateLaterThanNextTrip',
	// 紧急通知
	RedNotice = 'RedNotice',
	/**
	 * 伤残军警弹窗
	 */
	Army = 'Army',
	/**
	 * 国内目前仅支持两程查询弹窗
	 */
	MultiTripRestriction = 'MultiTripRestriction',
	// 反爬验证码拦截
	Captcha = 'Captcha',
	// 临近起飞
	DepartSoon = 'DepartSoon',
	// 抽奖弹窗
	Lottery = 'Lottery',
	// 列表页 页面停留超时
	ShoppingTimeOut = 'ShoppingTimeOut',
	// 空铁组合
	TrainAndFlight = 'TrainAndFlight',
	// 旅套劣势提示弹窗
	TravelDisadvantageTip = 'TravelDisadvantageTip',
	// 多票组合
	SelfTransfer = 'SelfTransfer',
	// 大兴机场提醒
	PKAirports = 'PKAirports',
	// 查询无结果
	NoResult = 'NoResult',
	// 筛选无结果
	NoFilteredResult = 'NoFilteredResult',
	// 网络错误
	NetworkError = 'NetworkError',
	// 未选择乘机人
	NoPsgSelected = 'NoPsgSelected',
	// 儿童/婴儿乘机需有年满18周岁的成人陪同，请添加成人乘机人
	NoAdultPsg = 'NoAdultPsg',
	// 成人儿童比例验证	（每位成人最多能带2位儿童）
	AdultChildRatio = 'AdultChildRatio',
	// 不支持购买婴儿票
	InfantNotAllowed = 'InfantNotAllowed',
}

export const classTypeMap = {
	Y_S: ['Economy', 'Premium'],
	C_F: ['Business', 'First'],
	C: ['Business'],
	F: ['First'],
	Y_S_C_F: ['ALL'], // 这种类型只适用于阻塞埋点传值，业务场景还是对应['Economy', 'Business', 'First'],
	Y: ['Economy'],
	CF: ['Business', 'First'],
};
