const UBT_KEY = {
  HOME: {
    SHOW: 'home_page_show',
    SEARCH_BUTTON_PRESS: 'home_page_search_button_press',
    REPORT_CITY_SHOW: 'c_flt_report_more_show',
    REPORT_CITY_CLICK: 'c_flt_report_city',
    REPORT_THEME_CLICK: 'c_flt_report_theme',
    REPORT_PRICE_CLICK: 'c_flt_report_price',
    REPORT_MORE_CLICK: 'c_flt_report_more',
    MAP_CITY_CLICK: 'c_flt_map_city',
    MAP_MORE_CLICK: 'c_flt_map_more',
    MAP_OPEN_CLICK: 'c_flt_map_open',
    MAP_BUBBLE_CLICK: 'c_flt_map_bubble',
    MAP_DESTINATION_CLICK: 'c_flt_map_destination',
    MAP_PRICE_CLICK: 'c_flt_map_price',
    MAP_PRICE_SHOW: 'c_flt_map_price_show',
    HOME_BUTTON_PRESS_SUCCESS: 'home_page_button_press_success',
  },
  LIST: {
    SHOW: 'list_page_show',
    SEARCH: 'list_page_search',
    SEARCH_SUCCESS: 'list_page_search_success',
    BOOK_BUTTON_PRESS: 'list_page_button_press',
    BOOK_BUTTON_PRESS_SUCCESS: 'list_page_button_press_success'
  },
  BOOK: {
    SHOW: 'book_page_show',
    CREATE_BUTTON_PRESS: 'book_page_create_button_press',
    CREATE_SUCCESS: 'book_page_create_success',
  },
  INCREMENT: {
    SHOW: 'increment_page_show',
    MODIFY_BUTTON_PRESS: 'increment_page_modify_button_press',
    MODIFY_SUCCESS: 'increment_page_modify_success',
  },
  COMMON: {
    ASYNC_LOAD_ERROR: 'async_load_error'
  }
}

export { UBT_KEY }
