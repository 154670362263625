import React from 'react'

const getGiftToolTip = (data) => {
    return (
        <div className='tooltip'>
            <div className='tooltip-content'>
                {
                    data.map((item, index) => {
                        return (
                            <div key={index}>
                                <h3>{item.title}</h3>
                                <p dangerouslySetInnerHTML={{ __html: item.content }}></p>
                                {index < data.length - 1 ? <br /> : null}
                            </div>)
                    })
                }
            </div>
        </div>)
}

export { getGiftToolTip }