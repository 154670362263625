import { lightUpload } from './lightUbt';

export const tryExec = (cb, defaultReturn) => {
	try {
		return cb();
	} catch (error) {
		lightUpload('exec error', error);
		return defaultReturn;
	}
};
