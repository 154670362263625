import React, { useState } from 'react';
import Tooltip from '../../../components/list/common/tooltip';
import { PRICE_TAG_POSITION } from './priceTagPosition';
import { BOOKING_TAG_POSITION } from './bookingTagPosition';
import { Tabs, TabList, Tab, TabPannels, TabPannel } from '../../../components/list/common/Tabs';
import DeferredContent from '../../../components/list/common/deferredContent';
import CONFIG_URL from '../api';
import { getRemoteTooltip } from '../../../sources/list/getRemoteTooltip';
import TravelPackageComponent from './travelPackageComponent';

const simplePriceType = {
	NormalCashBack: '普通返现',
	RebateCashBack: '多返',
	FloatUpMilesBack: '高返赠里程',
	FloatUpCashBack: '高返返现',
	FloatUpRedPacketsBack: '高返赠红包',
	FloatUpCashRedPacketsBack: '高返返现金红包',
	FloatUpGiftCardBack: '高返返礼品卡',
	FloatUpFreeDelivery: '免费配送',
	BookAndDiscount: '订立减',
	BookAlreadyDiscounted: '订已减',
	MarketingCode: '营销',
	GiftMarketingCode: '礼盒',
	MemberLevel: '会员专享',
	FreeSurcharge: '免机建燃油费',
	MultiPassenger: '多人特惠',
	GroupTickting: '拼团政策',
	XDiscountProduct: '赠定减',
	TargetDelivery: '定向投放',
	AirlineMemberShip: '航司会员',
	JJJXSpecial: '京冀旅客专享',
	PrePrice: '特惠',
	LuxuryService: '享奢华服务',
	RoundSpecial: '往返特惠',
	RecommendCode: '推荐',
	NakePriceDiscount: '可裸价订立减',
	CouponEcho: '乘客优惠券',
	DiscountWrapper: '领券立减',
	RecommendTag: '推荐标签',
	NormalLabel: '通用标签',
	RefundChangeTag: '退改签标签',
};
const GifBoxToolTip = ({ tooltipContent, popupContent }) => {
	const [showDetail, setShowDetail] = useState(false);
	const onDetailClick = () => {
		setShowDetail(!showDetail);
	};

	return (
		<Tooltip>
			<div>
				<p>{tooltipContent}</p>
				<p className="tool-tip-ctrl">
					<a onClick={onDetailClick}>
						详情
						{showDetail && <i className="iconf-arrow-up iconf-up">&#xe604;</i>}
						{!showDetail && <i className="iconf-arrow-down iconf-down">&#xe60c;</i>}
					</a>
				</p>
				<p>{showDetail && popupContent}</p>
			</div>
		</Tooltip>
	);
};
const customPriceType = {
	TravelPackage: {
		getTooltip: (wrapper, _prevCond, _price) => {
			const invoiceType = _price.getIn(['invoiceType']);
			const priceUnitList = _price.getIn(['priceUnitList']);
			const isNew =
				priceUnitList &&
				priceUnitList.some((priceUnitListItem) => {
					return (
						priceUnitListItem.getIn(['flightSeatList']) &&
						priceUnitListItem
							.getIn(['flightSeatList'])
							.some((flightSeatListItem) =>
								(flightSeatListItem.getIn(['productTypes']) || []).includes('TravelPackage')
							)
					);
				}) &&
				window.GlobalConfigs.travelpackage;

			if (isNew) {
				return <TravelPackageComponent wrapper={wrapper} invoiceType={invoiceType}></TravelPackageComponent>;
			}
			return null;
		},
		rawLabel: '旅游专享套餐',
	},
	ScatteredGroup: {
		getLabel: (wrapper) => wrapper.data.tag || wrapper.data.mainTitle,
		getTooltip: (wrapper) =>
			wrapper.data.hoverList ? (
				<div className="tooltip tooltip-table compact-title">
					<Tabs>
						<TabList>
							{wrapper.data.hoverList.map((item) => (
								<Tab key={item.tag}>{item.tag}</Tab>
							))}
						</TabList>
						<TabPannels>
							{wrapper.data.hoverList.map((item) => (
								<TabPannel key={item.tag}>
									<p style={{ padding: 8 }} dangerouslySetInnerHTML={{ __html: item.desc }} />
								</TabPannel>
							))}
						</TabPannels>
					</Tabs>
				</div>
			) : (
				<Tooltip content={wrapper.data.desc} />
			),
		rawLabel: '拼团套餐',
	},
	GiftMarketingCode: {
		getTooltip: (wrapper, _prevCond, _price, _index) => {
			let desc = wrapper.data.desc;
			if (desc) {
				return <Tooltip content={wrapper.data.desc} />;
			} else {
				return (
					<DeferredContent
						key={`${_index}`}
						promise={getRemoteTooltip(wrapper)}
						loading={<Tooltip content="loading..." />}
						then={(data) => {
							let { title, content } = (data && data[0]) || {};
							if (title) {
								return <GifBoxToolTip tooltipContent={title} popupContent={content} />;
							} else {
								return null;
							}
						}}
					/>
				);
			}
		},
		getUrl: () => CONFIG_URL.getGiftDetail,
		getUrlParams: (data) => {
			return { giftIds: data.giftId };
		},
		rawLabel: '礼',
	},
	BookAndDiscount: {
		// 拼接出来两段显示
		getLabel: (wrapper) => (
			<React.Fragment>
				{wrapper.data.preTag ? <span className="pre_label">{wrapper.data.preTag}</span> : null}
				<span dangerouslySetInnerHTML={{ __html: wrapper.data.tag }} />
			</React.Fragment>
		),
	},
	MemberSuper: {
		getLabel: (wrapper) => wrapper.data.tag,
		labelClassName: 'member-super',
	},
	MemberLevel: {
		getLabel: (wrapper) => wrapper.data.tag,
		labelClassName: window.GlobalConfigs.isBlackDiamondUser ? 'member-level-black' : 'member-level',
	},
	CouponEcho: {
		getLabel: (wrapper) => wrapper.data.tag,
		tooltipClassName: 'coupon-echo-tip',
		rawLabel: '乘客优惠券',
	},
};

export const DomesticPriceType = [...Object.keys(simplePriceType), ...Object.keys(customPriceType)].reduce(
	(acc, type) => {
		const { tooltipClassName = '' } = customPriceType[type] || {};
		acc[type] = {
			getLabel: (wrapper) => wrapper.data.tag,
			getTooltip: (_wrapper) => <Tooltip extClass={tooltipClassName} content={_wrapper.data.desc} />,
			position: PRICE_TAG_POSITION.MIDDLE,
			BookPosition: BOOKING_TAG_POSITION.HIDDEN,
			rawLabel: simplePriceType[type],
			...(customPriceType[type] || {}),
		};

		return acc;
	},
	{}
);
