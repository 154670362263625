const getCityName = ({ isBuildUp, prevCond, isDepart, showCityText, currentSegmentSeq }) => {
    const flied = isDepart ? 'departure' : 'arrival'
    let cityName = '', airportName = ''
    if (isBuildUp) {
        airportName = prevCond.getIn(['flightSegments', 0, `${flied}AirportName`]);
        cityName += prevCond.getIn(['flightSegments', 0, `${flied}CityName`]);
        airportName && !showCityText ? cityName += `(${airportName})` : ''
    }
    else {
        airportName = prevCond.getIn(['flightSegments', currentSegmentSeq, `${flied}AirportName`]);
        cityName += prevCond.getIn(['flightSegments', currentSegmentSeq, `${flied}CityName`]);
        airportName && !showCityText ? cityName += `(${airportName})` : ''
    }

    return cityName
}


export const getCityNamesFromPrevCond = (props) => {
    let departCityName = getCityName({ ...props, isDepart: true })
    let arrivalCityName = getCityName({ ...props, isDepart: false })
    const MAX_LENGTH = 12
    const HALF_MAX_LENGTH = MAX_LENGTH / 2
    const departCityNameLength = departCityName.length
    const arrivalCityNameLength = arrivalCityName.length
    const departCityNameShorterThanHalfMaxLength = departCityNameLength <= HALF_MAX_LENGTH
    const arrivalCityNameShorterThanHalfMaxLength = arrivalCityNameLength <= HALF_MAX_LENGTH

    const exceedLength = departCityNameLength + arrivalCityNameLength - MAX_LENGTH
    if (exceedLength > 0) {
        return [
            departCityNameShorterThanHalfMaxLength ? departCityName : departCityName.substring(0, arrivalCityNameShorterThanHalfMaxLength ? departCityNameLength - exceedLength : HALF_MAX_LENGTH) + '...',
            arrivalCityNameShorterThanHalfMaxLength ? arrivalCityName : arrivalCityName.substring(0, departCityNameShorterThanHalfMaxLength ? arrivalCityNameLength - exceedLength : HALF_MAX_LENGTH) + '...',
            departCityName,
            arrivalCityName
        ]
    } else {
        return [
            departCityName,
            arrivalCityName,
            departCityName,
            arrivalCityName
        ]
    }
}
