import React, { ComponentType, useState } from 'react';
import dynamic from 'next/dynamic';
import { InView } from 'react-intersection-observer';
import { ubtError } from '@/src/sources/common/lightUbt';

type ComponentModule<P> = {
	default: ComponentType<P>;
};

type LoaderComponent<P> = Promise<ComponentType<P> | ComponentModule<P>>;

type AsyncLoadOptions = {
	delay?: number;
	loading?: JSX.Element;
	logKey?: string;
};

type Loader<P> = (() => LoaderComponent<P>) | LoaderComponent<P>;

function AsyncLoad<P>(getPromise: Loader<P>, options?: AsyncLoadOptions) {
	const { delay = 0, loading = <div /> } = options || {};

	const ImportComponent = dynamic(getPromise, {
		ssr: false,
		loading: ({ error }) => {
			if (error) {
				ubtError(error);
			}
			return loading;
		},
	});

	return function LazyComponentWrapper(props: P) {
		const [isVisible, setIsVisible] = useState(false);
		if (isVisible) {
			// @ts-expect-error
			return <ImportComponent {...props} />;
		}
		return (
			<InView
				onChange={(inView) => {
					if (inView) {
						if (delay > 0) {
							setTimeout(() => {
								setIsVisible(true);
							}, delay);
						} else {
							setIsVisible(true);
						}
					}
				}}
			>
				{loading}
			</InView>
		);
	};
}

export default AsyncLoad;
