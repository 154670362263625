import { API_ROOT, RUNTIME_SERVER_HOST } from '../common';

/**
 * 航班查询接口
 */
export const searchFlightsUrl = `${API_ROOT}/search/batchSearch`;

const CONFIG_URL = {
	// 公告接口
	getNotices: `${API_ROOT}/notify/getSearchPageNotify`,
	// 公告和广告接口
	getAdsAndNotices: `${API_ROOT}/notify/getSearchPageHornContent`,
	// 航班舒适度查询接口
	getFlightComfort: `${API_ROOT}/flight/comfort/getFlightComfort`,

	searchFlights: searchFlightsUrl,
	// 轮询拉取普通查询剩余批次结果
	pullFlights: `${API_ROOT}/search/pull/`,
	// 查询航班信息浮层舒适度信息
	getFlightInfoCardComfort: `${API_ROOT}/flightinfo/getFlightInfoCardComfort`,
	// 查询航班信息浮层过境签说明信息
	getTransitVisaRemarkList: `${API_ROOT}/flightinfo/getTransitVisaRemarkList`,
	// 历史搜索查询接口
	getSearchHistory: `${API_ROOT}/history/getSearchHistory`,
	// 节假、工作日查询接口
	getWorkingDays: '//api.ctrip.com/date/api/queryRangeDat/',

	// 低价日历tab：低价日历单程接口、低价日历往返接口
	getCalendarL: `${RUNTIME_SERVER_HOST}/restapi/soa2/15380/bjjson/FlightIntlAndInlandLowestPriceSearch`,
	// // 低价日历tab：低价日历单程接口
	// getCalendarLP_S: `${API_ROOT}/lowprice/calendar/getCalendarDetailList`,
	// // 低价日历tab：低价日历往返接口
	// getCalendarLP_D: `${API_ROOT}/lowprice/calendar/get45DaysCalendarDetailList`,

	// 单程/往返获取临近低价推荐数据(临近城市)
	getAdjacentCityLowPrice: `${API_ROOT}/recommend/adjacentLowPrice/getAdjacentCityLowPrice`,
	// 直飞推荐接口
	getDirectRecommend: `${API_ROOT}/recommend/nearDateDirect/getAdjacentDateDirectFlights`,
	// 直飞日历接口
	getDirectCalendar: `${API_ROOT}/recommend/nearDateDirect/getCalendarAdjacentDateDirectFlights`,
	// 礼盒详情：HOVER时候查看
	getGiftDetail: `${API_ROOT}/gift/detail`,
	// 列表页自动发券
	sendCoupons: `${API_ROOT}/booking/coupon/sendCoupons`,
	// 普通反查
	routeSearch: `${API_ROOT}/search/routeSearch`,
	// 预订
	book: `${RUNTIME_SERVER_HOST}/international/AjaxRequest/SearchFlights/AsyncSearchHandlerSOAII.ashx`,
	// 新版填写页预订
	javaBook: `${API_ROOT}/search/strictSearch`,
	// 获取所有国家区号列表
	getCountryCodes: `${API_ROOT}/country/getCountryCodes`,
	// 需求单提交接口
	saveRequireForm: `${API_ROOT}/requisition/save`,
	// 获取单个运价退改签详情信息
	getPenaltyDetail: API_ROOT + '/penalty/getPenaltyRule',
	// 列表页批量获取舒适度外漏标签
	batchGetComfortTagList: `${API_ROOT}/flight/comfort/batchGetComfortTagList`,
	// 获取热门国家信息
	getHotCountryListUrl: `${API_ROOT}/poi/getHotCountryList`,
	// 列表页底部热门查询路线接口
	getSearchPageFooter: `${API_ROOT}/page/getSearchPageFooter`,
	// 缺口程推荐航班接口
	getOpenJawRecommend: `${API_ROOT}/recommend/openjaw/getOpenJawRecommend`,
	// 缺口程推荐交通信息接口
	getOpenJawGroundTraffic: `${API_ROOT}/recommend/openjaw/getGroundTraffic`,
	// 邻近航线直飞推荐
	getAdjacentCityDirectFlights: `${API_ROOT}/recommend/nearDateDirect/getAdjacentCityDirectFlights`,
	// 航班过境签/行李直挂说明
	getVisaLuggageDirectInfo: `${API_ROOT}/flightinfo/getVisaLuggageDirectInfo`,
	// 获取abtest某个KEY值
	getSwitchOpened: `${API_ROOT}/switch/getSwitchOpened`,
	// 获取abtest某个KEY版本
	getSwitchABTestVersion: `${API_ROOT}/switch/getSwitchABTestVersion`,
	// 写日志
	writeLog: `${API_ROOT}/log/writeLog`,
	// 列表页购票攻略接口
	getGuideline: `${API_ROOT}/flight/guideline/getGuideline`,
	// 单程推往返用户维度
	checkUserAttr: `${API_ROOT}/recommend/oneway/checkUserAttr`,
	// 获取查询条件相关信息
	getGlobalSearchCriteria: `${API_ROOT}/flightlist`,
	// 获取各类推荐航班组合信息
	getAllRecommendFlights: `${API_ROOT}/recommend/getAdjacentRecommendInfo`,
	// 获取前置x产品信息
	getXProductPrepose: `${API_ROOT}/search/getXproductprepose`,
	// 获取用户星级
	getUserStarInfo: `${API_ROOT}/user/getUserStarInfo`,
	// 获取NGS打分
	getNgs: `${API_ROOT}/flightinfo/getNgsScores`,
	getBasicPageData: `${API_ROOT}/base/getBasicPageData`,
	getThemeRecommmend: `${API_ROOT}/recommend/getThemeRecommmend`,
	getDestinationRecommend: `${API_ROOT}/recommend/getDestinationRecommend`,
	getFlightGiftInfo: `${API_ROOT}/gift/getFlightGiftInfo`,
	// 获取历史准点率
	getHistoryPunctuality: `${API_ROOT}/flight/comfort/getHistoryPunctuality`,
	// 获取反爬虫验证码信息
	getVerifyPicAuthCode: `${API_ROOT}/authcode/verifyPicAuthCode`,
};

export default CONFIG_URL;
