import React from "react"
import { connect } from "react-redux"
import ClassGrade from "./ClassGrade"
import OutsideClickHandler from '../OutsideClickHandler'

class PassengerCountContainer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            dropdownDisplay: null
        }
        this.onOutsideClick = this.onOutsideClick.bind(this)
        this.showDropdown = this.showDropdown.bind(this)
        this.hideDropdown = this.hideDropdown.bind(this)
        this.onSelectActive = this.onSelectActive.bind(this)
        this.onSelectedClassGrade = this.onSelectedClassGrade.bind(this)
        

    }
    showDropdown() {
        this.setState({ dropdownDisplay: !this.state.dropdownDisplay })
    }

    hideDropdown() {
        this.state.dropdownDisplay && this.setState({ dropdownDisplay: false })
    }
    onOutsideClick() {
        this.hideDropdown()
    }
    onSelectActive() {
        this.showDropdown()
    }
    onSelectedClassGrade(classGrade) {
        this.props.onClassGradeChange(classGrade, this.props.domestic)
        this.hideDropdown()
    }
    render() {
        return (
            <OutsideClickHandler style={{ float: 'right' }} onOutsideClick={this.onOutsideClick} >
                <ClassGrade
                    {...this.props} dropdownDisplay={this.state.dropdownDisplay} onSelectActive={this.onSelectActive} onSelectedClassGrade={this.onSelectedClassGrade}
                />
            </OutsideClickHandler>
        )
    }
}


export default connect(null, null)(PassengerCountContainer)
