import Enum from 'enum'

export const AREA_CODE = new Enum({
    HOT_COUNTRY_LIST: [
        { code: '86', text: '中国大陆' },
        { code: '852', text: '中国香港' },
        { code: '853', text: '中国澳门' },
        { code: '886', text: '中国台湾' },
        { code: '1', text: '加拿大' },
        { code: '1', text: '美国' },
        { code: '7', text: '俄罗斯' },
        { code: '33', text: '法国' },
        { code: '39', text: '意大利' },
        { code: '44', text: '英国' },
        { code: '49', text: '德国' },
        { code: '60', text: '马来西亚' },
        { code: '61', text: '澳大利亚' },
        { code: '62', text: '印度尼西亚' },
        { code: '63', text: '菲律宾' },
        { code: '64', text: '新西兰' },
        { code: '65', text: '新加坡' },
        { code: '66', text: '泰国' },
        { code: '81', text: '日本' },
        { code: '82', text: '韩国' },
        { code: '84', text: '越南' },
        { code: '91', text: '印度' },
        { code: '855', text: '柬埔寨' }
    ],
    STARTS: ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'J', 'K', 'L', 'M', 'N', 'P', 'R', 'S', 'T', 'W', 'X', 'Y', 'Z'],
    TITLE: ['hot','ABC','DEFGH','JKL','MN','PRST','WXYZ']
})