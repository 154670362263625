import qs from 'qs';
import axios from 'axios';
import CONFIG_URL, { searchFlightsUrl } from '../../constants/list/api';
import { MARK_PRE_SEARCH, AGG_SEARCH_ID, MARK_QUICK_SEARCH_FLIGHTS } from '../../sources/common/localStorageKey';
import { fetchPostWithHeaders } from '../common';
import { genAntiCrawlerHeader } from '../../sources/list/genAntiCrawlerHeader';
import { getQuickSearchFlightsCache } from './quickSearchFlightsCache';
import { getIfScopeInternational } from '../../sources/list/scope';

const getSearchId = () => {
	// 首次请求列表页 html 时站点会将searchId全局输出到页面id为 agg_search_id 的hidden，
	// 同时马上向AGG发出航班数据查询，前端就用全局变量 searchId 向站点发pull请求；
	// 但在切换低价日历等局部刷新页面搜索航班的场合，还是需要按照老的方式，
	// 从 batchSearch 发请求，根据batchSearch返回的 searchId发pull请求；
	// sessionStorage中存储的是首页SPA跳转前存储的
	let channelSpaSearchId = sessionStorage.getItem(AGG_SEARCH_ID);

	let elAggSearchId = document.getElementById('agg_search_id');
	let aggSearchId = channelSpaSearchId || (elAggSearchId ? elAggSearchId.value : '');

	return aggSearchId;
};

const cleanSearchId = () => {
	window.sessionStorage.removeItem(MARK_PRE_SEARCH);
	window.sessionStorage.removeItem(AGG_SEARCH_ID);
	let elAggSearchId = window.document.getElementById('agg_search_id');
	elAggSearchId && elAggSearchId.setAttribute('value', '');
};
// 上一次请求;
let pre = null;
export const lightSearchFlights = (prevCond) => {
	typeof prevCond.toJS === 'function' && (prevCond = prevCond.toJS());

	const cache = getQuickSearchFlightsCache(prevCond);
	if (cache) {
		cleanSearchId();
		window.sessionStorage.setItem(MARK_QUICK_SEARCH_FLIGHTS, '1');
		return cache.promise;
	} else {
		let header = genAntiCrawlerHeader(prevCond);
		let searchUrl = searchFlightsUrl;
		const aggSearchId = getSearchId(),
			scope = { prevCond };

		cleanSearchId();
		if (aggSearchId && scope == getIfScopeInternational()) {
			// 从首页过来的预搜索
			window.sessionStorage.setItem(MARK_PRE_SEARCH, '1');
			searchUrl = CONFIG_URL.pullFlights + aggSearchId;
		}
		const controll = axios.CancelToken.source();
		if (searchUrl === searchFlightsUrl) {
			// 快速切换时取消上一次请求
			if (!pre) {
				pre = controll;
			} else {
				try {
					pre.cancel();
				} catch (e) {
					console.error(e);
				}
				pre = controll;
			}
			// 仅给batchSearch接口
			const query = qs.parse(
					window.location.search && window.location.search[0] === '?'
						? window.location.search.substring(1)
						: window.location.search
				),
				{ subchannelid, channelid } = query;

			subchannelid && window.sessionStorage.setItem('subChannelId', subchannelid);
			channelid && window.sessionStorage.setItem('mainChannel', channelid);

			const subChannelId = subchannelid || sessionStorage.getItem('subChannelId');
			const mainChannel = channelid || sessionStorage.getItem('mainChannel');
			if (subChannelId || channelid) {
				prevCond = {
					...prevCond,
					searchSourceChannel: {
						subChannelId: subChannelId,
						channel: mainChannel,
					},
				};
			}
		}
		return fetchPostWithHeaders(searchUrl, prevCond, header, { cancelToken: controll.token });
	}
};
