
//TODO
const getEnv = () => 'PRO'

const SiteInfo = {
    siteName: (function () { //根据Ctrip.Util.PageConfig.solution计算出来 国际机票的域名
        var env, url;
        env = getEnv()
        switch (env) {
            case 'FAT':
                url = 'online.flights.ctrip.fat3.qa.nt.ctripcorp.com'; break;
            case 'UAT_NT':
                url = 'flights.uat.qa.nt.ctripcorp.com'; break;
            case 'PRO':
                url = 'flights.ctrip.com'; break;
            default:
                url = 'flights.ctrip.com'; break;
        }
    
        return location.protocol + '//' + url + '/';
    })()
}

export default SiteInfo