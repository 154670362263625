export const scrollToFirstFlight = (fixSortBar, fixLowPriceCalendar) => {
    const result = document.querySelector('.result-wrapper')
    if (fixSortBar && result) {
        const sortBar = document.querySelector('.topbar-v2'),
            lowPriceCalendar = document.querySelector('.calendarlp-tab')
        let height = sortBar && sortBar.getBoundingClientRect().height || 0
        if (fixLowPriceCalendar) {
            height += lowPriceCalendar && lowPriceCalendar.getBoundingClientRect().height || 0
        }
        // 增加一个小间距视觉上保持吸顶，但是又不是吸顶状态，解决向上滑动日历出现的问题
        let gap = fixLowPriceCalendar ? 0 : 4
        scrollTo(0, window.scrollY + result.getBoundingClientRect().top - height - gap)
    }
}