export const FETCH_HOT_TOPICS = 'CHANNEL_FETCH_HOT_TOPICS'; // 获取热门主题

export const SEARCH_INIT_GLOBAL_SWITCH = 'SEARCH_INIT_GLOBAL_SWITCH';
export const SET_PRICE_MAP_SWITCH = 'SET_PRICE_MAP_SWITCH';

// 重要公告
export const GEN_IMPORTANT_NOTICE = 'GEN_IMPORTANT_NOTICE';
export const GEN_CLOSE_IMPORTANT_NOTICE = 'GEN_CLOSE_IMPORTANT_NOTICE';

// 底部最新订单
export const FETCH_CHANNEL_LATEST_ORDERS = 'FETCH_CHANNEL_LATEST_ORDERS';
export const FETCH_CHANNEL_LATEST_ORDERS_SUCCESS = 'FETCH_CHANNEL_LATEST_ORDERS_SUCCESS';
export const FETCH_CHANNEL_LATEST_ORDERS_FAILURE = 'FETCH_CHANNEL_LATEST_ORDERS_FAILURE';
// 底部最新成交订单轮播
export const EXTRACT_CHANNEL_LATEST_ORDERS = 'EXTRACT_CHANNEL_LATEST_ORDERS';

// 底部热门航班
export const FETCH_CHANNEL_SPECIAL_AIRLINES = 'FETCH_CHANNEL_SPECIAL_AIRLINES';
export const FETCH_CHANNEL_SPECIAL_AIRLINES_SUCCESS = 'FETCH_CHANNEL_SPECIAL_AIRLINES_SUCCESS';
export const FETCH_CHANNEL_SPECIAL_AIRLINES_FAILURE = 'FETCH_CHANNEL_SPECIAL_AIRLINES_FAILURE';
export const SCROLL_LEFT_SPECIAL_AIRLINES = 'SCROLL_LEFT_SPECIAL_AIRLINES';
export const SCROLL_RIGHT_SPECIAL_AIRLINES = 'SCROLL_RIGHT_SPECIAL_AIRLINES';

// 获取用户当前城市三字码
export const FETCH_CITY_CODE = 'FETCH_CITY_CODE';
export const FETCH_CITY_CODE_SUCCESS = 'FETCH_CITY_CODE_SUCCESS';
export const FETCH_CITY_CODE_FAILRUE = 'FETCH_CITY_CODE_FAILRUE';

//获取往返特惠航线
export const FETCH_CHANNEL_RETURN_AIRLINES = 'FETCH_CHANNEL_RETURN_AIRLINES';
export const FETCH_CHANNEL_RETURN_AIRLINES_SUCCESS = 'FETCH_CHANNEL_RETURN_AIRLINES_SUCCESS';
export const FETCH_CHANNEL_RETURN_AIRLINES_FAILURE = 'FETCH_CHANNEL_RETURN_AIRLINES_FAILURE';

//单程到达城市
export const CHANGE_CHANNEL_RETURN_FROM = 'CHANGE_CHANNEL_RETURN_FROM';
export const CHANGE_CHANNEL_RETURN_TO = 'CHANGE_CHANNEL_RETURN_TO';

//获取单程特价航线
export const FETCH_CHANNEL_SINGLE_AIRLINES = 'FETCH_CHANNEL_SINGLE_AIRLINES';
export const FETCH_CHANNEL_SINGLE_AIRLINES_SUCCESS = 'FETCH_CHANNEL_SINGLE_AIRLINES_SUCCESS';
export const FETCH_CHANNEL_SINGLE_AIRLINES_FAILURE = 'FETCH_CHANNEL_SINGLE_AIRLINES_FAILURE';

//获取单程特价航线目的城市列表
export const FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS = 'FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS';
export const FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_SUCCESS = 'FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_SUCCESS';
export const FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_FAILURE = 'FETCH_CHANNEL_SINGLE_AIRLINE_LOCATIONS_FAILURE';

export const CHANGE_CHANNEL_SEARCH_CONDITION = 'CHANGE_CHANNEL_SEARCH_CONDITION';
export const SEARCH_CHANNEL_FLIGHT = 'SEARCH_CHANNEL_FLIGHT';
export const CHANGE_CHANNEL_FLIGHTNO = 'CHANGE_CHANNEL_FLIGHTNO';

export const SELECT_CHANNEL_FLIGHTWAY = 'SELECT_CHANNEL_FLIGHTWAY';

//全局事件
export const MOUSE_DOWN = 'MOUSE_DOWN';
export const MOUSE_UP = 'MOUSE_UP';
export const RESIZE = 'RESIZE';

//左侧工具栏上边距控制
export const SIDEBOX_STYLE_RESET = 'SIDEBOX_STYLE_RESET';

//搜索框宽度
export const SEARCH_BOX_WIDTH = 'SEARCH_BOX_WIDTH';

//城市选择器
export const SHOW_HOT_CITY_PICKER = 'SHOW_HOT_CITY_PICKER';
export const HIDE_HOT_CITY_PICKER = 'HIDE_HOT_CITY_PICKER';

// 搜索框城市选择
export const SELECT_CITY = 'SELECT_CITY';
export const EXCHANGE_CITY = 'EXCHANGE_CITY';
export const CHANGE_MT_DEPARTCITY = 'CHANGE_MT_DEPARTCITY';
export const CHANGE_MT_ARRIVALCITY = 'CHANGE_MT_ARRIVALCITY';

//POI
export const CHANGE_POI_KEY = 'CHANGE_POI_KEY';
export const HIDE_POI_CITY_PICKER = 'HIDE_POI_CITY_PICKER';
export const CHANGE_POI_INPUT = 'CHANGE_POI_INPUT';
export const CHANGE_POI_LIST = 'CHANGE_POI_LIST';
export const SELECT_POI_ITEM = 'SELECT_POI_ITEM';
export const CHANGE_POI_LIST_VISIBLE = 'CHANGE_POI_LIST_VISIBLE';
export const SAVE_LAST_POI = 'SAVE_LAST_POI';
export const SHOW_WARN = 'SHOW_WARN';

export const CANCEL_FOCUS_MT_SECOND_DCITY = 'CANCEL_FOCUS_MT_SECOND_DCITY';

// 旧版高级搜索
export const TOGGLE_ADVANCED_SEARCH = 'TOGGLE_ADVANCED_SEARCH';

//搜索框日期选择
export const CHANGE_OW_START_DATE = 'CHANGE_OW_START_DATE';
export const CHANGE_OW_END_DATE = 'CHANGE_OW_END_DATE';
export const CHANGE_RT_START_DATE = 'CHANGE_RT_START_DATE';
export const CHANGE_RT_END_DATE = 'CHANGE_RT_END_DATE';
export const CHANGE_MT_START_DATE = 'CHANGE_MT_START_DATE';
export const GET_START_DATE_TIP = 'GET_START_DATE_TIP';
export const GET_END_DATE_TIP = 'GET_END_DATE_TIP';
export const CANCEL_FOCUS = 'CANCEL_FOCUS';

//搜索框多程
export const ADD_MT_SEG = 'ADD_MT_SEG';
export const DEL_MT_SEG = 'DEL_MT_SEG';
export const EXCHANGE_MT_SEG = 'EXCHANGE_MT_SEG';

//搜索框乘客数量
export const CHANGE_PASSENGER_COUNT = 'CHANGE_PASSENGER_COUNT';
export const CHANGE_CLASS_GRADE = 'CHANGE_CLASS_GRADE';

//日历低价
export const FETCH_LOW_PRICE = 'FETCH_LOW_PRICE';
export const FETCH_LOW_PRICE_SUCCESS = 'FETCH_LOW_PRICE_SUCCESS';
export const FETCH_LOW_RPRICE_SUCCESS = 'FETCH_LOW_RPRICE_SUCCESS';
export const FETCH_LOW_PRICE_FAILRUE = 'FETCH_LOW_PRICE_FAILRUE';

// validate
export const SHOW_INFANT_TIPS = 'SHOW_INFANT_TIPS';
export const DDATE_ERROR = 'DDATE_ERROR';
export const ADATE_ERROR = 'ADATE_ERROR';
export const DCITY_ERROR = 'DCITY_ERROR';
export const ACITY_ERROR = 'ACITY_ERROR';
export const OW_RT_CLEAN_ERROR = 'OW_RT_CLEAN_ERROR';
export const MT_DCITY_ERROR = 'MT_DCITY_ERROR';
export const MT_ACITY_ERROR = 'MT_ACITY_ERROR';
export const MT_DDATE_ERROR = 'MT_DDATE_ERROR';
export const MT_CLEAN_ERROR = 'MT_CLEAN_ERROR';
export const SHOW_CHILD_TIPS = 'SHOW_CHILD_TIPS';

export const CACHE_RECOVERY = 'CACHE_RECOVERY';

//机加酒
export const SEARCH_FLT_PLUS_HOTEL = 'SEARCH_FLT_PLUS_HOTEL';

//关闭第一次进入时弹出的优惠提醒气泡
export const CLOSE_FIRST_ENTER_TIP = 'CLOSE_FIRST_ENTER_TIP';
//打开第一次进入时弹出的优惠提醒气泡
export const OPEN_FIRST_ENTER_TOP = 'OPEN_FIRST_ENTER_TOP';

//高级搜索航司下拉列表及搜索框
export const FETCH_AIRLINES_LIST = 'FETCH_AIRLINES_LIST';
export const FETCH_AIRLINES_LIST_SUCCESS = 'FETCH_AIRLINES_LIST_SUCCESS';
export const FETCH_AIRLINES_LIST_FAILRUE = 'FETCH_AIRLINES_LIST_FAILRUE';
export const SELECT_AIRLINE = 'SELECT_AIRLINE';

//focus第二程
export const FOCUS_MT_SECOND_DCITY = 'FOCUS_MT_SECOND_DCITY';

//有告警搜索按钮不跳转
export const SET_CAN_NOT_SEARCH = 'SET_CAN_NOT_SEARCH';
export const RESET_CAN_NOT_SEARCH = 'RESET_CAN_NOT_SEARCH';

export const SET_DIRECT_FLIGHT = 'SET_DIRECT_FLIGHT';

//热门国家模糊入口
export const CHANGE_ARRIVAL_COUNTRY = 'CHANGE_ARRIVAL_COUNTRY';
export const SWITCH_HOT_SEARCH = 'SWITCH_HOT_SEARCH';

export const SWITCH_FREE_PICKER = 'SWITCH_FREE_PICKER';
export const CHANGE_CURRENT_MONTH = 'CHANGE_CURRENT_MONTH';
export const CHANGE_SELECTED_DAY = 'CHANGE_SELECTED_DAY';
export const CHANGE_FLOAT_DAYS = 'CHANGE_FLOAT_DAYS';
export const CHANGE_STAY_DAYS = 'CHANGE_STAY_DAYS';
export const CHANGE_SELECTED_TIME_ITEM = 'CHANGE_SELECTED_TIME_ITEM';
export const CHANGE_COUNRTY_SOURCE = 'CHANGE_COUNRTY_SOURCE';
export const CHANGE_INPUT_STRING = 'CHANGE_INPUT_STRING';

export const FETCH_SERVICE_NOTICE_FAILURE = 'CHANNEL_FETCH_SERVICE_NOTICE_FAILURE';

// 地图搜搜
export const SET_SEARCH_MAP_DCITY = 'SET_SEARCH_MAP_DCITY';
export const SET_SEARCH_MAP_DDATE = 'SET_SEARCH_MAP_DDATE';
export const SET_SEARCH_MAP_ADATE = 'SET_SEARCH_MAP_ADATE';
export const SET_SEARCH_MAP_ACOUNTRY = 'SET_SEARCH_MAP_ACOUNTRY';
export const SET_SEARCH_MAP_FLIGHTWAY = 'SET_SEARCH_MAP_FLIGHTWAY';
export const SET_SEARCH_MAP_DIRECT_FLIGHT = 'SET_SEARCH_MAP_DIRECT_FLIGHT';
export const set_SEARCH_MAP_SEARCH_RESULT = 'SET_SEARCH_MAP_SEARCH_RESULT';
export const SET_SEARCH_MAP_FETCH_STATUS = 'SET_SEARCH_MAP_FETCH_STATUS';

// 低价速报
export const SET_LOW_PRICE_REPORT_SEARCH_RESULT = 'SET_LOW_PRICE_REPORT_SEARCH_RESULT';
export const SET_LOW_PRICE_REPORT_SEARCH_RESULT_LOADING_STATUS = 'SET_LOW_PRICE_REPORT_SEARCH_RESULT_LOADING_STATUS';
export const CLEAR_LOW_PRICE_REPORT_SEARCH_RESULT = 'CLEAR_LOW_PRICE_REPORT_SEARCH_RESULT';
