import Enum from "enum"

/**
 * 舱等
 */
export const FILTER_CLASS_GRADE_TYPE = new Enum({
    Y_S: {
        title: "经济/超级经济舱"
    },
    Y: {
        title: "经济舱"
    },
    S: {
        title: "超级经济舱"
    },
    C_F: {
        title: "公务/头等舱"
    },
    C: {
        title: "公务舱"
    },
    F: {
        title: "头等舱"
    },
    Y_S_C_F: {
        title: "不限舱等"
    }
})
