import { COUNTRY, PROVINCE, FILTER_TRANS_CITY_TYPE } from '@/src/constants/list/enums';
import getIfFlightAsLowPrice from '../getIfFlightAsLowPrice';

const getDefaultCityCodeItem = (cityCode, cityName, provinceId, countryName, countryKey) => {
	return {
		cityCode,
		cityName,
		provinceId,
		countryName,
		minAvgPriceWithTax: Infinity,
		minAvgPriceWithoutTax: Infinity,
		countryKey,
	};
};
/**
 * 获取航班所有中转和经停城市信息
 * @param {*航班集合} flights
 * @param {*当前航程顺序号} currentSegmentSeq
 */
const getAllTransAndStopCitiesOfFlights = (flights, currentSegmentSeq) => {
	const cityCodeOfFlightsMap = {};
	const ensureKeepMinPriceItem = (
		flight,
		cityCode,
		cityName,
		provinceId,
		countryName,
		countryKey,
		minAvgPriceWithoutTax,
		minAvgPriceWithTax
	) => {
		let theCityCodeItem = cityCodeOfFlightsMap[cityCode];

		if (!theCityCodeItem) {
			theCityCodeItem = cityCodeOfFlightsMap[cityCode] = getDefaultCityCodeItem(
				cityCode,
				cityName,
				provinceId,
				countryName,
				countryKey
			);
		}

		if (getIfFlightAsLowPrice(flight)) {
			if (minAvgPriceWithoutTax < theCityCodeItem.minAvgPriceWithoutTax) {
				theCityCodeItem.minAvgPriceWithoutTax = minAvgPriceWithoutTax;
			}

			if (minAvgPriceWithTax < theCityCodeItem.minAvgPriceWithTax) {
				theCityCodeItem.minAvgPriceWithTax = minAvgPriceWithTax;
			}
		}
	};

	flights.valueSeq().forEach((flight) => {
		let minAvgPriceWithTax = flight.getIn(['minPriceWithTax', 'avg']),
			minAvgPriceWithoutTax = flight.getIn(['minPriceWithoutTax', 'avg']),
			currentSegment = flight.getIn(['flightSegments', currentSegmentSeq]),
			hasStopOrTrans = currentSegment.get('transferAndStopCount') > 0,
			getCountryKey = (countryName, provinceId) =>
				countryName == COUNTRY.CN.value.name
					? provinceId === PROVINCE.TW.value.id
						? FILTER_TRANS_CITY_TYPE.TW.key
						: FILTER_TRANS_CITY_TYPE.MAIN_LAND_GW.key
					: countryName;

		if (hasStopOrTrans) {
			currentSegment
				.get('flightList')
				.valueSeq()
				.forEach((theFlight, theIndex) => {
					theFlight
						.get('stopList')
						.valueSeq()
						.forEach((stop) => {
							let countryName = stop.get('countryName'),
								provinceId = stop.get('provinceId'),
								cityCode = stop.get('cityCode'),
								cityName = stop.get('cityName'),
								countryKey = getCountryKey(countryName, provinceId);

							ensureKeepMinPriceItem(
								flight,
								cityCode,
								cityName,
								provinceId,
								countryName,
								countryKey,
								minAvgPriceWithoutTax,
								minAvgPriceWithTax
							);
						});

					if (theIndex > 0) {
						let countryName = theFlight.get('departureCountryName'),
							provinceId = theFlight.get('departureProvinceId'),
							cityCode = theFlight.get('departureCityCode'),
							cityName = theFlight.get('departureCityName'),
							countryKey = getCountryKey(countryName, provinceId);

						ensureKeepMinPriceItem(
							flight,
							cityCode,
							cityName,
							provinceId,
							countryName,
							countryKey,
							minAvgPriceWithoutTax,
							minAvgPriceWithTax
						);
					}
				});
		}
	});

	return cityCodeOfFlightsMap;
};

export default getAllTransAndStopCitiesOfFlights;
