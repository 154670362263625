export const syncFixSelectedFlight = (fixSplitFlights, selectFlightItineryId) => {
    const footerEl = document.querySelector('#base_ft')
    if (footerEl) {
        const footElDisplay = footerEl.style.display
        if (fixSplitFlights && !footElDisplay) {
            footerEl.style.display = 'none'
        }
        else if (!fixSplitFlights && footElDisplay === 'none') {
            footerEl.style.display = ''
        }
    }

    const footWrapper = document.querySelector('.footer-wrapper')
    if (footWrapper) {
        const footWrapperDisplay = footWrapper.style.display
        if (selectFlightItineryId && !footWrapperDisplay) {
            footWrapper.style.display = 'none'
        } else if (!selectFlightItineryId && footWrapperDisplay === 'none') {
            footWrapper.style.display = ''
        }
    }

    // 设置tooltip浮层的top，防止出现超长滚动条
    if (fixSplitFlights && selectFlightItineryId) {
        const tooltip = document.querySelector('.ToolTipPortal>div')
        if (tooltip) {
            tooltip.style.visibility = 'hidden'
            tooltip.style.top = '-1000px'
            tooltip.style.opacity = '0'
        }
    }
}

let leftSideTopBar, rightSideTopBar
let leftSideTopBarRect, rightSideTopBarRect

// 根据 坐标 判断当前是在 左侧航班区域，还是右侧航班区域
// 左侧：-1, -.5    右侧：1, .5   其他：0
const getIfInSplitFlightArea = (ev) => {
    if (leftSideTopBarRect && rightSideTopBarRect) {
        // 通过 topbar-v2 判断两侧航班的位置
        const { left: left1, right: right1, top: top1 } = leftSideTopBarRect
        const { left: left2, right: right2 } = rightSideTopBarRect
        const { clientX: x, clientY: y } = ev
        const middleOfHorizontal = right1 + (left2 - right1) / 2    // 左右空白中间区

        // 鼠标是否停留在左右分屏区
        const inLeftSideArea = x >= left1 && x <= middleOfHorizontal && y >= top1
        if (inLeftSideArea) {
            return x <= right1 ? -1 : -.5   // -1 表示左侧航班区域，-.5表示空白区域的左侧
        } else {
            const inRightSideArea = x >= middleOfHorizontal && x <= right2 && y >= top1
            return inRightSideArea ? (x >= left2 ? 1 : .5) : 0      // 1 表示右侧航班区域，.5表示空白区域的右侧
        }
    } else {
        return 0
    }
}

const refreshAreaRect = () => {
    leftSideTopBar = document.querySelector('.flight-list-container.left-side')
    leftSideTopBarRect = leftSideTopBar.getBoundingClientRect()

    rightSideTopBar = document.querySelector('.flight-list-container.right-side')
    rightSideTopBarRect = rightSideTopBar.getBoundingClientRect()
}

export const onWindowMouseMove = ev => {
    if (ev.target.getAttribute('id') === 'outerContainer-background-translucent') return

    if (!leftSideTopBar || !rightSideTopBar) {
        window.removeEventListener('resize', refreshAreaRect)
        window.addEventListener('resize', refreshAreaRect)

        refreshAreaRect()
    }

    if (leftSideTopBarRect && rightSideTopBarRect) {
        // 鼠标是否停留在左右分屏区
        const inSplitRoundArea = getIfInSplitFlightArea(ev) !== 0
        if (inSplitRoundArea) {
            hideBodyOverflow()
        } else {
            resumeBodyOverflow()
        }
    }
}

const HIDE_SCROLL_Y = 'hide-scroll-y'

const getBodyClass = () => document.body.getAttribute('class') || ''

const setBodyClass = (className) => document.body.setAttribute('class', className)

const ifContainsHideScrollYClass = (className) => className.indexOf(HIDE_SCROLL_Y) >= 0

export const hideBodyOverflow = () => {
    const bodyClass = getBodyClass()
    if (!ifContainsHideScrollYClass(bodyClass)) {
        setBodyClass([bodyClass, HIDE_SCROLL_Y].filter(Boolean).join(' '))
    }
}

export const resumeBodyOverflow = () => {
    const bodyClass = getBodyClass()
    if (ifContainsHideScrollYClass(bodyClass)) {
        setBodyClass(bodyClass.split(' ').filter(item => item && item !== HIDE_SCROLL_Y).join(' '))
    }
}