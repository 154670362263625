import Enum from 'enum';
import { sendTraceExpose } from '@/src/sources/common/lightUbt';
import { UBT_EVENT_TYPE } from '../common';
import { invokeCustomEvent } from './handler';
import { LIST_UBT_GROUP_TYPES } from './groupTypes';
import CONSTS from '../../constants/list/constConfig';
import { UbtHandler } from '../handler';
import { getForBestFlightsPostfix } from '../../constants/list/enums/getForBestFlightsPostfix';
import { DataRetriever } from './dataRetriver';
import { getPrevCondFromGlobal } from '../../actions/common';

// 此处是引用的而不是重新定义，给各种自定义事件的 process 中使用
const rawBasicDataFlow = LIST_UBT_GROUP_TYPES.BASIC_DATA_FLOW.value.rawData,
  rawCommonLogData = LIST_UBT_GROUP_TYPES.COMMON_LOG.value.rawData,
  rawCalendarData = LIST_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK.value.rawData,
  rawSearchDataStatistics = LIST_UBT_GROUP_TYPES.SEARCH_DATA_STATISTICS_SUBMIT.value.rawData,
  rawSnatchBookingShowData = LIST_UBT_GROUP_TYPES.SNATCH_BOOKING_SHOW.value.rawData,
  rawFlightListData = LIST_UBT_GROUP_TYPES.FLIGHT_LIST_FLOW.value.rawData,
  rawFlightListKey = LIST_UBT_GROUP_TYPES.FLIGHT_LIST_FLOW.value.group,
  rawFlightBookCountReason = LIST_UBT_GROUP_TYPES.BOOK_COUNT_REASON_CLICK.value.rawData;

const COMMON_LOG_MAX_ACTION_SIZE = CONSTS.UBT.COMMON_LOG_MAX_ACTION_SIZE,   // 最多纪录多少action.type 数量
  COMMON_LOG_RECORD_ACTION_TIME = CONSTS.UBT.COMMON_LOG_RECORD_ACTION_TIME;    // 是否同时上报时间
let VISA_FLIGHT_ID = [],
  RENDER_TRANSFER_AND_DIRECT_FLIGHT_ID = [];
/**
 * 确保某数据一定包含指定属性路径，比如确保 data 对象一定包含 data.p1.p2 ，如果属性不存在就初始化为 传入的默认对象
 * @param {待设置的数据} data
 * @param {待设置的数据，主要是考虑到空对象、空数组、数字0等情况} defaultValue
 * @param {根据顺序排序的属性名称，比如 'p1', p2' 就是确保 data 对象包含 data.p1.p2 属性} propsArray
 */
let ensureProp = (data, defaultValue, ...propsArray) => {
  // let thePrevData = data
  //
  // for (let index in propsArray) {
  //     let propName = propsArray[index]
  //     if (typeof thePrevData[propName] === 'undefined') {
  //         //最后一个属性值使用传入的参数，否则就用空对象
  //         thePrevData[propName] = (+index === propsArray.length - 1 ? defaultValue : {})
  //     }
  //
  //     thePrevData = thePrevData[propName]
  // }
  if (propsArray?.length) {
    propsArray.reduce((acc, cur, idx) => {
      if (!acc.hasOwnProperty(cur)) {
        Object.defineProperty(acc, cur, {
          writable: true,
          configurable: true,
          value: idx === propsArray.length - 1 ? defaultValue : {},
        });
      }
      return acc[cur];
    }, data);
  }
  return data;
};

let getActionDetail = (action) => {
  // let detail = {}
  // for (const key in action) {
  //     if (action.hasOwnProperty(key)) {
  //         const value = action[key]
  //         detail[key] = value
  //     }
  // }

  // return JSON.stringify(detail)

  //先上传简单信息
  return action.type;
};

export const LIST_UBT_KEY_TYPES = new Enum({
  COMMON_LOG: {
    //自定义上报的日志信息
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.COMMON_LOG.key, args);
    },
    process: (handler, source, ev = []) => {
      ensureProp(rawCommonLogData, [], 'actions');
      rawCommonLogData.transactionID = GlobalSearchCriteria.transactionID;

      if (ev.length) {
        let data = ev[0],
          type = ev[1];

        switch (type) {
          case 'action':
            if (rawCommonLogData.actions.length < COMMON_LOG_MAX_ACTION_SIZE) {
              let detail = getActionDetail(data);

              if (COMMON_LOG_RECORD_ACTION_TIME) {
                let now = new Date();
                rawCommonLogData.actions.push(`${detail}|${now.getHours()}:${now.getMinutes()}:${now.getSeconds()}`);
              } else {
                rawCommonLogData.actions.push(detail);
              }
            }
            break;
          default:
            Object.assign(rawCommonLogData, data);
            break;
        }
      }
    },
  },
  //基础业务信息的上报
  BASIC_DATA_FLOW: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.BASIC_DATA_FLOW.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length) {
        let flight = ev[0],
          priceList = flight.get('priceList'),
          oldTags = rawBasicDataFlow.tags,
          newTags = {
            Producttags: '',
            Servicetags: '',
            Restrictiontags: '',
          },
          oldProductTypeLabels = oldTags.Producttags ? oldTags.Producttags.split(',') : [],
          oldPriceTagLabels = oldTags.Servicetags ? oldTags.Servicetags.split(',') : [],
          oldRestrictLabels = oldTags.Restrictiontags ? oldTags.Restrictiontags.split(',') : [];

        priceList.forEach(price => {
          let currentProductTypeLabel = price.get('productTypeEnum').tag.value.rawLabel,
            currentPriceTagLabels = price.get('priceTagEnums').map(wrapper => wrapper.tag.value.rawLabel),
            currentRestrictLabels = price.get('restrictionList').map(tag => tag.get('label'));

          if (currentProductTypeLabel && oldProductTypeLabels.indexOf(currentProductTypeLabel) < 0) {
            newTags.Producttags = oldProductTypeLabels.concat(currentProductTypeLabel).join(',');
          }

          for (let i = 0; i < currentPriceTagLabels.size; i++) {
            let priceTagLabel = currentPriceTagLabels.get(i);
            if (priceTagLabel && oldPriceTagLabels.indexOf(priceTagLabel) < 0) {
              oldPriceTagLabels.push(priceTagLabel);
            }
          }
          newTags.Servicetags = oldPriceTagLabels.join(',');

          for (let i = 0; i < currentRestrictLabels.size; i++) {
            let restrictLabel = currentRestrictLabels.get(i);
            if (restrictLabel && oldRestrictLabels.indexOf(restrictLabel) < 0) {
              oldRestrictLabels.push(restrictLabel);
            }
          }
          newTags.Restrictiontags = oldRestrictLabels.join(',');

          rawBasicDataFlow.tags = newTags;
        });
      }
    },
  },
  //局部刷新
  SEARCH_WITHOUT_REFRESH: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    process: (handler, newRawData) => {
      const setRawData = (thePrevCond) => {
        var data = DataRetriever.getBasicDataWithoutInitFromPrevCond(thePrevCond);
        handler.rawData = Object.assign(handler.rawData, data);
      };

      setRawData(getPrevCondFromGlobal());
      UbtHandler.onUploadData(handler.groupEnum.value.group, handler.rawData);

      setRawData(newRawData);  // 更新groupEnum中的rawData为新拿到的prevCond
      return false;
    },
  },
  // 排序CLICK
  SORT_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      ensureProp(handler.rawData, {}, 'sort_click');

      let count = handler.rawData['sort_click'][handler.tag] || 0;
      handler.rawData['sort_click'][handler.tag] = count + 1;
    },
  },
  // 退改签的HOVER
  PENALTY_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`h_penalty`, {});
      return false;
    },
  },
  //服务包的HOVER
  SERVICE_PACKAGE_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`h_servicePackage`, {});
      return false;
    },
  },
  //春秋会员的HOVER
  SPRING_MEMBER_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`spring_member`, {});
      return false;
    },
  },
  //商务经济座的HOVER
  BUSINESS_ECONOMIC_SEAT_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`hover_business_economic_seat`, {});
      return false;
    },
  },
  //航班信息的HOVER
  FLIGHT_INFO_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`h_flight_info`, {});
      return false;
    },
  },
  //舒适度的HOVER
  FLIGHT_COMFORT_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`h_comfort_info`, {});
      return false;
    },
  },
  //NGS打分的HOVER
  NGS_SCORE_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (_handler, _source, ev) => {
      let score;

      const currentScoreEl = ev && ev.currentTarget && ev.currentTarget.querySelector('.current-score');
      if (currentScoreEl) {
        score = currentScoreEl.textContent;
      }

      UbtHandler.onUploadData(`h_ngs_score`, { score });
      return false;
    },
  },
  //推荐类航班，推荐位所在页面埋点
  RECOMMEND_FLIGHT_LOAD: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      let [Type, Order, _position, Sequence, OtherParams] = handler.tag,
        ubtData = {
          Type,
          Order,
          Sequence,
        };
      if (Object.keys(OtherParams).length > 0) {
        ubtData = Object.assign(ubtData, OtherParams);
      }
      if (!handler.rawData.Is_Crawler) {
        ensureProp(handler.rawData, {}, 'RecommendProduct');
        ensureProp(handler.rawData, [], 'RecommendProduct', 'RecommendInfo');
        ensureProp(handler.rawData['RecommendProduct'], '', 'IsShow');
        // let existOrderIndex = -1
        // handler.rawData['RecommendProduct'][position].forEach((data, index) => {
        //     if (data.Order === ubtData.Order && NON_EXCLUSIVE_RECOMMEND_TYPE.indexOf(ubtData.Type) < 0 && NON_EXCLUSIVE_RECOMMEND_TYPE.indexOf(data.Type)) {
        //         existOrderIndex = index
        //     } else if (data.Order === ubtData.Order && ubtData.Type === data.Type && NON_EXCLUSIVE_RECOMMEND_TYPE.indexOf(data.Type) >= 0) {
        //         existOrderIndex = index
        //     }
        // })
        // if (existOrderIndex >= 0) {
        //     handler.rawData['RecommendProduct'][position][existOrderIndex] = ubtData  //头部并列推荐位随着数据加载会被相互覆盖
        // } else {
        //     handler.rawData['RecommendProduct'][position].push(ubtData)
        // }
        handler.rawData['RecommendProduct']['IsShow'] = 'Y';
        if (!handler.rawData['RecommendProduct']['RecommendInfo'].some(recommend => recommend.Type == ubtData.Type
          && (!recommend.Sequence || !ubtData.Sequence || recommend.Sequence.every(seq => ubtData.Sequence.some(ubt => ubt.From == seq.From && ubt.To == seq.To && ubt.StartTime == seq.StartTime)))))
          handler.rawData['RecommendProduct']['RecommendInfo'].push(ubtData);
      }
    },
  },
  //进入列表页上报列表页来源
  RECOMMEND_FLIGHT_RESOURCE: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.RECOMMEND_FLIGHT_RESOURCE.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length && !rawBasicDataFlow.Is_Crawler) {
        ensureProp(rawBasicDataFlow, {}, 'RecommendProduct', 'SourceInfo');
        rawBasicDataFlow.RecommendProduct.SourceInfo = Object.assign(rawBasicDataFlow.RecommendProduct.SourceInfo, ev[0]);
      }
    },
  },
  //无结果推荐
  RECOMMEND_NO_RESULT: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.RECOMMEND_NO_RESULT.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length && !rawBasicDataFlow.Is_Crawler) {
        ensureProp(rawBasicDataFlow, {}, 'NoResultRecommend');
        rawBasicDataFlow.NoResultRecommend = Object.assign(rawBasicDataFlow.NoResultRecommend, ev[0]);
      }
    },
  },

  //TO DELTE [START]
  //两舱推荐埋点基础数据
  FC_REC_SY_INFO: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.FC_REC_SY_INFO.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length && !rawBasicDataFlow.Is_Crawler) {
        ensureProp(rawBasicDataFlow, {}, 'FC_rec_SY');
        rawBasicDataFlow.FC_rec_SY = Object.assign(rawBasicDataFlow.FC_rec_SY, ev[0]);
      }
    },
  },
  //两舱推荐展开收起埋点
  FC_REC_SY_FOLD_COUNT: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.FC_REC_SY_FOLD_COUNT.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length && !rawBasicDataFlow.Is_Crawler) {
        ensureProp(rawBasicDataFlow, {}, 'FC_rec_SY');
        rawBasicDataFlow.FC_rec_SY.Open = rawBasicDataFlow.FC_rec_SY.Open || 0;
        rawBasicDataFlow.FC_rec_SY.Close = rawBasicDataFlow.FC_rec_SY.Close || 0;
        if (ev[0])
          rawBasicDataFlow.FC_rec_SY.Open += 1;
        else
          rawBasicDataFlow.FC_rec_SY.Close += 1;
      }
    },
  },
  //TO DELTE [END]

  //两舱
  HIGH_CABIN_REC_EXPAND: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, source, _ev = []) => {
      ensureProp(handler.rawData, {}, 'FC_rec_SY');
      ensureProp(handler.rawData, 0, 'FC_rec_SY', 'Close');
      ensureProp(handler.rawData, 0, 'FC_rec_SY', 'Open');

      if (!handler.tag[0]) {
        handler.rawData.FC_rec_SY.Open = handler.rawData.FC_rec_SY.Open + 1;
      } else {
        handler.rawData.FC_rec_SY.Close = handler.rawData.FC_rec_SY.Close + 1;
      }

      sessionStorage.FC_rec_SY = JSON.stringify({ FC_rec_SY: handler.rawData.FC_rec_SY });
    },
  },

  //需求单
  SEARCH_NO_RESULT: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.SEARCH_NO_RESULT.key, args);
    },
    process: (handler, source, ev = []) => {
      if (ev.length && !rawBasicDataFlow.Is_Crawler) {
        rawBasicDataFlow.searchNoResult = ev[0];
      }
    },
  },
  //低价日历点击次数
  LOW_PRICE_CALENDAR_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      ensureProp(handler.rawData, 0, handler.tag);

      let count = handler.rawData[handler.tag] || 0;
      handler.rawData[handler.tag] = count + 1;
    },
  },
  //低价日历曝光
  LOW_PRICE_CALENDAR: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      let [head_show, month_type, week_type, is_calender_show, is_trend_show] = handler.tag,
        ubtData = {
          head_show,
          month_type,
          week_type,
          is_calender_show,
          is_trend_show,
        };
      if (!rawBasicDataFlow.Is_Crawler) {
        ensureProp(handler.rawData, {}, 'lp_calender');
        Object.keys(handler.rawData['lp_calender']).forEach(key => {
          if (!handler.rawData['lp_calender'][key]) {
            handler.rawData['lp_calender'][key] = ubtData[key];
          }
        });
      }
    },
  },
  //低价日历翻页,左翻
  LOW_PRICE_CALENDAR_MONTH_PREV_CLICK: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: () => {
      let count = rawCalendarData['c_leftmore_calender_search'] || 0;
      rawCalendarData['c_leftmore_calender_search'] = count + 1;
    },
  },
  //低价日历翻页,右翻
  LOW_PRICE_CALENDAR_MONTH_NEXT_CLICK: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: () => {
      let count = rawCalendarData['c_rightmore_calender_search'] || 0;
      rawCalendarData['c_rightmore_calender_search'] = count + 1;
    },
  },
  //日历选中
  LOW_PRICE_CALENDAR_ON_DATE_CAHNGE: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: () => {
      let count = rawCalendarData['c_select_price_search'] || 0;
      rawCalendarData['c_select_price_search'] = count + 1;
    },
  },
  //低价选中
  LOW_PRICE_CALENDAR_ON_LP_DATE_SELECT: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: () => {
      let count = rawCalendarData['c_select_lp_price_search'] || 0;
      rawCalendarData['c_select_lp_price_search'] = count + 1;
    },
  },

  //低价准确度埋点
  LOWEST_PRICE_COMPARE: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      let [lowest_list_price, is_tax, calendar_price, lowest_list_engine] = handler.tag,
        ubtData = {
          lowest_list_price,
          is_tax,
          calendar_price,
          lowest_list_engine,
        };
      if (!handler.rawData.Is_Crawler && lowest_list_price) {
        ensureProp(handler.rawData, {}, 'lowest_price_compare');
        handler.rawData['lowest_price_compare'] = ubtData;
      }
    },
  },
  RECOMMEND_INFO: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      let [ubtData] = handler.tag;

      if (!handler.rawData.Is_Crawler && ubtData) {
        ensureProp(handler.rawData, {}, 'RecommendProduct');
        ensureProp(handler.rawData['RecommendProduct'], [], 'RecommendInfo');
        if (!handler.rawData['RecommendProduct']['RecommendInfo'].some(recommon => recommon.value == ubtData.value)) {
          handler.rawData['RecommendProduct']['RecommendInfo'].push(ubtData);
        }
      }
    },
  },
  RECOMMEND_IS_SHOW: {
    listen: [UBT_EVENT_TYPE.LOAD, UBT_EVENT_TYPE.MOUSE_ENTER],
    process: (handler, _source, _ev) => {

      if (!handler.rawData.Is_Crawler) {
        ensureProp(handler.rawData, {}, 'RecommendProduct');
        ensureProp(handler.rawData['RecommendProduct'], '', 'IsShow');
        handler.rawData['RecommendProduct']['IsShow'] = _ev.type == 'onLoad' && handler.rawData['RecommendProduct']['IsShow'] != 'Y' ? 'N' : 'Y';
      }
    },
  },
  POI: {
    listen: UBT_EVENT_TYPE.CUSTOM,
    invoke: (...args) => {
      invokeCustomEvent(LIST_UBT_KEY_TYPES.POI.key, args);
    },
    process: (handler, source, ev = []) => {
      ensureProp(rawBasicDataFlow, {}, 'poi');

      //poi处理 可用
      if (ev.length) {
        rawBasicDataFlow.poi.errCount = ev[0];
      }
    },
  },
  //往返组合/自由搭配
  BUILD_UP_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      ensureProp(handler.rawData, {}, 'build_up_click');

      let count = handler.rawData['build_up_click'][handler.tag] || 0;
      handler.rawData['build_up_click'][handler.tag] = count + 1;
    },
  },
  /**
   * .赠跨城机场巴士
   */
  FREE_AIRPORT_BUS_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (handler, _source, _ev) => {
      if (!handler.tag || !handler.tag[0]) {
        return;
      }
      handler.rawData['content'] = handler.tag[0].content;
    },
  },
  //点击筛选项
  FILTER_ITEM_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      //c_filter_airline
      let { tag } = handler,
        filterItem = tag[0],
        filterKey = tag[4],
        title = tag[5],
        currentSegmentSeq = tag[6];

      if (filterItem.get('enable')) {
        let itemKey = filterItem.get('key'),     //可能是枚举值或者字符串
          itemKeyStr = filterKey || itemKey.key || itemKey,
          itemTitle = title || filterItem.get('titleForChoose'),  // 目前只有在 只看直飞 时候会传该参数，区别直接筛选 直飞
          fullGroupTypeKey = filterItem.get('groupType').value.id,  //所属分组
          groupTypeMatch = fullGroupTypeKey.match(/([a-zA-Z_]+)\.(.+)/),
          groupTypeKey = groupTypeMatch[1] || fullGroupTypeKey,
          subGroupTypeKey = groupTypeMatch[2] || fullGroupTypeKey,
          isRadio = tag[1],
          prevIsChecked = tag[2],
          activeItems = tag[3],
          activeKeys = [],

          nextIsChecked = isRadio ? true : !prevIsChecked,    //点击一个radio，永远都是使之处于选中状态
          subItems = filterItem.get('airlineList');     //目前只有航司联盟有联动子项关联

        activeItems.forEach(item => {
          activeKeys.push(item.key || item);
        });

        let updateToActiveKeys = (isChecked, key) => {
          let theIndexOfItem = activeKeys.indexOf(key);

          if (isChecked) {
            if (theIndexOfItem < 0) {
              activeKeys.push(key);
            }
          } else {
            if (theIndexOfItem >= 0) {
              activeKeys.splice(theIndexOfItem, 1);
            }
          }
        };

        updateToActiveKeys(nextIsChecked, itemKeyStr);
        if (subItems) {
          subItems.forEach(subItem => {
            updateToActiveKeys(nextIsChecked, subItem.key || subItem);
          });
        }

        UbtHandler.onUploadData(`c_filter_${groupTypeKey}`, {
          groupTypeKey: subGroupTypeKey,
          key: itemKeyStr,
          title: itemTitle,
          checked: nextIsChecked,
          activeKeys,
          currentSegmentSeq,
        });
      }
      return false;
    },
  },
  //点击筛选框清除全部
  FILTER_CLEAR_ALL: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (_handler, _source, _ev) => {
      //c_filter_clear_all
      UbtHandler.onUploadData(`c_filter_clear_all`, { title: '清除全部' });

      return false;
    },
  },
  //点击筛选分组中的清除，清掉该分组所有选中项
  FILTER_CLEAR_GROUP: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      //c_filter_airline
      let { tag } = handler,
        filterType = tag[0];

      UbtHandler.onUploadData(`c_filter_clear:${filterType.key}`, { title: filterType.value.title });

      return false;
    },
  },
  //通程航班描述文案显示就上报
  TONGCHENG_REMARK: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      //c_tongcheng_remark

      delete handler.rawData.__skip__;
      handler.rawData.value = true;
    },
  },
  //购票攻略，load触发
  GUIDE_LINE_LOAD: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      //c_guide_line

      const { tag } = handler;

      if (tag) {
        const key = tag[0],
          value = tag[1];

        if (key) {
          if (!handler.rawData.price_predict) {
            handler.rawData.price_predict = {};
          }

          handler.rawData.price_predict[key] = value;
        }
      }
    },
  },
  //购票攻略，click触发
  GUIDE_LINE_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      //c_guide_line

      const { tag } = handler;

      if (tag) {
        const key = tag[0];

        if (key) {
          if (!handler.rawData.price_predict) {
            handler.rawData.price_predict = {};
          }

          if (!handler.rawData.price_predict[key]) {
            handler.rawData.price_predict[key] = 0;
          }

          handler.rawData.price_predict[key] = handler.rawData.price_predict[key] + 1;
        }
      }
    },
  },
  // 单程推荐往返，load
  OW_RECOMMEND_RT_LOAD: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, source, _ev) => {
      const key = handler;
      if (key) {
        if (!rawBasicDataFlow.ow_recommend_rt) {
          rawBasicDataFlow.ow_recommend_rt = {};
        }
        if (!rawBasicDataFlow.ow_recommend_rt[key]) {
          rawBasicDataFlow.ow_recommend_rt[key] = source;
        } else {
          rawBasicDataFlow.ow_recommend_rt[key] = source;

        }
      }
    },
  },
  OW_RECOMMEND_RT_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler;

      if (key) {
        if (key) {
          if (!rawBasicDataFlow.ow_recommend_rt) {
            rawBasicDataFlow.ow_recommend_rt = {};
          }

          if (!rawBasicDataFlow.ow_recommend_rt[key]) {
            rawBasicDataFlow.ow_recommend_rt[key] = 0;
          }
          rawBasicDataFlow.ow_recommend_rt[key] = rawBasicDataFlow.ow_recommend_rt[key] + 1;
        }
      }
    },
  },
  // 点击查询触发
  SEARCH_DATA_SUBMIT: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      try {
        let { selectedCity, classGrade, adultCnt, childCnt, infantCnt, flightWay, owDDate, rtDDate, rtADate, isDistrictSearch, arrivalDistrict } = handler;
        let getcitySource = function(city) {
          if (city.get('source') == '1' || city.get('source') == '2') {
            return city.get('source');
          }
          if (city && city.get('POIID')) {
            return '2';
          }
          return '3';

        };
        let parseUbtCityItem = (city) => {
          return {
            'CityID': city.get('CityId'),
            'CityCode': city.get('Code'),
            'CityName': city.get('text'),
            source: getcitySource(city),
          };
        };
        let ubtFlightWay = '';
        let ubtData = {
          'triggertype': 'Action',
          'iscountry': isDistrictSearch ? 'Y' : 'N',
          'flightclass': 'I',
          'flightway': flightWay,
          'class': classGrade.replace('_', ''),
          'passengertype': {
            'adult': adultCnt,
            'children': childCnt,
            'infant': infantCnt,
          },
          'Sequence': [],
        };
        let selectedCountryUbt = {};
        if (isDistrictSearch) {
          selectedCountryUbt = {
            'CityCode': arrivalDistrict.areaCode,
            'CityName': arrivalDistrict.areaName,
            'source': arrivalDistrict.source ? arrivalDistrict.source : '3',
          };
        }
        switch (flightWay) {
          case 'OW':
            let owSelectedDCity = selectedCity.get('owDCity');
            let owSelectedACity = selectedCity.get('owACity');
            ubtFlightWay = 'S';
            ubtData.Sequence.push({
              'From': parseUbtCityItem(owSelectedDCity),
              'To': isDistrictSearch ? selectedCountryUbt : parseUbtCityItem(owSelectedACity),
              'StartTime': owDDate && owDDate.format && owDDate.format('YYYY-MM-DD'),
            });
            break;
          case 'RT':
            let rtSelectedDCity = selectedCity.get('rtDCity');
            let rtSelectedACity = selectedCity.get('rtACity');
            ubtFlightWay = 'D';
            ubtData.Sequence.push({
              'From': parseUbtCityItem(rtSelectedDCity),
              'To': isDistrictSearch ? selectedCountryUbt : parseUbtCityItem(rtSelectedACity),
              'StartTime': rtDDate && rtDDate.format && rtDDate.format('YYYY-MM-DD'),
            });
            ubtData.Sequence.push({
              'From': isDistrictSearch ? selectedCountryUbt : parseUbtCityItem(rtSelectedACity),
              'To': parseUbtCityItem(rtSelectedDCity),
              'StartTime': rtADate && rtADate.format && rtADate.format('YYYY-MM-DD'),
            });
            break;
          case 'MT':
            ubtFlightWay = 'M';
            selectedCity.forEach((value, key) => {
              if (key.indexOf('mtDCity') == 0) {
                let index = parseInt(key.substr(key.length - 1) - 1);
                if (!ubtData.Sequence[index]) {
                  ubtData.Sequence[index] = {};
                }
                if (!ubtData.Sequence[index]['From']) {
                  ubtData.Sequence[index]['From'] = {};
                }
                ubtData.Sequence[index]['From'] = parseUbtCityItem(value);
              }
              if (key.indexOf('mtACity') == 0) {
                let index = parseInt(key.substr(key.length - 1) - 1);
                if (!ubtData.Sequence[index]) {
                  ubtData.Sequence[index] = {};
                }
                if (!ubtData.Sequence[index]['To']) {
                  ubtData.Sequence[index]['To'] = {};
                }
                ubtData.Sequence[index]['To'] = parseUbtCityItem(value);
              }
              if (key.indexOf('mtStartDate') == 0) {
                let index = parseInt(key.substr(key.length - 1) - 1);
                if (!ubtData.Sequence[index]) {
                  ubtData.Sequence[index] = {};
                }
                if (!ubtData.Sequence[index]['StartTime']) {
                  ubtData.Sequence[index]['StartTime'] = {};
                }
                ubtData.Sequence[index]['StartTime'] = value.format('YYYY-MM-DD');
              }
            });

            break;
        }
        ubtData.flightway = ubtFlightWay;
        ubtData.sign = '';
        UbtHandler.onUploadData(`flt_oversea_homepage_online_basic`, ubtData);

      } catch (err) {
        console.log(err);
      }

      return false;
    },

  },
  //精选航班，离开页面触发，此处记录统计数据
  BEST_CHOICE_FLIGHTS: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, flights, _ev) => {
      try {
        if (flights) {    //所有航班中找出精选航班，并记录他们位置
          const tags = handler.rawData.tags_flt;
          tags.splice(0, tags.length);
          const flightsOfBestChoice = flights.filter(flight => flight.get('itineraryTagEnums').some(theEnum => theEnum.tag.value.ofBestChoiceFlight));
          if (flightsOfBestChoice.size) {
            flightsOfBestChoice.valueSeq().forEach(flight => {
              const bestChoiceItinerary = flight.get('itineraryTagEnums').find(theEnum => theEnum.tag.value.ofBestChoiceFlight);
              if (bestChoiceItinerary) {
                const firstFlight = flight.get('flightSegments').first().get('flightList').first(),
                  lastFlight = flight.get('flightSegments').last().get('flightList').last();

                tags.push({
                  title: bestChoiceItinerary.tag.value.title + getForBestFlightsPostfix(bestChoiceItinerary, { emptyIfHide: false }),
                  ariline: flight.get('itineraryId'),
                  dcitycode: firstFlight.get('departureCityCode'),
                  acitycode: lastFlight.get('arrivalCityCode'),
                  StartTime: firstFlight.get('departureDateTime'),
                  EndTime: lastFlight.get('arrivalDateTime'),
                  index: flights.indexOf(flight),
                });

              }
            });
          }

          while (tags.length > 20) {
            const maxIndex = tags.map(tag => tag.index).sort()[tags.length - 1];
            const flightIndexOfMaxIndex = tags.findIndex(tag => tag.index === maxIndex);
            tags.splice(flightIndexOfMaxIndex, 1);
          }
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  //精选航班，HOVER标签的统计
  BEST_CHOICE_FLIGHTS_HOVER: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (handler) => {
      try {
        const tagName = handler.tag ? handler.tag[0] : '';
        if (tagName) {
          const tagsHover = handler.rawData.tags_hover;
          if (!tagsHover[tagName]) {
            tagsHover[tagName] = 0;
          }

          tagsHover[tagName]++;
        }
      } catch (err) {
        console.error(err);
      }
    },
  },
  // 二维码低价提醒点击上报
  RECOMMEND_QRCODE_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`c_int_lowprice_card_click`, {});
    },
  },
  // 二维码低价提醒曝光上报
  RECOMMEND_QRCODE_LOAD: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (_handler, _source, _ev) => {
      UbtHandler.onUploadData(`c_int_lowprice_card_show`, {});
    },
  },
  //东航品牌运价，load触发
  BRAND_PRICE_LOAD: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      const { tag } = handler;

      if (tag) {
        const [priceSummary] = tag;

        if (priceSummary) {
          if (!handler.rawData.showChoose) {
            handler.rawData.showChoose = {};
          }

          if (!handler.rawData.showChoose[priceSummary]) {
            handler.rawData.showChoose[priceSummary] = 1;
            UbtHandler.onUploadData(`c_brand_price_show_choose`, { priceSummary });
          }
        }
      }

      return false;
    },
  },
  //东航品牌运价，click运价折叠/收缩的触发
  BRAND_PRICE_EXPAND_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const { tag } = handler;

      if (tag) {
        const [expand, priceSummary] = tag;

        if (priceSummary) {
          if (expand) {
            UbtHandler.onUploadData(`c_brand_price_expand`, { priceSummary });
          } else {
            UbtHandler.onUploadData(`c_brand_price_collapse`, { priceSummary });
          }
        }
      }

      return false;
    },
  },
  //3选X，click运价折叠/收缩的触发
  CHOOSE_X_PRICE_EXPAND_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const { tag } = handler;

      if (tag) {
        const [expand, priceSummary] = tag;

        if (priceSummary) {
          if (expand) {
            UbtHandler.onUploadData(`c_choose_x_price_expand`, { priceSummary });
          } else {
            UbtHandler.onUploadData(`c_choose_x_price_collapse`, { priceSummary });
          }
        }
      }

      return false;
    },
  },
  //东航品牌运价，hover退改签时候触发
  BRAND_PRICE_HOVER_PENALTY: {
    listen: UBT_EVENT_TYPE.MOUSE_ENTER,
    process: (handler, _source, _ev) => {
      const { tag } = handler;

      if (tag) {
        const [attrs, routeSearchToken] = tag;

        if (attrs) {
          UbtHandler.onUploadData(`c_brand_price_hover_penalty`, { attrs, token: routeSearchToken });
        }
      }

      return false;
    },
  },
  SEARCH_DATA_STATISTICS_SUBMIT: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const name = handler;
      if (name == 'submit') {
        for (const key1 in rawSearchDataStatistics) {
          if (key1.indexOf('City') >= 0) {
            // 城市选择会触发两次
            rawSearchDataStatistics[key1] = rawSearchDataStatistics[key1] && Math.ceil(rawSearchDataStatistics[key1] / 2);
          }
        }
        UbtHandler.onUploadData(`flt_oversea_search_statistics`, rawSearchDataStatistics);
        for (const key in rawSearchDataStatistics) {
          rawSearchDataStatistics[key] = 0;
        }
      } else {
        if (!rawSearchDataStatistics[name]) {
          rawSearchDataStatistics[name] = 1;
        } else {
          rawSearchDataStatistics[name] += 1;
        }
      }

    },
  },
  SELECTED_FLIGHTS: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [flightWay, cleanItineraryId, index] = handler.tag;

      UbtHandler.onUploadData(key, { flightWay, flightNo: cleanItineraryId, index });
      return false;
    },
  },
  MATRIX_TOGGLE: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [showAirlineMatrix, flightWay, currentSegmentSeq] = handler.tag;

      UbtHandler.onUploadData(key, { showAirlineMatrix, flightWay, currentSegmentSeq });
      return false;
    },
  },
  MATRIX_ITEM: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [flightWay, currentSegmentSeq, type, value] = handler.tag;

      UbtHandler.onUploadData(key, { flightWay, currentSegmentSeq, type, value });
      return false;
    },
  },
  FLIGHT_DETAIL: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [flightWay, currentSegmentSeq] = handler.tag,
        flightWayDict = { OW: 'S', RT: 'D', MT: 'M' };

      UbtHandler.onUploadData(key, { flightWay: flightWayDict[flightWay], currentSegmentSeq });
      return false;
    },
  },
  SUB_AIRPORT: {
    listen: [UBT_EVENT_TYPE.LOAD, UBT_EVENT_TYPE.CLICK],
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [flightWay] = handler.tag;

      UbtHandler.onUploadData(key, { type: _ev.type || 'onLoad', flightWay });
      return false;
    },
  },
  SORT_CLICK_NOW: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [sortKey, currentSegmentSeq] = handler.tag;

      UbtHandler.onUploadData(key, { sortKey, currentSegmentSeq });
      return false;
    },
  },
  FLIGHT_CARD_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group,
        [value] = handler.tag;
      if (value && value.clickBookingBeforeSearchIsFinished) {
        UbtHandler.onUploadData('c_first_flight_seg_before_finished', { clickBookingBeforeSearchIsFinished: true });
        delete value.clickBookingBeforeSearchIsFinished;
      }
      UbtHandler.onUploadData(key, value);

      return false;
    },
  },
  FIRST_SEG_FLIGHT_CARD_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      let [value] = handler.tag;
      if (value && value.clickBookingBeforeSearchIsFinished) {
        UbtHandler.onUploadData('c_first_flight_seg_before_finished', value);
      }
      return false;
    },
  },
  RESULT_INFO: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      let [resultInfo = {}, sourceInfo] = handler.tag;
      for (let key in resultInfo) {
        if (resultInfo.hasOwnProperty(key)) {
          ensureProp(handler.rawData, '', key);
          handler.rawData[key] = resultInfo[key];
        }
      }
      if (sourceInfo) {
        ensureProp(handler.rawData, {}, 'SourceInfo');
        handler.rawData['SourceInfo'] = sourceInfo;
      }
    },
  },
  // 列表航班曝光
  FLIGHT_ITEM_SHOW: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      const { tag } = handler;
      if (tag) {
        const [showTraceData] = tag;

        if (showTraceData) {
          if (!handler.rawData.showChoose) {
            handler.rawData.showChoose = {};
          }

          if (!handler.rawData.showChoose[JSON.stringify(showTraceData)]) {
            handler.rawData.showChoose[JSON.stringify(showTraceData)] = 1;
            sendTraceExpose('O_FLT_Online_Liebiaoye', { ...showTraceData })
          }
        }
      }

      return false;
    },
  },
  BACK_TO_SEARCH_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      return false;
    },
  },
  REQUIREMENT_FORM_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      return false;
    },
  },
  BOOK_BUTTON_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      // 点击了抢按钮
      if (handler.tag && handler.tag[0] && handler.tag[0].rapidBook) {
        UbtHandler.onUploadData('c_int_list_rob_click', {});
      }
      if (handler.tag && handler.tag[1] && handler.tag[1].isSpringMember) {
        UbtHandler.onUploadData('c_int_list_spring_member_click', {});
      }
      return false;
    },
  },
  FLIGHT_DETAIL_CLICK_NOW_UPLOAD: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      return false;
    },
  },
  // 往返分屏点击往返组合，点击就上报
  SPLIT_ROUND_CLICK_BUILD_UP: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      return false;
    },
  },
  // 往返分屏点击自由搭配，点击就上报
  SPLIT_ROUND_CLICK_FREE_STYLE: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      const key = handler.groupEnum.value.group;
      UbtHandler.onUploadData(key, {});
      return false;
    },
  },
  SNATCH_BOOKING_SHOW: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      if (typeof handler == 'boolean') {
        rawSnatchBookingShowData.show = handler;
      }
    },
  },
  // 点击就上报
  TRIGGER_NOW_CLICK: {
    listen: UBT_EVENT_TYPE.CLICK,
    process: (handler, _source, _ev) => {
      if (handler.tag) {
        const key = handler.tag[0];
        if (key) {
          UbtHandler.onUploadData(key, {});
        }
      }

      return false;
    },
  },
  FLIGHT_LIST_FLOW: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      if ((handler.transferAndStopCount || handler.transferAndStopCount == 0) && handler.cleanItineraryId) {
        if (RENDER_TRANSFER_AND_DIRECT_FLIGHT_ID.indexOf(handler.cleanItineraryId) < 0) {
          let { transferAndStopCount, cleanItineraryId } = handler;

          if (transferAndStopCount == 0) {
            rawFlightListData.showDirectFlightCount++;
          } else {
            rawFlightListData.showTransferFlightCount++;
          }
          RENDER_TRANSFER_AND_DIRECT_FLIGHT_ID.push(cleanItineraryId);
        }

      } else if (handler.tag && handler.tag[0] && handler.tag[0].leakedVisaTagTypeCode && handler.tag[0].cleanItineraryId) {
        let { leakedVisaTagTypeCode, cleanItineraryId } = handler.tag[0];
        if (VISA_FLIGHT_ID.indexOf(cleanItineraryId) < 0) {
          if (leakedVisaTagTypeCode == 'NEED_VISA') {
            rawFlightListData.showVisaFlightCount++;
          } else if (leakedVisaTagTypeCode == 'NOT_NEED_VISA') {
            rawFlightListData.showNoVisaFlightCount++;
          }
          VISA_FLIGHT_ID.push(cleanItineraryId);

        }
      } else if (handler.totalTransferFlightCount) {
        rawFlightListData.totalTransferFlightCount++;
      } else if (handler.totalDirectFlightCount) {
        rawFlightListData.totalDirectFlightCount++;
      }
      if (handler.resetData) {

        for (const key in handler.resetData) {
          if (handler.resetData.hasOwnProperty(key)) {
            rawFlightListData[key] = handler.resetData[key];
          }
        }
        VISA_FLIGHT_ID = [];
        RENDER_TRANSFER_AND_DIRECT_FLIGHT_ID = [];

      }
      if (handler.tag && handler.tag.upload) {
        UbtHandler.onUploadData(rawFlightListKey, rawFlightListData);
        VISA_FLIGHT_ID = [];
        RENDER_TRANSFER_AND_DIRECT_FLIGHT_ID = [];
        rawFlightListData.totalDirectFlightCount = 0;
        rawFlightListData.totalTransferFlightCount = 0;
        rawFlightListData.showDirectFlightCount = 0;
        rawFlightListData.showTransferFlightCount = 0;
        rawFlightListData.showVisaFlightCount = 0;
        rawFlightListData.showNoVisaFlightCount = 0;
        rawFlightListData.segmemtNo = 0;
        rawFlightListData.flightWay = '';
      }

    },

  },
  FLIGHT_BOOK_COUNT_REASON: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: (handler, _source, _ev) => {
      if (handler.tag) {
        rawFlightBookCountReason.totalClickCount = handler.tag.length;
        rawFlightBookCountReason.reasons = handler.tag;
      }
    },

  },
  AD_BAR_SHOW: {
    listen: UBT_EVENT_TYPE.LOAD,
    process: ({ type, ad }) => {
      let data = rawBasicDataFlow['ad_bar'][type];
      if (!data) {
        data = [];
      }
      let repeat = data.some(item => JSON.stringify(item) == JSON.stringify(ad));
      if (!repeat) {
        data.push(ad);
      }

    },
  },
});
