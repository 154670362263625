import React from 'react';
import { NOTICE_LEVEL } from '../../../constants/list/enums/notice';

const NoticeList = ({ noticeList, showDialog }) => {
  const noticeListSort = noticeList.sort((t1, t2) => {
    return t1.get('contentRank') - t2.get('contentRank');
  });
  const noticeListJS = noticeListSort.toJS();

  const showLvl = noticeListJS[0]?.contentRank;

  //   const [fold, setFold] = useState(true);
  const contentRank = ['FIRSTCLASS', 'SECONDCLASS', 'THIRDCLASS'][showLvl];
  const len = noticeListJS.length;
  const imgSrc =
    {
      FIRSTCLASS: 'https://pic.c-ctrip.com/flight_intl/book/svgs/red.svg',
      SECONDCLASS: 'https://pic.c-ctrip.com/flight_intl/book/svgs/orange.svg',
      THIRDCLASS: 'https://pic.c-ctrip.com/flight_intl/book/svgs/green.svg',
    }[contentRank] || '';

  return (
    <div className={`${NOTICE_LEVEL[contentRank]?.value.divClass}`} onClick={() => showDialog()}>
      <div className="content">
        {len > 1 ? (
          <div className="notice-banner">
            <img className="notice_icon" src={imgSrc} />
            <strong className="notice-type">出行提醒: {len}条公告</strong>
            {noticeList.map((message, index) => (
              <React.Fragment key={index}>
                <span>·{message.get('title')} </span>
              </React.Fragment>
            ))}
          </div>
        ) : (
          <div className="notice-banner">
            <img className="notice_icon" src={imgSrc} />
            <strong className="notice-type">出行提醒: </strong>
            {noticeList.map((message, index) => (
              <React.Fragment key={index}>
                <div>
                  ·{message.get('title')}: {message.get('content')}
                </div>
              </React.Fragment>
            ))}
          </div>
        )}
      </div>
      <i className="iconf-arrow-down iconf-down fold-ctrl">&#xe60c;</i>
    </div>
  );
};

export default NoticeList;
