import React, { useEffect, useState } from 'react';
import classnames from 'classnames';
import ReactToolTip from '@/src/components/Base/reactToolTip';
import Tooltip from './tooltip';
import { getVisaLuggageDetail } from '../../../actions/list/visa';
import { PRODUCT_CATEGORY_TAG_TYPE } from '../../../constants/list/enums';
import { UbtBoundary, ListUbtGenerator, LIST_UBT_GROUP_TYPES, LIST_UBT_KEY_TYPES } from '../../../ubt.v2/list';

export const getVisaLuggageDirectInfoKey = ({ segment, priceList, isCheaperPlusAnotherTrip }) => {
	const transferAndStopCount = segment.get('transferAndStopCount');
	if (transferAndStopCount) {
		const luggageVisaKeyList: any[] = [];
		let singlePU = priceList.filter(
			(price) => price.get('productTypeEnum').tag !== PRODUCT_CATEGORY_TAG_TYPE.TransitPU,
		);

		if (isCheaperPlusAnotherTrip) {
			// 多飞一程一定是单PU
			singlePU = priceList.filter((price) => {
				const priceTagEnums = price.get('priceTagEnums');
				return priceTagEnums.some((priceTagEnum) => priceTagEnum.tag.value.isCheaperPlusAnotherTrip);
			});
		} else {
			singlePU = priceList.filter(
				(price) => price.get('productTypeEnum').tag !== PRODUCT_CATEGORY_TAG_TYPE.TransitPU,
			);
			priceList.forEach((price) => {
				if (
					price.get('productTypeEnum').tag == PRODUCT_CATEGORY_TAG_TYPE.TransitPU &&
					price.get('luggageVisaKey')
				)
					luggageVisaKeyList.push(price.get('luggageVisaKey'));
			});
		}
		if (singlePU.size > 0) {
			singlePU.getIn([0, 'luggageVisaKey']) && luggageVisaKeyList.push(singlePU.getIn([0, 'luggageVisaKey']));
		}

		return luggageVisaKeyList;
	}
	return [];
};

// 批量获取过境签
export const BatchGetVisas = ({ flightDetailInfos, onPopupFlightDetail, currentSegmentSeq, cleanItineraryId }) => {
	if (flightDetailInfos.length) {
		const visaPromises = flightDetailInfos.map((flightInfo) => {
			const { segment, priceList, isCheaperPlusAnotherTrip } = flightInfo;
			const segmentNo = segment.get('segmentNo');
			const theVisaLuggageDirectInfoKey = getVisaLuggageDirectInfoKey({
				segment,
				priceList,
				isCheaperPlusAnotherTrip,
			});
			const theVisaLuggageDetailPromise = getVisaLuggageDetail(theVisaLuggageDirectInfoKey, segmentNo);
			if (theVisaLuggageDirectInfoKey.length > 0) {
				return theVisaLuggageDetailPromise;
			}
			return new Promise((resolve) => {
				resolve({});
			});
		});
		return Promise.all(visaPromises)
			.then((visas) => {
				const newFlightDetailInfos = visas.map((visa, index) => {
					return { ...flightDetailInfos[index], visaLuggage: visa };
				});
				onPopupFlightDetail({ flightDetailInfos: newFlightDetailInfos, currentSegmentSeq, cleanItineraryId });
			})
			.catch(() => {
				onPopupFlightDetail({ flightDetailInfos, currentSegmentSeq, cleanItineraryId });
			});
	} else {
		console.warn('flightDetailInfos is a array');
		return null;
	}
};

export const FlightDetailFragment = ({
	cleanItineraryId,
	flightWay,
	currentSegmentSeq,
	splitRoundFlightsSwitch,
	onFlightDetailClick,
}) => {
	return (
		<UbtBoundary
			tracker={ListUbtGenerator(
				LIST_UBT_GROUP_TYPES.FLIGHT_DETAIL,
				LIST_UBT_KEY_TYPES.FLIGHT_DETAIL,
				flightWay,
				currentSegmentSeq,
			)}
		>
			<div
				u_key="flight_detail"
				u_remark={`itineraryId:${cleanItineraryId}`}
				className={classnames({ 'flight-detail-toggle': true, detail: splitRoundFlightsSwitch })}
				id={`flightDetail-${cleanItineraryId}`}
				onClick={(ev) => {
					onFlightDetailClick && onFlightDetailClick();
					ev.stopPropagation();
				}}
			>
				<a
					href="javascript:;"
					id={`flight-detail-link-${cleanItineraryId}`}
					className="link"
					title="点击查看详情"
				>
					航班详情
				</a>
			</div>
		</UbtBoundary>
	);
};

export const FlightSummaryFragment = ({ onFlightDetailClick }) => {
	return (
		<a
			href="javascript:;"
			className="apt-detail"
			onClick={() => {
				onFlightDetailClick && onFlightDetailClick();
			}}
		>
			航班详情
		</a>
	);
};

type VisaTagProps = {
	theVisaLuggageDetailPromise: Promise<any> | undefined;
	cleanItineraryId: string;
};

export const VisaTag = ({ theVisaLuggageDetailPromise, cleanItineraryId }: VisaTagProps) => {
	const [visa, serVisa] = useState<any>();

	useEffect(() => {
		theVisaLuggageDetailPromise &&
			theVisaLuggageDetailPromise.then((visa) => {
				serVisa(visa);
			});
	}, [theVisaLuggageDetailPromise]);

	return visa?.tagName ? (
		<React.Fragment>
			{visa.visaToolTip.length ? (
				<ReactToolTip
					group="flightInfo"
					parentId={`flightInfo-${cleanItineraryId}`}
					key={`flightInfo-visa-${cleanItineraryId}`}
					toolTipContent={<Tooltip extClass={'tooltip-visa'}>{visa.visaToolTip}</Tooltip>}
				/>
			) : null}
			<UbtBoundary
				tracker={ListUbtGenerator(LIST_UBT_GROUP_TYPES.FLIGHT_LIST_FLOW, LIST_UBT_KEY_TYPES.FLIGHT_LIST_FLOW, {
					leakedVisaTagTypeCode: visa.leakedVisaTagTypeCode,
					cleanItineraryId,
				})}
			>
				<div className="remind">{visa.tagName}</div>
			</UbtBoundary>
		</React.Fragment>
	) : null;
};

export class VisaFragment {
	theVisaLuggageDetailPromise: Promise<any> | undefined;
	theVisaLuggageDirectInfoKey: any[];
	flightDetailInfo: Promise<any>;
	flightDetail: JSX.Element;
	constructor(props) {
		const {
			segment,
			priceList,
			cleanItineraryId,
			currentSegmentSeq,
			onPopupFlightDetail,
			flightWay,
			title,
			extTitle,
			extFooter,
			headerClassName,
			tongChengFlightEnum,
			splitRoundFlightsSwitch,
			showTongChengRemark,
			onFooterBtnClick,
		} = props;
		const segmentNo = segment.get('segmentNo');
		const theVisaLuggageDirectInfoKey = (this.theVisaLuggageDirectInfoKey = getVisaLuggageDirectInfoKey({
			segment,
			priceList,
			isCheaperPlusAnotherTrip: undefined,
		}));
		const theVisaLuggageDetailPromise = (this.theVisaLuggageDetailPromise = getVisaLuggageDetail(
			theVisaLuggageDirectInfoKey,
			segmentNo,
		));

		const theFlightDetailThen = (visa, parentPosition) => {
			return onPopupFlightDetail(
				tongChengFlightEnum,
				segment,
				priceList,
				currentSegmentSeq,
				`flightDetail${cleanItineraryId}`,
				showTongChengRemark,
				visa,
				title,
				extTitle,
				extFooter,
				headerClassName,
				parentPosition,
				onFooterBtnClick,
			);
		};

		this.flightDetailInfo = new Promise((resolve, reject) => {
			const detailInfo = {
				tongChengFlightEnum,
				segment,
				priceList,
				currentSegmentSeq,
				showTongChengRemark,
				title,
				extTitle,
				extFooter,
				headerClassName,
				cacheKey: `flightDetail${cleanItineraryId}`,
			};
			if (theVisaLuggageDirectInfoKey.length > 0) {
				theVisaLuggageDetailPromise
					?.then((visa) => {
						resolve({
							...detailInfo,
							visaLuggage: visa.visaLuggage,
						});
					})
					.catch((e) => reject(e));
			} else {
				resolve(detailInfo);
			}
		});
		const getFlightDetailClick = () => {
			if (theVisaLuggageDirectInfoKey.length > 0) {
				theVisaLuggageDetailPromise?.then((visa) => theFlightDetailThen(visa));
			} else {
				theFlightDetailThen({});
			}
		};
		this.flightDetail = FlightDetailFragment({
			cleanItineraryId,
			flightWay,
			currentSegmentSeq,
			splitRoundFlightsSwitch,
			onFlightDetailClick: getFlightDetailClick,
		});
		this.CheaperPlusAnotherTripDetail = (
			<div
				className="flt-hover"
				onClick={() => {
					getFlightDetailClick();
				}}
			>
				航班详情/入境须知
			</div>
		);

		this.flightSummaryDetail = FlightSummaryFragment({ onFlightDetailClick: getFlightDetailClick });
		this.getVisaLuggageDetailPromise = () => {
			if (theVisaLuggageDirectInfoKey.length > 0) {
				return theVisaLuggageDetailPromise;
			}
			return new Promise((resolve) => {
				resolve({});
			});
		};
	}
}
