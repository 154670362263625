export function getIEVersion(userAgent?: string) {
	const agent = (userAgent || window.navigator.userAgent).toLowerCase();
	return agent.indexOf('msie') !== -1 ? parseInt(agent.split('msie')[1]) : false;
}

export function ifFireFox(userAgent?: string) {
	const agent = (userAgent || window.navigator.userAgent).toLowerCase();
	return agent.indexOf('firefox') >= 0;
}

export function ifIE() {
	if (!!window.ActiveXObject || 'ActiveXObject' in window) {
		return true;
	} else return false;
}
