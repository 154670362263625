import React, { useMemo } from 'react';
import Image, { ImageWrapProps } from '@/src/components/ImageWrap';

type AirlineLogoSize = '32' | '48' | '72';

type AirlineLogoProps = Override<
	ImageWrapProps,
	{
		airlineCode: string | undefined;
		airlineName?: string | undefined;
		size: AirlineLogoSize;
		src?: string;
		alt?: string;
		isTongCheng?: boolean;
	}
>;

type AirlineLogoSrcProps = {
	airlineCode: string | undefined;
	size: AirlineLogoSize;
	isTongCheng?: boolean;
};

/**
 * 获取新版本航司logo地址
 */
export const useAirlineLogoSrc = ({ airlineCode, size, isTongCheng }: AirlineLogoSrcProps) =>
	useMemo(() => {
		if (isTongCheng) {
			return 'https://pages.ctrip.com/flight_h5/tinyapp/domList/common_flight.png';
		}
		if (airlineCode) {
			return `https://static.tripcdn.com/packages/flight/airline-logo/latest/airline/${size}/${airlineCode.toUpperCase()}.png`;
		}
		return undefined;
	}, [airlineCode, isTongCheng, size]);

/**
 * 航司logo
 */
const AirlineLogo = ({ airlineCode, airlineName, size, isTongCheng, className, ...props }: AirlineLogoProps) => {
	const src = useAirlineLogoSrc({ airlineCode, size, isTongCheng });
	if (!src) {
		return null;
	}
	const onAirlineLogoNotFound = (event) => {
		event.target.onerror = null;
		event.target.src = `https://static.tripcdn.com/packages/flight/airline-logo/latest/airline/${size}/default.png`;
	};
	const mergeClassName = isTongCheng ? className + ' tongcheng-logo' : className;

	return (
		<Image
			{...props}
			src={src}
			alt={airlineName || ''}
			className={mergeClassName}
			onError={onAirlineLogoNotFound}
		/>
	);
};

export default AirlineLogo;
