import Immutable from 'immutable';
import getCabinTitle from './getCabinTitle';

/**
 * 获取多个舱等航程的舱等title，供HOVER
 * @param {*舱等集合} cabinEnumList
 */
const getCabinListTitle = (cabinEnumList: Immutable.List<any>, flightSegments, lccServiceWrapper?: any) => {
	// 此处需要注意，南航超经舱是一个特殊舱等，叫 明珠经济舱
	const cabinTitleList: { title: string; shortTitle: string }[] = [];
	const singleCabin = cabinEnumList.toSet().toList().size === 1;
	let totalSequence = -1;

	flightSegments.forEach((segment, segmentIndex) => {
		if (cabinEnumList.size <= totalSequence + 1) {
			totalSequence = -1;
		}

		segment.get('flightList').forEach((flight, _flightIndex) => {
			totalSequence++;

			const airlineCode = flight.get('operateAirlineCode') || flight.get('marketAirlineCode');
			const cabinInfo = getCabinTitle(
				singleCabin ? cabinEnumList.first() : cabinEnumList.get(totalSequence),
				airlineCode
			);

			if (!cabinTitleList.some((cabin) => cabin.title === cabinInfo.title) && !flight.get('virtualFlight')) {
				if (
					lccServiceWrapper &&
					lccServiceWrapper.data &&
					lccServiceWrapper.data.flightIndexList &&
					lccServiceWrapper.data.flightIndexList.find((item) => {
						return item.segmentNo === segmentIndex + 1 && item.sequenceNo === totalSequence + 1;
					})
				) {
					// @ts-expect-error
					cabinInfo.isLccServiceCabin = true;
				}

				cabinTitleList.push(cabinInfo);
			}
		});
	});

	const cabinTitleLength = cabinTitleList.length;
	if (cabinTitleLength === 1) {
		return cabinTitleList[0].title;
	} else if (cabinTitleLength === 2) {
		return cabinTitleList.map((cabin) => cabin.shortTitle).join('+') + '舱';
	} else {
		return '多种舱位提醒';
	}
};

export default getCabinListTitle;
