/**
 * @class cQueryCookie
 * 设置cookie
 * @namespace cQuery
 */

// cQuery正则表达式
const usedRegExp = {
    toReStringA: /([\.\\\/\+\*\?\[\]\{\}\(\)\^\$\|])/g,
    toReStringB: /[\r\t\n]/g
}

class cQueryCookie {
    constructor(_props) {
        Object.assign(String.prototype, {
            /**
            * @method toReString
            * 转义正则特殊字符
            * @return {string} 返回转义后的特殊字符 (\r转为\\r；\n转为\\n；\t转为\\t；)
            */
            toReString() {
                var h = { "\r": "\\r", "\n": "\\n", "\t": "\\t" }
                return this.replace(
                    usedRegExp.toReStringA,
                    "\\$1"
                ).replace(usedRegExp.toReStringB, function(a) {
                    return h[a]
                })
            }
        })
    }

    /**
    * @method set
    * 设置cookie
    * @param {string} cookie的名称
    * @param {string} cookie的子键
    * @param {string} 值
    * @param {object} 配置项
    */
    set(name, subKey, value, opts) {
        opts = opts || {}
        if (value === null) {
            value = ""
            opts.expires = -1
        }
        var expires = ""
        if (
            opts.expires &&
            (typeof opts.expires == "number" || opts.expires.toUTCString)
        ) {
            var date
            if (typeof opts.expires == "number") {
                date = new Date()
                date.setTime(
                    date.getTime() + opts.expires * 24 * 60 * 60 * 1000
                )
            } else {
                date = opts.expires
            }
            expires = "; expires=" + date.toUTCString() // use expires attribute, max-age is not supported by IE
        }
        var path = opts.path ? "; path=" + opts.path : ""
        var domain = opts.domain ? "; domain=" + opts.domain : ""
        var secure = opts.secure ? "; secure" : ""
        if (subKey) {
            var orginalValue = this.get(name, false) || ""
            if (orginalValue) {
                orginalValue = (orginalValue + "&").replace(
                    new RegExp(
                        "(^|&)\\s*" +
                            encodeURIComponent(subKey).toReString() +
                            "=[^&]+&"
                    ),
                    "$1"
                )
            }
            document.cookie = [
                encodeURIComponent(name),
                "=",
                orginalValue,
                encodeURIComponent(subKey),
                "=",
                encodeURIComponent(value),
                expires,
                path,
                domain,
                secure
            ].join("")
        } else {
            document.cookie = [
                encodeURIComponent(name),
                "=",
                encodeURIComponent(value),
                expires,
                path,
                domain,
                secure
            ].join("")
        }
    }

    /**
        * @method get
        * 获取cookie
        * @param {string} cookie的名称
        * @param {string} cokie的子键
        */
    get(name, subkey) {
        var arr = document.cookie.match(
            new RegExp(
                "(?:^|;)\\s*" +
                    encodeURIComponent(name).toReString() +
                    "=([^;]+)"
            )
        )
        if (subkey === false) {
            return arr ? arr[1] : null
        }
        if (arr && subkey) {
            arr = arr[1].match(
                new RegExp(
                    "(?:^|&)\\s*" +
                        encodeURIComponent(subkey).toReString() +
                        "=([^&]+)"
                )
            )
        }
        return arr ? decodeURIComponent(arr[1]) : null
    }
    del(name, subKey, opts) {
        opts = opts || {}
        var path = opts.path ? "; path=" + opts.path : ""
        var domain = opts.domain ? "; domain=" + opts.domain : ""
        if (subKey) {
            var orginalValue = this.get(name, false)
            if (orginalValue === null) {
                return
            }
            orginalValue = orginalValue
                .replace(
                    new RegExp(
                        "(^|&)\\s*" +
                            encodeURIComponent(subKey).toReString() +
                            "=[^&]+"
                    ),
                    ""
                )
                .replace(/^\s*&/, "")
            if (orginalValue) {
                document.cookie = encodeURIComponent(name) + "=" + orginalValue
                return
            }
        }
        var expires = new Date()
        expires.setTime(expires.getTime() - 1)
        document.cookie =
            encodeURIComponent(name) +
            "=" +
            domain +
            path +
            "; expires=" +
            expires.toGMTString()
    }
}

export default new cQueryCookie()
