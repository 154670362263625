import React, { PropsWithChildren } from 'react';

/**
 * title: 显示的title，可为空
 * content: 文本内容，可以设置 html
 * children: 直接是React元素展现，用于文本不足以展现时候，设置 children 时候不需设置 content
 * extClass: 整个tip的根元素额外样式
 * childrenReplaceRoot: children是否包含整个root根元素
 */
const Tooltip = ({ title, content, children, extClass = '', childrenReplaceRoot = false }: PropsWithChildren<any>) => {
	if (!content && children && childrenReplaceRoot) {
		return children;
	} else {
		return content || children ? (
			<div
				className={`tooltip ${extClass}`}
				style={{
					verticalAlign: 'top',
					textAlign: 'left',
				}}
			>
				<div className="tooltip-content">
					{title ? <h3>{title}</h3> : null}
					{children || (content ? <p dangerouslySetInnerHTML={{ __html: content }}></p> : null)}
				</div>
			</div>
		) : null;
	}
};

export default Tooltip;
