import React from 'react'
import Enum from 'enum'
import CONSTS from '../constConfig'

const RECOMMEND = CONSTS.RECOMMEND
/**
 * 临近低价推荐位置
 */
export const RECOMMEND_LOWPRICE_POSITION = new Enum({
    TOP: {
        key: 1,
        position: "top"
    },
    MIDDLE: {
        key: 2,
        position: "middle"
    }
})

/**
 * 临近低价推荐数量
 */
export const RECOMMEND_LOWPRICE_COUNT = new Enum({
    ONE: {
        key: 1,
        count: "one"
    },
    TWO: {
        key: 2,
        count: "two"
    },
    THREE: {
        key: 3,
        count: "three"
    },
    FOUR: {
        key: 4,
        count: "four"
    },
    MORE: {
        key: 5,
        count: "more"
    }
})

/**
 * 临近低价推荐数量
 */
export const RECOMMEND_CLASS_NAME = new Enum({
    default: {
        className: "four-recmd over-four"
    },
    2: {
        className: "two-recmd"
    },
    3: {
        className: "three-recmd"
    },
    4: {
        className: "four-recmd"
    }
})

/**
 * 临近低价腰部推荐类型
 */
export const RECOMMEND_LOWPRICE_TYPE = new Enum({
    CITY: {
        key: 1
    },
    DATE: {
        key: 2
    },
    CITY_DIRECT: {
        key: 3
    }
})
/**
 * 对于E版整体推荐位下移 后头部推荐位的位置
 */
export const HEAD_RECOMMEND_POSITION = RECOMMEND.HEAD_RECOMMEND_POSITION || 4;
/**
 * 腰部推荐位置
 */
export const MID_RECOMMEND_POSITION = {
    B:new Enum({
        ONE: {
            count: RECOMMEND.MIDDLE_REC_INDEX || 8
        },
        TWO: {
            count: RECOMMEND.MIDDLE_REC_INDEX_TWO || 16
        },
        THREE: {
            count: RECOMMEND.MIDDLE_REC_INDEX_THREE || 24
        }
    }),
    E:new Enum({
        ONE: {
            count: (RECOMMEND.MIDDLE_REC_INDEX + HEAD_RECOMMEND_POSITION) || 12
        },
        TWO: {
            count: (RECOMMEND.MIDDLE_REC_INDEX_TWO + HEAD_RECOMMEND_POSITION) || 20
        },
        THREE: {
            count: (RECOMMEND.MIDDLE_REC_INDEX_THREE + HEAD_RECOMMEND_POSITION) || 28
        }
    }),
    getVersion:function(version) {
        return this[version] || this['B']
    }
}

/**
 * 两舱推荐提示语
 */
export const CF_RECOMMEND_YS_TIPS = new Enum({
    MORE_CLASSGRADE_OPTIONS: {
        key: 1,
        value: `为您提供更多时间和航司选择，补充更多舱位航班`
    },
    YS_DIRECT_CLASSGRADE_TIPS: {
        key: 2,
        value: `经济/超级经济舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    FYS_DIRECT_CLASSGRADE_TIPS: {
        key: 3,
        value: `头等/经济/超级经济舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    Y_DIRECT_CLASSGRADE_TIPS: {
        key: 4,
        value: `经济舱  航班`,
        iconType: 'ico-directflt'
    },
    S_DIRECT_CLASSGRADE_TIPS: {
        key: 5,
        value: `超级经济舱  航班`,
        iconType: 'ico-seatType'
    },
    CYS_DIRECT_CLASSGRADE_TIPS: {
        key: 6,
        value: `公务/经济/超级经济舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    CF_TRANSFER_CLASSGRADE_TIPS: {
        key: 7,
        value: `公务舱/头等舱  中转航班`,
        iconType: 'ico-transfer'
    },
    CF_DIRECT_CLASSGRADE_TIPS: {
        key: 8,
        value: `公务舱/头等舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    CF_DIRECTANDTRANSFER_CLASSGRADE_TIPS: {
        key: 28,
        value: `公务舱/头等舱  航班`,
        iconType: 'ico-directflt'
    },
    C_DIRECT_CLASSGRADE_TIPS: {
        key: 9,
        value: `公务舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    C_NONEDIRECT_CLASSGRADE_TIPS: {
        key: 29,
        value: `公务舱航班`,
        iconType: 'ico-directflt'
    },
    F_DIRECT_CLASSGRADE_TIPS: {
        key: 10,
        value: `头等舱  直飞航班`,
        iconType: 'ico-directflt'
    },
    C_TRANSFER_CLASSGRADE_TIPS: {
        key: 11,
        value: `公务舱  中转航班`,
        iconType: 'ico-transfer'
    },
    F_TRANSFER_CLASSGRADE_TIPS: {
        key: 12,
        value: `头等舱  中转航班`,
        iconType: 'ico-transfer'
    },
    C_DIRECT_OR_TRANSFER_CLASSGRADE_TIPS: {
        key: 13,
        value: `公务舱  直飞+中转航班`,
        iconType: 'ico-directflt'
    },
    F_DIRECT_OR_TRANSFER_CLASSGRADE_TIPS: {
        key: 14,
        value: `头等舱  直飞+中转航班`,
        iconType: 'ico-directflt'
    },
    //TODO可配置的
    YS_FOLD_GATE: {
        key: 15,
        value: RECOMMEND.CF_REC_YS_REC_FOLD_INDEX || 4
    },
    RECOMMEND_MIN_GATE: {
        key: 16,
        value: RECOMMEND.CF_REC_YS_REC_MIN_INDEX || 12
    },
    RECOMMEND_AFTER_GATE: {
        key: 17,
        value: RECOMMEND.CF_REC_YS_REC_AFTER_INDEX || 10
    },
    CF_NODIRECT_TIPS: {
        key: 18,
        value: `您所查询的航线公务/头等舱无直飞，为您推荐：`
    },
    C_NODIRECT_TIPS: {
        key: 19,
        value: `您所查询的航线公务舱无直飞航班，为您推荐：`
    },
    F_NODIRECT_TIPS: {
        key: 20,
        value: `您所查询的航线头等舱无直飞航班，为您推荐：`
    },
    Y_NODIRECT_TIPS: {
        key: 21,
        value: `您所查询的航线公务舱无直飞航班，为您推荐：`
    },
    S_NODIRECT_TIPS: {
        key: 22,
        value: `您所查询的航线头等舱无直飞航班，为您推荐：`
    },
    CF_NORESULT_TIPS: {
        key: 23,
        value: `您所查询的航线公务/头等舱无航班，为您推荐：`
    },
    C_NORESULT_TIPS: {
        key: 24,
        value: `您所查询的航线无公务舱航班，为您推荐：`
    },
    F_NORESULT_TIPS: {
        key: 25,
        value: `您所查询的航线无头等舱航班，为您推荐：`
    },
    MORE_OPTIONS_CHILD_TIPS: {
        key: 26,
        value: `更多时间和航司选择`
    },
    TRANSFER_CHILD_TIPS: {
        key: 27,
        value: `直飞无结果，看看中转吧`
    }
})

/**
 * 查询无结果&放开查询条件有推荐
 */
export const NO_RESULT_RELEASE_COND_RECOMMEND = new Enum({
    mutilCondtion: {
        text: '很遗憾，未查询到符合条件航班，为您推荐',
        title: 'DEPCITY到AIRCITY所有航班',
        hint: '多个机场，选择更多'
    },
    subAirport: {
        text: '很遗憾，未查询到指定机场航班，为您推荐',
        title: ['DEPCITY所有机场出发的航班',
            '到达AIRCITY所有机场的航班',
            'DEPCITY到AIRCITY所有机场航班'],
        hint: '多个机场，选择更多'
    },
    airLine: {
        text: '很遗憾，未查询AIRLINE航司的航班，为您推荐',
        title: '更多航司航班',
        hint: '看看更多航司，总有你要的选择'
    },
    isDirect: {
        text: '很遗憾，未查询到直飞航班，为您推荐',
        title: '中转航班',
        hint: '直飞无结果，看看中转吧'
    }
})

/**
 * 子机场推荐
 */
export const SUB_AIRPORT_RECOMMEND = new Enum({
    AIRPORT_DEPART: {
        type: 1,
        text: 'DCITY所有机场和航司出发的航班'
    },
    AIRPORT_ARRIVE: {
        type: 2,
        text: '到达ACITY所有机场和航司的航班'
    },
    AIRPORT_BOTH: {
        type: 3,
        text: 'DCITY到ACITY所有机场和航司航班'
    }
})

/**
 * 埋点腰部推荐位
 */
export const UBT_CENTRAL_INFO = new Enum({
    0: 'CentralFirstInfo',
    1: 'CentralSecondInfo',
    2: 'CentralThirdInfo'
})

/**
 * 缺口程推荐地面程交通方式
 */
export const OPEN_JAW_GROUND_TRAFFIC_TYPE = new Enum({
    TRAIN: {
        name: "火车"
    },
    BUS: {
        name: "巴士"
    },
    FERRY: {
        name: "轮船"
    },
    CAR: {
        name: "自驾"
    }
})

/**
* 缺口程推荐地面程表格抬头
*/
export const OPEN_JAW_GROUND_TRAFFIC_TITLE = new Enum({
    0: {
        title: "交通方式",
        dataKey: "type",
    },
    1: {
        title: "距离",
        dataKey: "distance"
    },
    2: {
        title: "交通时间",
        dataKey: "time"
    },
    3: {
        title: "票价",
        dataKey: "price"
    }
})


/**
* 邻近直飞推荐的直飞类型
*/
export const ADJACENT_AIRLINE_DIRECT_TYPE = new Enum({
    DEPARTURE: '去程直飞',
    BACK: '返程直飞',
    ROUND: '往返直飞'
})

/**
* 邻近推荐的直飞类型
*/
export const ADJACENT_AIRLINE_TYPE = new Enum({
    adjacentAirline: {
        title: '邻近航线',
        ubtInfoType: 1,
        getTag: (price) => <span className="tag">省<dfn>&yen;</dfn>{price}</span>,
        getPrice: (price, hint, tax, includeTax) => (
            <div className="recmd-price">
                <div className="price"><dfn>&yen;</dfn>{price}<span className="qi">起</span></div>
                <div className={includeTax ? "hint" : "hint tax"}>{hint}{includeTax ? null : <React.Fragment> <dfn>&yen;</dfn>{tax}</React.Fragment>}</div>
            </div>)
    },
    adjacentAirlineDirect: {
        title: '邻近航线直飞',
        ubtInfoType: 6,
        getTag: (_price) => null,
        getPrice: (_price) => (
            <div className="recmd-price direct-txt">
                有直飞
            </div>)
    },
    adjacentAirlineCombineDirect: {
        title: '邻近航线',
        ubtInfoType: 7,
        getTag: (_price) => <span className="tag">有直飞</span>,
        getPrice: (price, hint, tax, includeTax) => (
            <div className="recmd-price">
                <div className="price"><dfn>&yen;</dfn>{price}<span className="qi">起</span></div>
                <div className={includeTax ? "hint" : "hint tax"}>{hint}{!includeTax ? <dfn>&yen;</dfn> : null}{!includeTax ? tax : null}</div>
            </div>)
    }
})

/**
* 价格提示
*/
export const PRICE_HINT = new Enum({
    includeTax: {
        S: '含税价',
        D: '往返含税价'
    },
    notIncludeTax: {
        S: '税费',
        D: '往返税费'
    }
})