import { GLOBAL_PREFIX, PAGE_LIST_URL } from '../../constants/common';

export const trimUrlHost = (url = '') => {
	if (url) {
		if (url.toLowerCase().indexOf('http') === 0) {
			const anchor = document.createElement('a');
			anchor.href = url;

			const { pathname, search } = anchor;
			url = `${pathname}${search}`;
		}
		return url.replace(`${GLOBAL_PREFIX}/`, '').replace('list/', '');
	} else {
		return '';
	}
};

export const trimUrlPageList = (url = '') => {
	if (url) {
		// if (url.toLowerCase().indexOf('http') === 0) {
		//   var anchor = document.createElement('a');
		//   anchor.href = url;
		//
		//   const { pathname, search } = anchor;
		//   url = `${pathname}${search}`;
		// }
		// return url.replace(/\/?international\/search\/?/, '')
		return url.replace(PAGE_LIST_URL, '');
	} else {
		return '';
	}
};
