function joinQueryParams(params) {
    return params ? Object.keys(params)
        .map(k => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
        .join('&') : '';
}

export const genUrlFromParams = (url, params) => {
    if (url && params) {
        params = { ...params }
        url += (url.indexOf('?') === -1 ? '?' : '&') + joinQueryParams(params)
    }

    return url
}