import Enum from 'enum'
import { UBT_EVENT_TYPE } from '../common'
import { CHANNEL_UBT_GROUP_TYPES } from './groupTypes'
import { UbtHandler } from '../handler'

/**
 * 确保某数据一定包含指定属性路径，比如确保 data 对象一定包含 data.p1.p2 ，如果属性不存在就初始化为 传入的默认对象
 * @param {待设置的数据} data 
 * @param {待设置的数据，主要是考虑到空对象、空数组、数字0等情况} defaultValue 
 * @param {根据顺序排序的属性名称，比如 'p1', p2' 就是确保 data 对象包含 data.p1.p2 属性} propsArray 
 */
let ensureProp = (data, defaultValue, ...propsArray) => {
    let thePrevData = data

    for (let index in propsArray) {
        let propName = propsArray[index]
        if (typeof thePrevData[propName] === 'undefined') {
            //最后一个属性值使用传入的参数，否则就用空对象
            thePrevData[propName] = (+index === propsArray.length - 1 ? defaultValue : {})
        }

        thePrevData = thePrevData[propName]
    }
}

//此处是引用的而不是重新定义，给各种自定义事件的 process 中使用
 let rawCalendarData = CHANNEL_UBT_GROUP_TYPES.LOW_PRICE_CALENDAR_CLICK.value.rawData
 let rawHistorySearchData = CHANNEL_UBT_GROUP_TYPES.HISTORY_SEARCH_RECOMMEND.value.rawData
 let lowPriceReportData = CHANNEL_UBT_GROUP_TYPES.LOW_PRICE_REPORT.value.rawData

/**
 * UBT信息的默认处理，适合单一KEY的简单加1机制
 * @param {KEY的路径} path 
 * @param {处理器} handler 
 */


export const CHANNEL_UBT_KEY_TYPES = new Enum({
   
    //低价日历点击次数
    LOW_PRICE_CALENDAR_CLICK:{
        listen: UBT_EVENT_TYPE.CLICK,
        process: (handler, _source, _ev) => {
            ensureProp(handler.rawData, 0, handler.tag)

            let count = handler.rawData[handler.tag] || 0
            handler.rawData[handler.tag] = count + 1
        }
    },

     //低价日历翻页,左翻
     LOW_PRICE_CALENDAR_MONTH_PREV_CLICK: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: () => {
            let count = rawCalendarData['c_leftmore_calender_search'] || 0 
            rawCalendarData['c_leftmore_calender_search'] = count + 1
        }
    },
     //低价日历翻页,右翻
     LOW_PRICE_CALENDAR_MONTH_NEXT_CLICK: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: () => {
            let count = rawCalendarData['c_rightmore_calender_search'] || 0 
            rawCalendarData['c_rightmore_calender_search'] = count + 1
        }
    },
    //日历选中
    LOW_PRICE_CALENDAR_ON_DATE_CAHNGE: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: () => {
            let count = rawCalendarData['c_select_price_search'] || 0 
            rawCalendarData['c_select_price_search'] = count + 1
        }
    },
    //低价选中
    LOW_PRICE_CALENDAR_ON_LP_DATE_SELECT: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: () => {
            let count = rawCalendarData['c_select_lp_price_search'] || 0 
            rawCalendarData['c_select_lp_price_search'] = count + 1
        }
    },
    //点击即上报
    SEND_NOW_CLICK: {
        listen: UBT_EVENT_TYPE.MOUSE_DOWN,
        process: (handler, _source, _ev) => {
            UbtHandler.onUploadData(`c_click`, handler.tag)
            return false
        }
    },
    SEND_NOW_SHOW:{
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler) => {
            UbtHandler.onUploadData(`c_show`, handler)
        }
    },
    LOW_PRICE_REPORT:{
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler) => {
            Object.assign(lowPriceReportData, handler)
        }
    },
    HISTORY_SEARCH_RECOMMEND_SHOW: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler) => {
            let sData = handler.tag[0] && handler.tag[0].sData
            let calendarPrice = handler.tag[0] && handler.tag[0].calendarPrice
            let flight = sData && sData.flightList && sData.flightList[0]
            let returnDate = flight && sData.flightList[1] && sData.flightList[1].departureDate
            let getCityName = (text) =>{
                let cityLength = text.lastIndexOf('(') > 0 ? text.lastIndexOf('(') : text.length
                return text.substr(0, cityLength)
            }
            if(flight) {
                let pirceattribute = null
                let title = null
                if(sData.price && calendarPrice) {
                    if(sData.price < calendarPrice) {
                        pirceattribute = '上涨'
                        title = `比上次搜涨了￥${calendarPrice - sData.price}`
                    }else if(sData.price > calendarPrice) {
                        pirceattribute = '下降'
                        title = `比上次搜降了￥${sData.price - calendarPrice}`

                    }else if(sData.price == calendarPrice) {
                        pirceattribute = '持平'
                        title = `价格稳定`
                    }
                }
                let ubt = {
                    dcityname: getCityName(flight.departureName),
                    acityname: getCityName(flight.arrivalName),
                    takeoffdate: flight.departureDate,
                    arrivedate: returnDate || null,
                    price: calendarPrice || null,
                    pirceattribute: pirceattribute,
                    title: title,
                    click: 0
                }
                rawHistorySearchData.push(ubt)
            }
        }
    },
    HISTORY_SEARCH_RECOMMEND_CLICK: {
        process: (handler) => {
            let index = handler.id
            if(rawHistorySearchData[index]) {
                rawHistorySearchData[index]['click'] = 1
            }
        } 
    },
    // 航司特惠曝光上报
    AIRLINE_PREFERENCE_SHOW: {
        listen: UBT_EVENT_TYPE.LOAD,
        process: (handler) => {
            UbtHandler.onUploadData(`c_int_airline_preference_show`, handler)
        }
    }
})
