export class DataRetriever {
    static getBasicDataWithoutInitFromPrevCond(prevCond) {
        let sequence = []
        prevCond.flightSegments.forEach((segment, _index) => {
            sequence.push({
                From: {
                    CityName: segment.departureCityName,
                    CityCode: segment.departureCityCode,
                    CityID: segment.departureCityId
                },
                To: {
                    CityName: segment.arrivalCityName,
                    CityCode: segment.arrivalCityCode,
                    CityID: segment.arrivalCityId
                },
                StartTime: segment.departureDate
            })
        })

        let result = {
            FlightClass: 'I',
            FlightWay: prevCond.flightWay,
            Class: prevCond.cabin,
            PassengerType: {
                Adult: prevCond.adultCount,
                Children: prevCond.childCount,
                Infant: prevCond.infantCount
            },
            Sequence: sequence,
            TriggerType: "Exit"
        }

        if (typeof (prevCond.Is_Crawler) != 'undefined') {
            result.Is_Crawler = prevCond.Is_Crawler;
        }
        if (typeof (prevCond.CrawlerType) != 'undefined') {
            result.CrawlerType = prevCond.CrawlerType;
        }
        return result
    }

    static getBasicDataFromPrevCond(prevCond) {
        const basicData = DataRetriever.getBasicDataWithoutInitFromPrevCond(prevCond)
        const result = Object.assign(basicData, {
            AirLine: '',
            tags: {
                Producttags: '',
                Servicetags: '',
                Restrictiontags: ''
            },
            lp_calender: {
                head_show: 0,
                month_type: 0,
                week_type: 0,
                is_trend_show: 0,
                is_calender_show: 0
            }
        })

        return result
    }
}