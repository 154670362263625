import { PRICE_TAG_TYPE, PriceTagWrapper } from '../../constants/list/enums/priceTagType';
import { ProductCategoryWrapper } from '../../constants/list/enums';
import { INVOICE_TYPE } from '../../constants/common/enum/common';
import { GV_LIMITATION_TYPE, GVLimitationsWrapper } from '../../constants/list/enums/gvLimitationType';
import { RelatedPriceWrapper } from '../../constants/list/enums/relatedPriceWrapper';
import { getBrandPriceTag, getChooseXPriceTag, getRefinedRelatedPrices, getPriceTagOfIncludingRelatedPrice } from './genRelatedPrices';
import { genLccService } from './genLccService';
import { LccServiceWrapper } from '../../constants/list/enums/lccServiceWrapper';

const PRICE_TAG_TYPE_KEYS = Object.keys(PRICE_TAG_TYPE.toJSON()),
  GV_LIMITATION_TYPE_KEYS = Object.keys(GV_LIMITATION_TYPE.toJSON());

/**
 * 对单条运价处理后补充设置其枚举值，拆出来的原因是此处依赖项过于复杂，不方便在Worker中重用
 * @param {*某一条运价} price
 */
export const setPriceEnums = price => {
  price.priceTagEnums = price.priceTags.filter(tag => PRICE_TAG_TYPE_KEYS.includes(tag.type)).map(tag => new PriceTagWrapper(tag));

  price.GVLimitationsEnums = price.restrictionList.filter(restrict => GV_LIMITATION_TYPE_KEYS.includes(restrict.type)).map(restrict => new GVLimitationsWrapper(restrict));

  price.productTypeEnum = price.productType && price.productType.category && new ProductCategoryWrapper(price.productType);

  price.extDataProductTypeEnum = price.extData && price.extData.productType && price.extData.productType.category && new ProductCategoryWrapper(price.extData.productType);

  price.invoiceTypeEnum = price.invoiceType
    .split(',')
    .filter((element, index, self) => self.indexOf(element) === index)
    .map(c => INVOICE_TYPE.get(c));

  // 设置 品牌运价 和 三选X 的信息
  const setRelatedPrice = (getPriceTag, thePrice, wrapperFieldName, recursion) => {
    const theBrand = getPriceTag(thePrice);
    if (theBrand) {
      const wrapper = new RelatedPriceWrapper(theBrand.data);
      // 可能会有内嵌运价，点击选择运价时候展开显示，内嵌运价不包括当前运价自身，但展开时候又会并排显示
      thePrice[wrapperFieldName] = wrapper;

      if (wrapper.hasRelatedPrices) {
        const refinedRelatedPrices = getRefinedRelatedPrices(thePrice);
        const tag = getPriceTagOfIncludingRelatedPrice(thePrice);
        tag && (tag.data.relatedPrices = refinedRelatedPrices);
        thePrice.relatedPrices = refinedRelatedPrices;

        // 内嵌
        recursion &&
          thePrice.relatedPrices.forEach(innerPrice => {
            setRelatedPrice(getPriceTag, innerPrice, wrapperFieldName, false);
          });
      }
    }
  };

  setRelatedPrice(getBrandPriceTag, price, 'brandWrapper', true);
  setRelatedPrice(getChooseXPriceTag, price, 'chooseXWrapper', true);

  const theLccService = genLccService(price);
  if (theLccService) {
    price.lccServiceWrapper = new LccServiceWrapper(theLccService.data);
  }

  return price;
};
