import { createStore, applyMiddleware } from 'redux';
import { combineReducers } from 'redux-immutable';
import Immutable from 'immutable';
import { thunk } from 'redux-thunk';
import { composeWithDevTools } from '@redux-devtools/extension';
import list from '@/src/reducers/list/list';
import routerReducer from './router-reducer';

// 定义将始终存在于应用程序中的 Reducer

const createReducer = (asyncReducers = {}) =>
	combineReducers({
		list,
		router: routerReducer,
		...asyncReducers,
	});

const storeCreator = (initialState = {}, asyncReducers = {}) => {
	let enhancer = applyMiddleware(thunk);
	if (process.env.NODE_ENV === 'development') {
		enhancer = composeWithDevTools(applyMiddleware(thunk));
	}
	const store = createStore(createReducer(asyncReducers), Immutable.fromJS(initialState), enhancer);
	// 添加一个对象以跟踪已注册的异步 Reducer
	// @ts-expect-error
	store.asyncReducers = asyncReducers;

	// 创建注入 reducer 函数
	// @ts-expect-error
	store.createReducer = createReducer;

	return store;
};

let store;

export const initializeStore = (preloadedState?: Object) => {
	let _store = store ?? storeCreator(preloadedState);

	// After navigating to a page with an initial Redux state, merge that state
	// with the current state in the store, and create a new store
	if (preloadedState && store) {
		_store = storeCreator({
			...store.getState(),
			...preloadedState,
		});
		// Reset the current store
		store = undefined;
	}

	// For SSG and SSR always create a new store
	if (typeof window === 'undefined') return _store;
	// Create the store once in the client
	if (!store) store = _store;

	return _store;
};

initializeStore();

// export function useStore(initialState) {
// 	return useMemo(() => initializeStore(initialState), [initialState]);
// }

export default store;
