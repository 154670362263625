import React from 'react'
import Tooltip from '../../../components/list/common/tooltip'
import DeferredContent from '../../../components/list/common/deferredContent'
import PRODUCT_MESSAGE from './productMessage'
import { UED } from '../../../components/list/popups/styleConst'
import Converter from '../../../sources/common/converter'
import { getIfNeedExTipOfTransitPU } from '../../../sources/common/getIfNeedExTipOfTransitPU'
import POP_UP_MSG from '../../../components/list/popups/popupMsg'

const getTransitPUTooltip = (price, currentSegmentSeq) => {
    let hasVisaRiskCityList = []

    const hasVisaRiskCities = price
        .get('transferCheckInCities')
        .filter(city => city.get('segmentNo') === currentSegmentSeq + 1 && city.get('hasVisaRisk')),
        needExtTransitTip = hasVisaRiskCities.some(city => getIfNeedExTipOfTransitPU(city))

    hasVisaRiskCities.sort((prev, next) => prev.get('sequenceNo') - next.get('sequenceNo'))
        .forEach(city => {
            hasVisaRiskCityList.push(city.get('cityName'))
        })

    let tooltipContent = (
        <div className='tooltip tooltip-ticketgroup' style={Object.assign({}, UED.tooltipMargin, { marginTop: 0, marginBottom: 3 })}>
            <div className='tooltip-content'>
                <div className='tg-title'>
                    <div className='title' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Summary_Header }}></div>
                    <div className='hint' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Summary_Content }}></div>
                </div>

                <ul className='tg-cont'>
                    <li>
                        <div className='caption' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Advantage_Split }}></div>
                        <div className='options'>
                            <div className='opt-detail'>
                                <i className='ico-ensure'></i>
                                <div className='opt-tit' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Ensure_Header }}></div>
                                <div className='opt-cont' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Ensure_Content }}></div>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className='caption' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Caution_Split }}></div>
                        <div className='options'>
                            <div className='opt-detail'>
                                <i className='ico-rebook'></i>
                                <div className='opt-tit' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Boarding_Header }}></div>
                                <div className='opt-cont' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Boarding_Content }}></div>
                            </div>
                            {hasVisaRiskCityList.length ? (
                                <div className='opt-detail'>
                                    <i className='ico-revisa'></i>
                                    <div className='opt-tit' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Visa_Header }}></div>
                                    <div className='opt-cont' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Visa_Content.replace(/\[CITY_LIST\]/, hasVisaRiskCityList.join('、')) + (needExtTransitTip ? POP_UP_MSG.TRANSITPU_CONFIRM.TW_VISA_TIP : '') }}></div>
                                </div>
                            ) : null}
                            <div className='opt-detail'>
                                <i className='ico-reluggage'></i>
                                <div className='opt-tit' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Luggage_Header }}></div>
                                <div className='opt-cont' dangerouslySetInnerHTML={{ __html: PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.TransitPU_Luggage_Content }}></div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </div>)

    return (
        <Tooltip childrenReplaceRoot={true}>
            {tooltipContent}
        </Tooltip>)
}

/**
 * 产品类型
 */
export const InternationalProductType = {
    None: {
        isNone: true,
        rawLabel: '',
        getLabel: (_wrapper, _id) => null,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    TransitPU: {
        //多票组合
        rawLabel: '多票组合',
        getLabel: (wrapper, id) => <img className='special-img' alt='多票组合' id={id} src='//pic.c-ctrip.com/flight_intl/list/tags/tag-ticketgroup.png' />,
        getLabelTooltip: (wrapper, price, currentSegmentSeq) => {
            return getTransitPUTooltip(price, currentSegmentSeq)
        },
        getFlightRowLabel: (_wrapper, _id) => {
            return <img className='fltcard-tag special-img' src='//pic.c-ctrip.com/flight_intl/list/tags/tag-ticketgroup2.png' />
        },
        getFlightRowLabelTooltip: (price, currentSegmentSeq) => {
            return getTransitPUTooltip(price, currentSegmentSeq)
        },
        getBookNotice: (wrapper, id) => <span id={id} className='abbr'>订票须知</span>,
        getBookNoticeTooltip: (price) => {
            let checkInCitys = price.get('transferCheckInCities'),
                hasVisaRisk = checkInCitys.some(city => city.get('hasVisaRisk')),
                joinCityNames = checkInCitys.map(city => city.get('cityName')).toSet().join('、'),
                needExtTransitTip = checkInCitys.some(city => getIfNeedExTipOfTransitPU(city)),
                innerHtml = PRODUCT_MESSAGE.BOOK_NOTICE.TransitPUPart1
                    + (hasVisaRisk ? PRODUCT_MESSAGE.BOOK_NOTICE.TransitPUHasRiskPart2WithExtTip : PRODUCT_MESSAGE.BOOK_NOTICE.TransitPUNoRiskPart2)
                        .replace(/TRANS_CITY_NAME/ig, joinCityNames)
                        .replace(/\[EXT_VISA_TIP\]/ig, needExtTransitTip ? POP_UP_MSG.TRANSITPU_CONFIRM.TW_VISA_TIP : '')

            return <Tooltip content={innerHtml} />
        }
    },
    MultiPU: {
        //组合特价
        rawLabel: '组合特价',
        getLabel: (wrapper, id) => <img id={id} alt='组合特价' className='special-img' src='//pic.c-ctrip.com/flight_intl/list/tags/tag-cheap-group.png' />,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.MultiPU} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    FlagShipStore: {
        // 航司旗舰店
        rawLabel: '航司旗舰店',
        getLabel: (wrapper, id) => <img id={id} alt='航司旗舰店' src={`//pic.c-ctrip.com/fltcommon/flagship/web/${wrapper.name.toUpperCase()}.png`} />,
        getLabelTooltip: (wrapper, _price, _currentSegmentSeq) => <Tooltip content={wrapper.description} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    AirlineAuthorizedStore: {
        // 航司授权店
        rawLabel: '航司授权店',
        getLabel: (wrapper, id) => <img id={id} alt='航司授权店' src={wrapper.icon && wrapper.icon.indexOf('http') === 0 ? wrapper.icon : `//pic.c-ctrip.com/fltcommon/flagship/${wrapper.icon || `${wrapper.name}.png`}`} />,
        getLabelTooltip: (wrapper, _price, _currentSegmentSeq) => <Tooltip content={wrapper.description} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    InternationalStudentFreight: {
        //航司授权店
        rawLabel: '留学生',
        getLabel: (wrapper, id) => <img id={id} alt='留学生' src={wrapper.icon && wrapper.icon.indexOf('http') === 0 ? wrapper.icon : `//pic.c-ctrip.com/fltcommon/flagship/${wrapper.icon || `${wrapper.name}.png`}`} />,
        getLabelTooltip: (wrapper, _price, _currentSegmentSeq) => <Tooltip content={wrapper.description} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    LowestPrice: {
        //航司直销
        rawLabel: '航司直销',
        wrapLabelTooltip: true,
        getDeferredLabel: (wrapper, id, productTypeTagTooltip) => {
            let defaultLabel = InternationalProductType.LowestPrice.getLabel(wrapper, id)

            return (
                <React.Fragment>
                    {defaultLabel}
                    {productTypeTagTooltip}
                </React.Fragment>
            )

        },
        getLabel: (wrapper, id) => <img id={id} alt='航司直销' className='special-img' src='//pic.c-ctrip.com/flight_intl/list/tags/tag-direct-sell.png' />,
        getLabelTooltip: (wrapper, _price, _currentSegmentSeq) => <Tooltip content={wrapper.description} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    SpecialRefund24Hours: {
        //24小时特惠退
        rawLabel: '24小时特惠退',
        getLabel: (wrapper, id) => <img className='special-img' id={id} alt='24小时特惠退' src='//pic.c-ctrip.com/flight_intl/list/tags/tag-refundeasy.png' />,
        getDeferredLabelTooltip: (id, getPromise) => {
            const defaultLabelTooltip = <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund24Hours} />

            return <DeferredContent key={id} getPromise={getPromise} loading={defaultLabelTooltip} then={(data, ext) => {
                const { pennyRefundFee } = ext,
                    penaltyMsg = typeof pennyRefundFee === 'undefined'
                        ? PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund24Hours
                        : pennyRefundFee > 0
                            ? PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund24HoursWithFee.replace('[VAL]', pennyRefundFee)
                            : PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund24HoursWithoutFee

                return <Tooltip content={penaltyMsg} />
            }} />
        },
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    SpecialRefund2Hours: {
        //2小时特惠退
        rawLabel: '2小时特惠退',
        getLabel: (wrapper, id) => <img className='special-img' id={id} alt='2小时特惠退' src='//pages.ctrip.com/flight_h5/online/list/tags/tag-2h.png' />,
        getDeferredLabelTooltip: (id, getPromise) => {
            const defaultLabelTooltip = <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund2Hours} />

            return <DeferredContent key={id} getPromise={getPromise} loading={defaultLabelTooltip} then={(data, ext) => {
                const { pennyRefundFee, specialRefund} = ext;
                const { content } = specialRefund || {};
                const defaultPenaltyMsg = typeof pennyRefundFee === 'undefined'
                        ? PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund2Hours
                        : pennyRefundFee > 0
                            ? PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund2HoursWithFee.replace('[VAL]', pennyRefundFee)
                            : PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.SpecialRefund2HoursWithoutFee
                const penaltyMsg = content || defaultPenaltyMsg
                return <Tooltip content={penaltyMsg} />
            }} />
        },
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    Prioritizing: {
        //商务优选
        rawLabel: '出行优选',
        getLabel: (wrapper, id) => <img id={id} alt='出行优选' src='//pic.c-ctrip.com/flight_intl/list/tags/tag-businessv2.png' />,
        getLabelTooltip: (wrapper, price, _currentSegmentSeq) => {
            // 包含电子凭证时，hover商务优选不用展示文案：“提供行程单或者机票发票，报销无忧；”
            let invoice = price.get('invoiceTypeEnum').filter(i => i.key == 'E')
            if (invoice.size > 0) {
                return <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.PrioritizingExceptEInvoice} />
            }
            return <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.Prioritizing} />
        },
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    Exclusive: {
        //旅行套餐
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.Exclusive,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.Exclusive}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => <Tooltip content={PRODUCT_MESSAGE.PRODUCT_TAG_HOVER.Exclusive.replace(/\[TIME\]/g, Converter.getThreeMonthsLater())} />,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    BaggagePackage: {
        //行李套餐
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.BaggagePackage,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.BaggagePackage}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    GuangfaExclusive: {
        //广发专享
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.GuangfaExclusive,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.GuangfaExclusive}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    BusinessRecommend: {
        //商务推荐
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.BusinessRecommend,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.BusinessRecommend}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    SpecialRecommend: {
        //特别推荐
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.SpecialRecommend,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.SpecialRecommend}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    },
    Cloud: {
        //特惠推荐
        rawLabel: PRODUCT_MESSAGE.PRODUCT_TAG.Cloud,
        getLabel: (wrapper, id) => <span id={id}>{PRODUCT_MESSAGE.PRODUCT_TAG.Cloud}</span>,
        getLabelTooltip: (_wrapper, _price, _currentSegmentSeq) => null,
        getFlightRowLabel: (_wrapper, _id) => null,
        getFlightRowLabelTooltip: (_price, _currentSegmentSeq) => null,
        getBookNotice: (_wrapper, _id) => null,
        getBookNoticeTooltip: (_wrapper) => null
    }
}
