import qs from 'qs';
import { PAGE_LIST_URL } from '@/src/constants/common';
import { onResearchFlightsWithFullUrl } from '@/src/actions/list/getGlobalSearchCriteria';
import assignObjectDelectIfBlank from './assignObjectDelectIfBlank';
import addParamsToListUrl from './addParamsToListUrl';
import { setAdjacentRecommendType } from './SessionHandler';

const toNewListPage = (urlProps, ubtProps) => {
	let url = window.location.href,
		params = qs.parse(window.location.search.substr(1)),
		hash = window.location.hash,
		flightWayAndCityString = window.location.pathname.split('/')[window.location.pathname.split('/').length - 1];
	if (urlProps && Object.keys(urlProps).length > 0) {
		if (urlProps.flightWayAndCityString) {
			flightWayAndCityString = urlProps.flightWayAndCityString;
		}
		if (urlProps.searchParams && Object.keys(urlProps.searchParams).length > 0) {
			params = assignObjectDelectIfBlank(params, urlProps.searchParams);
		}
		if (urlProps.hash) {
			hash = urlProps.hash;
		}
		url = `${PAGE_LIST_URL}/${flightWayAndCityString}?${qs.stringify(params)}${hash}`;
		if (urlProps.otherParamsForJson && Object.keys(urlProps.otherParamsForJson).length > 0) {
			url = addParamsToListUrl(urlProps.otherParamsForJson, url);
		}
	}
	if (ubtProps && Object.keys(ubtProps).length > 0 && urlProps.searchParams.txid) {
		let storageUbtSourceData = {};
		if (localStorage.ubtSourceData) {
			storageUbtSourceData = JSON.parse(localStorage.ubtSourceData);
			setAdjacentRecommendType(ubtProps);
		}
		storageUbtSourceData[urlProps.searchParams.txid] = ubtProps;
		localStorage.ubtSourceData = JSON.stringify(storageUbtSourceData);
	}
	onResearchFlightsWithFullUrl(url);
	// import(/* webpackChunkName: "list.getGlobalSearchCriteria" */ '../../actions/list/getGlobalSearchCriteria')
	//     .then(result => result.onResearchFlightsWithFullUrl(url))
};

export default toNewListPage;
