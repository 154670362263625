import Enum from 'enum';

export const VIRTUAL_FLIGHT_TEXT = new Enum({
	SEAPLANE: '船',
	TRAIN: '火车',
	BUS: '巴士',
});

export const VIRTUAL_FLIGHT_TAG = new Enum({
	SEAPLANE: '含船票',
	TRAIN: '含火车',
	BUS: '含巴士',
});

export const VIRTUAL_FLIGHT_NAME = new Enum({
	SEAPLANE: '汽艇/小船',
	TRAIN: '火车',
	BUS: '巴士',
});
